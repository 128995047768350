import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from "react-burger-menu";

function ProviderHeader() {
  const { providerEmail } = useContext(AuthContext);
  const [menuActive, setMenuActive] = useState(false);
  const { providerId } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const menubarMobile = (
    <Menu
      left
      pageWrapId={"page-wrap"}
      outerContainerId={"root"}
      style={{ color: "black", right: "0px !important" }}
    >
      <ul className="nav-list mynavlist providerlist">
        <li>
          <Link className="nav-link ddd" to="/provider/dashboard">
            <font color="black">Dashboard</font>
          </Link>
        </li>
        <li>
          <Link className="nav-link ddd" to="/provider/profile">
            <font color="black">Profile</font>
          </Link>
        </li>
      </ul>
    </Menu>
  );

  const menuBar = (
    <nav className="nav">
      <input type="checkbox" id="nav-check" />
      <div className="nav-header"></div>
      <div className="nav-btn providerheadernavbtn" onClick={toggleMenu}>
        <label htmlFor="nav-check">
          <span className="bgm1"></span>
          <span className="bgm1"></span>
          <span className="bgm1"></span>
        </label>
      </div>
      <ul className="nav-list mynavlist providerlist">
        <li>
          <Link className="nav-link ddd" to="/provider/dashboard">
            <font color="#fff">Dashboard</font>
          </Link>
        </li>
        <li>
          <Link className="nav-link ddd" to="/provider/profile">
            <font color="#fff">Profile</font>
          </Link>
        </li>
        {/* <li>
          <a className="nav-link ddd" href="/provider/report">
            <font color="#fff">Report</font>
          </a>
        </li>
        <li>
          <a className="nav-link ddd" href="/provider/scheduling">
            <font color="#fff">Schedule</font>
          </a>
        </li> */}
      </ul>
    </nav>
  );

  const loginUI = () => (
    <Link to="/provider/login">
      {!isMobile ? (
        <span className="custom-btn btn-1 loginbtn loginbutton dropdown-toggle">
          Login
        </span>
      ) : (
        <FontAwesomeIcon
          icon={faUser}
          style={{ fontSize: "25px", top: "0px" }}
        />
      )}
    </Link>
  );

  return (
    <>
      <nav className="navbar" id="page-wrap">
        <div className="d-flex">
          <div className="logo">
            <Link to="/provider/dashboard">
              <img src="/../style/img/logonav.png" alt="Logo" />
            </Link>
          </div>

          <div className="login-button">
            <ul
              id="primary-menu"
              className={`menu nav-menu ${menuActive ? "menu-active" : ""}`}
            >
              <li className="topnavtext submenu dropdown">
                {!providerEmail ? (
                  loginUI()
                ) : (
                  <>
                    <span className="topnavtext dropdown-toggle">
                      {!isMobile ? (
                        providerEmail
                      ) : (
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ fontSize: "25px"}}
                        />
                      )}
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/provider/dashboard">Dashboard</Link>
                      </li>
                      <li>
                        <Link to="/provider/profile">Profile</Link>
                      </li>
                      <li>
                        <Link
                          to="/provider/logout"
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <br />
      <br />

      {isMobile ? menubarMobile : menuBar}
    </>
  );
}

export default ProviderHeader;
