import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
import { FaArrowRightLong } from "react-icons/fa6";
function Ostomycare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="ssimg10">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>Ostomy Care
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Overview</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Stoma Management</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Regular cleaning and care of the stoma to prevent complications.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Bag Changes</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Efficient replacement of ostomy bags with skin protection measures.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Patient Support</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Education on self-care and monitoring for infections.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br/>

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                      </div>
                      </form>
                <br></br>       
                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">Service in Detail</b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Stoma Care</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        We carefully clean the stoma and surrounding area to maintain hygiene and prevent complications.</b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/assicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Bag Management</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Regular changing of the ostomy bag is performed to ensure comfort and functionality.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/assicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Skin Protection</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        We apply protective measures to safeguard the skin around the stoma, minimizing irritation and injury.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-6">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/assicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Infection Monitoring</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Our team diligently monitors for signs of infection or other complications, ensuring prompt intervention if needed.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/patientedu.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Patient Education</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        We empower patients with essential knowledge on self-care techniques, enhancing their confidence and independence.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default Ostomycare;
