import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/authContext";

import ListallProviders from "./ListallProviders";
import ListallRhf from "./ListallRhf";
import { useSearchParams } from "react-router-dom";

import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus, getAuthTokens } from "../../helpers/authFunctions";
import AddSpecialization from "./AddSpecialization";
import AddRhfservice from "./AddRhfservice";
import AdminHeader from "../header/AdminHeader";
import AddQualification from "./AddQualification";
import DisbursementReport from "./DisbursementReport.js";
import AddCoordinator from "./AddCoordinator.js";
import { slide as Menu } from "react-burger-menu";

const AdminDashboard = () => {
  const {
    adminId,
    setAdminId,
    adminEmail,
    setAdminEmail,
    setIdToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    isMobile
  } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });
  const [menuActive, setMenuActive] = useState(false);
  const [showallProviders, setallProviders] = useState(false);
  const [showallRhf, setAllRhf] = useState(false);
  const [showAddSpecialization, setShowAddSpecialization] = useState(false);
  const [showRhfService, setShowRhfService] = useState(false);
  const [showAddQualification, setShowAddQualification] = useState(false);
  const [showDisbursementReport, setShowDisbursementReport] = useState(false);
  const [showAddCoordinator, setShowAddCoordinator] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [existingAdmin, setExistingAdmin] = useState(true);

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  useEffect(() => {
    if (accessToken && refreshToken && adminId) {
      getProfile(accessToken, refreshToken, adminId);
      setallProviders(true);
    }
  }, [accessToken, refreshToken, adminId]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/admin/get-token", "admin")
        .then((data) => {
          console.log(data);
          setAdminId(data.sub);
          setAdminEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("adminEmail", data.email);
          localStorage.setItem("adminId", data.sub);
          getProfile(data.accessToken, data.refreshToken, data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);

  const getProfile = (accessToken, refreshToken, providerId) => {
    getFunction({ id: adminId }, "/admin/get-profile")
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
        });
      })
      .catch((error) => {
        setData({ ...data, email: adminEmail, id: providerId });
        console.log(error);
        setExistingAdmin(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleMenuClick = (section) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setallProviders(section === "allproviders");
      setAllRhf(section === "allrhf");
      setShowAddSpecialization(section === "addspecialization");
      setShowRhfService(section === "addrhfservice");
      setShowAddQualification(section === "addqualification");
      setShowDisbursementReport(section === "disbursementreport");
      setShowAddCoordinator(section === "addcoordinator");
      setIsMenuOpen(false); 
      setMenuActive(false); 
    }, 500); // Simulate loading time
  };

  const renderMenu = () => (
    <div style={{marginTop:"-40px"}}>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("allproviders")}
      >
        Pending providers
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("allrhf")}>
        Pending Rhf
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("addspecialization")}
      >
        Add Specialization
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("addrhfservice")}
      >
        Add Rhf Service
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("addqualification")}
      >
        Add Qualification
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("disbursementreport")}
      >
        Disbursement Report
      </h3>

      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("addcoordinator")}
      >
        Add Coordinator
      </h3>
    </div>
  );

  const renderMenuMobile = () => (
    <Menu
      left
      pageWrapId={"page-wrap"}
      outerContainerId={"root"}
      isOpen={isMenuOpen} // Control the open state
      onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)} // Update state when burger menu opens/closes
      style={{ color: "white" }}
    >
      {renderMenu()}
    </Menu>
  );

  const renderMenuDesktop = () => (
    <div className={`side-menu bg2 prowidth ${menuActive ? "active" : ""}`}>
      {renderMenu()}
    </div>
  );

  return (
    <>
      <AdminHeader />
      {isMobile && renderMenuMobile()}
      <div className="profile" >
        <div className="container-fluid">
        
          <br />
          
          <div className="row">
            
            <div className="col-sm-4">
              <div>
                <img
                  src="../style/img/resp/drprofile.jpg"
                  alt="Profile"
                  className="drprofile providerdrprofile"
                />
                <img
                  src="../style/img/resp/green.png"
                  alt="Profile"
                  className="liveicon providerliveicon"
                />
              </div>
            </div>
            
            <div className="col-sm-4">
              <h3 className="profileln">Admin</h3>
              {/* <p className="profilelive">
                <div className="pgreen profilegreen">&nbsp; live</div>
                Your Profile is live on Respirolab.com
              </p> */}
            </div>
            {/* <div className="col-sm-4">
              <h3 className="prohed prohaed">
                100%
                <p style={{ display: "inline", fontSize: "14px" }}>
                  Profile completed
                </p>
              </h3>
              <div className="progress progressbar">
                <div
                  className="progress-bar probar"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div> */}
          </div>
          <hr />
        </div>
      </div>

      <br />
      <br />

      <div className="d-flex protop">
        {/* <div className="positon">
          <button className="toggle-button" onClick={toggleMenu}>
            ☰
          </button>
        </div> */}
        {/* {isMobile ? renderMenuMobile() : renderMenuDesktop()} */}
        {!isMobile && renderMenuDesktop()}
        <div
          className="container profile-container"
          id="page-wrap"
          style={{ marginTop: "20px" }}
        >
          <>
            {showallProviders && <ListallProviders />}
            {showallRhf && <ListallRhf />}

            {showAddSpecialization && <AddSpecialization />}
            {showRhfService && <AddRhfservice />}
            {showAddQualification && <AddQualification />}
            {showDisbursementReport && <DisbursementReport />}
            {showAddCoordinator && <AddCoordinator />}
          </>
        </div>
      </div>
    </>
  );
};

const LoadingSpinner = () => (
  <>
    <br></br> <br></br> <br></br>
    <div className="loading">
      <svg
        version="1.2"
        height="300"
        width="600"
        xmlns="http://www.w3.org/2000/svg"
        viewport="0 0 60 60"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          id="pulsar"
          stroke="rgba(6, 57, 212, 0.888)"
          fill="none"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
        />
      </svg>
      <center>
        <h5>Loading...</h5>
      </center>
    </div>
  </>
);

export default AdminDashboard;
