import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setFunction } from "../../api/apiFunctions";

function Payment() {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [number, setNumber] = useState("");
  const navigate = useNavigate();

  const handleClick = async () => {
    const data = {
      name,
      amount: parseFloat(amount),
      number,
      // MID: 'MID' + Date.now(),
      transactionId: "T" + Date.now(),
    };

    try {
      const response = await setFunction(data, "/payment/initiate-payment");
      console.log(response);
      if (
        response.success &&
        response.success.result &&
        response.success.result.success === true
      ) {
        window.location.href =
          response.success.result.data.instrumentResponse.redirectInfo.url;
      } else {
        navigate("/Success");
      }
    } 
    catch (error) {
      console.log(error);
      navigate("/failure");
    }
  };

  return (
    <div>
      <div>
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      </div>

      <div>
        <label>
          Amount:
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </label>
      </div>

      <div>
        <label>
          Phone Number:
          <input
            type="text"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </label>
      </div>
      <button onClick={handleClick}>Pay Now</button>
    </div>
  );
}

export default Payment;
