import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import validator from "validator";
import { Link } from "react-router-dom";
import AdminHeader from "../header/AdminHeader";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus, getAuthTokens } from "../../helpers/authFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";


function Dashboard() {
  const {
    providerId,
    patientId,
    setProviderId,
    providerEmail,
    setProviderEmail,
    setIdToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });
  const [appointment, setAppointment] = useState({
    id: "",
    patientId: "",
    appointmentDate: "",
    startTime: "",
    status: "",
  });

  //const [appointment, setAppointment] = useState([]);

  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);
  const tokenRenewPath = "/auth/provider/renew-token";
  const authType = "provider";

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    if (accessToken && refreshToken && providerId) {
      getProfile(accessToken, refreshToken, providerId);
      getAppoinments(accessToken, refreshToken, providerId);
    }
  }, [accessToken, refreshToken, providerId]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/provider/get-token", "provider")
        .then((data) => {
          setProviderId(data.sub);
          setProviderEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("providerEmail", data.email);
          localStorage.setItem("providerId", data.sub);
          getProfile(data.accessToken, data.refreshToken, data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);

  const getProfile = (accessToken, refreshToken, providerId) => {
    getFunction({ id: providerId }, "/provider/get-profile")
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
        });
      })
      .catch((error) => {
        setData({ ...data, email: providerEmail, id: providerId });
        console.log(error);
        setExistingProvider(false);
      });
  };

  // Appointment Details
  const getAppoinments = (accessToken, refreshToken, providerId) => {
    getFunction(
      { id: providerId },
      "appointment/get-appointment-by-provider-id-and-date"
    )
      .then((response) => {
        const responseData = response.success.result[0];
        //const responseData = response.success.result[0];
        setAppointment({
          id: responseData.id,
          patientId: responseData.patient_id,
          appointmentDate: responseData.appointment_date,
          startTime: responseData.start_time,
          status: responseData.status,
        });
        // setAppointment(responseData
        //   id: responseData.id,
        //   patientId: responseData.patient_id,
        //   appointmentDate : responseData.appointment_date,
        //   startTime: responseData.start_time,
        //   status:responseData.status

        // );
      })
      .catch((error) => {
        //setAppointment({ ...data, id: providerId });
        console.log(error);
        //setExistingProvider(false);
      });
  };

  return (
    <>
    <AdminHeader></AdminHeader>

    <div class="grid">
        <aside class="sidenav">
          <div class="container">
            <br></br>
            <br></br>
            <div class="container">
              <div className="logo">
                <Link to="/home">
                  <img src="../style/img/logonav.png" alt="" />
                </Link>
              </div>
            </div>
            <div class="sidenav__profile">
              <div class="sidenav__profile-title text-dark">
                {data.firstName}&nbsp;{data.lastName}
              </div>
            </div>
            
          </div>
        </aside>

        <main class="">
          <br></br>
          <div class="main-header">
            <div class="main-header__intro-wrapper">
              <div class="main-header__welcome">
                <div class="main-header__welcome-title text-light">
                  Welcome,{" "}
                  <strong>
                    {data.firstName}&nbsp;{data.lastName}
                  </strong>
                </div>
                <div class="main-header__welcome-subtitle text-light">
                  How are you today?
                </div>
              </div>
              <div class="quickview">
                <div class="quickview__item">
                  <div class="quickview__item-total">41</div>
                  <div class="quickview__item-description">
                    <i class="far fa-calendar-alt"></i>
                    <span class="text-light">Events</span>
                  </div>
                </div>
                <div class="quickview__item">
                  <div class="quickview__item-total">64</div>
                  <div class="quickview__item-description">
                    <i class="far fa-comment"></i>
                    <span class="text-light">Messages</span>
                  </div>
                </div>
                <div class="quickview__item">
                  <div class="quickview__item-total">27&deg;</div>
                  <div class="quickview__item-description">
                    <i class="fas fa-map-marker-alt"></i>
                    <span class="text-light">Austin</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="main__cards">
            {/* <UpcomingAppointment /> */}
            
           
          </div>
        </main>

        <footer class="footer">
          <p>
            <span class="footer__copyright">&copy;</span> 2018 MTH
          </p>
         
        </footer>
      </div>
    </>
    
  )
}

export default Dashboard