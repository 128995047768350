import React, { useEffect, useState, useContext } from "react";
import { getFunction, updateFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import moment from "moment";
import ProviderHeader from "../header/ProviderHeader";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withProviderAuth from "../../middleware/withProviderAuth";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
  faCakeCandles,
  faMarsAndVenus,
  faEnvelopesBulk,
  faPhone,
  faMailBulk,
  faLocationDot,
  faLayerGroup,
  faMap,
  faGear,
  faEarth,
  faCalendarDays,
  faClock,
  faCreditCard,
  faMoneyCheckDollar,
  faUser,
  faBarsProgress,
} from "@fortawesome/free-solid-svg-icons";

function Patientdetails() {
  const { providerId } = useContext(AuthContext);
  // const { id } = useParams();
  const [data, setData] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [address, setAddress] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [notes, setNotes] = useState([]);
  const [showNotesModal, setShowNotesModal] = useState(false);

  const [appointments, setAppointments] = useState();
  const [prevAppointments, setPrevAppointments] = useState();
  const [show, setShow] = useState(false);
  const [note, setNote] = useState({});
  const [newNote, setNewNote] = useState();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const updateAppointmentNotes = () => {
    console.log(note);
    if (newNote) {
      const timeStamp = moment().format("DD-MM-yyyy hh:mm A");
      updateFunction(
        { id: note.id, notes: timeStamp + ": " + newNote },
        "/appointment/update-appointment-notes"
      )
        .then((response) => {
          console.log(response.success);
          getAllAppointments();
          setNewNote();
          handleClose();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          console.log(error);
          setNewNote();
          handleClose();
        });
    }
    else {
      handleClose();
    }
  };

  const updateAppointmentStatus = (id, status) => {
    updateFunction({ id, status }, "/appointment/update-appointment")
      .then((response) => {
        console.log(response.success);
        getAllAppointments();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedAppointment) {
      const patientId = selectedAppointment.patient_id;
      const appointmentId = selectedAppointment.appointment_id;
      getFunction({ id: patientId }, "/provider/get-patient-profile")
        .then((response) => {
          const responseData = response.success.result[0];
          //console.log(response.data);
          setData(responseData);
        })

        .catch((error) => {
          console.log(error);
        });
      getFunction(
        { patientId, providerId, appointmentId },
        "/patient/get-appointments-and-address"
      )
        .then((response) => {
          const responseData = response.success.result[0];
          setAddress(responseData);
        })
        .catch((error) => {
          console.log(error);
        });
      getFunction(
        { patientId, providerId },
        "/provider/get-patient-appointment-history"
      )
        .then((response) => {
          setPrevAppointments(response.success.result);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedAppointment]);

  const getAllAppointments = () => {
    getFunction({ providerId }, "/provider/get-upcomingappointment-by-day")
      .then((response) => {
        const responseData = response.success.appointments;

        setAppointments(responseData);
        setSelectedAppointment(responseData[0]);
        setNote({
          id: responseData[0].appointment_id,
          notes: responseData[0].notes,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllAppointments();
  }, []);

  //btn click
  const retrieveDocuments = () => {
    // alert('Button clicked!');
    const patientId = selectedAppointment.patient_id;
    if (patientId && providerId && address.appointment_date) {
      getFunction(
        { patientId, providerId, startDate: address.appointment_date },
        "/patientFileUpload/view-patientFile-forProvider"
      )
        .then((response) => {
          const responseData = response.success.result;
          setDocuments(responseData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  
  const retrieveNotes = () => {
    const patientId = selectedAppointment.patient_id;
    if (patientId) {
      getFunction({ patientId }, "/appointment/get-note-history")
        .then((response) => {
          const responseData = response.success.result;
          if (responseData && responseData.length > 0) {
            const notesGroupedByProvider = responseData.map(provider => ({
              providerName: `${provider.first_name} ${provider.last_name}`,
              notes: provider.notes ? provider.notes.split("\n") : []
            }));
            setNotes(notesGroupedByProvider);
            setShowNotesModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>

   
      <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)} animation={false}>
  <Modal.Header closeButton>
    <Modal.Title>Notes</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {notes.length > 0 &&
      notes.map((providerNotes, providerIndex) => (
        providerNotes.notes.length > 0 && (
          <div key={providerIndex} style={{ marginBottom: "20px" }}>
            <h5>Doctor: {providerNotes.providerName}</h5>
            {providerNotes.notes.map((note, noteIndex) => (
              <div key={noteIndex} style={{ marginBottom: "10px" }}>
                <p>{note}</p>
              </div>
            ))}
          </div>
        )
      ))}
  </Modal.Body>
  <Modal.Footer>
    <button onClick={() => setShowNotesModal(false)}>Close</button>
  </Modal.Footer>
</Modal>

     
      

      <Modal show={show} onHide={handleClose}  animation={false}>

        <Modal.Header>
          <Modal.Title>Appointment Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Previous Note:</h5>
          <textarea rows="4" cols="50" disabled={true}>
            {note.notes}
          </textarea>
          <h5>Add New Note:</h5>
          <textarea
            rows="4"
            cols="50"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={updateAppointmentNotes}>Save Changes</button>
          <button onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
      {/* <ProviderHeader /> */}
      <div className="d-flex">
        <div class="col-sm-3">
          <div className="responsive-queue">
            <div
              class="pcard"
              style={{
                maxHeight: "80%",
                minHeight: "60%",
                overflow: "auto",
                paddingBottom: "20px",
                width: "90%",
              }}
            >
              <p className="pcard-header">Patient Queue</p>
              <div className="d-flex">
                <div className="pqcard-responsive">
                  {appointments &&
                    appointments.map((item) => (
                      <div
                        className="pcard pqcard pqcard-mobile"
                        style={
                          selectedAppointment.appointment_id ===
                          item.appointment_id
                            ? { border: "1px solid #2196f3" }
                            : {  }
                        }
                        // style={item.status === 'Completed' ? {maxWidth: "80%", border: '1px solid green'} : {maxWidth: "80%", border: '1px solid red'}}
                        onClick={() => {
                          setSelectedAppointment(item);
                          setNote({
                            id: item.appointment_id,
                            notes: item.notes,
                          });
                        }}
                        key={item.appointment_id}
                      >
                        <div className="d-flex pqcard-mobile">
                          <FontAwesomeIcon
                            icon={faUser}
                            className="plaintext"
                            style={{ color: "#204ea0" }}
                          />
                          <span className="boldtext">{item.patient_name}</span>
                        </div>
                        <div className="d-flex pqcard-mobile">
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            className="plaintext"
                            style={{ color: "#204ea0" }}
                          />
                          <span className="boldtext">
                            {moment(item.appointment_date).format("DD-MM-yyyy")}
                          </span>
                        </div>
                        <div className="d-flex pqcard-mobile">
                          <FontAwesomeIcon
                            icon={faClock}
                            className="plaintext"
                            style={{ color: "#204ea0" }}
                          />
                          <span className="boldtext">
                            {moment(item.start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </span>
                        </div>
                        <div
                          className="d-flex pqcard-mobile"
                          style={{ marginBottom: "10px" }}
                        >
                          <FontAwesomeIcon
                            icon={faBarsProgress}
                            className="plaintext"
                            style={{ color: "#204ea0" }}
                          />
                          <span
                            className="boldtext"
                            style={
                              item.status === "Completed"
                                ? { background: "green", color: "white" }
                                : { background: "red", color: "white" }
                            }
                          >
                            {item.status}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="pcard  responsive-queue"
          style={{
            width: "90%",
            marginBottom: "200px",
            maxHeight: "50% !important",
          }}
        >
          <div class="">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <h5>
                {data.first_name}&nbsp;{data.last_name}
              </h5>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{ all: "initial", fontWeight: "800" }}
                  >
                    <FontAwesomeIcon icon={faGear} className="iconstyle" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleShow}>
                      Add Notes
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        updateAppointmentStatus(
                          selectedAppointment.appointment_id,
                          "Completed"
                        )
                      }
                      disabled={
                        selectedAppointment &&
                        selectedAppointment.status !== "Booked"
                      }
                    >
                      Mark as Complete
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        updateAppointmentStatus(
                          selectedAppointment.appointment_id,
                          "No Show"
                        )
                      }
                      disabled={
                        selectedAppointment &&
                        selectedAppointment.status !== "Booked"
                      }
                    >
                      Mark as No Show
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        updateAppointmentStatus(
                          selectedAppointment.appointment_id,
                          "Booked"
                        )
                      }
                      disabled={
                        selectedAppointment &&
                        selectedAppointment.status === "Booked"
                      }
                    >
                      Revert Status
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <div class="d-flex" style={{ justifyContent: "space-between" }}>
              <div class="col-sm-3 ">
                <div class="pcard">
                  <p className="pcard-header">Basic Information</p>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faMarsAndVenus}
                        className="plaintext"
                      />
                      <span className="plaintext">Gender</span>
                      <p class="boldtext">{data.gender}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCakeCandles}
                        className="plaintext"
                      />
                      <span className="plaintext">Birthday</span>
                      <p class="boldtext">{data.dob}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faPhone} className="plaintext" />
                      <span className="plaintext">Phone</span>
                      <p class="boldtext">{data.phone}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faMailBulk}
                        className="plaintext"
                      />
                      <span className="plaintext">Email</span>
                      <p class="boldtext">{data.email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="pcard">
                  <p className="pcard-header">Address Information</p>
                  <div class="row">
                    <div class="col-sm-6">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="plaintext"
                      />
                      <span className="plaintext">Addresline 1</span>
                      <p class="boldtext">{address.address_line_1}</p>
                      <br></br>
                      <FontAwesomeIcon
                        icon={faLayerGroup}
                        className="plaintext"
                      />
                      <span class="plaintext">Place</span>
                      <p class="boldtext">{address.locality} </p>

                      <br></br>
                      <FontAwesomeIcon icon={faEarth} className="plaintext" />
                      <span class="plaintext">State </span>
                      <p class="boldtext">{address.state}</p>
                    </div>
                    <div class="col-sm-6">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="plaintext"
                      />
                      <span class="plaintext">Addressline2</span>
                      <p class="boldtext">{address.address_line_2} </p>
                      <br></br>
                      <FontAwesomeIcon icon={faMap} className="plaintext" />
                      <span class="plaintext">District </span>
                      <p class="boldtext">{address.districtname} </p>
                      <br></br>
                      <FontAwesomeIcon
                        icon={faEnvelopesBulk}
                        className="plaintext"
                      />
                      <span class="plaintext">Pin Code </span>
                      <p class="boldtext">{address.pincode} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 ">
                <div class="pcard">
                  <p className="pcard-header">Appointment</p>
                  <div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        className="plaintext"
                      />
                      <span className="plaintext">Date</span>
                      <p class="boldtext">
                        {moment(address.appointment_date).format("DD-MM-yyyy")}
                      </p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClock} className="plaintext" />
                      <span className="plaintext">Time</span>
                      <p class="boldtext">
                        {moment(address.start_time, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                      </p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="plaintext"
                      />
                      <span className="plaintext">Payment Due</span>
                      <p class="boldtext">{address.payment_due}</p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faMoneyCheckDollar}
                        className="plaintext"
                      />
                      <span className="plaintext">Payment Status</span>
                      <p class="boldtext">{address.payment_status}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div class="col-sm-6">
                <div class="pcard">
                  <p className="pcard-header">Previous Appointments</p>

                  {prevAppointments &&
                    prevAppointments.map((item) => (
                      <div
                        className="pcard"
                        style={{ marginRight: "20px", marginBottom: "20px", maxWidth: "95%"}}
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            className="plaintext"
                          />
                          
                          <span class="boldtext">
                            {moment(item.appointment_date).format("DD-MM-yyyy")}
                          </span>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon
                            icon={faClock}
                            className="plaintext"
                          />
                          
                          <span class="boldtext">
                            {moment(item.start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <center style={{ marginTop: "20px" }}>
              <button className="custom-btn1 btn-4" onClick={retrieveDocuments}>
                <span>Retrieve Documents</span>
              </button>
            </center>
            <center style={{ marginTop: "20px" }}>
              <button className="custom-btn1 btn-4" onClick={retrieveNotes}>
                <span>Retrieve Notes</span>
              </button>
            </center>
            <br />
            <center>
              {documents &&
                documents.map((item) => (
                  <ol type="1" style={{ justifyContent: "center" }}>
                    <a href={item.url} target="_blank">
                      <li class="list_doc" style={{ textAlign: "center" }}>
                        •&nbsp;{item.file_name}
                      </li>
                    </a>
                  </ol>
                ))}
            </center>
            
          </div>

          <br></br>
        </div>
      </div>
    </>
  );
}

export default withProviderAuth(Patientdetails);
