import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function Regrhf() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="regbg4">
        <div className="container">
          <div class="hero__title-wrapper">
            <div class="hero__title-large">Hello!</div>
            <div class="hero__title-small">
              Welcome to Rapid Heal Force (RHF): India's Leading Healthcare
              Platform for Respiratory Care!
            </div>
            {/* <div class="hero__title-small">Welcome to Respiro - India's Premier Cloud Healthcare Platform for Respiratory Care</div> */}
          </div>

          <div class="container">
            <div class="hero__description">
              At RHF, we stand as a beacon of innovation and inclusivity in the
              field of respiratory healthcare. With a vision to provide
              comprehensive and affordable lung care for all, we have gathered a
              network of over 25,000 esteemed healthcare Chest Physicians,
              including 2,000 pulmonologists, 3,000 pediatricians, 1 lakh Rapid
              Heal Force (RHF) and a team of healthcare experts. From
              Kanyakumari to Kashmir, RHF is dedicated to serving individuals
              with excellence and compassion.
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>

      <section id="features" class="features1">
        <div class="features__wrapper">
          <div class="section-header">
            <div class="section-header__title  section-header__title--features">
              Who is RHF?
            </div>
            <div class="section-header__subtitle">
              RHF is a nurse who is dedicated to providing essential respiratory
              care services directly to individuals in their homes. With a focus
              on addressing respiratory health disparities and positively
              impacting lives, RHF delivers personalized care to underserved
              communities across India<br></br>
            </div>
            <div class="section-header__title  section-header__title--features">
              RHF Benefits:
            </div>

            <div class="section-header__subtitle">
              Comprehensive Training Programs: We offer rigorous training
              programs to ensure proficiency in respiratory care, empowering
              nurses with the skills and knowledge necessary to deliver
              exceptional care.<br></br>
              Dedicated Marketing Support: Our dedicated social media marketing
              support and extensive offline advertising exposure ensure that our
              nurses effectively reach potential clients.<br></br>
              Professional Setup Assistance: We assist in branding office setup
              to maintain a professional image, ensuring a conducive environment
              for delivering quality care.<br></br>
              Uniforms and Brand Identity: We provide professional uniforms for
              staff, fostering a cohesive brand identity and instilling
              confidence in our services.<br></br>
              Financial Opportunities: Nurses have the opportunity to earn
              projected earnings ranging from 40k to 1 lakh per month based on
              performance, with the option to participate in a revenue-sharing
              model for sustained growth and profitability.<br></br>
              Independent Work Style: Nurses have the opportunity to work
              independently with freedom. There is no work pressure, providing
              nurses the opportunity to work in a stress-free atmosphere..
              <br></br>
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--features">
              Technical Support:
            </div>
            <div class="section-header__subtitle">
              ➢ Online Booking Support: Seamless online booking system for
              patient appointments.<br></br>➢ Staff Tracking System: Efficient
              system for tracking staff schedules and assignments.<br></br>➢
              Patient Records Storage: Secure online storage for patient
              records.<br></br>➢ Appointment Scheduling: Easy appointment
              scheduling for patients and staff.<br></br>➢ Billing and Financial
              Management: Streamlined billing and financial management
              solutions.<br></br>➢ Emergency Assistance Button: Quick access to
              emergency assistance for patients.<br></br>➢ Patient Portal:
              Interactive patient portal for accessing medical records and
              information.<br></br>➢ Appointment Reminders: Automated
              appointment reminders for patients.<br></br>➢ Feedback and Rating
              System: Feedback mechanism for continuous improvement.<br></br>➢
              Voice-Enabled Assistance: Voice-enabled assistance for easy
              navigation and support.<br></br>
            </div>
            <br></br>
          </div>
        </div>
      </section>

      <section id="realizations" class="realizations">
        <div class="realizations__wrapper">
          <div class="section-header">
            <div class="section-header__title  section-header__title--realizations">
              Social Impact:
            </div>
            <div class="section-header__subtitle">
              Nurses find fulfillment in contributing to society's well-being by
              addressing respiratory health disparities and positively impacting
              lives through essential respiratory care services to underserved
              communities.<br></br>
              RHF supports research initiatives aimed at advancing respiratory
              healthcare and improving treatment outcomes, ensuring that nurses
              are at the forefront of innovation and progress.<br></br>
              Join Rapid Heal Force (RHF) and embark on a transformative journey
              to shape the future of respiratory healthcare in India. Contact us
              today to explore franchise opportunities and make a difference in
              the lives of patients across the nation!
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--realizations">
              Create your free RHF profile in 3 simple steps
            </div>
            <div class="section-header__subtitle">
              ➢ Register or find yourself on respirolabs.com by entering your
              name, email id, mobile number, and adding your profile
              information.<br></br>➢ Fill in details about yourself and your
              nursing practice, including your professional certifications, work
              timings, and more.<br></br>➢ Help us verify your details by
              completing our simple online verification process, and you'll be
              ready to go live and start providing essential respiratory care
              services to patients in need!
            </div>
            <br></br>
          </div>
        </div>
      </section>

      <div class="content">
        <div class="reg1bg1">
          <div class="experience__wrapper">
            <div class="">
              <center>
                {" "}
                <div class="btn btn-infy" onClick={handleOpen}>
                  Registration
                </div>
              </center>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  animation={false}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      ...style,
                      maxHeight: "80vh", // Set the maximum height of the modal content
                      overflowY: "auto", // Enable vertical scrolling if content exceeds the height
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Registration<hr></hr>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div class="">
                        <label class="contact__label text-dark">Name</label>

                        <input
                          id="sender_email"
                          class="form-control"
                          type="Text"
                          placeholder="Name"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">Location</label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder="City/Town"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">
                          {" "}
                          Email Address
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="email"
                          placeholder=" Email Address"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Contact Number
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder="+91"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Current Job Title
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder=""
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Years of Experience7. Nursing Specialization (if any):
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder=""
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Nursing Specialization :
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder="(if any)"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Current Workplace
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder=""
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Why do you want to join the Rapid Heal Force (RHF)
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder=""
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Additional Information
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder=""
                          required
                        />
                      </div>

                      <br></br>

                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            Upload Your detailed Profile (PDF format)
                          </span>
                        </div>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="inputGroupFile01"
                          />
                          <label
                            class="custom-file-label"
                            for="inputGroupFile01"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                      <br></br>

                      <center>
                        {" "}
                        <div class="btn btn-infy1">Registration</div>
                      </center>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <section id="features" class="features1">
          <div class="features__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--features">
                Services Offered by Your Rapid Heal Force (RHF):
              </div>
              <div class="section-header__subtitle">
                ➔ Emergency care@ home<br></br>➔ Routine medical check-up
                <br></br>➔ Sleep study<br></br>➔ Pulmonary function test
                <br></br>➔ COPD patient care<br></br>➔ Chest physiotherapy
                <br></br>➔ Pneumonia pediatric care<br></br>➔ Doctor
                consultation@home<br></br>➔ Providing oxygen concentrator, home
                ventilator, medical bed, wheelchair, ambulance, diabetic food
                <br></br>➔ Consult with concerned doctor through video call
                about patient conditions and provide the care properly<br></br>➔
                Provide emotional support
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact">
          <div class="contact__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--contact">
                Terms and Conditions:
              </div>
              <div class="section-header__subtitle">
                By submitting this form, you agree to abide by the policies and
                guidelines of Respiro Care Units. Respiro reserves the right to
                verify the information provided in this registration form.
                <br></br>
                Contact Information:<br></br>
                For inquiries or assistance with the registration process,
                please contact us at:<br></br>
                Email: business.respiro@gmail.com<br></br>
                Phone: +919645035537
              </div>
            </div>
            <div class="container">
              <div class="contact__icons-wrapper">
                <div class="contact__icon">
                  <a
                    target="_blank"
                    href="mailto:business.respiro@gmail.com"
                    rel="noreferrer"
                  >
                    <img
                      src="./style/img/banner/lablogo.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">business.respiro@gmail.com</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/logonav.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">@instagram</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/petro.png"
                      class="logoreg1"
                    ></img>
                  </a>
                </div>
              </div>
            </div>

            {/* <div class="contact__spacer">&nbsp;</div> */}

            {/* <form class="contact__form">

      <div class="contact__textarea-wrapper">
        <label for="message_content" class="contact__label">Message</label>
        <div class="contact__response-ajax-text">&nbsp;</div>
        <textarea id="message_content" rows="10" placeholder="Hello! Would you be interested in collaboration for the greatest project ever?" required></textarea>
      </div>

      <div class="contact__bottom-wrapper">
        <div class="contact__sender-info-wrapper">
          <div class="contact__input-wrapper  contact__input-wrapper--name">
            <label for="sender_name" class="contact__label">Your Name</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_name" type="text" placeholder="John Kowalski" required/>
          </div>
          <div class="contact__input-wrapper  contact__input-wrapper--email">
            <label for="sender_email" class="contact__label">Your E-mail</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_email" type="email" placeholder="john@example.com" required/>
          </div>
        </div>

        <div class="contact__button-wrapper  ghost-button  ghost-button--contact">
          <div class="contact__paper-plane-wrapper"><i class="fa fa-paper-plane"></i></div>
          <div class="contact__response  contact__response--success">
            <div class="contact__response-title"><i class="fa fa-check"></i> Message was sent.</div>
            <div class="contact__response-description  contact__response-description--success">&nbsp;</div>
          </div>
          <div class="contact__response  contact__response--error">
            <div class="contact__response-title"><i class="fa fa-warning"></i> Error.</div>
            <div class="contact__response-description  contact__response-description--error">Message couldn't be sent.</div>
          </div>
          <input id="contact__submit" type="button" value=" "/>
        </div>
      </div>
    </form> */}
          </div>
        </section>
      </div>
    </>
  );
}

export default Regrhf;
