import React, { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegEdit } from "react-icons/fa";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withPatientAuth from "../../middleware/withPatientAuth";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function Address() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingAddress, setExistingAddress] = useState(true);
  const [editView, setEditView] = useState(false);
  const { patientId, accessToken, refreshToken, isMobile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    patientId: patientId || "",
    addressType: "patient",
    addressLine1: "",
    addressLine2: "",
    place: "",
    district: "",
    state: "",
    pincode: "",
    localityId: "",
  });

  const [loc, setLoc] = useState([]);

  // useEffect(() => {
  //   setData((prevData) => ({
  //     ...prevData,
  //     patientId,
  //     addressType:"patient",
  //   }));

  useEffect(() => {
    if (!patientId) return;
    setData((prevData) => ({ ...prevData, patientId }));

    getFunction(
      { id: patientId, addressType: "patient" },
      "/address/get-address"
    )
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          addressType: "patient",
          patientId: responseData.patient_id,
          addressLine1: responseData.address_line_1,
          addressLine2: responseData.address_line_2,
          place: responseData.locality,
          district: responseData.district,
          state: responseData.state,
          pincode: responseData.pincode.toString(),
          localityId: responseData.locality_id,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setExistingAddress(false);
        setLoading(false);
      });
  }, [patientId, accessToken]);

  useEffect(() => {
    if (data.pincode.length < 6) {
      setLoc([]);
      setData({ ...data, place: "", district: "", state: "" });
    } else {
      getLocations(data.pincode);
    }
  }, [data.pincode]);

  useEffect(() => {
    const selectedLoc = loc.filter((item) => item.locality === data.place);
    console.log("selectedLoc", selectedLoc);
    if (selectedLoc.length > 0) {
      setData({
        ...data,
        district: selectedLoc[0].districtname,
        state: selectedLoc[0].state,
        localityId: selectedLoc[0].id,
      });
    }
  }, [data.place]);

  const getLocations = async (pincode) => {
    try {
      const response = await getFunction(
        { pincode },
        "/locality/get-location-by-pincode"
      );
      const responseData = response.success.result;
      setLoc(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const regex = new RegExp(/^[\w\s,.-]*$/gm);
    const pinRegex = new RegExp(/^[1-9]\d{2}\s?\d{3}$/);
    if (fieldName === "addressLine1" && !regex.test(fieldValue)) {
      setErrors({ addressLine1: "Invalid Address Line 1" });
    } else if (fieldName === "addressLine2" && !regex.test(fieldValue)) {
      setErrors({ addressLine2: "Invalid Address Line 2" });
    } else if (fieldName === "pincode" && fieldValue.length === 6) {
      getLocations(fieldValue);
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.addressLine1.trim()) {
      newErrors.addressLine1 = "Address line 1 is required";
      valid = false;
    }
    if (!data.place.trim()) {
      newErrors.place = "Place is required";
      valid = false;
    }
    if (!data.state.trim()) {
      newErrors.state = "State is required";
      valid = false;
    }
    if (!data.pincode.trim()) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    // if (!data.district.trim()) {
    //   newErrors.district = "District is required";
    //   valid = false;
    // }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFunction(data, "/address/set-address")
        .then((response) => {
          toast.success('Address Information added successfully');
          setEditView(false);
          setExistingAddress(true);
          setData({ ...data, id: response.success.id });
          console.log(response.data);

        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            alert(error.response.data.error);
            toast.error(error.response.data.error);
          }
          console.log(error);


          // setRegisterStatus("An error occurred while submitting the form. Please try again later."

          // );


        });
    }
  };

  const updatePatientAddress = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    updateFunction(data, "/address/update-address")
      .then((response) => {
        toast.success('Address updated  successfully');
        setEditView(false);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        }
        console.log(error);

        // setRegisterStatus(
        //   "An error occurred while submitting the form. Please try again later."
        // );
      });
  };

  const addressButtonHandler = (e) => {
    setEditView(true);
    if (editView) {
      if (existingAddress) {
        updatePatientAddress(e);
      } else {
        handleSubmit(e);
      }
    }
  };
  const desktopView = () => (
    <>
      <div className="col-sm-5 serviceinfo1">
        <h2 className="citext topfrom">Address line 1 *</h2>
        <input
          disabled={editView ? false : true}
          type="text"
          name="addressLine1"
          tabIndex={1}
          onChange={changeHandler}
          value={data.addressLine1}
          placeholder="Address Line 1"
          className="form-input textbox"
        />
        {errors.addressLine1 && (
          <div className="validation">{errors.addressLine1}</div>
        )}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Pincode *</h2>
        <input
          disabled={editView ? false : true}
          type="text"
          name="pincode"
          onChange={changeHandler}
          value={data.pincode}
          placeholder="Pincode"
          className="form-input textbox"
        />
        {errors.pincode && <div className="validation">{errors.pincode}</div>}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">District *</h2>
        <input
          disabled={true}
          type="text"
          name="district"
          value={data.district}
          placeholder="District"
          className="form-input textbox"
        />
        {errors.district && <div className="validation">{errors.district}</div>}
        <br></br>
        <br></br>
      </div>
      <div className="col-sm-5">
        <h2 className="citext topfrom">Address line 2 *</h2>
        <input
          disabled={editView ? false : true}
          type="text"
          name="addressLine2"
          tabIndex={2}
          onChange={changeHandler}
          value={data.addressLine2}
          placeholder="Address Line 2"
          className="form-input textbox"
        />
        {errors.addressLine2 && (
          <div className="validation">{errors.addressLine2}</div>
        )}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Place *</h2>
        <select
          disabled={editView ? false : true}
          type="text"
          name="place"
          onChange={changeHandler}
          placeholder="Place"
          className="form-input textbox"
        >
          <option value={data.place}>{data.place}</option>
          {loc &&
            loc.map((item) => (
              <option value={item.locality} key={item.id}>
                {item.locality}
              </option>
            ))}
        </select>
        {errors.place && <div className="validation">{errors.place}</div>}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">State *</h2>
        <input
          disabled={true}
          type="text"
          name="state"
          value={data.state}
          placeholder="State"
          className="form-input textbox"
        />
        {errors.state && <div className="validation">{errors.state}</div>}
        <br></br>
        <br></br>
      </div>
      <div className="col-sm-2">
        <h2
          type="button"
          className=" providervisit33"
          onClick={addressButtonHandler}
          style={{ fontSize: "18px", marginTop: "-50px" }}
        >
          {editView ? "Submit " : "Edit "}
          {/* <span className="fa-solid fa-pen"></span> */}
          <span>
            <FaRegEdit />
          </span>
        </h2>
      </div>
    </>
  );
  const mobileView = () => (
    <>
      <div className="col-sm-2">
        <h2
          type="button"
          className=""
          onClick={addressButtonHandler}
          style={{fontSize:"18px",marginLeft:"10px"}}
        >
          {editView ? "Submit " : "Edit "}
          {/* <span className="fa-solid fa-pen"></span> */}
          <span>
            <FaRegEdit />
          </span>
        </h2>
      </div>
      <div className="col-sm-5" style={{marginTop:"10px"}}>
        <h2 className="citext topfrom">Address line 1 *</h2>
        <input
          disabled={editView ? false : true}
          type="text"
          name="addressLine1"
          onChange={changeHandler}
          value={data.addressLine1}
          placeholder="Address Line 1"
          className="form-input textbox"
        />
        {errors.addressLine1 && (
          <div className="validation">{errors.addressLine1}</div>
        )}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Address line 2 *</h2>
        <input
          disabled={editView ? false : true}
          type="text"
          name="addressLine2"
          onChange={changeHandler}
          value={data.addressLine2}
          placeholder="Address Line 2"
          className="form-input textbox"
        />
        {errors.addressLine2 && (
          <div className="validation">{errors.addressLine2}</div>
        )}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Pincode *</h2>
        <input
          disabled={editView ? false : true}
          type="text"
          name="pincode"
          tabIndex={3}
          onChange={changeHandler}
          value={data.pincode}
          placeholder="Pincode"
          className="form-input textbox"
        />
        {errors.pincode && <div className="validation">{errors.pincode}</div>}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Place *</h2>
        <select
          disabled={editView ? false : true}
          type="text"
          name="place"
          tabIndex={4}
          onChange={changeHandler}
          placeholder="Place"
          className="form-input textbox"
        >
          <option value={data.place}>{data.place}</option>
          {loc &&
            loc.map((item) => (
              <option value={item.locality} key={item.id}>
                {item.locality}
              </option>
            ))}
        </select>
        {errors.place && <div className="validation">{errors.place}</div>}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">District *</h2>
        <input
          disabled={true}
          type="text"
          name="district"
          tabIndex={5}
          value={data.district}
          placeholder="District"
          className="form-input textbox"
        />
        {errors.district && <div className="validation">{errors.district}</div>}
        <br></br>
        <br></br>

        <h2 className="citext topfrom">State *</h2>
        <input
          disabled={true}
          type="text"
          name="state"
          tabIndex={6}
          value={data.state}
          placeholder="State"
          className="form-input textbox"
        />
        {errors.state && <div className="validation">{errors.state}</div>}
        <br></br>
        <br></br>
      </div>
    </>
  );

  return (
    <div className="pat-align-address">
      <div className="providerline1">
        <br></br>
        <br></br>
        <br></br>
        <h1 className="profileheading1 providerprofileheading111">
          Address Information
        </h1>
        <br></br>
        <br></br>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <ToastContainer />
            <div className="row">
              {isMobile ? mobileView() : desktopView()}

            </div>
            <br></br>
            <br></br>
            <br></br>
          </>
        )}
      </div>
    </div>
  );
}

export default withPatientAuth(Address);