import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function Reglab() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="regbg3">
        <div className="container">
          <div class="hero__title-wrapper">
            <div class="hero__title-large">Hello!</div>
            <div class="hero__title-small">
              Join Our Network of Respiro Labs Franchise Clinics
            </div>
            <div class="hero__title-small">
              Welcome, Healthcare Professionals!
            </div>
          </div>

          <div class="container">
            <div class="hero__description">
              At Respiro Labs, we are dedicated to bringing advanced respiratory
              diagnostics and research to communities across India. If you own a
              clinic and are interested in offering cutting-edge respiratory
              diagnostic services to your patients, we invite you to join our
              network of Respiro Labs Franchise Clinics and be part of our
              mission to revolutionize respiratory healthcare. About Respiro
              Labs Franchise:<br></br>
              As a Respiro Labs Franchise Clinic, you'll become part of a
              network of clinics offering state-of-the-art respiratory
              diagnostic services. From pulmonary function testing and molecular
              diagnostics to imaging analysis and research collaborations, our
              franchise clinics are committed to providing accurate and timely
              diagnostic solutions to individuals across India. Patients are
              seeking labs like yours!<br></br>
              Millions of patients are actively seeking reliable diagnostic
              services for their respiratory needs. Joining Respiro Labs allows
              you to expand your clinic's offerings and connect with patients
              who are in search of expert diagnostic solutions. Join Our Network
              in 3 Simple Steps:<br></br>
              1. Register your clinic with RespiroLabs.com.<br></br>
              2. Provide your clinic's information, including location, contact
              details, and services offered.<br></br>
              3. Partner with Respiro Labs to access advanced diagnostic
              technologies and training for your staff.
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="reg1bg2">
          <div class="experience__wrapper">
            <div class="">
              <center>
                {" "}
                <div class="btn btn-infy" onClick={handleOpen}>
                  Registration
                </div>
              </center>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  animation={false}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      ...style,
                      maxHeight: "80vh", // Set the maximum height of the modal content
                      overflowY: "auto", // Enable vertical scrolling if content exceeds the height
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Registration<hr></hr>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div class="">
                        <label class="contact__label text-dark">
                          Clinic Name
                        </label>

                        <input
                          id="sender_email"
                          class="form-control"
                          type="Text"
                          placeholder="Clinic Name"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">Location</label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder="City/Town"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">
                          {" "}
                          Email Address
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="email"
                          placeholder=" Email Address"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Contact Number
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder="+91"
                          required
                        />
                      </div>
                      <br></br>

                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Upload</span>
                        </div>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="inputGroupFile01"
                          />
                          <label
                            class="custom-file-label"
                            for="inputGroupFile01"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                      <center>
                        {" "}
                        <div class="btn btn-infy1">Registration</div>
                      </center>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <section id="features" class="features2">
          <div class="features__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--features">
                Join Our Network
              </div>
              <div class="section-header__subtitle">
                By joining our network of Respiro Labs Franchise, you will have
                the opportunity to: Expand your clinic's offerings with advanced
                respiratory diagnostics. Provide accurate and timely diagnostic
                solutions to patients in your community. Collaborate with a
                network of clinics dedicated to improving respiratory
                healthcare. Make a meaningful impact on the respiratory health
                of individuals across India.<br></br>
                <br></br>
                Benefits of Joining Respiro Labs Franchise:<br></br>
                Access to advanced respiratory diagnostic technologies.<br></br>
                Online booking support<br></br>
                Patient records storage online<br></br>
                Training and support for your clinic's staff.<br></br>
                Marketing and promotional support to attract patients.<br></br>
                Patient portal<br></br>
                Appointment reminders<br></br>
                Feedback and rating system<br></br>
                Collaboration opportunities with other franchise clinics and
                respiratory specialists.<br></br>
                Recognition as a trusted provider of respiratory diagnostic
                services.<br></br>
                As a Respiro Lab, you will provide a range of specialized
                services in respiratory care, including:<br></br>
                <br></br>
                Sleep Apnea Testing <br></br>
                Blood Test for Lungs<br></br>
                X-ray and CT Scan Services<br></br>
                Diagnosis and Treatment Planning<br></br>
                Routine Medical Check-Up<br></br>
                Pneumonia Pediatric Care<br></br>
                Asthma Diagnosis and Management<br></br>
                Pulmonary Function Testing<br></br>
                Diabetic Nutrition Consultation<br></br>
                Allergy Testing and Treatment<br></br>
                Smoking Cessation Programs<br></br>
                Respiratory Rehabilitation Programs<br></br>
                Air Quality Assessment and Counseling<br></br>
                Respiratory Health Education and Counseling<br></br>
                Immunization for Respiratory Conditions<br></br>
                Genetic Testing for Respiratory Disorders
              </div>
            </div>
          </div>
        </section>

        <section id="realizations" class="realizations">
          <div class="realizations__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--realizations">
                Investment Details
              </div>
              <div class="section-header__subtitle">
                Choose from investment options ranging from Rs. 20,000, tailored
                to your business aspirations and financial capabilities.
              </div>
            </div>
          </div>
        </section>

        <section id="realizations" class="realizations1">
          <div class="realizations__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--realizations">
                Terms and Conditions
              </div>
              <div class="section-header__subtitle">
                By submitting this form, you agree to abide by the policies and
                guidelines of Respiro Labs. Respiro Labs reserves the right to
                verify the information provided in this registration form.
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact">
          <div class="contact__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--contact">
                Contact Information:
              </div>
              <div class="section-header__subtitle">
                For inquiries or assistance with the registration process,
                please contact us at:<br></br>
                Email: business.respirolabs@gmail.com<br></br>
                Phone: +919645035537
              </div>
            </div>

            <div class="container">
              <div class="contact__icons-wrapper">
                <div class="contact__icon">
                  <a
                    target="_blank"
                    href="mailto:business.respiro@gmail.com"
                    rel="noreferrer"
                  >
                    <img
                      src="./style/img/banner/cliniclogo.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">business.respiro@gmail.com</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/logonav.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">@instagram</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/petro.png"
                      class="logoreg1"
                    ></img>
                  </a>
                </div>
              </div>
            </div>

            {/* <div class="contact__spacer">&nbsp;</div> */}

            {/* <form class="contact__form">

      <div class="contact__textarea-wrapper">
        <label for="message_content" class="contact__label">Message</label>
        <div class="contact__response-ajax-text">&nbsp;</div>
        <textarea id="message_content" rows="10" placeholder="Hello! Would you be interested in collaboration for the greatest project ever?" required></textarea>
      </div>

      <div class="contact__bottom-wrapper">
        <div class="contact__sender-info-wrapper">
          <div class="contact__input-wrapper  contact__input-wrapper--name">
            <label for="sender_name" class="contact__label">Your Name</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_name" type="text" placeholder="John Kowalski" required/>
          </div>
          <div class="contact__input-wrapper  contact__input-wrapper--email">
            <label for="sender_email" class="contact__label">Your E-mail</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_email" type="email" placeholder="john@example.com" required/>
          </div>
        </div>

        <div class="contact__button-wrapper  ghost-button  ghost-button--contact">
          <div class="contact__paper-plane-wrapper"><i class="fa fa-paper-plane"></i></div>
          <div class="contact__response  contact__response--success">
            <div class="contact__response-title"><i class="fa fa-check"></i> Message was sent.</div>
            <div class="contact__response-description  contact__response-description--success">&nbsp;</div>
          </div>
          <div class="contact__response  contact__response--error">
            <div class="contact__response-title"><i class="fa fa-warning"></i> Error.</div>
            <div class="contact__response-description  contact__response-description--error">Message couldn't be sent.</div>
          </div>
          <input id="contact__submit" type="button" value=" "/>
        </div>
      </div>
    </form> */}
          </div>
        </section>
      </div>
    </>
  );
}

export default Reglab;
