import React from "react";
import Navbar from "./Navbar";
import Nav1 from "./Nav1";
import Nav2 from "./Nav2";
import Fnav from "./Fnav";
import Snav from "./Snav";
function Header() {
  return (
    <>
      <Fnav></Fnav>
      <Snav></Snav>

      {/* <Nav1></Nav1>
      <Navbar></Navbar> */}
    </>
  );
}

export default Header;
