import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import withPatientAuth from "../../middleware/withPatientAuth";
import { updateFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";

function SlotConfirmation() {
  //   const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [hospital, setHospital] = useState(
    JSON.parse(localStorage.getItem("selectedHospital"))
  );

  useEffect(() => {
    setData({
      patientId: patientId,
      providerId: localStorage.getItem("appointmentProviderId"),
      providerName: localStorage.getItem("appointmentProviderName"),
      appointmentDate: localStorage.getItem("appointmentDate"),
      startTime: localStorage.getItem("appointmentTime"),
      status: "Booked",
      paymentDue: localStorage.getItem("appointmentFee"),
      paymentStatus: "Completed",
    });
  }, []);

  const { patientId } = useContext(AuthContext);

  useEffect(() => {
    const appointmentId = localStorage.getItem("appointmentId");
    updateFunction({ id: appointmentId }, "/appointment/update-payment-status")
      .then((response) => {
        console.log(response.data);
        localStorage.removeItem("appointmentId");
      })
      .catch((error) => {
        console.log(error.response.data);
        alert(error.response.data.error);
      });
  }, []);

  return (
    <>
      <Header />
      {/*<div class="container fluid">
        <div class="row">
          <div class="col-sm-12">
            <center>
              <h3 style={{ color: "#199fd9" }}>Appointment has been booked!</h3>
            </center>
            <div className="hospital-card" style={{ color: "black" }}>
              <div className="hospital-header">
                Details of Your Appointment:
              </div>
              <div className="hospital-fields">
                Date: {data.appointmentDate}
              </div>
              <div className="hospital-fields">Time: {data.startTime}</div>
              <div className="hospital-fields">
                Doctor's Name: {data.providerName}
              </div>
              <br></br>
              <div className="hospital-header">Hospital Address: </div>
              <div className="hospital-header">{hospital.hospitalName}</div>
              <div className="hospital-fields">
                {hospital.addressline_1}, {hospital.addressline_2}
              </div>
              <div className="hospital-fields">
                {hospital.state}, {hospital.pincode}
              </div>
              <div className="hospital-fields">{hospital.phone}</div>
            </div>
          </div>
        </div>
      </div>*/}
      <div class="container fluid">
        <center>
          <img
            src="../style/img/resp/check-mark.png"
            className="fdicon signicon"
          />
        </center>
        <center>
          <h3 style={{ color: "#199fd9" }}>Appointment has been booked!</h3>
        </center>

        <br></br>
        <br></br>
        <center>
          <div class="appointhed"> <center><h4 class="appointtxt">Details of Your Appointment</h4></center>
          </div>
        </center>
        <br></br>

        <center>
          <p>Date:  {data.appointmentDate} <br></br>
            Time:{data.startTime}<br></br>
            Doctor's Name: {data.providerName}</p>
        </center>
        <br></br>
        <center>
          <div class="appointhed"> <center><h4 class="appointtxt">Hospital Address</h4></center>
          </div>
        </center>
        <br></br>

        <center>
          <p>{hospital.hospitalName}<br></br>
          {hospital.addressline_1}, {hospital.addressline_2}<br></br>
          {hospital.state}, {hospital.pincode}<br></br>
          {hospital.phone}</p>
        </center>

      </div>

    </>
  );
}

export default withPatientAuth(SlotConfirmation);
