import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DATA from "../../data/Data";

const Pneumothorax = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cardItem = (item) => {
    return (
      <div className="col-md-3 mb-4" key={item.id}>
        <div className="card-service1" style={{ width: "18rem" }}>
          <img src={item.img} className="card-img-top" alt={item.title} />
          <div className="card-body">
            <h5 className="card-title">{item.title}</h5>
            <p className="lead">{item.price}</p>
            <p className="">{item.desc}</p>
            <br></br>
            <center>
              <NavLink to={`/products/${item.id}`} className="main_btn1">
                Buy Now
              </NavLink>
            </center>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="container py-5">
        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-around">{DATA.map(cardItem)}</div>
      </div>
    </div>
  );
};

export default Pneumothorax;
