import React, { useEffect } from 'react';


const Success = ({ redirectUrl = 'http://localhost:3000' }) => {
  useEffect(() => {
    if (redirectUrl) {
      setTimeout(() => {
        // window.location = redirectUrl;
      }, );
    }
  }, [redirectUrl]);

  return (
    <html>
      <head>
        <title>Payment successful</title>
      </head>
      <body className="page-background">
        <div className="container">
          <div className="heading">
            <span className="tick-container">
              <i className="tick" style={{ color: 'green' }}>&nbsp;</i>
            </span>
            <span>Your payment is successful</span>
          </div>
          <div className="text-container">
            <div>Thank you for your payment. Your transaction was completed successfully.</div>
            <a className="primary-button" href={redirectUrl}>
              Go to Website
            </a>
          </div>
        </div>
      </body>
    </html>
  );
};

export default Success;
