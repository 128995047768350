import { useState, useContext, useEffect } from "react";
import UpcomingAppointment from "./UpcomingAppointment";
import ReportPastappointment from "./ReportPastappointment";
import Revenuebydate from "./Revenuebydate";
import Getappointments from "./Getappointments";
import Revenuebyweek from "./Revenuebyweek";
import withProviderAuth from "../../middleware/withProviderAuth";
import ProviderHeader from "../header/ProviderHeader";


const Report = () => {
 

  const [menuActive, setMenuActive] = useState(false);
  const [showupcomingappointment, setShowupcomingappointment] = useState(false);
  const [showgetappointments, setShowgetappointments] = useState(true);
  const [showpastappointment, setShowpastappointment] = useState(false);
  const [showrevenuebydate, setShowrevenuebydate] = useState(false);
  const [showrevenuebyweek, setShowrevenuebyweek] = useState(false);





  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleMenuClick = (section) => {
    setShowgetappointments(section === 'getappointments');
    setShowupcomingappointment(section === 'upcomingappointment');
    setShowpastappointment(section === 'pastappointment');
    setShowrevenuebydate(section === 'revenuebydate');
    setShowrevenuebyweek(section === 'revenuebyweek');


  };

  return (
    <>
      <ProviderHeader />
      <br /> <br /> <br />

      <div className="d-flex protop">
        <div class="positon">
          <button className="toggle-button" onClick={toggleMenu}>
            ☰
          </button></div>
        <div className={`side-menu bg2 prowidth ${menuActive ? 'active' : ''}`}>
        
          <h3 className="side-menu-item" onClick={() => handleMenuClick('getappointments')}>
            Get Appointments
          </h3>
          <h3 className="side-menu-item" onClick={() => handleMenuClick('upcomingappointment')}>
            Upcoming Appointments
          </h3>

          <h3 className="side-menu-item" onClick={() => handleMenuClick('pastappointment')}>
            Past Appointments
          </h3>
          <h3 className="side-menu-item" onClick={() => handleMenuClick('revenuebydate')}>
            Revenue by Date
          </h3>
          <h3 className="side-menu-item" onClick={() => handleMenuClick('revenuebyweek')}>
            Revenue by Week
          </h3>

        </div>
        <div className="container profile-container">
          {showupcomingappointment && <UpcomingAppointment />}
          {showgetappointments && <Getappointments />}
          {showpastappointment && <ReportPastappointment />}
          {showrevenuebydate && <Revenuebydate />}
          {showrevenuebyweek && <Revenuebyweek />}
        </div>
      </div>
    </>
  );
};

export default withProviderAuth(Report);