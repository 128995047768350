import React, { useEffect, useState, useContext } from "react";
import { getFunction, updateFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";

const LoadingSpinner = () => (
    <div className="loading">
        <svg
            version="1.2"
            height="300"
            width="600"
            xmlns="http://www.w3.org/2000/svg"
            viewport="0 0 60 60"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                id="pulsar"
                stroke="rgba(6, 57, 212, 0.888)"
                fill="none"
                strokeWidth="1"
                strokeLinejoin="round"
                d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
            />
        </svg>
        <center>
            <h5>Loading...</h5>
        </center>
    </div>
);
function UpcomingRhfAppoinments() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { patientId } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const cancelAppointment = (id) => {
        const confirmed = window.confirm('Are you sure you want to cancel your appointment?');
        if (confirmed) {
            // Perform the cancel action
            const data = {
                id,
                statusField: "Cancelled",
            };
            updateFunction(data, "/rhf_booking/update-rhf-booking-status")
                .then((response) => {
                    getUpcomingAppointments();
                    console.log(response.data);
                    
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        alert(error.response.data.error);
                    }
                });

            
        } else {
            console.log('Delete canceled');
        }

        
    };
    useEffect(() => {
        getUpcomingAppointments();
    }, [patientId]);
    const getUpcomingAppointments = () => {
        getFunction(
            { patientId, timeFrame: "upcoming" },
            "/rhf_booking/get-rhf-booking-by-patient-id"
        )
            .then((response) => {
                const responseData = response.success.result;
                setData(responseData);
                setLoading(false); // Add this line to stop loading when data is fetched
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setData([]);
                setLoading(false);
            });
    };




    return (

        <>
            <div className="">
                <h1 className="profileheading1 rhfappointmenthead">Upcoming RHF  Appointments</h1>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <hr></hr>
                        <div className="">
                            <div className="">
                                {data.length === 0 ? (
                                    <div className="card__detail">
                                        <div className="card__source text-bold">
                                            No Upcoming Appointments
                                        </div>
                                    </div>
                                ) : (
                                    <div className="container">
                                        <table class="table-responsive" style={{ marginLeft: "-23px" }}>
                                            <thead>
                                                <th>Unit Number</th>
                                                <th>Member Name</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Payment</th>
                                                <th>Payment Status</th>
                                                <th>Action</th>
                                            </thead>
                                            <tbody>
                                                {data.map((item) => (
                                                    <tr>
                                                        <td>{item.unit_number}</td>

                                                        <td>
                                                            {item.status_field === "Assigned" ? (
                                                                <>
                                                                    Assigned to <span style={{ color: '#204ea0' }}>{item.member_name}</span>
                                                                </>
                                                            ) : (
                                                                item.status_field
                                                            )}
                                                        </td>

                                                        <td>{item.booking_date}</td>
                                                        <td>Rs.&nbsp;{item.booking_time}</td>

                                                        <td>{item.cost}</td>
                                                        <td>{item.payment_status}</td>

                                                        <td onClick={() => cancelAppointment(item.id)}>
                                                            <button>Cancel</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

export default UpcomingRhfAppoinments