import React from 'react'

function MusicianRespiratory() {
  return (
   <>
   <>
      {" "}
      <br></br> <br></br>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class='col-sm-8'>

        <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="d-block w-100 rhfimg vip"
              src="./style/img/banner/music.png"
              alt="First slide"
            />
          </div>
        </div>
      </div>







        </div>
        <div class="col-sm-2"></div>
      </div>
   
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Musician Respiratory Lung Care Health Package</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                    As a musician, your passion for creating beautiful melodies often involves long hours of practice and performance, which can impact your respiratory health. Our specialized Musician Respiratory Health Plan is designed to support you in maintaining optimal lung function and well-being, tailored to address the unique challenges faced by musicians. Here's what our Musician Respiratory Health Plan includes
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Breath Control and Performance Enhancement:</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                   * Provide resources and techniques for improving breath control during performances and practice sessions.<br></br>
                    * Offer guidance on proper breathing techniques to enhance musical expression and endurance.
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Instrument Ergonomics Optimization:</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                        * Collaborate with experienced ergonomists to customize ergonomic solutions for various instruments, prioritizing posture and comfort to minimize respiratory strain and optimize breathing efficiency<br></br>
*  Provide personalized consultations and recommendations on instrument setup and adjustments, ensuring musicians can perform comfortably and sustainably. 
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Indoor Air Quality Enhancement</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                   * Raise awareness about the importance of indoor air quality for musicians' respiratory health<br></br>
                    * Recommend air purifiers and ventilation strategies to reduce exposure to indoor pollutants and allergens in practice and performance spaces
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Stress Management and Performance Anxiety Relief</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                        *  Provide resources and strategies for managing performance anxiety and stress-related breathing difficulties. <br></br>
* Offer relaxation techniques such as breathing exercises, meditation, and mindfulness to promote calmness and focus during performances. 
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Healthy Respiratory Lifestyle Promotion</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                   * Deliver educational materials emphasizing practices that foster respiratory health and overall well-being<br></br>
                    * Offer tailored guidance on nutrition, hydration, and physical activities aimed at optimizing lung function and supporting the unique needs of musicians' respiratory systems
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Enhanced Features</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                        *  In addition to our standard offerings, our Musician Respiratory Health Package includes the following enhanced features:  <br></br>
* Personalized Breathing Assessment <br></br>
*  Access to personalized breathing assessments conducted by respiratory health experts to identify individual needs and tailor recommendations accordingly.<br></br>
*  Performance Venue Air Quality Evaluation<br></br>
*  Evaluation of air quality in performance venues to identify potential respiratory health risks and provide recommendations for mitigatio
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Musician Respiratory Health Package</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                   * Breathing Control and Performance Enhancement<br></br>
                    *  Breath Trainer Device for Wind Instrument Players - ₹1800 (One-time Purchase) <button class="btnclss">Book Now</button><br>
                    </br> * Online Breathing Technique Workshop for Singers - ₹400 (One-time Fee) <button class="btnclss">Bokk Now</button>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Instrument Ergonomics Optimization</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                        *  Musician's Chair with Lumbar Support - ₹5500 <button class="btnclss"> Book Now</button>  <br></br>
* Adjustable Music Stand with Angle Control - ₹3200 <button class="btnclss">Book Now</button> 
 <br></br>
*  Guitar Strap with Breathable Material for Comfort - ₹800 <button class="btnclss">Book Now</button> <br></br>
 </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Indoor Air Quality Enhancement</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                   * HEPA Air Purifier with Noise Reduction Technology for Practice Rooms - ₹4000 <button class="btnclss">Book Now</button><br></br>
                    *   Air-Purifying Plants Specifically Selected for Musicians (Set of 3) - ₹1200  <button class="btnclss">Book Now</button><br>
                    </br> *  Hygrometer and Thermometer Combo for Instrument Room Climate Control - ₹750  <button class="btnclss">Bokk Now</button>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Stress Management and Performance Anxiety Relief</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                    Musician's Meditation App/website Subscription with Breathing Exercises - ₹800/year <button class="btnclss">Book Now</button> <br></br>
* Aromatherapy Diffuser with Essential Oils for Relaxation and Focus - ₹300  <button class="btnclss">Book Now</button> 
 <br></br>
*  Yoga for Musicians Online Classes with Breathing Techniques - ₹900 <button class="btnclss">Book Now</button> <br></br>
 </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Healthy Lifestyle Promotio</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                   * Organic Snack Box Subscription (Monthly) with Nutrient-Rich Snacks - ₹1500/month  <button class="btnclss">Book Now</button><br></br>
                    *  Fitness Tracker Smartwatch with Musician-friendly Features and Lung Health Tracking - ₹3000   <button class="btnclss">Book Now</button><br>
                    </br> *  Personalized Nutrition Consultation Session Tailored to Musicians' Dietary Needs - ₹800   <button class="btnclss">Bokk Now</button>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0">Enhanced Features</font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                    Personalized Breathing Assessment with a Focus on Musicians' Respiratory Needs - ₹1200   <button class="btnclss"> Book Now</button>  <br></br>
* Performance Venue Air Quality Evaluation with Acoustic and Lung Health Considerations - ₹2500  <button class="btnclss">Book Now</button> 
 </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-top-border">
              <div class="container">
                <h3 class="mb-30 title_color">
                  <font color="#204ea0"></font>
                </h3>
                <div class="row">
                  <div class="col-lg-12">
                    <blockquote class="generic-blockquote">
                    Join Us in Prioritizing Respiratory Health for Musicians! Customize your Musician Respiratory Health Package today and elevate your performance while safeguarding your lung health. Contact us now to tailor your plan and embark on a journey to healthier lungs and enhanced musical expression. 
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>










</>
     
  </>
  )
}

export default MusicianRespiratory
