import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function Regdoctor() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="regbg">
        <div className="container">
          <div class="hero__title-wrapper">
            <div class="hero__title-large">Hello Doctors!</div>
            <div class="hero__title-small">
              Join Our Team of Respiro Experts
            </div>
            <div class="hero__title-small">Welcome, </div>
          </div>

          <div class="container">
            <div class="hero__description">
              At Respiro, we are dedicated to providing exceptional respiratory
              care to patients across India. If you are a passionate and skilled
              doctor specializing in respiratory medicine, we invite you to join
              our team of Respiro Experts and be part of our mission to
              revolutionize respiratory healthcare. About Respiro Expert: As a
              Respiro Expert, you'll be part of a team of highly skilled doctors
              and healthcare professionals specializing in respiratory medicine.
              From pulmonologists and pediatric respiratory specialists to chest
              physicians, our experts are committed to delivering personalized
              care and improving the respiratory health of individuals across
              India. Patients are looking for doctors like you! Millions of
              patients are seeking the right doctor for their respiratory needs.
              Start your digital journey with Respiro and reach patients who are
              actively seeking expert care. Create Your Respiro Expert Profile
              in 3 Simple Steps: Register or find yourself on Respiro.com. Enter
              your name, email id, mobile number, and clinic or establishment
              name. Add your profile information, including your medical
              registration, fees, timings, and more.
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="reg1bg">
          <div class="experience__hollow-arrow">&nbsp;</div>
          <div class="experience__wrapper">
            <div class="">
              <center>
                {" "}
                <div class="btn btn-infy" onClick={handleOpen}>
                  Registration
                </div>
              </center>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  animation={false}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      ...style,
                      maxHeight: "80vh", // Set the maximum height of the modal content
                      overflowY: "auto", // Enable vertical scrolling if content exceeds the height
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Registration<hr></hr>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div class="">
                        <label class="contact__label text-dark">
                          Full Name
                        </label>

                        <input
                          id="sender_email"
                          class="form-control"
                          type="Text"
                          placeholder="Full Name"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">
                          Email Address
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="Email"
                          placeholder="john@example.com"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">
                          Contact Number
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder="+91"
                          required
                        />
                      </div>
                      <br></br>
                      <select class="form-control">
                        <option>Specializationt</option>
                        <option>Pulmonology</option>
                        <option>Pediatric Respiratory Medicine</option>
                        <option>Chest Medicine</option>
                      </select>
                      <br></br>

                      <div class="">
                        <label class="contact__label text-dark">
                          Medical License Number
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder="*****"
                          required
                        />
                      </div>
                      <br></br>
                      <label
                        for="sender_email"
                        class="contact__label text-dark"
                      >
                        Years of Experience in Respiratory Medicine
                      </label>
                      <select class="form-control">
                        <option>0-5 years</option>
                        <option>6-10 years, </option>
                        <option>11-15 years</option>
                        <option>16+ years</option>
                      </select>

                      <div class="">
                        <label class="contact__label text-dark">
                          Current Practice
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder="Hospital/Clinic Name"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Why do you want to join Respiro
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder=""
                          required
                        />
                      </div>
                      <br></br>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Upload</span>
                        </div>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="inputGroupFile01"
                          />
                          <label
                            class="custom-file-label"
                            for="inputGroupFile01"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                      <center>
                        {" "}
                        <div class="btn btn-infy1">Registration</div>
                      </center>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <section id="features" class="features">
          <div class="features__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--features">
                Services Offered by Respiro Expert
              </div>
              <div class="section-header__subtitle">
                Our Respiro Expert offers a range of specialized services in
                respiratory care, including: Diagnosis and treatment of
                respiratory conditions Pediatric respiratory care Chest medicine
                consultations Telemedicine appointments for remote access to
                expert care
              </div>
            </div>
          </div>
        </section>

        <section id="realizations" class="realizations">
          <div class="realizations__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--realizations">
                Terms and Conditions
              </div>
              <div class="section-header__subtitle">
                By submitting this form, you agree to abide by the policies and
                guidelines of Respiro. Respiro reserves the right to verify the
                information provided in this registration form.
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact">
          <div class="contact__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--contact">
                Join Our Team
              </div>
              <div class="section-header__subtitle">
                By joining our team of Respiro Experts, you will have the
                opportunity to: Provide expert respiratory care to patients
                across India. Collaborate with a network of healthcare
                professionals dedicated to improving respiratory health. Access
                advanced technology and resources to enhance patient care. Make
                a meaningful impact on the lives of individuals with respiratory
                conditions.<br></br>
                Contact Information:<br></br>
                For inquiries or assistance with the registration process,
                please contact us at:<br></br>
                Email: business.respiro@gmail.com<br></br>
                Phone: +919645035537
              </div>
            </div>

            <div class="container">
              <div class="contact__icons-wrapper">
                <div class="contact__icon">
                  <a target="_blank" href="mailto:business.respiro@gmail.com">
                    <img
                      src="./style/img/banner/thelogo.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">business.respiro@gmail.com</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/logonav.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">@instagram</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/petro.png"
                      class="logoreg1"
                    ></img>
                  </a>
                </div>
              </div>
            </div>

            {/* <div class="contact__spacer">&nbsp;</div> */}

            {/* <form class="contact__form">

      <div class="contact__textarea-wrapper">
        <label for="message_content" class="contact__label">Message</label>
        <div class="contact__response-ajax-text">&nbsp;</div>
        <textarea id="message_content" rows="10" placeholder="Hello! Would you be interested in collaboration for the greatest project ever?" required></textarea>
      </div>

      <div class="contact__bottom-wrapper">
        <div class="contact__sender-info-wrapper">
          <div class="contact__input-wrapper  contact__input-wrapper--name">
            <label for="sender_name" class="contact__label">Your Name</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_name" type="text" placeholder="John Kowalski" required/>
          </div>
          <div class="contact__input-wrapper  contact__input-wrapper--email">
            <label for="sender_email" class="contact__label">Your E-mail</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_email" type="email" placeholder="john@example.com" required/>
          </div>
        </div>

        <div class="contact__button-wrapper  ghost-button  ghost-button--contact">
          <div class="contact__paper-plane-wrapper"><i class="fa fa-paper-plane"></i></div>
          <div class="contact__response  contact__response--success">
            <div class="contact__response-title"><i class="fa fa-check"></i> Message was sent.</div>
            <div class="contact__response-description  contact__response-description--success">&nbsp;</div>
          </div>
          <div class="contact__response  contact__response--error">
            <div class="contact__response-title"><i class="fa fa-warning"></i> Error.</div>
            <div class="contact__response-description  contact__response-description--error">Message couldn't be sent.</div>
          </div>
          <input id="contact__submit" type="button" value=" "/>
        </div>
      </div>
    </form> */}
          </div>
        </section>
      </div>
    </>
  );
}

export default Regdoctor;
