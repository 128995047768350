import React from "react";
import Header from "../header/Header";
import { useState, useEffect } from "react";

function RHFemergencyCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="emimg1">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>RHF - Emergency Care @ Home
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Overview</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Service Description</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Receive immediate medical attention from our dedicated
                          respiratory nurses in the comfort of your home.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Available 24/7</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Access emergency care round the clock.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Customizable Options</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Adjust the duration and price to suit your needs.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                {/* <div className="form-group1 duration">
                        <label >Duration:</label>
                        <div style={{ position: 'relative', width: '200px' }}>
                            <span
                                className="subtraction"
                                onClick={() => handleDurationChange(-1)}
                                style={{
                                    position: 'absolute',
                                    left: 20,
                                    marginTop:"25px",
                                    width: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    fontWeight: "bold"
                                }}
                            >
                                -
                            </span>
                            <input
                                name="duration"
                                value={`${duration} month${duration > 1 ? 's' : ''}`}
                                readOnly
                                style={{ textAlign: 'center', width: '100%', padding: '0 40px' }}
                            />
                            <span
                                className="addition"
                                onClick={() => handleDurationChange(1)}
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    top: 25,
                                    bottom: '0px',
                                    width: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    fontWeight: "bold"
                                }}
                            >
                                +
                            </span>
                        </div>
                    </div><br></br> */}
                <div class="price">
                  <label>Price:</label>
                  <div class="pricebox">
                    <b class="text-dark">₹500 - ₹1,500</b>
                  </div>
                  {/* <input
                            type="number"
                            name="totalPrice"
                            value={totalPrice}
                            readOnly 
                        /> */}
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <br></br>
                <center>
                  <button class="custom-btn1 btn-3 ">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">Service in Detail</b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emergencycare.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Emergency Room (ER) Visit</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Immediate care for acute illnesses or injuries.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/advicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Personalized Attention</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Dedicated respiratory nurses provide tailored care
                          plans specific to your condition and needs.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ambulance.PNG"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Ambulance Services</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                         Transportation to medical facilities in emergencies.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-6">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/urgentcare.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Urgent Care Clinics</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Care for non-life-threatening conditions that require prompt attention.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/kindness.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Paramedic Services</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        On-scene medical assistance provided by trained paramedics.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default RHFemergencyCare;
