import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  updateFunction,
  setFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

function MyRhfAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [memberId, setMemberId] = useState();
  const [unitId, setUnitId] = useState();
  const [selectedDate, setSelectedDate] = useState("%");
  const [selectedStatus, setSelectedStatus] = useState("%");
  const [show, setShow] = useState(false);
  const [note, setNote] = useState({});

  const handleClose = () => {
    setShow(false);
    setNote({});
  };
  const handleShow = (id, notes) => {
    setNote({ id, notes });
    setShow(true);
  };
  const { rhfEmail } = useContext(AuthContext);
  const [data, setData] = useState({
    memberId: "",
    bookingId: "",
  });

  useEffect(() => {
    // Fetch unit members
    getFunction({ email: rhfEmail }, "/rhf_member/get-rhf-member-by-email")
      .then((response) => {
        const responseData = response.success.result;
        setMemberId(responseData[0].id);
        setUnitId(responseData[0].rhf_unit_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rhfEmail]);

  const getAppointments = () => {
    setAppointments([]);
    if (unitId && memberId) {
      getFunction(
        {
          rhfUnitId: unitId,
          memberId,
          date: selectedDate,
          status: selectedStatus,
        },
        "/rhf_booking/get-my-appointments"
      )
        .then((response) => {
          const responseData = response.success.result.map((appointment) => ({
            ...appointment,
          }));
          setAppointments(responseData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    // Fetch today's appointments
    getAppointments();
  }, [unitId, rhfEmail, selectedDate, selectedStatus]);

  const updateAppointmentStatus = (id, status) => {
    let statusField = "";
    if (status === "Assigned") {
      statusField = "Completed";
    } else {
      statusField = "Assigned";
    }
    updateFunction(
      { id, statusField },
      "/rhf_booking/update-rhf-booking-status"
    )
      .then((response) => {
        console.log(response.success);
        getAppointments();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
        console.log(error);
      });
  };

  const updateAppointmentNotes = () => {
    console.log(note);
    if (note.notes) {
      updateFunction(note, "/rhf_booking/update-rhf-booking-note")
        .then((response) => {
          console.log(response.success);

          handleClose();
          getAppointments();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          console.log(error);
          handleClose();
        });
    }
  };

  return (
    <>
      <div className="container">
        <br />
        <h4 style={{ color: "#3131e5" }}>My Tasks</h4>
        <br></br>
        <div className="filter-container">
          <div>
            <span style={{ color: "black" }}>Filter: </span>
            <span
              className="filterbtn"
              style={
                selectedDate === moment().format("yyyy-MM-DD")
                  ? { background: "#204ea0" }
                  : null
              }
              onClick={() => setSelectedDate(moment().format("yyyy-MM-DD"))}
            >
              Today
            </span>{" "}
            &nbsp;
            <span
              className="filterbtn"
              style={
                selectedDate === moment().add(-1, "day").format("yyyy-MM-DD")
                  ? { background: "#204ea0" }
                  : null
              }
              onClick={() =>
                setSelectedDate(moment().add(-1, "day").format("yyyy-MM-DD"))
              }
            >
              Yesterday
            </span>
            &nbsp;&nbsp;
            <input
              type="date"
              onChange={(e) => setSelectedDate(e.target.value)}
              value={selectedDate}
            ></input>
            &nbsp;&nbsp;
            <span
              onClick={() => setSelectedDate("%")}
              className="filterbtn"
              style={selectedDate === "%" ? { background: "#204ea0" } : null}
            >
              All
            </span>
          </div>
          <br></br>
          <div className="status-filters">
            <div>
              <span
                className="filterbtn"
                style={
                  selectedStatus === "Assigned"
                    ? { background: "#204ea0" }
                    : null
                }
                onClick={() => setSelectedStatus("Assigned")}
              >
                Open
              </span>
              &nbsp;&nbsp;
              <span
                className="filterbtn"
                style={
                  selectedStatus === "Completed"
                    ? { background: "#204ea0" }
                    : null
                }
                onClick={() => setSelectedStatus("Completed")}
              >
                Closed
              </span>
              &nbsp;&nbsp;
            </div>
            <div>
              <br></br>
              <span
                onClick={() => {
                  setSelectedDate("%");
                  setSelectedStatus("%");
                }}
                className="filterbtn"
              >
                Clear Filters
              </span>
            </div>
          </div>
        </div>
        <br></br>
        <div style={{ overflowX: "scroll" }}>
          <table className="table">
            <thead>
              <tr>
                <th className="th-sm">S.No</th>
                <th className="th-sm">Patient Name</th>
                <th className="th-sm">Service Name</th>
                <th className="th-sm">Phone</th>
                <th className="th-sm">Booking Date</th>
                <th className="th-sm">Status</th>
                {/* <th className="th-sm">Unit Members</th> */}
                <th className="th-sm">Assigned Member</th>
                <th className="th-sm">Action</th>
                {/* <th className="th-sm">Re-Assign</th> */}
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment, index) => (
                <tr key={appointment.id}>
                  <td>{index + 1}</td>
                  <td>
                    {/* {appointment.first_name}&nbsp;{appointment.last_name} */}
                    {appointment.first_name} &nbsp;{appointment.last_name}
                  </td>
                  <td>{appointment.service_name}</td>
                  <td>{appointment.phone}</td>
                  <td
                    style={
                      appointment.booking_date < moment().format("yyyy-MM-DD")
                        ? { color: "red" }
                        : { color: "green" }
                    }
                  >
                    {appointment.booking_date}
                  </td>
                  <td>{appointment.status_field}</td>
                  <td>{appointment.member_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{ all: "initial", fontWeight: "800" }}
                      >
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{ fontSize: "15px" }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            updateAppointmentStatus(
                              appointment.id,
                              appointment.status_field
                            )
                          }
                        >
                          {appointment.status_field === "Completed"
                            ? "Reopen"
                            : "Close"}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleShow(appointment.id, appointment.notes)
                          }
                        >
                          Add/View Note
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header>
            <Modal.Title>Appointment Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Existing Note:</label>
            <textarea rows="4" cols="50" disabled={true}>
              {note.notes}
            </textarea>
            <label>Add New Note:</label>
            <textarea
              rows="4"
              cols="50"
              onChange={(e) => setNote({ ...note, notes: e.target.value })}
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={updateAppointmentNotes}>Save Changes</button>
            <button onClick={handleClose}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default MyRhfAppointments;
