import React from "react";

function Pneumoiaplan() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <section class="appointment-area">
        <div class="container">
          <div class="appointment-inner">
            <div class="row">
              <div class="col-sm-12 col-lg-5 offset-lg-1">
                <h3>
                  <font color="204ea0">Pnemonia pediatric Care at Home </font>
                </h3>
                <blockquote class="generic-blockquote">
                  <img
                    class="d-block w-100 r"
                    src="./style/img/banner/pnep.jpg"
                    alt="First slide"
                  />
                </blockquote>
              </div>

              <div class="col-lg-6">
                <div class="appointment-form">
                  <h3>
                    <font color="#204ea0">Just Starts 999₹</font>
                  </h3>
                  <blockquote class="generic-blockquote">
                    1. Maintaining Patent Airway Clearance<br></br>
                    2. Improving Gas Exchange<br></br>
                    3. Promoting Effective Breathing Pattern and Breathing
                    Exercises<br></br>
                    4. Administering Medications and Pharmacological Support
                    <br></br>
                    5. Initiating Measures for Infection Control & Management
                    <br></br>
                    6. Managing Acute Pain & Discomfort<br></br>
                    7. Promoting Rest and Improving Tolerance to Activity
                    <br></br>
                    8. Maintaining Normal Body Thermoregulation<br></br>
                    9. Promoting Optimal Nutrition & Fluid Balance<br></br>
                    10. Providing Patient Education & Health Teachings<br></br>
                    11. Monitoring Potential Complications of Pneumonia
                  </blockquote>
                  <center>
                    <a href="/Pulmonaryedema" class="main_btn mb-3 mr-1 ">
                      Add cart{" "}
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonial">
        <div class="container"></div>
        <div class="item">
          <div class="testi_item"></div>
        </div>
        <div class="item"></div>
      </section>
    </>
  );
}

export default Pneumoiaplan;
