import React from "react";
import Header from "../header/Header";
function Company() {
  return (
    <>
      <Header></Header>

      <section class="appointment-area">
        <div class="container">
          <div class="appointment-inner">
            <div class="row">
              <div class="col-sm-12 col-lg-5 offset-lg-1">
                <h3>
                  <font color="204ea0">OUR MISSION</font>
                </h3>
                <blockquote
                  class="generic-blockquote text-dark"
                  style={{ fontFamily: "Times New Roman" }}
                >
                  We aim to provide efficient equipment to our clients, which
                  are technologically superior, yet easy to use and value for
                  money. We aim to position ourselves as a well-accepted service
                  that not only delights but also exceeds customer expectations
                </blockquote>
              </div>

              <div class="col-lg-5">
                <div class="appointment-form">
                  <h3>
                    <font color="#204ea0">OUR VISION</font>
                  </h3>
                  <blockquote
                    class="generic-blockquote text-dark"
                    style={{ fontFamily: "Times New Roman" }}
                  >
                    We have a vision of offering high-quality products at
                    affordable prices by judiciously adhering to the following
                    principles.Reengineering & innovating proven products,
                    Highly effective customer service and backup, Trained,
                    enthusiastic and proactive employees, Constantly listening
                    to our customers and improving on their feedback.
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonial">
        <div class="container"></div>
        <div class="item">
          <div class="testi_item"></div>
        </div>
        <div class="item"></div>
      </section>
      <section class="blog-area area-padding">
        <div class="container">
          <div class="area-heading row"></div>

          <div class="row">
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <img
                    class="img-fluid"
                    src="./style/img/banner/Pulmonologistbannernew.jpg"
                    alt=""
                  />
                </div>
                <div class="short_details">
                  <div
                    class="meta-top d-flex text-dark"
                    style={{ fontFamily: "Times New Roman" }}
                  >
                    A pulmonologist is a doctor who specializes in lung
                    conditions. A pulmonologist diagnoses and treats diseases of
                    the respiratory system. You might hear these healthcare
                    providers called lung doctors, lung specialists, or chest
                    doctors. Pulmonologists treat conditions of the respiratory
                    system, especially of the lungs. These conditions can be
                    caused by things like inflammation, tissue overgrowth, and
                    infections.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <img
                    class="img-fluid"
                    src="./style/img/banner/RTbanner01.jpg"
                    alt=""
                  />
                </div>
                <div class="short_details">
                  <div
                    class="meta-top d-flex text-dark"
                    style={{ fontFamily: "Times New Roman" }}
                  >
                    A respiratory therapist(RT) is a specialized healthcare
                    practitioner trained in critical care and cardio-pulmonary
                    medicine in order to work therapeutically with people who
                    have acute critical conditions, cardiac and pulmonary
                    disease. Respiratory therapists initiate and manage life
                    support for people in intensive care units and emergency
                    departments, stabilizing, treating and managing pre-hospital
                    and hospital-to-hospital patient transport by air or ground
                    ambulance.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <img
                    class="img-fluid"
                    src="./style/img/banner/respirolabsbanner.jpg"
                    alt=""
                  />
                </div>
                <div class="short_details">
                  <div
                    class="meta-top d-flex text-dark"
                    style={{ fontFamily: "Times New Roman" }}
                  >
                    Respiro labs is an advanced diagnostic facility dedicated to
                    accurately assessing and treating lung conditions. we bring
                    advanced techniques and laboratory devices to perform lab
                    procedures to ensure accuracy. Experienced lab technicians
                    and healthcare professionals trained and equipped with the
                    latest technology constitute our backbone.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <img
                    class="img-fluid"
                    src="./style/img/banner/rhf.png"
                    alt=""
                  />
                </div>
                <div class="short_details">
                  <div
                    class="meta-top d-flex text-dark"
                    style={{ fontFamily: "Times New Roman" }}
                  >
                    A rapid heal force (RHF) is a team of health care providers
                    that responds to patients with early signs of deterioration
                    on non-intensive care units to prevent respiratory or
                    cardiac arrest. The health care providers are trained in
                    early resuscitation interventions and advanced life support.
                    The team responds to calls placed by clinicians or families
                    at the bedside who have detected deterioration.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Company;
