import React, { useState, useContext, useEffect } from "react";
import {
  uploadFileFunction,
  setFunction,
  getFunction,
} from "../../api/apiFunctions";
import withPatientAuth from "../../middleware/withPatientAuth";
import { AuthContext } from "../../context/authContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DocumentsTable = () => {
  const { patientId } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUploadError, setFileUploadError] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [data, setData] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(`Selected option: ${event.target.value}`);
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    if (selectedFile && fileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileUploadError(""); // Clear any previous error message
    } else {
      setFile(null);
      setFileName("");
      setFileUploadError("Only PNG, JPG, JPEG, and PDF files are allowed.");
      toast.error("Only PNG, JPG, JPEG, and PDF files are allowed.");
    }
  };

  const uploadDocument = async () => {
    if (!file) {
      setFileUploadError("Please select a file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);

      // Debugging: Check the file inside formData
      console.log("FormData contains:", file);

      // Upload file to the server
      const uploadResponse = await uploadFileFunction(
        formData,
        `/patient/file/upload/?patientId=${patientId}`
      );

      console.log("File upload response:", uploadResponse);

      // Check if the upload was successful
      if (uploadResponse && uploadResponse.success) {
        const documentData = {
          patientId,
          documentName: fileName,
          documentType: `${selectedOption}`,
          url: `${patientId}/${fileName}`,
        };

        // Add document details to the database
        const setResponse = await setFunction(
          documentData,
          "/documents/set-documents"
        );
        console.log("Set document response:", setResponse);

        if (setResponse && setResponse.success) {
          toast.success(
            "File uploaded and added to document table successfully"
          );
          setUploadStatus("File uploaded successfully");
        } else {
          setFileUploadError(
            "An error occurred while saving the document details."
          );
        }
      } else {
        setFileUploadError(
          "An error occurred while saving the document details ."
        );
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Please Select Document Type..");
    }
  };

  return (
    <>
      <ToastContainer />
      <br />
      <br />
      <div className="container">
        <form className="newform">
          <p>Choose Document Type:</p>
          <select value={selectedOption} onChange={handleOptionChange}>
            <option value="" disabled>
              Choose an option
            </option>
            <option>LAB REPORT</option>
            <option>PRESCRIPTION</option>
          </select>
          <div
            className="file-upload-wrapper"
            data-text={fileName || "No file chosen"}
          >
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
            />
          </div>
        </form>
        {file && (
          <button onClick={uploadDocument} style={{ marginTop: "10px" }}>
            Upload File
          </button>
        )}
        {uploadStatus && <p>{uploadStatus}</p>}
        {fileUploadError && <p style={{ color: "red" }}>{fileUploadError}</p>}
      </div>
      <br />
      <br />
    </>
  );
};

export default withPatientAuth(DocumentsTable);
