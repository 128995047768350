import axios from "axios";
import { getTokenFromLocalStorage } from "../helpers/authFunctions";

const baseURL = process.env.REACT_APP_API_URL;

const apiRequest = (method, apiPath, data = null) => {
  return new Promise((resolve, reject) => {
    getTokenFromLocalStorage()
      .then((token) => {        
        axios({
          method: method,
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
          url: baseURL + apiPath,
          data: data,
        })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      })
      .catch((error) => console.log(error));
  });
};

const setFunction = (data, apiPath) => apiRequest("post", apiPath, data);
const getFunction = (data, apiPath) => apiRequest("post", apiPath, data);
const updateFunction = (data, apiPath) => apiRequest("put", apiPath, data);
const deleteFunction = (data, apiPath) => apiRequest("delete", apiPath, data);

const uploadFileFunction = (formData, endpoint) => {
  return new Promise((resolve, reject) => {
    getTokenFromLocalStorage()
      .then((token) => {
        axios.post(baseURL + endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token.access_token}`,
          },
        })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      })
      .catch((error) => console.log(error));
  });
};

export { setFunction, getFunction, updateFunction, deleteFunction, uploadFileFunction };