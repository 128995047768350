import React, { useEffect } from 'react';
import Header from "../header/Header";
import { Link } from "react-router-dom";
import Rhf1 from "./Rhf1";
import Rhfresp from "./Rhfresp";

function Rhf() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="app">
        {window.innerWidth > 768 ? <Rhf1 /> : <Rhfresp />}
      </div>
    </>
  );
}

export default Rhf;
