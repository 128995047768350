import React, { useEffect, useState, useContext } from "react";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import ProviderHeader from "../header/ProviderHeader";

function ReportPastappointment() {
    const { providerId } = useContext(AuthContext);
    const [data, setData] = useState([]);
    
    useEffect(() => {
        getFunction({ providerId }, "/provider/get-pastappointment-by-day")
            .then((response) => {
                const responseData = response.success.appointments;
                setData(responseData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [providerId]);

    return (
        <>
            <div className="contain">
                <div className="providerline">
                    <br /> 
                    <h1 className="providerprofileheading100 profileheading1">Past Appointments</h1>
                    <hr className="providerline" />
                    <div>
            {data.length === 0 ? (
              <h4>No data to display</h4>
            ) : (
              data.map((item) => (
                <div key={item.patient_id}>
                  <h5>Patient Name:</h5>
                  <h4>{item.patient_name}</h4>
                  <h5>Start Time:</h5>
                  <h4>{item.start_time}</h4>
                  &nbsp;
                  &nbsp;
                </div>
              ))
            )}
          </div>
                </div>
            </div>
        </>
    );
}

export default withProviderAuth(ReportPastappointment);
