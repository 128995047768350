import { useRoutes } from "react-router-dom";

import { CoordinatorLogin, CoordinatorSignup } from "../pages/auth/CoordinatorLogin";
import Dashboard from "../pages/coordinator/Dashboard";
import CoordinatorDashboard from "../pages/coordinator/CoordinatorDashboard";
import Viewrhfunit from "../pages/coordinator/Viewrhfunit";


const CoordinatorRoutes = () => {
    let routes = useRoutes([
       
        { path: "/coordinator/login", element: <CoordinatorLogin /> },
         { path: "/coordinator/dash", element: <Dashboard /> },
        { path: "/coordinator/signup", element: <CoordinatorSignup /> },
        { path: "/coordinator/dashboard", element: <CoordinatorDashboard/> },
        { path: "/coordinator/Viewrhfunit/:id", element: <Viewrhfunit/> },



        
      ]);
      return routes;
};

export default CoordinatorRoutes;