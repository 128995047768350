import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import RhfHeader from "../header/RhfHeader";
function RhfJion() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RhfHeader></RhfHeader>
      <div class="rhfbg">
        <div class="container">
          <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
          <center>
            <div class="rhfhedtext text-white rehfresphedtxt">Welcome to RHF</div>
          </center>{" "}
          <br/>
          <center>
            {" "}
            
            <div class="rhfsubtext">
              Revolutionizing Respiratory Care at Home
            </div>
          </center>
        </div>
      </div>
      <div class="bgm2">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 ">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <div class="container rhflandcontent1">
                <p
                  class="text-white"
                  style={{ fontSize: "20px", color: "#fff" }}
                >
                  Welcome to Rapid Heal Force (RHF), where we bring exceptional
                  respiratory care directly to your home. Our team of dedicated
                  respiratory care providers, healthcare professionals, and
                  specialists are committed to providing personalized,
                  high-quality care to ensure the well-being of your loved ones
                </p>
                <Link to="/rhf/login">
                  <button class="rhfbtn1">Sign in </button>
                </Link>{" "}
                &nbsp;
                <Link to="/rhf/signup">
                  {" "}
                  <button class=" rhfbtn2">Sign up</button>
                </Link>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="container">
                <br></br>
                <br></br>
                <img
                  class="d-block  pdoctor pdocrespch "
                  src="../style/img/resp/rhfdoc1.png"
                  alt="Third slide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="container">
              <center>
                <h3 class="mainhedrhfresp">Expert Care at Home</h3>
              </center>
              <br></br>
              <div class="container">
                <div class="row ">
                  <div class="col-sm-3">
                    <img
                      class="fdicon rhfrespicon"
                      src="../style/img/resp/rhfico.png"
                      alt="Third slide"
                    />
                  </div>
                  <div class="col-sm-9">
                    <h5
                      class="rhfresphed"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {" "}
                      Dedicated Respiratory Care Providers
                    </h5>
                    <p  class="rhfrespsesc"style={{ fontSize: "12px" }}>
                      {" "}
                      Our team of certified providers is trained to provide
                      specialized respiratory care for all age groups, from
                      children to the elderly.
                    </p>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-sm-3">
                    <img
                      class="fdicon rhfrespicon"
                      src="../style/img/resp/rhfico1.png"
                      alt="Third slide"
                    />
                  </div>
                  <div class="col-sm-9">
                    <h5
                      class="rhfresphed"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {" "}
                      Comprehensive Services
                    </h5>
                    <p  class="rhfrespsesc"style={{ fontSize: "12px" }}>
                      {" "}
                      From emergency care and routine check-ups to chronic
                      condition management and sleep studies, RHF offers a full
                      spectrum of respiratory health services at home.
                    </p>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-sm-3">
                    <img
                      class="fdicon rhfrespicon"
                      src="../style/img/resp/rhfico2.png"
                      alt="Third slide"
                    />
                  </div>
                  <div class="col-sm-9">
                    <h5
                      class="rhfresphed"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {" "}
                      Professional Support
                    </h5>
                    <p class="rhfrespsesc" style={{ fontSize: "12px" }}>
                      {" "}
                      Our providers are supported by a team of pulmonologists
                      and healthcare professionals who are available as needed
                      to provide comprehensive care.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="container">
              <center>
                <h3 class="mainhedrhfresp">Peace of Mind</h3>
                <br></br>
              </center>
              <div class="container">
                <div class="row ">
                  <div class="col-sm-3">
                    <img
                      class="fdicon rhfrespicon"
                      src="../style/img/resp/rhfico3.png"
                      alt="Third slide"
                    />
                  </div>
                  <div class="col-sm-9">
                    <h5
                      class="rhfresphed"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {" "}
                      Family-Centric Care
                    </h5>
                    <p  class="rhfrespsesc"style={{ fontSize: "12px" }}>
                      {" "}
                      We ensure the respiratory health of your family members,
                      even when you are not at home. Our services include elder
                      care, pediatric respiratory care, and ongoing monitoring
                      of chronic conditions..
                    </p>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-sm-3">
                    <img
                      class="fdicon rhfrespicon"
                      src="../style/img/resp/rhfico4.png"
                      alt="Third slide"
                    />
                  </div>
                  <div class="col-sm-9">
                    <h5
                      class="rhfresphed"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {" "}
                      Dedicated Respiratory Care Providers
                    </h5>
                    <p class="rhfrespsesc" style={{ fontSize: "12px" }}>
                      {" "}
                      Our team of certified providers is trained to provide
                      specialized respiratory care for all age groups, from
                      children to the elderly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      {/* <div class="container">
        <center>
          <h2 style={{ fontWeight: "600" }}> How It Works</h2>
        </center>
        <br />
        <br />
        <br />
        <div class="row">
          <div class="col-sm-4">
            <img
              class=" "
              src="../style/img/resp/rhfcard1.png"
              alt="Third slide"
            />
            <br></br>
            <img
              class=" "
              src="../style/img/resp/rhfcard2.png"
              alt="Third slide"
            />
          </div>
          <div class="col-sm-4">
            <img
              class="rhfgirl "
              src="../style/img/resp/rhfgirl.png"
              alt="Third slide"
            />
          </div>
          <div class="col-sm-4">
            <img
              class=" "
              src="../style/img/resp/rhfcard3.png"
              alt="Third slide"
            />
            <br></br>
            <img
              class=" "
              src="../style/img/resp/rhfcard4.png"
              alt="Third slide"
            />
          </div>
        </div>
        <br></br>
        <br></br>
        <center>
          <h3 style={{ fontWeight: "600" }}>
            Bring top-tier healthcare to your doorstep<br></br>
            book our premium home care services now!{" "}
          </h3>
        </center>
        <br></br>
        <center>
          {" "}
          <button class=" rhfbtn2" style={{ borderRadius: "30px" }}>
            Book Now
          </button>{" "}
        </center>
        <br />
        <br />
        <br />
      </div>
      <div class="container">
        <center>
          <h2 style={{ fontWeight: "800" }}> Franchise Opportunities</h2>
        </center>
        <div class="row">
          <div class="col-sm-4">
            <img
              class="" style={{ width: "200px" }}
              src="../style/img/resp/rhfimg21.png"
              alt="Third slide"
            />
            <br></br>
            <h5 style={{ fontSize: "16px", fontWeight: "800" }}>Join RHF and Make a Difference</h5>
            <p> Empower your career by becoming a part of RHF and contributing to transforming respiratory healthcare.</p>
          </div>
          <div class="col-sm-4">
            <img
              class="" style={{ width: "290px", height: "260px", marginTop: "60px" }}
              src="../style/img/resp/rhfbt22.png"
              alt="Third slide"
            />
            <br></br>
            <h5 style={{ fontSize: "16px", fontWeight: "800" }}>Flexible Scheduling</h5>
            <p>Enjoy the flexibility to manage your work schedule according to your convenience.</p>
          </div>
          <div class="col-sm-4">
            <img
              class="" style={{ width: "200px", height: "320px" }}
              src="../style/img/resp/rhfbt12.png"
              alt="Third slide"
            />
            <br></br>
            <h5 style={{ fontSize: "16px", fontWeight: "800" }}>Comprehensive Support</h5>
            <p>We provide extensive training, advanced resources, and continuous support to ensure your success.</p>
          </div>
        </div>
        <br></br>
        <br></br>
        <center>
          <h3 style={{ fontWeight: "600" }}>
            Join our elite franchise network today <br></br>
            partner with our franchise and start your journey to financial feedom!
          </h3>
        </center>
        <br></br>
        <center>
          {" "}
          <button class="rhfbtn1">Book Now </button>
        </center>
        <br />
        <br />
        <br />
      </div>

      <br></br>
      <br></br> */}
    </>
  );
}

export default RhfJion;

