import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItem, delItem } from "../../redux/actions";
import { FaCartShopping } from "react-icons/fa6";
import Data1 from "../../data/Data1";
import Header from "../header/Header";

function SaleDetail() {
  const [cartBtn, setCartBtn] = useState("Add to Cart");
  const { id } = useParams(); // Fetching ID from URL parameters
  const dispatch = useDispatch();

  // Fetch product by ID with strict equality
  let product = Data1.find((item) => item.id === parseInt(id)); 

  // Debug log to check if product is found
  useEffect(() => {
    if (!product) {
      console.error("Product not found for ID:", id);
    }
    window.scrollTo(0, 0);
  }, [product, id]);

  const handleCart = (product) => {
    if (cartBtn === "Add to Cart") {
      dispatch(addItem(product)); // Add item to cart
      setCartBtn("Remove from Cart");
    } else {
      dispatch(delItem(product)); // Remove item from cart
      setCartBtn("Add to Cart");
    }
  };

  const [activeImageSrc, setActiveImageSrc] = useState(
    product ? product.img : ""
  );

  const changeImage = (e) => {
    setActiveImageSrc(e.target.src);
  };

  // Function to calculate discounted price
  const calculateDiscountedPrice = (price, discountPercentage) => {
    const discountedPrice = price - (price * discountPercentage) / 100;
    return discountedPrice.toFixed(2); // Format to two decimal places
  };

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="row">
            {/* Image Column */}
            <div className="col-sm-12 col-md-6 mb-4">
              <div className="product-gallery">
                <div className="product-image">
                  <img
                    className="active"
                    src={activeImageSrc}
                    alt="Active Product"
                    style={{ maxWidth: "100%", height: "auto" }} // Responsive image
                  />
                </div>
              </div>
            </div>

            {/* Description Column */}
            <div className="col-sm-12 col-md-6" style={{ paddingLeft: "30px" }}>
              {product ? ( // Check if product is available
                <>
                  <h1 style={{ fontWeight: "700", fontSize: "38px" }}>
                    {product.title}
                  </h1>
                  {/* Price display logic */}
                  {product.discountPercentage && product.discountPercentage > 0 ? (
                    <div>
                      <h2>
                        <span style={{ textDecoration: "line-through" }}>₹{product.price}</span>{" "}
                        <span style={{ color: "red" }}>
                          - {product.discountPercentage}%
                        </span>
                      </h2>
                      <h2>
                        ₹{calculateDiscountedPrice(product.price, product.discountPercentage)} 
                      </h2>
                    </div>
                  ) : (
                    <h2>₹{product.price} </h2> // Show only the original price if no discount
                  )}
                  <div className="description">
                    <p>{product.desc}</p>
                  </div>
        
                  <button
                    onClick={() => handleCart(product)}
                    className="add-to-cart"
                    style={{ marginTop: "20px", padding: "10px 20px", fontSize: "18px" }}
                  >
                    <FaCartShopping className="text-white" />
                    &nbsp;{cartBtn}
                  </button>
                </>
              ) : (
                <h2>Product not found</h2> // Display message if product is not found
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default SaleDetail;
