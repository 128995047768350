const WorkerData = [
    {
      id: 1,
      title: "N95 Respirators (Pack of 10)",
      price: 500,
      desc: "A pack of 10 high-quality N95 respirators designed to protect construction workers from inhaling harmful particles.",
      img: "../style/img/resp/workbg1.png",

    },
    {
      id: 2,
      title: "Dust Masks (Pack of 60)",
      price: 250,
      desc: "A pack of 60 dust masks to protect construction workers from dust and other particles.",
      img: "../style/img/resp/workbg2.png",
    },
    {
      id: 3,
      title: "Respirator Cartridges (Set of 2)",
      price: 5000,
      desc: "A set of 2 respirator cartridges compatible with various respirators for enhanced protection.",
      img: "../style/img/resp/workbg3.png",
    },
    {
      id: 4,
      title: "Portable Spirometer (One Time Purchase)",
      price: 1500,
      desc: "A set of 2 respirator cartridges compatible with various respirators for enhanced protection.",
      img: "../style/img/resp/workbg4.png",
    },
    {
      id: 5,
      title: "Training Sessions (per worker) (One Time Fee)",
      price: 200,
      desc: " Training sessions to educate workers about respiratory hazards and safety practices.",
      img: "../style/img/resp/workbg5.png",
    },
    {
      id: 6,
      title: "Respiratory Health Consultation with a Pulmonologist(Per Worker)",
      price: 300,
      desc: "Individual consultation sessions with a pulmonologist for respiratory health assessment.",
      img: "../style/img/resp/workbg6.png",
    },
    {
      id: 7,
      title: "Health Surveillance Program Setup (per site)(One-time Fee)",
      price: 500,
      desc: "Initial setup of a comprehensive health surveillance program at the construction site.",
      img: "../style/img/resp/workbg7.png",
    },
    {
      id: 8,
      title: "Lung Health Monitoring (per worker)",
      price: "800/month",
      desc: " Monthly monitoring of lung health for each worker",
      img: "../style/img/resp/workbg8.png",
    },
    {
      id: 9,
      title: "Educational Materials (Pamphlets and Posters)",
      price: 100,
      desc: " Monthly monitoring of lung health for each worker",
      img: "../style/img/resp/workbg9.png",
    },
    {
      id: 10,
      title: "Special Lung Care Package For Workers",
      price: 999,
      desc: " Monthly monitoring of lung health for each worker",
      img: "../style/img/resp/workbg10.png",
    },
    
    
    
  ];
  
  export default WorkerData;
  