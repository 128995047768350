import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  updateFunction,
  setFunction,
} from "../../api/apiFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/authContext";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";

function MyAppointments() {
  const [filteredPatients, setFilteredPatients] = useState([]); // For filtered data
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Number of items per page
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [appointments, setAppointments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("yyyy-MM-DD")
  );
  const [selectedStatus, setSelectedStatus] = useState("Booked");
  const { providerId } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [note, setNote] = useState({});
  const [newNote, setNewNote] = useState();

  const handleClose = () => {
    setShow(false);
    setNote({});
  };
  const handleShow = (id, notes) => {
    setNote({ id, notes });
    setShow(true);
  };

  useEffect(() => {
    // Fetch today's appointments
    setAppointments([]);
    getAppointments();
  }, [selectedDate, selectedStatus]);
  useEffect(() => {
    // Fetch today's appointments
    // setAppointments([]);
    // getAppointments();
    filterProviders();
  }, [searchTerm, appointments]);

  const getAppointments = () => {
    getFunction(
      { providerId, date: selectedDate, status: selectedStatus },
      "/appointment/get-appointment-by-provider-id-and-date"
    )
      .then((response) => {
        const responseData = response.success.result.map((appointment) => ({
          ...appointment,
        }));
        setAppointments(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filterProviders = () => {
    const filtered = appointments.filter((q) =>
      q.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPatients(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPatients = filteredPatients.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const updateAppointmentNotes = () => {
    const timeStamp = moment().format("DD-MM-yyyy hh:mm A");
    if (newNote) {
      updateFunction(
        { id: note.id, notes: timeStamp + ": " + newNote },
        "/appointment/update-appointment-notes"
      )
        .then((response) => {
          handleClose();
          setNewNote();
          getAppointments();
        })
        .catch((error) => {          
          setNewNote();
          handleClose();
        });
    }
    else{
      handleClose();
    }
  };

  const updateAppointmentStatus = (id, status) => {
    updateFunction({ id, status }, "/appointment/update-appointment")
      .then((response) => {
        console.log(response.success);
        getAppointments();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
        console.log(error);
      });
  };

  return (
    <div className="container" style={{ marginLeft: "10px" }}>
      <br />
      <h4 style={{ color: "#3131e5" }}>My Appointments</h4>
      <br></br>
      <div className="filter-container">
        <div>
          <span style={{ color: "black" }}>Filter: </span>
          <span
            className="filterbtn"
            style={
              selectedDate === moment().format("yyyy-MM-DD")
                ? { background: "#204ea0" }
                : null
            }
            onClick={() => setSelectedDate(moment().format("yyyy-MM-DD"))}
          >
            Today
          </span>{" "}
          &nbsp;
          <span
            className="filterbtn"
            style={
              selectedDate === moment().add(1, "day").format("yyyy-MM-DD")
                ? { background: "#204ea0" }
                : null
            }
            onClick={() =>
              setSelectedDate(moment().add(1, "day").format("yyyy-MM-DD"))
            }
          >
            Tomorrow
          </span>
          &nbsp;&nbsp;
          <br></br>
          <input
            type="date"
            onChange={(e) => setSelectedDate(e.target.value)}
            value={selectedDate}
          ></input>
          &nbsp;&nbsp;
          <span
            onClick={() => setSelectedDate("%")}
            className="filterbtn"
            style={selectedDate === "%" ? { background: "#204ea0" } : null}
          >
            All
          </span>
        </div>
        <br></br>
        <div className="status-filters">
          <div>
            <span
              className="filterbtn"
              style={
                selectedStatus === "Booked" ? { background: "#204ea0" } : null
              }
              onClick={() => setSelectedStatus("Booked")}
            >
              Booked
            </span>
            &nbsp;&nbsp;
            <span
              className="filterbtn"
              style={
                selectedStatus === "Completed"
                  ? { background: "#204ea0" }
                  : null
              }
              onClick={() => setSelectedStatus("Completed")}
            >
              Completed
            </span>
            &nbsp;&nbsp;
            <span
              className="filterbtn"
              style={
                selectedStatus === "Cancelled"
                  ? { background: "#204ea0" }
                  : null
              }
              onClick={() => setSelectedStatus("Cancelled")}
            >
              Cancelled
            </span>
            &nbsp;&nbsp;
          </div>
          <div>
            <span
              onClick={() => {
                setSelectedDate("%");
                setSelectedStatus("%");
              }}
              className="filterbtn"
            >
              Clear Filters
            </span>
          </div>
          <br />
        </div>
        <input
          type="text"
          placeholder="Search patients"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control mb-3"
        />
      </div>
      <br></br>
      <div style={{ overflowX: "scroll" }}>
        <table className=" ">
          <thead>
            <tr>
              <th className="th-sm">S.No</th>
              <th className="th-sm">Patient Name</th>
              <th className="th-sm">Date</th>
              <th className="th-sm">Time</th>
              <th className="th-sm">Gender</th>
              <th className="th-sm">Phone</th>
              <th className="th-sm">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentPatients.length === 0 ? (
              <div className="card__detail">
                <div className="card__source text-bold">No records found</div>
              </div>
            ) : (
              currentPatients &&
              currentPatients.map((appointment, index) => (
                <tr key={appointment.id}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>
                    {/* {appointment.first_name}&nbsp;{appointment.last_name} */}
                    {appointment.first_name} &nbsp;{appointment.last_name}
                  </td>
                  <td>
                    {moment(appointment.appointment_date).format("DD-MM-yyyy")}
                  </td>
                  <td>{appointment.start_time}</td>
                  <td>{appointment.gender}</td>
                  <td>{appointment.phone}</td>
                  <td>{appointment.status}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{ all: "initial", fontWeight: "800" }}
                      >
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{ fontSize: "15px" }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleShow(appointment.id, appointment.notes)
                          }
                        >
                          Add/View Note
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            updateAppointmentStatus(appointment.id, "Completed")
                          }
                          disabled={appointment.status !== "Booked"}
                        >
                          Set as Complete
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            updateAppointmentStatus(appointment.id, "No Show")
                          }
                          disabled={appointment.status !== "Booked"}
                        >
                          Set as No-Show
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <br />
        <Pagination>{renderPaginationItems()}</Pagination>
      </div>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header>
          <Modal.Title>Appointment Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Existing Note:</h5>
          <textarea
            rows="4"
            cols="50"
            disabled={true}
            style={{ maxWidth: "90vw" }}
          >
            {note.notes}
          </textarea>
          <h5>Add New Note:</h5>
          <textarea
            rows="4"
            cols="50"
            style={{ maxWidth: "90vw" }}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={updateAppointmentNotes}>Save Changes</button>
          <button onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyAppointments;
