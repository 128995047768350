import { useRoutes } from "react-router-dom";
import Profile from "../pages/patient/Profile";
import Address from "../pages/patient/Address";
import Profilevisit from "../pages/patient/Profilevisit";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";

const PatientRoutes = () => {
  let routes = useRoutes([
    { path: "/patient/profile", element: <Profile /> },
    { path: "patient/address", element: <Address /> },
    { path: "patient/profilevisit", element: <Profilevisit /> },
    { path: "/login", element: <Login /> },
    { path: "/logout", element: <Logout /> },
  ]);
  return routes;
};

export default PatientRoutes;
