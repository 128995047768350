import React, { useEffect, useState, useContext } from "react";
import { getFunction, deleteFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ListDocuments() {
    const { patientId } = useContext(AuthContext);
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchDocuments();
      }, [patientId]);
    
      const fetchDocuments = () => {
        setData([]);
        getFunction({ patientId }, "/documents/get-documentsbyid")
            .then((response) => {
                const responseData = response.success.result;
                setData(responseData);
            })
            .catch((error) => {
                console.log(error);
            });
      }

    const deleteDocument = (fileName) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
        deleteFunction({ patientId, fileName }, "/patientFileUpload/delete-patient-file")
            .then((response) => {
                toast.success("Document deleted successfully");
                deleteFunction({ patientId, fileName }, "/documents/delete-documents")
                    .then((resp) => {
                        fetchDocuments();
                    })
                    .catch((err) => console.log(err));
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    toast.error(error.response.data.error);
                }
                console.log(error);
            });
        }
    };

    return (
        <>
            <div className="">
                <ToastContainer />

                <br />
                <br />
                <br />
                <h1 className="profileheading1">Uploaded Documents</h1>

                <>
                    <hr />
                    <div className="">
                        <div className="providerline1">
                            <div className="col-sm-9">
                                <table class="table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Documents</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.document_name}</td>
                                                <td>
                                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                        <FaEye style={{ color: "#2b5ac7", cursor: "pointer" }} />
                                                    </a>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MdDelete
                                                        style={{ color: "#df4a4a", cursor: "pointer" }}
                                                        onClick={() => deleteDocument(item.document_name)} 
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default ListDocuments;
