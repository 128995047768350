import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
function RHFchestPhysiotherapy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="chphimg1">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>Palliative Care
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Overview</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Symptom Relief & Pain Management</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          RHF nurses provide home care to manage pain, nausea,
                          and breathing difficulties, ensuring patient comfort.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Emotional & Spiritual Support</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Comprehensive support for emotional, psychological,
                          and spiritual needs, helping both patients and
                          families cope.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">
                      Care Coordination & Medication Management
                    </p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Adjust the duration and price based on your specific
                          requirement
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br />

                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">Services Details</b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Symptom management </p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Comprehensive care to manage pain, nausea, shortness
                          of breath, and other discomforts.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Emotional and psychological support</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Compassionate assistance to cope with emotional and
                          psychological challenges.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">
                      Coordination of care between multiple healthcare providers
                    </p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Seamless collaboration between healthcare providers to
                          ensure optimal patient care.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Nutritional guidance</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Personalized dietary advice to support overall health
                          and wellness.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Medication management </p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Professional oversight of medications to ensure safe
                          and effective treatment.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Wound care</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Expert care for managing wounds and preventing
                          infections at home.{" "}
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon5.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Spiritual support</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Comfort and guidance to address the spiritual needs of
                          patients and families.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon6.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh"> End-of-life care </p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Respectful and compassionate care during the final
                          stages of life, when needed.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default RHFchestPhysiotherapy;
