import { useState, useContext, useEffect } from "react";
import ProviderPersonalInfo from "./ProviderPersonalInfo";
import ProviderAddress from "./ProviderAddress";
import ProviderHeader from "../header/ProviderHeader";
import Qualification from "./Qualification";
import Specialization from "./Specialization";
import HospitalAffiliation from "./HospitalAffiliation";
import Scheduling from "./Scheduling";
import Document from "./Document";
import ServiceInformation from "./ServiceInformation";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { getFunction } from "../../api/apiFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faUser,
  faMapLocationDot,
  faGraduationCap,
  faUserDoctor,
  faHospital,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from "react-burger-menu"; // Importing burger menu
import TherapistPayment from "./TherapistPayment";

const Profile = () => {
  const { providerId, providerEmail, isMobile, providerType } =
    useContext(AuthContext);

  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    gender: "",
    phone: "",
    email: "",
    yearsExperience: "",
    initConsultFee: "",
    repeatConsultFee: "",
    repeatInterval: "",
    providerType: "",
    registrationNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [showServiceInformation, setShowServiceInformation] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showQualification, setShowQualification] = useState(false);
  const [showSpecialization, setShowSpecialization] = useState(false);
  const [showHospitalAffiliation, setShowHospitalAffiliation] = useState(false);
  const [showScheduling, setShowScheduling] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFunction({ id: providerId }, "/provider/get-profile")
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
          gender: responseData.gender,
          phone: responseData.phone,
          email: responseData.email,
          yearsExperience: responseData.years_experience,
          initConsultFee: responseData.init_consult_fee,
          repeatConsultFee: responseData.repeat_consult_fee,
          repeatInterval: responseData.repeat_interval,
          providerType: responseData.provider_type,
          registrationNumber: responseData.registration_number,
        });
      })
      .catch((error) => {
        setData({ ...data, email: providerEmail, id: providerId });
        console.log(error);
        setExistingProvider(false);
      });
  }, [providerId, providerEmail]);

  const handleMenuClick = (section) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowInfo(section === "info");
      setShowAddress(section === "address");
      setShowQualification(section === "qualification");
      setShowSpecialization(section === "specialization");
      setShowHospitalAffiliation(section === "hospital");
      setShowScheduling(section === "scheduling");
      setShowDocument(section === "document");
      setShowPayment(section === "payment");

      // Close menu after item is clicked
      setIsMenuOpen(false);
    }, 500); // Simulate loading time
  };

const mobileMenu = () => (
  <Menu
    isOpen={isMenuOpen}
    onStateChange={(state) => setIsMenuOpen(state.isOpen)}
    className="d-block d-md-none"
  >
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("info")}
      style={showInfo ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faUser} style={{ fontSize: "25px" }} />
      &nbsp;Personal Info
    </h3>
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("address")}
      style={showAddress ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faMapLocationDot} style={{ fontSize: "25px" }} />
      &nbsp;Address
    </h3>
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("qualification")}
      style={showQualification ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: "25px" }} />
      &nbsp;Qualification
    </h3>
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("specialization")}
      style={showSpecialization ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faUserDoctor} style={{ fontSize: "25px" }} />
      &nbsp;Specialization
    </h3>
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("hospital")}
      style={showHospitalAffiliation ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faHospital} style={{ fontSize: "25px" }} />
      &nbsp;Hospital Affiliation
    </h3>
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("scheduling")}
      style={showScheduling ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
      &nbsp;Scheduling
    </h3>
    <h3
      className="side-menu-item"
      onClick={() => handleMenuClick("document")}
      style={showDocument ? { border: "1px solid #2196f3" } : null}
    >
      <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
      &nbsp;Document
    </h3>
    {providerType === "Therapist" && (
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("payment")}
        style={showDocument ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
        &nbsp;Make Initial Payment
      </h3>
    )}
  </Menu>
);


  const desktopMenu = () => (
    <div className="side-menu bg2 prowidth d-none d-md-block">
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("info")}
        style={showInfo ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faUser} style={{ fontSize: "25px" }} />
        &nbsp;Personal Info
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("address")}
        style={showAddress ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faMapLocationDot} style={{ fontSize: "25px" }} />
        &nbsp;Address
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("qualification")}
        style={showQualification ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: "25px" }} />
        &nbsp;Qualification
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("specialization")}
        style={showSpecialization ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faUserDoctor} style={{ fontSize: "25px" }} />
        &nbsp;Specialization
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("hospital")}
        style={showHospitalAffiliation ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faHospital} style={{ fontSize: "25px" }} />
        &nbsp;Hospital Affiliation
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("scheduling")}
        style={showScheduling ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
        &nbsp;Scheduling
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("document")}
        style={showDocument ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
        &nbsp;Document
      </h3>
      {providerType === "Therapist" && (
        <h3
          className="side-menu-item"
          onClick={() => handleMenuClick("payment")}
          style={showDocument ? { border: "1px solid #2196f3" } : null}
        >
          <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
          &nbsp;Make Initial Payment
        </h3>
      )}
    </div>
  );

  return (
    <>
      <ProviderHeader />

      <div className="profile">
        <div className="container-fluid profilecont">
          <br />
          <div className="row">
            <div className="col-sm-4">
              <div>
                <img
                  src="../style/img/resp/drprofile.jpg"
                  alt="Profile"
                  className="drprofile providerdrprofile"
                />
                <img
                  src="../style/img/resp/green.png"
                  alt="Profile"
                  className="liveicon providerliveicon"
                />
              </div>
            </div>
            <div className="col-sm-4">
              <h3 className="profileln">
                {data.firstName}&nbsp;{data.lastName}
              </h3>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <br />
      <br />

      <div className="d-flex protop">
        {/* Burger Menu for Mobile View */}
        {/* Regular Side Menu for Desktop View */}

        {isMobile ? mobileMenu() : desktopMenu()}

        <div className="container profile-container">
          <>
            {showInfo && <ProviderPersonalInfo />}
            {showServiceInformation && <ServiceInformation />}
            {showAddress && <ProviderAddress />}
            {showQualification && <Qualification />}
            {showSpecialization && <Specialization />}
            {showHospitalAffiliation && <HospitalAffiliation />}
            {showScheduling && <Scheduling />}
            {showDocument && <Document />}
            {showPayment && <TherapistPayment />}
          </>
        </div>
      </div>
    </>
  );
};

export default withProviderAuth(Profile);






















