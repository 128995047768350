import React from 'react';
import Header from "../header/Header";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <>
    <Header></Header>
    <section>
      <h3 className="text-center mb-4 pb-2 text-primary " style={{fontWeight:"bold"}}>FREQUENTLY ASKED QUESTIONS</h3>
      <p className="text-center mb-5">
        Find the answers for the most frequently asked questions below
      </p>

      <div className="row">
        {faqData.map((faq, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-4">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography className="text-primary">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

const faqData = [
  {
    question: 'What is RHF (Rapid Heal Force)?',
    answer: 'RHF (Rapid Heal Force) is a specialized team of nurses providing in-home treatment and support for respiratory health. Services include emergency care, routine check-ups, sleep studies, pulmonary function tests, COPD patient care, chest physiotherapy, pediatric pneumonia care, and spirometry tests.'
  },
  {
    question: 'How can I avail RHF services?',
    answer: 'You can avail RHF services by booking an appointment through our website or contacting our customer support team. Our nursing team will then arrange for in-home care tailored to your needs.'
  },
  {
    question: 'Who are Respiro Experts?',
    answer: 'Respiro Experts are highly specialized respiratory care professionals who provide personalized treatment plans and advanced care for complex respiratory conditions. They are leaders in their fields and ensure the highest standards of patient care..'
  },
  {
    question: 'How do I consult a Respiro Expert?',
    answer: 'You can consult a Respiro Expert by booking an appointment through our website or contacting our customer support team. We will match you with the best expert based on your specific needs.'
  },
  {
    question: 'What medical equipment can I rent or purchase from Respiro?',
    answer: 'Respiro offers a range of medical equipment for sale or rent, including oxygen concentrators, CPAP machines, nebulizers, ventilators, and respiratory monitoring devices. Our equipment is of the highest quality to ensure effective and reliable care.'
  },
  {
    question: ' How do I rent or purchase medical equipment from Respiro?',
    answer: 'You can rent or purchase medical equipment through our website or by contacting our customer support team. We offer delivery and setup services to ensure you have everything you need.'
  },
  {
    question: 'What services are offered at Respiro Labs?',
    answer: 'Respiro Labs provide advanced diagnostic testing, including pulmonary function tests, infection and allergy testing, respiratory pathogen panels, biomarker testing, lung biopsy, blood gas analysis, and imaging services.'
  }
  ,
  {
    question: 'How do I access services at Respiro Labs?',
    answer: 'You can access Respiro Labs services by booking an appointment through our website or contacting our customer support team. Our labs are equipped with the latest technology to provide accurate and timely results.'
  },
  {
    question: ' What services are available at Respiro Clinics?',
    answer:'Respiro Clinics offer comprehensive diagnostic and treatment services, including consultations with pulmonologists, diagnostic testing, treatment plans, and follow-up care. Our clinics are staffed with highly trained medical professionals dedicated to respiratory health.'
  },
  {
    question: 'How do I book an appointment at a Respiro Clinic?',
    answer: ' You can book an appointment at a Respiro Clinic through our website or by contacting our customer support team. We offer convenient scheduling and personalized care.'
  },
  {
    question: ' Who are Respiro Practitioners?',
    answer: ' Respiro Practitioners are skilled respiratory health professionals who provide a range of services, including consultations, diagnostics, and treatment plans. They work in collaboration with Respiro Experts to ensure comprehensive care.'
  },
  {
    question: 'How do I consult a Respiro Practitioner?',
    answer: 'You can consult a Respiro Practitioner by booking an appointment through our website or contacting our customer support team. We will connect you with the right practitioner based on your health needs.'
  }
];

export default FAQ;


