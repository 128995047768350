import Header from "../header/Header";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";


const vaccinations = [
  { id: 1, name: 'Hepatitis B Vaccine', priceRange: '₹250 - ₹500', imgSrc: '../style/img/resp/vaccine1.jpg' },
  { id: 2, name: 'Influenza (Flu) Vaccine', priceRange: '₹800 - ₹1,500', imgSrc: '../style/img/resp/vaccine2.jpg' },
  { id: 3, name: 'Tetanus, Diphtheria, Pertussis (Tdap)', priceRange: '₹300 - ₹700', imgSrc: '../style/img/resp/vaccine3.jpg' },
  { id: 4, name: 'MMR (Measles, Mumps, Rubella) Vaccine', priceRange: '₹500 - ₹1,500', imgSrc: '../style/img/resp/vaccine6.jpg' },
  { id: 5, name: 'Chickenpox (Varicella) Vaccine', priceRange: '₹1,200 - ₹2,000', imgSrc: '../style/img/resp/vaccine7.jpg' },
  { id: 6, name: 'Human Papillomavirus (HPV) Vaccine', priceRange: '₹2,500 - ₹4,000', imgSrc: '../style/img/resp/vaccine8.jpg' },
  { id: 7, name: 'Pneumococcal Vaccine (PCV and PPSV)', priceRange: '₹2,500 - ₹3,500', imgSrc: '../style/img/resp/vaccine10.jpg' },
  { id: 8, name: 'Hepatitis A Vaccine', priceRange: '₹1,200 - ₹2,000', imgSrc: '../style/img/resp/vaccine11.jpg' },
  { id: 9, name: 'Rabies Vaccine', priceRange: '₹300 - ₹600', imgSrc: '../style/img/resp/vaccine12.jpg' },
  { id: 10, name: 'Rotavirus Vaccine', priceRange: '₹1,200 - ₹2,000', imgSrc: '../style/img/resp/vaccine13.jpg' },
  { id: 11, name: 'Meningococcal Vaccine', priceRange: '₹2,000 - ₹4,000', imgSrc: '../style/img/resp/vaccine14.jpg' },
];

const VaccinationCard = ({ vaccine, handleSelect }) => {
  return (
    <div className="col-sm-4">
      <div className="card rhfcardser">
        <img className="card-img-top ghfg" src={vaccine.imgSrc} alt={vaccine.name} />
        <div className="card-body">
          <p className="card-text">{vaccine.name}</p>
          <p className="text-dark"><b className="text-dark">{vaccine.priceRange}</b></p>
          <center>
            <button className="custom-btn1 btn-3" onClick={() => handleSelect(vaccine)}>
              <span>Select</span>
            </button>
          </center>
        </div>
      </div>
    </div>
  );
};

const VaccinationSelection = () => {
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const bookingSectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleSelect = (vaccine) => {
    setSelectedVaccine(vaccine);
    const initialPrice = parseInt(vaccine.priceRange.split(' - ')[0].replace('₹', '').replace(',', ''));
    setTotalPrice(initialPrice);

    if (bookingSectionRef.current) {
      bookingSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Header />
      <div className="rhfsebgvaccinebackg">
        <br />
        <br />
        <center>
          <br></br>
          <h2 className="rhfhed1 text-dark">Essential Vaccine Packages</h2>
          <p>
          <br></br>
          Choose Respiro Healthcare's essential vaccine packages,
           managed by the RHF team. We offer comprehensive
            immunizations to ensure your health and safety.
             Schedule your shot today!
          </p>
        </center>
    
      <div className="container">
        <div className="row">
          {vaccinations.map((vaccine) => (
            <VaccinationCard key={vaccine.id} vaccine={vaccine} handleSelect={handleSelect} />
          ))}
        </div>

        {selectedVaccine && (
          <div ref={bookingSectionRef}>
            <center><h2><b className="selvacc text-dark">Selected Vaccine</b></h2></center>
            <br />
            <center>
              <div className="viosqg">
                <div className="container">
                  <form className="booking-form" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div className="form-group11">
  <label className="boxresd">Selected Vaccine:</label>
  <div className="diaginput" style={{ position: "relative", marginLeft: "10px" }}>
  <input
    className="booktestdurt blue-input text-dark" // Add the blue-input class here
    value={selectedVaccine.name}
    readOnly
    style={{ textAlign: "center", width: "100%" }}
  />
</div>

</div>

<div className="form-group11">
  <label className="boxresd">Price:</label>
  <div className="diaginput" style={{ position: "relative" }}>
    <input
      className="booktestdurt blue-input" // Add the blue-input class here
      style={{ width: "300px" }}
      type="number"
      name="totalPrice"
      value={totalPrice}
      readOnly
    />
  </div>
</div>
                  </form>
            
                  <center>
                    <button className="custom-btn1 btn-3">
                      <span className="btntexty bookingbtn">Book Now</span>
                    </button>
                  </center>
                </div>
              </div>
            </center>
          </div>
        )}
      </div>
     </div>
     </>
  );
};

export default VaccinationSelection;
