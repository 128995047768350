import React, { useEffect, useState } from "react";
import Header from "../header/Header";

function ADL() {
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="bg2">
        <div className="bg3">
          <div style={{ textAlign: "center", position: "relative" }}>
            <img
              src="../style/img/Rehabserv/ADL.jpg"
              className="pneumoban"
              alt="Pneumoban"
            />
           <h3 className="pneu1 text-white" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
  <b class="text-white">Activities of Daily Living (ADL) Training</b>
</h3>
<p className="pneu2 text-white" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
  <b class="text-white">
  Empower your recovery with our specialized Activities of Daily Living (ADL)
   training program, designed to enhance your
    independence and improve your overall well-being.
  </b>
</p>

          </div>
          <br />
          <div className="greenbg">
            <h3
              className="text-center text-white"
              style={{ fontWeight: "bold", fontFamily: "Poppins" }}
            >
              Package Highlights
            </h3>

            <div className="container">
              <div className="row">
                {[
                  {
                    imgSrc: "../style/img/resp/pneuicon2.png",
                    title: "Personalized ADL Assessment",
                    description:
                      "An individualized evaluation to identify your specific needs and challenges, forming the foundation of your tailored training plan.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon2.png",
                    title: "Daily Routine Reinforcement",
                    description:
                      "Structured support and training to help you navigate everyday tasks, from dressing and grooming to meal preparation and personal hygiene.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Mobility Training for Daily Activities",
                    description:
                      "Focused exercises to improve your mobility in daily situations, ensuring you can move confidently and safely within your environment.b",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Adaptive Techniques and Tools",
                    description:
                      "Expert guidance on the use of adaptive equipment and techniques to simplify tasks and enhance your independence in daily living.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Strength and Coordination Development",
                    description: "Targeted exercises to boost strength and coordination, critical for executing daily activities with ease and confidence.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Social Interaction and Engagement",
                    description:
                      "Training that emphasizes social skills and communication, fostering connections with others and enhancing your quality of life.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Routine Management and Planning",
                    description:
                      "Support in establishing effective routines that accommodate your abilities, making daily living more manageable and enjoyable.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Ongoing Monitoring and Adaptation",
                    description:
                      "Regular assessments to track progress and adapt training as needed, ensuring continuous improvement and a successful recovery journey.",
                  },
                ].map((item, index) => (
                  <div
                    className="col-sm-3 d-flex flex-column align-items-center text-center mb-4" // Added margin-bottom
                    key={index}
                  >
                    <div
                      className="blank d-flex flex-column justify-content-between"
                      style={{ height: "100%" }}
                    >
                      <img
                        src={item.imgSrc}
                        style={{
                          height: "60px",
                          display: "block",
                          margin: "40px auto",
                        }}
                        alt={item.title}
                      />
                      <h5 className="iconh">{item.title}</h5>
                      <p className="iconp">{item.description}</p>
                      <br />
                      <br />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <center>
            <h3
              style={{
                fontWeight: "800",
                fontFamily: "Poppins",
                fontSize: "30px",
              }}
            >
              Plan Purchase
            </h3>
            <h6
              style={{
                fontSize: "20px",
              }}
            >
              Choose Your Plan Duration
            </h6>
          </center>

          <div class="container">
            <center>
              <div
                class="greenbg priceboxgreen"
                style={{
                  borderRadius: "20px",
                  width: "750px",
                  height: "300px",
                }}
              >
                <br></br>
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd text-dark">Duration Selector:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd text-dark">Price:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button
                    class="purchasebutton"
                    style={{
                      fontWeight: "600",
                      backgroundColor: "#fff",
                      color: "#466E6C",
                      borderRadius: "30px",
                    }}
                  >
                    Purchase
                  </button>
                </center>
              </div>
            </center>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}

export default ADL;
