const DATA1 = [
  {
    id: 1,
    title: "Standard Walker",
    discountPercentage: 20,
    isBestseller: true,
    price: 1500,
    desc: " Reliable and sturdy, ideal for everyday mobility. assistance Easy to use with adjustable height options.",
    img: "../style/img/data3/std_walker.png",
    discountPercentage: 10,
  },
  {

    id: 2,
    title: "Folding Walker",
    price: 1800,
    discountPercentage: 20,
    desc: "Conveniently folds for easy storage and transport. Provides stable support with enhanced portability.",
    img: "../style/img/data3/foldable_walker.png",
},
{
    id: 3,
    title: "Hemi Walker",
    price: 1600,
    isBestseller: true,
    desc: "Designed for those with limited mobility, offering one-sided support for improved stability and ease of use.",
    img: "../style/img/data3/hemi_walker.png",
    discountPercentage: 40
},
{
    id: 4,
    title: "Two-Wheeled Walker",
    price: 2000,
    isRespiroAssured: true,
    desc: "Equipped with four wheels for easy movement and added stability. Includes a built-in seat for resting.",
    img: "../style/img/data3/two_wheeled_walker.png",
    discountPercentage: 10
},
{
    id: 5,
    title: "Four-Wheeled Walker",
    price: 2000,
    isRespiroAssured: true,
    desc: "Features wheels for smooth movement and maneuverability. Ideal for users requiring extra mobility support.",
    img: "../style/img/data3/four_wheeled_walker.png",
    discountPercentage: 5
},
{
    id: 6,
    title: "Portable Nano Oxygen Nebulizer",
    price: 3500,
    isBestseller: true,
    desc: "Compact and travel-friendly for on-the-go respiratory care. Delivers efficient nebulization anytime, anywhere.",
    img: "../style/img/data3/portable_nebu.png",
    discountPercentage: 5
},
{
    id: 7,
    title: "Desktop Nano Oxygen Nebulizer",
    price: 4000,
    desc: "Ideal for home use, offering reliable performance with a sleek design. Perfect for consistent respiratory therapy.",
    img: "../style/img/data3/desktop_nebu.png",
    discountPercentage: 20
},
{
    id: 8,
    title: "Handheld Nano Oxygen Nebulizer",
    price: 3000,
    desc: "Convenient and lightweight for personalized use. Provides effective nebulization with easy handling.",
    img: "../style/img/data3/handheld_nebu.png",
    discountPercentage: 10
},
{
    id: 9,
    title: "Single Air Bed",
    price: 7000,
    desc: "Comfortable and durable for single-person use. Ideal for guest accommodations and temporary bedding solutions.",
    img: "../style/img/data3/single_airbed.png",
    discountPercentage: 10
},
{
    id: 10,
    title: "Double Air Bed",
    price: 8000,
    isRespiroAssured: true,
    desc: "Spacious and supportive for two individuals. Provides a comfortable sleep experience with easy inflation.",
    img: "../style/img/data3/double_airbed.png",
    discountPercentage: 30
},
{
    id: 11,
    title: "Queen Air Bed",
    price: 9000,
    desc: "Offers ample space and comfort for a restful night's sleep. Designed for both durability and ease of use.",
    img: "../style/img/data3/queen_airbed.png",
    discountPercentage: 10
},
{
    id: 12,
    title: "King Air Bed",
    price: 10000,
    desc: "Extra-large bed providing luxurious comfort and support. Perfect for a premium sleep experience.",
    img: "../style/img/data3/king_airbed.png",
    discountPercentage: 30
},
{
    id: 13,
    title: "Air Bed with Pump",
    price: 7500,
    desc: "Includes a built-in pump for quick and effortless inflation. Combines convenience with comfort.",
    img: "../style/img/data3/airbed_pump.png",
    discountPercentage: 15
},
{
    id: 14,
    title: "Standard Hot Water Bag",
    price: 400,
    desc: "Simple and effective for soothing warmth and pain relief. Ideal for general use with easy application.",
    img: "../style/img/data3/std_hotbag.png",
    discountPercentage: 19
},
{
    id: 15,
    title: "Electric Hot Bag",
    price: 500,
    desc: "Provides consistent heat with adjustable temperature settings. Convenient and safe for extended use.",
    img: "../style/img/data3/electric_hotbag.png",
    discountPercentage: 20
},
{
    id: 16,
    title: "Gel Hot Bag",
    price: 450,
    desc: "Flexible and reusable, offering targeted heat therapy. Perfect for relieving muscle aches and pains.",
    img: "../style/img/data3/gel_bag.png",
    discountPercentage: 10
},
{
    id: 17,
    title: "Digital Thermometer",
    price: 150,
    isRespiroAssured: true,
    desc: "Provides accurate temperature readings quickly and easily. Ideal for everyday temperature monitoring.",
    img: "../style/img/data3/dig_thermometer.png",
    discountPercentage: 10
},
{
    id: 18,
    title: "Infrared Thermometer",
    price: 700,
    desc: "Non-contact thermometer offering fast and hygienic temperature measurements. Suitable for both adults and children.",
    img: "../style/img/data3/Infra_Thermometer.png",
    discountPercentage: 30
},
{
    id: 19,
    title: "Mercury Thermometer",
    price: 100,
    desc: "Classic design for precise temperature readings. Reliable for home use but requires careful handling.",
    img: "../style/img/data3/Merc_Thermometer.png",
    discountPercentage: 20
},
{
    id: 20,
    title: "Ear Thermometer",
    price: 800,
    desc: "Measures temperature through the ear for quick and accurate results. Ideal for children and adults alike.",
    img: "../style/img/data3/ear_thermometer.png",
    discountPercentage: 30
},
  {
    id: 21,
    title: "Plastic Bed Pan",
    price: 300,
    discountPercentage: 33, // 33% discount
    desc: "Lightweight and easy to clean. Ideal for comfortable and practical use in bed-bound situations.",
    img: "../style/img/data3/plastic_bedpan.png",
  },
  {
    id: 22,
    title: "Stainless Steel Bed Pan",
    price: 500,
    discountPercentage: 30, // 30% discount
    desc: "Durable and hygienic, providing long-lasting use. Easy to sanitize for improved cleanliness.",
    img: "../style/img/data3/steel_bedpan.png",
  },
  {
    id: 23,
    title: "Disposable Bed Pan",
    price: 250,
    discountPercentage: 25, // 25% discount
    desc: "Convenient single-use design for improved hygiene and infection control. Ideal for temporary needs.",
    img: "../style/img/data3/disposable_bedpan.png",
  },
  {
    id: 24,
    title: "Standard Commode Pot",
    price: 1000,
    discountPercentage: 20, // 20% discount
    isRespiroAssured: true,
    desc: "Sturdy and reliable for bedside use. Provides comfort and ease of use with a traditional design.",
    img: "../style/img/data3/std_commode.png",
  },
  {
    id: 25,
    title: "Folding Commode Pot",
    price: 1200,
    discountPercentage: 35, // 35% discount
    desc: "Portable and collapsible for easy storage and transport. Ideal for travel or temporary use.",
    img: "../style/img/data3/folding_commode.png",
  },
  {
    id: 26,
    title: "Portable Commode Pot",
    price: 1100,
    discountPercentage: 30, // 30% discount
    desc: "Compact and convenient for use in various settings. Offers easy mobility and accessibility.",
    img: "../style/img/data3/portable_commode.png",
  },
  {
    id: 27,
    title: "Raised Commode Pot",
    price: 1300,
    discountPercentage: 35, // 35% discount
    isRespiroAssured: true,
    desc: "Elevated design for easier sitting and standing. Provides additional comfort and support.",
    img: "../style/img/data3/raised_commode.png",
  },
  {
    id: 28,
    title: "Digital BP Monitor",
    price: 1500,
    discountPercentage: 20, // 20% discount
    desc: "User-friendly device for accurate blood pressure readings. Includes memory function for tracking measurements.",
    img: "../style/img/data3/digital_BP.png",
  },
  {
    id: 29,
    title: "Manual BP Monitor",
    price: 1000,
    discountPercentage: 25, // 25% discount
    desc: "Traditional sphygmomanometer for precise blood pressure measurements. Ideal for professional use.",
    img: "../style/img/data3/manual_BP.png",
  },
  {
    id: 30,
    title: "Wrist BP Monitor",
    price: 2000,
    discountPercentage: 25, // 25% discount
    desc: "Compact and easy-to-use for monitoring blood pressure at the wrist. Ideal for on-the-go measurements.",
    img: "../style/img/data3/wrist_BP.png",
  },
  {
    id: 31,
    title: "Adult Nebulizer Mask",
    price: 200,
    discountPercentage: 30, // 30% discount
    isRespiroAssured: true,
    desc: "Designed for comfortable and efficient nebulization for adults. Provides a secure fit and effective medication delivery.",
    img: "../style/img/data3/adult_Mask.png",
  },
  {
    id: 32,
    title: "Pediatric Nebulizer Mask",
    price: 250,
    discountPercentage: 20, // 20% discount
    desc: "Sized for children, offering comfort and ease during nebulization. Ensures effective treatment for young patients.",
    img: "../style/img/data3/Pediatric_Mask.png",
  },
  {
    id: 33,
    title: "Fingertip Pulse Oximeter (FPO-99)",
    price: 1200,
    discountPercentage: 20, // 20% discount
    desc: "Compact and easy-to-use, this oximeter provides quick and accurate measurements of blood oxygen levels and pulse rate.",
    img: "../style/img/data3/FPO-99.png",
  },
  {
    id: 34,
    title: "Fingertip Pulse Oximeter (FPO-101)",
    price: 1400,
    isRespiroAssured: true,
    discountPercentage: 25, // 25% discount
    desc: "Reliable and portable, designed for efficient monitoring of oxygen saturation and pulse with a clear LED display.",
    img: "../style/img/data3/FPO-101.png",
  },
  {
    id: 35,
    title: "Fingertip Pulse Oximeter (FPO-92)",
    price: 1300,
    discountPercentage: 30, // 30% discount
    desc: "User-friendly with a precise sensor, offering real-time readings of oxygen levels and pulse rate for personal health monitoring.",
    img: "../style/img/data3/FPO-92.png",
  },
  {
    id: 36,
    title: "Table Top Pulse Oximeter (Flight 70)",
    price: 5500,
    discountPercentage: 15, // 15% discount
    isRespiroAssured: true,
    desc: "Designed for stationary use, this oximeter delivers accurate and consistent readings with a large, easy-to-read display.",
    img: "../style/img/data3/flight-70.png",
  },
  {
    id: 37,
    title: "Pulse Oximeter (Hand Held) (Flight 60)",
    price: 4000,
    discountPercentage: 20, // 20% discount
    desc: "Portable and versatile, providing reliable oxygen saturation and pulse rate measurements with a user-friendly interface.",
    img: "../style/img/data3/flight-60.png",
  },
  {
    id: 38,
    title: "Pulse Oximeter (Hand Held) (Flight 65)",
    price: 4500,
    discountPercentage: 25, // 25% discount
    desc: "Advanced hand-held model with enhanced accuracy and durability, ideal for clinical and home use.",
    img: "../style/img/data3/flight-65.png",
  },
  {
    id: 39,
    title: "Pulse Oximeter with NIBP(FLIGHT 5100)",
    price: 15000,
    discountPercentage: 20, // 20% discount
    desc: "Comprehensive device offering both pulse oximetry and non-invasive blood pressure monitoring for thorough patient assessment.",
    img: "../style/img/data3/flight-5100.png",
  },
  {
    id: 40,
    title: "Mercury-free Sphygmomanometer",
    price: 2000,
    discountPercentage: 25, // 25% discount
    desc: "Environmentally friendly alternative providing precise blood pressure measurements without mercury.",
    img: "../style/img/data3/mercurysphygmomanometer.webp",
  },
  {
    id: 41,
    title: "Aneroid Table Model",
    price: 2500,
    discountPercentage: 37,
    desc: "Durable and reliable table model aneroid sphygmomanometer for accurate blood pressure readings in clinical settings.",
    img: "../style/img/data3/aneroidtable.webp",
  },
  {
    id: 42,
    title: "Portable Aneroid Sphygmomanometer",
    price: 3000,
    discountPercentage: 38,
    isRespiroAssured: true,
    desc: "Compact and portable, offering accurate blood pressure measurements with a user-friendly design for on-the-go use.",
    img: "../style/img/data3/portableaneroidsphygmoma.webp",
  },
  {
    id: 43,
    title: "Electronic BP Monitor (PW-215)",
    price: 2000,
    discountPercentage: 36,
    desc: "Automatic and easy-to-use, providing accurate blood pressure readings with a clear digital display.",
    img: "../style/img/data3/pw215bpmonitor.webp",
  },
  {
    id: 44,
    title: "Electronic BP Monitor (PW-218)",
    price: 2500,
    discountPercentage: 26,
    desc: "Features advanced measurement technology for precise blood pressure tracking and a user-friendly interface.",
    img: "../style/img/data3/pw-218.png",
  },
  {
    id: 45,
    title: "Electronic BP Monitor (PW-220)",
    price: 3000,
    discountPercentage: 32,
    isRespiroAssured: true,
    desc: "Offers quick and reliable blood pressure readings with added features for enhanced accuracy and convenience.",
    img: "../style/img/data3/pw220bpmonitor.webp",
  },
  {
    id: 46,
    title: "Electronic BP Monitor (PW-222)",
    price: 3500,
    discountPercentage: 40,
    desc: "High-performance monitor with adjustable settings and memory function for comprehensive blood pressure management.",
    img: "../style/img/data3/pw222bpmonitor.webp",
  },
  {
    id: 47,
    title: "Patient Monitor (flight 6000)",
    price: 50000,
    discountPercentage: 41,
    isRespiroAssured: true,
    desc: "Advanced device for continuous monitoring of vital signs, providing real-time data to support patient care and medical decision-making. ",
    img: "../style/img/data3/Flight_6000.jpg",
  },
  {
    id: 48,
    title: "Multi Parameter Patient Monitor (flight 7000)",
    price: 70000,
    discountPercentage: 13,
    desc: "Advanced monitor providing comprehensive vital signs monitoring with real-time data and clear display for clinical settings.",
    img: "../style/img/data3/flight7000patientmonitor.png",
  },
  {
    id: 49,
    title: "Multi Parameter Patient Monitor (flight 8000)",
    price: 85000,
    discountPercentage: 12,
    desc: "High-performance model featuring multiple parameters and customizable settings for precise patient monitoring.",
    img: "../style/img/data3/flight8000patientmonitor.webp",
  },
  {
    id: 50,

    title: "Multi Parameter Patient Monitor (flight 9000)",
    price: 100000,
    discountPercentage: 14,
    desc: "Premium monitor with extensive features including enhanced connectivity and real-time analysis for critical care. ",
    img: "../style/img/data3/flight9000patientmonitor.webp",
  },
  {
    id: 51,
    title: "Multi Parameter Patient Monitor (flight AQUA 12)",
    price: 120000,
    discountPercentage: 26,
    desc: "Offers detailed vital signs monitoring with advanced display and robust functionality for accurate patient management.",
    img: "../style/img/data3/aqua12.png",
  },
  {
    id: 52,
    title: "Multi Parameter Patient Monitor (flight AQUA 15)",
    price: 150000,
    discountPercentage: 30,
    isRespiroAssured: true,
    desc: " State-of-the-art monitor with a wide range of parameters and high-definition display, designed for complex clinical environments.",
    img: "../style/img/data3/aqua15.png",
  },
  {
    id: 53,
    title: "ECG Machine Single Channel",
    price: 12000,
    discountPercentage: 12.5,
    isRespiroAssured: true,
    desc: "Compact and efficient, providing essential single-channel ECG readings for basic cardiac assessments.",
    img: "../style/img/data3/single_channel.webp",
  },
  {
    id: 54,
    title: "ECG Machine Three Channel",
    price: 20000,
    discountPercentage: 12,
    desc: "Offers multi-lead ECG capabilities with improved accuracy for comprehensive cardiac monitoring.",
    img: "../style/img/data3/3_channel.png",
  },
  {
    id: 55,
    title: "ECG Machine Six Channel",
    price: 30000,
    discountPercentage: 11,
    desc: "Enhanced model for detailed cardiac analysis with six channels, suitable for a wide range of clinical needs.",
    img: "../style/img/data3/6_channel.png",
  },
  {
    id: 56,
    title: "ECG Machine Twelve Channel",
    price: 50000,
    discountPercentage: 12,
    desc: "Advanced ECG machine with twelve channels for in-depth cardiac evaluations and detailed analysis. ",
    img: "../style/img/data3/12_channel.png",
  },
  {
    id: 57,
    title: "ECG Workstation",
    price: 120000,
    discountPercentage: 14,
    desc: " High-tech workstation providing integrated ECG analysis, storage, and reporting features for efficient cardiac care management.e",
    img: "../style/img/data3/ECG_Workstation.png",
  },
  {
    id: 58,
    title: "Portable ECG Monitor",
    price: 25000,
    discountPercentage: 5,
    desc: " Lightweight and user-friendly, this monitor allows for convenient ECG recording and monitoring on the go. ",
    img: "../style/img/data3/portable_ECGmonitor.png",
  },
  {
    id: 59,
    title: "Holter ECG system",
    price: 60000,
    discountPercentage: 7,
    desc: "Comprehensive system for continuous 24-hour ECG monitoring, capturing detailed cardiac activity over extended periods.",
    img: "../style/img/data3/Holter_ECGsystem.png",
  },
  {
    id: 60,
    title: "EEG Machines 16 channel",
    price: 80000,
    discountPercentage: 11,
    desc: "Advanced 16-channel EEG system offering high-resolution brainwave monitoring for neurological assessments.",
    img: "../style/img/data3/EEGMachines_16.png",
  },
  {
    id: 61,
    title: "EEG Machines 24 channel",
    price: 100000,
    discountPercentage: 37,
    desc: "Premium 24-channel EEG machine providing extensive data and detailed brain activity analysis for specialized neurodiagnostic use.",
    img: "../style/img/data3/ECGMachine_24.png",
  },
  {
    id: 62,
    title: "SpO2 Simulator",
    price: 25000,
    discountPercentage: 12,
    desc: "Provides simulated SpO2 readings for testing and calibrating pulse oximeters, ensuring accurate device performance. ",
    img: "../style/img/data3/spo2_simulator.png",
  },
  {
    id: 63,
    title: "NIBP Simulator",
    price: 30000,
    discountPercentage: 31,
    desc: "Simulates non-invasive blood pressure readings for device testing and calibration, enhancing measurement reliability.",
    img: "../style/img/data3/nibp_simulator.png",
  },
  {
    id: 64,
    title: "ECG Simulator",
    price: 40000,
    discountPercentage: 22,
    desc: "Delivers various ECG waveforms for testing and calibrating ECG machines, ensuring accurate cardiac readings.",
    img: "../style/img/data3/ECG_simulator.png",
  },
  {
    id: 65,
    title: "Sleep Apnea",
    price: 50000,
    discountPercentage: 25,
    desc: "Advanced monitoring device for diagnosing and managing sleep apnea, providing comprehensive sleep study data.",
    img: "../style/img/data3/sleepapnea_model.png",
  },
  {
    id: 66,
    title: "IBP Module",
    price: 35000,
    discountPercentage: 32,
    isRespiroAssured: true,
    desc: "Invasive blood pressure module for real-time monitoring of arterial pressure, suitable for critical care settings.",
    img: "../style/img/data3/IBP_module.png",
  },
  {
    id: 67,
    title: "Ambulatory BP Monitor with SpO2",
    price: 45000,
    isRespiroAssured: true,
    discountPercentage: 38,
    desc: "Portable device for continuous blood pressure and SpO2 monitoring, ideal for long-term patient tracking.",
    img: "../style/img/data3/Ambulatory_BP.png",
  },
  {
    id: 68,
    title: "EtCO2 Module",
    price: 50000,
    discountPercentage: 26.4,
    isRespiroAssured: true,
    desc: "Measures end-tidal CO2 levels for accurate respiratory monitoring in critical care environments.",
    img: "../style/img/data3/etco2_module.png",
  },
  {
    id: 69,
    title: "Syringe Pump (SP-03)",
    price: 20000,
    discountPercentage: 24,
    desc: "Precision syringe pump for controlled medication delivery, ideal for various medical settings.",
    img: "../style/img/data3/SP-03.png",
  },
  {
    id: 70,
    title: "Syringe Pump (SP-04)",
    price: 25000,
    discountPercentage: 26,
    desc: "Advanced syringe pump with enhanced features for accurate infusion and medication administration.",
    img: "../style/img/data3/SP-04.png",
  },
  {
    id: 71,
    title: "Syringe Pump (SP-06)",
    price: 30000,
    discountPercentage: 32,
    desc: "High-performance syringe pump designed for reliable and consistent drug delivery.",
    img: "../style/img/data3/SP-06.png",
  },
  {
    id: 72,
    title: "Infusion Pump  (IP-01)",
    price: 35000,
    discountPercentage: 32,
    desc: "Reliable infusion pump for precise fluid and medication administration in clinical environments.",
    img: "../style/img/data3/IP-01.png",
  },
  {
    id: 73,
    title: "Infusion Pump (IP-flight 750)",
    price: 45000,
    discountPercentage: 26,
    desc: "Advanced infusion pump with customizable settings for complex infusion requirements.",
    img: "../style/img/data3/IP_FLIGHT_750.png",
  },
  {
    id: 74,
    title: "Fetal Monitor (BT-350)",
    price: 60000,
    discountPercentage: 33,
    desc: "Comprehensive fetal monitor providing real-time fetal heart rate and contraction monitoring.",
    img: "../style/img/data3/BT_350.png",
  },
  {
    id: 75,
    title: "Fetal Monitor (flight 800)",
    price: 75000,
    discountPercentage: 31,
    isRespiroAssured: true,
    desc: "High-performance fetal monitor offering detailed fetal assessments and continuous monitoring.",
    img: "../style/img/data3/flight-800.png",
  },
  {
    id: 76,
    title: "Fetal Doppler",
    price: 15000,
    discountPercentage: 16,
    desc: "Portable device for detecting and monitoring fetal heartbeats, ensuring quick and easy assessments.",
    img: "../style/img/data3/fetal_Doppler.png",
  },
  {
    id: 77,
    title: "Infusion/Blood Warmer",
    price: 30000,
    discountPercentage: 34,
    desc: "Warms infusion fluids and blood for safe and effective patient treatment.",
    img: "../style/img/data3/blood_warmer.png",
  },
  {
    id: 78,
    title: "Bi-PAP with Tidal Volume",
    price: 70000,
    discountPercentage: 36,
    desc: "Provides bilevel positive airway pressure with adjustable tidal volume settings for respiratory support.",
    img: "../style/img/data3/Tidal Volume.png",
  },
  {
    id: 79,
    title: "Bi-PAP with Avaps",
    price: 85000,
    discountPercentage: 42,
    isRespiroAssured: true,
    desc: "Advanced bilevel positive airway pressure with adaptive ventilation for optimized respiratory care.",
    img: "../style/img/data3/bipap-avaps.png",
  },
  {
    id: 80,
    title: "Auto C-PAP",
    price: 60000,
    discountPercentage: 32,
    desc: "Automatic continuous positive airway pressure device for effective treatment of sleep apnea. ",
    img: "../style/img/data3/G2S.png",
  },
  {
    id: 81,
    title: "Oxygen Concentrator (single flow)",
    price: 25000,
    discountPercentage: 22,
    desc: "Supplies oxygen to patients with a single flow setting, ideal for home use. ",
    img: "../style/img/data3/OC_SINGLE.jpg",
  },
  {
    id: 82,
    title: "Oxygen Concentrator (dual flow)",
    price: 35000,
    discountPercentage: 37,
    desc: "Provides oxygen with dual flow settings for simultaneous use by multiple patients.",
    img: "../style/img/data3/OC_dual.png",
  },
  {
    id: 83,
    title: "Oxygen Concentrator (OC-701)",
    price: 40000,
    discountPercentage: 10,
    desc: "High-efficiency oxygen concentrator for reliable oxygen delivery in medical settings.",
    img: "../style/img/data3/OC_701.jfif",
  },
  {
    id: 84,
    title: "Oxygen Concentrator (OC-7B)",
    price: 50000,
    discountPercentage: 26,
    desc: "Advanced oxygen concentrator offering high flow rates and durability for clinical use.",
    img: "../style/img/data3/OC-7B.jfif",
  },
  {
    id: 85,
    title: "Oxygen Concentrator (AIRSEP)",
    price: 45000,
    discountPercentage: 46,
    desc: "Compact and efficient oxygen concentrator suitable for various medical applications. ",
    img: "../style/img/data3/AIRSEP.png",
  },
  {
    id: 86,
    title: "Oxygen Splitter",
    price: 15000,
    discountPercentage: 33,
    desc: "Divides a single oxygen source into multiple outputs for simultaneous patient use.",
    img: "../style/img/data3/OX_splitter.png",
  },
  {
    id: 87,
    title: "Oxygen Analyzer",
    price: 20000,
    discountPercentage: 22,
    desc: "Measures oxygen concentration levels in medical settings to ensure proper oxygen delivery. ",
    img: "../style/img/data3/ox_analyzer.png",
  },
  {
    id: 88,
    title: "Photo Therapy",
    price: 40000,
    discountPercentage: 16,
    desc: "Device for treating neonatal jaundice with effective light therapy, ensuring safer and faster recovery.",
    img: "../style/img/data3/phototherapy.jpg",
  },
  {
    id: 89,
    title: "Head Lamp",
    price: 10000,
    discountPercentage: 12,
    desc: "Provides hands-free lighting for precise procedures and examinations in clinical settings. ",
    img: "../style/img/data3/headlamp.jfif",
  },

  {
    id: 90,
    title: "Portable Ventillator",
    price: 80000,
    discountPercentage: 72,
    isRespiroAssured: true,
    desc: "Compact and mobile ventilator for respiratory support in various clinical and emergency settings.",
    img: "../style/img/data3/portable_vent.jpg",
  },
  {
    id: 91,
    title: "Emergency Ventillator",
    price: 100000,
    discountPercentage: 14,
    desc: "Designed for rapid and reliable respiratory support during emergencies.",
    img: "../style/img/data3/emergency_vent.jpg",
  },
  {
    id: 92,
    title: "ICU Ventillator",
    price: 150000,
    discountPercentage: 31,
    desc: "Advanced ventilator for intensive care units, offering comprehensive respiratory support and monitoring. ",
    img: "../style/img/data3/ICU_vent.jpg",
  },
  {
    id: 93,
    title: "Anesthesia Workstation",
    price: 200000,
    discountPercentage: 24,
    desc: "Complete workstation for administering anesthesia with integrated monitoring and support features.",
    img: "../style/img/data3/ACES.jpeg",
  },
  {
    id: 94,
    title: "Biphasic Defibrillator",
    price: 70000,
    discountPercentage: 36,
    desc: "Provides controlled shock delivery for cardiac resuscitation, essential for emergency care. ",
    img: "../style/img/data3/D-Fab.jpg",
  },
  {
    id: 95,
    title: "Biphasic Defibrillator with AED",
    price: 85000,
    discountPercentage: 12,
    desc: "Combines biphasic defibrillation with an automated external defibrillator for versatile cardiac care. ",
    img: "../style/img/data3/flight_defib_AED.jpg",
  },
  {
    id: 96,
    title: "Automatic External Defibrillator (AED-7000)",
    price: 60000,
    discountPercentage: 33,
    desc: "User-friendly AED for public and clinical use with advanced defibrillation capabilities.",
    img: "../style/img/data3/AED_7000.jpg",
  },
  {
    id: 97,
    title: "Automatic External Defibrillator (AED VIEW)",
    price: 75000,
    discountPercentage: 36,
    desc: "Features enhanced visual and auditory prompts for effective defibrillation.",
    img: "../style/img/data3/aed-view.jpg",
  },
  {
    id: 98,
    title: "AED Trainer",
    price: 20000,
    discountPercentage: 34,
    desc: "Training device for practicing AED use and cardiac emergency response.",
    img: "../style/img/data3/aed-trainer.webp",
  },
  {
    id: 99,
    title: "Stethoscope Rappaport (PW-22)",
    price: 8000,
    discountPercentage: 18,
    desc: "High-quality stethoscope with superior acoustic performance for accurate auscultation.",
    img: "../style/img/data3/rappaport-stethoscope.webp",
  },
  {
    id: 100,
    title: "Stethoscope Mark-V",
    price: 6000,
    discountPercentage: 34,
    desc: "Advanced stethoscope offering clear sound transmission and durability for professional use.",
    img: "../style/img/data3/stethoscopes.webp",
  },
  {
    id: 101,
    title: "Spirometer",
    price: 3500,
    discountPercentage: 17,
    desc: "Measures lung function to assess respiratory conditions with accuracy and ease.",
    img: "../style/img/data3/digital-spirometer.webp",
  },
  {
    id: 102,
    title: "Three Ball Spirometer",
    price: 1800,
    isRespiroAssured: true,
    discountPercentage: 38,
    desc: " Simple device to monitor and improve lung capacity through breathing exercises.",
    img: "../style/img/data3/spirometer-3-ball.webp",
  },
  {
    id: 103,
    title: "Anti-Decubitus Pump System",
    price: 8000,
    discountPercentage: 17,
    desc: " Prevents pressure ulcers with a pump system that alternates pressure on the mattress.",
    img: "../style/img/data3/anti-decubitus-pump.jpg",
  },
  {
    id: 104,
    title: "Tubular Air Mattress",
    price: 6500,
    discountPercentage: 31,
    desc: " Provides pressure relief and comfort with a tubular design for patients at risk of bedsores.",
    img: "../style/img/data3/tubular-air-mattress.webp",
  },
  {
    id: 105,
    title: "Video Laryngoscope (NMVL-01)",
    price: 45000,
    discountPercentage: 17,
    desc: " Advanced laryngoscope with video capabilities for improved intubation visibility and accuracy.",
    img: "../style/img/data3/video-laryngoscope-nmvl-01.webp",
  },
  {
    id: 106,
    title: "Video Laryngoscope (NMVL-02)",
    price: 50000,
    discountPercentage: 24,
    desc: " High - definition video laryngoscope designed for enhanced visualization during intubation.",
    img: "../style/img/data3/laryngoscope-nmvl-02.jpeg",
  },
  {
    id: 107,
    title: "Endoscopy Equipments",
    price: 75000,
    discountPercentage: 38,
    isRespiroAssured: true,
    desc: " Comprehensive endoscopy system for detailed internal examinations and diagnostics.",
    img: "../style/img/data3/endoscopy-equipments.webp",
  },
  {
    id: 108,
    title: "Portable Light Source (NM-PLS)",
    price: 12000,
    discountPercentage: 14,
    desc: " Compact and portable light source for endoscopic procedures and examinations.",
    img: "../style/img/data3/portable-light.webp",
  },
  {
    id: 109,
    title: " Light Source (NM-LS)",
    price: 18000,
    discountPercentage: 38,
    desc: " Reliable light source for high - quality illumination during endoscopic and surgical procedures.",
    img: "../style/img/data3/light-source.webp",
  },
  {
    id: 110,
    title: "Endoscopy Camera",
    price: 25000,
    discountPercentage: 28,
    desc: " High - resolution camera for capturing detailed images during endoscopic procedures.",
    img: "../style/img/data3/endoscopy-camera.webp",
  },
  {
    id: 111,
    title: "Nebulizer(NB-117)",
    price: 2500,
    discountPercentage: 35,
    desc: " Efficient nebulizer for respiratory treatments with adjustable settings for patient comfort.",
    img: "../style/img/data3/nebulizer-117.webp",
  },
  {
    id: 112,
    title: " Nebulizer(NB-119)",
    price: 3000,
    discountPercentage: 32,
    isRespiroAssured: true,
    desc: " Compact and effective nebulizer for delivering medication to the lungs.",
    img: "../style/img/data3/nb-119-nebulizer.webp",
  },
  {
    id: 113,
    title: "Nebulizer(NB-121)",
    price: 3200,
    discountPercentage: 31,
    desc: " Advanced nebulizer with enhanced delivery system for optimal respiratory care.",
    img: "../style/img/data3/NB-121-neblizer.webp",
  },
  {
    id: 114,
    title: "Nebulizer(NB-122)",
    price: 3500,
    discountPercentage: 17,
    desc: " High - performance nebulizer designed for efficient and consistent medication delivery.",
    img: "../style/img/data3/nb-122-nebulizer.webp",
  },
  {
    id: 115,
    title: "Mesh Nebulizer",
    price: 3800,
    discountPercentage: 24,
    desc: " Portable nebulizer with mesh technology for effective medication delivery.",
    img: "../style/img/data3/mesh-nebulizer.webp",
  },
  {
    id: 116,
    title: "Ultrasonic Nebulizer",
    price: 4200,
    discountPercentage: 26,
    desc: " Ultrasonic nebulizer offering fine mist and high medication delivery efficiency.",
    img: "../style/img/data3/ultrasonic-nebulizer.webp",
  },
  {
    id: 117,
    title: "Hand Suction",
    price: 2500,
    discountPercentage: 16,
    desc: " Manual suction device for clearing airways or collecting fluids during medical procedures.",
    img: "../style/img/data3/hand-suction.webp",
  },
  {
    id: 118,
    title: "Pedal Suction",
    price: 4000,
    discountPercentage: 20,
    desc: " Pedal - operated suction device designed for convenience and efficiency in various medical settings.",
    img: "../style/img/data3/pedal-suction.webp",
  },
  {
    id: 119,
    title: "Portable Phleghm Suction",
    price: 5500,
    discountPercentage: 14,  
    desc: " Compact suction device ideal for removing phlegm and mucus in portable applications.",
    img: "../style/img/data3/portable-phlegm.webp",
  },
  {
    id: 120,
    title: "Portable Suction",
    price: 6000,
    discountPercentage: 28,
    desc: " Versatile suction device for use in various settings including emergency and home care.",
    img: "../style/img/data3/portablesuction-aspiret.webp",
  },
  {
    id: 121,
    title: "Trolley model Suction(SU-103)",
    price: 8000,
    discountPercentage: 17,
    desc: " Mobile suction unit with trolley for easy maneuverability and efficient suctioning.",
    img: "../style/img/data3/trolley-su-103.webp",
  },
  {
    id: 122,
    title: "Trolley model Suction(SU-105)",
    price: 9000,
    discountPercentage: 19,
    desc: " Advanced suction trolley designed for high performance and easy transport.",
    img: "../style/img/data3/trolley-su-105.webp",
  },
  {
    id: 123,
    title: "Trolley model Suction(HOSPIVAC-350)",
    price: 10000,
    discountPercentage: 12,
    isRespiroAssured: true,
    desc: " Robust suction unit with trolley for use in hospitals and clinics.",
    img: "../style/img/data3/trolley-hospivac.webp",
  },
  {
    id: 124,
    title: "Electric Breast Pump",
    price: 3000,
    discountPercentage: 19,
    desc: " Efficient electric breast pump designed for comfortable and effective milk extraction.",
    img: "../style/img/data3/electric-breast-pump.webp",
  },
  {
    id: 125,
    title: "Manual Breast Pump ",
    price: 1500,
    discountPercentage: 36,
    desc: " Hand - operated breast pump offering ease of use and portability for breastfeeding mothers.",
    img: "../style/img/data3/manual-breast-pump.webp",
  },
  {
    id: 126,
    title: "Infra red Lamp",
    price: 2200,
    discountPercentage: 24,
    desc: " Infrared lamp for therapeutic heat treatment to relieve muscle pain and improve circulation.",
    img: "../style/img/data3/infra-red-lamp.webp",
  },
  {
    id: 127,
    title: "Infrared Forehead  Thermometer",
    price: 1000,
    discountPercentage: 12,
    desc: " Non - contact infrared thermometer for quick and accurate temperature readings.",
    img: "../style/img/data3/forehead-thermometer.webp",
  },

  {
    id: 128,
    title: " Flexible Digital Thermometer",
    price: 500,
    discountPercentage: 28,
    desc: " Digital thermometer with a flexible tip for comfortable and accurate temperature measurement.",
    img: "../style/img/data3/flexible-digital-thermometer.webp",
  },

  {
    id: 129,
    title: " Autoloader Collapsable Stretcher",
    price: 45000,
    discountPercentage: 12,
    desc: " Lightweight, collapsible stretcher with autoloader feature for easy transportation.",
    img: "../style/img/data3/autoloader-strecher.webp",
  },
  {
    id: 130,
    title: " Scoop Stretcher",
    price: 6000,
    discountPercentage: 33,
    desc: " Durable scoop stretcher for safe and efficient patient transfer in emergency situations.",
    img: "../style/img/data3/scoop-stretcher.webp",
  },
  {
    id: 131,
    title: " Stair Stretcher",
    price: 7000,
    discountPercentage: 15,
    desc: " Specialized stretcher designed for navigating stairs and tight spaces with ease.",
    img: "../style/img/data3/stair-stretcher.webp",
  },
  {
    id: 132,
    title: " Two Fold Stretcher",
    price: 5500,
    discountPercentage: 26,
    desc: " Versatile two - fold stretcher for easy storage and transport in emergency scenarios.",
    img: "../style/img/data3/two-fold-stretcher.webp",
  },
  {
    id: 133,
    title: " Four Fold Stretcher",
    price: 6500,
    discountPercentage: 13,
    desc: " Compact four - fold stretcher with enhanced portability and storage capabilities.",
    img: "../style/img/data3/four-fold-stretcher.webp",
  },
  {
    id: 134,
    title: " Spine Board",
    price: 4500,
    discountPercentage: 24,
    desc: " Rigid spine board for immobilizing patients and preventing spinal injuries during transport.",
    img: "../style/img/data3/spine-board.webp",
  },
  {
    id: 135,
    title: " Air Splint",
    price: 2500,
    discountPercentage: 28,
    desc: " Inflatable splint for immobilizing and stabilizing limbs in emergency situations.",
    img: "../style/img/data3/air-splint.webp",
  },
  {
    id: 136,
    title: " Head Immobilizer",
    price: 1800,
    discountPercentage: 39,
    desc: " Head immobilizer designed to prevent head and neck movement during patient transport.",
    img: "../style/img/data3/head-immbolizer.webp",
  },
  {
    id: 137,
    title: " Aluminium Moldable Splint",
    price: 2000,
    discountPercentage: 22,
    desc: " Customizable aluminum splint for supporting and immobilizing injured limbs.",
    img: "../style/img/data3/aluminium-splint.webp",
  },
  {
    id: 138,
    title: " Cervical Collar",
    price: 1000,
    discountPercentage: 34,
    isRespiroAssured: true,
    desc: " Supportive cervical collar to stabilize the neck and reduce movement in case of injury.",
    img: "../style/img/data3/cervical-collar.webp",
  },
  {
    id: 139,
    title: " Weighing Scale Machine",
    price: 900,
    discountPercentage: 19,
    isRespiroAssured: true,
    desc: "Precision digital weighing scale for accurate weight measurement in various settings.",
    img: "../style/img/data3/weigh.jpeg",
  },
  {
    id: 140,
    title: " Facial Steamer",
    price: 1800,
    discountPercentage: 19,
    desc: "Facial steamer designed to open pores and improve skin hydration with a fine mist.",
    img: "../style/img/data3/steamer.jpeg",
  },
  {
    id: 141,
    title: " Fogger Machine(SM-01)",
    price: 5500,
    discountPercentage: 26,
    desc: "Efficient fogger machine for disinfection and sanitization in medium to large spaces.",
    img: "../style/img/data3/fogger-sm-01.jpeg",
  },
  {
    id: 142,
    title: " Fogger Machine(SM-02)",
    price: 7500,
    discountPercentage: 26,
    desc: "Advanced fogger machine for thorough and effective fogging in diverse environments.",
    img: "../style/img/data3/fogger-sm-02.webp",
  },
  {
    id: 143,
    title: " Dolphin Massager",
    price: 2200,
    discountPercentage: 31,
    desc: " Handheld massager with dolphin - shaped design for targeted muscle relaxation.",
    img: "../style/img/data3/Dolphin massager.webp",
  },
  {
    id: 144,
    title: " Hammer Massager",
    price: 2500,
    discountPercentage: 13,
    desc: " Massager with a hammer - like design for deep tissue muscle relief and relaxation.",
    img: "../style/img/data3/hammer_massager.jpg",
  },
  {
    id: 145,
    title: " Manipol Massager",
    price: 2800,
    discountPercentage: 27,
    desc: " Advanced manipol massager for versatile massage techniques and effective muscle treatment.",
    img: "../style/img/data3/manipolmassager.webp",
  },

  {
    id: 146,
    title: " Monipol Massager",
    price: 2600,
    discountPercentage: 13.5,
    desc: " Ergonomic monipol massager designed for targeted muscle relief and improved circulation.",
    img: "../style/img/data3/Monipol_Massager.webp",
  },
  {
    id: 147,

    title: " Foot Massager",
    price: 3500,
    discountPercentage: 33,
    desc: " Foot massager designed to provide soothing relief and relaxation to tired feet.",
    img: "../style/img/data3/foot massager.webp",
  },
  {
    id: 148,
    title: " Leg Massager",
    price: 4000,
    discountPercentage: 22,
    desc: " Comprehensive leg massager offering full - leg coverage for relaxation and pain relief.",
    img: "../style/img/data3/legmassager.webp",
  },
  {
    id: 149,
    title: " Three Para Monitor Stand with basket",
    price: 4500,
    discountPercentage: 31,
    isRespiroAssured: 12,
    desc: " Sturdy stand for three-parameter monitors, equipped with a convenient basket for storing accessories and supplies.",
    img: "../style/img/data3/3paramonitorstand.webp",
  },
  {
    id: 150,
    title: "Multi Para Monitor Wall Mount Stand with basket ",
    price: 5999,
    discountPercentage: 9,
    desc: " Versatile stand designed for multiparameter monitors, featuring a basket for additional storage of medical tools and supplies.",
    img: "../style/img/data3/multiparamonitorstand.webp",
  },
  {
    id: 151,
    title: "Full Face Mask & Nasal Mask ",
    price: 4999,
    discountPercentage: 15,
    desc: " Comprehensive set of masks including full face and nasal masks, designed for effective CPAP therapy.",
    img: "../style/img/data3/full-mask.webp",
  },
  {
    id: 152,
    title: " Nasal pillow Mask",
    price: 8000,
    discountPercentage: 39,
    desc: "Comfortable nasal pillow mask for CPAP therapy, providing a secure fit with minimal contact and maximum comfort.",
    img: "../style/img/data3/nasal-pillow-mask.webp",
  },
  {
    id: 153,
    title: "Ankle Blinder",
    price: 500,
    discountPercentage: 24,
    desc: "Provides compression and support for injured or weak ankles.",
    img: "../style/img/data3/ankle-binder.jpg",
  },
  {
    id: 154,
    title: "Anklet",
    price: 350,
    discountPercentage: 17,
    desc: "Lightweight support for the ankle to offer stability and relief from strains.",
    img: "../style/img/data3/anklet.jpeg",
  },
  {
    id: 155,
    title: "Anklet Comfeel",
    price: 700,
    discountPercentage: 36,
    desc: "Provides comfort and support for mild ankle injuries and joint pain.",
    img: "../style/img/data3/ankletcomfeel.jpg",
  },
  {
    id: 156,
    title: "Functional Knee Support",
    price: 900,
    discountPercentage: 19,
    desc: "Stabilizes and supports the knee during activities and rehabilitation.",
    img: "../style/img/data3/functional-knee.jpg",
  },
  {
    id: 157,
    title: "Foot Drop Splint",
    price: 1500,
    discountPercentage: 7.5,
    desc: "Maintains foot positioning for individuals with foot drop or weakness.",
    img: "../style/img/data3/foot-drop-splint.webp",
  },
  {
    id: 158,
    title: "Knee Cap",
    price: 400,
    discountPercentage: 22,
    desc: "Provides basic support and compression for knee injuries or arthritis.",
    img: "../style/img/data3/knee-cap.jpg",
  },
  {
    id: 159,
    title: "Elastic Knee Support",
    price: 600,
    discountPercentage: 28,
    desc: "Elasticated support for knee injuries, providing compression and flexibility.",
    img: "../style/img/data3/elastic-knee-support.jpg",
  },
  {
    id: 160,
    title: "Knee Immobilizer 14 inches",
    price: 1200,
    discountPercentage: 21,
    desc: "Rigid support for immobilizing the knee joint during severe injuries.",
    img: "../style/img/data3/knee-immobilizer-14.jpg",
  },
  {
    id: 161,
    title: "Knee Immobilizer 19 inches",
    price: 1500,
    discountPercentage: 16,
    desc: "Extended immobilizer for larger legs or more severe knee conditions.",
    img: "../style/img/data3/knee-immobilizer-19.jpg",
  },
  {
    id: 162,
    title: "Knee Cap Open Patella",
    price: 600,
    discountPercentage: 32,
    desc: "Open patella design reduces pressure on the kneecap and improves comfort.",
    img: "../style/img/data3/knee-cap-open.webp",
  },
  {
    id: 163,
    title: "R.O.M Knee Brace",
    price: 2500,
    discountPercentage: 12.5,
    desc: "Allows controlled range of motion for knee rehabilitation.",
    img: "../style/img/data3/rom-knee-brace.webp",
  },
  {
    id: 164,
    title: "Pouch Arm Sling",
    price: 350,
    discountPercentage: 17,
    desc: "Supports and immobilizes the arm after fractures or surgery.",
    img: "../style/img/data3/pouch-arm-sling.webp",
  },
  {
    id: 165,
    title: "Elastic Shoulder Immobilizer",
    price: 700,
    discountPercentage: 36,
    desc: "Keeps the shoulder in place with adjustable compression and support.",
    img: "../style/img/data3/elastic-shoulder-immobilizer.jpg",
  },
  {
    id: 166,
    title: "Clavicle Brace With Velcro",
    price: 800,
    discountPercentage: 36, 
    desc: "Supports and aligns the clavicle for healing after fractures.",
    img: "../style/img/data3/clavicle-brace.webp",
  },
  {
    id: 167,
    title: "Cast Shoe (Rocker Sole)",
    price: 1500,
    discountPercentage: 17,
    isRespiroAssured: true,
    desc: "Protects the cast and helps with walking comfortably.",
    img: "../style/img/data3/cast-shoe.jpeg",
  },
  {
    id: 168,
    title: "Cervical Collar Soft With Support",
    price: 600,
    discountPercentage: 28,
    desc: "Soft support for the neck, ideal for minor cervical injuries or pain relief.",
    img: "../style/img/data3/cervical-collar-support.webp",
  },
  {
    id: 169,
    title: "Cervical Pillow Regular",
    price: 700,
    discountPercentage: 36,
    isRespiroAssured: 13,
    desc: "Supports proper alignment of the neck and spine during sleep.",
    img: "../style/img/data3/cervical-pillow.webp",
  },
  {
    id: 170,
    title: "Abdominal Support 9 inches",
    price: 900,
    discountPercentage: 29,
    desc: "Provides compression and support for the abdominal area, aiding in post-operative recovery.",
    img: "../style/img/data3/abdominal-9.webp",
  },
  {
    id: 171,
    title: "Tummy Trimmer/Abdominal Belt 8 inches",
    price: 800,
    discountPercentage: 37,
    desc: "Supports abdominal muscles and aids in weight loss or post-surgery.",
    img: "../style/img/data3/abdominal-8.jpg",
  },
  {
    id: 172,
    title: "Lumbo Sacral Belt",
    price: 1200,
    discountPercentage: 13,
    desc: "Supports the lower back and pelvis, reducing pain and promoting posture.",
    img: "../style/img/data3/lumbo-sacral.webp",
  },
  {
    id: 173,
    title: "Contoured L.S Support",
    price: 1500,
    discountPercentage: 18,
    desc: "Provides enhanced lumbar support with a contoured design.",
    img: "../style/img/data3/contour-ls.jpg",
  },
  {
    id: 174,
    title: "Rib Belt",
    price: 900,
    discountPercentage: 36,
    desc: "Supports the rib cage and aids in the recovery from rib injuries.",
    img: "../style/img/data3/rib-belt.jpg",
  },
  {
    id: 175,
    title: "Chest Binder",
    price: 800,
    discountPercentage: 24,
    desc: "Provides compression and support for the chest area post-surgery or injury.",
    img: "../style/img/data3/chest-binder.jpg",
  },
  {
    id: 176,
    title: "Taylor's Brace Short/Long",
    price: 1800,
    discountPercentage: 24,
    desc: "Offers support for spinal injuries, available in short and long versions.",
    img: "../style/img/data3/taylors-brace.webp",
  },
  {
    id: 177,
    title: "Hernia Belt",
    price: 1000,
    discountPercentage: 26,
    desc: "Supports and contains hernias, providing relief and aiding recovery.",
    img: "../style/img/data3/hernia-belt.webp",
  },
  {
    id: 178,
    title: "Finger Cot",
    price: 150,
    discountPercentage: 17,
    desc: "Protects and supports individual fingers, suitable for finger injuries.",
    img: "../style/img/data3/finger-cot.webp",
  },
  {
    id: 179,
    title: "Frog Splint",
    price: 300,
    discountPercentage: 18,
    desc: "Immobilizes and supports hand or finger injuries effectively.",
    img: "../style/img/data3/frog-splint.jpg",
  },
  {
    id: 180,
    title: "Mallet Finger Splint",
    price: 350,
    discountPercentage: 18,
    desc: "Designed to support and immobilize the finger joint for mallet finger injuries.",
    img: "../style/img/data3/mallet-finger.jpg",
  },
  {
    id: 181,
    title: "Thumb Spica Splint",
    price: 450,
    discountPercentage: 27,
    isRespiroAssured: 12,
    desc: "Provides support and immobilization for the thumb joint, useful for injuries or arthritis.",
    img: "../style/img/data3/thumb-spica.jpg",
  },
  {
    id: 182,
    title: "Wrist Brace With Double Lock",
    price: 600,
    discountPercentage: 24,
    desc: "Offers stable wrist support with a double locking mechanism for added security.",
    img: "../style/img/data3/wrist-brace.jpg",
  },
  {
    id: 183,
    title: "Wrist Brace With Thumb",
    price: 500,
    discountPercentage: 24,
    desc: "Provides support to the wrist and thumb, aiding in recovery from strains or sprains.",
    img: "../style/img/data3/wrist-brace-thumb.webp",
  },
  {
    id: 184,
    title: "Tennis Elbow Support",
    price: 700,
    discountPercentage: 34,
    desc: "Relieves and supports tennis elbow and related conditions.",
    img: "../style/img/data3/tennis-elbow.jpg",
  },
  {
    id: 185,
    title: "Elbow Support",
    price: 550,
    discountPercentage: 26,
    desc: "Offers support and compression for the elbow joint, aiding in pain relief and recovery.",
    img: "../style/img/data3/elbow-support.jpeg",
  },
  {
    id: 186,
    title: "Forearm Splint",
    price: 700,
    discountPercentage: 33,
    desc: "Provides support and immobilization for the forearm, aiding in healing from fractures or strains.",
    img: "../style/img/data3/forearm-splint.jpg",
  },
  {
    id: 187,
    title: "R.O.M Elbow Brace",
    price: 1800,
    discountPercentage: 9,
    desc: "Allows controlled movement of the elbow for rehabilitation or post-surgical recovery.",
    img: "../style/img/data3/elbow-brace.webp",
  },
  {
    id: 188,
    title: "Shoulder Support (Neo)",
    price: 700,
    discountPercentage: 33,
    desc: "Supports and compresses the shoulder, useful for injuries or chronic conditions.",
    img: "../style/img/data3/shoulder-support.jpeg",
  },
  {
    id: 189,
    title: "Knee Wrap (Neo)",
    price: 600,
    discountPercentage: 28,
    desc: "Wraparound support for the knee, providing compression and comfort.",
    img: "../style/img/data3/knee-wrap.jpg",
  },
  {
    id: 190,
    title: "Knee Wrap Hinged (Neo)",
    price: 900,
    discountPercentage: 28,
    desc: "Enhanced knee support with hinges for added stability and controlled movement.",
    img: "../style/img/data3/knee-hinged.webp",
  },
  {
    id: 191,
    title: "Walking Stick",
    price: 600,
    discountPercentage: 28,
    desc: "Provides stability and support while walking for those with mobility issues.",
    img: "../style/img/data3/walking-sticks.jpg",
  },
  {
    id: 192,
    title: "Tripod Walking Stick",
    price: 800,
    discountPercentage: 31,
    isRespiroAssured: 8,
    desc: "Offers greater stability with a tripod base for extra support.",
    img: "../style/img/data3/tripod-walking-stick.jpg",
  },
  {
    id: 193,
    title: "Quadripod Walking Stick",
    price: 1000,
    discountPercentage: 4,
    desc: "Features a quadripod base for maximum stability and support for significant mobility challenges.",
    img: "../style/img/data3/quad walk.jpeg",
  },
  {
    id: 194,
    title: "Patient Back Rest",
    price: 1500,
    discountPercentage: 7,
    desc: "Adjustable back rest providing comfort and support for patients in bed or seated positions.",
    img: "../style/img/data3/back-rest.png",
  },
  {
    id: 195,
    title: "Humidifier Bottle for Oxygen Therapy",
    price: 450,
    discountPercentage: 24,
    desc: "High-efficiency humidifier bottle that attaches to oxygen concentrators to ensure moisture balance during oxygen therapy sessions.",
    img: "../style/img/data3/Humidifier-bottle.jpg",
  },
  {
    id: 196,
    title: "Oxygen Cylinder (B-Type)",
    price: 10500,
    discountPercentage: 23,
    desc: "Portable B-Type oxygen cylinder with a 10-liter capacity, ideal for home use and emergency situations.",
    img: "../style/img/data3/b-type-cylinder-o2.jpg",
  },
  {
    id: 197,
    title: "Oxygen Cylinder (D-Type)",
    price: 16500,
    discountPercentage: 12,
    desc: "Durable D-Type oxygen cylinder offering a 20-liter capacity, suitable for extended oxygen supply in healthcare facilities.",
    img: "../style/img/data3/d-type-cylinder-o2.webp",
  },
  {
    id: 198,
    title: "Oxygen Regulator with Flowmeter",
    price: 2800,
    discountPercentage: 14,
    desc: "Reliable oxygen regulator featuring an integrated flowmeter for precise control and monitoring of oxygen flow rates.",
    img: "../style/img/data3/O2-regulator.jpg",
  },
  {
    id: 199,
    title: "Pulse Flow Oxygen Concentrator",
    price: 65000,
    discountPercentage: 32,
    isRespiroAssured: 12,
    desc: " Advanced pulse flow oxygen concentrator that delivers oxygen in pulse doses, enhancing efficiency and battery life for portable use.",
    img: "../style/img/data3/PulseO2-Concentrator.jpg",
  },
  {
    id: 200,
    title: "Portable Oxygen Bag",
    price: 1000,
    discountPercentage: 35,
    desc: "Lightweight and easy-to-carry oxygen storage bag, perfect for transporting oxygen cylinders safely.",
    img: "../style/img/data3/Portable_O2bag.jpg",
  },
  {
    id: 201,
    title: "Oxygen Mask with Reservoir Bag",
    price: 280,
    discountPercentage: 23,
    desc: "Non-rebreather oxygen mask designed for high-concentration oxygen delivery during emergencies and critical care.",
    img: "../style/img/data3/O2mask-bag.jpg",
  },
  {
    id: 202,
    title: "Oxygen Flow Splitter",
    price: 1200,
    discountPercentage: 15,
    desc: "Efficient oxygen flow splitter that allows one concentrator to supply oxygen to multiple patients simultaneously.",
    img: "../style/img/data3/o2-splitter.webp",
  },
  {
    id: 203,
    title: "Portable Oxygen Inhaler Canister",
    price: 700,
    discountPercentage: 14,
    desc: "Compact and portable oxygen inhaler canister, ideal for short bursts of oxygen during travel or physical activities.",
    img: "../style/img/data3/Portable-O2inhaler.jpg",
  },
  {
    id: 204,
    title: "Venturi Mask Kit",
    price: 650,
    discountPercentage: 34,
    desc: "Venturi mask kit that delivers precise oxygen concentrations through adjustable venturi valves, suitable for controlled oxygen therapy.",
    img: "../style/img/data3/Venturi-mask-kit.jpg",
  },
  {
    id: 205,
    title: "CPAP Mask Cleaner",
    price: 2000,
    discountPercentage: 18,
    desc: "Automatic cleaning device designed for CPAP and BiPAP masks, ensuring hygiene and prolonging mask life.",
    img: "../style/img/data3/mask-cleaner.webp",
  },
  {
    id: 206,
    title: "CPAP Pillow",
    price: 3000,
    discountPercentage: 19,
    desc: "Ergonomically designed CPAP pillow that accommodates masks comfortably, promoting better sleep quality during therapy.",
    img: "../style/img/data3/cpap-pillow.jpg",
  },
  {
    id: 207,
    title: "Nasal Cannula (Soft Tip)",
    price: 90,
    discountPercentage: 24,
    desc: "Soft-tip nasal cannula designed for long-term oxygen therapy, enhancing patient comfort and reducing irritation.",
    img: "../style/img/data3/Nasal-cannula.jpg",
  },
  {
    id: 208,
    title: "Oxygen Humidifier Kit",
    price: 1000,
    discountPercentage: 32,
    desc: "Comprehensive humidifier kit including a humidifier bottle and tubing to ensure optimal moisture levels during oxygen therapy.",
    img: "../style/img/data3/o2-humidifier-kit.png",
  },
  {
    id: 209,
    title: "Pulse Dose Oxygen Conserving Device",
    price: 7500,
    discountPercentage: 32,
    desc: "Oxygen conserving device that delivers oxygen only during inhalation, optimizing usage and extending oxygen supply.",
    img: "../style/img/data3/pulse-dose-o2.jpg",
  },
  {
    id: 210,
    title: "Portable Nebulizer Kit with Mask",
    price: 2800,
    discountPercentage: 22,
    desc: "Compact nebulizer kit for effective respiratory treatments at home, featuring a comfortable mask for easy administration.",
    img: "../style/img/data3/portable-neublizer.jpg",
  },
  {
    id: 211,
    title: "Oxygen Tubing (7ft)",
    price: 180,
    discountPercentage: 4,
    desc: "Durable and kink-resistant oxygen tubing, suitable for use with oxygen concentrators and therapy devices.",
    img: "../style/img/data3/O2tubing.jpg",
  },
  {
    id: 212,
    title: "Omron NE-C801 Comp Air Nebulizer",
    price: 3500,
    discountPercentage: 9,
    desc: "A compact nebulizer suitable for delivering medication to the lungs for patients with respiratory conditions. Features easy maintenance and portability.",
    img: "../style/img/data3/Comp-AirNebulizer.jpg",
  },
  {
    id: 213,
    title: "BPL Nebulizer Machine with Compressor",
    price: 2999,
    discountPercentage: 15,
    desc: "A reliable nebulizer system designed for home use, offering effective medication delivery for respiratory illnesses.",
    img: "../style/img/data3/bpl-nebulizer-compressor.jpg",
  },
  {
    id: 214,
    title: "Caresmith Air Nebulizer",
    price: 4499,
    discountPercentage: 24,
    isRespiroAssured: 12,
    desc: "A portable nebulizer that uses a mesh technology for efficient aerosol delivery. Suitable for both adults and children.",
    img: "../style/img/data3/caresmith-nebulizer.jpg",
  },
  {
    id: 215,
    title: "Salter Labs Oxygen Tubing",
    price: 150,
    discountPercentage: 12,
    desc: "High-quality oxygen delivery tubing compatible with most oxygen concentrators. Designed for comfort and flexibility.",
    img: "../style/img/data3/salterlab-o2-tubing.webp",
  },
  {
    id: 216,
    title: "Breathe Easy Personal Steam Inhaler",
    price: 2199,
    discountPercentage: 19,
    desc: "A personal steam inhaler for relieving nasal congestion and respiratory discomfort through steam therapy.",
    img: "../style/img/data3/personal-steam-inhaler.webp",
  },
  {
    id: 217,
    title: "Nebulizer Masks for Adults (Pack of 5)",
    price: 350,
    discountPercentage: 16,
    desc: "Disposable nebulizer masks designed for comfort and effective medication delivery during nebulization.",
    img: "../style/img/data3/nebulizermask.jpg",
  },
  {
    id: 218,
    title: "Pure Mist Facial Steamer",
    price: 1299,
    discountPercentage: 14,
    desc: "A facial steamer that provides hydration and helps with sinus congestion, suitable for home use.",
    img: "../style/img/data3/mist-facial-steamer.webp",
  },
  {
    id: 219,
    title: "Respironics DreamStation CPAP Machine",
    price: 38000,
    discountPercentage: 18,
    desc: " A CPAP machine designed for patients with sleep apnea, ensuring continuous airway pressure throughout the night.",
    img: "../style/img/data3/dreamstation.webp",
  },
  {
    id: 220,
    title: "Medtronic Spacers for Inhalers",
    price: 800,
    discountPercentage: 32,
    desc: "Valved holding chambers that improve medication delivery from inhalers, reducing throat irritation and increasing effectiveness.",
    img: "../style/img/data3/medspace-inhalers.jpg",
  },
  {
    id: 221,
    title: "Anxiety Relief Aromatherapy Inhaler",
    price: 599,
    discountPercentage: 24,
    desc: "A portable inhaler infused with essential oils, designed to relieve anxiety and improve breathing comfort.",
    img: "../style/img/data3/aroma-inhaler.jpg",
  },
  {
    id: 222,
    title: "Tranquility Portable Oxygen Canister",
    price: 1200,
    discountPercentage: 7,
    desc: "A portable oxygen canister providing supplemental oxygen for short-term use, ideal for outdoor activities.",
    img: "../style/img/data3/o2-canister.jpg",
  },
  {
    id: 223,
    title: "Respiratory Therapy Handbook",
    price: 750,
    discountPercentage: 34,
    isRespiroAssured: true,
    desc: "A comprehensive guide on respiratory therapy techniques and best practices, ideal for healthcare providers.",
    img: "../style/img/data3/handbook.jpg",
  },
  {
    id: 224,
    title: "Heated Humidifier for CPAP Machines",
    price: 6500,
    discountPercentage: 31,
    desc: " A heated humidifier attachment for CPAP devices, providing comfortable, humidified air for better sleep quality.",
    img: "../style/img/data3/heated-humidifier.png",
  },
];

export default DATA1;