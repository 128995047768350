import React, { useEffect, useState, useContext } from "react";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { Link } from "react-router-dom";
function UpcomingAppointment() {
  const { providerId } = useContext(AuthContext);
  console.log(providerId);
  const [data, setData] = useState([]);
  const [pdata, setPdata] = useState([]);
  useEffect(() => {
    getFunction({ providerId }, "/provider/get-upcomingappointment-by-day")
      .then((response) => {
        const responseData = response.success.appointments;
        setData(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [providerId]);

  return (
    <>
      <div class="card">
        <div class="card__header">
          <div class="card__header-title text-light">
            Upcoming <strong>Appoinments</strong>&nbsp;&nbsp;
          </div>
          <div class="settings">
            <div class="settings__block">
              <i class="fas fa-edit"></i>
            </div>
            <div class="settings__block">
              <i class="fas fa-cog"></i>
            </div>
          </div>
        </div>
        <br />
        <div class="card__main">
          <div class="">
            <div class="card__icon">
              <i class="fas fa-book"></i>
            </div>
            <div class="card__time">
              <div>Today</div>
            </div>
            {data.length === 0 ? (
              <div className="card__detail">
                <div className="card__source text-bold">
                  No Appointments Today
                </div>
              </div>
            ) : (
              data &&
              data.map((item) => (
                <div class="card__detail" key={item.patient_id}>
                  <Link to={`/provider/patientdetails/${item.patient_id}`}>
                    <div class="card__source text-bold">
                      Patient: {item.patient_name}
                    </div>
                    <div class="card__note">Time: {item.start_time}</div>
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default UpcomingAppointment;
