import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import CommonRoutes from "./Routes/CommonRoutes";
import PatientRoutes from "./Routes/PatientRoutes";
import O2Routes from "./Routes/O2Routes";
import ProviderRoutes from "./Routes/ProviderRoutes";
import FinddoctorRoutes from "./Routes/FinddoctorRoutes";
import LungtherapiesRoutes from "./Routes/LungtherapiesRoutes";
import LungcareplansRoutes from "./Routes/LungcareplansRoutes";
import Footer from "./pages/footer/Footer";
import FooterpageRoutes from "./Routes/FooterpageRoutes";
import RhfRoutes from "./Routes/RhfRoutes";
import { AuthProvider } from "./context/authContext";
import BookaTestRoutes from "./Routes/BookaTestRoutes";
import AdvancedLungcheckupplanRoutes from "./Routes/AdvancedLungcheckupplanRoutes";
import RHFproductRoutes from "./Routes/RHFproductRoutes";
import DoctorRoutes from "./Routes/DoctorRoutes";
import ArticlesRoutes from "./Routes/ArticlesRoutes";
import PackagesummaryRoutes from "./Routes/PackagesummaryRoutes";
import DiagnosisServicesRoutes from "./Routes/DiagnosisServicesRoutes";
import PaymentRoutes from "./Routes/PaymentRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import CoordinatorRoutes from "./Routes/CoordinatorRoutes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // This includes both Bootstrap JS and Popper

function App() {
  return (
    <AuthProvider>
      <Router>
        <CommonRoutes />
        <FinddoctorRoutes />
        <AdvancedLungcheckupplanRoutes/>
        <PatientRoutes />
        <O2Routes />
        <ProviderRoutes />
        <LungtherapiesRoutes />
        <LungcareplansRoutes/>
        <RhfRoutes />
        <DoctorRoutes/>
        <RHFproductRoutes/>
        <ArticlesRoutes/>
        <PackagesummaryRoutes/>
        <DiagnosisServicesRoutes/>
        <BookaTestRoutes/>
        <PaymentRoutes/>
        <AdminRoutes/>
        <CoordinatorRoutes/>
        <FooterpageRoutes/>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;



