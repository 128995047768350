import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
function RHFCOPDpatientCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="copdimg1">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>RHF - COPD Patient Care
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Details</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Service Description</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Comprehensive care for Chronic Obstructive Pulmonary
                          Disease (COPD) patients, tailored to manage and
                          improve their respiratory health.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Flexible Schedule</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Choose the duration and frequency of care to suit your
                          health needs and schedule.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Customizable Options</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Adjust the duration and price based on your specific
                          requirements.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">Advantages of COPD Patient Care at Home</b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/assicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Personalized Care Plans</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Customized treatment plans tailored to the unique
                          needs of each COPD patient.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Home Monitoring</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Regular monitoring of symptoms and vital signs by
                          skilled respiratory nurses.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acopd3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Medication Management</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Assistance with medication adherence and management to
                          ensure optimal treatment.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acopd4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Emergency Support</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Rapid response to COPD exacerbations and other
                          respiratory emergencies.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acopd5.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Patient Education</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Guidance on lifestyle modifications, breathing
                          exercises, and techniques to manage symptoms.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Comfort and Convenience</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Receive professional care in the comfort of your home,
                          reducing the need for hospital visits.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default RHFCOPDpatientCare;
