import React, { useEffect, useState } from "react";
import Header from "../header/Header";

function Musclestrength() {
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="bg2">
        <div className="bg3">
          <div style={{ textAlign: "center", position: "relative" }}>
            <img
              src="../style/img/Rehabserv/Muscle Strengthening.jpg"
              className="pneumoban"
              alt="Pneumoban"
            />
           <h3 className="pneu1 text-white" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
  <b class="text-white">Dynamic Muscle Strengthening</b>
</h3>
<p className="pneu2 text-white" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}>
  <b class="text-white">
  Revitalize your strength and functional
   abilities with our cutting-edge muscle strengthening program,
    designed specifically for optimal recovery.
  </b>
</p>

          </div>
          <br />
          <div className="greenbg">
            <h3
              className="text-center text-white"
              style={{ fontWeight: "bold", fontFamily: "Poppins" }}
            >
              Package Highlights
            </h3>

            <div className="container">
              <div className="row">
                {[
                  {
                    imgSrc: "../style/img/resp/pneuicon2.png",
                    title: "Muscle Strengthening Therapy",
                    description:
                      "Rebuild your strength and enhance your physical function with our focused muscle strengthening therapy, an essential part of your rehabilitation journey.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon2.png",
                    title: "Specialized Strengthening Exercises",
                    description:
                      "Engage in evidence-based exercises that target muscle groups weakened by injury, illness, or surgery, designed to facilitate effective recovery.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Personalized Treatment Approach",
                    description:
                      "Each program is tailored to fit your individual needs, taking into account your current condition, goals, and activity level for optimal results.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Functional Strength Development",
                    description:
                      "Our therapy emphasizes functional movements that translate to real-life activities, helping you regain independence in your daily tasks.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Expert Physiotherapy Support",
                    description: "Work closely with our skilled physiotherapists who provide hands-on guidance, ensuring you perform each exercise correctly and safely.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Functional Movement Mastery",
                    description:
                      "Precise training to regain critical daily movements, restoring your ability to engage fully in everyday life.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Progressive Overload Methodology",
                    description:
                      "Utilize a progressive approach to increase resistance and challenge your muscles appropriately, fostering continuous improvement and strength gains.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Holistic Recovery Focus",
                    description:
                      "In-depth support for caregivers, ensuring they’re empowered to help you achieve the best outcomes.",
                  },
                ].map((item, index) => (
                  <div
                    className="col-sm-3 d-flex flex-column align-items-center text-center mb-4" // Added margin-bottom
                    key={index}
                  >
                    <div
                      className="blank d-flex flex-column justify-content-between"
                      style={{ height: "100%" }}
                    >
                      <img
                        src={item.imgSrc}
                        style={{
                          height: "60px",
                          display: "block",
                          margin: "40px auto",
                        }}
                        alt={item.title}
                      />
                      <h5 className="iconh">{item.title}</h5>
                      <p className="iconp">{item.description}</p>
                      <br />
                      <br />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <center>
            <h3
              style={{
                fontWeight: "800",
                fontFamily: "Poppins",
                fontSize: "30px",
              }}
            >
              Plan Purchase
            </h3>
            <h6
              style={{
                fontSize: "20px",
              }}
            >
              Choose Your Plan Duration
            </h6>
          </center>

          <div class="container">
            <center>
              <div
                class="greenbg priceboxgreen"
                style={{
                  borderRadius: "20px",
                  width: "750px",
                  height: "300px",
                }}
              >
                <br></br>
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd text-dark">Duration Selector:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd text-dark">Price:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button
                    class="purchasebutton"
                    style={{
                      fontWeight: "600",
                      backgroundColor: "#fff",
                      color: "#466E6C",
                      borderRadius: "30px",
                    }}
                  >
                    Purchase
                  </button>
                </center>
              </div>
            </center>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}

export default Musclestrength;
