import React, { useState, useEffect, useContext } from "react";
import { uploadFileFunction, getFunction, setFunction, deleteFunction, updateFunction } from "../../api/apiFunctions";
import withProviderAuth from "../../middleware/withProviderAuth";
import { AuthContext } from "../../context/authContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DocumentTable = () => {
  const { providerId, providerEmail } = useContext(AuthContext);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUploadError, setFileUploadError] = useState("");
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageId, setImageId] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState('');


  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  //   console.log(`Selected option: ${event.target.value}`);
  // };
  // const handleChange = (e) => {
  //   setFile(e.target.files[0]);
  //   setFileName(e.target.files[0].name);
  // };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(`Selected option: ${event.target.value}`);
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    if (selectedFile && fileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileUploadError(""); // Clear any previous error message
    } else {
      setFile(null);
      setFileName("");
      setFileUploadError("Only PNG, JPG, JPEG, and PDF files are allowed.");
      toast.error("Only PNG, JPG, JPEG, and PDF files are allowed.");
    }
  };



  const uploadDocument = async () => {
    if (!file) {
      setFileUploadError("Please select a file to upload.");
      toast.error("Please select a file to upload."); 
      return;
    }

    try {





      const formData = new FormData();
      formData.append("file", file);
      await uploadFileFunction(
        formData,
        `/provider/file/upload/?providerId=${providerId}`
      );
      console.log("File uploaded successfully");
  
      const documentData = {
        providerId,
        documentName: fileName,
        documentType: `${selectedOption}`,
        url: `${providerId}/${fileName}`,
      };
  
      const response = await setFunction(documentData, "/documents/set-documents");

      console.log("Added to document table");
  
      setImageKey(documentData.url);
      setImageId(response.success.id);
      // setUploadStatus("File uploaded successfully");

      

      toast.success("File uploaded and added to document table successfully");
    } catch (error) {
      console.error(error);
      setFileUploadError("An error occurred while uploading the file.");
      

      toast.error("An error occurred while uploading the file.");
    }
  };


 


  return (
    <>
        <ToastContainer /> 
      <br /><br />
      <div className="container">
        <form className=" newform">
        <p>Choose Document Type:</p>
          <select value={selectedOption} onChange={handleOptionChange}>
            <option value="" disabled>
              Choose an option
            </option>
            <option>Registration Certificate</option>
            <option>Experience Certificate</option>
            <option>Qualification Certificate</option>

          </select>
          <div className="file-upload-wrapper" data-text={fileName || 'No file chosen'}>
            <input
              type="file"
              className="file-upload-field"
              onChange={handleChange}
            />

          </div>
        </form>
        {file && (
          <button onClick={uploadDocument} className="" style={{ marginTop: '10px' }}>
            File Upload
          </button>
        )}
        {uploadStatus && <p>{uploadStatus}</p>}
      </div>


      <br /><br />
    </>
  );
};

export default withProviderAuth(DocumentTable);
