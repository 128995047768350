import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import validator from "validator";
import { Link } from "react-router-dom";
import ProviderHeader from "../header/ProviderHeader";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus, getAuthTokens } from "../../helpers/authFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import UpcomingAppointment from "./UpcomingAppointment";
import DashboardReports from "./DashboardReports";


function DashboardLanding() {
  const {
    providerId,
    patientId,
    setProviderId,
    setProviderType,
    providerEmail,
    setProviderEmail,
    setIdToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });
  const [appointment, setAppointment] = useState({
    id: "",
    patientId: "",
    appointmentDate: "",
    startTime: "",
    status: "",
  });

//   const [errors, setErrors] = useState({});
//   const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
//   const [profileEditView, setProfileEditView] = useState(false);
//   const tokenRenewPath = "/auth/provider/renew-token";
//   const authType = "provider";

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    if (providerId) {      
      getProfile(providerId);
      // getAppoinments(accessToken, refreshToken, providerId);
    }
  }, [providerId]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/provider/get-token", "provider")
        .then((data) => {
          console.log(data.sub);
          setProviderId(data.sub);
          setProviderEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("providerEmail", data.email);
          localStorage.setItem("providerId", data.sub);
          getProfile(data.accessToken, data.refreshToken, data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);

  const getProfile = (providerId) => {
    getFunction({ id: providerId }, "/provider/get-profile")
      .then((response) => {
        const responseData = response.success.result[0];
        console.log("profileData", responseData);
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
        });
        setProviderType(responseData.provider_type);
        localStorage.setItem("providerType", responseData.provider_type);
      })
      .catch((error) => {
        setData({ ...data, email: providerEmail, id: providerId });
        console.log(error);
        setExistingProvider(false);
      });
  };

  // Appointment Details
  // const getAppoinments = (accessToken, refreshToken, providerId) => {
  //   getFunction(
  //     { id: providerId },
  //     "appointment/get-appointment-by-provider-id-and-date"
  //   )
  //     .then((response) => {
  //       const responseData = response.success.result[0];
  //       //const responseData = response.success.result[0];
  //       setAppointment({
  //         id: responseData.id,
  //         patientId: responseData.patient_id,
  //         appointmentDate: responseData.appointment_date,
  //         startTime: responseData.start_time,
  //         status: responseData.status,
  //       });
  //       // setAppointment(responseData
  //       //   id: responseData.id,
  //       //   patientId: responseData.patient_id,
  //       //   appointmentDate : responseData.appointment_date,
  //       //   startTime: responseData.start_time,
  //       //   status:responseData.status

  //       // );
  //     })
  //     .catch((error) => {
  //       //setAppointment({ ...data, id: providerId });
  //       console.log(error);
  //       //setExistingProvider(false);
  //     });
  // };

  return (
    <>      
      <div class="">
       
        <main>
          
          <div class="main-header">
            <div class="main-header__intro-wrapper">
              <div class="main-header__welcome">
                <div class="main-header__welcome-title">
                  Welcome,{" "}
                  <strong>
                    {data.firstName}&nbsp;{data.lastName}
                  </strong>
                </div>
                <div class="main-header__welcome-subtitle">
                  How are you today?
                </div>
              </div>
              {/* <div class="quickview">
                <div class="quickview__item">
                  <div class="quickview__item-total">41</div>
                  <div class="quickview__item-description">
                    <i class="far fa-calendar-alt"></i>
                    <span class="">Events</span>
                  </div>
                </div>
                <div class="quickview__item">
                  <div class="quickview__item-total">64</div>
                  <div class="quickview__item-description">
                    <i class="far fa-comment"></i>
                    <span class="">Messages</span>
                  </div>
                </div>
                <div class="quickview__item">
                  <div class="quickview__item-total">27&deg;</div>
                  <div class="quickview__item-description">
                    <i class="fas fa-map-marker-alt"></i>
                    <span class="">Austin</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div class="">
            <DashboardReports />
          </div>
        </main>
      </div>
    </>
  );
}

export default withProviderAuth(DashboardLanding);
