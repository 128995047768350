import { useState, useEffect, useContext } from "react";
import {
  getFunction,
  setFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus } from "../../helpers/authFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import moment from 'moment';
const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);
const Scheduling = (props) => {
  const [hospital, setHospital] = useState();
  const [selectedHospital, setSelectedHospital] = useState();
  const [selectedHospitalSchedule, setSelectedHospitalSchedule] = useState();
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const { providerId } = useContext(AuthContext);

  useEffect(() => {
    getFunction(
      { providerId: providerId },
      "/hospital_affiliation/get-hospital-affiliation"
    )
      .then((response) => {
        const responseData = response.success.result;
        setHospital(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  



  const getSchedule = () => {
    getFunction(
      { providerId, hospitalAffiliationId: selectedHospital },
      "/provider/get-schedule"
    )
      .then((response) => {
        const responseData = response.success.result;
        setSelectedHospitalSchedule(responseData);
        setLoading(false);
      })
      .catch((error) => {
        setSelectedHospitalSchedule("");
        console.log(error);
        setLoading(false);
      });
  };



  useEffect(() => {
    getSchedule();
    setShowAddSchedule(false);
  }, [selectedHospital]);

  const changeHandler = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    if (targetName === "hospital") {
      setSelectedHospital(targetValue);
    } else if (targetName === "dayOfWeek") {
      if (e.target.checked) {
        setSelectedDays((oldArray) => [...oldArray, targetValue]);
      } else {
        const newDays = selectedDays.filter((item) => item !== targetValue);
        setSelectedDays(newDays);
      }
    } else if (targetName === "startTime") {
      setSelectedTimes({ ...selectedTimes, [targetName]: targetValue });
    } else if (targetName === "endTime") {
      setSelectedTimes({ ...selectedTimes, [targetName]: targetValue });
    } else if (targetName === "breakStartTime") {
      setSelectedTimes({ ...selectedTimes, [targetName]: targetValue });
    } else if (targetName === "breakEndTime") {
      setSelectedTimes({ ...selectedTimes, [targetName]: targetValue });
    } else if (targetName === "slotDuration") {
      setSelectedTimes({ ...selectedTimes, [targetName]: moment(targetValue,'hh:mm').format("mm") });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let data = selectedTimes;
    data.providerId = providerId;
    data.hospitalAffiliationId = selectedHospital;

    selectedDays.map((item) => {
      console.log(item);
      data = { ...data, dayOfWeek: item };
      console.log(data);
      setFunction(data, "/provider/set-schedule")
        .then((response) => {
          console.log(response.data);
          setSelectedDays([]);
          setSelectedTimes({});
          getSchedule();
          setShowAddSchedule(false);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            alert(error.response.data.error);
          }
          console.log(error);
        });
    });
  };

  const deleteHandler = (id) => {
    deleteFunction({ id }, "/provider/delete-schedule")
      .then((response) => {
        getSchedule();
        console.log(response.data);
        setShowAddSchedule(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.error);
        }
        console.log(error);
      });
  };

  const addScheduleUI = (
    <div>
      <br></br>
      <br></br>
      <p style={{ color: "red" }}>
        Please make sure there is no overlap in hours between multiple clinics.
      </p>
      <h5>Choose the days of consultation:</h5>
      <br></br>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Sunday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Sunday</label>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Monday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Monday</label>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Tuesday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Tuesday</label>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Wednesday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Wednesday</label>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Thursday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Thursday</label>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Friday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Friday</label>
      <input
        name="dayOfWeek"
        type="checkbox"
        value="Saturday"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      />
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Saturday</label>
      <br></br>
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Start:</label>
      <input
        type="time"
        name="startTime"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      ></input>
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">End:</label>
      <input
        type="time"
        name="endTime"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      ></input>
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">
        Break Start:
      </label>
      <input
        type="time"
        name="breakStartTime"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      ></input>
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Break End:</label>
      <input
        type="time"
        name="breakEndTime"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      ></input>
      <label style={{ marginRight: "10px", color: "black" }} className="boldtext">Duration:</label>
      <input
        type="time"
        name="slotDuration"
        style={{ marginRight: "10px" }}
        onChange={changeHandler}
      ></input>
      <br></br>
      <br></br>
      <span className="approvebtn" onClick={submitHandler}>
        Submit
      </span>
      &nbsp;&nbsp;
      <span className="approvebtn" onClick={() => setShowAddSchedule(false)}>
        Close
      </span>
    </div>
  );

  const existingScheduleUI = (
    <div>
      <br></br>
      <h5>Current Schedule:</h5>
      <br></br>
      <div className="col-sm-8">
        <table class="table-responsive">
          <thead>
            <th>Day</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Break Start Time</th>
            <th>Break End Time</th>
            <th>Duration</th>
            <th>Action</th>
          </thead>
          <tbody>
            {selectedHospitalSchedule &&
              selectedHospitalSchedule.map((item) => (
                <tr>
                  <td>{item.day_of_week}</td>
                  <td>{item.start_time}</td>
                  <td>{item.end_time}</td>
                  <td>{item.break_start_time}</td>
                  <td>{item.break_end_time}</td>
                  <td>{item.slot_duration}</td>
                  <td onClick={() => deleteHandler(item.id)}>Delete</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <br></br>
      <br></br>
    </div>
  );

  return (
    <div>
      <br></br>
      <select
        type="text"
        name="hospital"
        placeholder="Clinic"
        onChange={changeHandler}
      >
        <option>Select Clinic:</option>
        {hospital &&
          hospital.map((item) => (
            <option key={item.id} value={item.id}>
              {item.hospital_name}
            </option>
          ))}
      </select>
      <br></br>
      <br></br>
      <br></br>
      {selectedHospital && selectedHospitalSchedule === "" ? (
        <p>No schedules defined!</p>
      ) : (
        existingScheduleUI
      )}
      {selectedHospital && (
        <span className="approvebtn" onClick={() => setShowAddSchedule(true)}>
          Add new schedule
        </span>
      )}
      {showAddSchedule && addScheduleUI}
    </div>
  );
};

export default withProviderAuth(Scheduling);
