import React from 'react';
import { CSSTransition } from 'react-transition-group'; // Ensure you import CSSTransition if you're using it

const RespiroFoundation = () => {
  // Sample data for the foundcards
  const foundcardsData = [
    {
      id: 1,
      imgSrc: '../style/img/resp/Pfizer.png',
      title: 'Pfizer Health Solutions',
      text: 'Innovating Respiratory Treatments.     Pfizer is pioneering treatments for chronic lung diseases with Respiro.', 
      profileLink: '/pfizer-collaboration',
    },
    {
      id: 2,
      imgSrc: '../style/img/resp/philipshealth.jfif',
      title: 'Philips Healthcare',
      text: 'Enhancing Patient Care with Technology. Philips provides advanced respiratory equipment through our partnership.',
      profileLink: '/philips-collaboration',
    },
    {
      id: 3,
      imgSrc: '../style/img/resp/tesla.png',
      title: 'Tesla Energy Division',
      text: 'Sustainable Healthcare Power Solutions. Tesla helps us implement clean energy in healthcare facilities.',
      profileLink: '/tesla-collaboration',
    },
    {
      id: 4,
      imgSrc: '../style/img/resp/microsofthealth.png',
      title: 'Microsoft Cloud Health',
      text: 'Empowering Healthcare with Cloud Technologies. Respiro and Microsoft Cloud streamline patient care management.',
      profileLink: '/microsoft-collaboration',
    },
    // Add more cards as needed
  ];

  return (
    
    <div className="rfoundation-container">
      {/* Hero Section */}
      <div className="rfoundation-hero-section lunbnr2">
        <h1 className="foundbanner text-white fade-in">Envisioning a Future of Healthier Lives with Respiro</h1>
        <p className="foundbanner text-white fade-in">Together, we breathe easier, live healthier, and thrive.</p>
        <a href="/donate" className="foundbanner text-gray rfoundation-donate-btn bounce">Join Now</a>
      </div>

      {/* Our Mission Section */}
      <div class="signature-background121">
      <center>
      <div className="container">
        
      <section className="rfoundation-section">
        <h2 className="rfoundation-h2 slide-in">Our Mission</h2>
        <p className="rfoundation-p fade-in">
          Transforming respiratory health through innovative care and community support. 
          At Respiro, we are dedicated to enhancing the respiratory health of individuals 
          and communities by offering comprehensive care, support, and education. Our 
          mission is to make a significant impact on public health by empowering people 
          to live healthier lives through our specialized services and programs.
        </p>
        
        <p className="fade-in">
          Respiro defines social impact as “the measurable positive outcome of our 
          interventions on the respiratory health and overall wellbeing of individuals and 
          communities, addressing respiratory health challenges and promoting long-term health improvements.”
        </p>
        <a href="/learn-more" className="rfoundation-learn-more zoom-in">Learn More</a>
      </section>
    
      {/* Breathing Life into Communities Section */}
      <section className="rfoundation-section">
        <h2 className="rfoundation-h2 slide-in">Breathing Life into Communities</h2>
        <p className="rfoundation-p fade-in">
          When we focus on respiratory health, we have the power to transform lives. 
          This is the essence of Respiro.
        </p>
        <a href="/learn-more" className="rfoundation-learn-more zoom-in">Learn More</a>
      </section>

      {/* Scholarships Section */}
      <section className="rfoundation-section">
        <h2 className="rfoundation-h2 slide-in">Scholarships</h2>
        <p className="rfoundation-p fade-in">
          To make advanced respiratory care accessible to underrepresented populations, 
          Respiro partners with healthcare organizations and educational institutions to 
          offer scholarships for specialized respiratory care education and training.
        </p>
        <a href="/learn-more" className="rfoundation-learn-more zoom-in">Learn More</a>
      </section>

      {/* Special Projects Section */}
      <section className="rfoundation-section">
        <h2 className="rfoundation-h2 slide-in">Special Projects</h2>
        <p className="rfoundation-p fade-in">
          Respiro is committed to advancing respiratory healthcare and supporting 
          healthcare professionals worldwide. In addition to our core initiatives, 
          we undertake special projects to further our mission.
        </p>
        <a href="/learn-more" className="rfoundation-learn-more zoom-in">Learn More</a>
      </section>
      </div>
      {/* Latest News Section */}
      <section className="rfoundation-section">
        <h2 className="rfoundation-h2 slide-in">Latest News from Respiro 📩</h2>
        <div className="rfoundation-news-list">
          <div className="rfoundation-news-item fade-in-up">
            <h3>Respiro Supports Respiratory Health Initiatives in Rural Areas  📢</h3>
            <a href="/news1" className="rfoundation-news-link zoom-in">Continue Reading</a>
          </div>
          <div className="rfoundation-news-item fade-in-up">
            <h3>Announcing Respiro’s 2025 Respiratory Care Ambassador 📢</h3>
            <a href="/news2" className="rfoundation-news-link zoom-in">Continue Reading</a>
          </div>
          <div className="rfoundation-news-item fade-in-up">
            <h3>New Award Category: Excellence in Respiratory Care 📢</h3>
            <a href="/news3" className="rfoundation-news-link zoom-in">Continue Reading</a>
          </div>
          <div className="rfoundation-news-item fade-in-up">
            <h3>Respiro’s 2025 Board of Trustees Announced 📢</h3>
            <a href="/news4" className="rfoundation-news-link zoom-in">Continue Reading</a>
          </div>
        </div>
        <a href="/all-news" className="rfoundation-view-all-news fade-in">View All</a>
      </section>
      </center>
  
      
      {/* Foundcards Section */}
      <section className="rfoundation-section">
        <h2 className="rfoundation-h2 slide-in">Corporate Sponsors</h2>
        <div className="foundcards-container">
          {foundcardsData.map(item => (
            <CSSTransition
              key={item.id}
              in={true}
              timeout={300}
              classNames={{
                enter: 'foundcards-fade-enter',
                enterActive: 'foundcards-fade-enter-active',
                exit: 'foundcards-fade-exit',
                exitActive: 'foundcards-fade-exit-active',
              }}
              unmountOnExit
            >
              <div className="foundcards-card">
                <img className="foundcards-card-img" src={item.imgSrc} alt={item.title} />
                <div className="foundcards-card-overlay">
                  <h4 className="foundcards-card-title">{item.title}</h4>
                  <p className="foundcards-card-text">{item.text}</p>
                  <a href={item.profileLink} className="btn btn-primary foundcards-btn">See Details</a>
                </div>
              </div>
            </CSSTransition>
          ))}
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="rfoundation-section rfoundation-testimonial-section">
        <h2 className="rfoundation-h2 slide-in">What Our Supporters Say</h2>
        <div className="rfoundation-testimonial-cards">
          <div className="rfoundation-testimonial-card fade-in signature-background121">
            <blockquote>
              “Respiro's initiatives are changing lives, and I'm proud to support them.”
              <footer>— Dr. Sarah Lee, Respiratory Therapist (USA)</footer>
            </blockquote>
          </div>
          <div className="rfoundation-testimonial-card fade-in signature-background121">
            <blockquote>
              “Their focus on community health truly inspires me.”
              <footer>— Michael Smith, Public Health Advocate (Canada)</footer>
            </blockquote>
          </div>
          <div className="rfoundation-testimonial-card fade-in signature-background121">
            <blockquote>
              “Supporting Respiro aligns with my commitment to improving healthcare access.”
              <footer>— Dr. Amina Noor, Family Physician (UAE)</footer>
            </blockquote>
          </div>
          <div className="rfoundation-testimonial-card fade-in signature-background121">
            <blockquote>
              “I believe in Respiro's vision for a healthier future for all.”
              <footer>— John Doe, Philanthropist (UK)</footer>
            </blockquote>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="rfoundation-cta-section">
        <h2 className="fade-in">Every contribution makes a difference.</h2>
        <p className="fade-in">
          Support our mission by making a financial donation. Join the Respiro Community 
          or become a corporate sponsor today!
        </p>
        <a href="/donate" className="rfoundation-donate-btn bounce">Join Today</a>
      </section>
    </div>
    </div>
  );
};

export default RespiroFoundation;
