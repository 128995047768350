import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ProviderHeader from "../header/ProviderHeader";
function Provider() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ProviderHeader></ProviderHeader>
      <div class="providerbg ">
        <br></br> <br></br> <br></br>
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <h1 class="mainclass">
                <b class="text-dark start4 ">Welcome to Respiro</b>
                <br></br>
                <p class="start3">
                  Your Premier Respiratory and Physiotherapy Cloud Platform
                </p>
              </h1>
              <p class="par">
                Welcome to Respiro, your trusted partner in delivering
                outstanding respiratory and physiotherapy services. We are
                excited to have you join our community of dedicated therapists.
                Together, we can make a significant impact on patient care and
                recovery.
                <br></br>
                Thank you for choosing Respiro. Let's transform the future of
                respiratory and physiotherapy care while advancing your
                professional growth and success.
              </p>
              <Link to="/provider/login">
                <button class="providerbtn1">Sign in </button>
              </Link>{" "}
              &nbsp;
              <Link to="/provider/signup">
                {" "}
                <button class="btn-outline providerbtn2">Sign up</button>
              </Link>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-5">
              <img
                class=" providerimg  providerimage"
                src="../style/img/banner/provider.png"
                alt="Third slide"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="newbg">
        <div class="container">
          {" "}
          <h1>
            <center>
              <b class="text-dark">BENEFITS FOR PHYSIO</b>
            </center>
          </h1>
          <br></br> <br></br>
          <div class="row">
            <div class="col-sm-4">
              <div class="card landingcard ">
                <div class="container">
                  <br></br>
                  <center>
                    <h3 class="lhed providerhead">Independent Work Style</h3>
                  </center>
                  <center>
                    <img
                      src="../style/img/resp/Rectangle 425.png"
                      class="limg"
                    ></img>
                  </center>
                  <br></br>
                  <p class="">
                    Enjoy the freedom to manage your schedule and work
                    independently while making a significant impact on patients'
                    lives.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card landingcard ">
                <div class="container">
                  <br></br>
                  <center>
                    <h3 class="lhed1 providerhead">
                      {" "}
                      Access Advanced Resources
                    </h3>
                  </center>
                  <center>
                    <img
                      src="../style/img/resp/Rectangle 427.png"
                      class="limg"
                    ></img>
                  </center>
                  <br></br>
                  <p class="">
                    Utilize state-of-the-art medical equipment and advanced
                    resources to provide the best care possible.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card landingcard ">
                <div class="container">
                  <br></br>
                  <center>
                    <h3 class="lhed providerhead">Flexible Scheduling</h3>
                  </center>
                  <center>
                    <img
                      src="../style/img/resp/Rectangle 428.png"
                      class="limg"
                    ></img>
                  </center>
                  <br></br>
                  <p class="">
                    Benefit from flexible working hours that allow you to
                    balance your personal and professional life effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div class="row">
            <div class="col-sm-4">
              <div class="card landingcard ">
                <div class="container">
                  <br></br>
                  <center>
                    <h3 class="lhed providerhead">
                      Expert Tech Help, a Click Away
                    </h3>
                  </center>
                  <center>
                    <img
                      src="../style/img/resp/Rectangle 429.png"
                      class="limg"
                    ></img>
                  </center>
                  <br></br>
                  <p class="">
                    Get immediate technical support whenever you need it,
                    ensuring smooth and efficient operations.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card landingcard ">
                <div class="container">
                  <br></br>
                  <center>
                    <h3 class="lhed1 providerhead">Get 3X More Bookings</h3>
                  </center>
                  <center>
                    <img
                      src="../style/img/resp/Rectangle 430.png"
                      class="limg"
                    ></img>
                  </center>
                  <br></br>
                  <p class="">
                    Achieve 3x more bookings in physiotherapy plans by enhancing
                    patient engagement and leveraging targeted marketing
                    strategies.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="card landingcard ">
                <div class="container">
                  <br></br>
                  <center>
                    <h3 class="lhed providerhead">
                      {" "}
                      Secure and Stable Employment
                    </h3>
                  </center>
                  <center>
                    <img
                      src="../style/img/resp/Rectangle 431.png"
                      class="limg"
                    ></img>
                  </center>
                  <br></br>
                  <p class="">
                    Stability in a reputable organization that values and
                    supports its healthcare professionals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <center>
          <h2>
            <b class="text-dark">Our Therapy Services</b>
          </h2>
        </center>
        <br></br>
        <br></br>
        <div class="row">
          <div class="col-sm-6">
            {/* //1st row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2 text-dark">
                  {" "}
                  {/* <a href="/Asthma">Asthma Management</a> */}
                  <b class="text-dark ">Asthma Management</b>
                  <br></br>Comprehensive care to control and manage asthma
                  symptoms.
                </p>
              </div>
            </div>
            {/* 1st end */}

            {/* //2nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2 text-dark">
                  {" "}
                  {/* <a href="/Copd">COPD Treatment</a> */}
                  <b class="text-dark">COPD Treatment</b>
                  <br></br>Specialized treatment plans to manage and improve
                  COPD conditions.
                </p>
              </div>
            </div>
            {/* 2st end */}

            {/* //3nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2 text-dark">
                  {" "}
                  {/* <a href="/Sleepapnea">Sleep Apnea Therapy</a> */}
                  <b class="text-dark">Sleep Apnea Therapy</b>
                  <br></br>Effective solutions to manage and treat sleep apnea.
                </p>
              </div>
            </div>
            {/* 3nd end */}

            {/* //4nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Pulmonaryfntest">Pulmonary Function Tests</a> */}
                  <b class="text-dark">Pulmonary Function Tests</b>
                  <br></br>Advanced testing to evaluate lung function and
                  diagnose respiratory conditions.
                </p>
              </div>
            </div>
            {/* 4th end */}
            {/* //5nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Ventilator">Ventilator Management</a> */}
                  <b class="text-dark">Ventilator Management</b>
                  <br></br>Expert care and management for patients requiring
                  ventilator support.
                </p>
              </div>
            </div>
            {/* 5th end */}
          </div>
          <div class="col-sm-6">
            <img src="../style/img/resp/Group 1089.png" class="otsimg"></img>
          </div>
        </div>
        <hr class="ltline1"></hr> <br></br>
        <div class="row">
          <div class="col-sm-6">
            <img src="../style/img/resp/Group 1099.png" class="otsimg"></img>
          </div>

          <div class="col-sm-6">
            <br></br> <br></br>
            {/* //1st row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Postsurgical">Post-Surgical Rehabilitation</a> */}
                  <b class="text-dark">Post-Surgical Rehabilitation</b>
                  <br></br>Tailored rehabilitation plans to aid recovery after
                  surgery.
                </p>
              </div>
            </div>
            {/* //2nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Sportsinjury">Sports Injury Recovery</a> */}
                  <b class="text-dark">Sports Injury Recovery</b>
                  <br></br>Specialized treatment to help recover from
                  sports-related injuries.
                </p>
              </div>
            </div>
            {/* //3nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Chronicpain">Chronic Pain Management</a> */}
                  <b class="text-dark">Chronic Pain Management</b>
                  <br></br>Effective strategies to manage and reduce chronic
                  pain.
                </p>
              </div>
            </div>
            {/* //4nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Mobility">Mobility and Balance Training</a> */}
                  <b class="text-dark">Mobility and Balance Training</b>
                  <br></br>Programs designed to improve mobility and balance.
                </p>
              </div>
            </div>
            {/* //5nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  {/* <a href="/Strength">Strength and Conditioning Programs</a> */}
                  <b class="text-dark">Strength and Conditioning Programs</b>
                  <br></br>Customized plans to enhance strength and overall
                  physical conditioning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div class="container rhfresource">
        <div class="row">
          <h1>
            <center>
              <b class="text-dark">MANAGE YOUR PATIENTS</b>
            </center>
          </h1>
          <br></br>
          <br></br>
          <br></br>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed">Appointment Schedule</h3>
                </center>
                <center>
                  <img
                    src="../style/img/resp/appointment-book 1.png"
                    class="limg1"
                  ></img>
                </center>
                <br></br>
                <p class="">
                  Keep track of your daily, weekly, and monthly appointments
                  with ease. Set reminders and notifications to stay on top of
                  your schedule.
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed1">Access to Patient Profiles</h3>
                </center>
                <center>
                  <img src="../style/img/resp/user 1.png" class="limg1"></img>
                </center>

                <br></br>
                <p class="">
                  Access detailed profiles for each patient, including medical
                  history, current treatment plans, and progress notes. Easily
                  update records after each session.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed">Patient Monitoring</h3>
                </center>
                <center>
                  <img
                    src="../style/img/resp/information (1) 1.png"
                    class="limg1"
                  ></img>
                </center>
                <br></br>
                <p class="">
                  Tracking patient progress, monitoring vital signs, and
                  identifying potential complications.
                  <br></br>
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container newbg">
        <div class="row">
          <h1>
            <center>
              <b class="text-dark">RESOURCES AND TOOLS</b>
            </center>
          </h1>
          <br></br>
          <br></br>
          <br></br>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed">Clinical Guidelines</h3>
                </center>
                <center>
                  <img src="../style/img/resp/book 1.png" class="limg1"></img>
                </center>
                <br></br>
                <p class="">
                  Access a comprehensive library of clinical guidelines and
                  treatment protocols to ensure best practices in patient care.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed1">Professional Development</h3>
                </center>
                <center>
                  <img
                    src="../style/img/resp/progress 1.png"
                    class="limg1"
                  ></img>
                </center>
                <br></br>
                <p class="">
                  Participate in webinars, workshops, and training programs
                  designed to enhance your skills and knowledge.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed">Research Articles</h3>
                </center>
                <center>
                  <img
                    src="../style/img/resp/text-analytics 1.png"
                    class="limg1"
                  ></img>
                </center>
                <br></br>
                <p class="">
                  Stay updated with the latest research and developments in
                  physiotherapy. Read articles and case studies to expand your
                  expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div class="container  rhfresource">
        <div class="row  ">
          <h1>
            <center>
              <b class="text-dark">COMMUNICATION AND SUPPORT </b>
            </center>
          </h1>
          <br></br>
          <br></br>
          <br></br>
          <div class="col-sm-2"></div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed1">Team Collaboration</h3>
                </center>
                <center>
                  <img
                    src="../style/img/resp/collaboration 1.png"
                    class="limg1"
                  ></img>
                </center>
                <br></br>
                <p class="">
                  Engage with your peers and other healthcare professionals
                  within the Respiro network. Share insights and collaborate on
                  patient care.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed">Secure Messaging</h3>
                </center>
                <center>
                  <img src="../style/img/resp/lock 1.png" class="limg1"></img>
                </center>
                <br></br>
                <p class="">
                  Communicate securely with patients and colleagues. Use our
                  messaging system for follow-ups and patient inquiries.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2"></div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed1">Technical Support</h3>
                </center>
                <center>
                  <img
                    src="../style/img/resp/customer-service 1.png"
                    class="limg1"
                  ></img>
                </center>
                <br></br>
                <p class="">
                  Need help? Our technical support team is here to assist you
                  with any issues or questions. Reach out for quick and reliable
                  support.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card landingcard ">
              <div class="container">
                <br></br>
                <center>
                  <h3 class="lhed">Stay Connected</h3>
                </center>
                <center>
                  <img src="../style/img/resp/people 1.png" class="limg1"></img>
                </center>
                <br></br>
                <p class="">
                  Follow us on social media to stay informed about the latest
                  updates, events, and resources from Respiro.
                  <br></br>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </div>
    </>
  );
}

export default Provider;
