import React from "react";

function Dash() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="row">
        <div class="col-sm-3 dashcolor">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <a href="/dash1">
            <button class="btn btn-light dashbtn">User Details</button>
          </a>
          <br></br>
          <br></br>
          <a href="/dash2">
            <button class="btn btn-light dashbtn">Online Book</button>
          </a>
          <br></br>
          <br></br>
          <br></br>
          <a href="/dash3">
            <button class="btn btn-light dashbtn">Ofline Book</button>
          </a>
          <br></br>
          <br></br>
          <br></br>
          <a href="/dash4">
            <button class="btn btn-light dashbtn">Patient Records</button>
          </a>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div class="col-sm-9">
          <br></br>
          <br></br>

          <br></br>
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100 vip dashbanner"
                  src="./style/img/banner/dash.jpg"
                  alt="First slide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dash;
