import Header from "../header/Header";
export default function Privacy() {
  return (
    <>
    <Header></Header>
                    <div class="container-fluid teambg">
      <div class="conatiner">
        <h3 class=" mainprivacyhead">
          <b class="text-dark privhd">Privacy Policy</b>
        </h3>
        <br></br>
      </div>
      <div class="container">
        <p class="privj">
          Effective Date : 18/07/2024 <br></br>
          Last updated on : 19/07/2024<br></br>
          <br></br>
          Welcome to Respiro Healthcare's website
           <a href="https://respirohealthcare.com" class="text-dark"> (https://respirohealthcare.com)</a>. Respiro Healthcare is dedicated to
          protecting your privacy. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your information when you visit our
          website. Please read this Privacy Policy carefully. By accessing or
          using our website, you agree to the terms of this Privacy Policy.
          <br></br>
          <br></br>
          <b class=" text-dark privacyhead"> 1.Information We Collect</b>
          <br></br>
          <div class="subprivacyhead">
            {" "}
            1.1 Personal Information<br></br>
          </div>
          <div class="subprivacycontent">
            &nbsp;We may collect personal information that can identify you,
            such as:<br></br>
            &nbsp; Contact Information: Name, email address, phone number,
            mailing address.<br></br>
            &nbsp; Account Information: Username, password, and other
            registration details.<br></br>
            &nbsp; Payment Information: Credit card details, billing address,
            and payment history.
          </div>
          <br></br>
          <div class="subprivacyhead">
            1.2 Usage Data<br></br>
          </div>
          <div class="subprivacycontent">
            &nbsp; We may collect information about how you interact with our
            website, including:<br></br>
            &nbsp;&nbsp; Log Data: Your IP address, browser type, operating
            system, referring URLs, pages visited, time spent on pages, and
            access times.<br></br>
            &nbsp; &nbsp;Device Information: Device type, operating system,
            browser type, and unique device identifiers.
          </div>
          <br></br>
          <div class="subprivacyhead">
            1.3 Cookies and Tracking Technologies<br></br>
          </div>
          <div class="subprivacycontent">
            &nbsp;We use cookies and similar tracking technologies to collect
            and store information, including:<br></br>
            &nbsp;&nbsp;Session Cookies: Temporary cookies that expire once you
            close your browser.<br></br>
            &nbsp;&nbsp;Persistent Cookies: Cookies that remain on your device
            until they expire or are deleted.<br></br>
            &nbsp;&nbsp;Tracking Pixels: Tiny graphics embedded in emails or
            webpages to track user interactions.
          </div>
          <br></br>
          <b class="text-dark privacyhead"> 2. How We Use Your Information</b>
          <br></br>
          <div class="privacycontent">
            We use the information we collect for various purposes, including:
            <br></br>
            Providing and Maintaining Our Services: To deliver the services and
            features you request, process transactions, and manage your account.
            <br></br>
            Improving Our Website: To analyze user behavior, enhance website
            functionality, and develop new features.<br></br>
            Communication: To send you updates, newsletters, promotional
            materials, and respond to your inquiries.<br></br>
            Security: To detect, prevent, and address technical issues, fraud,
            and unauthorized access.<br></br>
            Legal Compliance: To comply with applicable laws, regulations, and
            legal processes.
          </div>
          <br></br>
          <b class=" text-dark privacyhead">3. Sharing Your Information</b>
          <br></br>
          <div class="privacycontent">
            We do not sell, trade, or rent your personal information to third
            parties.
            <br></br>However, we may share your information with:<br></br>
            Service Providers: Third-party vendors who perform services on our
            behalf, such as payment processing, data analysis, email delivery,
            and customer service.<br></br>
            Business Transfers: In connection with a merger, acquisition, or
            sale of assets, your information may be transferred to the acquiring
            entity.<br></br>
            Legal Requirements: When required by law or in response to valid
            requests by public authorities (e.g., law enforcement, regulatory
            bodies).<br></br>
            With Your Consent: With your explicit consent or at your direction.
          </div>
          <br></br>
          <b class=" text-dark privacyhead">4. Security of Your Information</b>
          <br></br>
          <div class="privacycontent">
            We implement appropriate technical and organizational measures to
            protect your personal information against unauthorized access,
            alteration, disclosure, or destruction. These measures include:
            <br></br>
            Encryption: Using SSL/TLS encryption for data transmission.<br></br>
            Access Control: Restricting access to personal information to
            authorized personnel only.<br></br>
            Regular Audits: Conducting security audits and assessments to
            identify and address vulnerabilities.<br></br>
            Despite our efforts, no security system is impenetrable. We cannot
            guarantee the absolute security of your information.
          </div>
          <br></br>
          <b class=" text-dark privacyhead">
            5. Your Choices Regarding Your Information
          </b>
          <br></br>
          <div class="privacycontent">
            You have the following rights regarding your personal information:
            <br></br>
            Access: You may request access to the personal information we hold
            about you.<br></br>
            Correction: You may request corrections to any inaccurate or
            incomplete information.<br></br>
            Deletion: You may request the deletion of your personal information,
            subject to legal obligations and legitimate interests.<br></br>
            Opt-Out: You may opt out of receiving promotional communications
            from us by following the unsubscribe instructions in our emails or
            contacting us directly.<br></br>
            To exercise your rights or make any requests, please contact us at
            mailto: business@respirohealthcare.com
          </div>
          <br></br>
          <b class=" text-dark privacyhead">6. Children's Privacy</b>
          <br></br>
          <div class="privacycontent">
            Our website is not intended for individuals under the age of 18. We
            do not knowingly collect personal information from children. If we
            become aware that we have inadvertently received personal
            information from a child under 18, we will delete such information
            from our records.
          </div>
          <br></br>
          <b class=" text-dark privacyhead"> 7. Links to Other Websites</b>
          <br></br>
          <div class="privacycontent">
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these external
            sites. We encourage you to review the privacy policies of any
            third-party websites you visit.
          </div>
          <br></br>
          <b class=" text-dark privacyhead">
            8. Changes to This Privacy Policy
          </b>
          <br></br>
          <div class="privacycontent">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website
            with a revised effective date. We encourage you to review this
            Privacy Policy periodically to stay informed about how we protect
            your information.
          </div>
          <br></br>
          <b class=" text-dark privacyhead">9. Contact Us</b>
          <br></br>
          <div class="privacycontent">
            {" "}
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our privacy practices, please contact us:<br></br>
            Email: <a href="mailto:business@respirohealthcare.com"  classname="policy-contact">
              business@respirohealthcare.com
            </a><br></br> <br></br> 
            Address: <br></br>2nd floor, Golden Plaza, TB Junction, Angamaly 683572<br></br><br></br>
            By Phone: +91 8075892588<br></br><br></br>
            By Website Contact Form: <a href="https://respirohealthcare.com/contact" class="text-dark">https://respirohealthcare.com/contact</a>
            <br></br><br></br>
            Thank you for choosing Respiro Healthcare. Your privacy and trust are
            important to us.
          </div>
        </p>
      </div>
      </div>
    </>
  );
}
