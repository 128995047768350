import React from "react";

function Respirotherapist() {
  return (
    <>
      <br></br> <br></br>
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="d-block w-100 vip"
              src="./style/img/banner/RTbanner01.jpg"
              alt="First slide"
            />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <blockquote class="generic-blockquote">
              A respiratory therapist(RT) is a specialized healthcare
              practitioner trained in critical care and cardio-pulmonary
              medicine in order to work therapeutically with people who have
              acute critical conditions, cardiac and pulmonary disease.
              Respiratory therapists initiate and manage life support for people
              in intensive care units and emergency departments, stabilizing,
              treating and managing pre-hospital and hospital-to-hospital
              patient transport by air or ground ambulance. RT help improve
              outcomes for people with asthma, pneumonia, emphysema, lung
              trauma, and other diagnoses. RTs can assess your breathing,
              recommend exercises, and monitor your progress.
            </blockquote>
          </div>

          <div class="col-md-6">
            <img class="img-fluid" src="./style/img/banner/baby.jpg" alt="" />
          </div>
        </div>
      </div>
      <br></br> <br></br>
      <section class="hotline-area text-center area-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h1>How to join an RT?</h1>
              <p>
                How to join an RT? A respiratory therapist(RT) is a specialized
                healthcare practitioner trained in critical care and
                cardio-pulmonary medicine in order to work therapeutically with
                people who have acute critical conditions, cardiac and pulmonary
                disease. Respiratory therapists initiate and manage life support
                for people in intensive care units and emergency departments,
                stabilizing, treating and managing pre-hospital and
                hospital-to-hospital patient transport by air or ground
                ambulance. RT help improve outcomes for people with asthma,
                pneumonia, emphysema, lung trauma, and other diagnoses. RTs can
                assess your breathing, recommend exercises, and monitor your
                progress.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="team-area area-padding">
        <div class="container">
          <div class="area-heading row"></div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="204ea0">What an RT do?</font>
                    </a>
                  </h3>
                  <br></br>
                  <p class="ptext">
                    Treat a wide range of patients from infants to the elderly.
                    Teach patients how to use medications and equipment. Consult
                    with physicians to help develop individual patient care
                    plans. Perform regular checks on patients and equipment
                  </p>
                  <div class="team-footer d-flex justify-content-between"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img class="card-img rounded-0" src="img/team/2.jpg" alt="" />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="204ea0">Kinds of patients RT</font>
                    </a>
                  </h3>
                  <br></br>
                  <p class="ptext">
                    Lung disease patientsHeart patients Neuromuscular patients
                    Accident victims Premature babies children, adults and
                    elderly
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="team-footer d-flex justify-content-between"></div>
                </div>
              </div>
            </div>{" "}
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img class="card-img rounded-0" src="img/team/2.jpg" alt="" />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="204ea0">What disorders RT Treat</font>
                    </a>
                  </h3>
                  <br></br>
                  <p class="ptext">
                    Asthma Bronchitis Emphysema Chronic obstructive Pulmonary
                    disease Pneumonia children, adults Chest trauma
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="team-footer d-flex justify-content-between"></div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img class="card-img rounded-0" src="img/team/3.jpg" alt="" />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="204ea0">Therapeutic procedures</font>
                    </a>
                  </h3>
                  <br></br>
                  <p class="ptext">
                    {" "}
                    Mechanical ventilation Oxygen administration Medical
                    Administration Chest physiotherapy Lung expansion therapy
                    Pulmonary rehabilitation Smoking cessation
                  </p>
                  <br></br>
                  <br></br>
                  <div class="team-footer d-flex justify-content-between"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Respirotherapist;
