
import AdminHeader from '../header/AdminHeader'
import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';

import {
    getFunction,
    setFunction,
    updateFunction,
    deleteFunction,
} from "../../api/apiFunctions";
function ViewUnits() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const coordinatorId = id;
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
        getFunction({ coordinatorId }, "/coordinator/get-all-rhf-for-area-coordinator")
            .then((response) => {
                const responseData = response.success.result;
                setData(responseData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <>
            <AdminHeader />
            <center>
                <div className="datatable"  >
                    <h4 style={{ color: "#3131e5" }}>Units</h4>
                    <div className="col-sm-8" >
                  <div style={{ overflowX: "scroll" }}>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="th-sm">S.No</th>
                                    <th className="th-sm">Unit Number</th>
                                    <th className="th-sm">Contact Name</th>
                                    <th className="th-sm" >Phone</th>
                                    <th className="th-sm" >Email</th>
                                    <th className="th-sm">District</th>
                                    <th className="th-sm">Member Count</th>
                                    <th className="th-sm">Total Amount Earned</th>
                                    <th className="th-sm">Average Income</th>
                                    <th className="th-sm">Total working days</th>
                                    <th className="th-sm">Avg daily booking</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length === 0 ? (
                                    <div className="card__detail">
                                        <div className="card__source text-bold">No units available under this area.</div>
                                    </div>
                                ) : (
                                    data && data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td> {index + 1}</td>
                                            <td>{item.unit_number}</td>
                                            <td>{item.contact_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.districtname}</td>
                                            <td>{item.member_count}</td>
                                            <td>{item.total_amount_earned}</td>
                                            <td>{item.avg_income}</td>
                                            <td>{item.total_working_days}</td>
                                            <td>{item.avg_daily_booking}</td>                                       
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        </div>
                        <br />
                    </div>
                </div>
          </center>
        </>
    )
}

export default ViewUnits