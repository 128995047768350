import React from "react";

function Intervetional() {
  return (
    <>
      <br></br>

      <div class="service-area area-padding-top">
        <div class="container">
          <div class="area-heading row">
            <div class="col-md-5 col-xl-4">
              <h3>
                <font color="204ea0">Interventional Pulmonology</font>
                <br />
              </h3>
            </div>
            <div class="col-md-7 col-xl-8">
              <p>
                The relatively new and growing field of interventional
                pulmonology is an area of pulmonary medicine that focuses on
                using minimally invasive endoscopic techniques to diagnose and
                treat lung, thoracic, tracheal and mediastinal conditions.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="card-service text-center text-lg-left mb-4 mb-lg-0">
                <div class=" zoom-shadow icon">
                  <div class="inner">
                    <img src="./style/img/banner/pn.jpg" class="" alt="" />
                  </div>
                </div>
                <h3 class="card-service__title">Pneumothorax</h3>

                <a class="card-service__link" href="/Pneumothorax">
                  Learn More
                </a>
                <br></br>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="card-service text-center text-lg-left mb-4 mb-lg-0">
                <div class=" zoom-shadow icon">
                  <div class="inner">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <img src="./style/img/banner/lu.jpg" class="icon" alt="" />
                  </div>
                </div>
                <h3 class="card-service__title">Lung Cancer</h3>
                <a class="card-service__link" href="/Lungcancer">
                  Learn More
                </a>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="card-service text-center text-lg-left mb-4 mb-lg-0">
                <div class=" zoom-shadow icon">
                  <div class="inner">
                    <br></br>
                    <br></br>
                    <img src="./style/img/banner/as.jpg" class="icon" alt="" />
                  </div>
                </div>
                <h3 class="card-service__title">Asthma</h3>
                <a class="card-service__link" href="/Asthma">
                  Learn More
                </a>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="card-service text-center text-lg-left mb-4 mb-lg-0">
                <div class=" zoom-shadow icon">
                  <div class="inner">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <img src="./style/img/banner/cp1.jpg" class="icon" alt="" />
                  </div>
                </div>
                <br></br>
                <h3 class="card-service__title">Copd</h3>
                <a class="card-service__link" href="/Copd">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intervetional;
