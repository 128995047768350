const RentDATA = [
  {
    id: 1,

    title: "Standard Walker",
    price: 214,
    desc: " Reliable and sturdy, ideal for everyday mobility. assistance Easy to use with adjustable height options.",
    img: "../style/img/data3/std_walker.png",

  },
  {
    id: 2,
    title: "Folding Walker",
    price: 257,
    desc: " Conveniently folds for easy storage and transport. Provides stable support with enhanced portability.",
    img: "../style/img/data3/foldable_walker.png",
  },
  {
    id: 3,
    title: "Hemi Walker",
    price: 228,
    desc: " Designed for those with limited mobility, offering one-sided support for improved stability and ease of use.",
    img: "../style/img/data3/hemi_walker.png",
  },
  {
    id: 4,
    title: "Two-Wheeled Walker",
    price: 285,
    desc: "Equipped with four wheels for easy movement and added stability. Includes a built-in seat for resting.",
    img: "../style/img/data3/two_wheeled_walker.png",
  },
  {
    id: 5,
    title: "Four-Wheeled Walker",
    price: 285,
    desc: "  Features wheels for smooth movement and maneuverability. Ideal for users requiring extra mobility support.",
    img: "../style/img/data3/four_wheeled_walker.png",
  },

  {
    id: 6,
    title: "Portable Nano Oxygen Nebulizer",
    price: 500,
    desc: "Compact and travel-friendly for on-the-go respiratory care. Delivers efficient nebulization anytime, anywhere.",
    img: "../style/img/data3/portable_nebu.png",
  },
  {
    id: 7,
    title: "Desktop Nano Oxygen Nebulizer",
    price: 570,
    desc: "Ideal for home use, offering reliable performance with a sleek design. Perfect for consistent respiratory therapy.",
    img: "../style/img/data3/desktop_nebu.png",
  },
  {
    id: 8,
    title: "Handheld Nano Oxygen Nebulizer",
    price: 428,
    desc: " Convenient and lightweight for personalized use. Provides effective nebulization with easy handling.",
    img: "../style/img/data3/handheld_nebu.png",
  },
  {
    id: 9,
    title: "Single Air Bed",
    price: 1000,
    desc: " Comfortable and durable for single-person use. Ideal for guest accommodations and temporary bedding solutions.",
    img: "../style/img/data3/single_airbed.png",
  },
  {
    id: 10,
    title: "Double Air Bed",
    price: 1143,
    desc: "Spacious and supportive for two individuals. Provides a comfortable sleep experience with easy inflation.",
    img: "../style/img/data3/double_airbed.png",
  },
  {
    id: 11,
    title: "Queen Air Bed",
    price: 1285,
    desc: " Offers ample space and comfort for a restful night's sleep. Designed for both durability and ease of use.",
    img: "../style/img/data3/queen_airbed.png",
  },
  {
    id: 12,
    title: "King Air Bed",
    price: 1428,
    desc: " Extra-large bed providing luxurious comfort and support. Perfect for a premium sleep experience.",
    img: "../style/img/data3/king_airbed.png",
  },
  {
    id: 13,
    title: "Air Bed with Pump",
    price: 1071,
    desc: " Includes a built-in pump for quick and effortless inflation. Combines convenience with comfort.",
    img: "../style/img/data3/airbed_pump.png",
  },

  {
    id: 14,
    title: "Digital BP Monitor",
    price: 214,
    desc: "User-friendly device for accurate blood pressure readings. Includes memory function for tracking measurements.",
    img: "../style/img/data3/digital_BP.png",
  },
  {
    id: 15,
    title: "Manual BP Monitor",
    price: 142,
    desc: " Traditional sphygmomanometer for precise blood pressure measurements. Ideal for professional use.",
    img: "../style/img/data3/manual_BP.png",
  },
  {
    id: 16,
    title: "Wrist BP Monitor",
    price: 285,
    desc: "Compact and easy-to-use for monitoring blood pressure at the wrist. Ideal for on-the-go measurements.",
    img: "../style/img/data3/wrist_BP.png",
  },

  {
    id: 17,
    title: "Fingertip Pulse Oximeter (FPO-99)",
    price: 171,
    desc: "Compact and easy-to-use, this oximeter provides quick and accurate measurements of blood oxygen levels and pulse rate. ",
    img: "../style/img/data3/FPO-99.png",
  },
  {
    id: 18,
    title: "Fingertip Pulse Oximeter (FPO-101)",
    price: 200,
    desc: "Reliable and portable, designed for efficient monitoring of oxygen saturation and pulse with a clear LED display.  ",
    img: "../style/img/data3/FPO-101.png",
  },
  {
    id: 20,
    title: "Table Top Pulse Oximeter (Flight 70)",
    price: 785,
    desc: "Designed for stationary use, this oximeter delivers accurate and consistent readings with a large, easy-to-read display.  ",
    img: "../style/img/data3/flight-70.png",
  },
  {
    id: 21,
    title: "Pulse Oximeter (Hand Held) (Flight 60)",
    price: 571,
    desc: " Portable and versatile, providing reliable oxygen saturation and pulse rate measurements with a user-friendly interface.",
    img: "../style/img/data3/flight-60.png",
  },
  {
    id: 22,
    title: "Pulse Oximeter (Hand Held) (Flight 65)",
    price: 642,
    desc: "Advanced hand-held model with enhanced accuracy and durability, ideal for clinical and home use. ",
    img: "../style/img/data3/flight-65.png",
  },
  {
    id: 23,
    title: "Pulse Oximeter with NIBP(FLIGHT 5100)",
    price: 2142,
    desc: " Comprehensive device offering both pulse oximetry and non-invasive blood pressure monitoring for thorough patient assessment.",
    img: "../style/img/data3/flight-5100.png",
  },
  {
    id: 24,
    title: "Mercury-free Sphygmomanometer",
    price: 285,
    desc: "Environmentally friendly alternative providing precise blood pressure measurements without mercury.",
    img: "../style/img/data3/mercurysphygmomanometer.webp",
  },
  {
    id: 25,
    title: "Aneroid Table Model",
    price: 357,
    desc: "Durable and reliable table model aneroid sphygmomanometer for accurate blood pressure readings in clinical settings.",
    img: "../style/img/data3/aneroidtable.webp",
  },
  {
    id: 26,
    title: "Portable Aneroid Sphygmomanometer",
    price: 428,
    desc: "Compact and portable, offering accurate blood pressure measurements with a user-friendly design for on-the-go use.",
    img: "../style/img/data3/portableaneroidsphygmoma.webp",
  },
  {
    id: 27,
    title: "Electronic BP Monitor (PW-215)",
    price: 285,
    desc: "Automatic and easy-to-use, providing accurate blood pressure readings with a clear digital display.",
    img: "../style/img/data3/pw215bpmonitor.webp",
  },
  {
    id: 28,
    title: "Electronic BP Monitor (PW-218)",
    price: 357,
    desc: "Features advanced measurement technology for precise blood pressure tracking and a user-friendly interface.",
    img: "../style/img/data3/pw-218.png",
  },
  {
    id: 29,
    title: "Electronic BP Monitor (PW-220)",
    price: 428,
    desc: "Offers quick and reliable blood pressure readings with added features for enhanced accuracy and convenience.",
    img: "../style/img/data3/pw220bpmonitor.webp",
  },
  {
    id: 30,
    title: "Electronic BP Monitor (PW-222)",
    price: 500,
    desc: "High-performance monitor with adjustable settings and memory function for comprehensive blood pressure management.",
    img: "../style/img/data3/pw222bpmonitor.webp",
  },
  {
    id: 31,
    title: "Patient Monitor (flight 6000)",
    price: 7142,
    desc: "Advanced device for continuous monitoring of vital signs, providing real-time data to support patient care and medical decision-making. ",
    img: "../style/img/data3/Flight_6000.jpg",
  },
  {
    id: 32,
    title: "Multi Parameter Patient Monitor (flight 7000)",
    price: 10000,
    desc: "Advanced monitor providing comprehensive vital signs monitoring with real-time data and clear display for clinical settings.",
    img: "../style/img/data3/flight7000patientmonitor.png",
  },
  {
    id: 33,
    title: "Multi Parameter Patient Monitor (flight 8000)",
    price: 12142,
    desc: "High-performance model featuring multiple parameters and customizable settings for precise patient monitoring.",
    img: "../style/img/data3/flight8000patientmonitor.webp",
  },
  {
    id: 34,

    title: "Multi Parameter Patient Monitor (flight 9000)",
    price: 14285,
    desc: "Premium monitor with extensive features including enhanced connectivity and real-time analysis for critical care. ",
    img: "../style/img/data3/flight9000patientmonitor.webp",
  },
  {
    id: 35,
    title: "Multi Parameter Patient Monitor (flight AQUA 12)",
    price: 17142,
    desc: "Offers detailed vital signs monitoring with advanced display and robust functionality for accurate patient management.",
    img: "../style/img/data3/aqua12.png",
  },
  {
    id: 36,
    title: "Multi Parameter Patient Monitor (flight AQUA 15)",
    price: 21428,
    desc: " State-of-the-art monitor with a wide range of parameters and high-definition display, designed for complex clinical environments.",
    img: "../style/img/data3/aqua15.png",
  },
  {
    id: 37,
    title: "ECG Machine Single Channel",
    price: 1714,
    desc: "Compact and efficient, providing essential single-channel ECG readings for basic cardiac assessments.",
    img: "../style/img/data3/single_channel.webp",
  },
  {
    id: 38,
    title: "ECG Machine Three Channel",
    price: 2857,
    desc: "Offers multi-lead ECG capabilities with improved accuracy for comprehensive cardiac monitoring.",
    img: "../style/img/data3/3_channel.png",
  },
  {
    id: 39,
    title: "ECG Machine Six Channel",
    price: 4285,
    desc: "Enhanced model for detailed cardiac analysis with six channels, suitable for a wide range of clinical needs.",
    img: "../style/img/data3/6_channel.png",
  },
  {
    id: 40,
    title: "ECG Machine Twelve Channel",
    price: 7142,
    desc: "Advanced ECG machine with twelve channels for in-depth cardiac evaluations and detailed analysis. ",
    img: "../style/img/data3/12_channel.png",
  },
  {
    id: 41,
    title: "ECG Workstation",
    price: 17142,
    desc: " High-tech workstation providing integrated ECG analysis, storage, and reporting features for efficient cardiac care management.e",
    img: "../style/img/data3/ECG_Workstation.png",
  },
  {
    id: 42,
    title: "Portable ECG Monitor",
    price: 3571,
    desc: " Lightweight and user-friendly, this monitor allows for convenient ECG recording and monitoring on the go. ",
    img: "../style/img/data3/portable_ECGmonitor.png",
  },
  {
    id: 43,
    title: "Holter ECG system",
    price: 8571,
    desc: "Comprehensive system for continuous 24-hour ECG monitoring, capturing detailed cardiac activity over extended periods.",
    img: "../style/img/data3/Holter_ECGsystem.png",
  },
  {
    id: 44,
    title: "EEG Machines 16 channel",
    price: 11428,
    desc: "Advanced 16-channel EEG system offering high-resolution brainwave monitoring for neurological assessments.",
    img: "../style/img/data3/EEGMachines_16.png",
  },
  {
    id: 45,
    title: "EEG Machines 24 channel",
    price: 14285,
    desc: "Premium 24-channel EEG machine providing extensive data and detailed brain activity analysis for specialized neurodiagnostic use.",
    img: "../style/img/data3/ECGMachine_24.png",
  },
  {
    id: 46,
    title: "SpO2 Simulator",
    price: 3571,
    desc: "Provides simulated SpO2 readings for testing and calibrating pulse oximeters, ensuring accurate device performance. ",
    img: "../style/img/data3/spo2_simulator.png",
  },
  {
    id: 47,
    title: "NIBP Simulator",
    price: 4285,
    desc: "Simulates non-invasive blood pressure readings for device testing and calibration, enhancing measurement reliability.",
    img: "../style/img/data3/nibp_simulator.png",
  },
  {
    id: 48,
    title: "ECG Simulator",
    price: 5714,
    desc: "Delivers various ECG waveforms for testing and calibrating ECG machines, ensuring accurate cardiac readings.",
    img: "../style/img/data3/ECG_simulator.png",
  },
  {
    id: 49,
    title: "Sleep Apnea",
    price: 7142,
    desc: "Advanced monitoring device for diagnosing and managing sleep apnea, providing comprehensive sleep study data.",
    img: "../style/img/data3/sleepapnea_model.png",
  },
  {
    id: 51,
    title: "Ambulatory BP Monitor with SpO2",
    price: 6428,
    desc: "Portable device for continuous blood pressure and SpO2 monitoring, ideal for long-term patient tracking.",
    img: "../style/img/data3/Ambulatory_BP.png",
  },
  {
    id: 52,
    title: "EtCO2 Module",
    price: 7142,
    desc: "Measures end-tidal CO2 levels for accurate respiratory monitoring in critical care environments.",
    img: "../style/img/data3/etco2_module.png",
  },
  {
    id: 53,
    title: "Fetal Monitor (BT-350)",
    price: 8571,
    desc: "Comprehensive fetal monitor providing real-time fetal heart rate and contraction monitoring.",
    img: "../style/img/data3/BT_350.png",
  },
  {
    id: 54,
    title: "Fetal Monitor (flight 800)",
    price: 10714,
    desc: "High-performance fetal monitor offering detailed fetal assessments and continuous monitoring.",
    img: "../style/img/data3/flight-800.png",
  },
  {
    id: 55,
    title: "Fetal Doppler",
    price: 2142,
    desc: "Portable device for detecting and monitoring fetal heartbeats, ensuring quick and easy assessments.",
    img: "../style/img/data3/fetal_Doppler.png",
  },
  {
    id: 56,
    title: "Infusion/Blood Warmer",
    price: 4285,
    desc: "Warms infusion fluids and blood for safe and effective patient treatment.",
    img: "../style/img/data3/blood_warmer.png",
  },
  {
    id: 57,
    title: "Bi-PAP with Tidal Volume",
    price: 10000,
    desc: "Provides bilevel positive airway pressure with adjustable tidal volume settings for respiratory support.",
    img: "../style/img/data3/Tidal Volume.png",
  },
  {
    id: 58,
    title: "Bi-PAP with Avaps",
    price: 12142,
    desc: "Advanced bilevel positive airway pressure with adaptive ventilation for optimized respiratory care.",
    img: "../style/img/data3/bipap-avaps.png",
  },
  {
    id: 59,
    title: "Auto C-PAP",
    price: 8571,
    desc: "Automatic continuous positive airway pressure device for effective treatment of sleep apnea. ",
    img: "../style/img/data3/G2S.png",
  },
  {
    id: 60,
    title: "Oxygen Concentrator (single flow)",
    price: 3571,
    desc: "Supplies oxygen to patients with a single flow setting, ideal for home use. ",
    img: "../style/img/data3/OC_SINGLE.jpg",
  },
  {
    id: 61,
    title: "Oxygen Concentrator (dual flow)",
    price: 5000,
    desc: "Provides oxygen with dual flow settings for simultaneous use by multiple patients.",
    img: "../style/img/data3/OC_dual.png",
  },
  {
    id: 62,
    title: "Oxygen Concentrator (OC-701)",
    price: 5714,
    desc: "High-efficiency oxygen concentrator for reliable oxygen delivery in medical settings.",
    img: "../style/img/data3/OC_701.jfif",
  },
  {
    id: 63,
    title: "Oxygen Concentrator (OC-7B)",
    price: 7142,
    desc: "Advanced oxygen concentrator offering high flow rates and durability for clinical use.",
    img: "../style/img/data3/OC-7B.jfif",
  },
  {
    id: 64,
    title: "Oxygen Concentrator (AIRSEP)",
    price: 6428,
    desc: "Compact and efficient oxygen concentrator suitable for various medical applications. ",
    img: "../style/img/data3/AIRSEP.png",
  },

  {
    id: 65,
    title: "Photo Therapy",
    price: 5714,
    desc: "Device for treating neonatal jaundice with effective light therapy, ensuring safer and faster recovery.",
    img: "../style/img/data3/phototherapy.jpg",
  },

  {
    id: 67,
    title: "Portable Ventillator",
    price: 11428,
    desc: "Compact and mobile ventilator for respiratory support in various clinical and emergency settings.",
    img: "../style/img/data3/portable_vent.jpg",
  },
  {
    id: 68,
    title: "Emergency Ventillator",
    price: 14285,
    desc: "Designed for rapid and reliable respiratory support during emergencies.",
    img: "../style/img/data3/emergency_vent.jpg",
  },
  {
    id: 69,
    title: "ICU Ventillator",
    price: 21428,
    desc: "Advanced ventilator for intensive care units, offering comprehensive respiratory support and monitoring. ",
    img: "../style/img/data3/ICU_vent.jpg",
  },
  {
    id: 70,
    title: "Anesthesia Workstation",
    price: 28571,
    desc: "Complete workstation for administering anesthesia with integrated monitoring and support features.",
    img: "../style/img/data3/ACES.jpeg",
  },
  {
    id: 71,
    title: "Biphasic Defibrillator",
    price: 10000,
    desc: "Provides controlled shock delivery for cardiac resuscitation, essential for emergency care. ",
    img: "../style/img/data3/D-Fab.jpg",
  },
  {
    id: 72,
    title: "Biphasic Defibrillator with AED",
    price: 12142,
    desc: "Combines biphasic defibrillation with an automated external defibrillator for versatile cardiac care. ",
    img: "../style/img/data3/flight_defib_AED.jpg",
  },
  {
    id: 73,
    title: "Automatic External Defibrillator (AED-7000)",
    price: 8571,
    desc: "User-friendly AED for public and clinical use with advanced defibrillation capabilities.",
    img: "../style/img/data3/AED_7000.jpg",
  },
  {
    id: 74,
    title: "Automatic External Defibrillator (AED VIEW)",
    price: 10714,
    desc: "Features enhanced visual and auditory prompts for effective defibrillation.",
    img: "../style/img/data3/aed-view.jpg",
  },
  {
    id: 75,
    title: "AED Trainer",
    price: 2857,
    desc: "Training device for practicing AED use and cardiac emergency response.",
    img: "../style/img/data3/aed-trainer.webp",
  },

  {
    id: 76,
    title: "Spirometer",
    price: 500,
    desc: "Measures lung function to assess respiratory conditions with accuracy and ease.",
    img: "../style/img/data3/digital-spirometer.webp",
  },

  {
    id: 77,
    title: "Tubular Air Mattress",
    price: 928,
    desc: " Provides pressure relief and comfort with a tubular design for patients at risk of bedsores.",
    img: "../style/img/data3/tubular-air-mattress.webp",
  },
  {
    id: 78,
    title: "Video Laryngoscope (NMVL-01)",
    price: 6428,
    desc: " Advanced laryngoscope with video capabilities for improved intubation visibility and accuracy.",
    img: "../style/img/data3/video-laryngoscope-nmvl-01.webp",
  },
  {
    id: 79,
    title: "Video Laryngoscope (NMVL-02)",
    price: 7142,
    desc: " High - definition video laryngoscope designed for enhanced visualization during intubation.",
    img: "../style/img/data3/laryngoscope-nmvl-02.jpeg",
  },
  {
    id: 80,
    title: "Endoscopy Equipments",
    price: 10714,
    desc: " Comprehensive endoscopy system for detailed internal examinations and diagnostics.",
    img: "../style/img/data3/endoscopy-equipments.webp",
  },
  {
    id: 81,
    title: "Portable Light Source (NM-PLS)",
    price: 1714,
    desc: " Compact and portable light source for endoscopic procedures and examinations.",
    img: "../style/img/data3/portable-light.webp",
  },
  {
    id: 82,
    title: " Light Source (NM-LS)",
    price: 2571,
    desc: " Reliable light source for high - quality illumination during endoscopic and surgical procedures.",
    img: "../style/img/data3/light-source.webp",
  },
  {
    id: 83,
    title: "Endoscopy Camera",
    price: 3571,
    desc: " High - resolution camera for capturing detailed images during endoscopic procedures.",
    img: "../style/img/data3/endoscopy-camera.webp",
  },
  {
    id: 84,
    title: "Nebulizer(NB-117)",
    price: 357,
    desc: " Efficient nebulizer for respiratory treatments with adjustable settings for patient comfort.",
    img: "../style/img/data3/nebulizer-117.webp",
  },
  {
    id: 85,
    title: " Nebulizer(NB-119)",
    price: 428,
    desc: " Compact and effective nebulizer for delivering medication to the lungs.",
    img: "../style/img/data3/nb-119-nebulizer.webp",
  },
  {
    id: 86,
    title: "Nebulizer(NB-121)",
    price: 457,
    desc: " Advanced nebulizer with enhanced delivery system for optimal respiratory care.",
    img: "../style/img/data3/NB-121-neblizer.webp",
  },
  {
    id: 87,
    title: "Nebulizer(NB-122)",
    price: 500,
    desc: " High - performance nebulizer designed for efficient and consistent medication delivery.",
    img: "../style/img/data3/nb-122-nebulizer.webp",
  },
  {
    id: 88,
    title: "Mesh Nebulizer",
    price: 542,
    desc: " Portable nebulizer with mesh technology for effective medication delivery.",
    img: "../style/img/data3/mesh-nebulizer.webp",
  },
  {
    id: 89,
    title: "Ultrasonic Nebulizer",
    price: 600,
    desc: " Ultrasonic nebulizer offering fine mist and high medication delivery efficiency.",
    img: "../style/img/data3/ultrasonic-nebulizer.webp",
  },
  {
    id: 90,
    title: "Hand Suction",
    price: 357,
    desc: " Manual suction device for clearing airways or collecting fluids during medical procedures.",
    img: "../style/img/data3/hand-suction.webp",
  },
  {
    id: 91,
    title: "Pedal Suction",
    price: 571,
    desc: " Pedal - operated suction device designed for convenience and efficiency in various medical settings.",
    img: "../style/img/data3/pedal-suction.webp",
  },
  {
    id: 92,
    title: "Portable Phleghm Suction",
    price: 785,
    desc: " Compact suction device ideal for removing phlegm and mucus in portable applications.",
    img: "../style/img/data3/portable-phlegm.webp",
  },
  {
    id: 93,
    title: "Portable Suction",
    price: 857,
    desc: " Versatile suction device for use in various settings including emergency and home care.",
    img: "../style/img/data3/portablesuction-aspiret.webp",
  },
  {
    id: 94,
    title: "Trolley model Suction(SU-103)",
    price: 1142,
    desc: " Mobile suction unit with trolley for easy maneuverability and efficient suctioning.",
    img: "../style/img/data3/trolley-su-103.webp",
  },
  {
    id: 95,
    title: "Trolley model Suction(SU-105)",
    price: 1285,
    desc: " Advanced suction trolley designed for high performance and easy transport.",
    img: "../style/img/data3/trolley-su-105.webp",
  },
  {
    id: 96,
    title: "Trolley model Suction(HOSPIVAC-350)",
    price: 1428,
    desc: " Robust suction unit with trolley for use in hospitals and clinics.",
    img: "../style/img/data3/trolley-hospivac.webp",
  },

  {
    id: 97,
    title: "Infra red Lamp",
    price: 314,
    desc: " Infrared lamp for therapeutic heat treatment to relieve muscle pain and improve circulation.",
    img: "../style/img/data3/infra-red-lamp.webp",
  },
  {
    id: 99,
    title: " Autoloader Collapsable Stretcher",
    price: 1142,
    desc: " Lightweight, collapsible stretcher with autoloader feature for easy transportation.",
    img: "../style/img/data3/autoloader-strecher.webp",
  },
  {
    id: 100,
    title: " Scoop Stretcher",
    price: 857,
    desc: " Durable scoop stretcher for safe and efficient patient transfer in emergency situations.",
    img: "../style/img/data3/scoop-stretcher.webp",
  },
  {
    id: 101,
    title: " Stair Stretcher",
    price: 1000,
    desc: " Specialized stretcher designed for navigating stairs and tight spaces with ease.",
    img: "../style/img/data3/stair-stretcher.webp",
  },
  {
    id: 102,
    title: " Two Fold Stretcher",
    price: 785,
    desc: " Versatile two - fold stretcher for easy storage and transport in emergency scenarios.",
    img: "../style/img/data3/two-fold-stretcher.webp",
  },
  {
    id: 103,
    title: " Four Fold Stretcher",
    price: 928,
    desc: " Compact four - fold stretcher with enhanced portability and storage capabilities.",
    img: "../style/img/data3/four-fold-stretcher.webp",
  },
  {
    id: 104,
    title: " Spine Board",
    price: 642,
    desc: " Rigid spine board for immobilizing patients and preventing spinal injuries during transport.",
    img: "../style/img/data3/spine-board.webp",
  },
  {
    id: 106,
    title: " Head Immobilizer",
    price: 257,
    desc: " Head immobilizer designed to prevent head and neck movement during patient transport.",
    img: "../style/img/data3/head-immbolizer.webp",
  },
  
  {

    id: 109,

    title: " Fogger Machine(SM-01)",
    price: 785,
    desc: "Efficient fogger machine for disinfection and sanitization in medium to large spaces.",
    img: "../style/img/data3/fogger-sm-01.jpeg",
  },
  {
    id: 110,
    title: " Fogger Machine(SM-02)",
    price: 1071,
    desc: "Advanced fogger machine for thorough and effective fogging in diverse environments.",
    img: "../style/img/data3/fogger-sm-02.webp",
  },
  {
    id: 111,
    title: " Dolphin Massager",
    price: 314,
    desc: " Handheld massager with dolphin - shaped design for targeted muscle relaxation.",
    img: "../style/img/data3/Dolphin massager.webp",
  },
  {
    id: 112,
    title: " Hammer Massager",
    price: 357,
    desc: " Massager with a hammer - like design for deep tissue muscle relief and relaxation.",
    img: "../style/img/data3/hammer_massager.jpg",
  },
  {
    id: 113,
    title: " Manipol Massager",
    price: 400,
    desc: " Advanced manipol massager for versatile massage techniques and effective muscle treatment.",
    img: "../style/img/data3/manipolmassager.webp",
  },

  {
    id: 114,
    title: " Monipol Massager",
    price: 371,
    desc: " Ergonomic monipol massager designed for targeted muscle relief and improved circulation.",
    img: "../style/img/data3/Monipol_Massager.webp",
  },
  {
    id: 115,
    title: " Foot Massager",
    price: 500,
    desc: " Foot massager designed to provide soothing relief and relaxation to tired feet.",
    img: "../style/img/data3/foot massager.webp",
  },
  {
    id: 116,
    title: " Leg Massager",
    price: 571,
    desc: " Comprehensive leg massager offering full - leg coverage for relaxation and pain relief.",
    img: "../style/img/data3/legmassager.webp",
  },
  {
    id: 117,
    title: " Three Para Monitor Stand with basket",
    price: 642,
    desc: " Sturdy stand for three-parameter monitors, equipped with a convenient basket for storing accessories and supplies.",
    img: "../style/img/data3/3paramonitorstand.webp",
  },
  {
    id: 118,
    title: "Multi Para Monitor Wall Mount Stand with basket ",
    price: 857,
    desc: " Versatile stand designed for multiparameter monitors, featuring a basket for additional storage of medical tools and supplies.",
    img: "../style/img/data3/multiparamonitorstand.webp",
  },
  {


    id: 119,

    title: "Patient Back Rest",
    price: 214,
    desc: "Adjustable back rest providing comfort and support for patients in bed or seated positions.",
    img: "../style/img/data3/back-rest.png",
  },
];

export default RentDATA;
