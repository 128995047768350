import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addItem, delItem } from '../../redux/actions';
import ArtistData from '../../data/ArtistData';

import Header from '../header/Header';

function ArtistDetail() {
  const [cartBtn, setCartBtn] = useState('Add to Cart');
  const { id, mid } = useParams(); // Using id for TeacherData and mid for MusicianData
  const dispatch = useDispatch();

  let product = null;

    product = ArtistData.find((item) => item.id == id); // Fetch teacher product by id
 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCart = (product) => {
    if (cartBtn === 'Add to Cart') {
      dispatch(addItem(product));
      setCartBtn('Remove from Cart');
    } else {
      dispatch(delItem(product));
      setCartBtn('Add to Cart');
    }
  };

  const [activeImageSrc, setActiveImageSrc] = useState(product ? product.img : '');

  const changeImage = (e) => {
    setActiveImageSrc(e.target.src);
  };

  return (
    <>
      <Header />
      <main>
        <div className="container">
          <div className="grid1 product">
            <div className="column-xs-7 column-md-7">
                <div className="product-image">
                  <img className="active" src={activeImageSrc} alt="Active Product" />
                </div>
            </div>
            <div className="column-xs-5 column-md-5" style={{marginLeft:"600px"}}>
              {product && (
                <>
                  <h1 style={{ fontWeight: '700', fontSize: '38px' }}>{product.title}</h1>
                  <h2>₹{product.price}</h2>
                  <div className="description">
                    <p>{product.desc}</p>
                  </div>

                  <button onClick={() => handleCart(product)} className="add-to-cart">
                    <i className="fa-solid fa-cart-shopping"></i>&nbsp;{cartBtn}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ArtistDetail;
