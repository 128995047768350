import React from "react";

function Refundpolicy() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="container">
        <h3>
          <font color="204ea0">Refund & Cancellation</font>
        </h3>
        <br />
        <p>
          Our focus is complete customer satisfaction. In the event, if you are
          displeased with the services provided, we will refund back the money,
          provided the reasons are genuine and proved after investigation.
          Please read the fine prints of each service before buying it, it
          provides all the details about the services or the product you
          purchase.
        </p>
        <br></br>
        <p>
          In case of dissatisfaction from our services, clients have the liberty
          to cancel their projects and request a refund from us. Our Policy for
          the cancellation and refund will be as follows:
        </p>
        <h5>Cancellation Policy</h5>
        <p>
          For Cancellations please contact us via contact us link. Cancellation
          after payment for service will not be considered.
        </p>
        <h5>Refund Policy</h5>
        <p>
          We will try our best to provide service for which client has paid for.
        </p>
        <br></br>
        <p>
          If paid by credit card, refunds will be issued to the original credit
          card provided at the time of purchase and in case of payment gateway
          name payments refund will be made to the same account.
        </p>
        <br></br>
        <p>
          In case of any queries mail at:{" "}
          <font color="204ea0">labs.respiro@gmail.com</font>
        </p>
      </div>
      <br></br> <br></br>
    </>
  );
}
export default Refundpolicy;
