import Header from "../header/Header";
export default function Refund() {
  return (
    <>
    <Header></Header>
            <div class="container-fluid teambg">
      <div class="container">
        <b class="text-dark">
          <h3 class=" termshead ">Refund policy</h3>
        </b>
        <br></br>
        <p>
          <strong>Effective Date: 18/07/2024</strong>{" "}
        </p>
        <br></br>
        <p class=" termsp1 privj">
          Thank you for choosing Respiro Healthcare. We strive to provide the
          best possible service and support to our customers. Our refund policy
          is designed to ensure your satisfaction while maintaining the
          integrity of our services and products. Please read the following
          policy carefully.
        </p>
        <h4> General Refund Guidelines</h4>
        <h5 class="privacyhead">
          <b class="text-dark"> 1.Refund Eligibility</b>
        </h5>
        <p class=" termsp1 privj">
          Refund requests must be made within 3 to 7 days from the date of
          purchase.<br></br>
          Refunds will be processed within 3 to 7 days after the request has
          been approved.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">2.Non-Refundable Services</b>
        </h5>
        <p class=" termsp1 privj">
          Once a package service has been used, it is non-refundable. This
          includes all consultations, diagnostic tests, and any other services
          availed under the package.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">3.Refundable Services</b>
        </h5>
        <p class=" termsp1 privj">
          Customers are eligible for a refund if they have not used any benefits
          of the purchased package. Unused packages can be refunded within the 3
          to 7 day window from the date of purchase.
        </p>
        <h5 class="privacyhead">
          {" "}
          <b class="text-dark">4.Medical Devices</b>
        </h5>
        <p class=" termsp1 privj">
          Medical devices purchased from Respiro Healthcare can be refunded
          within 3 to 7 days.<br></br>
          Devices must be returned in their original condition, with all
          packaging and accessories included.<br></br>
          Shipping costs for returns may be the responsibility of the customer,
          depending on the reason for the return.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">5.How to Request a Refund</b>
        </h5>
        <p class=" termsp1 privj">
          Contact Us<br></br>
          Email:bussiness@respirohealthcare.com<br></br>
          Phone: +91 8075892588<br></br>
          Provide your order number, reason for the refund, and any relevant
          details.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">6.Approval Process</b>
        </h5>
        <p class=" termsp1 privj">
          Once we receive your refund request, our team will review it and
          notify you of the approval or rejection of your refund.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">7.Processing Time</b>
        </h5>
        <p class=" termsp1 privj">
          Approved refunds will be processed within 3 to 7 business days.
          <br></br>
          Refunds will be issued to the original method of payment used during
          the purchase.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">8.Important notes</b>
        </h5>
        <p class=" termsp1 privj">
          Refunds may take additional time to reflect in your account depending
          on your bank or credit card issuer. We reserve the right to refuse a
          refund request if it does not comply with our policy or if fraudulent
          activity is suspected.
        </p>
        <h5 class="privacyhead">
          <b class="text-dark">9.Contact Information</b>
        </h5>
        <p class=" termsp1 privj">
          For any questions or concerns regarding our refund policy, please
          contact us at:<br></br>
          Email: <a href="mailto:business@respirohealthcare.com"  classname="policy-contact">
              business@respirohealthcare.com
            </a><br></br><br></br>
          Phone: +91 8075892588<br></br><br></br>
          Address: <br></br>2nd floor, Golden Plaza, TB Junction, Angamaly 683572
          <br></br><br></br>
          Website Contact Form:{" "}
          <a href="https://respirohealthcare.com/contact" class="text-dark">
            https://respirohealthcare.com/contact
          </a>
          <br></br>
          We value your feedback and are committed to improving our services.
          Thank you for choosing Respiro Healthcare for your respiratory health
          needs.
        </p>
      </div>
      </div>
    </>
  );
}
