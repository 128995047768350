import React, { useState, useEffect } from "react";

import Doctors from "../provider/Doctors";

const Pulmonologist = () => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const articles = document.querySelectorAll(".product");
    const viewMoreBtn = document.getElementById("viewMoreBtn");

    articles.forEach((article, index) => {
      if (expanded || index < 20) {
        article.style.display = "block";
      } else {
        article.style.display = "none";
      }
    });

    if (viewMoreBtn) {
      viewMoreBtn.innerHTML = expanded ? "View Less" : "View More";
    }
  }, [expanded]);

  const toggleArticles = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <br></br> <br></br> <br></br>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block rhfimg w-100 vip"
                  src="./style/img/banner/plmo.png"
                  alt="First slide"
                />
              </div>
            </div>{" "}
          </div>
        </div>
        <div class="col-sm-2"></div>
      </div>
      <div class="container">
        <Doctors></Doctors>

        <center>
          <button class="main_btn" onClick={toggleArticles} id="viewMoreBtn">
            {expanded ? "View Less" : "View More"}
          </button>
        </center>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="container">
        <div class="row area-heading">
          <div class="col-md-6">
            <h3>
              <font color="#204ea9">WHEN TO SEE</font>
            </h3>
            <br></br>
            <blockquote class="generic-blockquote">
              If you have symptoms like a cough that doesn’t improve over time,
              shortness of breath, Wheezing, or Sleep apnea symptoms that your
              regular healthcare provider isn’t able to help you with, or if
              they think you would be better off seeing a specialist, they might
              refer you to a pulmonologist.
            </blockquote>
          </div>

          <div class="col-md-6">
            <h3>
              <font color="#204ea9">WHAT DO THEY DO?</font>
            </h3>
            <br></br>
            <blockquote class="generic-blockquote">
              A pulmonologist can diagnose and treat diseases of the respiratory
              system. They might have certain areas that they specialize in,
              like critical care, asthma or sleep medicine. They might also
              specialize in treating children (a pediatric pulmonologist) or the
              elderly (a geriatric pulmonologist).
            </blockquote>
          </div>
        </div>
      </div>
      <br></br>
      <div class="container">
        <div class="row area-heading">
          <div class="col-md-6">
            <h3>
              <font color="#204ea9">WHEN VISITING EXPECT THESE</font>
            </h3>
            <br></br>
            <blockquote class="generic-blockquote">
              If it’s your first appointment with a pulmonologist, you should be
              ready to fill out a medical history and have a physical
              examination. During this time, you can talk with your healthcare
              provider about the reasons you’re there and explain the details of
              your symptoms Your healthcare provider might order some tests to
              help with diagnosis and treatment.
            </blockquote>
          </div>

          <div class="col-md-6">
            <h3>
              <font color="#204ea9">SUB-SPECIALITY OF PULMONOLOGY</font>
            </h3>
            <br></br>
            <blockquote class="generic-blockquote">
              Interstitial lung disease. Interventional pulmonology. Lung
              neuromuscular disorders obstructive lung disease. Sleep-disordered
              breathing
            </blockquote>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pulmonologist;
