import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  updateFunction,
  setFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import moment from "moment";

function CompletedUnitAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [unitMembers, setUnitMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState({});
  const [rhfId, setRhfId] = useState();
  const [selectedDate, setSelectedDate] = useState("%");
  const [selectedStatus, setSelectedStatus] = useState("%");
  const { rhfEmail } = useContext(AuthContext);
  console.log(moment().format("yyyy-MM-DD"));
  const [data, setData] = useState({
    memberId: "",
    bookingId: "",
  });

  useEffect(() => {
    // Fetch unit members
    getFunction({ email: rhfEmail }, "/rhf_member/get-rhf-member-by-email")
      .then((response) => {
        const responseData = response.success.result;
        setRhfId(responseData[0].rhf_unit_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rhfEmail]);

  useEffect(() => {
    // Fetch today's appointments
    setAppointments([]);
    getFunction(
      { rhfUnitId: rhfId, date: selectedDate, status: selectedStatus },
      "/rhf_booking/get-completed-booking-for-id"
    )
      .then((response) => {
        const responseData = response.success.result.map((appointment) => ({
          ...appointment,
        }));
        setAppointments(responseData);
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch unit members
    getFunction({ rhfUnitId: rhfId }, "/rhf_member/get-rhf-unit-members")
      .then((response) => {
        const responseData = response.success.result;
        setUnitMembers(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rhfId, selectedDate, selectedStatus]);

  const handleMemberChange = (bookingId, memberId) => {
    const selectedMember = unitMembers.find((member) => member.id === memberId);
    const memberName = selectedMember
      ? `${selectedMember.first_name} ${selectedMember.last_name}`
      : "";

    setSelectedMembers((prev) => ({
      ...prev,
      [bookingId]: { id: memberId, name: memberName },
    }));

    setAppointments((prevAppointments) =>
      prevAppointments.map((appointment) =>
        appointment.id === bookingId
          ? { ...appointment, member_name: memberName }
          : appointment
      )
    );
  };

  const assignMember = (bookingId, status) => {
    const memberId = selectedMembers[bookingId]?.id;
    if (!memberId) {
      alert("Please select a member before assigning.");
      return;
    }

    console.log(bookingId, status);
    if (status === "Assigned") {
      updateAssignment(bookingId, memberId);
    } else {
      console.log("inside setAppointment");
      addAssignment(bookingId, memberId);
    }
  };

  const updateAssignment = (bookingId, memberId) => {
    updateFunction(
      { bookingId, memberId },
      "/rhf_booking_assignment/update-rhf-booking-assignment"
    )
      .then((response) => {
        console.log(response.success);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
        console.log(error);
      });
  };



  const addAssignment = (bookingId, memberId) => {
    setFunction(
      { bookingId, memberId },
      "/rhf_booking_assignment/set-rhf-booking-assignment"
    )
      .then((response) => {
        setData({ ...data, id: response.success.id });
        console.log(response.success);
        updateFunction({ bookingId, memberId }, "/rhf_unit/assign-task")
          .then((response) => {
            if (response.success) {
              const memberName = selectedMembers[bookingId]?.name || "Unknown";
              alert(`Task is Assigned to ${memberName}`);
              setAppointments((prevAppointments) =>
                prevAppointments.map((appointment) =>
                  appointment.id === bookingId
                    ? {
                        ...appointment,
                        status_field: "Assigned",
                        member_name: memberName,
                      }
                    : appointment
                )
              );
            }
          })
          .catch((err) => console.log(err));
      })

      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <br />
        <h4 style={{ color: "#3131e5" }}>Pending Tasks</h4>
        <br></br>
        <div className="filter-container">
          <div>
            <span style={{ color: "black" }}>Filter: </span>
            <span
              className="filterbtn"
              style={
                selectedDate === moment().format("yyyy-MM-DD")
                  ? { background: "#204ea0" }
                  : null
              }
              onClick={() => setSelectedDate(moment().format("yyyy-MM-DD"))}
            >
              Today
            </span>{" "}
            &nbsp;
            <span
              className="filterbtn"
              style={
                selectedDate === moment().add(-1, "day").format("yyyy-MM-DD")
                  ? { background: "#204ea0" }
                  : null
              }
              onClick={() =>
                setSelectedDate(moment().add(-1, "day").format("yyyy-MM-DD"))
              }
            >
              Yesterday
            </span>
            &nbsp;&nbsp;
            <input
              type="date"
              onChange={(e) => setSelectedDate(e.target.value)}
              value={selectedDate}
            ></input>
            &nbsp;&nbsp;
            <span
              onClick={() => setSelectedDate("%")}
              className="filterbtn"
              style={selectedDate === "%" ? { background: "#204ea0" } : null}
            >
              All
            </span>
          </div>
          <br></br>
          <br></br>
          <div className="status-filters">
            <div>
              <span
                className="filterbtn"
                style={
                  selectedStatus === "Assigned"
                    ? { background: "#204ea0" }
                    : null
                }
                onClick={() => setSelectedStatus("Assigned")}
              >
                Assigned
              </span>
              &nbsp;&nbsp;
              <span
                className="filterbtn"
                style={
                  selectedStatus === "Unassigned"
                    ? { background: "#204ea0" }
                    : null
                }
                onClick={() => setSelectedStatus("Unassigned")}
              >
                Not Assigned
              </span>
              &nbsp;&nbsp;
            </div>
            <div>
              <br></br>
              <span
                onClick={() => {
                  setSelectedDate(moment().format("yyyy-MM-DD"));
                  setSelectedStatus("%");
                }}
                className="filterbtn"
              >
                Clear Filters
              </span>
            </div>
          </div>
        </div>
        <br></br>
        <div style={{ overflowX: "scroll" }}>
          <table className="table">
            <thead>
              <tr>
                <th className="th-sm">S.No</th>
                <th className="th-sm">Patient Name</th>
                <th className="th-sm">Service Name</th>
                <th className="th-sm">Phone</th>
                <th className="th-sm">Booking Date</th>
                {/* <th className="th-sm">Status</th> */}
                {/* <th className="th-sm">Unit Members</th> */}
                <th className="th-sm">Assigned Member</th>
                {/* <th className="th-sm">Action</th> */}
                {/* <th className="th-sm">Re-Assign</th> */}
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment, index) => (
                <tr key={appointment.id}>
                  <td>{index + 1}</td>
                  <td>
                    {/* {appointment.first_name}&nbsp;{appointment.last_name} */}
                    {appointment.first_name} &nbsp;{appointment.last_name}
                  </td>
                  <td>{appointment.service_name}</td>
                  <td>{appointment.phone}</td>
                  <td>{appointment.booking_date}</td>
                  {/* <td>{appointment.status_field}</td> */}
                  <td>{appointment.member_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default CompletedUnitAppointments;
