import React from "react";

function Preventive() {
  return (
    <>
      <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>
      <div class="container">
        <div class="section-top-border">
          <div class="row">
            <div class="col-lg-12">
              <blockquote class="generic-blockquote">
                You may not think about the importance of your lung health until
                you experience a breathing problem. Your lungs provide oxygen
                and keep every other organ functioning by removing carbon
                dioxide from your body. Genetics, disease, and the environment
                can affect the health of your lungs and cause respiratory
                problems.
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <section class="">
        <div class="container">
          <div class="area-heading row">
            <div class="col-md-5 col-xl-4">
              <h3>
                <font color="#204ea0">
                  Why is it important to protect <br />
                  your lungs and keep them healthy
                </font>
              </h3>
            </div>
            <div class="col-md-7 col-xl-8">
              <blockquote class="generic-blockquote">
                With healthy lungs, you reduce your risk of having severe
                complications from respiratory illnesses, including the common
                cold. While your body has a natural defense system to keep dirt
                and germs at bay, there are several ways to help protect your
                lungs.{" "}
              </blockquote>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/ch.jpg"
                  alt=""
                />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Child</font>
                    </a>
                  </h3>
                  <p class="ptext">
                    There are many things that can affect children’s lung
                    health. Some of these things are out of your control, such
                    as genes. But there are things you can do to protect your
                    child’s lungs.
                  </p>

                  <h5>Avoid air pollution</h5>
                  <p class="ptext">
                    We know air pollution can harm your child’s lungs. You can
                    reduce the risk by
                  </p>
                  <p class="ptext">
                    1. walking or cycling, instead of driving
                    <br />
                    2. walking on the inside of the pavement
                    <br />
                    3. avoiding busy times of the day for traffic
                    <br />
                    4. avoiding busy roads and junctions.
                  </p>
                  <br></br>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/me.jpg"
                  alt=""
                />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Youngsters</font>
                    </a>
                  </h3>
                  <p class="ptext">
                    Since every Adult is different, the exact course of
                    pulmonary rehabilitation may vary too. Some adults have
                    serious health conditions that require a more rigorous
                    approach, whereas others benefit the most from an easy
                    program. The doctor creates a pulmonary rehabilitation plan
                    according to each one’s specific needs. Pulmonary
                    rehabilitation also involves consistent monitoring of the
                    adult’s condition. Communication with parents or caregivers
                    plays an important role here. The goal is to analyze the
                    progress and adjust the rehabilitation plan if.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/ma.jpg"
                  alt=""
                />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Middle Age</font>
                    </a>
                  </h3>
                  <p class="ptext">
                    As you age, changes affect your lung tissue, muscles and
                    bones, which all impact your breathing. The maximum amount
                    of air your lungs can hold—your total lung capacity—is about
                    six liters. That is about three large soda bottles. Your
                    lungs mature by the time you are about 20-25 years old.
                    After about 35, their function declines as you age and as a
                    result, breathing can slowly become more difficult over
                    time. In a person without lung disease, most of these
                    changes are due to cardiovascular and muscle changes, not
                    changes to the lungs themselves.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/ed.jpg"
                  alt=""
                />
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Elderly</font>
                    </a>
                  </h3>

                  <p class="ptext">
                    As you grow older, though, your lung function declines
                    gradually. You may find you’re not breathing as easily as
                    you did when you were younger or you get short of breath
                    often. But there are steps you can take to help improve and
                    maintain lung function throughout your life. While some
                    deterioration of physical capacities is a natural part of
                    aging, you can take steps to decrease the negative effects
                    of aging on your lungs and reduce your chances of
                    contracting a lung disease
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Preventive;
