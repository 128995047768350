import React from 'react'
import { NavLink } from "react-router-dom";
import DATA3 from '../../data/Data3';
import { FaArrowRightLong } from "react-icons/fa6";

const Subcatagory = () => {
  const cardItem = (item) => {
    return (
      <div className="col-md-4" key={item.id}>
        <div className="card o2scard">
          <div className="badge">Sales</div>
          <img className="o2simg" src={item.img} alt="" />
          <div>
            <center>
              <h4 className="o2stitle">{item.title}</h4>
            </center>

            <br />
            <br />
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="o2sprice text-dark">
                    <small>₹</small>
                    {item.price}
                  </div>
                </div>
                <div className="col-sm-6">
                  <NavLink to={`/sale/${item.id}`}>
                    <button className="btn btno2s">
                      <span className="btntexty">View <FaArrowRightLong class="text-dark up" /></span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg2">
        <div className="container">
          <div id="product">
            <br />
            <div className="o2bg">
              <h1 className="o2hed text-white">Trending Searches</h1>
            </div>
            <div className="row">{DATA3.map(cardItem)}</div>
          </div>
        </div>
      </div>

    
    </>
  );
};

export default Subcatagory;