import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header"; // Ensure your header component is properly imported
import { setFunction } from "../../api/apiFunctions";

function PFT() {
  const [fees, setFees] = useState(500); 
  const [patientId, setPatientId] = useState(""); 
  const navigate = useNavigate();
  const [duration, setDuration] = useState(1);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const handleClick = async () => {
    const data = {
      amount: parseFloat(fees * duration), // Total amount based on duration
      patientId,
      transactionId: "T" + Date.now(),
      pageSelector: "rhf",
    };

    try {
      const paymentResponse = await setFunction(data, "/payment/initiate-payment");
      console.log(paymentResponse);
      if (
        paymentResponse.success &&
        paymentResponse.success.result &&
        paymentResponse.success.result.success === true
      ) {
        window.location.href = paymentResponse.success.result.data.instrumentResponse.redirectInfo.url;
      } else {
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
      navigate("/failure");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const totalPrice = fees * duration; // Calculate total price based on duration

  return (
    <>
      <Header />
      <div className="bg2">
        <div className="bg3">
          <div style={{ textAlign: "center", position: "relative" }}>
            <center>
              <img src="../style/img/resp/pneumoban.png" className="pneumoban" alt="Pneumoban" />
            </center>
            <center>
              <h3>
                <b className="just text-white pneu1 ychn">
                  Consult Top Doctors Online for Pulmonary Function Tests (PFTs)
                </b>
              </h3>
            </center>
            <center>
              <p>
                <b className="just text-white pneu2 ychn">
                  Your health is our priority. Thank you for choosing Respiro for your online consultation.
                </b>
              </p>
            </center>
            <br />
            <br />
          </div>
          <div className="container" style={{ fontSize: "20px" }}>
            <p>
              Pulmonary Function Tests (PFTs) check how well your lungs work. They help diagnose lung problems like asthma or COPD,
              monitor disease progression, check how well treatments are working, and assess lung function before surgery.
            </p>
          </div>
          <br />
          <br />
          <div>
            <center>
              <h3 style={{ fontWeight: "800", fontFamily: "Poppins", fontSize: "30px" }}>Plan Purchase</h3>
              <h6 style={{ fontSize: "20px" }}>Choose Your Plan Duration</h6>
            </center>
            <div className="container">
              <center>
                <div
                  className="greenbg priceboxgreen"
                  style={{
                    borderRadius: "20px",
                    width: "850px",
                    height: "300px",
                  }}
                >
                  <br />
                  <form
                    className="booking-form"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="form-group11">
                      <label className="boxresd">Duration Selector:</label>
                      <div
                        style={{
                          position: "relative",
                          width: "300px",
                          marginLeft: "10px",
                        }}
                      >
                        <span
                          className="subtraction text-dark"
                          onClick={() => handleDurationChange(-1)}
                          style={{
                            position: "absolute",
                            left: 20,
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          -
                        </span>
                        <input
                          className="booktestdur"
                          name="duration"
                          value={`${duration} month${duration > 1 ? "s" : ""}`}
                          readOnly
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "0 40px",
                          }}
                        />
                        <span
                          className="addition text-dark booktestadd"
                          onClick={() => handleDurationChange(1)}
                          style={{
                            position: "absolute",
                            right: 20,
                            bottom: "0px",
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="form-group11">
                      <label className="boxresd">Price:</label>
                      <div style={{ position: "relative", width: "300px" }}>
                        <input
                          className="booktestdur"
                          style={{ width: "300px" }}
                          type="number"
                          name="totalPrice"
                          value={totalPrice}
                          readOnly
                        />
                      </div>
                    </div>
                  </form>
                  <br />
                  <center>
                    <button
                      className="purchasebutton"
                      style={{
                        fontWeight: "600",
                        backgroundColor: "#fff",
                        color: "#466E6C",
                        borderRadius: "30px",
                        marginLeft: "30px",
                      }}
                      onClick={handleClick}
                    >
                      Purchase
                    </button>
                  </center>
                </div>
              </center>
              <br />
            </div>
          </div>
          <div className="greenbg">
            <br />
            <center>
              <br />
              <br />
              <h5 className="text-white expecttext expecttextresp">What to Expect Next</h5>
            </center>
            <br />
            <div className="container">
              <center>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="blank">
                      <div className="container">
                        <br />
                        <img
                          src="../style/img/resp/pneuicon1.png"
                          style={{ height: "60px" }}
                          alt="Confirmation"
                        />
                        <br />
                        <center>
                          <h5 className="iconh"> Confirmation</h5>
                        </center>
                        <br />
                        <center>
                          <p className="iconp">You will receive a confirmation email shortly.</p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="blank">
                      <div className="container">
                        <br />
                        <img
                          src="../style/img/resp/pneuicon2.png"
                          style={{ height: "60px" }}
                          alt="Preparation"
                        />
                        <br />
                        <center>
                          <h5 className="iconh"> Preparation</h5>
                        </center>
                        <br />
                        <center>
                          <p className="iconp">
                            Make sure to have a stable internet connection and a quiet place for the consultation.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="blank">
                      <div className="container">
                        <br />
                        <img
                          src="../style/img/resp/pneuicon3.png"
                          style={{ height: "60px" }}
                          alt="Consultation"
                        />
                        <br />
                        <center>
                          <h5 className="iconh"> Consultation</h5>
                        </center>
                        <br />
                        <center>
                          <p className="iconp">Our doctor will call you at the scheduled time.</p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="blank">
                      <div className="container">
                        <br />
                        <img
                          src="../style/img/resp/pneuicon4.png"
                          style={{ height: "60px" }}
                          alt="Illustrations"
                        />
                        <br />
                        <center>
                          <h5 className="iconh"> Illustrations</h5>
                        </center>
                        <br />
                        <center>
                          <p className="iconp">
                            Include simple, clean illustrations for each step.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </center>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default PFT;
