import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Youngsterpackage() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [duration, setDuration] = useState(1); 
    const [pricePerMonth] = useState(500); // Set default price to 500

    const handleDurationChange = (amount) => {
        const newDuration = duration + amount;
        if (newDuration > 0) {
            setDuration(newDuration);
        }
    };

    const totalPrice = duration * pricePerMonth; 

    return (
        <>
            <Header />
            <div className="container packagecontainer" style={{ width: "90%", height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: '20px solid  #ccc' }}>
                <div className="packageheading text-dark" style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>
                    Thank You For Your Booking!
                </div>
                <br></br>
                <br></br>
                <br></br>
                    <h4 className="boxheading">Booking Details</h4>
                <hr style={{ width: '80%', height: '1px', backgroundColor: 'black', border: 'none' }} />

                <p style={{ textAlign: 'center' }}>
                    Package Name : Youngster Lung Care Package<br />
                </p>
                <br></br>
                <form className="booking-form ">
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}> Name:</label>
                        <input type="text" name="childName" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Parent Name:</label>
                        <input type="text" name="parentName" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Contact Number:</label>
                        <input type="tel" name="contactNo" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Email:</label>
                        <input type="email" name="email" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Address:</label>
                        <textarea name="address"></textarea>
                    </div>
                </form>
                <br></br>
                <br></br>
                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "10px" }}>
                    <h4 className="boxheading">Package Summary</h4>
                </div>
                <hr style={{ width: '80%', height: '1px', backgroundColor: 'black', border: 'none' }} />

                <form className="booking-form">
                    <div className="form-group1">
                        <label class="text-dark" >Duration:</label>
                        <div style={{ position: 'relative', width: '200px' }}>
                            <span
                                className="subtraction text-dark"
                                onClick={() => handleDurationChange(-1)}
                                style={{
                                    position: 'absolute',
                                    left: 20,
                                    width: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    fontWeight: "bold"
                                }}
                            >
                                -
                            </span>
                            <input
                                name="duration"
                                value={`${duration} month${duration > 1 ? 's' : ''}`}
                                readOnly
                                style={{ textAlign: 'center', width: '100%', padding: '0 40px' }}
                            />
                            <span
                                className="addition text-dark"
                                onClick={() => handleDurationChange(1)}
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: '0px',
                                    width: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    fontWeight: "bold"
                                }}
                            >
                                +
                            </span>
                        </div>
                    </div>
                    <div className="form-group1">
                        <label class="text-dark">Total Price:</label>
                        <input
                            type="number"
                            name="totalPrice"
                            value={totalPrice}
                            readOnly // Prevent editing
                        />
                    </div>
                    <div className="form-group1">
                        <label class="text-dark">Start Date:</label>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="form-group1">
                        <label class="text-dark">End Date:</label>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                </form>
                <br></br>
                <br></br>
                {/* <div style={{ width: '750px', height: '380px', border: '2px solid #000', margin: '20px auto', padding: '20px' }}>
                    <div style={{ textAlign: "center" }}>
                        <h4 className="boxheading">What's Included in Your Package</h4>
                    </div>
                    <hr style={{ width: '80%', height: '1px', backgroundColor: 'black', border: 'none' }} />
                    <br></br>
                    <div style={{ paddingLeft: '130px' }}>
                        <div>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            Comprehensive respiratory assessment.</p>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            Personalized lung care plan.</p>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            Regular visits from pediatric pulmonologists.</p>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            Home visits by respiratory therapists.</p>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            Breathing exercises and lung function improvement techniques.</p>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            24/7 telehealth support.</p>
                            <p><i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '8px' }}></i>&nbsp;
                            Emergency care assistance.</p>
                            <br></br>
                        </div>
                    </div>
                </div> */}

                <button style={{ borderRadius: "25px", padding: "10px 20px", margin: "20px 0" }}>
                    <span>Confirm Booking</span>
                </button>
                <br></br>
                <br></br>
              {/* <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "10px" }}>
                    <h4 className="boxheading">What To Expect Next</h4>
                </div>
                <hr style={{ width: '80%', height: '1px', backgroundColor: 'black', border: 'none' }} />
                <div className="row">
                    <div className="col-sm-3">
                        <div className="card card-200">
                            <div className="container">
                                <h6 className="boxsubhead">Confirmation Email</h6>
                                <p className="boxparag">You will receive a confirmation email shortly with all the details of your booking.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card card-200">
                            <div className="container">
                                <h6 className="boxsubhead">Schedule Initial Visit</h6>
                                <p className="boxparag">Our team will contact you within the next 24 hours to schedule the initial visit.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="card card-200">
                            <div className="container">
                                <h6 className="boxsubhead">Prepare for First Visit</h6>
                                <p className="boxparag">Please have any relevant medical records and your child’s health history ready for the initial assessment.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card card-200">
                            <div className="container">
                                <h6 className="boxsubhead">Support and Queries</h6>
                                <p className="boxparag">For any support or queries, feel free to contact us at [Support Phone Number] or email us at [Support Email Address].</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}