import Header from "../header/Header";

export default function Shipping() {
    return (
        <>
        <Header></Header>
                <div class="container-fluid teambg">
            <div class="container">
                <b class="text-dark">
                    <h3 class=" termshead ">Respiro Shipping Policy</h3>
                </b>
                <br></br>
                <p>
                    <strong>Effective Date: 18/07/2024</strong>{" "}
                </p>
                <br></br>
                <h4>Introduction</h4>
                <p class=" termsp1 privj">
                    At Respiro, we are committed to providing our customers with exceptional service and timely
                    delivery of all orders. This Shipping Policy outlines the terms and conditions related to the
                    shipping of medical devices, parts, and other products offered through our platform.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark"> General Shipping Information</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    ● <b class="text-dark">Shipping Destinations: </b>We ship to all major cities and regions across India. For remote
                    or rural locations, delivery times may be extended.<br></br>
                    ● <b class="text-dark">Processing Time:</b>Orders are processed within 1-2 business days of receipt. Orders
                    placed on weekends or public holidays will be processed on the next business day.<br></br>
                    ● <b class="text-dark">Shipping Carriers:</b> We partner with reputable shipping carriers to ensure your order
                    arrives safely and on time. Carriers may include but are not limited to India Post, Blue
                    Dart, and FedEx.
                </p>
</div>
                <h5 class="privacyhead">
                    <b class="text-dark">Shipping Rates and Delivery Times</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    ● <b class="text-dark"> Standard Shipping: </b><br></br>
                   <div style={{marginLeft:"10px"}}> ○  Estimated Delivery Time: 5-7 business days<br></br></div>
                   <div style={{marginLeft:"10px"}}>  ○  Rate: ₹50 for orders under ₹1,000, free for orders above ₹1,000<br></br></div>
                    ● <b class="text-dark">Expedited Shipping:</b><br></br>
                    <div style={{marginLeft:"10px"}}> ○  Estimated Delivery Time: 2-3 business days.<br></br></div>
                    <div style={{marginLeft:"10px"}}> ○  Rate: ₹150 for all orders<br></br></div>
                    ●  <b class="text-dark">Overnight Shipping:</b><br></br>
                    <div style={{marginLeft:"10px"}}> ○  Estimated Delivery Time: 1 business day.</div>
                    <div style={{marginLeft:"10px"}}> ○  Rate: ₹300 for all orders.</div>
                </p>
</div>
                <h5 class="privacyhead">
                    <b class="text-dark">Handling of Medical Devices and Parts</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    ● <b class="text-dark">Packaging: </b>All medical devices and parts are securely packaged to prevent damage
                    during transit. We use high-quality packaging materials to ensure the integrity of the
                    products.<br></br>
                    ● <b class="text-dark">Temperature-Sensitive Items:</b> For medical devices or parts that require specific
                    temperature conditions, we use insulated packaging and cooling packs to maintain the
                    required temperature range.<br></br>
                    ●<b class="text-dark"> Fragile Items: </b>Items marked as fragile will be handled with additional care and clearly
                    labeled to ensure they are treated appropriately during shipping.<br></br>
                </p></div>
                <h5 class="privacyhead">
                    {" "}
                    <b class="text-dark">Order Tracking</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    Once your order is shipped, you will receive an email with tracking information. You can track
                    the status of your order using the tracking number provided in the email. If you have any issues
                    with tracking your order, please contact our customer service team at Business@respirohealthcare.com
                    or 8075892588
                </p>
                </div>
                <h5 class="privacyhead">
                    <b class="text-dark">Shipping Restrictions</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    ● <b class="text-dark"> P.O.Boxes:</b> We do not ship to P.O. Box addresses. Please provide a physical address
                    for delivery.<br></br>
                    ●  <b class="text-dark">International shipping:</b>Currently, we only ship within India. International shipping
                        options will be available soon.
                </p>
                </div>
                <h5 class="privacyhead">
                    <b class="text-dark">Delivery Issues</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    ●<b class="text-dark">Delayed or Lost Orders: </b> If your order is delayed or lost, please contact our customer
                    service team. We will work with the shipping carrier to resolve the issue as quickly as
                    possible.<br></br>
                    ●<b class="text-dark">DamagedOrders:</b>If your order arrives damaged, please retain all packaging materials
                    and contact us immediately. We will arrange for a replacement or refund as per our
                    Return Policy.<br></br>
                    ●<b class="text-dark">Incorrect Address:</b>Ensure that your shipping address is correct when placing your
                    order. Respiro is not responsible for orders shipped to incorrect addresses provided by
                    the customer.
                </p>
</div>
                <h5 class="privacyhead">
                    <b class="text-dark"> Returns and Exchanges</b>
                </h5>
                <div class="subprivacycontent">
                <p class=" termsp1 privj">
                    For information on returns and exchanges, please refer to our <a href="/Return" class="text-dark">Return Policy</a>.
                </p></div>
                <h5 class="privacyhead">
                    <b class="text-dark">Customer Service</b>
                </h5>
                <div class="subprivacycontent"><p class=" termsp1 privj">
                    If you have any questions or concerns regarding our shipping policy, please contact us:<br></br>

                    ●Email: bussiness@respirohealthcare.com<br></br>
                    ●Phone: +91 8075892588<br></br>
                    <br></br>

                </p>
                </div>
                <h5 class="privacyhead">
                    <b class="text-dark"> Updates to this Policy</b>
                </h5>
                <div class="subprivacycontent">

                <p class=" termsp1 privj">
                Respiro reserves the right to update or modify this Shipping Policy at any time. Changes will be
                posted on our website, and it is your responsibility to review this policy periodically.                </p></div>
                <h5 class="privacyhead">
                    <b class="text-dark">  Conclusion</b>
                </h5>
                <div class="subprivacycontent">

                <p class=" termsp1 privj">
                Weat Respiro are dedicated to ensuring that your medical devices and parts are delivered
                safely and promptly. Thank you for choosing Respiro for your healthcare needs.                </p>
            </div>
            </div>
            </div>
        </>
    );
}

// •