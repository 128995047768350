import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

function Footer() {
  return (
    <>
      <footer class="footer-area area-padding-top fbgm">
        <div class="container">
          <div class="container-fluid">
            <div class="leaf"></div>
            <div class="row">
              <div class="col-md-1 col-sm-6 single-footer-widget column">
                <h4 class="fheadi">
                  <font color="#fff">Respiro</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="/company" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Company
                      </font>
                    </a>
                  </li>

                  <li class="ftext">
                    <a href="/Team" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Team
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Career" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Careers
                      </font>
                    </a>
                  </li>

                  <li class="ftext">
                    <a href="/upevents" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Events
                      </font>
                    </a>
                  </li>

                  <li class="ftext">
                    <a href="/Press" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Press
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Contact" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Contact
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/RespiroFoundation" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                      Respiro Foundation
                      </font>
                    </a>
                  </li>
                </ul>
              </div>
              
              <div class="col-md-2 col-sm-6 single-footer-widget column">
                <h4 class="fheadi">
                  <font color="#fff">Patients</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="/Finddoctor" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Search for Doctors
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/BookaTest" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Search for Clinics
                      </font>
                    </a>
                  </li>
                  {/* <li class="ftext">
                    <a href="/">
                      <font color="#fff" class="fnt">
                        Search for Hospitals
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/">
                      <font color="#fff" class="fnt">
                        Book Diagnostic Tests
                      </font>
                    </a>
                  </li>*/}
                  <li class="ftext">
                    <a href="/ArticleOne" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Read Health Articles
                      </font>
                    </a>
                  </li>
                  {/*<li class="ftext">
                    <a href="/">
                      <font color="#fff" class="fnt">
                        Read About Medicines
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Rhf">
                      <font color="#fff" class="fnt">
                        Online Doctor Consultation
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Rhf">
                      <font color="#fff" class="fnt">
                        Health App
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Rhf">
                      <font color="#fff" class="fnt">
                        Book Full Body Checkups
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Plus">
                      <font color="#fff" class="fnt">
                        Respiro Plus
                      </font>
                    </a>
                  </li>*/}

                  {/* <li><a href="http://rhf.com.s3-website.ap-south-1.amazonaws.com/"><font color="#fff">How to join a RHF</font></a></li> */}
                  {/* <li><a href="http://rhf.com.s3-website.ap-south-1.amazonaws.com/"><font color="#fff">Benefit</font></a></li> */}
                </ul>
              </div>

              <div class="col-md-2 col-sm-6 single-footer-widget column">
                <h4 class="fheadi">
                  <font color="#fff"> Respiro Experts</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="#" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Expert Sign Up
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="#" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Respiro Expert Profile
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/HealthFeed" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Respiro Health Feed
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="https://docs.google.com/forms/d/1xXOJhoTLM-M7zAxOv6pwHIVwKudgey5SQ3h8XPW13ak/viewform?edit_requested=true" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Partnerships
                      </font>
                    </a>
                  </li>
                  <br></br>
                </ul>
                <h4 class="fheadi">
                  <font color="#fff">RHF</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="#" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        RHF Services Overview
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="#" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        RHF Health Feed
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="#" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        RHF Profile
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="#" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Online RHF Consultation
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="https://docs.google.com/forms/d/1xXOJhoTLM-M7zAxOv6pwHIVwKudgey5SQ3h8XPW13ak/viewform?edit_requested=true" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Partnerships
                      </font>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-2 col-sm-6 single-footer-widget column">
                <h4 class="fheadi">
                  <font color="#fff"> Respiro Practitioners</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Therapist Consult
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Therapist Health Feed
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Therapist Profile
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="https://docs.google.com/forms/d/1xXOJhoTLM-M7zAxOv6pwHIVwKudgey5SQ3h8XPW13ak/viewform?edit_requested=true" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Partnerships
                      </font>
                    </a>
                  </li>
                  <br></br>
                    
                  <h4 class="fheadi">
                    <font color="#fff"> Respiro Clinics</font>
                  </h4>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Respiro Clinics
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Respiro Clinic Tools
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Respiro Clinic Services
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="https://docs.google.com/forms/d/1xXOJhoTLM-M7zAxOv6pwHIVwKudgey5SQ3h8XPW13ak/viewform?edit_requested=true" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Partnerships
                      </font>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-1 col-sm-6 single-footer-widget column">

                <h4 class="fheadi">
                  <font color="#fff">Quick Links</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Services
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Packages
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Contact Us
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/FAQ" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        FAQ
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="https://docs.google.com/forms/d/1xXOJhoTLM-M7zAxOv6pwHIVwKudgey5SQ3h8XPW13ak/viewform?edit_requested=true" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Partnerships
                      </font>
                    </a>
                  </li>

                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Lung Fitness App
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Site Map
                      </font>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-2 col-sm-6 single-footer-widget column">
                <h4 class="fheadi">
                  <font color="#fff"> For Medical Devices<br></br>Rent/Sale</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="/O2_rent" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Rent Medical Devices
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/O2_sale" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Buy Medical Devices
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Device Support and Maintenance
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Device User Guides
                      </font>
                    </a>
                  </li>
                  <br></br>

                  <h4 class="fheadi">
                    <font color="#fff">Subscribe</font>
                  </h4>
                  <li class="ftext">
                    <a href="/Newsletter" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Newsletter Signup
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Community" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Community Forums
                      </font>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-2 col-sm-6 single-footer-widget column">
                <h4 class="fheadi">
                  <font color="#fff"> Legal</font>
                </h4>
                <ul>
                  <li class="ftext">
                    <a href="/Privacy" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Privacy Policy
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Refund" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Refund policy
                      </font>
                    </a>
                  </li>

                  <li class="ftext">
                    <a href="/Shipping" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Shipping Policy
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Return" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Return Policy
                      </font>
                    </a>
                  </li>

                  <li class="ftext">
                    <a href="/Terms" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Terms & Conditions
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Cookie" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Cookie Policy
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/Disclaimer" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Disclaimer
                      </font>
                    </a>
                  </li>
                  <li class="ftext">
                    <a href="/SecurityPolicy" style={{textDecoration:"none"}}>
                      <font color="#fff" class="fnt">
                        Security Policy
                      </font>
                    </a>
                  </li>
                  <br></br>
                  

                  
                 
                  
                </ul>
              </div>
            </div>
            <br></br>
            <center>
              <b>
                <font color="#fff" class="ftext">
                  Get in Touch
                </font>
              </b>
            </center>{" "}
            <br></br>
            <center>
              <div class="foot">
                <a href="">
                  <FaInstagram class="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <FaFacebookF class="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <FaXTwitter class="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <FaLinkedinIn class="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <FaYoutube class="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <FaWhatsapp class="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </center>
            <hr color="#fff"></hr>
            <img src="../style/img/resp/footlogo.png" class="footlogo"></img>
            <div class="container">
              <center>
                <p class="ftext text-white">
                  Copyright &copy; 2024 Respiro Health. All rights reserved{" "}
                  <br></br>
                  <center>
                    <p class="text-white">
                      Owned and Operated by Pedro Labs Pvt.Ltd.&nbsp;&nbsp;|
                      &nbsp;&nbsp;Technical Support by Infosil Technolabs
                      Pvt.Ltd.&nbsp;&nbsp;|&nbsp;&nbsp;Regulatory Partner Silmed
                      Scientific Pvt.Ltd.
                      {/* <a
                        href="https://www.infosiltechnolabs.com/"
                        target="_blank"
                        class="text-white"
                      >
                        Infosiltechnolabs
                      </a> */}
                    </p>
                  </center>
                  <a
                    href="https://www.infosiltechnolabs.com/"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </p>
              </center>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
