import React, { useEffect, useState } from "react";
import Oproduct from "./Oproduct";
import Header from "../header/Header";
import Autosuggest from "react-autosuggest";
import DATA1 from "../../data/Data1";

function O2_sale() {
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : DATA1.filter((item) => item.title.toLowerCase().includes(inputValue));
  };

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item">{suggestion.title}</div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSearchChange = (event, { newValue }) => {
    setSearchInput(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <br/><br/>
      <div className="rentimg">
      <br/><br/>
        <center>
          <br />
          <br />
          <br />
          <br />
          <img src="../style/img/resp/O2_logo.png" className="o2logo" alt="" />
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="input-group">
                <div className="input-group-prepend"></div>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    placeholder: "Search for products here",
                    value: searchInput,
                    onChange: handleSearchChange,
                    className: "form-control o2scontorl",
                  }}
                  theme={{
                    container: "suggestions-container",
                    suggestion: "suggestion-item",
                    suggestionHighlighted: "suggestion-item--highlighted",
                  }}
                />
                
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </center>
      </div>
      <br />
      <Oproduct searchInput={searchInput} />
    </>
  );
}

export default O2_sale;
