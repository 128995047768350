import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { getFunction } from "../../api/apiFunctions"; 
import withProviderAuth from "../../middleware/withProviderAuth";
import { useNavigate } from 'react-router-dom';
import { FaStar } from "react-icons/fa";


function Finddoctor() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [specializations, setSpecializations] = useState([]);
  const [userLocation, setUserLocation] = useState("Your Location");
  const [nearestPlaces, setNearestPlaces] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showDoctors, setShowDoctors] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedlocValue, setSelectedlocValue] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetch specializations
  useEffect(() => {
    getFunction({ providerType: "doctor" }, "/provider/get-specialization")
      .then((response) => {
        const responseData = response.success.result;
        setSpecializations(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const callSearchService = () => {
      if (locationInput) {
        getFunction({ place: locationInput }, "/locality/get-location-by-name")
          .then((response) => {
            const responseData = response.success.result;
            setLocationSuggestions(responseData);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLocationSuggestions([]);
      }
    };

    let debouncer = setTimeout(() => {
      callSearchService();
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [locationInput]);

  // Handle location selection
  const handleLocationSelect = (e) => {
    const selectedOption = locationSuggestions.find(
      (location) => location.id === e.target.value
    );

    if (selectedOption) {
      setSelectedLocation(selectedOption);
      setLocationInput(`${selectedOption.locality}, ${selectedOption.pincode}`);
      setErrorMessage(""); // Clear error if location is selected
      setLocationSuggestions([]);
    }
  };

  const handleSpecializationChange = (event) => {
    const specializationValue = event.target.value;
    setSelectedValue(specializationValue);

    // Check if both location and specialization are selected
    if (!locationInput) {
      setErrorMessage("Please select a location first.");
      return;
    }
    if (!specializationValue) {
      setErrorMessage("Please select a specialization.");
      return;
    }

    // Navigate to search results if both fields are filled
    setErrorMessage("");
    const data = { loc: selectedLocation.pincode, spec: specializationValue };
    navigate("/SearchResult", { state: data });
  };


  // const handleSpecializationChange = (event) => {
  //   const serviceValue = event.target.value;
  //   setSelectedValue(serviceValue);


  // Handle change for specialization select
  // const handleSpecializationChange = (event) => {
  //   const specializationValue = event.target.value;
  //   setSelectedValue(specializationValue);
  //   const locationValue = selectedLocation
  //     ? selectedLocation.locality
  //     : locationInput;
  //   setSelectedlocValue(locationValue);
  //   const data = { loc: locationValue, spec: specializationValue };
  //   navigate("/SearchResult", { state: data });
  //   console.log("Selected Specialization:", specializationValue); // Log the selected specialization
  //   console.log("Selected Location:", locationValue); // Log the selected location
  // };

  // const handleSpecializationChange = (event) => {
  //   const serviceValue = event.target.value;
  //   setSelectedValue(serviceValue);


  
  //   if (selectedLocation) {
 
  //     const locationValue = `${selectedLocation.locality}, ${selectedLocation.pincode}`;
  //     setSelectedlocValue(locationValue);
  
  //     const data = { loc: selectedLocation.pincode, serv: serviceValue };
  //     navigate("/SearchResult", { state: data });
  //     console.log("Selected Service:", serviceValue); 
  //     console.log("Selected Location:", locationValue); 
  //     console.log("Pincode:", selectedLocation.pincode);
  //   } else {
  //     console.log("No location selected");
  //   }
  // };


  // Log location input
  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocationInput(value);
    setErrorMessage(""); // Clear error on new input
  };


  return (
    <>
      <Header></Header>
      <div className="container-fluid bg2">
        <br />
        <div className="fdban">
          <br /> <br />
          <center>
            <h2 className="text-white searchdoctor">
              Find Top Respiratory Specialist Near You
            </h2>
          </center>
          <br />
          <div className="container">
            <div className="row no-gutters">
              <div className="col-sm-6 location">
                {/* <div className="form-outline" data-mdb-input-init> */}
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  className="searche"
                  placeholder="Select Location"
                  value={locationInput}
                  onChange={handleLocationInputChange}
                />

                {locationSuggestions.length > 0 && (
                  <div className="suggestions-dropdown">
                    {locationSuggestions.map((item) => (
                      <button
                        key={item.id}
                        value={item.id}
                        onClick={handleLocationSelect}
                      >
                        {item.locality}, {item.districtname}, {item.state}
                      </button>
                    ))}
                  </div>
                )}

               {/* Display error message if location or specialization is not selected */}
               {errorMessage && <p className="text-warning">{errorMessage}</p>}
              </div>
              {/* </div> */}
              <div className="col-sm-6">
                {/* <div className="form-outline" data-mdb-input-init> */}
                <select
                  className="form-control findcontorl"
                  value={selectedValue}
                  onChange={handleSpecializationChange}
                >
                  <option value="">Select Specialization</option>
                  {specializations.map((spec) => (
                    <option key={spec.id} value={spec.specialization_id}>
                      {spec.specialization_name}
                    </option>
                  ))}
                </select>
              </div>
              {/* </div> */}
            </div>

            <center>
              <p className="text-white">
                Popular Searches : Pulmonologist | Chest Physician | Respiratory
                Therapist
              </p>
            </center>

            <center>
              <p className="text-white bookdoct">
                Book an appointment with experienced Pulmonologists, Chest
                Physicians and Respiratory Therapists.
              </p>
            </center>
            <br />
            <br />
          </div>
        </div>
        <br />
      </div>
      <br />
      <br />
      <center>
        <h3 class="pop popheader">Top Respiratory Specialist Near You</h3>
      </center>
      <center>
        <p class="popp poppara">
          Find the Best Respiratory Specialists in Your Area for Top-Quality
          Care and Treatment
        </p>
      </center>{" "}
      <div class="container">
        <div class="row">
          <div class="col-sm-3 ">
            <div class="card drcard">
              <div class="container">
                <img
                  src="../style/img/resp/doctor1.png"
                  alt="Image 1"
                  class="dryouth "
                ></img>

                <div class="container">
                  <hr></hr>
                </div>
                <h3 class="dry drname">Dr. Ashwanth K</h3>
                <p class="dry1 dreduca">
                  MBBS, MD<br></br>Pulmonologist
                </p>
                <center>
                  <FaStar class="starc" />
                  <FaStar class="starc" />
                  <FaStar class="starc" />
                  <FaStar class="starc" />
                </center>
                <div class="container">
                  <hr></hr>
                </div>
                <a href="/">
                  <h5 class="viewthp drview">View Profile</h5>
                </a>
                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card drcard">
              <div class="container">
                <img
                  src="../style/img/resp/doctor2.png"
                  alt="Image 2"
                  class="dryouth "
                ></img>
                <div class="card-content">
                  <div class="container">
                    <hr></hr>
                  </div>
                  <h3 class="dry drname">Dr. C Rao</h3>
                  <p class="dry1 dreduca">
                    MBBS, MD<br></br>Pulmonologist
                  </p>
                  <center>
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                  </center>
                  <div class="container">
                    <hr></hr>
                  </div>
                  <a href="/">
                    <h5 class="viewthp drview">View Profile</h5>
                  </a>
                  <center>
                    <button class="custom-btn1 btn-3">
                      <span class="btntexty bookingbtn">Book Now</span>
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card drcard">
              <div class="container">
                <img
                  src="../style/img/resp/doctor3.png"
                  alt="Image 3"
                  class="dryouth  "
                ></img>
                <div class="card-content">
                  <div class="container">
                    <hr></hr>
                  </div>

                  <h3 class="pop dry drname">Dr. B Narayan</h3>
                  <p class="dry1 dreduca">
                    MBBS, MD<br></br>Pulmonologist
                  </p>
                  <center>
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                  </center>
                  <div class="container">
                    <hr></hr>
                  </div>
                  <a href="/">
                    <h5 class="viewthp drview">View Profile</h5>
                  </a>
                  <center>
                    <button class="custom-btn1 btn-3">
                      <span class="btntexty bookingbtn">Book Now</span>
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card drcard">
              <div class="container">
                <img
                  src="../style/img/resp/doctor4.png"
                  alt="Image 4"
                  class="dryouth "
                ></img>
                <div class="card-content">
                  <div class="container">
                    <hr></hr>
                  </div>

                  <h3 class="pop dry drname">Dr. Alfred John</h3>
                  <p class="dry1 dreduca">
                    MBBS, MD<br></br>Pulmonologist
                  </p>
                  <center>
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                    <FaStar class="starc" />
                  </center>
                  <div class="container">
                    <hr></hr>
                  </div>
                  <a href="/">
                    <h5 class="viewthp drview">View Profile</h5>
                  </a>
                  <center>
                    <button class="custom-btn1 btn-3">
                      <span class="btntexty bookingbtn">Book Now</span>
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <center>
        <h3 className="pop childlung">WHY CHOOSE RESPIRO EXPERT ?</h3>
      </center>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <center>
              <img
                src="../style/img/resp/Rectangle 124.png"
                className="fdicon signicon"
              />
            </center>
            <br />
            <center>
              <p className="iconh">Comprehensive Network</p>
            </center>

            <center>
              <p className="iconp iconpara">Access to top-rated specialists</p>
            </center>
          </div>
          <div className="col-sm-2">
            <center>
              <img
                src="../style/img/resp/Rectangle 125.png"
                className="fdicon signicon"
              />
            </center>
            <br />
            <center>
              <p className="iconh">Simple Booking</p>
            </center>
            <center>
              <p className="iconp iconpara">
                Easy online appointment scheduling
              </p>
            </center>
            <hr className="fdline2" />
          </div>
          <div className="col-sm-2">
            <center>
              <img
                src="../style/img/resp/Rectangle 126.png"
                className="fdicon signicon"
              />
            </center>
            <br />
            <center>
              <p className="iconh">Verified Reviews</p>
            </center>
            <center>
              <p className="iconp iconpara">Genuine patient feedback</p>
            </center>
            <hr className="fdline2" />
          </div>
          <div className="col-sm-2">
            <center>
              <img
                src="../style/img/resp/Rectangle 127.png"
                className="fdicon signicon"
              />
            </center>
            <br />
            <center>
              <p className="iconh">Flexible Consultations</p>
            </center>
            <center>
              <p className="iconp iconpara">
                In-clinic, telemedicine or home visits
              </p>
            </center>
            <hr className="fdline2" />
          </div>
          <div className="col-sm-2">
            <center>
              <img
                src="../style/img/resp/Rectangle 128.png"
                className="fdicon signicon"
              />
            </center>
            <br />
            <center>
              <p className="iconh">Advanced Pulmono Treatment</p>
            </center>
            <center>
              <p className="iconp iconpara">
                Cutting-edge diagnostics and treatments
              </p>
            </center>
            <hr className="fdline4" />
          </div>
        </div>
      </div>
      <br />
      <div class="bg2">
        <br></br>

        <div class="container bg3">
          <center>
            <h3 class="pop childlung">How it works?</h3>
          </center>
          <br></br>

          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <div class="row slide-left">
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/one 1.png"
                      class="numbs doctornumbs doctornumbsresp"
                    ></img>
                  </div>
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/transparency 1.png"
                      class="hiwicon hiwicons hiwiconsresp"
                    ></img>
                  </div>
                  <div class="col-sm-6">
                    <p class="iconh iconheader iconheaderrespdoc ">
                      Search for Doctors
                    </p>
                    <p class="iconp iconpara iconpararespdoc">
                      Enter your location and find the nearest respiratory
                      specialist near you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row slide-right">
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/two 2.png"
                      class="numbs doctornumbs doctornumbsresp"
                    ></img>
                  </div>
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/doctor 1.png"
                      class="hiwicon hiwicons hiwiconsresp"
                    ></img>
                  </div>
                  <div class="col-sm-6">
                    <p class="iconh iconheader iconheaderrespdoc ">
                      View Profile
                    </p>
                    <p class="iconp iconpara iconpararespdoc">
                      Check qualifications, experiences and patient reviews.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="row slide-left">
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/three 3.png"
                      class="numbs doctornumbs doctornumbsresp"
                    ></img>
                  </div>
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/calendar 1.png"
                      class="hiwicon hiwicons hiwiconsresp"
                    ></img>
                  </div>
                  <div class="col-sm-6">
                    <p class="iconh iconheader iconheaderrespdoc ">
                      Book an Appointment
                    </p>
                    <p class="iconp iconpara iconpararespdoc">
                      Select your preferred doctors and schedule an appointment
                      at any convenient time.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row slide-right">
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/four 4.png"
                      class="numbs doctornumbs doctornumbsresp"
                    ></img>
                  </div>
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/advice 1.png"
                      class="hiwicon hiwicons hiwiconsresp"
                    ></img>
                  </div>
                  <div class="col-sm-6">
                    <p class="iconh iconheader iconheaderrespdoc ">
                      Consult and Care
                    </p>
                    <p class="iconp iconpara iconpararespdoc">
                      Attend your appointment in respiro-clinic or via
                      tele-medicine.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="row slide-left">
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/five 5.png"
                      class="numbs doctornumbs doctornumbsresp"
                    ></img>
                  </div>
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/syringe 1.png"
                      class="hiwicon hiwicons hiwiconsresp"
                    ></img>
                  </div>
                  <div class="col-sm-6">
                    <p class="iconh iconheader iconheaderrespdoc ">
                      Respiratory Advanced Treatment and Rehabilitation
                    </p>
                    <p class="iconp iconpara iconpararespdoc">
                      Access cutting-edge respiratory treatments and
                      personalized rehabilitation programs at Respiro.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row slide-right">
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/six 6.png"
                      class="numbs doctornumbs doctornumbsresp"
                    ></img>
                  </div>
                  <div class="col-sm-2">
                    <img
                      src="../style/img/resp/refresh 1.png"
                      class="hiwicon hiwicons hiwiconsresp"
                    ></img>
                  </div>
                  <div class="col-sm-6">
                    <p class="iconh iconheader iconheaderrespdoc ">Follow Up</p>
                    <p class="iconp iconpara iconpararespdoc">
                      Easily schedule follow-up appointments through our
                      platform
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
      <br></br>
      <br></br>
    </>
  );
}

export default Finddoctor;
