import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  setFunction,
  updateFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import Modal from "react-bootstrap/Modal";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
import moment from "moment";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import RhfPayment from "./RhfPayment";
import { useNavigate,useLocation } from 'react-router-dom';

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);
function RhfMember() {
  const navigate = useNavigate();
  const location = useLocation();

  const { rhfEmail } = useContext(AuthContext);
  const { date, time, fees } = location.state || {};
  const time24Hour = moment(time, "h:mm A").format("HH:mm");

  const handleClick = async (id) => {
      const data = {
        amount: 799, 
        rhfId: id,
        transactionId: "T" + Date.now(),
       // contactName:"anu",
        pageSelector: "default",
        //phone:"9876545678"
      };

      try {
        const paymentResponse = await setFunction(data, "/payment/initiate-payment");

        if (
          paymentResponse.success &&
          paymentResponse.success.result &&
          paymentResponse.success.result.success === true
        ) {
          window.location.href =
            paymentResponse.success.result.data.instrumentResponse.redirectInfo.url;
        }
      } catch (error) {
        console.log(error);
        navigate("/failure");
      }
    };
  const [showConfirm, setShowConfirm] = useState(false);
const [selectedSpec, setSelectedSpec] = useState(null);

  const { rhfId, accessToken, refreshToken,isMobile } = useContext(AuthContext);
  const [rhfMember, setRhfMember] = useState();
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    rhfUnitId: rhfId,
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    managerFlg: "N",
    localityId: "",
    employmentType:"",
  });

  const getRhfMembers = () => {
    getFunction({ rhfUnitId: rhfId }, "/rhf_member/get-rhf-unit-members")
      .then((response) => {
        const responseData = response.success.result;
        console.log(responseData);
        setRhfMember(responseData);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getRhfMembers();
  }, []);

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let errorMessage = "";

    if (fieldValue.trim() === "") {
      errorMessage = "This field is required";
    }

    if (fieldName === "firstName" && !validator.isAlpha(fieldValue)) {
      errorMessage = "Only alphabets allowed!";
    } else if (fieldName === "lastName" && !validator.isAlpha(fieldValue)) {
      errorMessage = "Only alphabets allowed!";
    } else if (fieldName === "email" && !validator.isEmail(fieldValue)) {
      errorMessage = "Invalid email address!";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));

    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }

    if (!data.gender) {
      errors.gender = "Gender is required";
      valid = false;
    }

    if (!validator.isEmail(data.email)) {
      errors.email = "Email is required";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFunction(data, "/rhf_member/set-rhf-member")
        .then((response) => {
          toast.success("Rhf Member registered successfully");
          console.log(response.data);
          setShowForm(false);
          getRhfMembers();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error);
          }
          console.log(error);
          toast.error(
            "An error occurred while submitting the form. Please try again later."
          );
        });
    }
  };

  const sendInvitation = (id, firstName, lastName, email) => {
    const inviteData = {
      email,
      firstName,
      lastName,
      cognitoSelector:"rhf",
    };
    setFunction(inviteData, "/admin/send-invite")
      .then((response) => {
        toast.success("Rhf Member Invited  successfully");
        updateFunction(
          { id, status: "Invited" },
          "/rhf_member/update-rhf-member-status"
        )
          .then((response) => {
            getRhfMembers();
            console.log(response.data);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              toast.error(error.response.data.error);
            }
            console.log(error);
            toast.error(
              "An error occurred while submitting the form. Please try again later."
            );
          });
        console.log(response.data);
        alert("Invitation sent.");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        }
        console.log(error);
        toast.error(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };

  const setManagerFlg = (id, value) => {
    updateFunction(
      { id, managerFlg: value },
      "/rhf_member/update-rhf-member-manager"
    )
      .then((response) => {
        getRhfMembers();
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.error);
        }
      });
  };

  const handleDelete = (id) => {
    deleteFunction({ id }, "/rhf_member/delete-rhf-member")
      .then((response) => {
        toast.success("Deleted Rhf Member successfully");
        console.log(response.data);
        getRhfMembers();       // Refresh the list of members
        setShowConfirm(false); // Close the confirmation modal
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        }
        console.log(error);
        toast.error(
          "An error occurred while deleting the member. Please try again later."
        );
      });
  };
  
  const handleClose = () => {
    setShowConfirm(false); // Close the confirmation modal
  };
const desktopView = () => (
  <div class="container">
      <div class="row">
        <div class="col-sm-5">
          <h2 class="citext topfrom">First Name *</h2>
          <input
            type="text"
            name="firstName"
            value={data.firstName}
            tabIndex={1}
            onChange={changeHandler}
            placeholder="First Name"
            className="form-input textbox"
          />
          {errors.firstName && (
            <div className="validation">{errors.firstName}</div>
          )}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Gender *</h2>
          <select
            type="text"
            name="gender"
            tabIndex={3}
            onChange={changeHandler}
            value={data.gender.toLowerCase()}
            placeholder="Gender"
            className="form-input pl-0 text-dark textbox"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="unspecified">Unspecified</option>
          </select>
          {errors.gender && <div className="validation">{errors.gender}</div>}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Date of Birth *</h2>
          <input
            type="date"
            name="dob"
            tabIndex={5}
            onChange={changeHandler}
            value={data.dob}
            placeholder="Date of Birth"
            className="form-input textbox"
          />
          {errors.dob && <div className="validation">{errors.dob}</div>}
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-5">
          <h2 class="citext topfrom">Last Name *</h2>
          <input
            type="text"
            name="lastName"
            tabIndex={2}
            onChange={changeHandler}
            value={data.lastName}
            placeholder="Last Name"
            className="form-input textbox"
          />
          {errors.lastName && (
            <div className="validation">{errors.lastName}</div>
          )}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Email *</h2>
          <input
            type="text"
            name="email"
            tabIndex={4}
            onChange={changeHandler}
            value={data.email}
            placeholder="Email"
            className="form-input textbox"
          />
          {errors.email && <div className="validation">{errors.email}</div>}
          <br/><br/>
          <h2 class="citext topfrom">Employment Type *</h2>
                  <select
                    
                    className="form-input pl-0 text-dark textbox"
                    name="employmentType"
                    placeholder="Employment Type"
                    onChange={changeHandler}
                    value={data.employmentType}
                  >
                    <option selected>--Select--</option>
                    <option>Full Time</option>
                    <option>Part Time</option>
                  </select>
                  
                  
                  {errors.empType && (
                    <div className="validation">{errors.empType}</div>
                  )}
        </div>
      </div>
      <br></br>
      <button onClick={handleSubmit}>Submit</button>
      <button style={{ marginLeft: "10px" }} onClick={() => setShowForm(false)}>
        Close
      </button>
    </div>
);
const mobileView = () => (
  <div class="container" style={{ marginLeft: "-7px" }}>
    <div class="row">
      <div class="col-sm-6">
        <h2 class="citext topfrom">First Name *</h2>
        <input
          type="text"
          name="firstName"
          value={data.firstName}
          tabIndex={1}
          onChange={changeHandler}
          placeholder="First Name"
          className="form-input textbox"
        />
        {errors.firstName && (
          <div className="validation">{errors.firstName}</div>
        )}
        <br></br>
        <br></br>
        <h2 class="citext topfrom">Last Name *</h2>
        <input
          type="text"
          name="lastName"
          tabIndex={2}
          onChange={changeHandler}
          value={data.lastName}
          placeholder="Last Name"
          className="form-input textbox"
        />
        {errors.lastName && <div className="validation">{errors.lastName}</div>}
        <br></br>
        <br></br>
        <h2 class="citext topfrom">Gender *</h2>
        <select
          type="text"
          name="gender"
          tabIndex={3}
          onChange={changeHandler}
          value={data.gender.toLowerCase()}
          placeholder="Gender"
          className="form-input pl-0 text-dark textbox"
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="unspecified">Unspecified</option>
        </select>
        {errors.gender && <div className="validation">{errors.gender}</div>}
        <br></br>
        <br></br>

        <h2 class="citext topfrom">Email *</h2>
        <input
          type="text"
          name="email"
          tabIndex={4}
          onChange={changeHandler}
          value={data.email}
          placeholder="Email"
          className="form-input textbox"
        />
        {errors.email && <div className="validation">{errors.email}</div>}
        <br />
        <br />
        <h2 class="citext topfrom">Date of Birth *</h2>
        <input
          type="date"
          name="dob"
          tabIndex={5}
          onChange={changeHandler}
          value={data.dob}
          placeholder="Date of Birth"
          className="form-input textbox"
        />
        {errors.dob && <div className="validation">{errors.dob}</div>}
      </div>
      <div class="col-sm-6">
        <br></br>
        <h2 class="citext topfrom">Employment Type *</h2>
        <select
          className="form-input pl-0 text-dark textbox"
          name="employmentType"
          placeholder="Employment Type"
          onChange={changeHandler}
          value={data.employmentType}
        >
          <option selected>--Select--</option>
          <option>Full Time</option>
          <option>Part Time</option>
        </select>

        {errors.empType && <div className="validation">{errors.empType}</div>}
      </div>
    </div>
    <br></br>
    <button onClick={handleSubmit}>Submit</button>
    <button style={{ marginLeft: "10px" }} onClick={() => setShowForm(false)}>
      Close
    </button>
  </div>
);
const addNewMemberUi = () => {
  return(
     <>
  {isMobile ? mobileView() : desktopView()}</>
  );
};



  const handleShowConfirm = (id) => {
    setSelectedSpec({ id });  // Ensure you're setting the correct ID to be deleted
    setShowConfirm(true);     // Show the confirmation modal
  };

const modalConfirmUI = (
  <Modal show={showConfirm} onHide={handleClose} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Delete</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to delete the Member?</Modal.Body>
    <Modal.Footer>
      <button onClick={() => handleDelete(selectedSpec.id)}>Delete</button>
      <button onClick={handleClose}>Close</button>
    </Modal.Footer>
  </Modal>
);


  return (

    <div class="contain">
    {modalConfirmUI}
      <ToastContainer />
      <div class="">
        <br></br> <br></br> <br></br>
        <h1 class=" rhfmemberheading profileheading1">RHF Member</h1>
        <hr class="providerline"></hr>
        <div className="col-sm-10 rhfmemberdata">
          <div style={{ overflowX: "scroll" }}>
            <table>
              <thead>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Email</th>
                <th>Manager</th>
                <th></th>
              </thead>
              <tbody>
                {rhfMember &&
                  rhfMember.map((item) => (
                    <tr key={item.id}>
                      
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>
                        {item.gender.charAt(0).toUpperCase() +
                          item.gender.slice(1)}
                      </td>
                      <td>{moment(item.dob).format("DD/MM/YYYY")}</td>
                      <td>{item.email}</td>
                      <td>{item.manager_flg}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{
                              all: "initial",
                              fontWeight: "800",
                              padding: "0px 10px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faGear}
                              style={{ fontSize: "15px" }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                          <Dropdown.Item
                           onClick={() => handleClick(item.id)}>
                           Make Initial Payment
                       </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                sendInvitation(
                                  item.id,
                                  item.first_name,
                                  item.last_name,
                                  item.email
                                )
                              }
                              disabled={item.status === "Invited"}
                            >
                              Send Invite
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setManagerFlg(item.id, "Y")}
                              disabled={
                                item.manager_flg === "Y" ||
                                item.status === "Pending Invitation"
                              }
                            >
                              Make Manager
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setManagerFlg(item.id, "N")}
                              disabled={item.manager_flg === "N"}
                            >
                              Revoke Manager Access
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleShowConfirm(item.id)}>
                          Delete
                          </Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <br></br>
          <br></br>
          <button class="qualificationbtn" onClick={() => setShowForm(true)}>
            +
          </button>
          <br></br>
          <br></br>
          {showForm && addNewMemberUi()}
        </div>
      </div>
    </div>
  );
}

export default withRhfAuth(RhfMember);
