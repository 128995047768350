import React, { useState } from "react";
import { Link } from "react-router-dom";
import { deleteFunction } from "../../api/apiFunctions";

// Loading Spinner Component
const LoadingSpinner = () => (
  <>
    <br />
    <br />
    <br />
    <div className="loading">
      <svg
        version="1.2"
        height="300"
        width="600"
        xmlns="http://www.w3.org/2000/svg"
        viewport="0 0 60 60"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          id="pulsar"
          stroke="rgba(6, 57, 212, 0.888)"
          fill="none"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
        />
      </svg>
      <center>
        <h5>Loading...</h5>
      </center>
    </div>
  </>
);

const HospitalAffiliationTable = ({ data, handleDelete }) => {
  const [loading, setLoading] = useState(false);

  const deleteAffiliation = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await deleteFunction(
        { id: data.id },
        "/hospital_affiliation/delete-hospital-affiliation"
      );
      handleDelete(data.id);
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        alert(error.response.data.error);
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="table-custom" style={{ marginRight: "350px", marginBottom: "50px" }}>
          <div>
            <p style={{ margin: 0, fontWeight: "bold" }}>{data.hospitalName}</p>
            <p style={{ margin: 0 }}>{data.addressline_1}, {data.addressline_2}</p>
            <p style={{ margin: 0 }}>{data.place}, {data.state},</p>
            <p style={{ margin: 0 }}>{data.pincode}</p>
            <button onClick={deleteAffiliation} style={{ marginTop: "30px" }}>Delete</button>
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

export default HospitalAffiliationTable;
