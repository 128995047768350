import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";

import {
  setFunction,
  getFunction,
  updateFunction,
  deleteFunction,
} from "../../api/apiFunctions";

function AddSpecialization() {
  const [providerType, setProviderType] = useState();
  const [errors, setErrors] = useState({});
  const [selectedSpec, setSelectedSpec] = useState({});
  const [updatedSpec, setUpdatedSpec] = useState("");
  const [newSpecialization, setNewSpecialization] = useState("");
  const [specializations, setSpecializations] = useState([]);
  const [filteredSpecializations, setFilteredSpecializations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [searchTerm, setSearchTerm] = useState("");
  const [showNew, setShowNew] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (providerType) {
      fetchSpecializations();
    }
  }, [providerType]);

  useEffect(() => {
    filterSpecializations();
  }, [searchTerm, specializations]);

  const fetchSpecializations = () => {
    getFunction({ providerType }, "/provider/get-specialization")
      .then((response) => {
        setSpecializations(response.success.result);
        setFilteredSpecializations(response.success.result);
        setCurrentPage(1); // Reset page to 1 after fetching data
      })
      .catch((err) => console.log(err));
  };

  const filterSpecializations = () => {
    const filtered = specializations.filter((spec) =>
      spec.specialization_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSpecializations(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSpecializations = filteredSpecializations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredSpecializations.length / itemsPerPage);

  const renderPaginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const handleClose = () => {
    setShowNew(false);
    setShowUpdate(false);
    setShowConfirm(false);
  };

  const handleShow = (type) => {
    if (type === "new") {
      setShowNew(true);
    } else if (type === "update") {
      setUpdatedSpec(""); // Reset updatedSpec to empty
      setShowUpdate(true);
    } else if (type === "confirm") {
      setShowConfirm(true);
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    const newSpecializationData = {
      specializationName: newSpecialization,
      providerType,
    };

    if (validateForm()) {
      setFunction(newSpecializationData, "/provider/set-specialization")
        .then((response) => {
          toast.success("Specialization added successfully");
          setNewSpecialization("");
          handleClose();
          fetchSpecializations();
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.error);
          } else {
            toast.error("An error occurred while adding specialization");
          }
        });
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!newSpecialization.trim()) {
      newErrors.specializationName = "Specialization is required";
      valid = false;
      toast.error("Specialization is required");
    }
    if (!providerType) {
      newErrors.providerType = "Provider type is required";
      valid = false;
      toast.error("Provider type is required");
    }

    setErrors(newErrors);
    return valid;
  };

  const handleDelete = (id) => {
    deleteFunction({ id }, "/provider/delete-specialization")
      .then(() => {
        toast.success("Specialization deleted successfully");
        fetchSpecializations();
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.error;
  
          // Check if the error is related to a foreign key constraint
          if (errorMessage.includes("ER_ROW_IS_REFERENCED_2")) {
            toast.error(
              "This specialization cannot be deleted because it is associated with one or more providers. Please update or remove those associations first."
            );
          } else {
            // Display the original error message for other errors
            toast.error(errorMessage);
          }
        } else {
          toast.error('An error occurred while deleting the specialization');
        }
        console.log(error);
      });
  };
  
  const handleUpdate = (id, specializationName, providerType) => {
    updateFunction(
      { id, specializationName, providerType },
      "/provider/update-specialization"
    )
      .then(() => {
        fetchSpecializations();
        handleClose();
      })
      .catch((err) => console.log(err));
  };

  const changeHandler = (e) => {
    setErrors({});
    setNewSpecialization(e.target.value);
  };

  const handleTypeChange = (event) => {
    setProviderType(event.target.value);
  };

  const modalAddNewUI = (
    <Modal show={showNew} onHide={handleClose} animation={false}>
      <Modal.Header>
        <Modal.Title>Add New Specialization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Selected Provider Type: {providerType}</h5>
        <input
          type="text"
          name="specializationName"
          placeholder="Specialization"
          className="form-input textbox"
          value={newSpecialization}
          onChange={changeHandler}
        />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleSubmit}>Save Changes</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  const modalUpdateUI = (
    <Modal show={showUpdate} onHide={handleClose} animation={false}>
      <Modal.Header>
        <Modal.Title>Update Specialization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <h5>Existing Specialization Name:</h5>
  <input
    type="text"
    className="form-input textbox"
    value={selectedSpec.spec}
    disabled={true}
  />
  <h5>Enter updated specialization name:</h5>
  <input
    type="text"
    name="specializationName"
    placeholder="Specialization"
    className="form-input textbox"
    value={updatedSpec}
    onChange={(e) => setUpdatedSpec(e.target.value)}
  />
</Modal.Body>

      <Modal.Footer>
        <button
          onClick={() =>
            handleUpdate(selectedSpec.id, updatedSpec, providerType)
          }
        >
          Save Changes
        </button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleClose} animation={false}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this specialization?</Modal.Body>
      <Modal.Footer>
        <button onClick={() => handleDelete(selectedSpec.id)}>Delete</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {modalAddNewUI}
      {modalUpdateUI}
      {modalConfirmUI}
      <ToastContainer />
      <div
        className="d-flex col-sm-5"
        style={{ justifyContent: "space-around" }}
      >
        <h4 style={{ marginLeft: "20px" }}>Provider Specialization</h4>
        <button onClick={() => handleShow("new")}>+</button>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h6 className="mb-2 pb-1">Specialization Type: </h6>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="doctor"
                value="Doctor"
                checked={providerType === "Doctor"}
                onChange={handleTypeChange}
              />
              <label className="form-check-label" htmlFor="doctor">
                Doctor
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="therapist"
                value="Therapist"
                checked={providerType === "Therapist"}
                onChange={handleTypeChange}
              />
              <label className="form-check-label" htmlFor="therapist">
                Therapist
              </label>
            </div>
            <br />
            <br />
            <br />
          </div>

          {errors.specializationName && (
            <span className="error">{errors.specializationName}</span>
          )}

          <div className="col-sm-6">
            <input
              type="text"
              placeholder="Search specializations"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control mb-3"
            />
            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Specialization</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentSpecializations.length > 0 ? (
                  currentSpecializations.map((item) => (
                    <tr key={item.id}>
                      <td>{item.provider_type}</td>
                      <td>{item.specialization_name}</td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{ all: "initial", fontWeight: "800" }}
                          >
                            . . .
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedSpec({
                                  id: item.id,
                                  spec: item.specialization_name,
                                });
                                handleShow("update");
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                handleShow("confirm");
                                setSelectedSpec({ id: item.id });
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No specializations available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination>{renderPaginationItems()}</Pagination>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSpecialization;
