import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Elderpackage() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [duration, setDuration] = useState(1); 
    const [pricePerMonth] = useState(480); // Set default price to 500

    const handleDurationChange = (amount) => {
        const newDuration = duration + amount;
        if (newDuration > 0) {
            setDuration(newDuration);
        }
    };

    const totalPrice = duration * pricePerMonth; 

    return (
        <>
            <Header />
            <div className="container packagecontainer" style={{ width: "90%", height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: '20px solid  #ccc' }}>
                <div className="packageheading text-dark" style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>
                    Thank You For Your Booking!
                </div>
                <br></br>
                <br></br>
                <br></br>
                    <h4 className="boxheading">Booking Details</h4>
                <hr style={{ width: '80%', height: '1px', backgroundColor: 'black', border: 'none' }} />

                <p style={{ textAlign: 'center' }}>
                    Package Name : Elders Lung Care Package<br />
                </p>
                <br></br>
                <form className="booking-form ">
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}> Name:</label>
                        <input type="text" name="childName" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Guardian Name:</label>
                        <input type="text" name="parentName" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Contact Number:</label>
                        <input type="tel" name="contactNo" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Email:</label>
                        <input type="email" name="email" />
                    </div>
                    <div className="form-group">
                        <label class="text-dark" style={{width:"125px"}}>Address:</label>
                        <textarea name="address"></textarea>
                    </div>
                </form>
                <br></br>
                <br></br>
                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "10px" }}>
                    <h4 className="boxheading">Package Summary</h4>
                </div>
                <hr style={{ width: '80%', height: '1px', backgroundColor: 'black', border: 'none' }} />

                <form className="booking-form">
                    <div className="form-group1">
                        <label class="text-dark" >Duration:</label>
                        <div style={{ position: 'relative', width: '200px' }}>
                            <span
                                className="subtraction text-dark"
                                onClick={() => handleDurationChange(-1)}
                                style={{
                                    position: 'absolute',
                                    left: 20,
                                    width: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    fontWeight: "bold"
                                }}
                            >
                                -
                            </span>
                            <input
                                name="duration"
                                value={`${duration} month${duration > 1 ? 's' : ''}`}
                                readOnly
                                style={{ textAlign: 'center', width: '100%', padding: '0 40px' }}
                            />
                            <span
                                className="addition text-dark"
                                onClick={() => handleDurationChange(1)}
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: '0px',
                                    width: '20px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    fontWeight: "bold"
                                }}
                            >
                                +
                            </span>
                        </div>
                    </div>
                    <div className="form-group1">
                        <label class="text-dark">Total Price:</label>
                        <input
                            type="number"
                            name="totalPrice"
                            value={totalPrice}
                            readOnly // Prevent editing
                        />
                    </div>
                    <div className="form-group1">
                        <label class="text-dark">Start Date:</label>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="form-group1">
                        <label class="text-dark">End Date:</label>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                </form>
                <br></br>
                <br></br>
                

                <button style={{ borderRadius: "25px", padding: "10px 20px", margin: "20px 0" }}>
                    <span>Confirm Booking</span>
                </button>
                <br></br>
                <br></br>
             
            </div>
        </>
    );
}
