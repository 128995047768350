import React, { useEffect, useState } from 'react';
import Header from "../header/Header";
import { useLocation, Link } from "react-router-dom";
import { getFunction } from "../../api/apiFunctions";
import Loader from "../common/Loader";

export default function RhfSearchResult() {
  const location = useLocation();
  const data = location.state;
  const [showDoctors, setShowDoctors] = useState(false);
  const [service, setService] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetch service
  useEffect(() => {
    getFunction({}, "/rhf_service/get-service")
      .then((response) => {
        const responseData = response.success.result;
        console.log("Fetched Services: ", responseData);
        setService(responseData);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);
  // Fetch location suggestions
  useEffect(() => {
    const callSearchService = () => {
      if (locationInput) {
        getFunction({ place: locationInput }, "/locality/get-location-by-name")
          .then((response) => {
            const responseData = response.success.result;
            setLocationSuggestions(responseData);
          })
          .catch((error) => {
            console.error("Error fetching location suggestions:", error);
          });
      } else {
        setLocationSuggestions([]);
      }
    };
    let debouncer = setTimeout(() => {
      callSearchService();
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [locationInput]);
  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocationInput(value);
    console.log("Location Input:", value);
  };
  const handleLocationSelect = (e) => {
    const selectedOption = locationSuggestions.find(
      (location) => location.id === e.target.value
    );
    if (selectedOption) {
      setSelectedLocation(selectedOption);
      setLocationInput(selectedOption.locality);
      setLocationSuggestions([]);
    }
  };
  // Initial fetch for search result
  useEffect(() => {
    setLoading(true); // Start loading
    fetchRhf(data.loc, data.serv);
  }, [data.loc, data.serv]);
  const handleServiceChange = (event) => {
    const serviceValue = event.target.value;
    setSelectedValue(serviceValue);
    const locationValue = selectedLocation ? selectedLocation.locality : locationInput;
    fetchRhf(locationValue, serviceValue);
    console.log("Selected Service:", serviceValue);
    console.log("Selected Location:", locationValue);
  };
  const fetchRhf = (locationValue, serviceValue) => {
    getFunction({ pincodeOrPlace: locationValue, service: serviceValue }, "/rhf_member/get-rhfmember-locality")
      .then((response) => {
        const responseData = response.success.result;
        setDatas(responseData);
      })
      .catch((error) => {
        console.error("Error fetching RHF:", error);
      })
      .finally(() => {
        // Set a delay for the loader
        setTimeout(() => {
          setLoading(false); // Stop loading after fetching data
        }, 1000);
      });
  };
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid bg2">
            <br />
            <div className="spec_search">
              <br /> <br />
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-sm-6 location1">
                    {/* <div className="form-outline" data-mdb-input-init> */}
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        className="searche"
                        placeholder="Select Location"                                            
                        value={locationInput}
                        onChange={handleLocationInputChange}
                      />
                      {locationSuggestions.length > 0 && (
                        <div className="suggestions-dropdown">
                          {locationSuggestions.map((item) => (
                            <button
                              key={item.id}
                              value={item.id}
                              onClick={handleLocationSelect}
                            >
                              {item.locality}, {item.districtname}, {item.state}
                              {/* <span className=""> {item.pincode} </span>     */}
                            </button>
                          ))}
                        </div>
                      )}
                    {/* </div> */}
                  </div>
                  <div className="col-sm-6">
                    {/* <div className="form-outline" data-mdb-input-init> */}
                      <select className="form-control findcontorl" value={selectedValue} onChange={handleServiceChange}>
                        <option value="">Select Service</option>
                        {service.map((serv) => (
                          <option key={serv.id} value={serv.id}>
                            {serv.service_name}
                          </option>
                        ))}
                      </select>
                    {/* </div> */}
                  </div>
                </div>
                <br />
              </div>
            </div>
            <br />
          </div>
          <br />
          <br />
          <div className="container">
            {datas.length === 0 ? (
              <div className="card__detail">
                <div className="card__source text-bold">RHF not found for this place</div>
              </div>
            ) : (
              datas.map((item) => (
                <div className="row" key={item.provider_id}>
                  <div className="col-sm-3">
                    <div>
                      {item.profilePic ? (
                        <img src={item.profilePic} alt="Profile" className="drprofile11" />
                      ) : (
                        <img
                          src="../style/img/resp/profilepicdummy.png"
                          alt="Profile"
                          className="drprofile providerdrprofile"
                        />
                      )}
                    </div>
                    <br />
                    <br />
                    <Link
                      to={`/RhfDetails/${item.rhf_unit_id}`}
                      state={{ serviceId: item.service_id }}
                      style={{ marginLeft: "55px", color: "#199fd9" }}
                    >
                      View Details
                    </Link>
                  </div>
                  <div className="col-sm-9">
                    {/* <p>{item.service_id}</p> */}
                    <h4 style={{ color: "#199fd9" }}>{item.contact_name}</h4>
                    <p className="docdetails">{item.unit_number}</p>
                    {/* <p className="docdetails">Service:{item.service_name}</p> */}
                    <p className="docdetails">{item.locality}, {item.subdistrictname}, {item.districtname} {item.state}</p>
                    <p className="docdetails"> <span style={{ fontWeight: "bold" }}> cost: {item.cost}</span> </p>
                    {/* <div>
                  <button className="custom-btn1 btn-3" style={{ marginLeft: "353px" }}>
                    <span>Book Now</span>
                  </button>
                </div> */}
                  </div>
                  <hr />
                </div>
              ))
            )}
            <hr />
          </div>
        </>
      )}
    </>
  );
}