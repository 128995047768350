const DATA3 = [
    {
      id: 1,
      title: "Walker",
      price: 1500,
      desc: "Cific Walker Alternating and Fixed 2-Way Type, Lightweight Aluminum, 8 Adjustable Heights, Nursing Use, Foldable, Stand Up, Misty Silver",
      img: "../style/img/product/walker.png",
    },
    {
      id: 2,
      title: "Nano Oxygen Nebulizer",
      price: 3500,
      desc: "Nano Oxygen Nebulizer with Nano Blue Light Technology, Disinfection Nebulizer, Rechargeable Battery for Indoor and Outdoor Home Office",
      img: "../style/img/product/nebulizer.png",
      // img1: "../style/img/product/neb.png",
      // img2: "../style/img/product/neb.png",
      // img3: "../style/img/product/neb.png",
      
    },
    {
      id: 3,
      title: "Air Bed",
      price: 7000,
      desc: "Airefina Air Bed, Single Size, Built-in Electric Pump, Camping, Mat, Durable, For Guests, Flocking Surface Finishing, Length 74.8 x Width 39.0 x Thickness 18.1 inches (190 x 99 x 46 cm), Load Capacity 650.0 lbs (295 kg), Air Bed, Comfortable Sleeping, Foldable, Storage Bag Included, Camping, Spare Bed, Home Use",
      img: "../style/img/product/airbed.png",
      // img1: "../style/img/product/neb.png",
      // img2: "../style/img/product/neb.png",
      // img3: "../style/img/product/neb.png",
    },
    {
      id: 4,
      title: "Hot Bag",
      price: 400,
      desc: "Hot Water Bottle, Capacity, Water Filling, Eco Hot Water Bottle, Rapid Heating, Cold Protection, Silicone Hot Water Bottle, Can Be Heated in a Microwave, Heat Retention Effect, Fatigue Relief, Perfect for Menstrual Periods, Perfect for Cold Protection in Winter (Round 8.5 fl oz (250 ml), Blue)",
      img: "../style/img/product/hotbag.png",
    },
    {
      id: 5,
      title: "Thermometer",
      price: 150,
      desc: "ERICKHILL Industrial Thermometer, -50 to +400 °C, Adjustable Emissivity, Ideal for Industrial Cooking, Frying Foods, etc. (Cannot be used as a thermometer), Japanese Instruction Manual Included",
      img: "../style/img/product/thermometer.png",
    },
    {
      id: 6,
      title: "Bed Pan",
      price: 300,
      desc: "Ssanvi Bed Pan For Men & Women Unisex Reusable Bed Pen With Lid And Handles Build Durable And Portable for Bed Pan For Adult Bed Patient (Pack Of 1)",
      img: "../style/img/product/bedpan.png",
    },
    {
      id: 7,
      title: "Commode Pot",
      price: 1000,
      desc: "Entros Chromed Height Adjustable Foldable Commode Chair with Pot Bucket - 894 | Ideal for Senior Citizens, Knee Patients, Old People, Handicap & Pregnant Women | Easy to use & Clean",
      img: "../style/img/product/commodepot.png",
    },
    {
      id: 8,
      title: "Bp Monitor",
      price: 1500,
      desc: "Control D Extra Large Display Series Automatic and Advance Feature Blood Pressure Monitoring System, Digital BP Monitor BP Machine (Blue, White)",
      img: "../style/img/product/bpmonitor.png",
    },
    {
      id: 9,
      title: "Nebulizer Mask",
      price: 200,
      desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
      img: "../style/img/product/nebulizermask.png",
    },
    // {
    //   id: 10,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 11,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 12,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 13,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 14,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 15,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 16,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 17,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 18,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
  
    // {
    //   id: 19,
    //   title: "Nebulizer Mask",
    //   price: 0,
    //   desc: "Nuwik Adult Mask with Air Tube, Medicine Chamber for All Nebulizer(Made In India)",
    //   img: "../style/img/product/Rectangle 16.png",
    // },
    
    // {
    //     id : 10,
    //     title : "Toilet Assersories",
    //     price : 1500,
    //     desc : "Control D Extra Large Display Series Automatic and Advance Feature Blood Pressure Monitoring System, Digital BP Monitor BP Machine (Blue, White)",
    //     img : "../ass/images/"
    // },
  ];
  
  export default DATA3;
  