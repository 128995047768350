import React from "react";

function Blog() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="./style/img/banner/trhf.jpg" alt="" />
        </div>
        <div class="col-md-5">
          <br></br>
          <br></br>
          <br></br>
          <h4>
            <font color="204ea0">Blog</font>
          </h4>
          <br></br>

          <p class="ptexty">
            World Lung Day (WLD), 25 September, is a day for lung health
            advocacy and action. Join us to promote better lung health globally.
            The COVID pandemic has highlighted stark inequalities in lung
            health, so our theme is Lung Health for All. This year we will look
            at what we can do to close those inequalities, focussing on: The
            global burden of the major respiratory diseases and the impact of
            COVID, with a focus on lower- and middle-income countries The
            importance of early detection and screening Reducing inequalities in
            screening, diagnosis and treatment of chronic lung conditions
          </p>
        </div>
      </div>

      <section class="blog-area area-padding">
        <div class="container">
          <div class="area-heading row"></div>

          <div class="row">
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <h5>Pulmanologist</h5>
                </div>
                <div class="short_details">
                  <div class="meta-top d-flex">
                    Early testing and diagnosis of diseases are crucial for
                    better recovery. Respiro foundation is introducing lab
                    facilities exclusive for lung conditions. Affordable and
                    widespread testing facilities will help to increase testing
                    rates and improve patient's number seeking better
                    healthcare. Respiro offer wide range of advanced test
                    facilities under the supervision of medical professionals
                    and also provides walk-in facilities for simple tests like
                    spirometry and other Lungs Function Tests. Early defection
                    of lung cancer, COPD, lugs fibrosis, sleep apnea etc.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <h5>Respiratory Therapist</h5>
                </div>
                <div class="short_details">
                  <div class="meta-top d-flex">
                    A respiratory therapist(RT) is a specialized healthcare
                    practitioner trained in critical care and cardio-pulmonary
                    medicine in order to work therapeutically with people who
                    have acute critical conditions, cardiac and pulmonary
                    disease. Respiratory therapists initiate and manage life
                    support for people in intensive care units and emergency
                    departments, stabilizing, treating and managing pre-hospital
                    and hospital-to-hospital patient transport by air or ground
                    ambulance.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <h5>Respiro Labs</h5>
                </div>
                <div class="short_details">
                  <div class="meta-top d-flex">
                    Until faced with a serious difficulty, breathing is the most
                    taken-for-granted biological activity in our body. But the
                    lung is a major internal organ exposed to the risks of the
                    external environment and is highly susceptible to health
                    hazards due to pollution, microbes and toxins Respiro Labs
                    is not just an advanced diagnosis facility but also a walk
                    in facility where you can take a quick appointment to assess
                    lung function without the recommendation of a doctor
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-md-3">
              <div class="single-blog">
                <div class="thumb">
                  <h5>RHF</h5>
                </div>
                <div class="short_details">
                  <div class="meta-top d-flex">
                    A rapid heal force (RHF) is a team of health care providers
                    that responds to patients with early signs of deterioration
                    on non-intensive care units to prevent respiratory or
                    cardiac arrest. The health care providers are trained in
                    early resuscitation interventions and advanced life support.
                    The team responds to calls placed by clinicians or families
                    at the bedside who have detected deterioration. Rapid
                    response teams appear to decrease the rates of respiratory
                    and cardiac arrest outside the intensive care unit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
