import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { checkAuthStatus } from "../helpers/authFunctions";
import { AuthContext } from "../context/authContext";
const withCoordinatorAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const [isAuth, setIsAuth] = useState(undefined);
    const { accessToken, refreshToken } = useContext(AuthContext);
    const renewPath = "/auth/coordinator/renew-token";
    const authType = "coordinator";
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");

    useEffect(() => {
      console.log("inside auth");
      if (WrappedComponent.name === "CoordinatorDashboard" && code) {
        setIsAuth(true);
      } else {
        checkAuthStatus(accessToken, refreshToken, renewPath, authType)
          .then(() => {
            setIsAuth(true);
          })
          .catch((error) => {
            console.error("Authentication error:", error);
            setIsAuth(false);
          });
      }
    }, [accessToken, refreshToken, renewPath, authType]);
    if (isAuth) {
      console.log("inside true");
      return <WrappedComponent {...props} />;
    } else if (isAuth === false) { 

    // return <Navigate to="/coordinator/login" />;
    return <WrappedComponent {...props} />;
    }
  };

  return WithAuth;
};

export default withCoordinatorAuth;
