import React, { useContext, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import validator from "validator";
import axios from "axios";
import { FaRegEdit } from "react-icons/fa";
import {
  setFunction,
  getFunction,
  updateFunction,
  uploadFileFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withPatientAuth from "../../middleware/withPatientAuth";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function PersonalInfo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { patientId, patientEmail, isMobile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    patientId: "",
    id: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",
    medicalHistory: "",
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileUploadError, setFileUploadError] = useState("");
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingPatient, setExistingPatient] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageId, setImageId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFunction(
          { patientId, documentType: "PROFILEPIC" },
          "/documents/get-documentsbytype"
        );
        setImageId(response.success.result[0].id);
        setImageKey(response.success.result[0].url);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [patientId]);

  useEffect(() => {
    const fetchImage = async () => {
      if (imageKey) {
        try {
          const response = await getFunction(
            { filename: imageKey },
            "/patient/file/get"
          );
          setImageUrl(JSON.parse(response.success.body).url);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    };
    fetchImage();
  }, [imageKey]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getFunction(
          { id: patientId },
          "/patient/get-profile"
        );
        const responseData = response.success.result[0];
        setData({
          id: patientId,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
          dob: moment(responseData.dob).format("YYYY-MM-DD"),
          gender: responseData.gender,
          phone: responseData.phone,
          email: responseData.email,
          medicalHistory: responseData.medical_history,
        });
      } catch (error) {
        setData({ ...data, email: patientEmail, id: patientId });
        setExistingPatient(false);
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [patientId, patientEmail]);

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    var regex = new RegExp(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/);

    if (
      fieldName === "firstName" &&
      fieldValue.length > 0 &&
      !regex.test(fieldValue)
    ) {
      setErrors({ firstName: "Only alphabets allowed!" });
    } else if (
      fieldName === "lastName" &&
      fieldValue.length > 0 &&
      !regex.test(fieldValue)
    ) {
      setErrors({ lastName: "Only alphabets allowed!" });
    } else if (
      fieldName === "phone" &&
      !validator.isMobilePhone(fieldValue, "en-IN", { strictMode: false })
    ) {
      setErrors({ phone: "Invalid phone number!" });
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      setFile(e.target.files[0]);
    }
  };

  const deleteFile = async () => {
    if (imageId) {
      try {
        await deleteFunction({ id: imageId }, "/documents/delete-documents");
        setImageId(null);
        setImageKey(null);
        setImageUrl(null);
        setFileName(null);
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    }
  };

  const uploadFile = async () => {
    if (!file) {
      setFileUploadError("Please select a file to upload.");
      return;
    }

    try {
      if (imageId) {
        await deleteFunction({ id: imageId }, "/documents/delete-documents");
      }
      const formData = new FormData();
      formData.append("file", file);
      await uploadFileFunction(
        formData,
        `/patient/file/upload/?patientId=${patientId}`
      );
      const documentData = {
        patientId,
        documentName: fileName,
        documentType: "PROFILEPIC",
        url: `${patientId}/${fileName}`,
      };
      const response = await setFunction(
        documentData,
        "/documents/set-documents"
      );
      setImageKey(documentData.url);
      setImageId(response.success.id);

      toast.success("Personal Information added successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      setFileUploadError("An error occurred while uploading the file.");

      toast.error(error.response.data.error);
    }
  };

  const register = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      await setFunction(data, "/patient/set-profile");
      setProfileEditView(false);
      toast.success("Personal Information added successfully");
    } catch (error) {
      console.error("Error registering patient:", error);
      // setRegisterStatus("An error occurred while submitting the form. Please try again later.");
      toast.error(error.response.data.error);
    }
  };

  const updatePatient = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      await updateFunction(data, "/patient/update-profile");
      setProfileEditView(false);
      toast.success("Personal Information added successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      // setRegisterStatus("An error occurred while submitting the form. Please try again later.");

      toast.error(error.response.data.error);
    }
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }
    if (!data.dob) {
      errors.dob = "Date of birth is required";
      valid = false;
    }
    if (!data.gender) {
      errors.gender = "Gender is required";
      valid = false;
    }
    if (!data.phone) {
      errors.phone = "Phone number is required";
      valid = false;
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const profileButtonHandler = (e) => {
    setProfileEditView(true);
    if (profileEditView) {
      if (existingPatient) {
        updatePatient(e);
      } else {
        register(e);
      }
    }
  };
  const desktopView = () => (
    <div className="row">
      <div className="col-sm-6">
        <h2 className="citext topfrom">First Name</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="firstName"
          tabIndex={1}
          value={data.firstName}
          onChange={changeHandler}
          placeholder="First Name"
          className="form-input textbox"
        />
        {errors.firstName && (
          <div className="validation">{errors.firstName}</div>
        )}
        <br />
        <br />
        <h2 className="citext topfrom">Date of Birth</h2>
        <input
          disabled={!profileEditView}
          type="date"
          name="dob"
          onChange={changeHandler}
          value={data.dob}
          placeholder="Date of Birth"
          className="form-input textbox"
        />
        {errors.dob && <div className="validation">{errors.dob}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">About Me</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="medicalHistory"
          tabIndex={5}
          onChange={changeHandler}
          value={data.medicalHistory}
          placeholder="About me"
          className="form-input textbox"
        />
        <br />
        <br />
      </div>
      <div className="col-sm-6">
        <h2 className="citext topfrom">Last Name</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="lastName"
          tabIndex={2}
          onChange={changeHandler}
          value={data.lastName}
          placeholder="Last Name"
          className="form-input textbox"
        />
        {errors.lastName && <div className="validation">{errors.lastName}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">Gender</h2>
        <select
          disabled={!profileEditView}
          name="gender"
          tabIndex={4}
          onChange={changeHandler}
          value={data.gender.toLowerCase()}
          className="form-input pl-0 text-dark textbox"
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="unspecified">Unspecified</option>
        </select>
        {errors.gender && <div className="validation">{errors.gender}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">Phone Number</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="phone"
          tabIndex={6}
          onChange={changeHandler}
          value={data.phone}
          placeholder="Phone"
          className="form-input textbox"
        />
        {errors.phone && <div className="validation">{errors.phone}</div>}
      </div>
    </div>
  );
  const mobileView = () => (
    <div className="row">
      <div className="col-sm-6">
        <h2 className="citext topfrom">First Name</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="firstName"
          tabIndex={1}
          value={data.firstName}
          onChange={changeHandler}
          placeholder="First Name"
          className="form-input textbox"
        />
        {errors.firstName && (
          <div className="validation">{errors.firstName}</div>
        )}
        <br />
        <br />
        <h2 className="citext topfrom">Last Name</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="lastName"
          tabIndex={2}
          onChange={changeHandler}
          value={data.lastName}
          placeholder="Last Name"
          className="form-input textbox"
        />
        {errors.lastName && <div className="validation">{errors.lastName}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">Gender</h2>
        <select
          disabled={!profileEditView}
          name="gender"
          tabIndex={4}
          onChange={changeHandler}
          value={data.gender.toLowerCase()}
          className="form-input pl-0 text-dark textbox"
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="unspecified">Unspecified</option>
        </select>
        {errors.gender && <div className="validation">{errors.gender}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">Date of Birth</h2>
        <input
          disabled={!profileEditView}
          type="date"
          name="dob"
          tabIndex={3}
          onChange={changeHandler}
          value={data.dob}
          placeholder="Date of Birth"
          className="form-input textbox"
        />
        {errors.dob && <div className="validation">{errors.dob}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">Phone Number</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="phone"
          tabIndex={6}
          onChange={changeHandler}
          value={data.phone}
          placeholder="Phone"
          className="form-input textbox"
        />
        {errors.phone && <div className="validation">{errors.phone}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">About Me</h2>
        <input
          disabled={!profileEditView}
          type="text"
          name="medicalHistory"
          tabIndex={5}
          onChange={changeHandler}
          value={data.medicalHistory}
          placeholder="About me"
          className="form-input textbox"
        />
        <br />
        <br />
      </div>
    </div>
  );

  return (
    <div className="providerline1">
      <br />
      <h1 className="profileheading1 providerprofileheading11">
        Personal Information
      </h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ToastContainer />
          <div className="row">
            <div className="col-sm-3">
              <div className="container">
                <div>
                  <img src={imageUrl} alt="Profile" className="drprofile11" />
                  <div className="dropdown">
                    <button className="dropbtn">
                      <img
                        src="../style/img/resp/addplus.png"
                        alt="Profile"
                        className="liveicon1 providerliveicon1"
                        name="file"
                      />
                    </button>
                    <div className="dropdown-content">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileChange}
                      />
                      {fileUploadError && <p>{fileUploadError}</p>}
                      <a href="#" id="saveLink" onClick={uploadFile}>
                        Save
                      </a>
                      <a href="#" onClick={deleteFile}>
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="container">
                <h3 className="patientprofilename profilname">
                  {data.firstName} &nbsp; {data.lastName}
                </h3>
                <h3 className="profilemail providerprofileemail">
                  {data.email}
                </h3>
              </div>
            </div>
            <div className="col-sm-3"></div>
            <div className="col-sm-3">
              <h2
                type="button"
                className="visit1 providervisit1"
                onClick={profileButtonHandler}
              >
                {profileEditView ? "Submit " : "Edit "}
                {/* <span className="fa-solid fa-pen"></span> */}
                <span>
                  <FaRegEdit />
                </span>
              </h2>
            </div>
          </div>
          {/* <p>test</p> */}
          {isMobile ? mobileView() : desktopView()}
        </>
      )}
    </div>
  );
}

export default withPatientAuth(PersonalInfo);
