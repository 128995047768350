import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Correct import for `useParams`
import DATA2 from "../../data/Data2";
import { useDispatch } from "react-redux";
import { addItem, delItem } from "../../redux/actions/index";

const Doctorsdetails = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [cartBtn, setCartBtn] = useState("Add to Cart");

  // Now we need a product id which is passed from the product page.
  const { id } = useParams();

  const proDetail = DATA2.filter((x) => x.id == id);

  // Assuming you want to merge results from DATA and DATA1
  const mergedProDetail = [...proDetail];

  const product = mergedProDetail[0];
  console.log(product);

  // We need to store useDispatch in a variable
  const dispatch = useDispatch();

  const handleCart = (product) => {
    if (cartBtn === "Add to Cart") {
      dispatch(addItem(product));
      setCartBtn("Remove from Cart");
    } else {
      dispatch(delItem(product));
      setCartBtn("Add to Cart");
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container my-5 py-3">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center mx-auto product">
            <img src={product.img} alt={product.name} className="proimg" />
            <br />
            <h2 className="my-4">₹{product.cfee}</h2>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h1 className="display-5 fw-bold">{product.title}</h1>
            <hr />
            <h2 className="my-4">{product.name}</h2>
            <p className="lead">{product.hspl}</p>
            <p className="lead">{product.qu}</p>
            <div className="input-group mb-3">
              <div className="input-group-prepend"></div>
              <input
                type="date"
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend"></div>
              <input
                type="time"
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend"></div>
              <select className="custom-select" id="inputGroupSelect01">
                <option selected>Location</option>
                <option value="1">Hospital</option>
                <option value="2">Clinic</option>
              </select>
            </div>
            <button className="main_btn ms-2c">Appointment</button>

            <button
              onClick={() => handleCart(product)}
              className="main_btn ms-2c"
            >
              <i className="fa-solid fa-cart-shopping"></i>&nbsp;{cartBtn}
            </button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

export default Doctorsdetails;
