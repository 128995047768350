import React, { useEffect, useState, useContext } from "react";
import validator from "validator";
import { setFunction, getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoadingSpinner = () => (
    <div className="loading">
        <svg
            version="1.2"
            height="300"
            width="600"
            xmlns="http://www.w3.org/2000/svg"
            viewport="0 0 60 60"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                id="pulsar"
                stroke="rgba(6, 57, 212, 0.888)"
                fill="none"
                strokeWidth="1"
                strokeLinejoin="round"
                d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
            />
        </svg>
        <center>
            <h5>Loading...</h5>
        </center>
    </div>
);

function CreateUnit() {
    const [errors, setErrors] = useState({});
    const [existingProfile, setExistingProfile] = useState(true);
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { rhfId,isMobile } = useContext(AuthContext);
    const { rhfEmail } = useContext(AuthContext);
    const [data, setData] = useState({
        id: rhfId,
        unitNumber: "",
        contactName: "",
        phone: "",
        email: rhfEmail,
        state: "",
        district: ""
    });


    useEffect(() => {
        if (data.state && data.district) {
            getFunction({ state: data.state, district: data.district }, "/rhf_unit/unit-number-generator")
                .then((response) => {
                    const responseData = response.success.result;
                    console.log(responseData);
                    setData((prevData) => ({
                        ...prevData,
                        unitNumber: responseData
                    }));
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setExistingProfile(false);
                    setLoading(false);
                });
        }
    }, [data.state, data.district]);

    useEffect(() => {
        getFunction({}, "/district/get-all-states")
            .then((response) => {
                const responseData = response.success.result;
                console.log(responseData);
                setStates(responseData);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const getalldistrict = (state) => {
        getFunction({ state }, "/district/get-district-by-state")
            .then((response) => {
                const responseData = response.success.result;
                console.log(responseData);
                setDistricts(responseData);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const changeHandler = (e) => {
        setErrors({});
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        const regex = new RegExp(/^[\w\s,.-]*$/gm);

        if (fieldName === "unitNumber" && !regex.test(fieldValue)) {
            setErrors({ unitNumber: "Invalid Unit Number" });
        } else if (fieldName === "contactName" && !validator.isAlpha(fieldValue)) {
            setErrors({ contactName: "Invalid Contact Name" });
        } else if (fieldName === "phone" && !validator.isMobilePhone(fieldValue, "en-IN", { strictMode: false })) {
            setErrors({ phone: "Invalid phone" });
        } else if (fieldName === "email" && !validator.isEmail(fieldValue)) {
            setErrors({ email: "Invalid Email" });
        }

        if (fieldName === "state") {
            getalldistrict(fieldValue);
        }

        setData((prevData) => ({
            ...prevData,
            [fieldName]: fieldValue,
        }));
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!data.unitNumber.trim()) {
            newErrors.unitNumber = "Unit number is required";
            valid = false;
        }
        if (!data.contactName.trim()) {
            newErrors.contactName = "Contact name is required";
            valid = false;
        }
        if (!data.phone.trim()) {
            newErrors.phone = "Phone is required";
            valid = false;
        }
        if (!data.email.trim() || !validator.isEmail(data.email)) {
            newErrors.email = "Valid Email is required";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form is valid, submitting data:', data);

            setFunction(data, "/rhf_unit/set-rhf-unit")
                .then((response) => {
                    toast.success("Rhf Unit registered successfully");
                    console.log(response.data);
                    // Reload the page after a successful submission
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000); // Add a delay to allow the toast to be displayed before reload
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        toast.error(error.response.data.error);
                    } else {
                        toast.error("An error occurred while submitting the form. Please try again later.");
                    }
                    console.log(error);
                });
        }
    };
 const desktopView = () => (
   <div className="row">
     <div className="col-sm-5">
       <h2 className="citext topfrom">Select State *</h2>
       <select
         className="form-input pl-0 text-dark textbox"
         name="state"
         placeholder="State"
         tabIndex={1}
         onChange={changeHandler}
         value={data.state}
       >
         <option value="">--Select State--</option>
         {states.map((item) => (
           <option key={item.state_id} value={item.state}>
             {item.state}
           </option>
         ))}
       </select>
<br />
<br />
       <h2 className="citext topfrom">Unit Number *</h2>
       <input
         disabled={true}
         type="text"
         name="unitNumber"
         tabIndex={3}
         value={data.unitNumber}
         placeholder="Unit Number"
         className="form-input"
       />
       {errors.unitNumber && (
         <div className="validation">{errors.unitNumber}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Contact Name *</h2>
       <input
         type="text"
         name="contactName"
         tabIndex={5}
         onChange={changeHandler}
         value={data.contactName}
         placeholder="Contact Name"
         className="form-input"
       />
       {errors.contactName && (
         <div className="validation">{errors.contactName}</div>
       )}
       <br />
       <br />
     </div>
     <div className="col-sm-5">
       <h2 className="citext topfrom">Select District *</h2>
       <select
         className="form-input pl-0 text-dark textbox"
         name="district"
         placeholder="District Name"
         tabIndex={2}
         onChange={changeHandler}
         value={data.district}
       >
         <option value="">--Select District--</option>
         {districts.map((item) => (
           <option key={item.id} value={item.district_name}>
             {item.district_name}
           </option>
         ))}
       </select>
       {errors.districtName && (
         <div className="validation">{errors.districtName}</div>
       )}<br />
       <br />
       <h2 className="citext topfrom">Phone Number *</h2>
       <input
         type="tel"
         name="phone"
         tabIndex={4}
         onChange={changeHandler}
         value={data.phone}
         placeholder="Phone Number"
         className="form-input"
       />
       {errors.phone && <div className="validation">{errors.phone}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Email *</h2>
       <input
         type="email"
         name="email"
         tabIndex={6}
         onChange={changeHandler}
         value={data.email || rhfEmail}
         placeholder="Email"
         className="form-input"
       />
       {errors.email && <div className="validation">{errors.email}</div>}
     </div>
     <div className="col-sm-2">
       <h2 type="button" className="visit1" onClick={handleSubmit}>
         Submit
         <span className="fa-solid fa-pen"></span>
       </h2>
     </div>
   </div>
 );
  const mobileView = () => (
    <div className="row">
      <div className="col-sm-5">
        <h2 className="citext topfrom">Select State *</h2>
        <select
          className="form-input pl-0 text-dark textbox"
          name="state"
          placeholder="State"
          tabIndex={1}
          onChange={changeHandler}
          value={data.state}
        >
          <option value="">--Select State--</option>
          {states.map((item) => (
            <option key={item.state_id} value={item.state}>
              {item.state}
            </option>
          ))}
        </select>
        <br></br><br></br>
        <h2 className="citext topfrom">Select District *</h2>
        <select
          className="form-input pl-0 text-dark textbox"
          name="district"
          placeholder="District Name"
          tabIndex={2}
          onChange={changeHandler}
          value={data.district}
        >
          <option value="">--Select District--</option>
          {districts.map((item) => (
            <option key={item.id} value={item.district_name}>
              {item.district_name}
            </option>
          ))}
        </select>
        {errors.districtName && (
          <div className="validation">{errors.districtName}</div>
        )}<br></br><br></br>
        <h2 className="citext topfrom">Unit Number *</h2>
        <input
          disabled={true}
          type="text"
          name="unitNumber"
          tabIndex={3}
          value={data.unitNumber}
          placeholder="Unit Number"
          className="form-input"
        />
        {errors.unitNumber && (
          <div className="validation">{errors.unitNumber}</div>
        )}
        <br />
        <br />
      </div>
      <div className="col-sm-5">
        <h2 className="citext topfrom">Phone Number *</h2>
        <input
          type="tel"
          name="phone"
          tabIndex={4}
          onChange={changeHandler}
          value={data.phone}
          placeholder="Phone Number"
          className="form-input"
        />
        {errors.phone && <div className="validation">{errors.phone}</div>}
        <br />
        <br />
        <h2 className="citext topfrom">Contact Name *</h2>
        <input
          type="text"
          name="contactName"
          tabIndex={5}
          onChange={changeHandler}
          value={data.contactName}
          placeholder="Contact Name"
          className="form-input"
        />
        {errors.contactName && (
          <div className="validation">{errors.contactName}</div>
        )}
        <br />
        <br />
        <h2 className="citext topfrom">Email *</h2>
        <input
          type="email"
          name="email"
          tabIndex={6}
          onChange={changeHandler}
          value={data.email || rhfEmail}
          placeholder="Email"
          className="form-input"
        />
        {errors.email && <div className="validation">{errors.email}</div>}
      </div>
      <div className="col-sm-2">
        <h2 type="button" className="visit1" onClick={handleSubmit}>
          Submit
          <span className="fa-solid fa-pen"></span>
        </h2>
      </div>
    </div>
  );
    return (
      <div className="">
        <ToastContainer />
        <br />
        <br />
        <br />
        <h1 className="profileheading1">Rhf Unit</h1>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <hr />
           {isMobile ? mobileView():desktopView()}
          </>
        )}
        <br />
        <br />
        <br />
      </div>
    );
}

export default withRhfAuth(CreateUnit);