import React, { useEffect, useState, useContext } from "react";
import validator from "validator";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
// import withProviderAuth from "../../middleware/withProviderAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function RhfUnit() {
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProfile, setExistingProfile] = useState(true);
  const [editView, setEditView] = useState(false);
  const { rhfId, setRhfId, rhfEmail, accessToken, refreshToken,isMobile } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: "",
    unitNumber: "",
    contactName: "",
    phone: "",
    email: rhfEmail,
  });

  useEffect(() => {
  getFunction({ email: rhfEmail }, "/rhf_unit/get-rhf-unit")
  .then((response) => {
  const responseData = response.success.result[0];
  setData({
          id: responseData.id,
          unitNumber: responseData.unit_number,
          contactName: responseData.contact_name,
          phone: responseData.phone,
          email: responseData.email,
        });
        setRhfId(responseData.id);
        localStorage.setItem("rhfId", responseData.id)
        setLoading(false);
      })
      .catch((error) => {
      console.log(error);
      setExistingProfile(false);
      setLoading(false);
      });
       }, [rhfId]);

      const changeHandler = (e) => {
      setErrors({});
      const fieldName = e.target.name;
      const fieldValue = e.target.value;
      const regex = new RegExp(/^[\w\s,.-]*$/gm);
      if (fieldName === "unitNumber" && !regex.test(fieldValue)) {
      setErrors({ unitNumber: "Invalid Unit Number" });
      } else if (fieldName === "contactName" && !validator.isAlpha(fieldValue)) {
      setErrors({ contactName: "Invalid Contact Name" });
      } else if (
      fieldName === "phone" &&
      !validator.isMobilePhone(fieldValue, "en-IN", { strictMode: false })
      ) {
      setErrors({ phone: "Invalid phone" });
    } else if (fieldName === "email" && !validator.isEmail(fieldValue)) {
      setErrors({ email: "Invalid Email" });
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.unitNumber.trim()) {
      newErrors.unitNumber = "Unit number is required";
      valid = false;
    }
    if (!data.contactName.trim()) {
      newErrors.contactName = "Contact name is required";
      valid = false;
    }
    if (!data.phone.trim()) {
      newErrors.phone = "Phone is required";
      valid = false;
    }
    if (!data.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFunction(data, "/rhf_unit/set-rhf-unit")
        .then((response) => {
          setEditView(false);
          toast.success("Rhf Unit registered successfully"); 
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error); 
          }
          console.log(error);
          toast.error("An error occurred while submitting the form. Please try again later."); 
        });
    }
  };

  const updateRhfUnit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    updateFunction(data, "/rhf_unit/update-rhf-unit")
      .then((response) => {
        toast.success("Profile updated successfully!");
        setEditView(false);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        }
        console.log(error);
        toast.error("An error occurred while submitting the form. Please try again later.");
      });
  };

  const rhfButtonHandler = (e) => {
    setEditView(true);
    if (editView) {
      if (existingProfile) {
        updateRhfUnit(e);
      } else {
        handleSubmit(e);
      }
    }
  };




const desktopView = () => (
  <div className="row">
    <div className="col-sm-5">
      <h2 className="citext topfrom">Unit Number *</h2>
      <input
        disabled={true}
        type="text"
        name="unitNumber"
        tabIndex={1}
        onChange={changeHandler}
        value={data.unitNumber}
        placeholder="Unit Number"
        className="form-input"
      />
      {errors.unitNumber && (
        <div className="validation">{errors.unitNumber}</div>
      )}
      <br></br>
      <br></br>
      <h2 className="citext topfrom">Contact Name *</h2>
      <input
        disabled={!editView}
        type="text"
        name="contactName"
        tabIndex={3}
        onChange={changeHandler}
        value={data.contactName}
        placeholder="Contact Name"
        className="form-input"
      />
      {errors.contactName && (
        <div className="validation">{errors.contactName}</div>
      )}
      <br></br>
      <br></br>
    </div>
    <div className="col-sm-5">
      <h2 className="citext topfrom">Phone Number *</h2>
      <input
        disabled={!editView}
        type="tel"
        name="phone"
        tabIndex={2}
        onChange={changeHandler}
        value={data.phone}
        placeholder="Phone Number"
        className="form-input"
      />
      {errors.phone && <div className="validation">{errors.phone}</div>}
      <br></br>
      <br></br>
      <h2 class="citext topfrom">Email *</h2>
      <input
        disabled={true}
        type="email"
        name="email"
        tabIndex={4}
        value={data.email}
        placeholder="Email"
        className="form-input"
      />
      {errors.email && <div className="validation">{errors.email}</div>}
    </div>
    <div className="col-sm-2">
      <h2 type="button" className="visit1" onClick={rhfButtonHandler}>
        {editView ? "Submit " : "Edit "}
        <span className="fa-solid fa-pen"></span>
      </h2>
    </div>
  </div>
);
const mobileView = () => (
  <div className="row">
    <div className="col-sm-2">
      <h2 type="button" className="visit1" onClick={rhfButtonHandler}>
        {editView ? "Submit " : "Edit "}
        <span className="fa-solid fa-pen"></span>
      </h2>
    </div>
    <br /><br />
    <div className="col-sm-5">
      <h2 className="citext topfrom">Unit Number *</h2>
      <input
        disabled={true}
        type="text"
        name="unitNumber"
        tabIndex={1}
        onChange={changeHandler}
        value={data.unitNumber}
        placeholder="Unit Number"
        className="form-input"
      />
      {errors.unitNumber && (
        <div className="validation">{errors.unitNumber}</div>
      )}
      <br></br>
      <br></br>
      <h2 className="citext topfrom">Phone Number *</h2>
      <input
        disabled={!editView}
        type="tel"
        name="phone"
        tabIndex={2}
        onChange={changeHandler}
        value={data.phone}
        placeholder="Phone Number"
        className="form-input"
      />
      {errors.phone && <div className="validation">{errors.phone}</div>}
      <br></br>
      <br></br>
    </div>
    <div className="col-sm-5">
      <h2 className="citext topfrom">Contact Name *</h2>
      <input
        disabled={!editView}
        type="text"
        name="contactName"
        tabIndex={3}
        onChange={changeHandler}
        value={data.contactName}
        placeholder="Contact Name"
        className="form-input"
      />
      {errors.contactName && (
        <div className="validation">{errors.contactName}</div>
      )}
      <br></br>
      <br></br>
      <h2 class="citext topfrom">Email *</h2>
      <input
        disabled={true}
        type="email"
        name="email"
        tabIndex={4}
        value={data.email}
        placeholder="Email"
        className="form-input"
      />
      {errors.email && <div className="validation">{errors.email}</div>}
    </div>
  </div>
);

  return (
    <div className="">
                <ToastContainer /> 
      <br></br> <br></br> <br></br>
      <h1 className="profileheading1">Rhf Unit</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <hr></hr>
        { isMobile ? mobileView() : desktopView()}
        </>
      )}
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default withRhfAuth(RhfUnit);
