import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from 'react-bootstrap/Pagination';

import {
  setFunction,
  getFunction,
  updateFunction,
  deleteFunction
} from "../../api/apiFunctions";

function AddQualification() {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    id: null, 
    qualificationName: "",
  });
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); // State to manage confirm modal
  const [selectedQualification, setSelectedQualification] = useState(null); // State to track selected qualification
  const [qualifications, setQualifications] = useState([]);
  const [filteredQualifications, setFilteredQualifications] = useState([]); 
  const [isEditMode, setIsEditMode] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); 
  const [searchTerm, setSearchTerm] = useState(""); 

  const handleClose = () => {
    setShow(false);
    setData({ id: null, qualificationName: "" }); 
    setIsEditMode(false);
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setSelectedQualification(null);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleShowConfirm = (id) => {
    setSelectedQualification(id);
    setShowConfirm(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchQualifications();
  }, []);

  useEffect(() => {
    filterQualifications();
  }, [searchTerm, qualifications]);

  const fetchQualifications = () => {
    getFunction({}, "/get-qualification")
      .then((response) => {
        setQualifications(response.success.result);
      })
      .catch((err) => console.log(err));
  };

  const filterQualifications = () => {
    const filtered = qualifications.filter(q =>
      q.qualification_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQualifications(filtered);
    setCurrentPage(1); 
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isEditMode) {
        updateQualification();
      } else {
        const newQualification = {
          qualificationName: data.qualificationName,
        };
        setFunction(newQualification, "/set-qualification")
          .then((response) => {
            setData({ id: null, qualificationName: "" });
            console.log(response.success);
            setSuccess(true);
            toast.success('Qualification added successfully');
            fetchQualifications();
            handleClose();
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              toast.error(error.response.data.error);
            } else {
              toast.error('An error occurred while adding the qualification');
            }
            console.log(error);
          });
      }
    }
  };

  const handleDelete = () => {
    if (!selectedQualification) return;

    deleteFunction({ id: selectedQualification }, "/delete-qualification")
      .then((response) => {
        toast.success("Qualification deleted successfully");
        fetchQualifications();
        handleCloseConfirm();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        } else {
          toast.error('An error occurred while deleting the qualification');
        }
        console.log(error);
      });
  };

  const handleEdit = (qualification) => {
    setData({
      id: qualification.id,
      qualificationName: qualification.qualification_name,
    });
    setIsEditMode(true);
    setShow(true);
  };

  const updateQualification = () => {
    const updatedQualification = {
      id: data.id,
      qualificationName: data.qualificationName,
    };

    updateFunction(updatedQualification, "/update-qualification")
      .then((response) => {
        setData({ id: null, qualificationName: "" });
        toast.success("Qualification updated successfully");
        console.log(response.data);
        fetchQualifications();
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred while updating the qualification");
        }
        console.log(error);
      });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.qualificationName.trim()) {
      newErrors.qualificationName = "Qualification is required";
      valid = false;
      toast.error('Qualification is required');
    }

    setErrors(newErrors);
    return valid;
  };

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setData({ ...data, [fieldName]: fieldValue });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentQualifications = filteredQualifications.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredQualifications.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const modalUI = (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header>
        <Modal.Title>{isEditMode ? "Edit Qualification" : "Add New Qualification"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          name="qualificationName"
          placeholder="Qualification"
          className="form-input textbox"
          value={data.qualificationName}
          onChange={changeHandler}
        />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleSubmit}>{isEditMode ? "Update" : "Save Changes"}</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleCloseConfirm} animation={false}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this qualification?</Modal.Body>
      <Modal.Footer>
        <button onClick={handleDelete}>Delete</button>
        <button onClick={handleCloseConfirm}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {modalUI}
      {modalConfirmUI}
      <ToastContainer />
      <div
        className="d-flex col-sm-5"
        style={{ justifyContent: "space-around" }}
      >
        <h4 style={{ marginLeft: "20px" }}>Add Qualification</h4>
        <button onClick={handleShow}>+</button>
      </div>
      <br />
      <br />
      <div className="col-sm-6">
        <input
          type="text"
          placeholder="Search Qualifications"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control mb-3"
        />
        <table>
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Qualification</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentQualifications &&
              currentQualifications.map((item, index) => (
                <tr key={item.id}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>{item.qualification_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{ all: "initial", fontWeight: "800" }}
                      >
                        . . .
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleShowConfirm(item.id)}>
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleEdit(item)}>Edit</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <br/>
        <Pagination>{renderPaginationItems()}</Pagination>
      </div>
    </>
  );
}

export default AddQualification;
