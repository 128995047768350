import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import validator from "validator";
import {
  setFunction,
  getFunction,
  updateFunction,
  uploadFileFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
import RhfHeader from "../header/RhfHeader";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function RhfMemberProfile() {
  const { rhfId, rhfEmail,isMobile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: rhfId,
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    place: "",
    district: "",
    state: "",
    pincode: "",
    localityId: "",
  });

  const [errors, setErrors] = useState({});  
  const [profileEditView, setProfileEditView] = useState(false);
  const [loc, setLoc] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getFunction(
          { email: rhfEmail },
          "/rhf_member/get-rhf-member-by-email"
        );
        const responseData = response.success.result[0];
        setData({
          ...data,
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
          dob: moment(responseData.dob).format("YYYY-MM-DD"),
          gender: responseData.gender,
          phone: responseData.phone,
          email: responseData.email,
          addressLine1: responseData.address_line_1,
          addressLine2: responseData.address_line_2,
          localityId: responseData.locality_id,
          pincode: responseData.pincode,
          place: responseData.locality,
          district: responseData.districtname,
          state: responseData.state
        });
      } catch (error) {
        setData({ ...data, email: rhfEmail, id: rhfId });
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [rhfId, rhfEmail]);

  useEffect(() => {
    if (data.pincode && data.pincode.length < 6) {
      setLoc([]);
      setData({ ...data, place: "", district: "", state: "" });
    } else {
      getLocations(data.pincode);
    }
  }, [data.pincode]);

  useEffect(() => {
    const selectedLoc = loc.filter((item) => item.locality === data.place);
    if (selectedLoc.length > 0) {
      setData({
        ...data,
        district: selectedLoc[0].districtname,
        state: selectedLoc[0].state,
        localityId: selectedLoc[0].id,
      });
    }
  }, [data.place, loc]);

  const getLocations = (pincode) => {
    getFunction({ pincode }, "/locality/get-location-by-pincode")
      .then((response) => {
        const responseData = response.success.result;
        setLoc(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    var regex = new RegExp(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/);

    if (
      fieldName === "firstName" &&
      fieldValue.length > 0 &&
      !regex.test(fieldValue)
    ) {
      setErrors({ firstName: "Only alphabets allowed!" });
    } else if (
      fieldName === "lastName" &&
      fieldValue.length > 0 &&
      !regex.test(fieldValue)
    ) {
      setErrors({ lastName: "Only alphabets allowed!" });
    } else if (
      fieldName === "phone" &&
      !validator.isMobilePhone(fieldValue, "en-IN", { strictMode: false })
    ) {
      setErrors({ phone: "Invalid phone number!" });
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const profileButtonHandler = (e) => {
    setProfileEditView(!profileEditView);
    if (profileEditView) {
        updateRhfMember(e);
    }
  };

  const updateRhfMember = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    updateFunction(data, "/rhf_member/update-rhf-member")
      .then((response) => {
        setProfileEditView(false);
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.error);
        }
      });
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }
    if (!data.dob) {
      errors.dob = "Date of birth is required";
      valid = false;
    }
    if (!data.gender) {
      errors.gender = "Gender is required";
      valid = false;
    }
    if (!data.phone) {
      errors.phone = "Phone number is required";
      valid = false;
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
      valid = false;
    }
    if (!data.addressLine1.trim()) {
      errors.addressLine1 = "Address line 1 is required";
      valid = false;
    }
    if (!data.pincode.trim()) {
      errors.pincode = "Pincode is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };
 const desktopView = () => (
   <div className="row">
     <div className="col-sm-6">
       <h2 className="citext topfrom">First Name</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="firstName"
         tabIndex={1}
         value={data.firstName}
         onChange={changeHandler}
         placeholder="First Name"
         className="form-input textbox"
       />
       {errors.firstName && (
         <div className="validation">{errors.firstName}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Date of Birth</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="dob"
         tabIndex={3}
         onChange={changeHandler}
         value={data.dob}
         placeholder="Date of Birth"
         className="form-input textbox"
       />
       {errors.dob && <div className="validation">{errors.dob}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Phone Number</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="phone"
         tabIndex={5}
         onChange={changeHandler}
         value={data.phone}
         placeholder="Phone"
         className="form-input textbox"
       />
       {errors.phone && <div className="validation">{errors.phone}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Address line 2 *</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="addressLine2"
         tabIndex={7}
         onChange={changeHandler}
         value={data.addressLine2}
         placeholder="Address Line 2"
         className="form-input textbox"
       />
       {errors.addressLine2 && (
         <div className="validation">{errors.addressLine2}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Place *</h2>
       <select
         disabled={!profileEditView}
         name="place"
         tabIndex={9}
         onChange={changeHandler}
         value={data.place}
         className="form-input textbox"
       >
         <option value={data.place}>{data.place}</option>
         {loc.map((item) => (
           <option value={item.locality} key={item.id}>
             {item.locality}
           </option>
         ))}
       </select>
       {errors.place && <div className="validation">{errors.place}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">State *</h2>
       <input
         disabled
         type="text"
         name="state"
         tabIndex={11}
         value={data.state}
         placeholder="State"
         className="form-input textbox"
       />
       {errors.state && <div className="validation">{errors.state}</div>}
       <br />
       <br />
     </div>
     <div className="col-sm-6">
       <h2 className="citext topfrom">Last Name</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="lastName"
         tabIndex={2}
         onChange={changeHandler}
         value={data.lastName}
         placeholder="Last Name"
         className="form-input textbox"
       />
       {errors.lastName && <div className="validation">{errors.lastName}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Gender</h2>
       <select
         disabled={!profileEditView}
         name="gender"
         tabIndex={4}
         onChange={changeHandler}
         value={data.gender.toLowerCase()}
         className="form-input pl-0 text-dark textbox"
       >
         <option value="male">Male</option>
         <option value="female">Female</option>
         <option value="unspecified">Unspecified</option>
       </select>
       {errors.gender && <div className="validation">{errors.gender}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Address line 1 *</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="addressLine1"
         tabIndex={6}
         onChange={changeHandler}
         value={data.addressLine1}
         placeholder="Address Line 1"
         className="form-input textbox"
       />
       {errors.addressLine1 && (
         <div className="validation">{errors.addressLine1}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Pincode *</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="pincode"
         tabIndex={8}
         onChange={changeHandler}
         value={data.pincode}
         placeholder="Pincode"
         className="form-input textbox"
       />
       {errors.pincode && <div className="validation">{errors.pincode}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">District *</h2>
       <input
         disabled
         type="text"
         name="district"
         tabIndex={10}
         value={data.district}
         placeholder="District"
         className="form-input textbox"
       />
       {errors.district && <div className="validation">{errors.district}</div>}
     </div>
   </div>
 );
 const mobileView = () => (
   <div className="row">
     <div className="col-sm-6">
       <h2 className="citext topfrom">First Name</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="firstName"
         tabIndex={1}
         value={data.firstName}
         onChange={changeHandler}
         placeholder="First Name"
         className="form-input textbox"
       />
       {errors.firstName && (
         <div className="validation">{errors.firstName}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Last Name</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="lastName"
         tabIndex={2}
         onChange={changeHandler}
         value={data.lastName}
         placeholder="Last Name"
         className="form-input textbox"
       />
       {errors.lastName && <div className="validation">{errors.lastName}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Date of Birth</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="dob"
         tabIndex={3}
         onChange={changeHandler}
         value={data.dob}
         placeholder="Date of Birth"
         className="form-input textbox"
       />
       {errors.dob && <div className="validation">{errors.dob}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Gender</h2>
       <select
         disabled={!profileEditView}
         name="gender"
         tabIndex={4}
         onChange={changeHandler}
         value={data.gender.toLowerCase()}
         className="form-input pl-0 text-dark textbox"
       >
         <option value="male">Male</option>
         <option value="female">Female</option>
         <option value="unspecified">Unspecified</option>
       </select>
       {errors.gender && <div className="validation">{errors.gender}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Phone Number</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="phone"
         tabIndex={5}
         onChange={changeHandler}
         value={data.phone}
         placeholder="Phone"
         className="form-input textbox"
       />
       {errors.phone && <div className="validation">{errors.phone}</div>}
       <br />
       <br />
     </div>
     <div className="col-sm-6">
       <h2 className="citext topfrom">Address line 1 *</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="addressLine1"
         tabIndex={6}
         onChange={changeHandler}
         value={data.addressLine1}
         placeholder="Address Line 1"
         className="form-input textbox"
       />
       {errors.addressLine1 && (
         <div className="validation">{errors.addressLine1}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Address line 2 *</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="addressLine2"
         tabIndex={7}
         onChange={changeHandler}
         value={data.addressLine2}
         placeholder="Address Line 2"
         className="form-input textbox"
       />
       {errors.addressLine2 && (
         <div className="validation">{errors.addressLine2}</div>
       )}
       <br />
       <br />
       <h2 className="citext topfrom">Pincode *</h2>
       <input
         disabled={!profileEditView}
         type="text"
         name="pincode"
         tabIndex={8}
         onChange={changeHandler}
         value={data.pincode}
         placeholder="Pincode"
         className="form-input textbox"
       />
       {errors.pincode && <div className="validation">{errors.pincode}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">Place *</h2>
       <select
         disabled={!profileEditView}
         name="place"
         tabIndex={9}
         onChange={changeHandler}
         value={data.place}
         className="form-input textbox"
       >
         <option value={data.place}>{data.place}</option>
         {loc.map((item) => (
           <option value={item.locality} key={item.id}>
             {item.locality}
           </option>
         ))}
       </select>
       {errors.place && <div className="validation">{errors.place}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">District *</h2>
       <input
         disabled
         type="text"
         name="district"
         tabIndex={10}
         value={data.district}
         placeholder="District"
         className="form-input textbox"
       />
       {errors.district && <div className="validation">{errors.district}</div>}
       <br />
       <br />
       <h2 className="citext topfrom">State *</h2>
       <input
         disabled
         type="text"
         name="state"
         tabIndex={11}
         value={data.state}
         placeholder="State"
         className="form-input textbox"
       />
       {errors.state && <div className="validation">{errors.state}</div>}
       <br />
       <br />
     </div>
   </div>
 );
  return (
    <>
      <RhfHeader />
      <div className="container">
        <div className="providerline">
          <br />
          <h1 className="profileheading1 providerprofileheading1">
            Personal Information
          </h1>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <hr className="providerline" />
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-3">
                  <div className="container">
                    <h3 className="profilname providerprofilname">
                      {data.firstName} &nbsp; {data.lastName}
                    </h3>
                    <h3 className="profilemail providerprofileemail">
                      {data.email}
                    </h3>
                  </div>
                </div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3">
                  <h2
                    type="button"
                    className="visit1 providervisit1"
                    onClick={profileButtonHandler}
                  >
                    {profileEditView ? "Submit " : "Edit "}
                    <span className="fa-solid fa-pen"></span>
                  </h2>
                </div>
              </div>
              {isMobile ? mobileView() : desktopView()}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default withRhfAuth(RhfMemberProfile);
