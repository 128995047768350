import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [isMobile, setIsMobile] = useState(false);

  const mobileAndTabletCheck = () => {
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    if (!!isMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => mobileAndTabletCheck(), []);

  const [providerEmail, setProviderEmail] = useState(
    localStorage.getItem("providerEmail")
  );
  const [providerId, setProviderId] = useState(
    localStorage.getItem("providerId")
  );
  const [providerType, setProviderType] = useState(localStorage.getItem("providerType"));
  const [patientEmail, setPatientEmail] = useState(
    localStorage.getItem("patientEmail")
  );
  const [adminEmail, setAdminEmail] = useState(
    localStorage.getItem("adminEmail")
  );
  const [adminId, setAdminId] = useState(
    localStorage.getItem("adminId")
  );

  const [coordinatorEmail, setCoordinatorEmail] = useState(
    localStorage.getItem("coordinatorEmail")
  );

  const [coordinatorId, setCoordinatorId] = useState(
    localStorage.getItem("coordinatorId")
  );

  const [coordinatorType, setCoordinatorType] = useState(localStorage.getItem("coordinatorType"));

  const [patientId, setPatientId] = useState(localStorage.getItem("patientId"));

  const [rhfEmail, setRhfEmail] = useState(
    localStorage.getItem("rhfEmail")
  );
  const [rhfId, setRhfId] = useState(localStorage.getItem("rhfId"));
  const [rhfPrivilege, setRhfPrivilege] = useState(localStorage.getItem("rhfPrivilege"));
  const [idToken, setIdToken] = useState(localStorage.getItem("id_token"));
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh_token")
  );

  return (
    <AuthContext.Provider
      value={{
        providerEmail,
        setProviderEmail,
        providerId,
        setProviderId,
        providerType,
        setProviderType,
        patientEmail,
        setPatientEmail,
        patientId,
        setPatientId,
        coordinatorType,
        setCoordinatorType,
        idToken,
        setIdToken,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        rhfEmail, 
        setRhfEmail,
        rhfId, 
        setRhfId,
        rhfPrivilege, 
        setRhfPrivilege,
        adminId,
        setAdminId,
        adminEmail,
        setAdminEmail,
        coordinatorId,
        setCoordinatorId,
        coordinatorEmail,
        setCoordinatorEmail,
        isMobile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
