import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  setFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);
function RhfServices() {
  const [showConfirm, setShowConfirm] = useState(false);
const [selectedSpec, setSelectedSpec] = useState(null);

  const { rhfId, accessToken, refreshToken } = useContext(AuthContext);
  const [rhfUnitService, setRhfUnitService] = useState();
  const [rhfServices, setRhfServices] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedService, setSelectedService] = useState("");

  const getRhfUnitService = () => {
    getFunction({ rhfId }, "/rhf_unit_service/get-unitservice")
      .then((response) => {
        const responseData = response.success.result;
        console.log(responseData);
        setRhfUnitService(responseData);
      })
      .catch((error) => {});
  };

  const getRhfServices = () => {
    getFunction({}, "/rhf_service/get-service")
      .then((response) => {
        const responseData = response.success.result;
        setRhfServices(responseData);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getRhfUnitService();
    getRhfServices();
  }, []);

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    console.log(fieldName, fieldValue);
    if (fieldValue !== "Select Service") {
      setSelectedService(fieldValue);
    } else {
      setSelectedService("");
    }
    let errorMessage = "";

    if (fieldValue.trim() === "") {
      errorMessage = "This field is required";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    setErrors(errors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFunction(
        { rhfUnitId: rhfId, serviceId: selectedService },
        "/rhf_unit_service/set-unitservice"
      )
        .then((response) => {
          console.log(response.data);
          setShowForm(false);
          getRhfUnitService();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            alert(error.response.data.error);
          }
          console.log(error);
        });
    }
  };

  const handleShowConfirm = (id) => {
    setSelectedSpec({ id });  // Ensure you're setting the correct ID to be deleted
    setShowConfirm(true);     // Show the confirmation modal
  };


  const handleDelete = (id) => {
    deleteFunction({ id }, "/rhf_unit_service/delete-unitservice")
      .then((response) => {
        console.log(response.data);
        getRhfUnitService();
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.error);
        }
        console.log(error);
      });
  };

  const handleClose = () => {
  setShowConfirm(false); // Close the confirmation modal
};

  const addNewServiceUi = () => (
    <div class="container">
      <div>
        <label style={{ color: "black", marginRight: "10px" }}>
          Add New Service:{" "}
        </label>
        <select
          type="text"
          name="serviceId"
          onChange={changeHandler}
          placeholder="Service"
        >
          <option>Select Service</option>
          {rhfServices.length > 0 &&
            rhfServices.map((item) => (
              <option key={item.id} value={item.id}>
                {item.service_name}
              </option>
            ))}
        </select>
      </div>
      <br></br>
      <button disabled={selectedService === ""} onClick={handleSubmit}>
        Submit
      </button>
      <button
        style={{ marginLeft: "10px" }}
        onClick={() => {
          setShowForm(false);
          setSelectedService("");
        }}
      >
        Close
      </button>
    </div>
  );

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete the Member?</Modal.Body>
      <Modal.Footer>
        <button onClick={() => handleDelete(selectedSpec.id)}>Delete</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );
  
  return (
    <div class="contain">
            {modalConfirmUI}
      <div class="providerline">
        <br></br> <br></br> <br></br>
        <h1 class=" providerprofileheading100 profileheading1">RHF Service</h1>
        <hr class="providerline"></hr>
        <div className="col-sm-6">
          <div style={{ overflowX: "scroll" }}>
            <table>
              <thead>
                <th>Service Name</th>
                <th>Session Count</th>
                <th>Frequency</th>
                <th>Cost Per Session</th>
                <th>Action</th>
              </thead>
              <tbody>
                {rhfUnitService &&
                  rhfUnitService.map((item) => (
                    <tr key={item.id}>
                      <td>{item.service_name}</td>
                      <td>{item.session_count}</td>
                      <td>{item.frequency}</td>
                      <td>{item.cost}</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => handleShowConfirm(item.id)}
                          icon={faTrash}
                          style={{ fontSize: "15px" }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <br></br>
          <br></br>
          <button class="qualificationbtn" onClick={() => setShowForm(true)}>
            +
          </button>
          <br></br>
          <br></br>
          {showForm && addNewServiceUi()}
        </div>
      </div>
    </div>
  );
}

export default withRhfAuth(RhfServices);
