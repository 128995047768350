import React, { useEffect, useState } from "react";

import { useNavigate, Link } from "react-router-dom";
import { getFunction } from "../../api/apiFunctions";
import Header from "../header/Header";
import withPatientAuth from "../../middleware/withPatientAuth";

function Profilevisit() {
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingAddress, setExistingAddress] = useState(true);
  const navigate = useNavigate();

  const [data, setData] = useState({
    patientId: "",
    addressType: "",
    addressLine1: "",
    addressLine2: "",
    place: "",
    state: "",
    pincode: "",
  });

  useEffect(() => {
    const id = localStorage.getItem("patientId");
    data.patientId = id;
    data.addressType = "patient";

    getFunction(
      { id: data.patientId, addressType: data.addressType },
      "/address/get-address"
    ).then((response) => {
      const responseData = response.success.address;
      setData({
        id: responseData.id,
        addressType: "patient",
        patientId: responseData.patient_id,
        addressLine1: responseData.address_line_1,
        addressLine2: responseData.address_line_2,
        place: responseData.place,
        state: responseData.state,
        pincode: responseData.pincode.toString(),
      });
    });
  }, []);

  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div class="container">
        <h1 class="profileheading">My Profile</h1>
        <hr class="prfline"></hr>
        <br></br>
        <div class="card">
          <div class="card-body">
            <div class="container">
              <div class="lingap">
                <h1 class="pname lingap">
                  {data.pincode}&nbsp;{data.lastName}
                </h1>
                <h3 class="pvisit">{data.place}</h3>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <h1 class="profileheading1">Personal information</h1>
        <hr class="prfline"></hr>
        <div class="card">
          <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col-sm-5">
                  <h2 class="citext">First Name</h2>
                  <h2 class="citext1">{data.firstName}</h2>
                  <br></br>
                  <h2 class="citext">Date of Birth</h2>
                  <h2 class="citext1">{data.dob}</h2>
                  <br></br>
                  <h2 class="citext">Email</h2>
                  <h2 class="citext1">{data.email}</h2>
                  <br></br>
                  <h2 class="citext">About Me</h2>
                  <h2 class="citext1">{data.medicalHistory}</h2>
                </div>
                <div class="col-sm-5">
                  <h2 class="citext">Last Name</h2>
                  <h2 class="citext1">{data.lastName}</h2>
                  <br></br>
                  <h2 class="citext">Gender</h2>
                  <h2 class="citext1">{data.gender}</h2>
                  <br></br>
                  <h2 class="citext">Phone Number</h2>
                  <h2 class="citext1">{data.phone}</h2>
                </div>
                <div class="col-sm-2">
                  <Link to="/patient/profile">
                    <button type="button" class="visit">
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 class="profileheading1">Personal information</h1>
        <hr class="prfline"></hr>
        <div class="card">
          <div class="card-body">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <h2 class="citext">First Name</h2>
                  <h2 class="citext1">{data.firstName}</h2>
                  <br></br>
                  <h2 class="citext">Date of Birth</h2>
                  <h2 class="citext1">{data.dob}</h2>
                  <br></br>
                  <h2 class="citext">Email</h2>
                  <h2 class="citext1">{data.email}</h2>
                  <br></br>
                  <h2 class="citext">About Me</h2>
                  <h2 class="citext1">{data.medicalHistory}</h2>
                </div>
                <div class="col-sm-6">
                  <h2 class="citext">Last Name</h2>
                  <h2 class="citext1">{data.lastName}</h2>
                  <br></br>
                  <h2 class="citext">Gender</h2>
                  <h2 class="citext1">{data.gender}</h2>
                  <br></br>
                  <h2 class="citext">Phone Number</h2>
                  <h2 class="citext1">{data.phone}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        {/* <center>
          <Link to="/patient/profile" onClick={() => setProfileEditView(true)}>
            <button type="button" class="visit">
              Edit
            </button>
          </Link>
        </center> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default withPatientAuth(Profilevisit);
