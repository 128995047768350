import React from "react";
import Header from "../header/Header";
import { useState,useEffect } from "react";
function Infection() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);


  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };
  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <div class="bg3">
          <div class="" style={{ textAlign: "center", position: "relative" }}>
            <center>
              <img
                src="../style/img/resp/pneumoban.png"
                class="pneumoban"
              ></img>
            </center>
            <center>
              <h3>
                <b class="just text-white pneu1 ychn">
                  Consult Top Doctors Online for Infection and Allergy Testing
                </b>
              </h3>
            </center>
            <center>
              <p>
                <b class="just text-white pneu2 ychn">
                  Your health is our priority. Thank you for choosing Respiro
                  for your online consultation.
                </b>
              </p>
            </center>

            <br></br>
            <br></br>
          </div>
          <div class="container">
            <p style={{ fontSize: "20px" }}>
              Infection Testing checks for harmful bacteria, viruses, or fungi
              in your body. It helps identify the cause of symptoms like fever
              or pain, using blood tests, urine samples, or swabs to find the
              infection. Allergy Testing checks if you're allergic to substances
              like pollen, foods, or animals. It involves skin tests or blood
              tests to see if your body reacts to certain allergens.
            </p>
          </div>

          <br></br>
          <br></br>

          <div>
            <center>
              <h3
                style={{
                  fontWeight: "800",
                  fontFamily: "Poppins",
                  fontSize: "30px",
                }}
              >
                Plan Purchase
              </h3>
              <h6
                style={{
                  fontSize: "20px",
                }}
              >
                Choose Your Plan Duration
              </h6>
            </center>

            <div class="container">
              <center>
                <div
                  class="greenbg priceboxgreen"
                  style={{
                    borderRadius: "20px",
                    width: "850px",
                    height: "300px",
                  }}
                >
                  <br></br>
                  <form
                    class="booking-form"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div class="form-group11">
                      <label class="boxresd">Duration Selector:</label>
                      <div
                        style={{
                          position: "relative",
                          width: "300px",
                          marginLeft: "10px",
                        }}
                      >
                        <span
                          class="subtraction text-dark"
                          onClick={() => handleDurationChange(-1)}
                          style={{
                            position: "absolute",
                            left: 20,
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          -
                        </span>
                        <input
                          class="booktestdur"
                          name="duration"
                          value={`${duration} month${duration > 1 ? "s" : ""}`}
                          readOnly
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "0 40px",
                          }}
                        />
                        <span
                          class="addition text-dark  booktestadd"
                          onClick={() => handleDurationChange(1)}
                          style={{
                            position: "absolute",
                            right: 20,
                            bottom: "0px",
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div class="form-group11">
                      <label class="boxresd">Price:</label>
                      <div style={{ position: "relative", width: "300px" }}>
                        <input
                          class="booktestdur"
                          style={{ width: "300px" }}
                          type="number"
                          name="totalPrice"
                          value={totalPrice}
                          readOnly
                        />
                      </div>
                    </div>
                  </form>
                  <br></br>
                  <center>
                    <button
                      class="purchasebutton"
                      style={{
                        fontWeight: "600",
                        backgroundColor: "#fff",
                        color: "#466E6C",
                        borderRadius: "30px",
                        marginLeft: "30px",
                      }}
                    >
                      Purchase
                    </button>
                  </center>
                </div>
              </center>
              <br></br>
            </div>
          </div>
          <div class="greenbg">
            <br></br>
            <center>
              <br></br>
              <br></br>
              <h5 class="text-white expecttext expecttextresp">
                What to Expect Next
              </h5>
            </center>
            <br></br>
            <div class="container">
              <center>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon1.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Confirmation</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            You will receive a confirmation email shortly.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon2.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Preparation</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            Make sure to have a stable internet connection and a
                            quiet place for the consultation..
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon3.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Consultation</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            Our doctor will call you at the scheduled time.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon4.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Illustrations</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            Include simple, clean illustrations for each step.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </center>
              <br></br>
            </div>
            <br></br>
            <br></br>
          </div>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default Infection;