import React from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
  const tests = [
    {
      id: 1,
      title: 'Pulmonary Function Tests',
      image: '../style/img/resp/6401477 1.png',
      link: '/PFT',
    },
    {
      id: 2,
      title: 'Infection and Allergy Testing',
      image: '../style/img/resp/allergy.png',
      link: '/Infection',
    },
    {
      id: 3,
      title: 'Respiratory Pathogen Panel',
      image: '../style/img/resp/pathogen.png',
      link: '/Pathogenpaneltest',
    },
    {
      id: 4,
      title: 'Sleep Studies',
      image: '../style/img/resp/test 1.png',
      link: '/Sleeptest',
    },
    {
      id: 5,
      title: 'Lung Biopsy',
      image: '../style/img/resp/10207743 1.png',
      link: '/Lungbiopsytest',
    },
    {
      id: 6,
      title: 'Blood Gas Analysis',
      image: '../style/img/resp/5842785 1.png',
      link: '/Bloodgastest',
    },
    {
      id: 7,
      title: 'Home-Based Testing Option',
      image: '../style/img/resp/11365354 1.png',
      link: '/Hometest',
    },
    {
      id: 8,
      title: 'Biomarker Testing',
      image: '../style/img/resp/2460085 1.png',
      link: '/Biomarkertest',
    },
    {
      id: 9,
      title: 'Imaging Services',
      image: '../style/img/resp/387617 2.png',
      link: '/Imagingtest',
    },
  ];

  const labData = [
    {
      id: 1,
      name: "Enrich Laboratories",
      location: "Chalakudy",
      contact: "+91 9234567891",
      imageSrc: "../style/img/resp/toplab1.png",
      rating: 4, // number of stars
      profileLink: "/",
    },
    {
      id: 2,
      name: "Matha Laboratories",
      location: "Chalakudy",
      contact: "+91 92345054321",
      imageSrc: "../style/img/resp/toplab2.png",
      rating: 4,
      profileLink: "/",
    },
    {
      id: 3,
      name: "Tesla Laboratory",
      location: "Chalakudy",
      contact: "+91 6565495159",
      imageSrc: "../style/img/resp/toplab3.png",
      rating: 4,
      profileLink: "/",
    },
    {
      id: 4,
      name: "Vision Laboratories",
      location: "Potta",
      contact: "+91 3575319551",
      imageSrc: "../style/img/resp/toplab4.png",
      rating: 4,
      profileLink: "/",
    },
  ];
  
function BookaTest() {
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <br />
        <div class="bookabg">
          <div class="container">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div class="row no-gutter">
              <div class="col-sm-6">
                <h3 class="bookahed booktestsearch text-white">
                  Search Your Respiro Lab
                </h3>
              </div>
              <div class="col-sm-6">
                <div class="input-group searchlab">
                  <div class="input-group-prepend "></div>
                  <input
                    type="text"
                    class="form-control lungcontorl lunginput "
                    placeholder="Search for Respiro Lab here"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid ">
          <center>
            <div class="card bcard welcomecard">
              <div class="container wlcmconr">
                <div class="hedbgh">
                  <h4 class="bhed welcomelab">Welcome To Respiro Labs</h4>
                  <br></br>
                </div>
                <p class="bptext testcontent">
                  At Respiro Lab, we are committed to providing accurate and
                  timely diagnostic services to support your respiratory health.
                  Our state-of-the-art laboratory is equipped with advanced
                  technology and operated by skilled professionals, ensuring you
                  receive the highest quality care.
                </p>
              </div>
            </div>
          </center>
        </div>

        <div class="container-fluid">
          <br></br>
          <center>
            {" "}
            <h3>Comprehensive Diagnostic Testing</h3>
          </center>{" "}
          <br></br>
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
            data-interval="2700"
          >
            <div className="container">
              <div className="row">
                {tests.map((test) => (
                  <div className="col-sm-4" key={test.id}>
                    <div className="card boxcard">
                      <div className="container">
                        <br />
                        <center>
                          <img
                            src={test.image}
                            className="boximg"
                            alt={test.title}
                          />
                          <br />
                          <h4 className="boxtext">{test.title}</h4>
                          <Link to={test.link}>
                            <button className="custom-btn1 btn-3">
                              <span className="btntexty">Book Now</span>
                            </button>
                          </Link>
                        </center>
                        <br />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div class=" bg3">
              <center>
                <br></br>
                <br></br>
                <h3 class="pop">Top Respiro Labs Near You</h3>
              </center>
              <center>
                <p class="popp">
                  Your Trusted Partner for Accurate and Prompt Lab Services
                </p>
              </center>{" "}
              <br></br>


              <div className="container">
      <div className="row">
        {labData.map((lab) => (
          <div className="col-sm-3" key={lab.id}>
            <div className="card drcard1">
              <img
                src={lab.imageSrc}
                alt={`Image of ${lab.name}`}
                className="dryouth"
              />
              <br />
              <div className="card-content" style={{ textAlign: "center" }}>
                <h5 className="dry">{lab.name}</h5>
                <p className="dry1">
                  {lab.location}
                  <br />
                  {lab.contact}
                </p>
                <center>
                  {[...Array(lab.rating)].map((_, index) => (
                    <FaStar key={index} className="starc" />
                  ))}
                </center>
                <div className="container">
                  <hr />
                </div>
                <a href={lab.profileLink}>
                  <h5 className="viewthp">View Profile</h5>
                </a>
                <center>
                  <button className="custom-btn1 btn-3">
                    <span className="btntexty">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

            </div>
          </div>
        </div>
      </div>
   
      <div class="container-fluid">
        <div class="card">
          <br></br>
          <center>
            {" "}
            <h3 class="pop">How It Works?</h3>
          </center>{" "}
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <center>
                  <b class="text-dark">
                    <h6 class="booktestworks">Book Your Test </h6>
                  </b>
                </center>
                <center>
                  <img
                    src="../style/img/resp/Group 136.png"
                    class="batimg"
                  ></img>
                </center>
                <center>
                  <p class="batpresp">
                    Visit our online portal or contact us to schedule an
                    appointment. <br></br>
                    Choose between in-lab testing or home-based sample
                    collection.{" "}
                  </p>
                </center>
              </div>
              <div class="col-sm-3"></div>
              <div class="col-sm-3 recievetest">
                <center>
                  <b class="text-dark">
                    <h6 class="booktestworks">Prepare for Your Test</h6>
                  </b>
                </center>
                <center>
                  <img
                    src="../style/img/resp/Group 137.png"
                    class="batimg"
                  ></img>
                </center>
                <center>
                  <p class="batpresp">
                    Follow the provided instructions to prepare for your
                    specific test.<br></br>
                    For home-based tests, ensure you follow the kit instructions
                    carefully.{" "}
                  </p>
                </center>
              </div>
              <div class="col-sm-3"></div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-3">
                <center>
                  <b class="text-dark">
                    <h6 class="booktestworks">Get Tested </h6>
                  </b>
                </center>
                <center>
                  <img
                    src="../style/img/resp/Group 139.png"
                    class="batimg"
                  ></img>
                </center>
                <center>
                  <p class="batpresp">
                    Attend your scheduled appointment at Respiro Labs or
                    complete the home-based test and send the sample back to us.{" "}
                  </p>
                </center>
              </div>
              <div class="col-sm-3"></div>
              <div class="col-sm-3 recievetest">
                <center>
                  <b class="text-dark">
                    <h6 class="booktestworks">Receive Your Results </h6>
                  </b>
                </center>
                <center>
                  <img
                    src="../style/img/resp/Group 140.png"
                    class="batimg"
                  ></img>
                </center>
                <center>
                  <p class="batpresp">
                    Access your test results securely online or receive them via
                    email. <br></br>
                    Schedule a consultation with our experts to discuss your
                    results and next steps.{" "}
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </>
  );
}

export default BookaTest;
