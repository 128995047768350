import React from "react";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import RentDATA from "../../data/Rentdata";
import { useDispatch } from "react-redux";
import { FaCartShopping } from "react-icons/fa6";
import Header from "../header/Header";
import { addItem, delItem } from "../../redux/actions/index";

const RentDetail = () => {
  const [cartBtn, setCartBtn] = useState("Add to Cart");
  {
  }
  const proid = useParams();
  const proDetail = RentDATA.filter((x) => x.id == proid.id);
  const product = proDetail[0];

  const dispatch = useDispatch();

  const handleCart = (product) => {
    if (cartBtn === "Add to Cart") {
      dispatch(addItem(product));
      setCartBtn("Remove from Cart");
    } else {
      dispatch(delItem(product));
      setCartBtn("Add to Cart");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header></Header>
      <br></br>
      <br></br>
      <div className="container my-5 py-3">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center mx-auto product">
            <img src={product.img} alt={product.title} className="proimg" />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h1 className="display-5 fw-bold">{product.title}</h1>
            <hr />
            <h2 className="my-4">
              ₹{product.price}
              <font size="3">/Week</font>
            </h2>
            <p className="lead">{product.desc}</p>
            <button
              onClick={() => handleCart(product)}
              className="main_btn ms-2c"
            >
              {" "}
              <FaCartShopping class="text-white" />
              &nbsp;{cartBtn}
            </button>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      ></div>
    </>
  );
};

export default RentDetail;
