import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import RentDATA from "../../data/Rentdata";
import { FaArrowRightLong } from "react-icons/fa6";

const Orent = ({ searchInput }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [showAllProducts, setShowAllProducts] = useState(false);

  const filteredProducts = RentDATA.filter((item) => {
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchInput.toLowerCase());
    const matchesCategory = selectedCategory
      ? item.title.toLowerCase().includes(selectedCategory)
      : true;
    return matchesSearch && matchesCategory;
  }).sort((a, b) => {
    if (sortOrder === "lowToHigh") return a.price - b.price;
    if (sortOrder === "highToLow") return b.price - a.price;
    return 0;
  });

  const displayedProducts = showAllProducts ? filteredProducts : filteredProducts.slice(0, 9);

  const handleCategorySelect = (category) => {
    setSelectedCategory(prevCategory => (prevCategory === category ? "" : category));
    setShowAllProducts(false);
  };

  const toggleShowProducts = () => {
    setShowAllProducts(!showAllProducts);
  };

  return (
    <div style={{ display: "flex" }}>
      {/* Sidebar Filter */}
      <div style={{
        width: "20%",
        padding: "20px",
        backgroundColor: "#f4f4f4",
        borderRight: "1px solid #ddd"
      }}>
        <h4>Filter By</h4>

        {/* Product Category */}
        <div style={{ marginBottom: "20px" }}>
          <h5>Category</h5>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === ""}
              onChange={() => handleCategorySelect("")}
            /> All
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "bp monitor"}
              onChange={() => handleCategorySelect("bp monitor")}
            /> BP Monitor
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "oxygen concentrator"}
              onChange={() => handleCategorySelect("oxygen concentrator")}
            /> Oxygen Concentrator
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "nebulizer"}
              onChange={() => handleCategorySelect("nebulizer")}
            /> Nebulizer
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "massager"}
              onChange={() => handleCategorySelect("massager")}
            /> Massager
          </div>
        </div>

        {/* Sort by Price */}
        <div style={{ marginBottom: "20px" }}>
          <h5>Sort by Price</h5>
          <button
            className="bg-secondary"
            onClick={() => setSortOrder("lowToHigh")}
            style={{ display: "block", margin: "5px 0", width: "100%" }}>
            Low to High
          </button>
          <button
            className="bg-secondary"
            onClick={() => setSortOrder("highToLow")}
            style={{ display: "block", margin: "5px 0", width: "100%" }}>
            High to Low
          </button>
        </div>
      </div>

      {/* Main Product Area */}
      <div style={{ width: "80%", padding: "20px" }}>
        <h1>All Products</h1>
        <div className="row">
          {displayedProducts.map((item) => (
            <div className="col-md-4" key={item.id} style={{ marginBottom: "20px" }}>
              <div className="card o2scard" style={{ position: "relative", padding: "25px", border: "1px solid #ddd" }}>
                <div className="badge" style={{
                  position: "absolute",
                  top: "10px",
                  left: "50px",
                  backgroundColor: "#28a745",
                  color: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px"
                }}>Rent</div>
                <img className="o2simg" src={item.img} alt="" style={{ width: "100%", height: "200px" }} />
                <center><h4 className="o2stitle" style={{ margin: "40px 0" }}>{item.title}</h4></center>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div className="o2sprice" style={{ fontSize: "1.2em", fontWeight: "bold", color: "#333" }}>
                    <small>₹</small>{item.price} /week
                  </div>
                  <NavLink to={`/products/${item.id}`}>
                    <button  style={{marginLeft:"120px"}}className="btn btno2ss" >
                      <span>View <FaArrowRightLong style={{ marginLeft: "5px" }} /></span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={toggleShowProducts}
          style={{ marginTop: "20px", padding: "10px 20px", backgroundColor: "#007bff", color: "#fff", border: "none", borderRadius: "5px" }}>
          {showAllProducts ? "Show Less" : "Show All Products"}
        </button>
      </div>
    </div>
  );
};

export default Orent;
