import { useRoutes } from "react-router-dom";
import BookaTest from "../pages/bookaTest/BookaTest";
import PFT from "../pages/bookaTest/PFT";
import Bloodgastest from "../pages/bookaTest/Bloodgastest";
import Infection from "../pages/bookaTest/Infection";
import Lungbiopsytest from "../pages/bookaTest/Lungbiopsytest";
import Pathogenpaneltest from "../pages/bookaTest/Pathogenpaneltest";
import Sleeptest from "../pages/bookaTest/Sleeptest";
import Hometest from "../pages/bookaTest/Hometest";
import Imagingtest from "../pages/bookaTest/Imagingtest";
import Biomarkertest from "../pages/bookaTest/Biomarkertest";

const BookaTestRoutes = () => {
  let routes = useRoutes([
    { path: "/PFT", element: <PFT /> },
    { path: "/BookaTest", element: <BookaTest /> },
    { path: "/Bloodgastest", element: <Bloodgastest /> },
    { path: "/Infection", element: <Infection /> },
    { path: "/Lungbiopsytest", element: <Lungbiopsytest /> },
    { path: "/Pathogenpaneltest", element: <Pathogenpaneltest /> },
    { path: "/Sleeptest", element:< Sleeptest /> },
    { path: "/Hometest", element:< Hometest /> },
    { path: "/Imagingtest", element:< Imagingtest /> },
    { path: "/Biomarkertest", element:< Biomarkertest/> },
   
  ]);
  return routes;
};

export default BookaTestRoutes;

