import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import validator from "validator";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProviderHeader from "../header/ProviderHeader";

function NewHospital() {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState({
    hospitalName: "",
    addressLine1: "",
    addressLine2: "",
    phone: "",
    email: "",
    website: "",
    place: "",
    district: "",
    state: "",
    pincode: "",
    localityId: "",
  });

  const [loc, setLoc] = useState([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (data.pincode.length < 6) {
      setLoc([]);
      setData({ ...data, place: "", district: "", state: "" });
    } else {
      getLocations(data.pincode);
    }
  }, [data.pincode]);

  useEffect(() => {
    const selectedLoc = loc.filter((item) => item.locality === data.place);
    console.log("selectedLoc", selectedLoc);
    selectedLoc.length > 0 &&
      setData({
        ...data,
        district: selectedLoc[0].districtname,
        state: selectedLoc[0].state,
        localityId: selectedLoc[0].id,
      });
  }, [data.place]);

  const getLocations = (pincode) => {
    getFunction({ pincode }, "/locality/get-location-by-pincode")
      .then((response) => {
        const responseData = response.success.result;
        setLoc(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const regex = new RegExp(/^[\w\s,.-]*$/gm);
    const pinRegex = new RegExp(/^[1-9]\d{2}\s?\d{3}$/);
    if (fieldName === "addressLine1" && !regex.test(fieldValue)) {
      setErrors({ addressLine1: "Invalid Address Line 1" });
    } else if (fieldName === "addressLine2" && !regex.test(fieldValue)) {
      setErrors({ addressLine2: "Invalid Address Line 2" });
    } else if (fieldName === "pincode" && fieldValue.length === 6) {
      getLocations(fieldValue);
    } else if (
      fieldName === "phone" &&
      !validator.isMobilePhone(fieldValue, "en-IN", { strictMode: false })
    ) {
      setErrors({ phone: "Invalid phone number!" });
    } else if (fieldName === "website" && !validator.isURL(fieldValue)) {
      setErrors({ phone: "Invalid web url!" });
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.addressLine1.trim()) {
      newErrors.addressLine1 = "Address line 1 is required";
      valid = false;
    }
    if (!data.place.trim()) {
      newErrors.place = "Place is required";
      valid = false;
    }
    if (!data.state.trim()) {
      newErrors.state = "State is required";
      valid = false;
    }
    if (!data.pincode.trim()) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newHospital = {
      hospitalName: data.hospitalName,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      localityId: data.localityId,
      phone: data.phone,
      email: data.email,
      website: data.website,
      localityId: data.localityId,
    };
    if (validateForm()) {
      setFunction(newHospital, "/hospital/set-hospital")
        .then((response) => {
          toast.success("Hospital Added successfully");
          setData({ ...data, id: response.success.id });
          console.log(response.success);
          setSuccess(true);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error);
          }
          console.log(error);
          toast.error("An error occurred while saving qualification data.");
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <ProviderHeader />
      <div class="container">
        <div class="">
          <br></br> <br></br>
          <h1 class="profileheading1 providerprofileheading100">
            Add New Hospital/Clinic
          </h1>
          <hr class=""></hr>
          <div class="d-flex">
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Hospital/Clinic Name *</h2>
              <input
                type="text"
                name="hospitalName"
                tabIndex={1}
                onChange={changeHandler}
                value={data.hospitalName}
                placeholder="Hospital Name"
                className="form-input textbox"
              />
              {errors.hospitalName && (
                <div className="validation">{errors.hospitalName}</div>
              )}
            </div>
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Address line 1 *</h2>
              <input
                type="text"
                name="addressLine1"
                tabIndex={2}
                onChange={changeHandler}
                value={data.addressLine1}
                placeholder="Address Line 1"
                className="form-input textbox"
              />
              {errors.addressLine1 && (
                <div className="validation">{errors.addressLine1}</div>
              )}
            </div>
          </div>
          <div class="d-flex">
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Address line 2 *</h2>
              <input
                type="text"
                name="addressLine2"
                tabIndex={3}
                onChange={changeHandler}
                value={data.addressLine2}
                placeholder="Address Line 2"
                className="form-input textbox"
              />
              {errors.addressLine2 && (
                <div className="validation">{errors.addressLine2}</div>
              )}
            </div>
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Pincode *</h2>
              <input
                type="text"
                name="pincode"
                tabIndex={4}
                onChange={changeHandler}
                value={data.pincode}
                placeholder="Pincode"
                className="form-input textbox"
              />
              {errors.pincode && (
                <div className="validation">{errors.pincode}</div>
              )}
            </div>
          </div>
          <div class="d-flex">
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Place *</h2>
              <select
                type="text"
                name="place"
                tabIndex={5}
                onChange={changeHandler}
                placeholder="Place"
                className="form-input textbox"
              >
                <option value={data.place}>{data.place}</option>
                {loc &&
                  loc.map((item) => (
                    <option value={item.locality} key={item.id}>
                      {item.locality}
                    </option>
                  ))}
              </select>
              {errors.place && <div className="validation">{errors.place}</div>}
            </div>
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">District *</h2>
              <input
                disabled={true}
                type="text"
                name="state"
                value={data.district}
                placeholder="District"
                className="form-input textbox"
              />
              {errors.district && (
                <div className="validation">{errors.district}</div>
              )}
            </div>
          </div>
          <div class="d-flex">
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">State *</h2>
              <input
                disabled={true}
                type="text"
                name="state"
                value={data.state}
                placeholder="State"
                className="form-input textbox"
              />
              {errors.state && <div className="validation">{errors.state}</div>}
            </div>
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Phone *</h2>
              <input
                type="text"
                name="phone"
                tabIndex={6}
                value={data.phone}
                onChange={changeHandler}
                placeholder="Phone"
                className="form-input textbox"
              />
              {errors.phone && <div className="validation">{errors.phone}</div>}
            </div>
          </div>
          <div class="d-flex">
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Website *</h2>
              <input
                type="text"
                name="website"
                tabIndex={7}
                value={data.website}
                onChange={changeHandler}
                placeholder="Website"
                className="form-input textbox"
              />
              {errors.website && (
                <div className="validation">{errors.website}</div>
              )}
            </div>
            <div class="col-sm-5 serviceinfo2">
              <h2 class="citext topfrom">Email *</h2>
              <input
                type="text"
                name="email"
                tabIndex={8}
                value={data.email}
                onChange={changeHandler}
                placeholder="Email"
                className="form-input textbox"
              />
              {errors.email && <div className="validation">{errors.email}</div>}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <button onClick={handleSubmit}>Submit</button>
        {success && navigate("/provider/profile")}
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default withProviderAuth(NewHospital);
