import { useRoutes } from "react-router-dom";
import ArticleOne from "../pages/articles/ArticleOne";
const ArticlesRoutes = () => {
    let routes = useRoutes([
      { path: "/ArticleOne", element: <ArticleOne /> },

  ]);
  return routes;
};

export default ArticlesRoutes;
