import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { slide as Menu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function RhfHeader() {
  const { rhfEmail, rhfPrivilege,isMobile } = useContext(AuthContext);
  const [menuActive, setMenuActive] = useState(false);
  

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const menubarMobile = (
    <Menu
      left
      pageWrapId={"page-wrap"}
      outerContainerId={"root"}
      style={{ color: "black", right: "0px !important" }}
    >
      <ul className="nav-list mynavlist providerlist">
        <li>
          <Link className="nav-link ddd" to="/rhf/dashboard">
            <font color="black">Dashboard</font>
          </Link>
        </li>
        <li>
          <Link className="nav-link ddd" to="/rhf/profile">
            <font color="black">Profile</font>
          </Link>
        </li>
      </ul>
    </Menu>
  );

  const menuBar = (
    <nav className="nav">
      <input type="checkbox" id="nav-check" />
      <div className="nav-header"></div>
      <div className="nav-btn providerheadernavbtn">
        <label htmlFor="nav-check">
          <span className="bgm1"></span>
          <span className="bgm1"></span>
          <span className="bgm1"></span>
        </label>
      </div>
      <ul className="nav-list mynavlist providerlist">
        <li>
          <Link className="nav-link ddd" to="/rhf/dashboard">
            <font color="#fff">Dashboard</font>
          </Link>
        </li>
        {rhfPrivilege ? (
          <li>
            <Link className="nav-link ddd" to="/rhf/profile">
              <font color="#fff">Profile</font>
            </Link>
          </li>
        ) : (
          <li>
            <Link className="nav-link ddd" to="/rhf/member-profile">
              <font color="#fff">Profile</font>
            </Link>
          </li>
        )}

        {/* <li>
          <a className="nav-link ddd" href="/rhf/report">
            <font color="#fff">Report</font>
          </a>
        </li>
        <li>
          <a className="nav-link ddd" href="/rhf/scheduling">
            <font color="#fff">Schedule</font>
          </a>
        </li> */}
      </ul>
    </nav>
  );

  return (
    <>
      <nav className="navbar" id="page-wrap">
        <div className="d-flex">
          <div className="logo">
            <Link to="/rhf/dashboard">
              <img src="/../style/img/logonav.png" alt="Logo" />
            </Link>
          </div>

          <div className="login-button">
            <ul
              id="primary-menu"
              className={`menu nav-menu ${menuActive ? "menu-active" : ""}`}
            >
              <li className="topnavtext submenu dropdown ">
                {!rhfEmail ? (
                  <Link
                    className="custom-btn btn-1 loginbtn loginbutton dropdown-toggle"
                    to="/rhf/login"
                  >
                    Login
                  </Link>
                ) : (
                  <>
                    <span className="topnavtext dropdown-toggle">
                    {!isMobile ? (
                        rhfEmail
                      ) : (
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ fontSize: "25px"}}
                        />
                      )}
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/rhf/dashboard">
                          <i className="fa-solid fa-user"></i> Dashboard
                        </Link>
                      </li>
                      {rhfPrivilege ? (
                        <li>
                          <Link to="/rhf/profile">
                            <i className="fa-solid fa-user"></i> Profile
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link to="/rhf/member-profile">
                            <i className="fa-solid fa-user"></i> Profile
                          </Link>
                        </li>
                      )}

                      <li>
                        <Link
                          to="/rhf/logout"
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          <i className="fa-solid fa-user"></i> Logout
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {rhfEmail && (isMobile ? menubarMobile : menuBar)}
    </>
  );
}

export default RhfHeader;
