import React from "react";
import Header from "../header/Header";
function SecurityPolicy() {
  return (
    <>
      <Header />
      
        <div className="container-fluid teambg">
        <div class="container">
          <h3 class=" termshead" >Security Policy</h3>

          <h4 className="policy-section-title">1. Introduction</h4>
          <p className="policy-text">
            At Respiro Healthcare, safeguarding the confidentiality, integrity,
            and availability of our information and systems is of paramount
            importance. This Security Policy outlines our commitment to
            protecting sensitive data and ensuring robust security measures are
            in place to mitigate risks.
          </p>

          <h4 className="policy-section-title">2. Scope</h4>
          <p className="policy-text">
            This policy applies to all employees, contractors, and third parties
            who have access to Respiro Healthcare's information systems, data,
            and facilities.
          </p>

          <h4 className="policy-section-title">
            3. Information Security Objectives
          </h4>
          <p className="policy-list">
           
              <strong>Confidentiality:</strong> Ensure that sensitive
              information is only accessible to authorized individuals.
            <br></br>
            
              <strong>Integrity:</strong> Maintain the accuracy and completeness
              of information and prevent unauthorized modifications.
           <br></br>
            
              <strong>Availability:</strong> Ensure that information and systems
              are accessible to authorized users when needed.
            
          </p>

          <h4 className="policy-section-title">
            4. Roles and Responsibilities
          </h4>
          <p className="policy-list">
            
              <strong>Chief Information Security Officer (CISO):</strong>{" "}
              Oversees the implementation and enforcement of security policies
              and procedures.
            <br></br>
            
              <strong>IT Department:</strong> Responsible for the maintenance of
              security systems, network protection, and incident response.
            <br></br>
            
              <strong>Employees and Contractors:</strong> Must adhere to
              security policies and report any security concerns or incidents.
            <br></br>
          </p>

          <h4 className="policy-section-title">5. Access Control</h4>
          <p className="policy-list">
            
              <strong>User Authentication:</strong> All users must authenticate
              using unique credentials. Mpti-factor authentication (MFA) is
              required for accessing sensitive systems.
            <br></br>
            
              <strong>Access Rights:</strong> Access to information and systems
              is granted based on the principle of least privilege. Users are
              provided access only to the resources necessary for their roles.
            <br></br>
            
              <strong>Account Management:</strong> User accounts are reviewed
              periodically to ensure that access rights are current and
              appropriate.
            <br></br>
          </p>

          <h4 className="policy-section-title">6. Data Protection</h4>
          <p className="policy-list">
            
              <strong>Data Encryption:</strong> Sensitive data, both in transit
              and at rest, must be encrypted using industry-standard encryption
              protocols.
            <br></br>
            
              <strong>Data Backup:</strong> Regpar backups of critical data are
              performed and stored securely. Backup procedures are tested
              periodically to ensure data recovery capabilities.
            <br></br>
            
              <strong>Data Retention:</strong> Data is retained according to
              legal and regpatory requirements. Data no longer needed is
              securely disposed of.
            <br></br>
          </p>

          <h4 className="policy-section-title">7. Network Security</h4>
          <p className="policy-list">
            
              <strong>Firewall and Intrusion Detection:</strong> Network
              security is maintained through firewalls, intrusion detection
              systems (IDS), and intrusion prevention systems (IPS).
            <br></br>
            
              <strong>Secure Communication:</strong> Use of secure communication
              channels (e.g., HTTPS, VPN) is mandated for accessing Respiro
              Healthcare's systems remotely.
            <br></br>
            
              <strong>Network Monitoring:</strong> Continuous monitoring of
              network activity to detect and respond to security threats.
            <br></br>
          </p>

          <h4 className="policy-section-title">8. Physical Security</h4>
          <p className="policy-list">
            
              <strong>Facility Access:</strong> Physical access to Respiro
              Healthcare facilities is restricted to authorized personnel.
              Access control systems (e.g., key cards) are used to monitor and
              manage entry.
            <br></br>
            
              <strong>Equipment Security:</strong> All hardware is secured and
              monitored to prevent unauthorized access or tampering. Devices are
              protected with locks and secure storage.
            <br></br>
          </p>

          <h4 className="policy-section-title">9. Incident Management</h4>
          <p className="policy-list">
            
              <strong>Incident Response:</strong> A formal incident response
              plan is in place to address security breaches and other incidents.
              All incidents must be reported immediately to the IT department.
            <br></br>
            
              <strong>Incident Investigation:</strong> Incidents are
              investigated thoroughly to identify the cause and impact. Remedial
              actions are taken to prevent recurrence.
            <br></br>
          </p>

          <h4 className="policy-section-title">10. Training and Awareness</h4>
          <p className="policy-list">
            
              <strong>Security Training:</strong> All employees and contractors
              receive mandatory security training upon hiring and periodically
              thereafter. Training includes best practices for data protection
              and recognizing security threats.
            <br></br>
            
              <strong>Awareness Programs:</strong> Regpar security awareness
              programs and communications are conducted to keep all stakeholders
              informed of current threats and security practices.
            <br></br>
          </p>

          <h4 className="policy-section-title">11. Compliance and Audits</h4>
          <p className="policy-list">
            
              <strong>Regpatory Compliance:</strong> Respiro Healthcare
              complies with all applicable legal, regpatory, and contractual
              security requirements.
            <br></br>
            
              <strong>Security Audits:</strong> Regpar security audits are
              conducted to assess the effectiveness of security controls and
              identify areas for improvement.
            <br></br>
          </p>

          <h4 className="policy-section-title">12. Policy Review</h4>
          <p className="policy-text">
            This Security Policy is reviewed annually or as needed to ensure its
            relevance and effectiveness. Updates are communicated to all
            relevant stakeholders.
          </p>

          <h4 className="policy-section-title">13. Contact Information</h4>
          <p className="policy-text">
            For questions or concerns regarding this Security Policy, please
            contact:
            <br />
            Respiro Healthcare IT Department
            <br />
            Email:{" "}
            <a
              href="mailto:business@respirohealthcare.com"
              classname="policy-contact"
            >
              business@respirohealthcare.com
            </a>
            <br />
            Phone: +91 8075892588
          </p>
        </div>
      </div>
     
    </>
  );
}

export default SecurityPolicy;
