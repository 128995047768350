import Header from "../header/Header";
export default function Cookie() {
    return (
        <>
        <Header></Header>
        <div class="container-fluid teambg">
            <div class="container" >
                {/* <div class="container usehead" >
                    <span class="dot1"></span>
                    <span class="dot2"></span>
                    <span class="dot3"></span>
                    </div> */}
                <b class="text-dark">
                    <h3 class=" termshead ">Cookie Policy</h3>
                </b>
                <br></br>

                <br></br>
                <p class=" termsp1 privj">
                    Welcome to Respiro (the "Website"). This Cookie Policy explains what cookies are,
                    how we use cookies, how third parties we may partner with may use cookies on the Website,
                    your choices regarding cookies, and further information about cookies.
                </p>
                <br></br>

                <h5 class="privacyhead">
                    <b class="text-dark"> What are Cookies?</b>
                </h5>
                <p class=" termsp1 privj">
                    Cookies are small pieces of text sent by your web browser by a website you visit.
                    A cookie file is stored in your web browser and allows the Website or a third-party
                    to recognize you and make your next visit easier and the Website more useful to you.

                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">How Respiro Uses Cookies?</b>
                </h5>
                <p class=" termsp1 privj">
                    When you use and access the Website, we may place a number of cookie files in your web browser. We use cookies for the following purposes:<br></br>
                    ● To enable certain functions of the Website.<br></br>
                    ● To provide analytics.<br></br>
                    ● To store your preferences.<br></br>
                    ● We use both session and persistent cookies on the Website and we use different types of cookies to run the Website:<br></br>
                    ● Essential Cookies: We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.<br></br>
                    ● Performance and Functionality Cookies: These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.<br></br>
                    ● Analytics and Customization Cookies: These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.<br></br>
                    ● Advertising Cookies: These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.<br></br>

                </p>


                <br></br>
                <h5 class="privacyhead">
                    <b class="text-dark"> Third-Party Cookies</b>
                </h5>
                <p class=" termsp1 privj">
                    In addition to our own cookies, we may also use various third parties' cookies to report usage statistics of the Website, deliver advertisements on and through the Website, and so on.
                </p>

                <h5 class="privacyhead">
                    <b class="text-dark">  Your Choices Regarding Cookies</b>
                </h5>
                <p class=" termsp1 privj">
                    If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.<br></br>
                    For the Chrome web browser, please visit this page from Google: Chrome<br></br>
                    For the Internet Explorer web browser, please visit this page from Microsoft: Internet Explorer<br></br>
                    For the Firefox web browser, please visit this page from Mozilla: Firefox<br></br>
                    For the Safari web browser, please visit this page from Apple: Safari<br></br>
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">More Information about Cookies</b>
                </h5>
                <p class=" termsp1 privj">
                    You can learn more about cookies and the following third-party websites:<br></br>
                    AllAboutCookies: All About Cookies<br></br>
                    Network Advertising Initiative: Network Advertising Initiative<br></br>
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Changes to This Cookie Policy</b>
                </h5>
                <p class=" termsp1 privj">
                    We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page. You are advised to review this Cookie Policy periodically for any changes. Changes to this Cookie Policy are effective when they are posted on this page.
                </p>

                <h5 class="privacyhead">
                    <b class="text-dark">Contact Information</b>
                </h5>
                <p class=" termsp1 privj">
                    If you have any questions about this Cookie Policy, please contact us:<br></br>
                    Email:{" "}
            <a
              href="mailto:business@respirohealthcare.com"
              classname="policy-contact"
            >
              business@respirohealthcare.com
            </a><br></br>
                    Phone:+91 8075892588<br></br>

                </p>

                </div>
            </div>
        </>
    );
}








