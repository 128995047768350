import { useRoutes } from "react-router-dom";
import Team from "../pages/footerpages/Team";
import Privacy from "../pages/footerpages/Privacy";
import Terms from "../pages/footerpages/Terms";
import Refund from "../pages/footerpages/Refund";
import Career from "../pages/footerpages/Career";
import Shipping from "../pages/footerpages/Shipping";
import Return from "../pages/footerpages/Return";
import FAQ from "../pages/footerpages/FAQ";
import Upevents from "../pages/footerpages/Upevents";
import Cookie from "../pages/footerpages/Cookie";
import Disclaimer from "../pages/footerpages/Disclaimer";
import Newsletter from "../pages/footerpages/Newsletter";
import Community from "../pages/footerpages/Community";
import Plus from "../pages/footerpages/Plus";
import SecurityPolicy from "../pages/footerpages/SecurityPolicy";
import HealthFeed from "../pages/footerpages/HealthFeed";
import SurgeryServices from "../pages/footerpages/SurgeryServices";
import Respirofoundation from "../pages/footerpages/Respirofoundation";
const FooterpageRoutes = () => {
  let routes = useRoutes([
    { path: "/Team", element: <Team /> },
    { path: "/Privacy", element: <Privacy />},
    { path: "/Terms", element: <Terms />},
    { path: "/Refund", element: <Refund />},
    { path: "/Career", element: <Career />},    
    { path: "/Shipping", element: <Shipping />},
    { path: "/Return", element: <Return />},
    { path: "/FAQ", element: <FAQ />},
    { path: "/Upevents", element: <Upevents />},
    { path: "/Cookie", element: <Cookie />},
    { path: "/Disclaimer", element: <Disclaimer />},
    { path: "/Newsletter", element: <Newsletter/>},
    { path: "/Community", element: <Community/>},
    { path: "/Plus", element: <Plus />},
    { path: "/SecurityPolicy", element: <SecurityPolicy />},
    { path: "/HealthFeed", element: <HealthFeed />},
    { path: "/SurgeryServices", element: <SurgeryServices/>},
    { path: "/Respirofoundation", element: <Respirofoundation/>},
    
  ]);
  return routes;
};

export default FooterpageRoutes;