import Header from "../header/Header";

export default function Return() {
    return (
        <>   
        <Header></Header>  
         <div class="container-fluid teambg">
            <div class="container">
                <b class="text-dark">
                    <h3 class=" termshead ">Return Policy</h3>
                </b>
                <br></br>

                <br></br>
                <p class=" termsp1 privj">
                    At Respiro, we are committed to providing the highest quality medical devices and health care products. We understand that sometimes
                    a product may not meet your expectations, and we are here to help. This Return Policy outlines the conditions and processes for returning
                    items purchased from Respiro.
                </p>
                <br></br>
                <h4> Eligibility for Returns</h4>
                <br></br>
                <h5 class="privacyhead">
                    <b class="text-dark"> General Conditions</b>
                </h5>
                <p class=" termsp1 privj">
                    Products must be returned within 30 days of receipt.<br></br>
                    Items must be unused, in their original packaging, and in the same condition that you received them.<br></br>
                    A valid proof of purchase (receipt or order number) is required.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Non-Returnable Items</b>
                </h5>
                <p class=" termsp1 privj">
                    ● Opened or used medical devices and supplies.<br></br>
                    ●Products marked as non-returnable on the product page.<br></br>
                    ●Personalized or customized items.<br></br>
                    ●Items not purchased directly from Respiro (e.g., third-party sellers).
                </p>

                <h4
                    class="text-dark">Return Process
                </h4>
                <br></br>
                <h5 class="privacyhead">
                    <b class="text-dark"> Step-by-Step Guide</b>
                </h5>
                <p class=" termsp1 privj">
                    <b class="text-dark">1.Initiate a Return:</b> Contact our Customer Support team at [Support Email Address] or [Support Phone Number] to
                    initiate a return. Provide your order number and details about the product you wish to return.<br></br>
                    <b class="text-dark">2.Receive Return Authorization:</b> Once your return request is approved, you will receive a Return Authorization (RA)
                    number and instructions on how to return the item.<br></br>
                    <b class="text-dark">3.Package the Item:</b> Securely package the item in its original packaging, including all accessories, manuals, and parts.
                    Clearly write the RA number on the outside of the package.<br></br>
                    <b class="text-dark">4.Ship the Item:</b>: Ship the item to the address provided by our Customer Support team. We recommend using a trackable
                    shipping service and purchasing shipping insurance.
                </p>
                <h4
                    class="text-dark">Refunds
                </h4>
                <h5 class="privacyhead">
                    <b class="text-dark">  Processing Refunds</b>
                </h5>
                <p class=" termsp1 privj">
                    ● Once we receive and inspect your return, we will notify you of the approval or rejection of your refund.<br></br>
                    ●Approved refunds will be processed within 7-10 business days and will be applied to your original method of payment.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Partial Refunds</b>
                </h5>
                <p class=" termsp1 privj">
                    Partial refunds may be granted in certain situations, such as items returned in a
                    condition different from the original or missing parts.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Exchanges</b>
                </h5>
                <p class=" termsp1 privj">
                    If you received a defective or damaged item, we will replace it at no additional cost. Contact
                    our Customer Support team to arrange an exchange.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Shipping Costs</b>
                </h5>
                <p class=" termsp1 privj">
                    Return shipping costs are the responsibility of the customer, except in cases where the return is due to our error
                    (e.g., wrong item shipped, defective product).
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Contact Information</b>
                </h5>
                <p class=" termsp1 privj">
                    For any questions or concerns regarding returns, please contact our Customer Support team:<br></br>
                    Email:bussiness@respirohealthcare.com<br></br>
                        Phone:+91 8075892588<br></br>
                    
                </p>
                <h4
                    class="text-dark">Additional Information
                </h4>
                <h5 class="privacyhead">
                    <b class="text-dark">Respiro Labs and Clinics</b>
                </h5>
                <p class=" termsp1 privj">
                Products purchased from Respiro Labs and Clinics must be returned directly to the respective location. 
                Please contact the specific Lab or Clinic for their return policy details.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Medical Equipment Rent/Sale</b>
                </h5>
                <p class=" termsp1 privj">
                For rented medical equipment, refer to the rental agreement for return instructions and conditions.
                Purchased medical equipment is subject to the general return conditions outlined above.
                </p>
                <h3 class="privacyhead">
                    <b class="text-dark">Conclusion</b>
                </h3>
                <p class=" termsp1 privj">
                At Respiro, our priority is your satisfaction and well-being. We strive to make the return process as smooth
                 and hassle-free as possible. If you have any feedback or suggestions regarding our Return Policy, please do 
                 not hesitate to contact us. Thank you for choosing Respiro for your healthcare needs.
                </p>
            </div>
            </div>
        </>
    );
}


