const TeacherData = [
  {
    id: 1,
    title: "Voice Care Kit for Teachers with Breathing Exercises",
    price: 1200,
    desc: "This package is tailored to support teachers in maintaining optimal vocal and respiratory health. It includes essential tools and exercises designed to enhance breathing control, promote vocal health, and improve overall respiratory wellness.",
    img: "../style/img/resp/teacherbg1.png",
  },
  {
    id: 2,
    title: "Online Vocal Health Workshop for Educators(One-time Fee)",
    price: 500,
    desc: "This kit is designed specifically for teachers to support optimal vocal and respiratory health. It includes essential tools and exercises aimed at improving breathing control and enhancing vocal health.",
    img: "../style/img/resp/teacherbg1.png",
  },
  {
    id: 3,
    title: "Ergonomic Teacher's Chair with Back Support",
    price: 3500,
    desc: "This package focuses on optimizing classroom ergonomics for teachers. It includes an ergonomic chair designed to provide excellent back support and promote proper posture during teaching sessions.",
    img: "../style/img/resp/teacherbg3.png",

  },
  {
    id: 4,
    title: "Height-Adjustable Lectern for Comfortable Speaking Posture",
    price: 3000,
    desc: "Ensure a comfortable and ergonomic speaking posture with our height-adjustable lectern. Designed to accommodate individual height preferences, this lectern promotes a healthy posture during lectures and presentations.",
    img: "../style/img/resp/teacherbg4.png",
  },
  {
    id: 5,
    title: "Anti-Fatigue Mat for Standing Teachers",
    price: 700,
    desc: "Our Anti-Fatigue Mat is designed specifically for teachers who spend long hours standing, providing essential support to enhance comfort and well-being.",
    img: "../style/img/resp/teacherbg5.png",
  },
  {
    id: 6,
    title: "Indoor Air Quality Enhancement Air Purifier with HEPA Filter for Classroom",
    price: 7000,
    desc: " Enhance the air quality in your classroom with our high-efficiency air purifier equipped with a HEPA filter. Designed to remove airborne pollutants and allergens, it ensures a healthier and cleaner environment for both teachers and students.",
    img: "../style/img/resp/teacherbg6.png",
  },
  {
    id: 7,
    title: "Indoor Plants Known for Air Purification and Oxygen Production",
    price: 700,
    desc: " Enhance the air quality and aesthetic appeal of your classroom or workspace with our set of 3 indoor plants. These plants are specifically chosen for their air-purifying qualities and ability to produce oxygen, creating a healthier environment.",
    img: "../style/img/resp/teacherbg7.png",
  },
  {
    id: 8,
    title: "Stress Management and Relaxation  Techniques",
    price: "600/year",
    desc: "This service provides educators with a subscription to a specialized meditation app designed to help manage stress and promote relaxation. The app includes a variety of meditation sessions, breathing exercises, and relaxation techniques specifically tailored for the unique challenges faced by educators.",
    img: "../style/img/resp/teacherbg8.png",
  },
  {
    id: 9,
    title: "Aromatherapy Diffuser with Relaxing Essential Oils for Teacher's Lounge",
    price: 3000,
    desc: "This product offers an aromatherapy diffuser paired with relaxing essential oils, perfect for creating a calming environment in the teacher's lounge. It helps in reducing stress, enhancing mood, and promoting relaxation.",
    img: "../style/img/resp/teacherbg9.png",
  },
  {
    id: 10,
    title: "Yoga for Teachers Online Course for  Stress Reduction and Improved Breathing",
    price: "700/year",
    desc: " This online course is specifically designed for teachers, focusing on yoga practices that reduce stress and improve breathing. The course includes guided yoga sessions, breathing exercises, and mindfulness techniques tailored to the unique needs of educators.",
    img: "../style/img/resp/teacherbg10.png",
  },
  {
    id: 11,
    title: "Healthy Lifestyle Promotion",
    price: "1500/month",
    desc: " A monthly subscription box delivering a curated selection of healthy, teacher-approved snacks. Each box is designed to support a healthy lifestyle, providing nutritious and delicious snacks that can be enjoyed on the go or during breaks.",
    img: "../style/img/resp/teacherbg11.png",
  },
  {
    id: 12,
    title: " Fitness Tracker Watch for Activity Monitoring and Movement Encouragement",
    price: 3500,
    desc: " A state-of-the-art fitness tracker watch designed to monitor activity levels, track fitness metrics, and encourage movement throughout the day. Ideal for teachers looking to stay active and maintain a healthy lifestyle.",
    img: "../style/img/resp/teacherbg12.png",
  },
  {
    id: 13,
    title: "Nutrition Consultation Session for Educators' Dietary Needs",
    price: 700,
    desc: " Personalized nutrition consultation session focusing on the specific dietary needs of educators. Designed to promote health and well-being through expert dietary guidance.",
    img: "../style/img/resp/teacherbg13.png",
  },
  {
    id: 14,
    title: "Personalized Breathing Assessment with Emphasis on Teachers' Respiratory Health",
    price: 1000,
    desc: "Our Personalized Breathing Assessment is specially designed to cater to the unique respiratory needs of teachers. This comprehensive package focuses on evaluating and optimizing your breathing patterns and lung function to ensure you maintain peak respiratory health throughout your teaching career.",
    img: "../style/img/resp/teacherbg14.png",
  },
  {
    id: 15,
    title: "Classroom Environment Evaluation for Air Quality and Respiratory Health Consideration",
    price: 2500,
    desc: " Comprehensive evaluation of classroom environment focusing on air quality and respiratory health considerations. Designed to identify potential sources of indoor air pollution and provide recommendations for improving air quality.",
    img: "../style/img/resp/teacherbg15.png",
  },
  {
    id: 16,
    title: "Special Lung Care Package For Teachers",
    price: 999,
    desc: "Our Special Lung Care Package is tailored exclusively for teachers to prioritize their respiratory health and well-being. This comprehensive package includes essential tests to monitor and maintain optimal lung function, ensuring you can breathe easier and stay healthy while managing the demanding responsibilities of your profession.",
    img: "../style/img/resp/teacherbg16.png",
  },
  

];

export default TeacherData;
