import React, { useEffect, useState, useContext } from "react";
import DocumentTable from "./DocumentTable";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function Document() {
  const { providerId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [newRow, setNewRow] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const emptyRow = {
    id: "",
    providerId: "",
    fileName: "",
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const documentsResponse = await getFunction({}, "/patient/file/get");
        const documentsData = documentsResponse.success.result;
        setDocuments(documentsData);

        // Assuming `patientId` is fetched from somewhere
        const patientId = "some_patient_id"; // Replace this with actual patientId
        const dataResponse = await getFunction({ patientId }, "/patient/file/get");
        const dataResult = dataResponse.success.result;
        setData(dataResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [providerId]);

  const addEmptyRow = () => {
    setData((prevData) => [...prevData, emptyRow]);
  };

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  return (
    
    <div className="container">
      <div className="providerline">
        <br />
        <br />
        <br />
        <h1 className="providerprofileheading100 profileheading1">
          Documents
        </h1>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <hr className="providerline" />
            <table class="table-responsive doctbl">
              <thead>
                <tr>
                  <th>Document</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <DocumentTable
                    key={item.id}
                    data={{
                      id: item.id,
                      providerId: item.providerId,
                      fileName: item.fileName,
                    }}
                    documents={documents}
                    handleDelete={handleDelete}
                  />
                ))}
              </tbody>
            </table>
            <button
              onClick={() => {
                setNewRow(true);
                addEmptyRow();
                setNewRow(false);
              }}
            >
              +
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default withProviderAuth(Document);
