import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { NavLink, Link } from "react-router-dom";
import TeacherData from "../../data/TeacherData";
const Teachercareplan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const items = TeacherData.filter(
    (item) =>
      item.id === 1 ||
      item.id === 2 ||
      item.id === 3 ||
      item.id === 4 ||
      item.id === 5 ||
      item.id === 6 ||
      item.id === 7 ||
      item.id === 8 ||
      item.id === 9 ||
      item.id === 10 ||
      item.id === 11 ||
      item.id === 12 ||
      item.id === 13 ||
      item.id === 14 ||
      item.id === 15 ||
      item.id === 16
  );
  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // Function to add the 'show' class to elements in the viewport
  function checkCards() {
    const cards = document.querySelectorAll('.careercardanim');
    cards.forEach(card => {
      if (isElementInViewport(card)) {
        card.classList.add('show');
      }
    });
  }

  // Run the checkCards function on scroll
  window.addEventListener('scroll', checkCards);

  // Run the checkCards function on page load (in case some cards are initially visible)
  window.addEventListener('load', checkCards);
  

  return (
    <>
      <Header></Header>

      <div class="bg4">
        <div class="techbanner">
          <div class="container">
            <center>
              <h3 class="techhed tit cartexanim">
                Teacher Respiratory Health Care Package
              </h3>
            </center>
          </div>
        </div>
        <div class="container">
          <br></br>
          <p class="techptext psize">
            As an educator, your dedication to shaping young minds often
            involves long hours of teaching and classroom management, which can
            impact your respiratory health. Our specialized Teacher Respiratory
            Health Plan is designed to support you in maintaining optimal lung
            function and well-being, tailored to address the unique challenges
            faced by teachers.
          </p>
          <p class="techpbold techb">
            {" "}
            Here's what our Teacher Respiratory Health Plan includes:
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="card techcard careercardanim">
                <center>
                  <div class="containertech ">

                    <br></br>
                    
                    <h3 class="techshed"> Breath Control and Vocal Health</h3>
                    <img
                      src="../style/img/resp/techi1.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Provide resources and techniques for improving breath
                      control during teaching sessions and presentations. Offer
                      guidance on proper breathing techniques to enhance vocal
                      projection and endurance.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card techcard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Classroom Ergonomics Optimization</h3>
                    <img
                      src="../style/img/resp/techi2.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Promote proper posture and teaching stance to reduce
                      strain on the respiratory system and enhance breathing
                      efficiency. Offer ergonomic seating options and adjustable
                      lecterns to support optimal teaching posture and comfort.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card techcard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Indoor Air Quality Enhancement</h3>
                    <img
                      src="../style/img/resp/techi3.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Raise awareness about the importance of indoor air quality
                      for teachers' respiratory health. Recommend air purifiers
                      and ventilation strategies to reduce exposure to indoor
                      pollutants and allergens in classrooms and staff rooms.
                    </p>
                  </div>
                </center>
                <br></br>
                
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card techcard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">
                      Stress Management and Relaxation Techniques
                    </h3>
                    <img
                      src="../style/img/resp/techi4.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Provide resources and strategies for managing stress and
                      anxiety-related breathing difficulties in the classroom.
                      Offer relaxation techniques such as breathing exercises,
                      meditation, and mindfulness to promote calmness and focus
                      during teaching hours.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card techcard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Healthy Lifestyle Promotion:</h3>
                    <img
                      src="../style/img/resp/techi5.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Provide educational materials on maintaining a healthy
                      lifestyle to support overall respiratory health and
                      well-being. Offer tips on nutrition, hydration, and
                      physical activity tailored to the specific needs of
                      educators.
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card techcard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">Enhanced Features</h3>
                    <img
                      src="../style/img/resp/techi6.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      <b class="text-dark">
                        {" "}
                        Personalized Breathing Assessment:
                      </b>
                      Access to personalized breathing assessments conducted by
                      respiratory health experts to identify individual needs
                      and tailor recommendations accordingly.
                    </p>
                    <p class="techptext1">
                      <b class="text-dark">
                        {" "}
                        Classroom Environment Evaluation:
                      </b>
                      Assessment of air quality and ergonomic factors in
                      classrooms to identify potential respiratory health risks
                      and provide recommendations for improvement.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="bg4">
      <img src="../style/img/resp/techb.png" className="hiwbg" alt="Background" />
      <br />
      <br />
      {/* Card 1 */}
      <div className="card techfcard">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src="../style/img/resp/Rectangle 195.png" className="techcardimg" alt="Breathing Control" />
            </div>
            <div className="col-sm-6">
              <center>
                <h4 className="hea">Breathing Control and Vocal Health</h4>
                <p className="sub teachline">
                  Voice Care Kit for Teachers with Breathing Exercises
                  <br />
                  (One Time Purchase)
                </p>
                <p className="sub teachline">
                  Online Vocal Health Workshop for Educators
                  <br />
                  (One-time Fee)
                  <br />
                </p>
              </center>
            </div>
            <div className="col-sm-2">
              <h5 className="fprice pri1 teachprup1">₹ 1200/-</h5>
              <h5 className="sprice pri2 teachprup2">₹ 500/-</h5>
            </div>
            <div className="col-sm-1">
              {items.map((item) => (
                <div className="button-container teabtnryt" key={item.id}>
                  {item.id === 1 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="fprice prib custom-btn1 btn-3 teachbtn1">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 2 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="bprice prib2 custom-btn1 btn-3 teachbtn2">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <center>
        <hr className="techhr" />
      </center>

      {/* Card 2 */}
      <div className="card techfcard">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src="../style/img/resp/Rectangle 200.png" className="techcardimg1" alt="Classroom Ergonomics" />
            </div>
            <div className="col-sm-6">
              <center>
                <h4 className="hea">Classroom Ergonomics Optimization</h4>
                <p className="sub teachline">
                  Ergonomic Teacher's Chair<br />
                  with Back Support
                </p>
                <p className="sub teachline">
                  Height-Adjustable Lectern for Comfortable <br />
                  Speaking Posture
                </p>
                <p className="sub teachline">
                  Anti-Fatigue Mat for <br />
                  Standing Teachers
                </p>
              </center>
            </div>
            <div className="col-sm-2">
              <h5 className="fprice pri3 teachprup3">₹ 3500/-</h5>
              <h5 className="sprice pri4 teachprup4">₹ 3000/-</h5>
              <h5 className="tprice pri5 teachprup5">₹ 700/-</h5>
            </div>
            <div className="col-sm-1">
              {items.map((item) => (
                <div className="button-container teabtnryt" key={item.id}>
                  {item.id === 3 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="fprice prib3 custom-btn1 btn-3 teachbtn3">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 4 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="bprice prib4 custom-btn1 btn-3 teachbtn4">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 5 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="tprice prib5 custom-btn1 btn-3 teachbtn5">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <center>
        <hr className="techhr" />
      </center>

      {/* Card 3 */}
      <div className="card techfcard">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src="../style/img/resp/Rectangle 201.png" className="techcardimg" alt="Indoor Air Quality" />
            </div>
            <div className="col-sm-6">
              <center>
                <h4 className="hea">Indoor Air Quality Enhancement</h4>
                <p className="sub teachline">
                  Air Purifier with HEPA Filter for<br />
                  Classroom Use
                </p>
                <p className="sub teachline">
                  Indoor Plants Known for Air Purification and Oxygen<br />
                  Production (Set of 3)
                </p>
              </center>
            </div>
            <div className="col-sm-2">
              <h5 className="fprice pri6 teachprup6">₹ 7000/-</h5>
              <h5 className="sprice pri7 teachprup7">₹ 700/-</h5>
            </div>
            <div className="col-sm-1">
              {items.map((item) => (
                <div className="button-container teabtnryt" key={item.id}>
                  {item.id === 6 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="fprice prib6 custom-btn1 btn-3 teachbtn6">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 7 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="bprice prib7 custom-btn1 btn-3 teachbtn7">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <center>
        <hr className="techhr" />
      </center>

      {/* Card 4 */}
      <div className="card techfcard">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src="../style/img/resp/Rectangle 202.png" className="techcardimg1" alt="Stress Management" />
            </div>
            <div className="col-sm-6">
              <center>
                <h4 className="hea">Stress Management and Relaxation Techniques</h4>
                <p className="sub teachline">
                  Meditation App Subscription Tailored for <br />
                  Educators Stress Relief
                </p>
                <p className="sub teachline">
                  Aromatherapy Diffuser with Relaxing Essential Oils for
                  <br />
                  Teacher's Lounge
                </p>
                <p className="sub teachline">
                  Yoga for Teachers Online Course for Stress Reduction and
                  Improved Breathing
                </p>
              </center>
            </div>
            <div className="col-sm-2">
              <h5 className="fprice pri8 teachprup8">₹ 600/year</h5>
              <h5 className="sprice pri9 teachprup9">₹ 3000/-</h5>
              <h5 className="tprice pri10 teachprup10">₹ 700/year</h5>
            </div>
            <div className="col-sm-1">
              {items.map((item) => (
                <div className="button-container teabtnryt" key={item.id}>
                  {item.id === 8 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="fprice prib8 custom-btn1 btn-3 teachbtn8">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 9 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="bprice prib9 custom-btn1 btn-3 teachbtn9">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 10 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="tprice prib10 custom-btn1 btn-3 teachbtn10">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <center>
        <hr className="techhr" />
      </center>

      {/* Card 5 */}
      <div className="card techfcard">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src="../style/img/resp/Rectangle 203.png" className="techcardimg" alt="Personal Development" />
            </div>
            <div className="col-sm-6">
              <center>
                <h4 className="hea">Personal Development and Professional Growth</h4>
                <p className="sub teachline">
                  Online Courses for Skill Development
                  <br />
                  (Various Topics)
                </p>
                <p className="sub teachline">
                  Educational Books and Resources for Teachers
                  <br />
                  (One-Time Purchase)
                </p>
                <p className="sub teachline">
                  Coaching Sessions for Career Advancement
                  <br />
                  (Monthly Subscription)
                </p>
              </center>
            </div>
            <div className="col-sm-2">
              <h5 className="fprice pri11 teachprup11">₹ 2000/-</h5>
              <h5 className="sprice pri12 teachprup12">₹ 500/-</h5>
              <h5 className="tprice pri13 teachprup13">₹ 3000/-</h5>
            </div>
            <div className="col-sm-1">
              {items.map((item) => (
                <div className="button-container teabtnryt" key={item.id}>
                  {item.id === 11 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="fprice prib11 custom-btn1 btn-3 teachbtn11">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 12 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="bprice prib12 custom-btn1 btn-3 teachbtn12">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 13 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="tprice prib13 custom-btn1 btn-3 teachbtn13">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <center>
        <hr className="techhr" />
      </center>

      {/* Card 6 */}
      <div className="card techfcard">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <img src="../style/img/resp/Rectangle 205.png" className="techcardimg" alt="Community and Collaboration" />
            </div>
            <div className="col-sm-6">
              <center>
                <h4 className="hea">Enhanced Features</h4>
                <p className="sub teachline">
                Personalized Breathing Assessment with Emphasis
                on Teachers' Respiratory Health
                  <br />
                  
                </p>
                <p className="sub teachline">
                Classroom Environment Evaluation for Air Quality
                and Respiratory Health Consideration
                  <br />
                  
                </p>
              </center>
            </div>
            <div className="col-sm-2">
              <h5 className="fprice pri14 teachprup14">₹ 1500/year</h5>
              <h5 className="sprice pri15 teachprup15">₹ 2000/-</h5>
            </div>
            <div className="col-sm-1">
              {items.map((item) => (
                <div className="button-container teabtnryt" key={item.id}>
                  {item.id === 14 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="fprice prib14 custom-btn1 btn-3 teachbtn14">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                  {item.id === 15 && (
                    <NavLink to={`/products/${item.id}`}>
                      <button className="bprice prib15 custom-btn1 btn-3 teachbtn15">
                        <span className="btntexty btnfsm">Book Now</span>
                      </button>
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <center>
        <hr className="techhr" />
      </center>
    </div>
    <div className="bg2">
      <br />
      <div className="container-fluid">
        <div className="card techlcard">
          <br />
          <div className="container">
            <center>
              <h4 className="techshed">Special Lung Care Package For Teachers</h4>
            </center>
            <br />
            <div className="row">
              <div className="col-sm-4">
                <img
                  src="../style/img/resp/Rectangle 224.png"
                  className="lcardimg"
                  alt="Special Lung Care Package"
                />
              </div>
              <div className="col-sm-8">
                <h5>Monthly Diagnosis Plan</h5>
                <br />
                <div className="container">
                  
                  <p>
                    Our Special Lung Care Package is designed specifically for teachers to ensure their respiratory health and
                    well-being. With the variety of tests included in this all-inclusive package, your lung function will be
                    monitored and maintained at its best, allowing you to breathe more easily and remain healthy while performing
                    the demanding duties of your job. The package includes:
                  </p>
                  <p className="techlp">
                    Peak Flow Test
                    <br />
                    Oxygen Air Flow Test
                    <br />
                    Asthma Prevention Check-up
                    <br />
                    Spirometry Test
                    <br />
                    Consultation with Pulmonologist
                  </p>
                  <center>
                    <p>
                      Monthly Diagnosis Plan&nbsp; &nbsp; &nbsp; <b className="text-dark">₹ 999/-</b>&nbsp; &nbsp; &nbsp; &nbsp;
                      {items.map((item) => (
                        <div className="button-container teabtnryt" key={item.id}>
                          {item.id === 16 && (
                            <NavLink to={`/products/${item.id}`}>
                              <button className="custom-btn1 btn-3 teachbtn16">
                                <span className="btntexty btnfsm">Book Now</span>
                              </button>
                            </NavLink>
                          )}
                        </div>
                      ))}
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <br />
    </div>
    </>
  );
};

export default Teachercareplan;
