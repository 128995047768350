import Header from "../header/Header";
export default function Disclaimer() {
    return (
        <>
        <Header></Header>
        <div class="container-fluid teambg">
            <div class="container " >
                {/* <div class="container usehead" >
                    <span class="dot1"></span>
                    <span class="dot2"></span>
                    <span class="dot3"></span>
                    </div> */}
                <b class="text-dark">
                    <h3 class=" termshead "> Disclaimer</h3>
                </b>
                <br></br>

                <br></br>  
                <p class=" termsp1 privj">
                Welcome to Respiro Healthcare . The information provided by us on    <a href="https://respirohealthcare.com" class="text-dark"> www.respirohealthcare.com</a> and through our services is for general informational purposes only. We strive to offer accurate and reliable information but make no warranties or representations regarding the accuracy, reliability, or completeness of any information on the Website.
                </p>
                <br></br>

                <h5 class="privacyhead">
                    <b class="text-dark"> Medical Equipment Rent and Sale Disclaimer</b>
                </h5>
                <p class=" termsp1 privj">
                Respiro Healthcare offers a range of medical equipment for both sale and rental through our Website. While we strive to provide high-quality products, the equipment available for rent or purchase is subject to the respective manufacturer’s warranties and guidelines. We do not guarantee that the equipment will meet your specific needs or that it is free from defects. Please follow the manufacturer’s instructions and consult with healthcare professionals to ensure the equipment is suitable for your needs.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">RHF Services Disclaimer</b>
                </h5>
                <p class=" termsp1 privj">
                Respiro Healthcare’s RHF (Rapid Heal Force) Services offer in-home respiratory care, including emergency care, routine check-ups, and various respiratory tests. While we are committed to delivering high-quality care, the effectiveness of our RHF services relies on accurate information provided by patients and adherence to prescribed care plans. Always follow the guidance of your healthcare provider.
                </p>


                <br></br>
                <h5 class="privacyhead">
                    <b class="text-dark">  Respiro Experts and Respiro Practitioners Disclaimer</b>
                </h5>
                <p class=" termsp1 privj">
                Respiro Experts (Doctors): Our Respiro Experts are highly qualified and experienced medical doctors who provide specialized consultations and medical advice. The information and recommendations provided by our experts are intended to assist with general health inquiries and are based on the latest medical knowledge. However, this information should not be used as a substitute for professional medical advice, diagnosis, or treatment. For personalized medical care, always consult with your healthcare provider.<br></br>
                Respiro Practitioners (Physiotherapists and Respiratory Therapists): Our Respiro Practitioners include trained physiotherapists and respiratory therapists who offer specialized rehabilitation and therapeutic services. Their advice and services are aimed at improving patient health and recovery. While valuable, this information and these services should not replace professional medical advice. For comprehensive care and personalized treatment plans, consult with a qualified healthcare provider.
                                </p>

                <h5 class="privacyhead">
                    <b class="text-dark">  No Doctor-Patient Relationship</b>
                </h5>
                <p class=" termsp1 privj">
                The use of the Website and communication with our practitioners or experts does not establish a doctor-patient relationship. Information provided through the Website and by our practitioners or experts should not be considered a substitute for professional medical consultation.</p>
                <h5 class="privacyhead">
                    <b class="text-dark">External Links Disclaimer</b>
                </h5>
                <p class=" termsp1 privj">
                The Website may contain links to third-party websites or content, including those related to our services such as Respiro Clinics, Respiro Labs, and other partners. We do not control or endorse these external websites and are not responsible for their content or practices. Use these links at your own risk and review their privacy policies and terms of use.</p>
                <h5 class="privacyhead">
                    <b class="text-dark"> Limitation of Liability</b>
                </h5>
                <p class=" termsp1 privj">
                To the fullest extent permitted by law, Respiro Healthcare, including its directors, employees, partners, and affiliates, shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Website or our services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses. Our liability, if any, shall be limited to the maximum extent allowed by applicable law.                </p>

                <h5 class="privacyhead">
                    <b class="text-dark">Product and Service Information</b>
                </h5>
                <p class=" termsp1 privj">
                To the fullest extent permitted by law, Respiro Healthcare, including its directors, employees, partners, and affiliates, shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Website or our services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses. Our liability, if any, shall be limited to the maximum extent allowed by applicable law.

                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Changes to the Disclaimer</b>
                </h5>
                <p class=" termsp1 privj">
                We reserve the right to update or modify this Disclaimer at any time. Any changes will be posted on this page with the effective date updated accordingly. Your continued use of the Website following the posting of changes constitutes your acceptance of such changes.
                </p>
                <h5 class="privacyhead">
                    <b class="text-dark">Contact Us</b>
                </h5>
                <p class=" termsp1 privj">
                If you have any questions about this Disclaimer or our practices, please contact us:<br></br>
                Email: <a href="mailto:business@respirohealthcare.com"  classname="policy-contact">
              business@respirohealthcare.com
            </a>
            <br></br>
             Phone number: +91 8075892588                </p>

            </div>
            </div>
        </>
    );
}















// Disclaimer
// Effective Date: [Insert Date]
// 1. Introduction
// Welcome to Respiro Healthcare (“we,” “us,” or “our”). The information provided by us on https://www.respirohealthcare.com (the “Website”) and through our services is for general informational purposes only. We strive to offer accurate and reliable information but make no warranties or representations regarding the accuracy, reliability, or completeness of any information on the Website.
// 2. Medical Equipment Rent and Sale Disclaimer
// Respiro Healthcare offers a range of medical equipment for both sale and rental through our Website. While we strive to provide high-quality products, the equipment available for rent or purchase is subject to the respective manufacturer’s warranties and guidelines. We do not guarantee that the equipment will meet your specific needs or that it is free from defects. Please follow the manufacturer’s instructions and consult with healthcare professionals to ensure the equipment is suitable for your needs.
// 3. RHF Services Disclaimer
// Respiro Healthcare’s RHF (Rapid Heal Force) Services offer in-home respiratory care, including emergency care, routine check-ups, and various respiratory tests. While we are committed to delivering high-quality care, the effectiveness of our RHF services relies on accurate information provided by patients and adherence to prescribed care plans. Always follow the guidance of your healthcare provider.
// 4. Respiro Experts and Respiro Practitioners Disclaimer
// Respiro Experts (Doctors): Our Respiro Experts are highly qualified and experienced medical doctors who provide specialized consultations and medical advice. The information and recommendations provided by our experts are intended to assist with general health inquiries and are based on the latest medical knowledge. However, this information should not be used as a substitute for professional medical advice, diagnosis, or treatment. For personalized medical care, always consult with your healthcare provider.
// Respiro Practitioners (Physiotherapists and Respiratory Therapists): Our Respiro Practitioners include trained physiotherapists and respiratory therapists who offer specialized rehabilitation and therapeutic services. Their advice and services are aimed at improving patient health and recovery. While valuable, this information and these services should not replace professional medical advice. For comprehensive care and personalized treatment plans, consult with a qualified healthcare provider.
// 5. No Doctor-Patient Relationship
// The use of the Website and communication with our practitioners or experts does not establish a doctor-patient relationship. Information provided through the Website and by our practitioners or experts should not be considered a substitute for professional medical consultation.
// 6. External Links Disclaimer
// The Website may contain links to third-party websites or content, including those related to our services such as Respiro Clinics, Respiro Labs, and other partners. We do not control or endorse these external websites and are not responsible for their content or practices. Use these links at your own risk and review their privacy policies and terms of use.
// 7. Limitation of Liability
// To the fullest extent permitted by law, Respiro Healthcare, including its directors, employees, partners, and affiliates, shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Website or our services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses. Our liability, if any, shall be limited to the maximum extent allowed by applicable law.
// 8. Product and Service Information
// We strive to ensure that information about our medical equipment, RHF services, Respiro Clinics, Respiro Labs, and other services is accurate and current. However, specifications, availability, and prices are subject to change without notice. We do not guarantee that the Website’s content is error-free and reserve the right to correct any errors or omissions.
// 9. Changes to the Disclaimer
// We reserve the right to update or modify this Disclaimer at any time. Any changes will be posted on this page with the effective date updated accordingly. Your continued use of the Website following the posting of changes constitutes your acceptance of such changes.
// 10. Contact Us
// If you have any questions about this Disclaimer or our practices, please contact us:
// By email: mailto:business@respirohealthcare.com
// By visiting this page on our website: Contact Us
// By phone number: +91 8075892588



