// import React, { useEffect, useState, useContext } from "react";
// import {
//   getFunction,
//   updateFunction,
//   setFunction,
// } from "../../api/apiFunctions";
// import { AuthContext } from "../../context/authContext";
// import { Chart as ChartJS } from "chart.js/auto";

// import { Doughnut, Bar, Line } from "react-chartjs-2";
// import moment from "moment";

// function DashboardReports() {
//   //   const [weekData, setWeekData] = useState();
//   const [monthData, setMonthData] = useState();
//   const [monthlyRevenue, setMonthlyRevenue] = useState();
//   const [aptCount, setAptCount] = useState();
//   const [year, setYear] = useState(new Date().getFullYear().toString());
//   const [yearList, setYearList] = useState();
//   const { providerId } = useContext(AuthContext);
//   const [weekData, setWeekData] = useState([]);
//   const [weeklyRevenue, setWeeklyRevenue] = useState([]);
//   const [weeklyAppointmentCount, setWeeklyAppointmentCount] = useState([]);

//   useEffect(() => {
//     setMonthData([]);
//     setMonthlyRevenue([]);
//     setAptCount([]);
//     setWeekData([]);
//     setWeeklyRevenue([]);
//     setWeeklyAppointmentCount([]);
//     //setMonthData();
//     //setMonthlyRevenue();
//     getFunction({ providerId, year }, "/provider/get-weekly-report")
//       .then((response) => {
//         const data = response.success.result;

//         // Format week start dates for labels
//         const weekLabels = data.map((el) =>
//           moment(el.weekStartDate).format("YYYY-MM-DD")
//         );

//         // Extract revenue and appointment counts
//         const revenueData = data.map((el) => el.payable_amount);
//         const appointmentData = data.map((el) => el.appointment_count);

//         // Set state with formatted data
//         setWeekData(weekLabels);
//         setWeeklyRevenue(revenueData);
//         setWeeklyAppointmentCount(appointmentData);
//       })
//       .catch((err) => console.log(err));
//     getFunction({ providerId, year }, "/provider/get-monthly-report")
//       .then((response) => {
//         const data = response.success.result;
//         setMonthData(
//           data.map((el) => moment(el.month_num, "MM").format("MMM"))
//         );
//         setMonthlyRevenue(data.map((el) => el.payable_amount));
//         setAptCount(data.map((el) => el.appointment_count));
//       })
//       .catch((err) => console.log(err));
//     getYearList();
//   }, [year, providerId]);

//   const getYearList = () => {
//     const currYear = new Date().getFullYear();
//     const list = [];
//     for (let i = currYear; i > currYear - 10; i--) {
//       list.push(i);
//     }
//     setYearList(list);
//   };

//   return (
//     <div className="">
//       <div className="">
//         <span className="boldtext" style={{ marginRight: "20px" }}>
//           Select Year
//         </span>
//         <select onChange={(e) => setYear(e.target.value)} value={year}>
//           {yearList && yearList.map((el) => <option key={el}>{el}</option>)}
//         </select>
//       </div>
//       <div className="d-flex">
//         <div className="pcard" style={{ marginRight: "10%" }}>
//           <p>Monthly Revenue Report (Approx)</p>
//           <Bar
//             data={{
//               labels: monthData,
//               datasets: [
//                 {
//                   label: "Monthly Revenue",
//                   data: monthlyRevenue,
//                   backgroundColor: ["#03a1fc", "#fc7303", "blue"],
//                 },
//               ],
//             }}
//           />
//         </div>
        
//         <div className="pcard">
//           <p>Monthly Appointment Count</p>
//           <Line
//             data={{
//               labels: monthData,
//               datasets: [
//                 {
//                   label: "Monthly Appointment Count",
//                   data: aptCount,
//                   backgroundColor: ["#03a1fc", "#fc7303", "blue"],
//                 },
//               ],
//             }}
//           />
//         </div>

//       </div>
//       <div className="d-flex">
//         <div className="pcard" style={{ marginRight: "10%" }}>
//           <p>Weekly Revenue Report (Approx)</p>
//           <Bar
//             data={{
//               labels: weekData,
//               datasets: [
//                 {
//                   label: "Weekly Revenue",
//                   data: weeklyRevenue,
//                   backgroundColor: "#fc7303",
//                 },
//               ],
//             }}
//           />
//         </div>
//         <div className="pcard">
//           <p>Weekly Appointment Count</p>
//           <Line
//             data={{
//               labels: weekData,
//               datasets: [
//                 {
//                   label: "Weekly Appointment Count",
//                   data: weeklyAppointmentCount,
//                   backgroundColor: "#03a1fc",
//                   borderColor: "#03a1fc",
//                   fill: false,
//                   tension: 0.1,
//                 },
//               ],
//             }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DashboardReports;
import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  updateFunction,
  setFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import moment from "moment";

function DashboardReports() {
  const [monthData, setMonthData] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [aptCount, setAptCount] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [yearList, setYearList] = useState([]);
  const { providerId } = useContext(AuthContext);
  const [weekData, setWeekData] = useState([]);
  const [weeklyRevenue, setWeeklyRevenue] = useState([]);
  const [weeklyAppointmentCount, setWeeklyAppointmentCount] = useState([]);

  useEffect(() => {
    setMonthData([]);
    setMonthlyRevenue([]);
    setAptCount([]);
    setWeekData([]);
    setWeeklyRevenue([]);
    setWeeklyAppointmentCount([]);

    getFunction({ providerId, year }, "/provider/get-weekly-report")
      .then((response) => {
        const data = response.success.result;

        const weekLabels = data.map((el) =>
          moment(el.weekStartDate).format("YYYY-MM-DD")
        );
        const revenueData = data.map((el) => el.payable_amount);
        const appointmentData = data.map((el) => el.appointment_count);

        setWeekData(weekLabels);
        setWeeklyRevenue(revenueData);
        setWeeklyAppointmentCount(appointmentData);
      })
      .catch((err) => console.log(err));
    
    getFunction({ providerId, year }, "/provider/get-monthly-report")
      .then((response) => {
        const data = response.success.result;
        setMonthData(
          data.map((el) => moment(el.month_num, "MM").format("MMM"))
        );
        setMonthlyRevenue(data.map((el) => el.payable_amount));
        setAptCount(data.map((el) => el.appointment_count));
      })
      .catch((err) => console.log(err));

    getYearList();
  }, [year, providerId]);

  const getYearList = () => {
    const currYear = new Date().getFullYear();
    const list = [];
    for (let i = currYear; i > currYear - 10; i--) {
      list.push(i);
    }
    setYearList(list);
  };

  return (
    <div className="dashboard-reports">
      <div className="year-selector">
        <span className="boldtext" style={{ marginRight: "20px" }}>
          Select Year
        </span>
        <select onChange={(e) => setYear(e.target.value)} value={year}>
          {yearList && yearList.map((el) => <option key={el}>{el}</option>)}
        </select>
      </div>
      <div className="d-flex">
        <div className="pcard">
          <p>Monthly Revenue Report (Approx)</p>
          <Bar
            data={{
              labels: monthData,
              datasets: [
                {
                  label: "Monthly Revenue",
                  data: monthlyRevenue,
                  backgroundColor: ["#03a1fc", "#fc7303", "blue"],
                },
              ],
            }}
          />
        </div>
        <div className="pcard">
          <p>Monthly Appointment Count</p>
          <Line
            data={{
              labels: monthData,
              datasets: [
                {
                  label: "Monthly Appointment Count",
                  data: aptCount,
                  backgroundColor: ["#03a1fc", "#fc7303", "blue"],
                },
              ],
            }}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="pcard">
          <p>Weekly Revenue Report (Approx)</p>
          <Bar
            data={{
              labels: weekData,
              datasets: [
                {
                  label: "Weekly Revenue",
                  data: weeklyRevenue,
                  backgroundColor: "#fc7303",
                },
              ],
            }}
          />
        </div>
        <div className="pcard">
          <p>Weekly Appointment Count</p>
          <Line
            data={{
              labels: weekData,
              datasets: [
                {
                  label: "Weekly Appointment Count",
                  data: weeklyAppointmentCount,
                  backgroundColor: "#03a1fc",
                  borderColor: "#03a1fc",
                  fill: false,
                  tension: 0.1,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardReports;

