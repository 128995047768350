import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
function ChildLungcareplan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <div class="bg3">
          <br></br>
          <br></br>
          <img src="../style/img/resp/childbnr.png" class="childbnr  childbnrresp"></img>
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-7 childdivresp">
                <h2 class="pop childhead ">

                  <b class="text-dark  start ">
                    Respiro - Your <br></br>Child's Lung <br></br>Care Partner
                  </b>
                </h2>
                <p class="pop just drychild mainhedchldresp">
                  Welcome to Respiro-Your Child’s Lung Care Partner, your online
                  cloud hospital network platform dedicated to providing
                  comprehensive lung care for children. Our mission is to ensure
                  100% lung health care for your child, right from the comfort
                  of your home. With Respiro, you can access online or offline
                  doctor consultations, personalized lung health assessments,
                  and a range of services tailored to your child's respiratory
                  well-being.
                </p>
                <div class="col-sm-6 left">
                  <button class=" fprice custom-btn1 btn-3 childbutresp">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </div>


                <a href="/">
                  <div class="col-sm-6 right text-dark details childdetailsresp">
                    For more details <FaLongArrowAltRight class="text-dark" />
                  </div>
                </a>
              </div>
              <div class="col-sm-5">
                <img
                  src="../style/img/resp/childbnr2.png"
                  class="childbnr2"
                ></img>
              </div>
            </div>
            <h3 class="orange pop childlung smtxtchildresp">Your Child's Lung Health Starts Here!</h3>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bg2">
          <div class="container">
            <br></br>
            <div class="bg3">
              <br></br>
              <br></br>

              <hr class="childline line childlineresp"></hr>

              <h3 class="pop choosehd choosechildhd">
                Why Choose Respiro<br></br>
                for Your Child's Lung Care?
              </h3>
              <img src="../style/img/resp/tomjerry.png" class="tom tomimg tomimgresp"></img>
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <center>
                      <h5 class="iconh pc choosechildresp">Personalized Care</h5>
                    </center>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/chicon1.png"
                        class="childicon childicon1 "
                      ></img>
                    </div>

                    <div class="col-sm-9">
                      <p class="childp mid just pcc  ">
                        Our team of experienced pulmonologists provides
                        personalized lung health assessments tailored to your
                        child's specific needs.
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <center>
                      <h5 class="iconh cc choosechildresp">Convenient Check-ups</h5>
                    </center>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/chicon2.png"
                        class=" childicon childicon2 "
                      ></img>
                    </div>

                    <div class="col-sm-9">
                      <p class="childp mid just ccc  ">
                        Enjoy a package including 5 free lung health check-ups
                        for your child upon enrollment, with subsequent
                        check-ups at a 50% discount, all from the comfort of
                        your home.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col-sm-6">
                    <center>
                      <h5 class="iconh dc choosechildresp">365 Days of Consultation</h5>
                    </center>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/chicon3.png"
                        class="childicon childicon3 "
                      ></img>
                    </div>

                    <div class="col-sm-9">
                      <p class="childp mid just dcc  ">
                        Access our team of pulmonologists at any time for any
                        lung-related concerns or emergencies regarding your
                        child's health.
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-3"></div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <center>
                      <h5 class="iconh ao choosechildresp">Attractive Offers</h5>
                    </center>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/chicon4.png"
                        class="childicon childicon4 "
                      ></img>
                    </div>

                    <div class="col-sm-9">
                      <p class="childp mid just aoc  ">
                        Receive special parental discounts, free initial
                        consultations, and referral benefits when you enroll
                        your child with Respiro.
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <center>
                      <h5 class="iconh as choosechildresp">Additional Services</h5>
                    </center>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/chicon5.png"
                        class="childicon childicon5 "
                      ></img>
                    </div>

                    <div class="col-sm-9">
                      <p class="childp mid just asc  ">
                        Avail discounts on respiratory therapy, sleep studies,
                        and pulmonary function tests exclusively for your
                        child's lung health care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <img src="../style/img/resp/mickey.png" class="mickey mouse mickeyresp"></img>
              <br></br>
            </div>
          </div>
          <br></br>
        </div>
      </div>

      <div class="bggradient">
        <br></br>
        <center>
          {" "}
          <h5>
            <b class="text-white childfff">How to Get Started ?</b>
          </h5>
        </center>
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col-sm-3 childclsresp">
              <div class="blank blank0">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Sign Up</b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/gradch1.png"
                      class="fdicon signicon "
                    ></img>
                  </center>
                  <p class="gradf just how">
                    Create an account on Respiro's website.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3 childclsresp">
              <div class="blank blank11">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Enroll Your Child</b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/gradch2.png"
                      class=" fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just how">
                    Provide your child's details and enroll them in the
                    Children's Lung Guard program.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3 childclsresp">
              <div class="blank blank3">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Schedule Check-ups</b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/gradch3.png"
                      class="fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just how">
                    Book your child's lung health check-ups conveniently online.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3 childclsresp">
              <div class="blank blank4">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Access Consultations</b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/gradch4.png"
                      class="fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just how">
                    Connect with our pulmonologists for consultations and
                    guidance.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
          </div>
          <br></br>
          <center>
            <hr class="gradline bottomline childlineresp"></hr>
          </center>
          <center>
            <Link to="/Packagesummary/Childpackage"> <button class=" fprice  custom-btn1 btn-3 finbtchldresp">
              <span class="btntexty bookingbtn">Book Now</span>
            </button></Link>
          </center>
        </div>
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default ChildLungcareplan;

