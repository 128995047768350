import React, { useEffect, useState } from "react";
import { getFunction } from "../../api/apiFunctions";
import Header from "../header/Header";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Loader from "../common/Loader";

export default function RhfDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { serviceId } = location.state || {};
  const { pincode } = location.state || {};
  const [data, setData] = useState({});
  const [servicedata, setServiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true); // Start loading

    getFunction({ id, serviceId, pincode }, "/rhf_unit/get-rhf-full-profile")
      .then((response) => {
        const responseData = response.success.result.unitDetails[0];
        const responseServiceData = response.success.result.serviceDetails[0];
        setData(responseData);
        setServiceData(responseServiceData); 
      })
      
      .catch((error) => {
        console.error(error);
        setError("Error fetching data.");
       
      })
      .finally(() => {

        setTimeout(() => {
          setLoading(false); 
        }, 1000); 
      });
  }, [id, serviceId, pincode]);

  const generateDates = (numDays) => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < numDays; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(date.toISOString().split("T")[0]);
    }
    return dates;
  };

  const dates = generateDates(21);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;
  const handleSubmit = (date) => {
    navigate("/RhfSlotBooking", { state: { selectedDate: date,unitId:data.rhf_unit_id,serviceId:servicedata.id, unitNumber: data.unit_number, contactName:data.contact_name,fees:servicedata.cost,service:servicedata.service_name } });
  }

  return (
    <>
      <Header />
      {loading ? (
          <Loader/>

            ):(
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            
            <div className="col-sm-2"></div>
            <div className="col-sm-6">
            <h2>Rhf Unit Details</h2>
              <h4 style={{ color: "#199fd9" }}>{data.contact_name}</h4>
              <p className="docdetails">
                <span style={{ fontWeight: "bold" }}>{data.unit_number}</span>
              </p>
              <p className="docdetails">{data.phone}</p>
              <p className="docdetails">{data.email}</p>
              <p className="docdetails">
                {data.locality}, {data.subdistrictname}, {data.districtname}
              </p>
            </div>
            <div className="col-sm-4">
              <h4>Pick a Date</h4>
              <div className="card slotcard">
                <div className="subslotcard">
                  <div className="row">
                    <div className="col-sm-2">
                      <img
                        src="../style/img/resp/Group 244 (1).png"
                        alt="Profile"
                        className="clinic_icon"
                      />
                    </div>
                    <div className="col-sm-6">
                      <h6>Appointment</h6>
                    </div>
                    <div className="col-sm-4">
                      <h6>cost:&nbsp;₹{servicedata.cost}</h6>
                      
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="container">
                        <div className="row">
                          {dates.map((date) => (
                            <div className="col-4 mb-6" key={date}>
                              <button className="slotbtn" style={{ width: "90px", marginTop: "13px" }} onClick={() => handleSubmit(date)}>
                                {date}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
       )}
    </>
  );
}
