import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import Homefull from "./Homefull";
import Homeresponsive from "./Homeresponsive";
import { Link, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { getAuthTokens } from "../../helpers/authFunctions";
import Homecard from "./Homecard";
import Header from "../header/Header";

import Indexhome from "./Indexhome";
const Home = () => {
  const [menuActive, setMenuActive] = useState(false);
  const {
    setPatientEmail,
    setPatientId,
    setIdToken,
    setAccessToken,
    setRefreshToken,
  } = useContext(AuthContext);
  const modalRef = useRef(null);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const videoRef = useRef(null);
  const [firstInteraction, setFirstInteraction] = useState(true);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/get-token", "patient")
        .then((data) => {
          setPatientId(data.sub);
          setPatientEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("patientEmail", data.email);
          localStorage.setItem("patientId", data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);

  const playVideo = () => {
    if (videoRef.current) {
      if (firstInteraction) {
        setFirstInteraction(false);
      } else {
        if (videoRef.current.paused) {
          videoRef.current.play().catch((error) => {
            console.error("Autoplay was prevented:", error);
          });
        }
      }
    }
  };

  const handleClick = () => {
    playVideo();
    const autoButton = document.getElementById("auto-button");
  };

  useEffect(() => {
    document.addEventListener("auto", handleClick);
    return () => {
      document.removeEventListener("auto", handleClick);
    };
  }, [handleClick]);

  const handleButtonClick = () => {
    // Handle modal functionality using React state
  };

  return (
    <>
      <Header></Header>
      {/* <Homecard></Homecard> */}
      <Indexhome></Indexhome>
    </>
  );
};

export default Home;
