const DATA2 = [
  {
    id: 1,
    name: "Dr. MOHAMMED KUTTY L R",
    qu: "MBBS, MD Pulmonary Medicine",
    hspl: "Ananthapuri Hospital, Trivandrum",
    img: "../style/img/doctor/mr.png",
    sp: "Consultant Pulmonologist",
    other:
      "Expert in Pulmonary Critical Care, Interventional Pulmonology, Sleep Medicine.",
    cfee: 785,
    rating: 45,
    ps: 456,
    hrating: 78,
  },
  {
    id: 2,
    name: "Dr. ARSHAD KALLIATH",
    qu: "MBBS, DTCD - Pulmonologist",
    hspl: "State Training Center, Trivandrum",
    img: "../style/img/doctor/mr.png",
    sp: "Consultant in Respiratory Medicine",
    other: " Special interest in Allergy, Asthma, Chest, and Tuberculosis.",
    cfee: 785,
    rating: 45,
    ps: 456,
    hrating: 78,
  },
  {
    id: 3,
    name: "SABIR M.C",
    qu: "MBBS, MD - Chest Specialist",
    hspl: "Mietra Hospital, Kozhikode",
    img: "../style/img/doctor/mr.png",
    sp: "Senior Consultant Pulmonologist",
    other: "Expertise in Allergy, Asthma, and Chest related issues.",
    cfee: 785,
    rating: 45,
    ps: 456,
    hrating: 78,
  },
];

export default DATA2;
