import React, { useEffect, useState, useContext } from "react";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";

function ReportRevenuebyweek() {
  const { providerId } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    console.log("Fetching data for providerId:", providerId);

    getFunction({ providerId }, "/provider/get-revenue-last-week")
      .then((response) => {
             

        if ( response.success.result) {
          const responseData = response.success.result[0];
          setData(responseData);
          console.log("Fetched data successfully:", responseData);
        } else {
          console.error("Unexpected response structure:", response);
          setError("Failed to fetch data");
        }
      })
      .catch((error) => {
        console.error("API error:", error);
        setError("Failed to fetch data: API call failed");
      });
  }, [providerId]);

  return (
    <div className="contain">
        <div className="providerline">
          <br />
          <h1 className="providerprofileheading100 profileheading1">Revenue by Week</h1>
          <hr className="providerline" />
          
          <div className="row">
            <div className="col-sm-5">
              <p>Appointments Count:</p>
            </div>
            <div className="col-sm-7">
              <p>{data.appointment_count}</p>
            </div>
            <div className="col-sm-5">
              <p>Payable Amount:</p>
            </div>
            <div className="col-sm-7">
              <p>{data.payable_amount}</p>
            </div>
          </div>
        
      </div>
    </div>
   
  );
}

export default withProviderAuth(ReportRevenuebyweek);
