const DATA = [
  {
    id: 0,
    title: "walker",
    price: 1750,
    unique: "Trending",
    desc: "An electrocardiogram (ECG or EKG) records the electrical signal from the heart to check for different heart conditions",
    img: "../style/img/Trending/walker.png",
  },
  {
    id: 1,
    title: "nebulizer",
    price: 2800,
    unique: "Trending",
    desc: "A chest radiograph, called a chest X-ray, or chest film, is a projection radiograph of the chest used to diagnose conditions affecting the chest, its contents, and nearby structures",
    img: "../style/img/Trending/nebulizer.png",
   
  },
  {
    id: 2,
    title: "air bed",
    price: 1850,
    unique: "Trending",
    desc: "A computed tomography scan is a medical imaging technique used to obtain detailed internal images of the body",
    img: "../style/img/product/neb.png",
  
  },
  {
    id: 3,
    title: "hot bag",
    price: 480,
    unique: "Trending",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Trending/hot bag.png",

  },

  {
    id: 4,
    title: "thermometer",
    price: 160,
    unique: "Trending",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Trending/thermometer.png",

  },
  {
    id: 5,
    title: "bed pan",
    price: 270,
    unique: "Trending",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Trending/bed pan.png",

  },
  {
    id: 6,
    title: "Commode pot",
    price: 1700,
    unique: "Trending",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Trending/Commode pot.png",
  },
  {
    id: 7,
    title: "BP monitor",
    price: 1150,
    unique: "Trending",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Trending/BP monitor.png",
  },
  {
    id: 8,
    title: "Nebulizer Mask",
    price: 210,
    unique: "Trending",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Trending/Nebulizer Mask.png",

  },
  {
    id: 9,
    title: "medical bed",
    price: 54999,
    unique: "ICU",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/ICU/medical bed.png",
  },
  {
    id: 10,
    title: "oxygen Concentrator",
    price: 21999,
    unique: "ICU",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/ICU/oxygen Concentrator.png",
  },
  {
    id: 11,
    title: "CPAP Machine",
    price: 26999,
    unique: "ICU",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/ICU/CPAP Machine.png",
    
  },
  {
    id: 12,
    title: "Set Cpap Machine",
    price: 36999,
    unique: "ICU",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/ICU/Set Cpap Machine.png",
    
  },
  {
    id: 13,
    title: "Pediatric CPAP Machine",
    price: 17999,
    unique: "Pediatric",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Pediatric/Pediatric CPAP Machine.png",
    
  },
  {
    id: 14,
    title: "Pediatric Nebulizers",
    price: 13999,
    unique: "Pediatric",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Pediatric/Pediatric Nebulizers.png",
     },
  {
    id: 15,
    title: "Pediatric Oxygen Cannulas",
    price: 110,
    unique: "Pediatric",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Pediatric/Pediatric Oxygen Cannulas.png",
  },
  {
    id: 16,
    title: "Pediatric Ventilators",
    price: 489000,
    unique: "Pediatric",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Pediatric/Pediatric Ventilators.png",
    },
  {
    id: 17,
    title: "Portable Oxygen Concentrators",
    price: 18999,
    unique: "Home",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Home/Portable Oxygen Concentrators.png",
    },
  {
    id: 18,
    title: "Home Nebulizers",
    price: 1899,
    unique: "Home",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Home/Home Nebulizers.png",
    },
  {
    id: 19,
    title: "Pulse Oximeters",
    price: 1210,
    unique: "Home",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Home/Pulse Oximeters.png",
    },
  {
    id: 20,
    title: "Peak Flow Meters",
    price: 1120,
    unique: "Home",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Home/Peak Flow Meters.png",
    },
  {
    id: 21,
    title: "Emergency Oxygen Kit",
    price: 7500,
    unique: "Disaster",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Disaster/Emergency Oxygen Kit.png",
    },
  {
    id: 22,
    title: "Portable Resuscitators",
    price: 3250,
    unique: "Disaster",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Disaster/Portable Resuscitators.png",
    },
  {
    id: 23,
    title: "Portable Ventilators",
    price: 250000,
    unique: "Disaster",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Disaster/Portable Ventilators.png",
 },
  {
    id: 24,
    title: "Emergency Nebulizer",
    price: 1560,
    unique: "Disaster",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Disaster/Emergency Nebulizer.png",
},
  {
    id: 25,
    title: "Spirometers",
    price: 350,
    unique: "Monitoring and Diagnostics",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Monitoring and Diagnostics/Spirometers.png",
  },
  {
    id: 26,
    title: "Capnographs",
    price: 32000,
    unique: "Monitoring and Diagnostics",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Monitoring and Diagnostics/Capnographs.png",
  },
  {
    id: 27,
    title: "Respiratory Rate Monitors",
    price: 1399,
    unique: "Monitoring and Diagnostics",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Monitoring and Diagnostics/Respiratory Rate Monitors.png",
  },
  {
    id: 28,
    title: "Sleep Apnea Monitors",
    price: 25999,
    unique: "Monitoring and Diagnostics",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Monitoring and Diagnostics/Sleep Apnea Monitors.png",
  },
  {
    id: 29,
    title: "Grade Oxygen Concentrators",
    price: 19000,
    unique: "Clinical",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Clinical/Grade Oxygen Concentrators.png",
  },
  {
    id: 30,
    title: "Ventilator",
    price: 475000,
    unique: "Clinical",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Clinical/Ventilator.png",
    img1: "../style/img/Clinical/Ventilator.png",
    img2: "../style/img/Clinical/Ventilator.png",
    img3: "../style/img/Clinical/Ventilator.png",
  },
  {
    id: 31,
    title: "10L Oxygen Cylinder",
    price: 17000,
    unique: "Clinical",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Clinical/10L Oxygen Cylinder.png",
    img1: "../style/img/Clinical/10L Oxygen Cylinder.png",
    img2: "../style/img/Clinical/10L Oxygen Cylinder.png",
    img3: "../style/img/Clinical/10L Oxygen Cylinder.png",
  },
  {
    id: 32,
    title: "Nebulizer System",
    price: 999,
    unique: "Clinical",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Clinical/Nebulizer System.png",
    img1: "../style/img/Clinical/Nebulizer System.png",
    img2: "../style/img/Clinical/Nebulizer System.png",
    img3: "../style/img/Clinical/Nebulizer System.png",
  },
  {
    id: 33,
    title: "Altitude Training Mask",
    price: 1700,
    unique: "Sports",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Sports/Altitude Training Mask.png",
    img1: "../style/img/Sports/Altitude Training Mask.png",
    img2: "../style/img/Sports/Altitude Training Mask.png",
    img3: "../style/img/Sports/Altitude Training Mask.png",
  },
  {
    id: 34,
    title: "Athletics Training Mask",
    price: 1700,
    unique: "Sports",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Sports/Athletics Training Mask.png",
    img1: "../style/img/Sports/Athletics Training Mask.png",
    img2: "../style/img/Sports/Athletics Training Mask.png",
    img3: "../style/img/Sports/Athletics Training Mask.png",
  },
  {
    id: 35,
    title: "Fitbit Charge",
    price: 1700,
    unique: "Sports",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Sports/Fitbit Charge.png",
    img1: "../style/img/Sports/Fitbit Charge.png",
    img2: "../style/img/Sports/Fitbit Charge.png",
    img3: "../style/img/Sports/Fitbit Charge.png",
  },
  {
    id: 36,
    title: "PowerBreathe",
    price: 1700,
    unique: "Sports",
    desc: "An arterial blood gas test, or arterial blood gas analysis measures the amounts of arterial gases, such as oxygen and carbon dioxide",
    img: "../style/img/Sports/PowerBreathe.png",
    img1: "../style/img/Sports/PowerBreathe.png",
    img2: "../style/img/Sports/PowerBreathe.png",
    img3: "../style/img/Sports/PowerBreathe.png",
  },


];

export default DATA;
