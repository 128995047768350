import React from "react";
import { Link } from "react-router-dom";
import Snav1 from "./Snav1";
import Snavresp from "./Snavresp";
function Snav() {
  return (
    <>
      <div className="app">
        {window.innerWidth > 768 ? <Snav1 /> : <Snavresp />}
      </div>
    </>
  );
}

export default Snav;
