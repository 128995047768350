import React from "react";
import Header from "../header/Header";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
export default function GeneTherapy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <div class="bg3">
          <div class="" style={{ textAlign: "center", position: "relative" }}>
            <center>
              <img
                src="../style/img/resp/pneumoban.png"
                class="pneumoban"
              ></img>
            </center>
            <center>
              <h3>
                <b class="just text-white pneu1 ychn">
                  Consult Top Doctors Online forGene Therapy{" "}
                </b>
              </h3>
            </center>
            <center>
              <p>
                <b class="just text-white pneu2 ychn">
                  <br></br>
                  Your health is our priority. Thank you for choosing Respiro
                  for your online consultation.
                </b>
              </p>
            </center>
            {/* <button
              class="text-white mngnowbtn"
              style={{
                backgroundColor: "#1A3359",
                color: "#000",
                borderRadius: "30px",
                border: "2px solid #ffffff",
              }}
            >
              Manage Your Booking
            </button>
            <br></br>
            <br></br> */}
          </div>
          {/* <center>
            <h2 style={{ color: "#466E6C", fontWeight: "bold" }}>
              Thank You for Choosing Respiro!
            </h2>
          </center>
          <center>
            <p
              style={{
                color: "#466E6C",
                fontSize: "20px",
                fontFamily: "Poppins",
              }}
            >
              We have received your booking details. Here is a summary of your
              consultation
            </p>
          </center>
          <br></br>
          <br></br>

          <center>
            <h3
              style={{ fontWeight: "bold", color: "#466E6C", fontSize: "20px" }}
            >
              Booking Details
            </h3>
          </center>
          <hr style={{ borderColor: "#000", width: "70%" }}></hr>
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <p>Patient's Name</p>
                </center>
              </div>
              <div class="col-sm-8">

                <input class="textfields" ></input>
              </div>
            </div>
            <br></br>

            <div class="row">
              <div class="col-sm-4">
                <center>
                  <p>Phone no</p>
                </center>
              </div>
              <div class="col-sm-8">
                <input class="textfields" ></input>

              </div>
            </div>
            <br></br>
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <p>Email</p>
                </center>
              </div>
              <div class="col-sm-8">
                <input class="textfields" ></input>

              </div>
            </div>
            <br></br>
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <p>Symptoms or Concerns</p>
                </center>
              </div>
              <div class="col-sm-8">
                <input class="textfields" ></input>

              </div>
            </div>
            <br></br>
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <p>Preferred Consultation Date and Time</p>
                </center>
              </div>
              <div class="col-sm-4">
                <input class="textfields" ></input>

              </div>
              <div class="col-sm-4">
                <input class="textfields" ></input>

              </div>
            </div>
            <br></br>
            <br></br>
          </div>  */}
          <br></br>
          <div class="container" style={{ fontSize: "20px" }}>
            <p>
              Gene therapy is a medical treatment that involves changing or
              fixing genes in the body to treat diseases. It can help replace
              missing or faulty genes or add new ones to help fight illnesses,
              like genetic disorders or some types of cancer.
            </p>
          </div>

          <br></br>
          <div>
            <center>
              <h3
                style={{
                  fontWeight: "800",
                  fontFamily: "Poppins",
                  fontSize: "30px",
                }}
              >
                Plan Purchase
              </h3>
              <h6
                style={{
                  fontSize: "20px",
                }}
              >
                Choose Your Plan Duration
              </h6>
            </center>

            <div class="container">
              <center>
                <div
                  class="greenbg priceboxgreen"
                  style={{
                    borderRadius: "20px",
                    width: "750px",
                    height: "300px",
                  }}
                >
                  <br></br>
                  <form
                    class="booking-form"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div class="form-group11">
                      <label class="boxresd">Duration Selector:</label>
                      <div
                        class="diaginput"
                        style={{ position: "relative", marginLeft: "10px" }}
                      >
                        <span
                          class="subtraction text-dark"
                          onClick={() => handleDurationChange(-1)}
                          style={{
                            position: "absolute",
                            left: 20,
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </span>
                        <input
                          class="booktestdur"
                          name="duration"
                          value={`${duration} month${duration > 1 ? "s" : ""}`}
                          readOnly
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "0 40px",
                          }}
                        />
                        <span
                          class="addition text-dark  booktestadd"
                          onClick={() => handleDurationChange(1)}
                          style={{
                            position: "absolute",
                            right: 20,
                            bottom: "0px",
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div class="form-group11">
                      <label class="boxresd">Price:</label>
                      <div class="diaginput" style={{ position: "relative" }}>
                        <input
                          class="booktestdur"
                          style={{ width: "300px" }}
                          type="number"
                          name="totalPrice"
                          value={totalPrice}
                          readOnly
                        />
                      </div>
                    </div>
                  </form>
                  <br></br>
                  <center>
                    <button
                      class="purchasebutton"
                      style={{
                        fontWeight: "600",
                        backgroundColor: "#fff",
                        color: "#466E6C",
                        borderRadius: "30px",
                      }}
                    >
                      Purchase
                    </button>
                  </center>
                </div>
              </center>
              <br></br>
            </div>
          </div>
          <div class="greenbg">
            <br></br>
            <center>
              <h5
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  fontFamily: "Poppins",
                }}
              >
                What to Expect Next
              </h5>
            </center>
            <br></br>
            <div class="container">
              <center>
                <div class="row">
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon1.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Confirmation</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            You will receive a confirmation email shortly.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon2.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Preparation</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            Make sure to have a stable internet connection and a
                            quiet place for the consultation..
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon3.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Consultation</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            Our doctor will call you at the scheduled time.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="blank">
                      <div class="container">
                        <br></br>
                        <img
                          src="../style/img/resp/pneuicon4.png"
                          style={{ height: "60px" }}
                        ></img>
                        <br></br>
                        <center>
                          <h5 class="iconh"> Illustrations</h5>
                        </center>
                        <br></br>
                        <center>
                          <p class="iconp">
                            Include simple, clean illustrations for each step.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </center>
              <br></br>
            </div>
            <br></br>
            <br></br>
            {/* <center>
              <h3>
                <b
                  class="text-white"
                  style={{ fontFamily: "Poppins", fontSize: "20px" }}
                >
                  Call to Action
                </b>
              </h3>
            </center>
            <hr style={{ borderColor: "#fff", width: "50%" }}></hr>
            <br></br> */}
            {/* <center>
              <button
                class=""
                style={{
                  fontWeight: "600",
                  backgroundColor: "#fff",
                  color: "#466E6C",
                  borderRadius: "30px",
                }}
              >
                Modify Booking
              </button>
            </center> */}
            {/* <br></br>
            <br></br>
            <center>
             <Link to ="/"> <button
                class=""
                style={{
                  fontWeight: "600",
                  backgroundColor: "#fff",
                  color: "#466E6C",
                  borderRadius: "30px",
                }}
              >
                Back to Home
              </button>
              </Link>
            </center>
            <br></br>
            <br></br> */}
          </div>

          <br></br>
        </div>
      </div>
    </>
  );
}

 
