import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function Regcare() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="regbg4">
        <div className="container">
          <div class="hero__title-wrapper">
            <div class="hero__title-large">Hello!</div>
            <div class="hero__title-small">
              Respiro Care: India's Premier Cloud Healthcare Platform for
              Respiratory Care
            </div>
            <div class="hero__title-small">
              Welcome to Respiro - India's Premier Cloud Healthcare Platform for
              Respiratory Care
            </div>
          </div>

          <div class="container">
            <div class="hero__description">
              Respiro stands as a symbol of innovation and accessibility in the
              realm of respiratory healthcare. As pioneers in the field, we have
              forged a path to provide comprehensive and affordable lung care
              for all. With over 25,000 esteemed physicians, including 2,000
              pulmonologists, 3,000 pediatricians, and a network of 20,000 labs,
              alongside 15,000 healthcare professionals, Respiro is committed to
              serving individuals from Kanyakumari to Kashmir
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>

      <section id="features" class="features1">
        <div class="features__wrapper">
          <div class="section-header">
            <div class="section-header__title  section-header__title--features">
              Franchise Benefits:
            </div>
            <div class="section-header__subtitle">
              ❖ Comprehensive training programs ensuring proficiency in
              respiratory care<br></br>❖ Dedicated social media marketing
              support to reach potential clients<br></br>❖ Extensive offline and
              TV media advertising exposure for brand visibility<br></br>❖
              Assistance with branding office setup to maintain a professional
              image<br></br>❖ Provision of professional uniforms for staff for a
              cohesive brand identity<br></br>❖ Projected earnings ranging from
              4 lakhs to 20 lakhs per month based on performance<br></br>❖
              Opportunity to participate in a revenue-sharing model for
              sustained growth and<br></br>
              profitability<br></br>❖ Find fulfillment in contributing to
              society's well-being by addressing respiratory health disparities
              <br></br>❖ Impact lives positively by providing essential
              respiratory care services to underserved communities.<br></br>❖
              Support research initiatives aimed at advancing respiratory
              healthcare and improving treatment outcomes.<br></br>
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--features">
              Technical Support:
            </div>
            <div class="section-header__subtitle">
              ★ Online booking support<br></br>★ Staff tracking system<br></br>★
              Video consultation<br></br>★ Patient records storage online
              <br></br>★ Systematic alerts<br></br>★ Appointment scheduling
              <br></br>★ Remote patient monitoring<br></br>★ Billing and
              financial management<br></br>★ Telemedicine integration<br></br>★
              Emergency assistance button<br></br>★ Patient portal<br></br>★
              Appointment reminders<br></br>★ Feedback and rating system
              <br></br>★ Voice-enabled assistance<br></br>★ Comprehensive
              training programs ensuring proficiency in respiratory care
              <br></br>★ Dedicated social media marketing support to reach
              potential clients<br></br>★ Extensive offline and TV media
              advertising exposure for brand visibility<br></br>★ Assistance
              with branding office setup to maintain a professional image
              <br></br>★ Provision of professional uniforms for staff for a
              cohesive brand identity
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--features">
              Financial Opportunities:
            </div>
            <div class="section-header__subtitle">
              ★ Projected earnings ranging from 4 lakhs to 20 lakhs per month
              based on performance<br></br>★ Opportunity to participate in a
              revenue-sharing model for sustained growth and profitability
              <br></br>
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--features">
              Social Impact
            </div>
            <div class="section-header__subtitle">
              ★ Find fulfillment in contributing to society's well-being by
              addressing respiratory health disparities<br></br>★ Impact lives
              positively by providing essential respiratory care services to
              underserved communities.<br></br>★ Support research initiatives
              aimed at advancing respiratory healthcare and improving treatment
              outcomes.<br></br>
            </div>
          </div>
        </div>
      </section>

      <section id="realizations" class="realizations">
        <div class="realizations__wrapper">
          <div class="section-header">
            <div class="section-header__title  section-header__title--realizations">
              About Respiro Care:
            </div>
            <div class="section-header__subtitle">
              Respiro is more than just a healthcare platform; it's a commitment
              to excellence in respiratory care. Our cloud-based platform
              connects patients with highly skilled healthcare professionals,
              ensuring access to superior healthcare services regardless of
              geographic location or socioeconomic status. With plans to
              establish 1,240 Respiro Care Units and a staff of 4,621 dedicated
              members, our mission is to extend our reach to every corner of
              India.
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--realizations">
              Our Focus:
            </div>
            <div class="section-header__subtitle">
              Our primary focus at Respiro is to prioritize the well-being of
              our patients above all else. Our dedicated team consists of
              experienced professionals including pulmonologists, chest
              physicians, pediatricians, nurses, lab technicians, respiratory
              therapists, and physiotherapists. Each member of our team is
              committed to delivering personalized and compassionate care,
              tailored to meet the unique needs of every patient. Together, we
              work diligently with precision and empathy to ensure the highest
              standards of healthcare delivery.
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--realizations">
              Respiro Care Units
            </div>
            <div class="section-header__subtitle">
              Become a part of the Respiro family by joining our franchise
              model. Respiro Care Units are designed to provide comprehensive
              respiratory care services to communities across India. From
              respiratory nursing care at home to specialized treatments and
              telemedicine consultations, our franchisees play a vital role in
              delivering high-quality care to those in need. It’s an opportunity
              for aspirants who want to do something for society. In the Respiro
              Care Unit, we create opportunities for entrepreneurs who are
              seeking opportunities; it's not just a franchise model, but more
              than that, we make you socially responsible and elevate your
              social status through Respiro.
            </div>
            <br></br>
            <div class="section-header__title  section-header__title--realizations">
              Where it's Happening
            </div>
            <div class="section-header__subtitle">
              Respiro's plans to extend its influence across all 28 states and 8
              union territories of India. Regardless of your location, Respiro
              aims to deliver unparalleled respiratory care services.<br></br>
              <br></br>
              Embark on this transformative journey with Respiro and be part of
              revolutionizing respiratory healthcare in India. Contact us today
              to explore franchise opportunities and join hands in shaping a
              healthier future!
            </div>
          </div>
        </div>
      </section>

      <div class="content">
        <div class="reg1bg1">
          <div class="experience__wrapper">
            <div class="">
              <center>
                {" "}
                <div class="btn btn-infy" onClick={handleOpen}>
                  Registration
                </div>
              </center>
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  animation={false}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      ...style,
                      maxHeight: "80vh", // Set the maximum height of the modal content
                      overflowY: "auto", // Enable vertical scrolling if content exceeds the height
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Registration<hr></hr>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div class="">
                        <label class="contact__label text-dark">
                          Clinic Name
                        </label>

                        <input
                          id="sender_email"
                          class="form-control"
                          type="Text"
                          placeholder="Clinic Name"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">Location</label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder="City/Town"
                          required
                        />
                      </div>

                      <div class="">
                        <label class="contact__label text-dark">
                          {" "}
                          Email Address
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="email"
                          placeholder=" Email Address"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Contact Number
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="number"
                          placeholder="+91"
                          required
                        />
                      </div>
                      <div class="">
                        <label class="contact__label text-dark">
                          Why do you want to join the Respiro Care Unit
                          Franchise?
                        </label>
                        <input
                          id="sender_email"
                          class="form-control"
                          type="text"
                          placeholder=""
                          required
                        />
                      </div>
                      <br></br>

                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            Upload Your detailed Profile (PDF format)
                          </span>
                        </div>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="inputGroupFile01"
                          />
                          <label
                            class="custom-file-label"
                            for="inputGroupFile01"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                      <br></br>
                      <label>
                        Services Offered by Your Respiro Care Unit in Future
                      </label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Advanced Pulmonary Function Testing
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Cutting-edge Molecular Diagnostics
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          State-of-the-art Imaging Analysis
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Innovative Research Collaboration
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Seamless Teleconsultation
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Respiratory Therapy
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Lung Cancer Screening
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Asthma Managemen
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Chronic Obstructive Pulmonary Disease (COPD)
                          Management
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Sleep Disorder Evaluation, including Polysomnography
                          (Sleep Study) and Home Sleep Apnea Testing
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Allergy Testing and Treatment
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Smoking Cessation Programs
                        </label>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Pulmonary Rehabilitation
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Home Ventilation Support
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          Customized Services (please specify)
                        </label>
                      </div>

                      <center>
                        {" "}
                        <div class="btn btn-infy1">Registration</div>
                      </center>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <section id="features" class="features1">
          <div class="features__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--features">
                Investment Details
              </div>
              <div class="section-header__subtitle">
                Choose from investment options ranging from 40,000 INR to 7
                lakhs INR, tailored to your business aspirations and financial
                capabilities.
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact">
          <div class="contact__wrapper">
            <div class="section-header">
              <div class="section-header__title  section-header__title--contact">
                Terms and Conditions:
              </div>
              <div class="section-header__subtitle">
                By submitting this form, you agree to abide by the policies and
                guidelines of Respiro Care Units. Respiro reserves the right to
                verify the information provided in this registration form.
                <br></br>
                Contact Information:<br></br>
                For inquiries or assistance with the registration process,
                please contact us at:<br></br>
                Email: business.respiro@gmail.com<br></br>
                Phone: +919645035537
              </div>
            </div>
            <div class="container">
              <div class="contact__icons-wrapper">
                <div class="contact__icon">
                  <a target="_blank" href="mailto:business.respiro@gmail.com">
                    <img
                      src="./style/img/banner/lablogo.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">business.respiro@gmail.com</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/logonav.png"
                      class="logoreg"
                    ></img>
                  </a>
                  {/* <div class="contact__icon-description">@instagram</div> */}
                </div>
                <div class="contact__icon">
                  <a target="_blank" href="">
                    <img
                      src="./style/img/banner/petro.png"
                      class="logoreg1"
                    ></img>
                  </a>
                </div>
              </div>
            </div>

            {/* <div class="contact__spacer">&nbsp;</div> */}

            {/* <form class="contact__form">

      <div class="contact__textarea-wrapper">
        <label for="message_content" class="contact__label">Message</label>
        <div class="contact__response-ajax-text">&nbsp;</div>
        <textarea id="message_content" rows="10" placeholder="Hello! Would you be interested in collaboration for the greatest project ever?" required></textarea>
      </div>

      <div class="contact__bottom-wrapper">
        <div class="contact__sender-info-wrapper">
          <div class="contact__input-wrapper  contact__input-wrapper--name">
            <label for="sender_name" class="contact__label">Your Name</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_name" type="text" placeholder="John Kowalski" required/>
          </div>
          <div class="contact__input-wrapper  contact__input-wrapper--email">
            <label for="sender_email" class="contact__label">Your E-mail</label>
            <div class="contact__response-ajax-text">&nbsp;</div>
            <input id="sender_email" type="email" placeholder="john@example.com" required/>
          </div>
        </div>

        <div class="contact__button-wrapper  ghost-button  ghost-button--contact">
          <div class="contact__paper-plane-wrapper"><i class="fa fa-paper-plane"></i></div>
          <div class="contact__response  contact__response--success">
            <div class="contact__response-title"><i class="fa fa-check"></i> Message was sent.</div>
            <div class="contact__response-description  contact__response-description--success">&nbsp;</div>
          </div>
          <div class="contact__response  contact__response--error">
            <div class="contact__response-title"><i class="fa fa-warning"></i> Error.</div>
            <div class="contact__response-description  contact__response-description--error">Message couldn't be sent.</div>
          </div>
          <input id="contact__submit" type="button" value=" "/>
        </div>
      </div>
    </form> */}
          </div>
        </section>
      </div>
    </>
  );
}

export default Regcare;
