import React from "react";
import { Link } from "react-router-dom";
import CartBtn from "../buttons/CartBtn";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function Navbar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            {" "}
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter your Location"
              />
              <br></br>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d62827.67681809604!2d76.33981221569047!3d10.202583843577054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sall%20map!5e0!3m2!1sen!2sin!4v1693474624960!5m2!1sen!2sin"
                width="468"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>
      </> */}
      <div class="container">
        <div class="top_menu  row m0">
          <div class="container">
            <div class="float-right">
              <ul class="list header_social">
                {/* <li><a href="https://www.facebook.com/profile.php?id=100086842796600&is_tour_dismissed=true"><i class="ti-facebook"></i></a></li>
                      <li><a href="https://twitter.com/LabsRespiro"><i class="ti-twitter-alt"></i></a></li>
                      <li><a href="https://www.linkedin.com/in/respiro-labs-852061246/"><i class="ti-linkedin"></i></a></li> */}
                <li>
                  <a href="/partnership">
                    <a class="topnavtext">Respiro Partnership</a>
                  </a>
                </li>
                <li class="topnavtext submenu dropdown">
                  <Link
                    to="/login"
                    class=" topnavtext   dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Login
                  </Link>
                  <ul class="dropdown-menu">
                    <div class="container">
                      <div class="row">
                        <div class="col-sm-7">
                          <li class="">
                            <h6> New Customer?</h6>
                          </li>
                        </div>
                        <div class="col-sm-5">
                          <a href="/signup">Sign Up </a>
                        </div>
                      </div>
                    </div>

                    <hr></hr>

                    <li class="">
                      <a class="nav-link" href="/patient/profile">
                        <i class="fa-solid fa-user"></i> Profile
                      </a>
                    </li>
                    <li class="">
                      <a class="nav-link" href="#">
                        <i class="fa-solid fa-user"></i> Order
                      </a>
                    </li>
                  </ul>
                </li>

                <CartBtn></CartBtn>
              </ul>
            </div>
          </div>
        </div>
        <header class="header_area">
          <div class="main_menu mh">
            <nav class="navbar navbar-expand-lg navbar-light">
              <div class="container">
                <a class="navbar-brand logo_h " href="/home">
                  <img src="../style/img/logonav.png" alt="" />
                </a>
                <button
                  class="navbar-toggler raja"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>

                <div
                  class="collapse navbar-collapse offset"
                  id="navbarSupportedContent"
                >
                  <ul class="nav navbar-nav menu_nav ml-auto">
                    <li class="">
                      <a class="nav-link ddd" href="/rhf">
                        <font color="#000">Book a RHF</font>
                      </a>

                      <div class=""></div>
                    </li>

                    <li class=" submenu dropdown">
                      <Link
                        to="/bookathome"
                        class="nav-link  ddd "
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <font color="#000">Book a Test</font>
                      </Link>
                      <ul class="dropdown-menu">
                        <li class="">
                          <a class="nav-link" href="/booking-home">
                            Book at Home{" "}
                          </a>
                        </li>
                        <li class="">
                          <a class="nav-link" href="/book-clinic">
                            Book a Clinic
                          </a>
                        </li>
                        {/* <li class="nav-item"><a class="nav-link" href="/Respirotherapist">Book a Therapist</a></li> */}
                        {/* <li class="nav-item"><a class="nav-link" href="/Pulmonologist">Pulmonologistt</a></li> */}
                      </ul>
                    </li>
                    <li class="">
                      <a class="nav-link  ddd" href="/book-therapist">
                        <font color="#000">Book a therapist</font>
                      </a>
                      <div class=""></div>
                    </li>
                    <li class=" submenu dropdown">
                      <Link
                        to="/pulmonologist"
                        class="nav-link  ddd dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <font color="#000">consult a Pulmonologist </font>
                      </Link>
                      <ul class="dropdown-menu">
                        <li class="">
                          <a class="nav-link" href="/consult-online">
                            Online
                          </a>
                        </li>
                        <li class="">
                          <a class="nav-link" href="/consult-offline">
                            Offline
                          </a>
                        </li>
                        {/* <li class="nav-item"><a class="nav-link" href="/Respirotherapist">Book a Therapist</a></li> */}
                        {/* <li class="nav-item"><a class="nav-link" href="/Pulmonologist">Pulmonologistt</a></li> */}
                      </ul>
                    </li>
                    <li class="">
                      <a class="nav-link  ddd" href="/o2">
                        <font color="#000">Medical equipment</font>
                      </a>
                      <div class=""></div>
                    </li>

                    <br />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
}

export default Navbar;
