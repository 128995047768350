import React from "react";

function Dash2() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="row">
        <div class="col-sm-3 dashcolor">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <a href="/dash1">
            <button class="btn btn-light dashbtn">User Details</button>
          </a>
          <br></br>
          <br></br>
          <a href="/dash2">
            <button class="btn btn-light dashbtn">Online Book</button>
          </a>
          <br></br>
          <br></br>
          <br></br>
          <a href="/dash3">
            <button class="btn btn-light dashbtn">Ofline Book</button>
          </a>
          <br></br>
          <br></br>
          <br></br>
          <a href="/dash4">
            <button class="btn btn-light dashbtn">Patient Records</button>
          </a>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div class="col-sm-9">
          <br></br>
          <br></br>

          <h2 class="dashtext">Online Booking</h2>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default Dash2;
