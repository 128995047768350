import React, { useEffect, useState } from "react";
import AdminHeader from '../header/AdminHeader'
import { useParams } from 'react-router-dom';
import { getFunction } from "../../api/apiFunctions";

function ViewRhfDetails() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [groupedDocuments, setGroupedDocuments] = useState({});
  const [expandedDocumentTypes, setExpandedDocumentTypes] = useState({});
  const [docdata, setDocdata] = useState([]);
  const rhfUnitId = id;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {

    getFunction({ id }, "/admin/get-rhf-manager")
      .then((response) => {
        const responseData = response.success.result[0];
        //console.log(response.data);
        setData(responseData);

      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const groupDocumentsByType = (documents) => {
    return documents.reduce((acc, doc) => {
      if (!acc[doc.documentType]) {
        acc[doc.documentType] = [];
      }
      acc[doc.documentType].push(doc);
      return acc;
    }, {});
  };

  useEffect(() => {
    getFunction({ rhfUnitId }, "/admin/get-allrhfdocuments")
      .then((response) => {
        const responseData = response.success.result;
        setDocdata(responseData);
        setGroupedDocuments(groupDocumentsByType(responseData));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rhfUnitId]);

  const toggleDocumentVisibility = (documentType) => {
    setExpandedDocumentTypes(prevState => ({
      ...prevState,
      [documentType]: !prevState[documentType]
    }));
  };
  return (
    <>
    <AdminHeader/>
    <div class="pbgm1">
        <div class="container">
          <div class="card">
            <br></br>
            <div class="container">
              <center><h2 class="paihed">Rhf Information</h2></center><br></br>
              <div class="row">
                <div class="col-sm-6">
                  {/* <p>rhf ID: {id}</p> */}
                  {/* <p class="plaintext">Name </p>
                  <h2 class="paihed2">{data.first_name}&nbsp;{data.last_name}</h2> */}                 
                  <p class="plaintext">Contact Name </p>
                  <h2 class="paihed2">{data.contact_name} </h2>
                  <br></br>
                  <p class="plaintext">Phone </p>
                  <h2 class="paihed2">{data.phone} </h2>
                  <br></br>                  
                  <p class="plaintext">Locality</p>
                  <h2 class="paihed2">{data.locality} </h2>
                </div>
                <div class="col-sm-6">
                  <p class="plaintext">Unit Number </p>
                  <h2 class="paihed2">{data.unit_number}</h2>
                  <br></br>
                  <p class="plaintext">email </p>
                  <h2 class="paihed2">{data.email} </h2>
                  <br></br>
                  <p class="plaintext">District </p>
                  <h2 class="paihed2">{data.districtname} </h2>
                  <br></br>                                    
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="card">
            <br></br>
            <div class="container">
              <center><h2 class="paihed">Documents uploaded by the rhf</h2></center><br></br>
              <div className="row">
                <div className="col-sm-12">
                  {Object.keys(groupedDocuments).length > 0 ? (
                    Object.keys(groupedDocuments).map((documentType, index) => (
                      <div key={index}>
                        <p>
                          Document Type : {documentType} &nbsp;&nbsp;&nbsp;
                          <a
                            href="#!"
                            style={{ color: "#127bed" }}
                            onClick={() => toggleDocumentVisibility(documentType)}
                          >
                            click here 
                          </a>
                        </p>
                        {expandedDocumentTypes[documentType] && (
                          groupedDocuments[documentType].map((item, docIndex) => (
                            <div key={docIndex}>
                              <a href={item.url} target="_blank" rel="noopener noreferrer" style={{color:"#2024e5",fontSize:"12px"}}>
                                <li style={{ color: "#287ae5" }}>&nbsp;{item.document_name}</li>
                              </a>
                            </div>
                          ))
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No documents uploaded.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </>
  )
}

export default ViewRhfDetails