import React, { useEffect, useState, useContext } from "react";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { useParams } from "react-router-dom";

function ReportRevenuebydate() {
  const { id } = useParams(); // Correct useParams usage
  const { providerId } = useContext(AuthContext);
  console.log(providerId);
  const [data, setData] = useState(null); // Set initial state to null to handle loading state
  const [selectedDate, setSelectedDate] = useState(""); // State to hold the selected date

  // Fetch data whenever the selected date changes
  useEffect(() => {
    if (selectedDate) {
      getFunction({ appointmentDate: selectedDate, providerId }, "/provider/get-revenue-by-date")
        .then((response) => {
          const responseData = response.success.result[0];
          setData(responseData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <>
      <div className="contain">
        <div className="providerline">
          <br />
          <h1 className="providerprofileheading100 profileheading1">Revenue by Date</h1>
          <hr className="providerline" />
          
          <div className="row">
            <div className="col-sm-5">
              <label htmlFor="datePicker">Select Date:</label>
            </div>
            <div className="col-sm-7">
              <input
                type="date"
                id="datePicker"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </div>

          {data ? ( 
            <div className="row">
              <div className="col-sm-5">
                <p>Appointments Count:</p>
              </div>
              <div className="col-sm-7">
                <p>{data.appointment_count}</p>
              </div>
              <div className="col-sm-5">
                <p>Payable Amount:</p>
              </div>
              <div className="col-sm-7">
                <p>{data.payable_amount}</p>
              </div>
            </div>
          ) : (
            <p> view revenue details.</p> // Show a message when no date is selected
          )}
        </div>
      </div>
    </>
  );
}

export default withProviderAuth(ReportRevenuebydate);
