import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import DATA2 from "../../data/Data2";

const Doctors = () => {
  const [menuActive, setMenuActive] = useState(false);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }
  const cardItem = (item) => {
    return (
      <div class="col-md-4" key={item.id}>
        <div class="product-card1">
          <div class="product-tumb">
            <img class="" src={item.img} alt="" />
          </div>

          <div class="product-details">
            <h5>
              <a href="">{item.name}</a>
            </h5>
            <br></br>
            <p>{item.qu}</p>
            <br></br>
            <p>
              {item.hspl}
              <font color="green">
                &nbsp;({item.hrating}
                <i class="fa-solid fa-star"></i>)
              </font>{" "}
            </p>
            <br></br>
            <p>{item.sp}</p>
            <br></br>
            <p>{item.other}</p>
            <hr></hr>
            <button class="strbtn">
              {item.rating}
              <i class="fa-solid fa-star"></i>
            </button>{" "}
            {item.ps} Patient Stories
            <div class="product-bottom-details">
              <div class="product-price">Consultation Fee {item.cfee}/-</div>

              <div class="product-links">
                <NavLink to={`/doctors-details/${item.id}`}>Book Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div class="content">
      <div class="container">
        <div class="content-top" id="product">
          <br></br>
          <center>
            {" "}
            <h1>PULMONOLOGIST</h1>
          </center>
          <div class="row">{DATA2.map(cardItem)}</div>
        </div>
      </div>
    </div>
  );
};

export default Doctors;
