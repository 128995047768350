import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

function Snavresp() {
  return (
    <>
      <div class="container-fluid">
        <div class="row ">
          <Menu
            left
            pageWrapId={"page-wrap"}
            outerContainerId={"root"}
            style={{ color: "white" }}
            className="customburger-menu"
          >
            <div class="">
              <div className="respnav">
                <Link to="/Finddoctor" class="linktext">
                  <h5 class="sanavtext1 ">
                    FIND DOCTORS NEAR YOU
                  </h5>
                </Link>
              </div>
              <div className="respnav">
                <Link to="/Rhf" className="linktext">
                  <h5 class="sanavtext1">RAPID HEAL FORCE(RHF)</h5>
                </Link>
              </div>
              <div className="respnav">
                <Link to="/LungTherapies" class="linktext">
                  <h5 class="sanavtext1">REHAB</h5>
                </Link>
              </div>
              <div className="respnav">
                <Link to="/BookaTest" class="linktext">
                  <h5 class="sanavtext1">
                    BOOK A TEST
                  </h5>
                </Link>
              </div>
              <div className="respnav">
                <Link to="/O2_sale" class="linktext">
                  <h5 class="sanavtext1">MEDICAL DEVICES SALE</h5>
                </Link>
              </div>
              <div className="respnav">
                <Link to="/O2_rent" class="linktext">
                  <h5 class="sanavtext1">
                    MEDICAL EQUIPMENT RENT
                  </h5>
                </Link>
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </>
  );
}
export default Snavresp;
