import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { IoMdArrowDropright } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import CartBtn from "../buttons/CartBtn";
import { FaUser } from "react-icons/fa";

function Fnav() {
  const { patientEmail } = useContext(AuthContext);
  const [menuActive, setMenuActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the width as per your design
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="logo">
            <Link to="/">
              <img src="../style/img/logonav.png" alt="" />
            </Link>
          </div>

          <div className="push-left">
            <ul className={`nav-menu`}>
              <li className="sighbtn submenu dropdown ">
                {!patientEmail ? (
                  <Link
                    className=" custom-btn custom-btn2 btn-1  dropdown-toggle "
                    to="/login"
                  >
                    {isMobile ? (
                      <FaUser
                        className="signinuser" />
                    ) : (
                      <>
                        Sign in<IoMdArrowDropright className="text-white signinarrow" />
                      </>
                    )}
                  </Link>
                ) : (
                  <>
                    {isMobile ? (
                      <FaUser className="signinuser" />
                    ) : (
                      <span className="emailnav custom-bt dropdown-toggle">
                        {patientEmail}
                      </span>
                    )}
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="nav-link" to="/patient/profile">
                          <FaArrowRight /> Profile
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="#">
                          <FaArrowRight /> Order
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/logout"
                          onClick={() => localStorage.clear()}
                        >
                          <FaArrowRight /> Logout
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </li>
              <CartBtn />
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Fnav;
