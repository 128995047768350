import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
function YoungsterLungcareplan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div class="bg2">
        <div class="bg3">
          <br></br>
          <br></br>
          <img
            src="../style/img/resp/Rectangle 325.png"
            class="yngstrbnr1"
          ></img>
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-7">
                <h2 class="pop childhead">
                  <b class="text-dark start1">
                    Respiro - <br></br>Young India <br></br>Lung Health<br></br>
                    Care Plan
                  </b>
                </h2>
                <br></br>
                <p class="pop just drychild">
                  Welcome to Respiro, Your Youngster's Lung Care Partner.
                  Ensuring 100% Lung Health for Youngsters in India.
                </p>
                <br></br>
                <div class="col-sm-6 left">
                  <button class=" fprice custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </div>
                <a href="">
                  <div class="col-sm-6 right text-dark details">
                    For more details <FaLongArrowAltRight class="text-dark"/>
                  </div>
                </a>
              </div>
              <div class="col-sm-5">
                <img
                  src="../style/img/resp/Rectangle 326.png"
                  class="yngstrbnr2"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <br></br>

        <div class="bg2">
          <div class="container">
            <center>
              <img src="../style/img/resp/yngstrbnr3.png" class="yngimg3"></img>
            </center>
            <div class="container">
              <div class="row">
                <div class="col-sm-5">
                  <img
                    src="../style/img/resp/yngpic1.png"
                    class="yngpic1"
                  ></img>
                </div>
                <div class="col-sm-7">
                  <center>
                    <h3 class="ylghead">Youngster's Lung Guard</h3>
                  </center>
                  <br></br>
                  <div class="container">
                    <center>
                      <p class="ylgpara">
                        At Respiro, we are dedicated to providing comprehensive
                        lung care for youngsters across India. Our specialized
                        Young India Lung Health Care Plan ensures optimal
                        respiratory well-being for your family, all from the
                        comfort of your home.
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg3">
        <br></br>
        <hr class="yline1"></hr>
        <center>
          <h3 class="childlung">Why Choose Respiro for Your Youngster's Lung Health?</h3>
        </center>
        <hr class="yline2"></hr>
        <br></br>

        <div class="container">
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-4 parag ">
              <div class="square yel">
                <img src="../style/img/resp/sqimg1.png" class="ysqimg"></img>
                <h5 class="iconh iconhh text-dark pchd">Personalized Care</h5>
                <p class="iconp iconpp text-dark pcp">
                  Receive tailored lung health assessments for each family
                  member, ensuring personalized care.
                </p>
              </div>
            </div>
            <div class="col-sm-4 parag ">
              <div class="square pink">
                <img src="../style/img/resp/sqimg2.png" class="ysqimg"></img>
                <h5 class="iconh iconhh text-dark pchd">Convenient Check-ups</h5>
                <p class="iconp iconpp text-dark pcp">
                  Get 5 free lung health check-ups when you join. Enjoy a 50%
                  discount on all subsequent check-ups.
                </p>
              </div>
            </div>
            <div class="col-sm-2"></div>
          </div>
          <br></br>
          <div class="row">
            <div class="col-sm-4 parag  ">
              <div class="square lblue">
                <img src="../style/img/resp/sqimg3.png" class="ysqimg"></img>
                <h5 class="iconh iconhh text-dark pchd">365 Days of Consultation</h5>
                <p class="iconp iconpp text-dark pcp">
                  Access our experienced pulmonologists anytime for any
                  lung-related concerns or emergencies.
                </p> 
              </div>
            </div>
            <div class="col-sm-4 parag ">
              <div class="square peach">
                <img src="../style/img/resp/sqimg4.png" class="ysqimg"></img>
                <h5 class="iconh iconhh text-dark pchd">
                  Special Household Discounts
                </h5>
                <p class="iconp iconpp text-dark pcp">
                  Sign up and get a 20% to 50% discount for your entire
                  household
                </p>
              </div>
            </div>
            <div class="col-sm-4 parag ">
              <div class="square dyel">
                <img src="../style/img/resp/sqimg5.png" class="ysqimg"></img>
                <h5 class="iconh iconhh text-dark pchd">Free Initial Consultations</h5>
                <p class="iconp iconpp text-dark pcp">
                  Avail the first 5 lung health consultations for free upon
                  enrollment.
                </p>
              </div>
            </div>
          </div>
          <br></br>
          <div class="container">
            <div class="row ">
              <div class="col-sm-2"></div>
              <div class="col-sm-4 parag ">
                <div class="square orangey">
                  <img src="../style/img/resp/sqimg6.png" class="ysqimg"></img>
                  <h5 class="iconh iconhh text-dark pchd">Referral Benefits </h5>

                  <p class="iconp iconpp text-dark pcp">
                    Refer a family to Respiro and get extra benefits and
                    discounts. Invite your friends today
                  </p>
                </div>
              </div>
              <div class="col-sm-4 parag">
                <div class="square dblue">
                  <img src="../style/img/resp/sqimg7.png" class="ysqimg"></img>
                  <h5 class="iconh iconhh text-dark pchd">Additional Services</h5>
                  <p class="iconp iconpp text-dark pcp">
                    Avail discounts on services such as respiratory therapy,
                    sleep studies, and pulmonary function tests.
                  </p>
                </div>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </div><br></br>
      </div>


      <div class="bggradient1">
        <br></br>
        <center>
          
          <h5>
            <b class="text-white childfff">How to Get Started ?</b>
          </h5>
        </center>
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col-sm-3">
              <div class="blank">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Sign Up</b>
                    </h5>
                  </center>
                 
                  <center>
                    <img
                      src="../style/img/resp/icony1.png"
                      class="fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                  Create an account on Respiro's website.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3">
              <div class="blank">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Enroll Your Family</b>
                    </h5>
                  </center>
            
                  <center>
                    <img
                      src="../style/img/resp/icony2.png"
                      class="fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                  Provide your family member’s details and enroll them in the Young India Lung Health Care Plan.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3">
              <div class="blank">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Schedule Check-ups</b>
                    </h5>
                  </center>
                 
                  <center>
                    <img
                      src="../style/img/resp/icony3.png"
                      class="fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                  Book lung health check-ups conveniently online.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3">
              <div class="blank">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Access Consultations</b>
                    </h5>
                  </center>
               
                  <center>
                    <img
                      src="../style/img/resp/icony4.png"
                      class="fdicon signicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                  Connect with our pulmonologists for consultations and guidance.

                  </p>
                </div>
              </div>
            </div>
            <br></br>
          </div>
          <br></br>
        </div>
        <br></br>
        <br></br>
      </div>
      <div class="bg3"><br></br>
        <div class="container">
          <div class="row">
            <div class="col-sm-7">
             <h4><b class="text-dark join">Join Respiro Today</b></h4><br></br>
              <p class="just yadjust">Prioritize your family's lung health with Respiro. Sign up now and embark on a journey to ensure optimal respiratory well-being for your youngsters. Together, let's breathe easy!</p>
            </div>
           
            
            <div class="col-sm-5">

              <img src="../style/img/resp/yngpic2.png" class="yngpic2"></img>
            </div>

          </div>
        </div>

        <center> <Link to ="/Packagesummary/Youngsterpackage">   <button class=" fprice custom-btn1 btn-3 btn-33">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button></Link>
                  </center>
                  <br></br><br></br>
      </div>
    </>
  );
}

export default YoungsterLungcareplan;
