import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
export default function RHFenema() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="ssimg12">
        <center>
          <h2>
            <b class="text-dark">
              <br></br>
              <br></br>
              <br></br>
              <br></br>Patient Asssessment
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Overview</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Health Check</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Comprehensive evaluation covering vital signs and overall health.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Specialized Care</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Focused assessments for specific conditions like diabetes or heart health.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Elderly & Rehab Care</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Tailored assessments for seniors and rehab patients to enhance mobility and wellness.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">Service in Detail</b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh"> Basic Vital Sign Check</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        A quick assessment of key health indicators like blood pressure, heart rate, and temperature to monitor your overall condition.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh"> Comprehensive Patient Assessment</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        A thorough examination covering physical, emotional, and medical aspects to ensure holistic care.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Specialized Assessments</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        Targeted evaluations for specific health concerns, such as neurological, cardiovascular, or diabetic conditions.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh"> Doctor’s Consultation & Assessment</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        A detailed consultation with a doctor to review symptoms, diagnose conditions, and recommend treatment.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh"> Physiotherapy Assessment</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        A physical evaluation by a physiotherapist to assess movement, strength, and functional abilities for tailored therapy.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Geriatric Assessment</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                        A specialized assessment focusing on the health and wellness of older adults, addressing age-related concerns.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

            </div>
        
          <br></br>
        </div>
    </>
  );
}


