import React, { useEffect, useState, useContext } from "react";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";

function Getappointments() {
  const { providerId } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (providerId && selectedDate) {
      console.log("Fetching appointments for providerId:", providerId, "on date:", selectedDate);
      setLoading(true);
      setError('');
      getFunction({ providerId, date: selectedDate }, "/appointment/get-appointment-by-provider-id-and-date")
        .then((response) => {
          console.log("API response:", response);
          if (response && response.success && Array.isArray(response.success.result)) {
            const responseData = response.success.result;
            console.log("Fetched appointments:", responseData);
            setData(responseData);
          } else {
            console.log("No appointments data found in response or response structure is incorrect:", response);
            setData([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching appointments:", error);
          setError('Failed to fetch appointments. Please try again later.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("Provider ID or selected date is not available");
    }
  }, [providerId, selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const toggleExpand = (appointmentId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [appointmentId]: !prevExpanded[appointmentId],
    }));
  };

  return (
    <>
      <div className="contain">
        <div className="providerline">
          <br />
          <h1 className="providerprofileheading100 profileheading1">View Appointments</h1>
          <hr className="providerline" />

          <div className="row">
            <div className="col-sm-5">
              <label htmlFor="datePicker">Select Date:</label>
            </div>
            <div className="col-sm-7">
              <input
                type="date"
                id="datePicker"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </div>

          {loading ? (
            <p>Loading appointments...</p>
          ) : error ? (
            <p>{error}</p>
          ) : data && data.length > 0 ? (
            data.map((appointment) => (
              <div className="appointment-item" key={appointment.patient_id}>
                <div className="row">
                  <div className="col-sm-10 card__source text-bold">&nbsp;
                    <p onClick={() => toggleExpand(appointment.patient_id)} style={{ cursor: 'pointer', color: "blue", textDecoration: 'underline' }}>
                      Patient Name: {appointment.first_name} {appointment.last_name}
                    </p>
                  </div>
                </div>
                {expanded[appointment.patient_id] && (
                  <div className="appointment-details">
                    <div className="row">
                      <div className="col-sm-5">
                        <p>Appointment Date:</p>
                      </div>
                      <div className="col-sm-7">
                        <p>{new Date(appointment.appointment_date).toLocaleDateString()}</p>
                      </div>
                      <div className="col-sm-5">
                        <p>Start Time:</p>
                      </div>
                      <div className="col-sm-7">
                        <p>{appointment.start_time}</p>
                      </div>
                      <div className="col-sm-5">
                        <p>Status:</p>
                      </div>
                      <div className="col-sm-7">
                        <p>{appointment.status}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No appointments available for the selected date.</p>
          )}
        </div>
      </div>
    </>
  );
}
export default Getappointments;
