
import React from "react";
import DoctorHeader from "../header/DoctorHeader";
import { Link } from "react-router-dom";
function DoctorLanding() {
  return (
    <>
      <DoctorHeader></DoctorHeader>
      <div class="docbgm">
        <div class="container">
          <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
          <center>
            <h1 class="text-white doclandh">
              Welcome to Respiro<br></br>
              Your Premier Respiratory Healthcare Cloud Platform
            </h1>
          </center>{" "}
          <br />
          <center>
            {" "}
            <p class="text-white doclandp">
              Welcome to Respiro, your trusted partner in delivering outstanding
              respiratory healthcare services. We are thrilled to have you join
              our community of dedicated respiratory specialists. Together, we
              can make a significant impact on patient care and outcomes.
            </p>
          </center>
        </div>
      </div>
      <div class="dbgm2">
        <div class="container">
          <div class="row">
            <div class="col-sm-8 ">
              <br></br>
              <br></br>
              <br></br>

              <div class="container">
                <p
                  class="text-white"
                  style={{ fontSize: "22px", color: "#fff" }}
                >
                  Thank you for choosing Respiro. Let's transform the future of
                  respiratory healthcare while advancing your professional
                  growth and success.
                </p>
                <Link to="/provider/login">
                  <button class="rhfbtn4">Sign in </button>
                </Link>{" "}
                &nbsp;
                <Link to="/provider/signup">
                  {" "}
                  <button class="rhfbtn3">Sign up</button>
                </Link>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="container">
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <br /> <br />
        <h4 style={{ fontWeight: "600" }}>Why Choose </h4>
        <br></br>
        <div class="doctext1">
          <h4 class="text-white doctexttt">Respiro Expert</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <img
            class="dorimg1"
            src="../style/img/resp/dorimg1.png"
            alt="Third slide"
          />
        </div>
        <div class="col-sm-4">
          <div class="container">
            <br></br>
            <div class="container">
              <div class="row ">
                <div class="col-sm-3">
                  <img
                    class="docioc"
                    src="../style/img/resp/docioc.png"
                    alt="Third slide"
                  />
                </div>
                <div class="col-sm-9">
                  <h5 class="docioctxt"> Comprehensive Network</h5>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Access to top-rated respiratory specialists.
                  </p>
                </div>
              </div>
              <div class="row ">
                <div class="col-sm-3">
                  <img
                    class="docioc"
                    src="../style/img/resp/docion1.png"
                    alt="Third slide"
                  />
                </div>
                <div class="col-sm-9">
                  <h5 class="docioctxt"> Simple Booking</h5>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Easy online appointment scheduling.
                  </p>
                </div>
              </div>
              <div class="row ">
                <div class="col-sm-3">
                  <img
                    class="docioc"
                    src="../style/img/resp/docioc3.png"
                    alt="Third slide"
                  />
                </div>
                <div class="col-sm-9">
                  <h5 class="docioctxt"> Verified Reviews</h5>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Genuine patient feedback to help build your practice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="container">
            <br></br>
            <div class="container">
              <div class="row ">
                <div class="col-sm-3">
                  <img
                    class="docioc"
                    src="../style/img/resp/docioc4.png"
                    alt="Third slide"
                  />
                </div>
                <div class="col-sm-9">
                  <h5 class="docioctxt"> Flexible Consultations</h5>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Offer in-clinic, telemedicine, or home visit options to your
                    patients.
                  </p>
                </div>
              </div>
              <div class="row ">
                <div class="col-sm-3">
                  <img
                    class="docioc"
                    src="../style/img/resp/docioc5.png"
                    alt="Third slide"
                  />
                </div>
                <div class="col-sm-9">
                  <h5 class="docioctxt"> Advanced Pulmono Treatment</h5>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Utilize cutting-edge diagnostics and treatments.
                  </p>
                </div>
              </div>
              <div class="row ">
                <div class="col-sm-3">
                  <img
                    class="docioc"
                    src="../style/img/resp/docioc6.png"
                    alt="Third slide"
                  />
                </div>
                <div class="col-sm-9">
                  <h5 class="docioctxt"> Secure and Stable Employment</h5>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Enjoy stable and secure employment within a reputable
                    organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div class="container">
        <center>
          <h3 class="docladjust">How It Works</h3>
          <br></br>
        </center>
        <div class="row">
          <div class="col-sm-4">
            <img
              class="doccardresp "
              src="../style/img/resp/doccard1.png"
              alt="Third slide"
            />
          </div>
          <div class="col-sm-4">
            <img
              class="doccardresp"
              src="../style/img/resp/doccard2.png"
              alt="Third slide"
            />
          </div>
          <div class="col-sm-4">
            <img
              class="doccardresp"
              src="../style/img/resp/doccard3.png"
              alt="Third slide"
            />
          </div>
        </div>
        <br />

        <div class="row docardtop">
          <div class="col-sm-4">
            <img
              class="doccardresp"
              src="../style/img/resp/doccard4.png"
              alt="Third slide"
            />
          </div>
          <div class="col-sm-4">
            <img
              class="doccardresp"
              src="../style/img/resp/doccard5.png"
              alt="Third slide"
            />
          </div>
          <div class="col-sm-4">
            <img
              class="doccardresp"
              src="../style/img/resp/doccard6.png"
              alt="Third slide"
            />
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div class="container-fluid">
        <center>
          <h3 class="docladjust">Benefits for Doctors</h3>
          <br></br>
          <br></br>
        </center>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div
            class="card doccard doctcentcards"
            style={{ marginLeft: "40px" }}
          >
            <div class="container">
              <br></br>

              <h3 style={{ fontWeight: "800" }}> Independent Work Style</h3>
              <p>
                {" "}
                Enjoy the autonomy to manage your practice and deliver patient
                care independently.
              </p>

              <br></br>
            </div>
          </div>
          <br></br>

          <div class="card doccard doctcentcards boxmov2">
            <div class="container">
              <br></br>
              <h3 style={{ fontWeight: "800" }}> Flexible Scheduling</h3>
              <p>
                {" "}
                Benefit from flexible working hours that allow you to balance
                your personal and professional life effectively.
              </p>
              <br></br>
            </div>
          </div>
          <br></br>
          <div class="card doccard doctcentcards boxmov3">
            <div class="container">
              <br></br>
              <h3 style={{ fontWeight: "800" }}>Increase Patient Engagement</h3>
              <p>
                Achieve higher patient engagement through innovative care plans
                and targeted communication strategies.
              </p>
              <br></br>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <img
            class="doccardimg docimg3"
            src="../style/img/resp/docimg3.png"
            alt="Third slide"
          />
        </div>
        <div class="col-sm-4">
          <div class="card doccard doctcentcards boxmov4">
            <div class="container">
              <br></br>
              <h3 style={{ fontWeight: "800" }}> Access Advanced Resources</h3>
              <p>
                Utilize state-of-the-art medical equipment and advanced
                resources to provide the best respiratory care possible.
              </p>
              <br></br>
            </div>
          </div>
          <br></br>

          <div class="card doccard doctcentcards boxmov5">
            <div class="container">
              <br></br>
              <h3 style={{ fontWeight: "800" }}>
                {" "}
                Expert Tech Help, a Click Away
              </h3>
              <p>
                {" "}
                Get immediate technical support whenever you need it, ensuring
                smooth and efficient operations.
              </p>
              <br></br>
            </div>
          </div>
          <br></br>
          <div
            class="card doccard doctcentcards boxmov6"
            style={{ marginLeft: "-240px" }}
          >
            <div class="container">
              <br></br>
              <h3 style={{ fontWeight: "800" }}>
                Secure and Stable Employment
              </h3>
              <p>
                {" "}
                Benefit from job security and stability in a reputable
                organization that values and supports its healthcare{" "}
              </p>
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <br></br>
        <br></br>
        <div class="dlandtop"></div>
        <center>
          <h3 class="docladjust">Manage Your Patients</h3>
          <br></br>
          <br></br>
        </center>
        <div class="row">
          <div class="col-sm-4">
            <div class="card doctcard doctcardresp">
              <div class="container">
                <br></br>
                <center>
                  <h4 style={{ fontWeight: "700" }} class="text-white">
                    {" "}
                    Appointment Schedule
                  </h4>
                </center>
                <center>
                  <img
                    class=""
                    style={{ width: "110px" }}
                    src="../style/img/resp/scheduledoc.png"
                    alt="Third slide"
                  />
                </center>
                <br />
                <p class="text-white just">
                  {" "}
                  Keep track of your daily, weekly, and monthly appointments
                  with ease. Set reminders and notifications to stay on top of
                  your schedule.
                  <br /> <br />
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card doctcard doctcardresp">
              <div class="container">
                <br></br>
                <center>
                  <h4 style={{ fontWeight: "700" }} class="text-white">
                    {" "}
                    Access to Patient Profiles
                  </h4>
                </center>
                <center>
                  <img
                    class=""
                    style={{ width: "110px" }}
                    src="../style/img/resp/cv 1.png"
                    alt="Third slide"
                  />
                </center>
                <br />
                <p class="text-white just">
                  {" "}
                  Access detailed profiles for each patient, including medical
                  history, current treatment plans, and progress notes. Easily
                  update records after each session.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card doctcard doctcardresp">
              <div class="container">
                <br></br>
                <center>
                  <h4 style={{ fontWeight: "700" }} class="text-white">
                    Patient Monitoring
                  </h4>
                </center>
                <center>
                  <img
                    class=""
                    style={{ width: "110px" }}
                    src="../style/img/resp/vital-signs 1.png"
                    alt="Third slide"
                  />
                </center>
                <br />
                <p class="text-white just">
                  {" "}
                  Track patient progress, monitor vital signs, and identify
                  potential complications.
                </p>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <div class="card doctcard doctcardresp">
              <div class="container just">
                <br></br>
                <center>
                  <h4 style={{ fontWeight: "700" }} class="text-white">
                    Clinical Guidelines
                  </h4>
                </center>
                <center>
                  <img
                    class=""
                    style={{ width: "110px" }}
                    src="../style/img/resp/manual-book 1.png"
                    alt="Third slide"
                  />{" "}
                  <br />
                </center>
                <p class="text-white">
                  {" "}
                  Access a comprehensive library of clinical guidelines and
                  treatment protocols to ensure best practices in patient care.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card doctcard doctcardresp">
              <div class="container">
                <br></br>
                <center>
                  <h4 style={{ fontWeight: "700" }} class="text-white">
                    {" "}
                    Professional Development
                  </h4>
                </center>
                <center>
                  <img
                    class=""
                    style={{ width: "110px" }}
                    src="../style/img/resp/professional-development 1.png"
                    alt="Third slide"
                  />{" "}
                  <br />
                </center>
                <p class="text-white just">
                  {" "}
                  Participate in webinars, workshops, and training programs
                  designed to enhance your skills and knowledge.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card doctcard doctcardresp">
              <div class="container">
                <br></br>
                <center>
                  <h4 style={{ fontWeight: "700" }} class="text-white">
                    Research Articles
                  </h4>
                </center>
                <center>
                  <img
                    class=""
                    style={{ width: "110px" }}
                    src="../style/img/resp/article 1.png"
                    alt="Third slide"
                  />{" "}
                  <br />
                </center>
                <p class="text-white just">
                  {" "}
                  Stay updated with the latest research and developments in
                  respiratory healthcare. Read articles and case studies to
                  expand
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" style={{ backgroundColor: "#4B78D2" }}>
        <div class="container">
          <br></br>
          <center>
            <h4 style={{ fontWeight: "700" }} class="text-white">
              Communication and Support
            </h4>
          </center>{" "}
          <br></br> <br></br>
          <div class="row">
            <div class="col-sm-3">
              <div class="card doctcardresp" style={{ height: "400px" }}>
                <div class="container">
                  <br></br>
                  <center>
                    <img
                      class="fdicon"
                      src="../style/img/resp/2058768 1.png"
                      alt="Third slide"
                    />
                  </center>

                  <center>
                    <h4 style={{ fontWeight: "700" }}>Team Collaboration</h4>
                  </center>

                  <p>
                    {" "}
                    Engage with your peers and other healthcare professionals
                    within the Respiro network. Share insights, discuss cases,
                    and collaborate on patient care.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card doctcardresp" style={{ height: "400px" }}>
                <div class="container">
                  <br></br>
                  <center>
                    <img
                      class="fdicon"
                      src="../style/img/resp/7214036 1.png"
                      alt="Third slide"
                    />
                  </center>
                  <center>
                    <h4 style={{ fontWeight: "700" }}> Secure Messaging</h4>
                  </center>

                  <p>
                    {" "}
                    Communicate securely with patients and colleagues. Use our
                    messaging system for follow-ups, patient inquiries, and
                    professional discussions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card doctcardresp" style={{ height: "400px" }}>
                <div class="container">
                  <br></br>
                  <center>
                    <img
                      class="fdicon"
                      src="../style/img/resp/4233839 1.png"
                      alt="Third slide"
                    />
                  </center>
                  <center>
                    <h4 style={{ fontWeight: "700" }}>Technical Support</h4>
                  </center>

                  <p>
                    {" "}
                    Need help? Our technical support team is here to assist you
                    with any issues or questions. Reach out for quick and
                    reliable support.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card doctcardresp" style={{ height: "400px" }}>
                <div class="container">
                  <br></br>
                  <center>
                    <img
                      class="fdicon"
                      src="../style/img/resp/978012 1.png"
                      alt="Third slide"
                    />
                  </center>
                  <center>
                    <h4 style={{ fontWeight: "700" }}>Stay Connected</h4>
                  </center>

                  <p>
                    {" "}
                    Follow us on social media to stay informed about the latest
                    updates, events, and resources from Respiro.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default DoctorLanding;
