import React from "react";
import Navbar from "../header/Navbar";
import Footer from "../footer/Footer";
function Signup() {
  return (
    <div>
      <Navbar></Navbar>
      <br></br>
      <br></br>
      <br></br>
      <div class="container">
        <div class="wrapper">
          <form class="form-signin">
            <h2 class="form-signin-heading">Please login</h2>
            <input
              type="text"
              class="form-control"
              name="username"
              placeholder="Frist Name"
              required=""
              autofocus=""
            />
            <input
              type="text"
              class="form-control"
              name="username"
              placeholder="Last Name"
              required=""
              autofocus=""
            />
            <input
              type="text"
              class="form-control"
              name="username"
              placeholder="Email Address"
              required=""
              autofocus=""
            />
            <input
              type="password"
              class="form-control"
              name="password"
              placeholder="Password"
              required=""
            />
            <label class="checkbox">
              <input
                type="checkbox"
                value="remember-me"
                id="rememberMe"
                name="rememberMe"
              />{" "}
              Remember me
            </label>
            <button class="btn btn-lg btn-primary btn-block" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Signup;
