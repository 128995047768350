import React, { useEffect, useState, useContext } from "react";
import { getFunction } from "../../api/apiFunctions";
import Header from "../header/Header";
import { useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import { FaCheckCircle } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { SiPhonepe } from "react-icons/si";
import { FaStar } from "react-icons/fa6";
import moment from "moment";

export default function ProviderDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [providerId, setProviderId] = useState(id);
  const [imageUrl, setImageUrl] = useState("");
  const [data, setData] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [qualificationNames, setQualificationNames] = useState([]);
  const [hospital, setHospital] = useState([]);
  const [review, setReview] = useState([]);
  const [rating, setRating] = useState([]);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const [date, setDate] = useState(`${year}-${month}-${day}`); // State variable for date
  const [availslots, setAvailSlots] = useState([]);
  const [datas, setDatas] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const tabsToShow = 3;
  const [activeKey, setActiveKey] = useState(moment().format("yyyy-MM-DD")); // To manage active tab
  const [loading, setLoading] = useState(true); // Manage loading state
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSlots(activeKey);
  }, []);

  const handleNext = () => {
    if (startIndex + tabsToShow < dates.length) {
      setStartIndex(startIndex + tabsToShow);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - tabsToShow);
    }
  };
  const handleTabSelect = (key) => {
    setActiveKey(key);
    fetchSlots(key); // Fetch slots for the selected date
  };

  useEffect(() => {
    setProviderId(id);
    setLoading(true); // Start loading

    getFunction({ providerId }, "/patient/get-provider-full-profile")
      .then((response) => {
        const responseData = response.success.result.provider[0];
        const responseHosp = response.success.result.hospitals[0];
        const responseQuali = response.success.result.qualification;
        const responseReview = response.success.result.review;
        const responseRating = response.success.result.rating[0];
        setQualificationNames(responseQuali.map((q) => q.qualification_name));
        //const qualificationNames = qualification.map(qualification => qualification.qualification_name);
        //console.log(qualificationNames);
        //console.log(responseData);
        const responseImg = response.success.result.profilePic;
        console.log("url", responseImg);
        //setImageUrl(JSON.parse(response.success.profilePic).url);
        setData(responseData);
        setQualification(responseQuali);
        setHospital(responseHosp);
        const hospitalAddress = {
          hospitalName: responseHosp.hospital_name,
          addressline_1: responseHosp.addressline_1,
          addressline_2: responseHosp.addressline_2,
          subdistrictName: responseHosp.subdistrictname,
          districtName: responseHosp.districtname,
          state: responseHosp.state,
          pincode: responseHosp.pincode,
          phone: responseHosp.phone,
        };
        localStorage.setItem(
          "selectedHospital",
          JSON.stringify(hospitalAddress)
        );
        // console.log(JSON.parse(localStorage.getItem("selectedHospital")));
        setReview(responseReview || []); // Ensure review is an array

        //setReview(responseReview);
        setImageUrl(responseImg);
        console.log(hospital);
        setRating(responseRating);
      })

      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // Set a delay for the loader
        setTimeout(() => {
          setLoading(false); // Stop loading after fetching data
        }, 1000); // Change the duration (in milliseconds) to your desired wait time
      });
  }, [id]);

  useEffect(() => {
    //fetchSlots(activeKey); // Fetch slots for the active key (first date)

    getFunction({ providerId, date }, "/provider/get-slots")
      .then((response) => {
        const responseData = response.success.result;
        setAvailSlots(responseData);
        console.log(availslots);
        console.log(providerId);
        console.log(date);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // Toggle function to show more or less content
  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  // Conditionally render short or full content
  const shortContent = data.about_me && data.about_me.slice(0, 100);
  const fullContent = data.about_me;
  // Generate the current date and the next days
  const generateDates = (numDays) => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < numDays; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(date.toISOString().split("T")[0]);
    }
    return dates;
  };
  const dates = generateDates(20); // Generate 7 days including today
  const fetchSlots = (date) => {
    //alert(date);

    getFunction({ providerId, date }, "/provider/get-slots")
      .then((response) => {
        const responseData = response.success.result.slots;
        setDatas(responseData);
        console.log(datas);
      })
      .catch((error) => {
        console.log(error);
      });
    setDatas("");
  };
  const handleButtonClick = (item) => {
    navigate(`/Slotbooking`, {
      state: {
        date: activeKey,
        time: item,
        providerId,
        providerName: data.full_name,
        fees: data.init_consult_fee,
      },
    });
  };
  /* rating*/

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <>
        {fullStars && Array(fullStars).fill(
          <FaStar style={{ color: "#ffc107", width: "35px", height: "19px" }} />
        )}
        {hasHalfStar && (
          <span
            style={{
              position: "relative",
              display: "inline-block",
              width: "35px",
              height: "19px",
            }}
          >
            <FaStar
              style={{
                color: "#e4e5e9", // Gray color for the unfilled part
                width: "35px",
                height: "19px",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            <FaStar
              style={{
                color: "#ffc107", // Gold color for the filled part
                width: "35px",
                height: "19px",
                position: "absolute",
                top: 0,
                left: 0,
                clipPath: "inset(0 50% 0 0)", // Clip the right half
              }}
            />
          </span>
        )}
        {emptyStars && Array(emptyStars).fill(
          <FaStar style={{ color: "#e4e5e9", width: "19px", height: "19px" }} />
        )}
      </>
    );
  };

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div class="container-fluid">
          <div class="container">
            <div class="row d-flex">
              <div
                class="col-sm d-flex flex-column"
                style={{ flex: " 0 0 9.33%" }}
              >
                <div>
                  {imageUrl ? (
                    <img src={imageUrl} alt="Profile" class="drprofile11"></img>
                  ) : (
                    <img
                      src="../style/img/resp/profilepicdummy.png"
                      alt="Profile"
                      className="drprofile providerdrprofile"
                    />
                  )}
                </div>
              </div>
              <div
                class="col-sm d-flex flex-column"
                style={{ flex: "0 0 53.33%" }}
              >
                <h4 style={{ color: "#199fd9" }}>{data.full_name}</h4>
                <p class="docdetails" id="qualnames">
                  {qualificationNames.join(", ")}
                </p>
                <p class="docdetails">
                  {data.years_experience} <span>Years of experience</span>
                </p>
                <p class="docdetails">
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    ₹ {data.init_consult_fee}
                  </span>{" "}
                  Consultation fee at clinic
                </p>
                <br />
                <FaCheckCircle style={{ color: "#01a400" }} />

                <p style={{ marginTop: "-26px", marginLeft: "33px" }}>
                  Medical Registration Verified
                </p>
                {/* <FaThumbsUp style={{ color: "#01a400" }}/> */}

                {/* <p style={{ marginTop: "-26px", marginLeft: "33px" }}>
                  95% (784 patients)
                </p> */}
                {/* <p>
                  <CiStar style={{ color: "#ffc107", height: "104px", width: "30px" }} />
                  <CiStar style={{ color: "#ffc107", height: "104px", width: "30px" }} />
                  <CiStar style={{ color: "#ffc107", height: "104px", width: "30px" }} />
                  <CiStar style={{ color: "#ffc107", height: "104px", width: "30px" }} />
                  <CiStar style={{ color: "#ffc107", height: "104px", width: "30px" }} />
                </p> */}
                {/* <p>{rating.average_rating}</p> */}

                <div style={{ color: "black" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {renderStars(rating.average_rating)}&nbsp;({review.length})
                    {/* <p style={{ marginLeft: "8px" }}>{rating.average_rating}</p> */}
                  </div>
                  {isContentVisible ? (
                    <span id="moreContent">{fullContent}</span>
                  ) : (
                    <span id="shortContent">
                      {shortContent}
                      {data.about_me && data.about_me.length > 100 && "... "}
                    </span>
                  )}
                  <p
                    id="toggleButton"
                    onClick={toggleContent}
                    // style={{ color: isContentVisible ? "#03a9f4" : "#03a9f4" }}
                    style={{
                      color: "#03a9f4",
                      cursor: "pointer",
                      color: "#03a9f4 !important",
                    }}
                  >
                    {isContentVisible ? "Show Less" : "More.."}
                  </p>
                </div>
              </div>
              <div
                class="col-sm d-flex flex-column"
                style={{ flex: "0 0 37.33%" }}
              >
                <h4> Pick a time slot</h4>
                <div class="card slotcard">
                  <div class="subslotcard" >
                    <div class="row" style={{marginTop: '15px'}}>
                      <div class="col-sm-2">
                        <img
                          src="../style/img/resp/Group 244 (1).png"
                          alt="Profile"
                          className="clinic_icon"
                        />
                      </div>
                      <div class="col-sm-6">
                        <h6>Clinic Appointment</h6>
                      </div>
                      <div class="col-sm-4">
                        <h6>₹ {data.init_consult_fee} fee</h6>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="container">
                          <p>
                            &nbsp;&nbsp;
                            <span style={{ fontWeight: "bold" }}>
                              {hospital.hospital_name}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span style={{ fontSize: "10px" }}>
                              {/* <i class="fa fa-phone" aria-hidden="true"></i> */}
                              <FaPhone style={{ color: "#199fd9" }} />
                            </span>
                            <span>&nbsp;&nbsp;{hospital.phone}</span>{" "}
                          </p>
                          <div>
                            <p class="hname">
                              ₹ {data.init_consult_fee} &nbsp;&nbsp;
                              <span style={{ color: "#8a45e6" }}>
                                Verified details
                              </span>
                            </p>
                            <p class="hname">
                              {hospital.addressline_1}, {hospital.addressline_2}{" "}
                              ,{hospital.locality}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-12">
                        <div>
                          {/* <div className="d-flex justify-content-between mb-3">
                            <a
                              onClick={handlePrev}
                              className="prebutton"
                              disabled={startIndex - tabsToShow >= dates.length}
                            >
                            <IoIosArrowDropleftCircle style={{ color: "green" }} />

                            </a>
                            <a
                              onClick={handleNext}
                              className="nextbutton"
                              disabled={startIndex + tabsToShow >= dates.length}
                            >
                              
                              <IoIosArrowDroprightCircle style={{ color: "green" }} />

                            </a>
                            
                          </div> */}
                           {/* <button
                                onClick={handlePrev}
                                className="prebutton"
                                disabled={startIndex - tabsToShow >= dates.length}
                                style={{ border: "none", background: "transparent",    position:"absolute",
                                  left: "20px",
                                  top: "14px" }}
                              >
                                <IoIosArrowDropleftCircle style={{ color: "green", fontSize: "20px" }} />
                              </button>  */}

                          <Tabs
                            style={{ fontSize: "10px" }}
                            defaultActiveKey={date}
                            id="uncontrolled-tab-example"
                            className="tabs custom-tab"
                            onSelect={handleTabSelect}
                          >
                            <Tab
                              eventKey="previous"
                              title={
                                <a
                                  onClick={handlePrev}
                                  className="prebutton"
                                  disabled={
                                    startIndex - tabsToShow >= dates.length
                                  }
                                >
                                  <IoIosArrowDropleftCircle
                                    style={{ color: "green", fontSize: "20px" }}
                                  />
                                </a>
                              }
                            ></Tab>

                            {dates
                              .slice(startIndex, startIndex + tabsToShow)
                              .map((date, index) => (
                                <Tab eventKey={date} title={date} key={index}>
                                  <br />
                                  <p
                                    style={{ marginLeft: "20px"}}
                                  >{`Available Slots(${datas.length})`}</p>
                                  {datas.length === 0 ? (
                                    <div className="card__detail">
                                      <div
                                        className="card__source text-bold"
                                        style={{ marginLeft: "-123px" }}
                                      >
                                        No slots available
                                      </div>
                                    </div>
                                  ) : (
                                    <div class="container slot-container">
                                      <div className="row">
                                        {datas.map((item, idx) => (
                                          <div className="col-4 mb-2" key={idx}>
                                            <button
                                              className="slotbtn"
                                              onClick={() =>
                                                handleButtonClick(item)
                                              }
                                            >
                                              {item}
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                   
                                </Tab>
                                
                               
                              ))}
                               
                            <Tab
                              eventKey="next"
                              title={
                                <a
                                  onClick={handleNext}
                                  className="nextbutton"
                                  disabled={
                                    startIndex + tabsToShow >= dates.length
                                  }
                                >
                                  <IoIosArrowDroprightCircle
                                    style={{ color: "green", fontSize: "20px" }}
                                  />
                                </a>
                              }
                            ></Tab>
                            
                          </Tabs>
                          {/* <button
                                onClick={handleNext}
                                className="nextbutton"
                                disabled={startIndex + tabsToShow >= dates.length}
                                style={{ border: "none", background: "transparent",    position:"absolute",
                                  right: "68px",
                                  top: "14px" }}
                              >
                                <IoIosArrowDroprightCircle style={{ color: "green", fontSize: "20px" }} />
                              </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <hr />
          <div class="container">
            <Tabs
              defaultActiveKey="Info"
              id="uncontrolled-tab-example"
              className="mb-3 tabs custom-tab"
            >
              <Tab eventKey="Info" title="Info" class="tab">
                <div class="container">
                  <p>
                    {hospital.locality}, {hospital.subdistrictname} ,{" "}
                    {hospital.districtname}
                  </p>
                  <div class="row">
                    <div class="col-sm-4">
                      <p style={{ color: "#199fd9" }}>
                        {hospital.hospital_name}
                      </p>
                      <p>
                        {hospital.addressline_1}, {hospital.addressline_2},{" "}
                        {hospital.locality}, {hospital.subdistrictname},{" "}
                        {hospital.districtname}, pin:{hospital.pincode}
                      </p>
                      <p>Phn:{hospital.phone}</p>
                      <p>e-mail:{hospital.email}</p>
                      <p>{hospital.website}</p>
                    </div>
                    <div class="col-sm-4">
                      <p style={{ fontWeight: "540" }}>Mon, Friday</p>
                      <p>09:00 AM - 04:30 PM</p>
                      <p style={{ fontWeight: "540" }}>Sat, Sun</p>
                      <p>09:00 AM - 12:30 PM</p>
                    </div>
                    <div class="col-sm-4">
                      <p class="hname">₹ {data.init_consult_fee}</p>

                      <p>
                        {" "}
                        <SiPhonepe style={{ color: "#5f259f" }} />
                        &nbsp;&nbsp;Online Payment Available
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              &nbsp;&nbsp;&nbsp;
              <Tab
                eventKey="reviews"
                title={`Reviews(${review.length})`}
                class="tab"
              >
                <div className="container">
                  {review.length === 0 ? (
                    <div className="card__detail">
                      <div className="card__source text-bold">No Reviews </div>
                    </div>
                  ) : (
                    review.map((item) => (
                      <div className="row" key={item.id}>
                        <div className="col-sm-12">
                          <p style={{ color: "#737382" }}>
                            {item.first_name} {item.last_name} (Verified)
                            <span
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "77px",
                              }}
                            >
                              {item.review_date}
                            </span>
                          </p>
                          <p>{item.comments}</p>
                          <hr />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </Tab>
              &nbsp;&nbsp;&nbsp;
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
}
