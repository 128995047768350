import { useRoutes } from "react-router-dom";
import Pneumothorax from "../pages/common/Pneumothorax";
import Lungcancer from "../pages/common/Lungcancer";
import Loading from "../pages/common/Loding";
import Home from "../pages/common/Home";
import Partnership from "../pages/common/Partnership";
// import Bookathome from "../pages/common/Bookathome";
// import Login from "../pages/common/Login";
import Comingsoon from "../pages/common/Comingsoon";

import Preventive from "../pages/common/Preventive";
import Rehabilitation from "../pages/common/Rehabilitation";
import Company from "../pages/common/Company";
import Respirotherapist from "../pages/common/Respirotherapist";
import Pulmonologist from "../pages/common/Pulmonologist";
import Reg from "../pages/common/Reg";
import Press from "../pages/common/Press";
import Blog from "../pages/common/Blog";
import Contact from "../pages/common/Contact";
import Bookclinic from "../pages/common/Bookclinic";
import Bookinghome from "../pages/common/Bookinghome";
import Booktherapsit from "../pages/common/Booktherapsit";
import Lungtransplantation from "../pages/common/Lungtransplantation";
import Interstitiallung from "../pages/common/Interstitiallung";
import Intervetional from "../pages/common/Intervetional";
import Pulmonologycritical from "../pages/common/Pulmonologycritical";
import Dash from "../pages/common/Dash";
import Dash1 from "../pages/common/Dash1";
import Dash2 from "../pages/common/Dash2";
import Dash3 from "../pages/common/Dash3";
import Dash4 from "../pages/common/Dash4";
import Cart from "../pages/common/Cart";
import Pneumoiaplan from "../pages/common/Pneumoiaplan";
import Postsurgerycare from "../pages/common/Postsurgerycare";
import Emergencymedicalcare from "../pages/common/Emergencymedicalcare";
import Chestphysiotherapy from "../pages/common/Chestphysiotherapy";
import Emergencymedicalequipment from "../pages/common/Emergencymedicalequipment";
import Pulmonologyfunction from "../pages/common/Pulmonologyfunction";
import Sleepstudy from "../pages/common/Sleepstudy";
import Refundpolicy from "../pages/common/Refundpolicy";
// import Rhf from "../pages/rhf/Rhf";
import Padmin from "../pages/common/Padmin";
import Signup from "../pages/common/Signup";
// import Rhfadmin from "../pages/rhf/Rhfadmin";
import Respiroreg from "../pages/register/Respiroreg";
import Regdoctor from "../pages/register/Regdoctor";
import Regtherapist from "../pages/register/Regtherapist";
import Reglab from "../pages/register/Reglab";
import Regcare from "../pages/register/Regcare";
import Regrhf from "../pages/register/Regrhf";
import Loading1 from "../pages/common/Loading1";
import MusicianRespiratory from "../pages/common/MusicianRespiratory";
import TeacherRespiratory from "../pages/common/TeacherRespiratory";
import Indexhome from "../pages/common/Indexhome";
import Homecard from "../pages/common/Homecard";

const CommonRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/", element: <Home /> },
    { path: "/home", element: <Home /> },
    // { path: "/bookathome", element: <Bookathome /> },
    { path: "/partnership", element: <Partnership /> },
    { path: "/pneumothorax", element: <Pneumothorax /> },
    { path: "/lung-cancer", element: <Lungcancer /> },
    // { path: "/login", element: <Login /> },
    { path: "/preventive", element: <Preventive /> },
    { path: "/rehabilitation", element: <Rehabilitation /> },
    { path: "/company", element: <Company /> },
    { path: "/respiro-therapist", element: <Respirotherapist /> },
    { path: "/pulmonologist", element: <Pulmonologist /> },
    { path: "/reg", element: <Reg /> },
    { path: "/press", element: <Press /> },
    { path: "/blog", element: <Blog /> },
    { path: "/contact", element: <Contact /> },
    { path: "/book-clinic", element: <Bookclinic /> },
    { path: "/booking-home", element: <Bookinghome /> },
    { path: "/book-therapist", element: <Booktherapsit /> },
    { path: "/Lungtransplantation", element: <Lungtransplantation /> },
    { path: "/interstitial-lung", element: <Interstitiallung /> },
    { path: "/interventional", element: <Intervetional /> },
    { path: "/pulmonology-critical", element: <Pulmonologycritical /> },
    { path: "/dash", element: <Dash /> },
    { path: "/dash1", element: <Dash1 /> },
    { path: "/dash2", element: <Dash2 /> },
    { path: "/dash3", element: <Dash3 /> },
    { path: "/dash4", element: <Dash4 /> },
    { path: "/cart", element: <Cart /> },
    { path: "/comming-soon", element: <Comingsoon /> },
    { path: "/pneumonia-plan", element: <Pneumoiaplan /> },
    { path: "/post-surgery-care", element: <Postsurgerycare /> },
    { path: "/emergency-medical-care", element: <Emergencymedicalcare /> },
    { path: "/chest-physiotherapy", element: <Chestphysiotherapy /> },
    { path: "/emergency-medical-equipment", element: <Emergencymedicalequipment />,},
    { path: "/pulmonology-function", element: <Pulmonologyfunction /> },
    { path: "/sleep-study", element: <Sleepstudy /> },
    { path: "/refund-policy", element: <Refundpolicy /> },
    { path: "/padmin", element: <Padmin /> },
    { path: "/signup", element: <Signup /> },
    { path: "/respiro-reg", element: <Respiroreg /> },
    { path: "/register-doctor", element: <Regdoctor /> },
    { path: "/register-therapist", element: <Regtherapist /> },
    { path: "/register-lab", element: <Reglab /> },
    { path: "/register-care", element: <Regcare /> },
    { path: "/register-rhf", element: <Regrhf /> },
    { path: "/Loading1", element: <Loading1 /> },
    { path: "/Indexhome", element: <Indexhome /> },
    { path: "/MusicianRespiratory", element: <MusicianRespiratory /> },
    { path: "/TeacherRespiratory", element: <TeacherRespiratory /> },
    { path: "/Homecard", element: <Homecard /> },
  ]);
  return routes;
};

export default CommonRoutes;
