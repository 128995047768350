import React from "react";
import { Link } from "react-router-dom";
function Snav1() {
  return (
    <div>
      <div class="navbgclr" >


        <div class="card snavcard">
          <div class="container">
            <div class="row">

              <div class="col-sm-2">
                <center>
                  <Link to="/Finddoctor">
                    <img
                      src="../style/img/resp/finddoctorimg.png"
                      class="snavimg2 "
                      alt=""
                      style={{ height: "100px", width: "170px" }}
                    />
                  </Link>{" "}

                  <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
                    <h5 class="sanavtext ">FIND DOCTORS NEAR YOU</h5>
                  </Link>
                </center>
              </div>
              <div class="col-sm-2">
                <center>
                  <Link to="/Rhf">
                    <img
                      src="../style/img/resp/rhf.png"
                      class="snavimg"
                      alt=""
                      style={{ width: "130px" }}
                    />{" "}
                  </Link>{" "}

                  <Link to="/Rhf" style={{ textDecoration: "none" }}>
                    <h5 class="sanavtext">RAPID HEAL FORCE(RHF)</h5>
                  </Link>
                </center>
              </div>
             
              <div class="col-sm-2">
                <center>
                  <Link to="/LungTherapies">
                    <img
                      src="../style/img/resp/lungtherapistimg.png"
                      class="snavimg"
                      alt=""
                      style={{ width: "170px" }}
                    />
                  </Link>{" "}

                  <Link to="/LungTherapies" style={{ textDecoration: "none" }}>
                    <h5 class="sanavtext">REHAB</h5>
                  </Link>
                </center>
              </div>
              <div class="col-sm-2">
                {" "}
                <center>
                  <Link to="/BookaTest">
                    <img
                      src="../style/img/resp/bookatestimg.png"
                      class="snavimg "
                      alt=""
                      style={{ width: "150px" }}
                    /></Link>{" "}

                  <Link to="/BookaTest" style={{ textDecoration: "none" }}>
                    <h5 class="sanavtext">BOOK A TEST</h5>
                  </Link>
                </center>
              </div>
              <div class="col-sm-2">
                <center>
                  <Link to="/O2_sale">
                    <img
                      src="../style/img/resp/saleimg.png"
                      class="snavimg3 "
                      alt=""
                      style={{ height: "100px", width: "120px", marginTop: "-5px", marginBottom: "5px" }}
                    />
                  </Link>{" "}

                  <Link to="/O2_sale" style={{ textDecoration: "none" }}>
                    <h5 class="sanavtext text-dark" >
                      MEDICAL DEVICES SALE
                    </h5>
                  </Link>
                </center>
              </div>
              <div class="col-sm-2">
                {" "}
                <center>
                  <Link to="/O2_rent">
                    {" "}
                    <img
                      src="../style/img/resp/mer.png"
                      class="snavimg"
                      alt=""
                      style={{ width: "130px", width: "120px" }}

                    />
                  </Link>{" "}

                  <Link to="/O2_rent" style={{ textDecoration: "none" }}>
                    <h5 class="sanavtext text-dark">

                      MEDICAL EQUIPMENT RENT

                    </h5>
                  </Link>
                </center>
              </div>
            </div>
          </div>

        </div>

        <br></br>
      </div>
    </div>
  );
}

export default Snav1;
