import { useRoutes } from "react-router-dom";
import Doctors from "../pages/provider/Doctors";
import Doctorsdetails from "../pages/provider/Doctorsdetails";
import Consultonline from "../pages/provider/Consultonline";
import Consultoffline from "../pages/provider/Consultoffline";
import ProviderLanding from "../pages/provider/ProviderLanding";
import { ProviderLogin, ProviderSignup } from "../pages/auth/ProviderLogin";
import ProviderDashboard from "../pages/provider/ProviderDashboard";
import ProviderLogout from "../pages/auth/ProviderLogout";
import ProviderAddress from "../pages/provider/ProviderAddress";
import Profile from "../pages/provider/Profile";
import ServiceInformation from "../pages/provider/ServiceInformation";
import Patientdetails from "../pages/provider/Patientdetails";
import Report from "../pages/provider/Report";
import Scheduling from "../pages/provider/Scheduling";
import NewHospital from "../pages/provider/NewHospital";

const ProviderRoutes = () => {
  let routes = useRoutes([
    { path: "/doctors", element: <Doctors /> },
    { path: "/doctors-details/:id", element: <Doctorsdetails /> },
    { path: "/consult-online", element: <Consultonline /> },
    { path: "/consult-offline", element: <Consultoffline /> },
    { path: "/provider/profile", element: <Profile /> },
    { path: "/provider/landing", element: <ProviderLanding /> },
    { path: "/provider/login", element: <ProviderLogin /> },
    { path: "/provider/dashboard", element: <ProviderDashboard /> },
    { path: "/provider/signup", element: <ProviderSignup /> },
    { path: "/provider/address", element: <ProviderAddress /> },
    { path: "/provider/ServiceInformation", element: <ServiceInformation /> },
    { path: "/provider/logout", element: <ProviderLogout /> },
    { path: "/provider/patientdetails/:id", element: <Patientdetails/> },
    { path: "/provider/report", element: <Report/>},
    { path: "/provider/scheduling", element: <Scheduling/>},
    { path: "/provider/add-hospital", element: <NewHospital/>},
    { path: "/provider/patientdetails", element: <Patientdetails/>},
  ]);
  return routes;
};

export default ProviderRoutes;
