import { useRoutes } from "react-router-dom";

import DoctorLanding from "../pages/doctor/Doctorlanding";


const DoctorRoutes = () => {
  let routes = useRoutes([

    { path: "/doctor/Landing", element: <DoctorLanding /> },

   
  ]);
  return routes;
};

export default DoctorRoutes;