import AdminHeader from '../header/AdminHeader';
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {
    getFunction,
    setFunction,
    updateFunction,
    deleteFunction,
} from "../../api/apiFunctions";

function ViewAreaCoordinators() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [locality, setLocality] = useState(null);

    const coordinatorId = id;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getFunction({ coordinatorId }, "/coordinator/get-all-area-coordinator-in-district")
            .then((response) => {
                const responseData = response.success.result.members;
                const responselocalityData = response.success.result.locality_details[0]; // Updated to access the first element
                setData(responseData);
                setLocality(responselocalityData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [coordinatorId]);

    return (
        <>
            <AdminHeader />
            <center>
                <div className="datatable">
                    <h4 style={{ color: "#3131e5" }}>Area Coordinators</h4>
                    <p>Locality count in  {locality ? locality.districtname : 0},{locality ? locality.state : 0} : {locality ? locality.locality_count : 0} </p>
                    <p>Total area coordinators: {locality ? locality.area_coordinator_count : 0}</p>
                    <div className="col-sm-8">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="th-sm">S.No</th>
                                    <th className="th-sm">Name</th>
                                    <th className="th-sm">Phone</th>
                                    <th className="th-sm">Email</th>
                                    <th className="th-sm">Locality</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length === 0 ? (
                                    <div className="card__detail">
                                        <div className="card__source text-bold">No area coordinators available under this district.</div>
                                    </div>
                                ) : (
                                    data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.full_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.locality}, {item.subdistrictname}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <br />
                    </div>
                </div>
            </center>
        </>
    );
}

export default ViewAreaCoordinators;
