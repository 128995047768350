import { useState, useContext, useEffect } from "react";
import Header from "../header/Header";
import PersonalInfo from "./PersonalInfo";
import Documents from "./Documents";
import Address from "./Address";
import { slide as Menu } from "react-burger-menu";

import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withPatientAuth from "../../middleware/withPatientAuth";
import UpcomingAppointments from "./UpcomingAppointments";
import PastAppointments from "./PastAppointments";
import UpcomingRhfAppoinments from "./UpcomingRhfAppoinments";
import PastRhfAppointment from "./PastRhfAppointment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faUser,
  faMapLocationDot,
  faCalendarCheck,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import ListDocuments from "./ListDocuments";

const Profile = () => {
  const { patientId, patientEmail, isMobile } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [data, setData] = useState({
    patientId: "",
    id: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",
    medicalHistory: "",
  });

  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingPatient, setExistingPatient] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);

  useEffect(() => {
    if (patientId) {
      getFunction({ id: patientId }, "/patient/get-profile")
        .then((response) => {
          const responseData = response.success.patient;
          setProfileEditView(false);
          setData({
            id: responseData.id,
            firstName: responseData.first_name,
            lastName: responseData.last_name,
            gender: responseData.gender,
            phone: responseData.phone,
            email: responseData.email,
            medicalHistory: responseData.medical_history,
          });
        })
        .catch((error) => {
          setData((prevData) => ({
            ...prevData,
            email: patientEmail,
            id: patientId,
          }));
          console.log(error);
          setExistingPatient(false);
        });
    }
  }, [patientId, patientEmail]);

  const [menuActive, setMenuActive] = useState(false);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const [showInfo, setShowInfo] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showUpcoming, setShowUpcoming] = useState(false);
  const [showPast, setShowPast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpcomingrhf, setShowUpcomingrhf] = useState(false);
  const [showPastrhf, setShowPastrhf] = useState(false);
  const [showUpdocuments, setShowUpdocuments] = useState(false);

  const handleMenuClick = (section) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setShowInfo(section === "info");
      setShowAddress(section === "address");
      setShowDocuments(section === "documents");
      setShowUpcoming(section === "upcoming");
      setShowPast(section === "past");
      setShowUpcomingrhf(section === "upcomingrhf");
      setShowPastrhf(section === "pastrhf");
      setShowUpdocuments(section === "uploadeddocuments");

      // Close mobile menu if open after item is selected
      if (isMobile) {
        setIsMenuOpen(false); // Close the menu
      }
    }, 500); // Simulate loading time
  };

  const mobileMenu = () => (
    <Menu
      className="d-block d-md-none"
      isOpen={isMenuOpen}
      onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}
    >
      <h3 className="side-menu-item" onClick={() => handleMenuClick("info")}>
        <FontAwesomeIcon icon={faUser} style={{ fontSize: "25px" }} />
        &nbsp;Personal Info
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("address")}>
        <FontAwesomeIcon icon={faMapLocationDot} style={{ fontSize: "25px" }} />
        &nbsp;Address
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("documents")}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
        &nbsp;Upload Documents
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("uploadeddocuments")}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
        &nbsp; List Documents
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("upcoming")}
      >
        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
        &nbsp;Upcoming Doctor Appointments
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("past")}>
        <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: "25px" }} />
        &nbsp;Previous Doctor Appointments
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("upcomingrhf")}
      >
        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
        &nbsp;Upcoming Rhf Appointments
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("pastrhf")}>
        <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: "25px" }} />
        &nbsp;Previous Rhf Appointments
      </h3>
    </Menu>
  );
  const desktopMenu = () => (
    <div className="side-menu bg2 prowidth">
      <h3 className="side-menu-item" onClick={() => handleMenuClick("info")}>
        <FontAwesomeIcon icon={faUser} style={{ fontSize: "25px" }} />
        &nbsp;Personal Info
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("address")}>
        <FontAwesomeIcon icon={faMapLocationDot} style={{ fontSize: "25px" }} />
        &nbsp;Address
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("documents")}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
        &nbsp;Upload Documents
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("uploadeddocuments")}
      >
        <FontAwesomeIcon icon={faFile} style={{ fontSize: "25px" }} />
        &nbsp; List Documents
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("upcoming")}
      >
        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
        &nbsp;Upcoming Doctor Appointments
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("past")}>
        <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: "25px" }} />
        &nbsp;Previous Doctor Appointments
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("upcomingrhf")}
      >
        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
        &nbsp;Upcoming Rhf Appointments
      </h3>
      <h3 className="side-menu-item" onClick={() => handleMenuClick("pastrhf")}>
        <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: "25px" }} />
        &nbsp;Previous Rhf Appointments
      </h3>
    </div>
  );

  return (
    <>
      <Header />

      <div>
        <div className="d-flex protop">
          {isMobile ? mobileMenu() : desktopMenu()}
          <div className="container profile-container">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {showInfo ? <PersonalInfo /> : null}
                {showAddress ? <Address /> : null}
                {showDocuments ? <Documents /> : null}
                {showUpcoming ? <UpcomingAppointments /> : null}
                {showPast ? <PastAppointments /> : null}
                {showUpcomingrhf ? <UpcomingRhfAppoinments /> : null}
                {showPastrhf ? <PastRhfAppointment /> : null}
                {showUpdocuments ? <ListDocuments /> : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const LoadingSpinner = () => (
  <>
    <br></br> <br></br> <br></br>
  </>
);

export default Profile;
