import React from 'react'

function Loader() {
  return (
    <>
    
    <div className="loader">
      <h1 class="loadhead">LOADING</h1>
      <span></span>
      <span></span>
      <span></span>
    </div>
    </>
  )
}

export default Loader