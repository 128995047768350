import React, { useEffect,useState} from "react";
import Header from "../header/Header";

function Chronicpain() {const [duration, setDuration] = useState(1);
const [pricePerMonth] = useState(500);

const handleDurationChange = (amount) => {
  const newDuration = duration + amount;
  if (newDuration > 0) {
    setDuration(newDuration);
  }
};

const totalPrice = duration * pricePerMonth;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="bg2">
        <div className="bg3">
          <div style={{ textAlign: "center", position: "relative" }}>
            <img
              src="../style/img/Rehabserv/Chronic pain.jpg"
              className="pneumoban"
              alt="Pneumoban"
            />
            <h3 className=" pneu1">
              <b class="text-dark">Chronic Pain Management</b>
            </h3>
            <p className=" pneu2">
              <b class="text-dark">
                Effective strategies to manage and alleviate chronic pain.
              </b>
            </p>
          </div>
          <br />
          <div className="greenbg">
            <h3
              className="text-center text-white"
              style={{ fontWeight: "bold", fontFamily: "Poppins" }}
            >
              Package Contents
            </h3>

            <div className="container">
              <div className="row">
                {[
                  {
                    imgSrc: "../style/img/resp/pneuicon1.png",
                    title: "Initial Assessment",
                    description:
                      "Comprehensive evaluation to identify the source and nature of chronic pain.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon2.png",
                    title: "Personalized Treatment Plan",
                    description:
                      "Customized plan tailored to your specific pain management needs and goals.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Therapeutic Interventions",
                    description:
                      "Including physical therapy, cupping therapy, dry needling, and other modalities to aid recovery.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Strengthening and Conditioning Exercises",
                    description:
                      "Exercises designed to restore strength, flexibility, and functionality.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Lifestyle Modifications",
                    description:
                      "Guidance on lifestyle changes to support pain management and improve quality of life.",
                  },
                  {
                    imgSrc: "../style/img/resp/pneuicon4.png",
                    title: "Precautions and Self-Care Advice",
                    description:
                      "Tips on precautions to take and self-care practices to manage chronic pain effectively.",
                  },
                ].map((item, index) => (
                  <div
                    className="col-sm-4 d-flex flex-column align-items-center text-center mb-4" // Added margin-bottom
                    key={index}
                  >
                    <div
                      className="blank d-flex flex-column justify-content-between"
                      style={{ height: "100%" }}
                    >
                      <img
                        src={item.imgSrc}
                        style={{
                          height: "60px",
                          display: "block",
                          margin: "40px auto",
                        }}
                        alt={item.title}
                      />
                      <h5 className="iconh">{item.title}</h5>
                      <p className="iconp">{item.description}</p>
                      <br />
                      <br />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <center>
            <h3
              style={{
                fontWeight: "800",
                fontFamily: "Poppins",
                fontSize: "30px",
              }}
            >
              Plan Purchase
            </h3>
            <h6
              style={{
                fontSize: "20px",
              }}
            >
              Choose Your Plan Duration
            </h6>
          </center>

          <div class="container">
            <center>
              <div
                class="greenbg priceboxgreen"
                style={{
                  borderRadius: "20px",
                  width: "750px",
                  height: "300px",
                }}
              >
                <br></br>
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd text-dark">Duration Selector:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd text-dark">Price:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button
                    class="purchasebutton"
                    style={{
                      fontWeight: "600",
                      backgroundColor: "#fff",
                      color: "#466E6C",
                      borderRadius: "30px",
                    }}
                  >
                    Purchase
                  </button>
                </center>
              </div>
            </center>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chronicpain;
               