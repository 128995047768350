import React, { useState } from "react";

function Failure() {
  const articles = [
    {
      img: "../style/img/resp/100.jpg",
      title: "Update",
      description: "Just in time for Lunar New Year and the Rat’s time in the cyclical place of honor, the Treasure of Unbound Majesty is now available.",
    },
    {
      img: "../style/img/resp/101.jpg",
      title: "Update",
      description: "Just in time for Lunar New Year and the Rat’s time in the cyclical place of honor, the Treasure of Unbound Majesty is now available.",
    },
    {
      img: "../style/img/resp/102.jpg",
      title: "Update",
      description: "Just in time for Lunar New Year and the Rat’s time in the cyclical place of honor, the Treasure of Unbound Majesty is now available.",
    },
    {
      img: "../style/img/resp/104.jpg",
      title: "Update",
      description: "Just in time for Lunar New Year and the Rat’s time in the cyclical place of honor, the Treasure of Unbound Majesty is now available.",
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <div style={{ background: "#24282f", padding: "15px" }}>
        <h2 style={{
          padding: "10px 25px",
          color: "#ccc",
          margin: "0",
          display: "inline-block",
          position: "relative",
        }}>
          <strong style={{
            background: "#24282f",
            padding: "4px 8px",
            position: "relative",
            zIndex: "2",
          }}>
            What's new?
          </strong>
        </h2>

        <div style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px",
        }}>
          {articles.map((article, index) => (
            <figure
              key={index}
              style={{
                position: "relative",
                width: "350px",
                height: "235px",
                overflow: "hidden",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.8)",
                transition: "0.3s",
                transform: hoveredIndex === index ? "translateY(-3px) scale(1.05) rotateX(15deg)" : "none",
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                src={article.img}
                alt={article.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  filter: hoveredIndex === index ? "blur(3px)" : "none",
                  transform: hoveredIndex === index ? "scale(0.97)" : "none",
                  transition: "0.2s",
                }}
              />
              <figcaption
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  padding: "40px",
                  background: "rgba(6, 18, 53, 0.6)",
                  color: "#fff",
                  fontSize: "14px",
                  boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                  opacity: hoveredIndex === index ? "1" : "0",
                  transform: hoveredIndex === index ? "none" : "scale(1.15)",
                  transition: "0.2s",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h3 style={{
                  color: "#3792e3",
                  fontSize: "16px",
                  marginBottom: "8px",
                  fontWeight: "bold",
                }}>
                  {article.title}
                </h3>
                <p>{article.description}</p>
              </figcaption>
            </figure>
          ))}
          {/* Dotted arrows SVG */}
          {articles.length > 1 && (
            <>
              <svg style={{
                position: "absolute",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}>
                <line x1="50%" y1="0" x2="50%" y2="50%" stroke="white" strokeWidth="2" strokeDasharray="5,5" />
                <line x1="50%" y1="50%" x2="100%" y2="50%" stroke="white" strokeWidth="2" strokeDasharray="5,5" />
                <line x1="100%" y1="50%" x2="100%" y2="100%" stroke="white" strokeWidth="2" strokeDasharray="5,5" />
              </svg>
            </>
          )}
        </div>
      </div>

      <section className="w3l-features py-5" id="features" style={{ width: "98%", margin: "0 auto" }}>
        <div style={{ padding: "40px 0" }}>
          <div className="title-content text-center mb-lg-3 mb-4">
            <h3 className="title-w3l">
              <font color="#FFC900">We are Focusing</font>
            </h3>
            <br />
            <h6 className="title-subw3hny mb-1">
              <font color="#FFFFF">
                Our expert team of engineers has evolved to support the move from traditional technologies to the IoT platform. We have the experience and capabilities to integrate IoT with any other technology to create a hybrid smart technology.
              </font>
            </h6>
          </div>

          <main className="page-content" style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}>
            {articles.map((article, index) => (
              <figure
                key={index}
                style={{
                  position: "relative",
                  width: "23%",
                  height: "235px",
                  overflow: "hidden",
                  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.8)",
                  transition: "0.3s",
                  margin: "0 10px 20px 0",
                  transform: hoveredIndex === index ? "translateY(-3px) scale(1.05) rotateX(15deg)" : "none",
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <img
                  src={article.img}
                  alt={article.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    filter: hoveredIndex === index ? "blur(3px)" : "none",
                    transform: hoveredIndex === index ? "scale(0.97)" : "none",
                    transition: "0.2s",
                  }}
                />
                <figcaption
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    padding: "40px",
                    background: "rgba(6, 18, 53, 0.6)",
                    color: "#fff",
                    fontSize: "14px",
                    boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                    opacity: hoveredIndex === index ? "1" : "0",
                    transform: hoveredIndex === index ? "none" : "scale(1.15)",
                    transition: "0.2s",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <h3 style={{
                    color: "#3792e3",
                    fontSize: "16px",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}>
                    {article.title}
                  </h3>
                  <p>{article.description}</p>
                </figcaption>
              </figure>
            ))}
          </main>
        </div>
      </section>
    </>
  );
}

export default Failure;
