import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ArtistData from "../../data/ArtistData";
import Header from "../header/Header";
const Artistcareplan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const items = ArtistData.filter(
    (item) =>
      item.id === 1 ||
      item.id === 2 ||
      item.id === 3 ||
      item.id === 4 ||
      item.id === 5 ||
      item.id === 6 ||
      item.id === 7 ||
      item.id === 8 ||
      item.id === 9 ||
      item.id === 10 ||
      item.id === 11 ||
      item.id === 12 ||
      item.id === 13 ||
      item.id === 14 ||
      item.id === 15 ||
      item.id === 16 ||
      item.id === 17 ||
      item.id === 18 ||
      item.id === 19
  );
  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // Function to add the 'show' class to elements in the viewport
  function checkCards() {
    const cards = document.querySelectorAll('.careercardanim');
    cards.forEach(card => {
      if (isElementInViewport(card)) {
        card.classList.add('show');
      }
    });
  }

  // Run the checkCards function on scroll
  window.addEventListener('scroll', checkCards);

  // Run the checkCards function on page load (in case some cards are initially visible)
  window.addEventListener('load', checkCards);

  return (
    <>
      <Header></Header>
      <br></br>
      <div class="bg4">
        <div class="artistbnr">
          <div class="container">
            <center>
              <h3 class="techhed text-white artmainhd cartexanim">
                Performance Artist's Lung Care Health Package
              </h3>
            </center>
          </div>
        </div>
        <div class="container">
          <br></br>
          <p class="techptext">
            As a performance artist, your commitment to delivering captivating
            performances often involves extensive rehearsals and stage
            appearances, which can impact your respiratory health. Our
            specialized Performance Artist Respiratory Health Plan is crafted to
            support you in maintaining optimal lung function and well-being,
            addressing the unique challenges faced by performers.
          </p>
          <p class="techpbold">
            {" "}
            Here's what our performance Artist Lung Health Plan includes:
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="card artcard  careercardanim">
                <center>
                  <div class="containertech">
                    <br></br>
                    <h3 class="techshed">
                      {" "}
                      Breath Control and Vocal Performance Enhancement
                    </h3>
                    <img
                      src="../style/img/resp/artimg1.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Performers rely heavily on their breath control and vocal
                      abilities for captivating performances. This package
                      offers essential tools such as a Vocal Warm-up Kit, Online
                      Workshops, and Personalized Coaching to enhance breath
                      control and vocal projection, ensuring performers deliver
                      their best on stage.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card artcard careercardanim">
                <center>
                  <div class="containertech">
                    <br></br>
                    <h3 class="techshed"> Stage Ergonomics Optimization</h3>

                    <img
                      src="../style/img/resp/artimg2.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Stage comfort is vital for performers to execute movements
                      and maintain focus during performances. This package
                      includes an Ergonomic Performance Chair, Portable Stage
                      Mat, and Customized Setup Consultation to optimize comfort
                      and movement on stage, reducing the risk of discomfort or
                      distraction during performances.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card artcard  careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Indoor Air Quality Enhancement</h3>

                    <img
                      src="../style/img/resp/artimg3.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Good air quality is crucial for performers' respiratory
                      health, especially in indoor venues. This package provides
                      a Stage Air Purifier, Oxygen-Enriched Air Generator, Air
                      Quality Monitor, and Consultation to ensure clean air
                      backstage, minimizing respiratory risks and enhancing
                      performers' well-being.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card artcard careercardanim ">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">
                      {" "}
                      Stress Management and Performance Anxiety Relief
                    </h3>

                    <img
                      src="../style/img/resp/artimg4.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      Performers often face stress and anxiety, impacting their
                      performances. This package offers resources like
                      Meditation Apps, Aromatherapy Diffusers, Workshops, and
                      Personalized Coaching to manage stress and anxiety
                      effectively, enabling performers to maintain focus and
                      confidence on stage.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="card artcard careercardanim ">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">Healthy Lifestyle Promotion</h3>
                    <img
                      src="../style/img/resp/artimg5.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      A healthy lifestyle is essential for performers' overall
                      well-being and performance quality. This package includes
                      an Organic Snack Box Subscription, Fitness Tracker
                      Smartwatch, Nutrition Consultation, and Wellness Workshops
                      to promote healthy eating, physical fitness, and overall
                      wellness tailored to performers' needs.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card artcard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Enhanced Features</h3>
                    <img
                      src="../style/img/resp/artimg6.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1">
                      The Enhanced Features package offers personalized
                      breathing assessments, stage environment evaluations, and
                      a respiratory health maintenance plan, ensuring performers
                      receive tailored support to optimize lung function and
                      mitigate respiratory risks for peak performance.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div class="bg4">
        <img src="../style/img/resp/artbg1.png" class="hiwbg"></img>
        <br></br>
        <br></br>
      </div>
      <div className="bg4">
        <div className="card techfcard ">
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <img
                  src="../style/img/resp/artt1.png"
                  className="techcardimg"
                />
              </div>
              <div className="col-sm-6">
                <center>
                  <h4> Breath Control and Vocal Performance Enhancement</h4>
                  <p class="artline">
                    Vocal Warm-up Kit with Breathing Exercises
                  </p>
                  <p class="artline">
                    Online Vocal Performance Technique Workshop
                  </p>
                  <p class="artline">
                    Personalized Vocal Coaching Session to Improve<br></br>
                    Breath Control and Projection
                  </p>
                </center>
              </div>
              <div className="col-sm-2">
                <h5 className="fprice artprup1">₹ 200/-</h5>
                <h5 className="sprice artprup2">₹ 300/-</h5>
                <h5 className="sprice artprup3">₹ 900/-</h5>
              </div>
              <div className="col-sm-1">
                {items.map((item) => (
                  <div className="button-container artbtnryt" key={item.id}>
                    {item.id === 1 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn1">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 2 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn2">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 3 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn3">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/artt2.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4>Stage Ergonomics Optimization</h4>
                  <p class="artline">
                    Ergonomic Performance Chair with Lumbar Support
                  </p>
                  <br></br>
                  <p class="artline">
                    Portable Stage Mat for Comfortable Movement
                  </p>
                  <br></br>
                  <p class="artline">
                    Customized Stage Setup Consultation to Optimize<br></br>
                    Breathing Space and Movement
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice artprup4">₹ 4000/-</h5>
                <h5 class="sprice artprup5">₹ 1000/-</h5>
                <h5 class="tprice artprup6">₹ 750/-</h5>
              </div>
              <div className="col-sm-1">
                {items.map((item) => (
                  <div className="button-container artbtnryt" key={item.id}>
                    {item.id === 4 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn4">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 5 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn5">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 6 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn6">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/artt3.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4>Indoor Air Quality Enhancement</h4>
                  <p class="artline">
                    Stage Air Puriﬁer with HEPA Filter and Quiet Operation
                  </p>
                  <br></br>
                  <p class="artline">
                    Oxygen-Enriched Air Generator for Backstage Use
                  </p>
                  <p class="artline">
                    Portable Air Quality Monitor for On-stage Performance{" "}
                    <br></br>Spaces
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice artprup7">₹ 6000/-</h5>
                <h5 class="sprice artprup8">₹ 4000/-</h5>
                <h5 class="sprice artprup9">₹ 1500/-</h5>
              </div>
              <div className="col-sm-1">
                {items.map((item) => (
                  <div className="button-container artbtnryt" key={item.id}>
                    {item.id === 7 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn7">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 8 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn8">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 9 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn9">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/artt4.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4>Stress Management and Performance Anxiety Relief</h4>
                  <p class="artline">
                    Meditation App Subscription with Stress-Relief <br></br>
                    Techniques
                  </p>
                  <p class="artline">
                    Aroma therapy Diffuser with Relaxing Essential Oils for{" "}
                    <br></br>
                    Backstage Relaxation
                  </p>
                  <p class="artline">
                    Performance Anxiety Relief Workshop with <br></br>Breathing
                    Techniques{" "}
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice artprup10">₹ 700/year</h5>
                <h5 class="sprice artprup11">₹ 1200/-</h5>
                <h5 class="tprice artprup12">₹ 1500/-</h5>
              </div>
              <div className="col-sm-1">
                {items.map((item) => (
                  <div className="button-container artbtnryt" key={item.id}>
                    {item.id === 10 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn10">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 11 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn11">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 12 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn12">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/artt5.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4>Healthy Lifestyle Promotion</h4>
                  <p class="artline">
                    Organic Snack Box Subscription (Monthly) with <br></br>{" "}
                    Performer-friendly Snacks
                  </p>
                  <p class="artline">
                    Fitness Tracker Smartwatch with Performance<br></br>{" "}
                    oriented Features
                  </p>
                  <p class="artline">
                    Personalized Nutrition Consultation Session Tailored
                    <br></br> to Performers' Dietary Needs
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice artprup13">₹ 1500/month</h5>
                <h5 class="sprice artprup14">₹ 3000/-</h5>
                <h5 class="tprice artprup15">₹ 800/-</h5>
              </div>
              <div className="col-sm-1">
                {items.map((item) => (
                  <div className="button-container artbtnryt" key={item.id}>
                    {item.id === 13 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn13">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 14 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn14">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 15 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn15">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/artt6.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4>Enhanced Features </h4>
                  <p class="artline">
                    Personalized Breathing Assessment with a Focus
                    <br></br>
                    on Musicians' Respiratory Needs
                  </p>
                  <p class="artline">
                    Stage Environment Evaluation for Air Quality <br></br>and
                    Respiratory Health Considerations
                  </p>
                  <p class="artline">
                    Personalized Nutrition Consultation Session<br></br>Tailored
                    to Performers' Dietary Needs
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice artprup16">₹ 1200/-</h5>
                <h5 class="sprice artprup17">₹ 2500/-</h5>
                <h5 class="sprice artprup18">₹ 500/-</h5>
              </div>
              <div className="col-sm-1">
                {items.map((item) => (
                  <div className="button-container artbtnryt" key={item.id}>
                    {item.id === 16 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn16">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 17 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn17">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 18 && (
                      <NavLink to={`/artist/${item.id}`}>
                        <button className="fprice custom-btn1 btn-3 artbtn18">
                          <span className="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg2">
        <br></br>
        <div class="container-fluid">
          <div class="card techlcard">
            <br></br>
            <div class="container">
              <center>
                <h4 class="techshed">
                  Special Lung Care Package For Performers{" "}
                </h4>
              </center>{" "}
              <br></br>
              <div class="row">
                <div class="col-sm-4">
                  <img
                    src="../style/img/resp/clocky.png"
                    class="lcardimg"
                  ></img>
                </div>
                <div class="col-sm-8">
                  <h5>Monthly Diagnosis Plan</h5>
                  <br></br>
                  <div class="container">
                    <p>
                      {" "}
                      In addition, to our standard offerings, our Performance
                      Artist Respiratory Health Package includes the following
                      enhanced features :
                    </p>
                    <p class="techlp">
                      Peak Flow Test
                      <br></br>
                      Spirometry Test
                      <br></br>
                      Lung Diffusion Capacity Test<br></br>
                      Voice Assessment and Laryngoscopy<br></br>
                      Consultation with Pulmonologist
                    </p>
                    <center>
                      <p>
                        {" "}
                        Monthly Diagnosis Plan&nbsp; &nbsp; &nbsp;{" "}
                        <b class="text-dark"> ₹ 999/-</b>&nbsp; &nbsp; &nbsp;
                        &nbsp;{" "}
                        {items.map((item) => (
                          <div className="button-container" key={item.id}>
                            {item.id === 19 && (
                              <NavLink to={`/artist/${item.id}`}>
                                <button className="fprice custom-btn1 btn-3 artbtn19">
                                  <span className="btntexty btnfsm">Book Now</span>
                                </button>
                              </NavLink>
                            )}
                          </div>
                        ))}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
};

export default Artistcareplan;
