import React, { useContext, useState } from "react";
import { setFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

const Hospital = (props) => {
  const { providerId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false); // Add loading state

  const setHospitalAffiliation = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setFunction(
      { providerId, hospitalId: props.data.id },
      "/hospital_affiliation/set-hospital-affiliation"
    )
      .then((response) => {
        const id = response.success.id;
        props.handleAddition(props.data, id);
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Stop loading on error
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="hospital-card">
      <div className="hospital-header">{props.data.hospital_name}</div>
      <div className="hospital-fields">
        {props.data.addressline_1}, {props.data.addressline_2}
      </div>
      <div className="hospital-fields">
        {props.data.locality}, {props.data.state}
      </div>
      <div className="hospital-fields">{props.data.phone}</div>
      <div className="hospital-fields">{props.data.email}</div>
      <div className="hospital-fields">{props.data.website}</div>
      <button className="hospital-button" onClick={setHospitalAffiliation}>
        Add
      </button>
    </div>
  );
};

export default withProviderAuth(Hospital);
