import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCartShopping } from "react-icons/fa6";
const CartBtn = () => {
  // We get a state of addItems
  // Write the name of the file not the function
  const state = useSelector((state) => state.addItem);
  return (
    <>
      {/* <NavLink to="/cart" className="topnavtext">
      <span className="fa fa-shopping-cart me-1 cartbtn"></span><div className="count">{state.length}</div>

      </NavLink> */}
      <NavLink to="/cart" className="topnavtext">
        <div class="cart">
        <FaCartShopping class="cartbtn text-dark "/>
          <span class="count">{state.length}</span>
        </div>
      </NavLink>
    </>
  );
};

export default CartBtn;
