import React, { useEffect, useState } from "react";
import Header from "../header/Header";
function ArticleOne() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <div class="bg3">
          <br></br>
          <div class="container">
            <div class="row" >
              <div class="col-sm-1"></div>
              <div class="col-sm-3">
                <h1 class="">
                  "Understanding <br></br>Modern Respiratory<br></br>Health:
                  Key Factors<br></br>and Trends"
                </h1>
                <p class="">May 30 ,2022</p>
              </div>
              
              <div class="col-sm-8" >
                <img
                  class="articimg1"
                  src="../style/img/resp/articimg1.png"
                  style={{height:"30vw"}}
                ></img>
              </div>
            </div>
<br></br><br></br>
            <div class="row">
              <div class="col-sm-4">
                <center>
                  <img
                    src="../style/img/resp/articircle1.png"
                    class="articircle1"
                  ></img>
                </center>
                <center>
                  <h4 style={{ fontWeight: "bold" }}>
                    <br></br>Dr Catherine Michael
                  </h4>
                </center>
                <center>
                  <p style={{ fontSize: "16px" }}>Respiratory Therapist</p>
                </center>
              </div>
              <div class="col-sm-8">
                <h4>
                  <b class="text-dark" >
                    IMPORTANCE OF RESPIRO HEALTH
                  </b>
                </h4>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                  Respiratory health is fundamental to overall well-being, as it
                  directly impacts the body's ability to obtain oxygen and expel
                  carbon dioxide, which is crucial for maintaining life. This
                  overview explores the significance of respiratory health,
                  factors affecting it, and measures to promote and protect it.
                  <br></br>
                  The Role of the Respiratory System The respiratory system
                  includes the airways, lungs, and respiratory muscles that work
                  together to enable breathing. It facilitates the exchange of
                  gases, delivering oxygen to the bloodstream and removing
                  carbon dioxide from the body. Efficient functioning of this
                  system is essential for cellular metabolism and energy
                  production.
                </p>
                <br></br>
                <h5 class="impact">
                  <b class="text-dark " >
                    Impact on Overall Health
                  </b>
                </h5>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                  • Oxygen Supply: Adequate oxygen levels are necessary for the
                  proper function of organs and tissues. Poor respiratory health
                  can lead to insufficient oxygenation, affecting the brain,
                  heart, and other vital organs.<br></br>• Energy Levels:
                  Respiratory efficiency influences energy levels and physical
                  performance. Impaired lung function can result in fatigue and
                  decreased stamina.<br></br>• Immune Function: The respiratory
                  system is a first line of defense against airborne pathogens.
                  Healthy respiratory organs help filter out harmful particles
                  and microorganisms, reducing the risk of infections.
                </p>
                <br></br>
                <h5>
                  <b class="text-dark" >
                    Common Respiratory Diseases
                  </b>
                </h5>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                  • Asthma: A chronic condition characterized by inflammation
                  and narrowing of the airways, leading to difficulty in
                  breathing, wheezing, and coughing.<br></br>• Chronic
                  Obstructive Pulmonary Disease (COPD): A group of diseases,
                  including emphysema and chronic bronchitis, that cause airflow
                  blockage and breathing problems.<br></br>
                  •Lung Cancer: A malignant tumor that forms in the tissues of
                  the lungs, often associated with smoking and exposure to
                  carcinogens.<br></br>
                  •Pneumonia: An infection that inflames the air sacs in one or
                  both lungs, which can fill with fluid or pus, causing cough,
                  fever, and difficulty breathing.
                </p>
                <br></br>
                <h5>
                  <b class="text-dark" >
                    Factors Affecting Respiratory Health
                  </b>
                </h5>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                  • Air Quality: Pollution, allergens, and exposure to hazardous
                  substances can significantly impact lung function and overall
                  respiratory health.<br></br>• Lifestyle Choices: Smoking, lack
                  of physical activity, and poor diet can negatively affect
                  respiratory health. Conversely, regular exercise and a
                  balanced diet can support lung function.<br></br>•
                  Occupational Hazards: Certain jobs expose individuals to dust,
                  chemicals, and fumes that can harm the respiratory system.
                  <br></br>• Genetics: Genetic predisposition can play a role in
                  the susceptibility to respiratory diseases.
                </p>
                <br></br>
                <h5>
                  <b class="text-dark" >
                    Preventive Measures
                  </b>
                </h5>
                <p style={{ fontSize: "18px", textAlign: "justify" }}>
                  • Avoid Smoking: Smoking is a major risk factor for many
                  respiratory conditions. Quitting smoking greatly improves
                  respiratory health.<br></br>• Regular Exercise: Physical
                  activity strengthens the respiratory muscles and enhances lung
                  capacity.<br></br>• Healthy Diet: A diet rich in fruits,
                  vegetables, and antioxidants supports the immune system and
                  respiratory health.<br></br>• Air Quality Management: Reducing
                  exposure to pollutants and allergens, using air purifiers, and
                  advocating for cleaner environments can improve respiratory
                  health.<br></br>• Vaccinations: Vaccinations against
                  influenza, pneumonia, and other respiratory infections help
                  protect vulnerable populations.
                </p>
                <br></br>
              </div>
            </div>
            <img src="../style/img/resp/lungimg.png" class="lungimg"></img>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-7">
                <center>
                  <img
                  class="articimg2"
                    src="../style/img/resp/articimg2.png"
                    style={{ height: "300px" }}
                  ></img>
                </center>
               
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default ArticleOne;
