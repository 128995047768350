import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
function ElderlyLungcareplan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <div class="bg3">
          <br></br>
          <br></br>
          <img src="../style/img/resp/eldbnr.png" class="childbnrr"></img>
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-7">
                <h2 class="pop childhead eldmainhd">
                  <b class="text-dark start">
                    Respiro - Elderly <br></br>Lung Health<br></br>Care Plan
                  </b>
                </h2>
                <p class="pop just drychild">
                  Welcome to Respiro, Your Partner in Elderly Lung Health <br />
                  Care Ensuring 100% Lung Health for Seniors in India.
                </p>
                <div class="col-sm-6 left">
                  <button class=" fprice custom-btn1 btn-3 eldbtn1">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </div>
                <a href="">
                  <div class="col-sm-6 right text-dark details">
                    For more details <FaLongArrowAltRight class="text-dark" />
                  </div>
                </a>
              </div>
              <div class="col-sm-5">
                <img
                  src="../style/img/resp/eldbnr2.png"
                  class="childbnr2"
                ></img>
              </div>
            </div>
          </div>
          <br></br>
        </div>

        <br></br>

        <div class="container">
          <center>
            <img src="../style/img/resp/eldbnr3.png" class="yngimg30 eldimg1"></img>
          </center>
          <div class="container">
            <div class="row eldup">
              <div class="col-sm-5">
                <img src="../style/img/resp/eldpic1.png" class="yngpic10 eldimg2"></img>
              </div>
              <div class="col-sm-7">
                <center>
                  <h3 class="elderhead elderhead414 elhead">
                    Enhanced <b class="text-dark">Lung Health</b>
                    <br></br>
                    for a Vibrant <b class="text-dark">Senior Life</b>
                  </h3>
                </center>
                <br></br>
                <div class="container">
                  <center>
                    <p class="elgpara">
                      At Respiro, we understand the unique respiratory
                      challenges faced by the elderly. Our Elderly Lung Health
                      Care Plan is designed to provide specialized lung care
                      tailored to the needs of seniors, ensuring they enjoy a
                      healthy, active, and fulfilling life. Together, let's
                      support the respiratory well-being of our seniors and help
                      them age gracefully with stronger lungs.
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container ">
        <br></br>
        {/* <hr class="childline line"></hr> */}
        <h3 class="pop elderlung ynghfont">
          Why Choose Respiro<br></br>
          for Your Child's Lung Care?
        </h3>
        <br></br>
        <div class="row">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-6">
                <div class="container ">
                  <center>
                    {" "}
                    <img
                      src="../style/img/resp/eldicon1.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <center>
                    <h5>
                      <b class="text-dark iconh">Specialized Senior Care</b>
                    </h5>
                  </center>
                  <p class="iconp just text-dark">
                    Our Elderly Lung Health Care Plan focuses on the specific
                    respiratory needs of seniors, providing comprehensive care
                    that addresses the complexities of aging lungs.
                  </p>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="container ">
                  <center>
                    {" "}
                    <img
                      src="../style/img/resp/eldicon2.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <center>
                    <h5>
                      <b class="text-dark iconh">
                        Holistic Approach to Lung Health
                      </b>
                    </h5>
                  </center>
                  <p class="iconp just text-dark">
                    Respiro takes a holistic approach to lung health, offering a
                    range of services including lifestyle advice, stress
                    management techniques, and support for maintaining overall
                    well-being in senior years.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="container ">
                  <center>
                    {" "}
                    <img
                      src="../style/img/resp/eldicon3.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <center>
                    <h5>
                      <b class="text-dark iconh">Community and Support</b>
                    </h5>
                  </center>
                  <p class="iconp just text-dark">
                    Join a supportive community of seniors and their families
                    committed to lung health. Share experiences, gain insights,
                    and encourage each other in maintaining optimal respiratory
                    health.
                  </p>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="container ">
                  <center>
                    {" "}
                    <img
                      src="../style/img/resp/eldicon4.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <center>
                    <h5>
                      <b class="text-dark iconh">
                        Exclusive Workshops and Events
                      </b>
                    </h5>
                  </center>
                  <p class="iconp just text-dark">
                    Participate in senior-friendly events and workshops focused
                    on lung health education, respiratory exercises, and fun
                    activities that promote overall wellness.
                  </p>
                </div>
              </div>
            </div>

            {/* <div class="container">
                      <center>
                        {" "}
                        <img
                          src="../style/img/resp/eldicon3.png"
                          class="fdicon"
                        ></img>
                      </center>
                      <center>
                        <h5>
                          <b class="text-dark iconh">Community and Support</b>
                        </h5>
                      </center>
                      <p class="iconp just text-dark">
                        Join a supportive community of seniors and their
                        families committed to lung health. Share experiences,
                        gain insights, and encourage each other in maintaining
                        optimal respiratory health.
                      </p>
                    </div> */}

            {/* <div class="container">
                      <center>
                        {" "}
                        <img
                          src="../style/img/resp/eldicon4.png"
                          class="fdicon"
                        ></img>
                      </center>
                      <center>
                        <h5>
                          <b class="text-dark iconh">
                            Exclusive Workshops and Events
                          </b>
                        </h5>
                      </center>
                      <p class="iconp just text-dark">
                        Participate in senior-friendly events and workshops
                        focused on lung health education, respiratory exercises,
                        and fun activities that promote overall wellness.
                      </p>
                    </div> */}
          </div>
          <div class="col-sm-4">
            <img src="../style/img/resp/sidepic1.png" class="sidepic1"></img>
          </div>
        </div>

        {/* <td class="image bg3" rowspan="2">
             
            </td> */}
      </div>
      <div class="bggradient3">
        <br></br>
        <center>
          <h5>
            <b class="text-dark childfff">How to Get Started ?</b>
          </h5>
        </center>
        <br></br>
        <div class="container">
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="blank2">
                  <div class="container">
                    <br></br>
                    <center>
                      <h5>
                        <b class="text-dark childf">Senior Enrollment</b>
                      </h5>
                    </center>
                    <br></br>
                    <center>
                      {/* <img
                      src="../style/img/resp/eldddicon1.png"
                      class="fdicon"
                    ></img> */}
                      <img
                        src="../style/img/resp/eldddicon1.png"
                        class="fdicon"
                      ></img>
                    </center>
                    <p class="gradf just">
                      Sign up your elderly family members for Respiro's Elderly
                      Lung Health Care Plan.
                    </p>
                  </div>
                </div>
              </div>
              <br></br>

              <div class="col-sm-4">
                <div class="blank2">
                  <div class="container">
                    <br></br>
                    <center>
                      <h5>
                        <b class="text-dark childf">
                          Personalized Health Assessment
                        </b>
                      </h5>
                    </center>
                    <br></br>
                    <center>
                      <img
                        src="../style/img/resp/eldddicon2.png"
                        class="fdicon"
                      ></img>
                    </center>
                    <p class="gradf just">
                      Complete a comprehensive health assessment to identify
                      individual needs and preferences.
                    </p>
                  </div>
                </div>
              </div>
              <br></br>

              <div class="col-sm-4">
                <div class="blank2">
                  <div class="container">
                    <br></br>
                    <center>
                      <h5>
                        <b class="text-dark childf">
                          Create Your Wellness Plan
                        </b>
                      </h5>
                    </center>
                    <br></br>
                    <center>
                      <img
                        src="../style/img/resp/eldddicon3.png"
                        class="fdicon"
                      ></img>
                    </center>
                    <p class="gradf just">
                      Work with our experts to create a personalized wellness
                      plan tailored to the specific goals and challenges of each
                      senior family member.
                    </p>
                  </div>
                </div>
              </div>
              <br></br>

              <br></br>
            </div>
          </center>
          <br></br>
        </div>
        <br></br>
        <br></br>
      </div>

      <div class="bg3">
        <br></br>
        <div class="container">
          <center>
            {" "}
            <div class="row joinnn">
              <div class="col-sm-7">
                <h4>
                  <b class="brown brownjoin">Join the Respiro Family Today</b>
                </h4>
                <br></br>
                <p class="just yadjust brown">
                  Experience the power of collective wellness with Respiro. Sign
                  up now and embark on a journey to ensure optimal lung health
                  for your elderly loved ones. Together, let's breathe, thrive,
                  and create a healthier future for our seniors.
                </p>
              </div>

              <div class="col-sm-5">
                <img
                  src="../style/img/resp/eldpic2.png"
                  class="yngpic2 eldpic2"
                ></img>
              </div>
            </div>
          </center>
        </div>

        <center>
          {" "}
          <Link to="/Packagesummary/Elderpackage">
            {" "}
            <button class=" fprice custom-btn1 btn-3">
              <span class="btntexty bookingbtn">Book Now</span>
            </button>
          </Link>
        </center>
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default ElderlyLungcareplan;
