import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import Header from "../header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

import withPatientAuth from "../../middleware/withPatientAuth";
import {
  deleteFunction,
  setFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";

function SlotBooking() {
  const location = useLocation();
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState({});
  const [appointmentId, setAppointmentId] = useState();
  const { date, time, providerId, providerName, fees, phone } =
    location.state || {};
  const { patientId } = useContext(AuthContext);
  const time24Hour = moment(time, "h:mm A").format("HH:mm");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useState(() => {
    setAppointmentData({
      patientId,
      providerId,
      providerName,
      appointmentDate: date,
      startTime: time24Hour,
      status: "Booked",
      paymentDue: fees,
      paymentStatus: "Pending",
    });
  }, []);

  //   const addAppointment = () => {
  //     setFunction(appointmentData, "/appointment/set-appointment")
  //       .then((response) => {
  //         setFlag(false);
  //         localStorage.removeItem("appointmentProviderId");
  //         setData({ ...data, providerId: "" });
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           console.log(error.response.data);
  //           alert(error.response.data.error);
  //         }
  //         console.log(error);
  //       });
  //   };

  const handleClick = () => {
    const data = {
      amount: parseFloat(fees),
      patientId,
      transactionId: "T" + Date.now(),
      pageSelector: "provider",
    };

    localStorage.setItem("appointmentProviderId", providerId);
    localStorage.setItem("appointmentProviderName", providerName);
    localStorage.setItem("appointmentType", "provider");
    localStorage.setItem("appointmentDate", date);
    localStorage.setItem("appointmentTime", time24Hour);
    localStorage.setItem("appointmentFee", fees);


    try {
      setFunction(appointmentData, "/appointment/set-appointment")
        .then(async (response) => {
          localStorage.setItem("appointmentId", response.success.id);
          setAppointmentId(response.success.id);
          const paymentResponse = await setFunction(
            { ...data, appointmentId: response.success.id },
            "/payment/initiate-payment"
          );

          console.log(paymentResponse);
          if (
            paymentResponse.success &&
            paymentResponse.success.result &&
            paymentResponse.success.result.success === true
          ) {
            window.location.href =
              paymentResponse.success.result.data.instrumentResponse.redirectInfo.url;
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            alert(error.response.data.error);
          }
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      navigate("/failure");
    }
  };
  return (
    <>
      <Header />

      <div className="rhf-payment-container">
        <Card className="rhf-payment-card shadow-sm">
          <Card.Body>
            <Card.Title className="text-center mb-4">
              <h3>Details of Your Appointment:</h3>
            </Card.Title>
            {/* <Card.Text className="text-center text-muted">
            <span className="payment-amount">₹&nbsp;500</span> test
          </Card.Text> */}
            <Card.Text className="text-center text-muted">
              <p>Date: {date}</p>
            </Card.Text>
            <Card.Text className="text-center text-muted">
              <p>Slot Time: {time}</p>
            </Card.Text>
            <Card.Text className="text-center text-muted">
              <p>Doctor Name: {providerName}</p>
            </Card.Text>
            <Card.Text className="text-center text-muted">
              <p>
                Fees:<span className="payment-amount">₹ {fees}</span>{" "}
              </p>
            </Card.Text>
            <div className="text-center mt-4">
              {/* <Button
              variant="primary"
              className="payment-button"
              onClick={handleClick}
            >
              Make Payment
            </Button> */}
              <button
                class="custom-btn1 btn-3"
                style={{ marginLeft: "353px" }}
                onClick={handleClick}
              >
                <span>Book Now</span>
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default withPatientAuth(SlotBooking);
