import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import HospitalAffiliationTable from "./HospitalAffiliationTable";
import { getFunction } from "../../api/apiFunctions";
import Hospital from "./Hospital";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";

function HospitalAffiliation() {
  const { providerId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState({
    hospitalQuery: "",
    placeQuery: "",
  });
  const [searchResult, setSearchResult] = useState(null);
  const [showNewHospital, setShowNewHospital] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [data, setData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
const [selectedSpec, setSelectedSpec] = useState(null);


  useEffect(() => {
    getFunction(
      { providerId: providerId },
      "/hospital_affiliation/get-hospital-affiliation"
    )
      .then((response) => {
        const responseData = response.success.result;
        setData(responseData);
      })
      .catch((error) => {
        console.log(error);
        // setExistingAddress(false);
      });
  }, []);




  const getSearchResults = (e) => {
    e.preventDefault();
  
    getFunction(
      {
        hospitalName: searchTerm.hospitalQuery,
        place: searchTerm.placeQuery,
      },
      "/hospital/get-hospital-by-name"
    )
      .then((response) => {
        console.log(response);
        const responseData = response.success.result;
        setSearchResult(responseData);
        setShowNewHospital(false);
  
        if (responseData.length === 0) {
          toast.info("No hospital found");
        } else {
          const hospitalNames = responseData.map(hospital => hospital.name).join(", ");
          // toast.info(`Hospital found: ${hospitalNames}`);
        }
      })
      .catch((error) => {
        setSearchResult("");
        setShowNewHospital(true);
        toast.error("No hospital found. Please add your hospital/clinic.");
        console.log(error);
      });
  };
  

  const handleSearchQueryChange = (e) => {
    setSearchResult("")
    setShowNewHospital(false);
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setSearchTerm({ ...searchTerm, [fieldName]: fieldValue });
  };


  const handleShowConfirm = (id) => {
    setSelectedSpec({ id });  // Ensure you're setting the correct ID to be deleted
    setShowConfirm(true);     // Show the confirmation modal
  };

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
    toast.success("Hospital deleted successfully");
    handleClose();
  };

  const handleAddition = (newData, id) => {
    
    const newItem = {
      id,
      provider_id: providerId,
      hospital_id: newData.id,
      hospital_name: newData.hospital_name,
      addressline_1: newData.addressline_1,
      addressline_2: newData.addressline_2,
      locality: newData.locality,
      state: newData.state,
      pincode: newData.pincode
    };
    setData((data) => [...data, newItem]);
    setSearchResult(null);
    setShowSearch(false);
    toast.success("Hospital added successfully");
  };

  const handleClose = () => {
  setShowConfirm(false); // Close the confirmation modal
};

  const hospitalSearchUI = (
    <div class=" navbar-light ">
      <form class="">
        <div>
          {" "}
          <input
            class="form-control sefrom mr-sm-2"
            type="search"
            placeholder="Hospital Name"
            name="hospitalQuery"
            aria-label="Search"
            onChange={handleSearchQueryChange}
            value={searchTerm.hospitalQuery}
          /><br></br>
          <input
            class="form-control sefrom mr-sm-2"
            type="search"
            placeholder="Place (Optional)"
            name="placeQuery"
            onChange={handleSearchQueryChange}
            value={searchTerm.placeQuery}
            style={{marginTop:"10px"}}
          />
        </div>
       
        <div style={{marginTop:"25px"}}>
          <button
            class=" btntexty bookingbtn"
            type="submit"
            onClick={getSearchResults}
            disabled={!searchTerm.hospitalQuery}
          >
            Search
          </button>
          <button
            class=" btntexty bookingbtn"
            type="submit"
            style={{ "margin-left": "20px" }}
            onClick={() => {
              setShowSearch(false);
              setSearchResult(null);
              setSearchTerm({
                hospitalQuery: "",
                placeQuery: "",
              });
            }}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete the Hospital?</Modal.Body>
      <Modal.Footer>
        <button onClick={() => handleDelete(selectedSpec.id)}>Delete</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div class="contain">
            {modalConfirmUI}
          <ToastContainer />
      <div class="providerline">
        <br></br>
        <br></br>
        <br></br>
        <h1 class="providerprofileheading100 profileheading1">
          Hospital Affiliation
        </h1>
        <hr class=""></hr>
        <div className="d-flex">
          {data &&
            data.map((item) => {
              const updatedItem = {
                id: item.id,
                providerId: item.provider_id,
                hospitalId: item.hospital_id,
                hospitalName: item.hospital_name,
                addressline_1: item.addressline_1,
                addressline_2: item.addressline_2,
                place: item.locality,
                state: item.state,
                pincode: item.pincode,
              };
              return (
                <HospitalAffiliationTable
                  key={item.id}
                  data={updatedItem}
                  handleDelete={handleShowConfirm}
                />
              );
            })}
        </div>        

        <button class="qualificationbtn" onClick={() => setShowSearch(true)}>
          +
        </button>
        <br></br>
        <br></br>
        {showSearch ? hospitalSearchUI : null}
        <br></br>
        <br></br>
        <div className="d-flex">
        
          {searchResult &&
            searchResult.map((item) => (
              <Hospital
                key={item.id}
                data={item}
                handleAddition={handleAddition}
              />
            ))}
        </div>
        <div>
          {showSearch && showNewHospital && (
            <>
              <br></br>
              {/* <p>No hospital found. Please add your hospital/clinic.</p> */}
              <button
                class="qualificationbtn"
                onClick={() => navigate('/provider/add-hospital')}
              >
                Add New Hospital/Clinic
              </button>
            </>
          )}
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export default withProviderAuth(HospitalAffiliation);
