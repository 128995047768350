import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link, NavLink } from "react-router-dom";
import MusicianData from "../../data/MusicianData";
const Musiciancareplan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const items = MusicianData.filter(item => item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5 || item.id === 6 || item.id === 7
    || item.id === 8 || item.id === 9 || item.id === 10 || item.id === 11 || item.id === 12 || item.id === 13 || item.id === 14 || item.id === 15 || item.id === 16 || item.id === 17

  );
  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // Function to add the 'show' class to elements in the viewport
  function checkCards() {
    const cards = document.querySelectorAll('.careercardanim');
    cards.forEach(card => {
      if (isElementInViewport(card)) {
        card.classList.add('show');
      }
    });
  }

  // Run the checkCards function on scroll
  window.addEventListener('scroll', checkCards);

  // Run the checkCards function on page load (in case some cards are initially visible)
  window.addEventListener('load', checkCards);
  return (
    <>
      <Header></Header>
      <br></br>
      <div class="bg4">
        <div class="musicbnr musimg">
          <div class="container">
            <center>
              <h3 class="musiched mushead mus_head  cartexanim">
                Musician Respiratory Lung Care Health Package
              </h3>
            </center>
          </div>
        </div>
        <div class="container">
          <br></br>
          <p class="techptext">
            As a musician, your passion for creating beautiful melodies often
            involves long hours of practice and performance, which can impact
            your respiratory health. Our specialized Musician Respiratory Health
            Plan is designed to support you in maintaining optimal lung function
            and well-being, tailored to address the unique challenges faced by
            musicians.
          </p>
          <p class="techpbold musPlanDet">
            {" "}
            Here's what our Musician Respiratory Health Plan includes:
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="card musiccard  careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed mushed">
                      {" "}
                      Breath Control and Performance Enhancement
                    </h3>
                    <img
                      src="../style/img/resp/musimg1.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1 musSerText1">
                      Provide resources and techniques for improving breath
                      control during performances and practice sessions. Offer
                      guidance on proper breathing techniques to enhance musical
                      expression and endurance.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card musiccard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed mushed">
                      {" "}
                      Instrument Ergonomics Optimization
                    </h3>

                    <img
                      src="../style/img/resp/musimg2.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1 musptext1 musSerText1">
                      Promote proper posture and instrument positioning to
                      reduce strain on the respiratory system and enhance
                      breathing efficiency. Offer ergonomic accessories and
                      modifications tailored to different instruments to support
                      optimal playing posture and comfort.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card musiccard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed mushed">
                      {" "}
                      Indoor Air Quality Enhancement
                    </h3>
                    <img
                      src="../style/img/resp/musimg3.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1 musSerText1">
                      Raise awareness about the importance of indoor air quality
                      for musicians' respiratory health. Recommend air purifiers
                      and ventilation strategies to reduce exposure to indoor
                      pollutants and allergens in practice and performance
                      spaces.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card musiccard careercardanim ">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed mushed">
                      {" "}
                      Stress Management and Performance Anxiety Relief
                    </h3>

                    <img
                      src="../style/img/resp/musimg4.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1 musSerText1">
                      Provide resources and strategies for managing performance
                      anxiety and stress-related breathing difficulties. Offer
                      relaxation techniques such as breathing exercises,
                      meditation, and mindfulness to promote calmness and focus
                      during performances.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="card musiccard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed mushed">Healthy Lifestyle Promotion</h3>
                    <img
                      src="../style/img/resp/musimg5.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1 musSerText1">
                      Provide educational materials on maintaining a healthy
                      lifestyle to support overall respiratory health and
                      well-being. Offer tips on nutrition, hydration, and
                      physical activity tailored to the specific needs of
                      musicians.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card musiccard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed mushed"> Enhanced Features</h3>
                    <img
                      src="../style/img/resp/musimg6.png"
                      class="techimg"
                    ></img>
                    <p class="techptext1 musSerText1">
                      <b class="text-dark">
                        Personalized Breathing Assessment:
                      </b>
                      Access to personalized breathing assessments conducted by
                      respiratory health experts to identify individual needs
                      and tailor recommendations accordingly.<br></br>
                      <b class="text-dark">
                        Performance Venue Air Quality Evaluation:{" "}
                      </b>
                      Evaluation of air quality in performance venues to
                      identify potential respiratory health risks and provide
                      recommendations for mitigation.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div class="bg4">
        <img src="../style/img/resp/musicbg1.png" class="hiwbg"></img>
        <br></br>
        <br></br>
        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/mus1.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="mushead2">
                    {" "}
                    Breathing Control and Performance Enhancement
                  </h4>
                  <p class="musline">
                    Breath Trainer Device for Wind Instrument <br></br>Players
                    (One Time Purchase)
                  </p>
                  <p class="musline">
                    Online Breathing Technique Workshop for <br></br> Singers
                    (One Time Purchase)
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice musprup1">₹ 1800/-</h5>
                <h5 class="sprice musprup2">₹ 400/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container musbtnryt" key={item.id}>
                    {item.id === 1 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn1">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 2 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 musicbtn2 ">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/mus2.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="mushead2">Instrument Ergonomics Optimization</h4>
                  <p class="musline">
                    Musician's Chair with Lumbar Support
                  </p>

                  <p class="musline">
                    Adjustable Music Stand with Angle Control
                  </p>

                  <p class="musline">
                    Guitar Strap with Breathable <br />
                    Material for Comfort
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice musprup3">₹ 5500/-</h5>
                <h5 class="sprice musprup4">₹ 3200/-</h5>
                <h5 class="tprice musprup5">₹ 800/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container musbtnryt" key={item.id}>
                    {item.id === 3 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn3">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 4 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 musicbtn4">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 5 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" tprice tmprice custom-btn1 btn-3 musicbtn5">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/mus3.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="mushead2">Indoor Air Quality Enhancement</h4>
                  <p class="musline">
                    HEPA Air Purifier with Noise Reduction <br />
                    Technology for Practice Rooms
                  </p>

                  <p class="musline">
                    Air-Purifying Plants Specifically Selected for
                    <br></br>
                    Musicians (Set of 3)
                  </p>
                  <p class="musline">
                    Hygrometer and Thermometer Combo for <br /> Room Climate
                    Control
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice musprup6">₹ 6000/-</h5>
                <h5 class="sprice musprup7">₹ 1200/-</h5>
                <h5 class="sprice musprup8">₹ 750/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container musbtnryt" key={item.id}>
                    {item.id === 6 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn6">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 7 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 musicbtn7">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                    {item.id === 8 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn8">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/mus4.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="mushead2">
                    Stress Management and Performance Anxiety Relief
                  </h4>
                  <p class="musline">
                    Musician's Meditation App/website
                    <br /> Subscription with Breathing Exercises
                  </p>
                  <p class="musline">
                    Aromatherapy Diffuser with Essential <br />
                    Oils for Relaxation and Focus
                  </p>
                  <p class="musline">
                    Yoga for Musicians Online Classes with
                    <br /> Breathing Techniques
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice musprup9">₹ 800/year</h5>
                <h5 class="sprice musprup10">₹ 300/-</h5>
                <h5 class="tprice musprup11">₹ 900/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container musbtnryt" key={item.id}>
                    {item.id === 9 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn9">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 10 && (
                      <NavLink to={`/products/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 musicbtn10">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                    {item.id === 11 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn11">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/mus5.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="mushead2">Healthy Lifestyle Promotion</h4>
                  <p class="musline">
                    Organic Snack Box Subscription <br /> (Monthly) with
                    Nutrient-Rich Snacks
                  </p>
                  <p class="musline">
                    Fitness Tracker Smartwatch with Musician- <br /> friendly
                    Features and Lung Health Tracking
                  </p>
                  <p class="musline">
                    Personalized Nutrition Consultation Session <br />
                    Tailored toMusicians' Dietary Needs
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice musprup12">₹ 1500/month</h5>
                <h5 class="sprice musprup13">₹ 3000/-</h5>
                <h5 class="tprice musprup14">₹ 800/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container musbtnryt" key={item.id}>
                    {item.id === 12 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn12">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 13 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 musicbtn13">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                    {item.id === 14 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" tprice tmprice custom-btn1 btn-3 musicbtn14">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/mus6.png"
                  class="techcardimg1"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="mushead2">Enhanced Features </h4>
                  <p class="musline">
                    Personalized Breathing Assessment with a <br></br>Focus on
                    Musicians' Respiratory Needs
                  </p>
                  <p class="musline">
                    Performance Venue Air Quality Evaluation with
                    <br></br>
                    Acoustic and Lung Health Considerations
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice musprup15">₹ 1200/-</h5>
                <h5 class="sprice musprup16">₹ 2500/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container musbtnryt" key={item.id}>
                    {item.id === 15 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" fprice fmprice custom-btn1 btn-3 musicbtn15">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 16 && (
                      <NavLink to={`/music/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 musicbtn16">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg2">
        <br></br>
        <div class="container-fluid">
          <div class="card techlcard">
            <br></br>
            <div class="container">
              <center>
                <h4 class="techshed_mus">
                  Special Lung Care Package For Musicians{" "}
                </h4>
              </center>{" "}
              <br></br>
              <div class="row">
                <div class="col-sm-4">
                  <img src="../style/img/resp/sing1.png" class="lcardimg"></img>
                </div>
                <div class="col-sm-8">
                  <h5 class="mushead3">Monthly Diagnosis Plan</h5>
                  <br></br>
                  <div class="container">
                    <p class="techptext">
                      {" "}
                      Our Tailored Respiratory Care Bundle for Musicians is
                      designed to cater to the distinctive respiratory and
                      wellness requirements of musicians, especially those
                      engaged in playing wind instruments and vocal
                      performances. This extensive bundle encompasses specific
                      examinations aimed at guaranteeing peak lung performance
                      and general well-being, empowering you to showcase your
                      talents at the highest.
                    </p>
                    <p class="techlp">
                      Peak Flow Test
                      <br></br>
                      Spirometry Test
                      <br></br>
                      Lung Diffusion Capacity Test<br></br>
                      Voice Assessment and Laryngoscopy<br></br>
                      Consultation with Pulmonologist
                    </p>
                    <center>
                      <p class="mus_subhead">
                        {" "}
                        Monthly Diagnosis Plan&nbsp; &nbsp; &nbsp;{" "}
                        <b class="text-dark aprice price17"> ₹ 999/-</b>&nbsp;
                        &nbsp; &nbsp; &nbsp;
                        {items.map((item) => (
                          <div className="button-container" key={item.id}>
                            {item.id === 17 && (
                              <NavLink to={`/music/${item.id}`}>
                                <button class=" custom-btn1 btn-3 musicbtn17">
                                  <span class="btntexty btnfsm">Book Now</span>
                                </button>
                              </NavLink>
                            )}
                          </div>
                        ))}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
}

export default Musiciancareplan;