import React, { useEffect, useRef, useState, useContext } from "react";
import $ from "jquery";
import Homefull from "./Homefull";
import Homeresponsive from "./Homeresponsive";
import { Link, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { getAuthTokens } from "../../helpers/authFunctions";

const Homecard = () => {
  const [menuActive, setMenuActive] = useState(false);
  const { email, setEmail, setId } = useContext(AuthContext);
  const modalRef = useRef(null);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const videoRef = useRef(null);
  const [firstInteraction, setFirstInteraction] = useState(true);

  useEffect(() => {
    setEmail(localStorage.getItem("email"));
  }, [setEmail]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/get-token", "patient")
        .then(() => {
          setId(localStorage.getItem("patientId"));
          setEmail(localStorage.getItem("patientEmail"));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code, setId, setEmail]);

  const playVideo = () => {
    if (videoRef.current) {
      if (firstInteraction) {
        setFirstInteraction(false);
      } else {
        if (videoRef.current.paused) {
          videoRef.current.play().catch((error) => {
            console.error("Autoplay was prevented:", error);
          });
        }
      }
    }
  };

  const handleClick = () => {
    playVideo();
    const autoButton = document.getElementById("auto-button");
  };

  useEffect(() => {
    document.addEventListener("auto", handleClick);
    return () => {
      document.removeEventListener("auto", handleClick);
    };
  }, [handleClick]);

  const handleButtonClick = () => {
    // Handle modal functionality using React state
  };

  return (
    <>
      <div class="container">
        <br></br>
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item main-img active">
              <a href="#">
                <img
                  src="./style/img/banner/bbn1.png"
                  alt="Img0"
                  class="back_ground_img"
                />
              </a>
            </div>
            <div class="carousel-item main-img">
              <a href="#">
                <img
                  src="./style/img/banner/bbn2.png"
                  alt="Img0"
                  class="back_ground_img"
                />
              </a>
            </div>
            <div class="carousel-item main-img">
              <a href="#">
                <img
                  src="./style/img/banner/bbn3.png"
                  alt="Img0"
                  class="back_ground_img"
                />
              </a>
            </div>
          </div>
          <button
            class="carousel-control-prev btn"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <a
            class="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="boxs">
          <div class="bgm">
            <div class="box1">
              <div class="box boxIn2">
                <div>
                  <h2>RHF Joing</h2>
                  <div>
                    <a href="#">
                      <div>
                        <img src="./style/img/banner/rhfr1.png" alt="" />
                      </div>
                      <div>
                        <br />
                        <span class="view">see more</span>
                      </div>
                    </a>

                    <a href="#">
                      <div>
                        <img src="./style/img/banner/rhficu.png" alt="" />
                      </div>
                      <div>
                        <br />
                        <span class="view">see more</span>
                      </div>
                    </a>
                  </div>
                  <a class="seeOffer" href="#">
                    view your browsing history
                  </a>
                </div>
              </div>
              <div class="box boxIn4">
                <div>
                  <h2>Emergency Medical Care Rent @ Home</h2>

                  <div>
                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img
                              src="./style/img/banner/pulmonary care.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <span>
                              up to 50% - 70* | Above<s>₹1899</s>
                            </span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img
                              src="./style/img/banner/emergency care.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <span>
                              up to 50% - 70* | Above<s>₹1899</s>
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img
                              src="./style/img/banner/sleepstudy12.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <span>
                              up to 50% - 70* | Above<s>₹1899</s>
                            </span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img
                              src="./style/img/banner/COPD Patient Care1.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <span>
                              up to 50% - 70* | Above<s>₹1899</s>
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <a class="seeOffer" href="#">
                    See all offers
                  </a>
                </div>
              </div>
              <div class="box boxIn1">
                <div>
                  <h2>RHF Team</h2>
                  <div>
                    <a href="#">
                      <div>
                        <img src="./style/img/banner/RHF (1).png" alt="" />
                      </div>
                    </a>
                  </div>
                  <a class="seeOffer" href="#">
                    See all
                  </a>
                </div>
              </div>
              <div class="box boxButton hidden">
                <div>
                  <div class="last-sign-in">
                    <h2>Respiro Partnership</h2>
                    <br></br>
                  </div>
                  {/* <hr class="hr_color"/> */}
                  <div>
                    <a href="#">
                      <div>
                        <img src="./style/img/amazon/Partner.png" alt="" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <br />
              <div class="box boxIn1">
                <div>
                  <h2>
                    <br></br>
                  </h2>
                  <div>
                    <a href="#">
                      <div>
                        <img src="./style/img/amazon/Respiro.png" alt="" />
                      </div>
                    </a>
                  </div>
                  <a class="seeOffer" href="#">
                    See all offers
                  </a>
                </div>
              </div>
              <div class="box boxIn4">
                <div>
                  <h2>
                    <br></br>
                  </h2>

                  <div>
                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/Test.png" alt="" />
                          </div>
                          <div>
                            <span>Book a Test</span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/RHF.png" alt="" />
                          </div>
                          <div>
                            <span>Book a RHF</span>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img
                              src="./style/img/amazon/Pulmonologist.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <span>Book a Pulmonologist</span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img
                              src="./style/img/amazon/Therapist.png"
                              alt=""
                            />
                          </div>
                          <div>
                            <span>Book a Therapist</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <a class="seeOffer" href="#">
                    See all offers
                  </a>
                </div>
              </div>
              <div class="box boxIn4">
                <div>
                  <h2>Up to 70% off | Deals on Amazon Brands & more</h2>

                  <div>
                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box171.jpg" alt="" />
                          </div>
                          <div>
                            <span>Up to 70% off | Home & kitchen</span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box172.jpg" alt="" />
                          </div>
                          <div>
                            <span>Up to 70% off | Electronic accessories</span>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box173.jpg" alt="" />
                          </div>
                          <div>
                            <span>Up to 60% off | Daily essentials</span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box174.jpg" alt="" />
                          </div>
                          <div>
                            <span>Under ₹599 | Clothing, shoes & more</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <a class="seeOffer" href="#">
                    Prime Early | See all offers
                  </a>
                </div>
              </div>
              <div class="box boxIn4 hidden">
                <div>
                  <h2>Cases and covers for top smartphones</h2>

                  <div>
                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box181.jpg" alt="" />
                          </div>
                          <div>
                            <span>iPhone 14 | Starting ₹89</span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box182.jpg" alt="" />
                          </div>
                          <div>
                            <span>Samsung Galaxy S22 | Starting ₹79</span>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box183.jpg" alt="" />
                          </div>
                          <div>
                            <span>OnePlus Nord CE 3 Lite | Starting ₹79</span>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <div>
                            <img src="./style/img/amazon/box184.jpg" alt="" />
                          </div>
                          <div>
                            <span>Redmi 12C | Starting ₹79</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <a class="seeOffer" href="#">
                    See all offers
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <hr class="hr_color"/> */}

          <div class="box11">
            <div class="box boxIn4">
              <div>
                <h2>Diagnosis Plans</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img
                            src="./style/img/amazon/Pneumothorax.png"
                            alt=""
                          />
                        </div>
                        <div>
                          <span>Pneumothorax</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Lungcancer.png" alt="" />
                        </div>
                        <div>
                          <span>Lung cancer</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Pnemonia.png" alt="" />
                        </div>
                        <div>
                          <span>Pnemonia</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Bronchitis.png" alt="" />
                        </div>
                        <div>
                          <span>Bronchitis</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn4">
              <div>
                {/* <h2>Minimum 60% off | Amazon Brands & more</h2> */}
                <br></br>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img
                            src="./style/img/amazon/PulmonaryEdema.png"
                            alt=""
                          />
                        </div>
                        <div>
                          <span>Pulmonary Edema</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/COPD.png" alt="" />
                        </div>
                        <div>
                          <span>COPD</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Asthma.png" alt="" />
                        </div>
                        <div>
                          <span>Asthma</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/SleepApnea.png" alt="" />
                        </div>
                        <div>
                          <span>Sleep Apnea</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn1">
              <div>
                <h2>Discover Relief|Offer Zoon</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/fAds.png" alt="" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
          </div>

          <div class="box2 flight_tickets">
            <div class="container">
              <h3 class="home_text">
                <font color="204ea0">Respiratory Wellness Corner</font>
              </h3>
              <p>Explore Essential Insights for Better Lung Health</p>
              <hr></hr>
              <div class="row">
                <div class="col-sm-6">
                  <Link to="/provider/landing">
                    <img
                      className="home2bg"
                      src="./style/img/banner/bnner.png"
                      alt="First slide"
                    />
                  </Link>
                  <br></br>
                </div>
                <div class="col-sm-6">
                  <Link to="/rhf/landing">
                    <img
                      className="home2bg"
                      src="./style/img/banner/RHFjoin.png"
                      alt="First slide"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <hr class="hr_color"/> */}

          {/* <hr class="hr_color"/> */}
          {/* <div class="sixer_img">
                    <a href="#"><img src="./style/img/amazon/sixer.jpg" alt="sixer"/></a>
                </div> */}
          {/* <hr class="hr_color"/> */}
          <div class="box11">
            <div class="box boxIn4">
              <div>
                <h2> Lung Care Plans | Up to 60% off </h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Child.png" alt="" />
                        </div>
                        <div>
                          <span>Child</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Youngster.png" alt="" />
                        </div>
                        <div>
                          <span>Youngster</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Middleage.png" alt="" />
                        </div>
                        <div>
                          <span>Middleage</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Elderly.png" alt="" />
                        </div>
                        <div>
                          <span>Elderly</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn1">
              <div>
                <h2>
                  <br></br>
                </h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/ADS2.png" alt="" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn4">
              <div>
                <h2>Rehabilitation Plans</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Child1.png" alt="" />
                        </div>
                        <div>
                          <span>Child</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Youngster1.png" alt="" />
                        </div>
                        <div>
                          <span>Youngster</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Middleage1.png" alt="" />
                        </div>
                        <div>
                          <span>Middleage</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/elderly1.png" alt="" />
                        </div>
                        <div>
                          <span>elderly</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn4">
              <div>
                <h2>Great Freedpm Festival | Brands in focus</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box341.jpg" alt="" />
                        </div>
                        <div>
                          <span>Up to 50% off | Xiaomi Tvs</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box342.jpg" alt="" />
                        </div>
                        <div>
                          <span>Starting 10,999 | TCL TVS</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box343.jpg" alt="" />
                        </div>
                        <div>
                          <span>Up to 40% off | Samsung TVs</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box344.jpg" alt="" />
                        </div>
                        <div>
                          <span>Starting ₹23,990 | Hisense TVs</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
          </div>

          <div class="box11">
            <div class="box boxIn1">
              <div>
                <h2>
                  <br></br>
                </h2>

                <div>
                  <div>
                    <div>
                      <div>
                        <video controls autoplay controlsList="nodownload">
                          <source
                            src="./style/img/banner/homvideo.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn4">
              <div>
                <h2>Health Package</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Musicians.png" alt="" />
                        </div>
                        <div>
                          <span>Musician respiratory care health package</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Teacher.png" alt="" />
                        </div>
                        <div>
                          <span>
                            Teacher respiratory lung care health package
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/Performace.png" alt="" />
                        </div>
                        <div>
                          <span>
                            Performance artist respiratory lung care health
                            package
                          </span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img
                            src="./style/img/amazon/Constructionworker.png"
                            alt=""
                          />
                        </div>
                        <div>
                          <span>Constraction worker lung health plan</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn1">
              <div>
                <h2>Save extra with amaxon coupons</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box431.jpg" alt="" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
            <div class="box boxIn4">
              <div>
                <h2>Up to 70% off on home care, chocolates & more</h2>

                <div>
                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box441.jpg" alt="" />
                        </div>
                        <div>
                          <span>Laundry & cleaners</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box442.jpg" alt="" />
                        </div>
                        <div>
                          <span>Cooking ingredients</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box443.jpg" alt="" />
                        </div>
                        <div>
                          <span>Chocolates</span>
                        </div>
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <div>
                          <img src="./style/img/amazon/box444.jpg" alt="" />
                        </div>
                        <div>
                          <span>Baby care</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <a class="seeOffer" href="#">
                  See all offers
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homecard;