import { useState, useEffect, useContext } from "react";
import {
  setFunction,
  updateFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../context/authContext";
import Dropdown from "react-bootstrap/Dropdown";

const QualificationTable = (props) => {
  const { providerId } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [editView, setEditView] = useState(false);
  const [existingQual, setExistingQual] = useState(false);
  const [isOpen, setIsOpen] = useState(false); 

  const [data, setData] = useState({
    id: "",
    providerId: "",
    qualificationId: "",
    qualificationName: "",
    instituteName: "",
    procurementYear: "",
  });

  useEffect(() => {
    if (props.data && props.data.qualificationId !== undefined) {
      setExistingQual(true);
      setData({
        id: props.data.id,
        providerId: props.data.providerId,
        qualificationId: props.data.qualificationId,
        qualificationName: props.data.qualificationName,
        instituteName: props.data.instituteName,
        procurementYear: props.data.procurementYear,
      });
    } else {
      setEditView(true);
    }
    setData((prevData) => ({
      ...prevData,
      providerId: providerId,
    }));
  }, [props.data, providerId]);

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const regex = new RegExp(/^[\w\s,.-]*$/gm);
    let qualificationName = "";

    if (fieldName === "qualificationId" && fieldValue !== "Select Qualification") {
      qualificationName = props.qualification.find(
        (item) => item.id === fieldValue
      )?.qualification_name || "";
      setData({ ...data, [fieldName]: fieldValue, qualificationName });
      return;
    } else if (fieldName === "instituteName" && !regex.test(fieldValue)) {
      setErrors({ instituteName: "Invalid institute name" });
    } else if (fieldName === "procurementYear" && !regex.test(fieldValue)) {
      setErrors({ procurementYear: "Invalid year" });
    }

    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.qualificationName.trim() || data.qualificationId === "Select Qualification") {
      newErrors.qualificationName = "Qualification name is required";
      valid = false;
    }
    if (!data.instituteName.trim()) {
      newErrors.instituteName = "Institute name is required";
      valid = false;
    }
    if (!data.procurementYear.trim()) {
      newErrors.procurementYear = "Year is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      setFunction(data, "/provider/set-qualification")
        .then((response) => {
          toast.success("Qualification data saved successfully");
          setEditView(false);  
          setData({ ...data, id: response.data.success.id });
          setIsOpen(false);  
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.error);
          }
        });
    }
  };

  const updateQualification = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      updateFunction(data, "/provider/update-qualification")
        .then(() => {
          toast.success("Qualification data updated successfully");
          setEditView(false);  
          setIsOpen(false);  
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.error);
          }
        });
    }
  };

  const deleteQualification = (e) => {
    e.preventDefault();
    if (!data.id) {
      props.handleDelete(data.id);
      return;
    }

    deleteFunction(data, "/provider/delete-qualification")
      .then(() => {
        toast.success("Qualification data deleted successfully");
        setEditView(false);
        props.handleDelete(data.id);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.error);
        }
      });
  };

  const submitButtonHandler = (e) => {
    e.preventDefault();
    setEditView(true);
    
    if (editView) {
      if (existingQual) {
        updateQualification(e);
      } else {
        handleSubmit(e);
      }
    }
  
   
    setEditView(false); 
    setIsOpen(false);   
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); 
  };

  return (
    <>
      <ToastContainer />
      <tr>
        <td>
          <select
            disabled={!editView}
            name="qualificationId"
            onChange={changeHandler}
            value={data.qualificationId}
          >
            <option>Select Qualification</option>
            {props.qualification &&
              props.qualification.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.qualification_name}
                </option>
              ))}
          </select>
          {errors.qualificationName && (
            <div className="validation">{errors.qualificationName}</div>
          )}
        </td>
        <td>
          <input
            disabled={!editView}
            type="text"
            name="instituteName"
            onChange={changeHandler}
            value={data.instituteName}
            placeholder="Institute Name"
          />
          {errors.instituteName && (
            <div className="validation">{errors.instituteName}</div>
          )}
        </td>
        <td>
          <input
            disabled={!editView}
            type="text"
            name="procurementYear"
            onChange={changeHandler}
            value={data.procurementYear}
            placeholder="Year"
          />

          {errors.procurementYear && (
            <div className="validation">{errors.procurementYear}</div>
          )}
        </td>
        <td>
          <Dropdown show={isOpen}>
            <Dropdown.Toggle
              onClick={toggleDropdown}
              style={{ all: "initial", fontWeight: "800" }}
            >
              . . .
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={submitButtonHandler}>
                {editView ? "Submit" : "Edit"}
              </Dropdown.Item>
              <Dropdown.Item onClick={deleteQualification}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>




      </tr>
    </>
  );
};

export default QualificationTable;
