import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItem, delItem } from "../../redux/actions";
import RentDATA from "../../data/Rentdata";
import { FaCartShopping } from "react-icons/fa6";
import Header from "../header/Header";

function ProductDetail() {
  const [cartBtn, setCartBtn] = useState("Add to Cart");
  const { id } = useParams();
  const dispatch = useDispatch();

  // Find product by ID
  let product = RentDATA.find((item) => item.id === parseInt(id)); // Use parseInt to ensure matching IDs

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCart = (product) => {
    if (cartBtn === "Add to Cart") {
      dispatch(addItem(product));
      setCartBtn("Remove from Cart");
    } else {
      dispatch(delItem(product));
      setCartBtn("Add to Cart");
    }
  };

  const [activeImageSrc, setActiveImageSrc] = useState(
    product ? product.img : ""
  );

  const changeImage = (e) => {
    setActiveImageSrc(e.target.src);
  };

  // Function to calculate discounted price
  const calculateDiscountedPrice = (price, discountPercentage) => {
    const discountedPrice = price - (price * discountPercentage) / 100;
    return discountedPrice.toFixed(2); // Keep 2 decimal points
  };

  return (
    <>
      <Header />
      <main className="container mt-4">
        <div className="row">
          {/* Image column */}
          <div className="col-sm-12 col-md-6 mb-4">
            <div className="product-gallery">
              <div className="product-image">
                <img
                  className="active"
                  src={activeImageSrc}
                  alt="Product"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </div>
          </div>

          {/* Description column */}
          <div className="col-sm-12 col-md-6" style={{ paddingLeft: "30px" }}>
            {product && (
              <>
                <h1 style={{ fontWeight: "700", fontSize: "38px" }}>
                  {product.title}
                </h1>
                {/* Original price and discounted price */}
                {product.discountPercentage > 0 ? (
                  <div>
                    <h2>
                      <strike>₹{product.price}</strike>{" "}
                      <span style={{ color: "red" }}>
                        - {product.discountPercentage}%
                      </span>
                    </h2>
                    <h2>
                      ₹{calculateDiscountedPrice(product.price, product.discountPercentage)} /week
                    </h2>
                  </div>
                ) : (
                  <h2>₹{product.price} /week</h2> // Show only the original price if there's no discount
                )}
                <p className="description">{product.desc}</p>

                <button
                  onClick={() => handleCart(product)}
                  className="add-to-cart"
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    fontSize: "18px",
                  }}
                >
                  <FaCartShopping className="text-white" />
                  &nbsp;{cartBtn}
                </button>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export default ProductDetail;
