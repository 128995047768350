import React from "react";
import Header from "../header/Header";
function Upevents() {
    return (
        <>
            <Header></Header>
            <div class="teambg">

                <h1 style={{ marginLeft: "140px" }} >UPCOMING<br></br> EVENTS <br></br>AT <b className="evheadres">RESPIRO</b></h1>
                <br></br>

                <div class="container">
                    <div class="row">
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">
                                    <img class="eventimg imgtop"
                                        src="../style/img/footer/mother.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">Webinar on Respiratory  Health  for New Mothers</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}>Join us for a webinar focused on the importance 
                                        of respiratory health for new mothers and their
                                        babies.</p>
                                   



                                    <p style={{ textAlign: "center" }}><b className="text-dark">August 3, 2024</b><br></br> <center>3:00 PM - 4:00 PM</center>
                                        Via Google Meet</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>





                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg  imgtop"
                                        src="../style/img/footer/workers.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">Respiratory Health Workshop
                                        for Humanitarian Workers</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}>This workshop is designed for humanitarian workers, focusing 
                                        on maintaining respiratory health in
                                        challenging environments. </p>

                                    <p style={{ textAlign: "center", }}><b className="text-dark">August 19, 2024</b><br></br> 10:00 AM - 12:00 PM<br></br>
                                        Respiro Health Center, City</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg3  imgtop"
                                        src="../style/img/footer/doctors.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">Panel Discussion on Chronic
                                        Respiratory Diseases</b></h5>

                                    <p style={{ textAlign: "justify", textAlign: "center" }}> A panel of experts will discuss the impact of chronic respiratory 
                                        diseases such as asthma and COPD,
                                        and treatment options.</p>

                                    <p style={{ textAlign: "center" }}><b className="text-dark">September 7, 2024</b><br></br> 2:00 PM - 4:00 PM<br></br>
                                        Via Google Meet</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">


                                    <img class="eventimg imgtop"
                                        src="../style/img/footer/alzhemeirs.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">Webinar on Respiratory Health  and Alzheimer's</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}>Explore the connection between respiratory health and Alzheimer's disease.
                                    </p>
                                    

                                    <p style={{ textAlign: "center" }}><b className="text-dark">September 21, 2024</b><br></br> 11:00 AM - 12:00 PM<br></br>
                                        Via Google Meet</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>



                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg3 imgtop"
                                        src="../style/img/footer/seniors.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark"> Respiratory Health Screening for Seniors</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}>Free respiratory health screening for seniors, including lung
                                        function tests and consultations with respiratory specialists.</p>


                                    <p style={{ textAlign: "center" }}><b className="text-dark">August 19, 2024</b><br></br> 10:00 AM - 12:00 PM<br></br>
                                        Respiro Health Center, City</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg3 imgtop"
                                        src="../style/img/footer/artheritis.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">Managing Respiratory Issues in
                                        Arthritis Patients</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}> This session will cover respiratory challenges faced by arthritis patients
                                        and how to manage them effectively.</p>
                                  

                                    <p style={{ textAlign: "center" }}><b className="text-dark"> October 12, 2024</b><br></br> 3:00 PM - 4:00 PM<br></br>
                                        Via Google Meet</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">


                                    <img class="eventimg imgtop"
                                        src="../style/img/footer/nutrition.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}> <b className="text-dark">Nutrition and Respiratory Health
                                        Workshop</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}> Discover the link between nutrition and respiratory health.
                                        Learn about foods that boost lung health and how to incorporate them into your diet.</p>

                                    <p style={{ textAlign: "center" }}><b className="text-dark"> October 16, 2024</b><br></br> 1:00 PM - 3:00 PM<br></br>
                                        Respiro Health Center, City</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>




                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg3 imgtop"
                                        src="../style/img/footer/diabetic.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">Respiratory Health in Diabetic Patients</b></h5>
                                    <br></br>
                                    <p style={{ textAlign: "center", textAlign: "justify" }}>This webinar will focus on respiratory health concerns in diabetic
                                        patients and offer strategies for better lung care.</p>


                                    <p style={{ textAlign: "center" }}> <b className="text-dark">November 14, 2024</b><br></br> 10:00 AM - 11:00 AM<br></br>
                                        Via Google Meet</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg3 imgtop"
                                        src="../style/img/footer/copd.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark">COPD Awareness and Management  Workshop</b></h5>
                                    <br></br>
                                    <p style={{ textAlign: "center", textAlign: "justify" }}>  A comprehensive workshop on COPD, including early signs, management techniques, and patient support.
                                    </p>

                                    <p style={{ textAlign: "center" }}> <b className="text-dark">November 17, 2024</b><br></br> 2:00 PM - 5:00 PM<br></br>
                                        Respiro Health Center, City</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                    <br></br>
                    <div class="row">
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">


                                    <img class="eventimg imgtop"
                                        src="../style/img/footer/free check up.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark" >Community Outreach and Free Lung
                                        Health Check-ups</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}> Participate in our community outreach program and get a free lung health
                                        check-up. Engage with our team to learn about maintaining healthy lungs.</p>

                                    <p style={{ textAlign: "center" }}><b className="text-dark"> November 21, 2024</b><br></br> 9:00 AM - 4:00 PM<br></br>
                                        Various Community Centers</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg11 imgtop"
                                        src="../style/img/footer/aids.jpg"
                                    />
                                    <br></br>
                                    <h5 style={{ textAlign: "center", color: "black" }}><b className="text-dark"> Respiratory Health for HIV/AIDS Patients</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}>An informative session on respiratory health management for HIV/AIDS patients,
                                        including prevention of opportunistic infections.</p>


                                    <p style={{ textAlign: "center" }}> <b className="text-dark">December 1, 2024</b><br></br> 1:00 PM - 2:00 PM<br></br>
                                        Via Google Meet</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 ">
                            <div class="card drcard">
                                <div class="container">

                                    <img class="eventimg11 imgtop"
                                        src="../style/img/footer/workshop.jpg"
                                    />
                                    <br></br>

                                    <h5 style={{ textAlign: "center", color: "black" }}> <b className="text-dark">Accessible Respiratory Care Workshop</b></h5>

                                    <p style={{ textAlign: "center", textAlign: "justify" }}> A workshop dedicated to respiratory care for individuals with disabilities,
                                        focusing on accessibility and tailored treatments.
                                    </p>


                                    <p style={{ textAlign: "center" }}><b className="text-dark"> December 3, 2024</b><br></br> 11:00 AM - 12:00 PM<br></br>
                                        Respiro Health Center, City</p>
                                    <button class="eventbtn">
                                        <span>Sign Up Here</span>
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>
    );
}
export default Upevents;