import React, { useEffect, useState, useContext } from "react";
import {
  getFunction,
  setFunction,
  updateFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import moment from "moment";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import Pagination from "react-bootstrap/Pagination";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);
function AddCoordinator() {
  const navigate = useNavigate();
  const {isMobile } = useContext(AuthContext);
  const [filteredCoordinators, setFilteredCoordinators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [searchTerm, setSearchTerm] = useState("");
  const [coordinators, setCoordinator] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [locality, setLocality] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedProviderType, setSelectedProviderType] = useState('Rhf');
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    // id: null,
    firstName: "",
    lastName: "",
    gender: "",
    phone: "",
    email: "",
    coordinatorType: "",
    districtId: "",
    entityTypes:selectedProviderType,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {    
    getcoordinator();
  }, [selectedProviderType]);
  useEffect(() => {
    filterCoordinators();
  }, [searchTerm, coordinators]);

  const getcoordinator = () => {
    getFunction({entityTypes:selectedProviderType}, "/coordinator/get-all-coordinators")
      .then((response) => {
        const responseData = response.success.result;
        console.log(responseData);
        setCoordinator(responseData);
      })
      .catch((error) => { });
  };

  const filterCoordinators = () => {
    const filtered = coordinators.filter((coordi) =>
      coordi.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCoordinators(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCoordinators = filteredCoordinators.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // const totalPages = Math.ceil(filteredCoordinators.length / itemsPerPage);
  const renderPaginationItems = () => {
    const totalPages = Math.ceil(filteredCoordinators.length / itemsPerPage);
    const pageRange = 5; // Number of pages to display at once
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
    let endPage = Math.min(totalPages, startPage + pageRange - 1);
  
    if (endPage - startPage < pageRange - 1) {
      startPage = Math.max(1, endPage - pageRange + 1);
    }
  
    let items = [];
  
    // Previous button
    items.push(
      <Pagination.Prev 
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)} 
        disabled={currentPage === 1}
      />
    );
  
    // Page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  
    // Next button
    items.push(
      <Pagination.Next 
        key="next"
        onClick={() => handlePageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
      />
    );
  
    return items;
  };
  
  const handleProviderTypeChange = (event) => {
    setSelectedProviderType(event.target.value);
  };
  const getalldistrict = (state) => {
    getFunction({ state }, "/district/get-district-by-state")
      .then((response) => {
        const responseData = response.success.result;
        console.log(responseData);
        setDistricts(responseData);
        
      })
      .catch((error) => {
        console.log("Error fetching districts:", error);
       });
  };
  const getrequireddistrictco =(state,entityTypes) =>{
    getFunction({state,entityTypes},"/coordinator/require-district-coordinator")
    .then((response) => {
      const responseData = response.success.result;
      console.log(responseData);
      setDistricts(responseData);
    })
    .catch((error) => { 
      console.log("Error fetching districts:", error);
    });

  };
  const getallstates = () => {
    getFunction({}, "/district/get-all-states")
      .then((response) => {
        const responseData = response.success.result;
        console.log(responseData);
        setStates(responseData);
      })
      .catch((error) => { });
  };
  const getallRequireAreCoordinators = (districtId) => {
    getFunction({districtId}, "/coordinator/require-area-coordinator")
      .then((response) => {
        const responseData = response.success.result;
        console.log(responseData);
        setLocality(responseData);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    getcoordinator();
    getallstates();
    getallRequireAreCoordinators();
  }, []);
  const showAreacoordinators = (id) => {
    navigate(`/admin/ViewAreaCoordinators/${id}`);
  }
  const showUnits = (id) => {
    navigate(`/admin/ViewUnits/${id}`);
  }
const changeHandler = (e) => {
  setErrors({});
  const fieldName = e.target.name;
  const fieldValue = e.target.value;
  let errorMessage = "";

  // Validation logic
  if (fieldValue.trim() === "") {
    errorMessage = "This field is required";
  }

  if (fieldName === "firstName" && !validator.isAlpha(fieldValue)) {
    errorMessage = "Only alphabets allowed!";
  } else if (fieldName === "lastName" && !validator.isAlpha(fieldValue)) {
    errorMessage = "Only alphabets allowed!";
  } else if (fieldName === "email" && !validator.isEmail(fieldValue)) {
    errorMessage = "Invalid email address!";
  }
  setErrors((prevErrors) => ({
    ...prevErrors,
    [fieldName]: errorMessage,
  }));

  // Fetch districts when state changes
  if (fieldName === "state") {
    getalldistrict(fieldValue);
   //getrequireddistrictco(fieldValue,selectedProviderType)
  }
   // Call appropriate district function based on coordinatorType
   if (fieldName === "coordinatorType") {
    if (fieldValue === "Area") {
      getalldistrict(data.state); // Fetch all districts when 'Area' is selected
    } else if (fieldValue === "District") {
      getrequireddistrictco(data.state, selectedProviderType); // Fetch specific districts when 'District' is selected
    }
  }

  // Fetch localities when district changes
  if (fieldName === "districtId") {
    setData((prevData) => ({
      ...prevData,
      districtId: fieldValue,
    }));
    getallRequireAreCoordinators(fieldValue);
  } else {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  }
};


  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }

    if (!data.gender) {
      errors.gender = "Gender is required";
      valid = false;
    }

    if (!validator.isEmail(data.email)) {
      errors.email = "Email is required";
      valid = false;
    }
    if (!data.phone.trim() || !validator.isMobilePhone(data.phone, "en-IN")) {
      errors.phone = "Valid phone number is required";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };
  


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const updatedData = {
        ...data,
        entityTypes: selectedProviderType,  
      };

      setFunction(updatedData, "/coordinator/add-coordinator")
        .then((response) => {
         // Reset form and state
          setData({
            firstName: "",
            lastName: "",
            gender: "",
            phone: "",
            email: "",
            coordinatorType: "",
            districtId: "",
            entityTypes:selectedProviderType,
          });
          toast.success("Coordinator Added successfully");
          console.log(response.data);
          setShowForm(false);
          getcoordinator();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error);
          }
          console.log(error);
          toast.error(
            "An error occurred while submitting the form. Please try again later."
          );
        });
    }
  };
 
  const sendInvitation = (id, firstName, lastName, email) => {
    const inviteData = {
      email,
      firstName,
      lastName,
      cognitoSelector:"coordinator",
    };
    setFunction(inviteData, "/admin/send-invite")
      .then((response) => {
        toast.success("Coordinator Invited  successfully");
        // updateFunction(
        //   { id, status: "Invited" },
        //   "/rhf_member/update-rhf-member-status"
        // )
        //   .then((response) => {
        //     getRhfMembers();
        //     console.log(response.data);
        //   })
        //   .catch((error) => {
        //     if (error.response) {
        //       console.log(error.response.data);
        //       toast.error(error.response.data.error);
        //     }
        //     console.log(error);
        //     toast.error(
        //       "An error occurred while submitting the form. Please try again later."
        //     );
        //   });
        console.log(response.data);
        alert("Invitation sent.");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        }
        console.log(error);
        toast.error(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };
  const deleteHandler = (id) => {
    deleteFunction({ id }, "/coordinator/delete-coordinator")
      .then((response) => {
        toast.success("Deleted Rhf Member successfully");
        console.log(response.data);
        getcoordinator();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        }
        console.log(error);
        toast.error(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };
  const desktopView = () => (
 <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <h2 class="citext topfrom">First Name *</h2>
          <input
            type="text"
            name="firstName"
            value={data.firstName}
            tabIndex={1}
            onChange={changeHandler}
            placeholder="First Name"
            className="form-input textbox"
          />
          {errors.firstName && (
            <div className="validation">{errors.firstName}</div>
          )}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Gender *</h2>
          <select
            type="text"
            name="gender"
            tabIndex={3}
            onChange={changeHandler}
            value={data.gender.toLowerCase()}
            placeholder="Gender"
            className="form-input pl-0 text-dark textbox"
          >
            <option value="">--select--</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="unspecified">Unspecified</option>
          </select>
          {errors.gender && <div className="validation">{errors.gender}</div>}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Date of Birth *</h2>
          <input
            type="date"
            name="dob"
            tabIndex={5}
            onChange={changeHandler}
            value={data.dob}
            placeholder="Date of Birth"
            className="form-input textbox"
          />

          {errors.dob && <div className="validation">{errors.dob}</div>}
          <br></br> <br></br>
          <h2 class="citext topfrom">Cordinator Type *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="coordinatorType"
            placeholder="Cordinator Type"
            tabIndex={7}
            onChange={changeHandler}
            value={data.coordinatorType}
          >
            <option value="">--select--</option>
            <option >District</option>
            <option>Area</option>
          </select>
          {errors.coordinatorType && (
            <div className="validation">{errors.coordinatorType}</div>
          )}
          <br></br> <br></br>
          <h2 class="citext topfrom">Select District *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="districtId"
            placeholder="District Name"
            tabIndex={9}
            onChange={changeHandler}
            value={data.districtId}
          >
            <option value="">--Select District--</option>
            {districts && districts.map((item) => (
              <option key={item.id} value={item.id}>{item.district_name}</option>
            ))}
          </select>
          {errors.districtId && (
            <div className="validation">{errors.districtId}</div>
          )}

        </div>
        <div class="col-sm-6">
          <h2 class="citext topfrom">Last Name *</h2>
          <input
            type="text"
            name="lastName"
            tabIndex={2}
            onChange={changeHandler}
            value={data.lastName}
            placeholder="Last Name"
            className="form-input textbox"
          />
          {errors.lastName && (
            <div className="validation">{errors.lastName}</div>
          )}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Email *</h2>
          <input
            type="text"
            name="email"
            tabIndex={4}
            onChange={changeHandler}
            value={data.email}
            placeholder="Email"
            className="form-input textbox"
          />
          {errors.email && <div className="validation">{errors.email}</div>}
          <br></br><br></br>
          <h2 class="citext topfrom">Phone Number *</h2>
          <input

            type="text"
            name="phone"
            tabIndex={6}
            onChange={changeHandler}
            value={data.phone}
            placeholder="Phone"
            className="form-input textbox"
          />
          {errors.phone && (
            <div className="validation">{errors.phone}</div>
          )}
          <br></br>

          <br></br>
          <h2 class="citext topfrom">Select State *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="state"
            placeholder="State"
            tabIndex={8}
            onChange={changeHandler}
            value={data.state}
          >
            <option value="">--Select State--</option>
            {states.map((item) => (
              <option key={item.state_id} value={item.state}>
                {item.state}
              </option>
            ))}
          </select>
          <br /><br />
          {data.coordinatorType === "Area" && (
          <>
            <h2 className="citext topfrom">Select Locality *</h2>
            <select
              className="form-input pl-0 text-dark textbox"
              name="pincode"
              placeholder="Pincode"
              tabIndex={10}
              onChange={changeHandler}
              value={data.pincode}
            >
              <option value="">--Select Locality--</option>
              {locality.map((item) => (
                <option key={item.id} value={item.pincode}>
                  {item.locality}
                </option>
              ))}
            </select>
          </>
        )}
          {/* <h2 class="citext topfrom">Select Pincode *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="pincode"
            placeholder="Pincode"
            tabIndex={10}
            onChange={changeHandler}
            value={data.pincode}
          >
             {locality.map((item) => (
              <option key={item.id} value={item.pincode}>
                {item.locality}
              </option>
            ))}
          </select> */}

          {errors.coordinatorType && (
            <div className="validation">{errors.coordinatorType}</div>
          )}
        </div>
      </div>
      <br></br>
      <center>
        <button onClick={handleSubmit}>Submit</button>
        <button style={{ marginLeft: "10px" }} onClick={() => setShowForm(false)}>
          Close
        </button>
      </center>
    </div>
  );

  const mobileView = () => (
    <div class="container">
      <div class="row">
        <div class="col-sm-6" style={{ marginLeft: "-26px" }}>
          <h2 class="citext topfrom">First Name *</h2>
          <input
            type="text"
            name="firstName"
            value={data.firstName}
            tabIndex={1}
            onChange={changeHandler}
            placeholder="First Name"
            className="form-input textbox"
          />
          {errors.firstName && (
            <div className="validation">{errors.firstName}</div>
          )}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Last Name *</h2>
          <input
            type="text"
            name="lastName"
            tabIndex={2}
            onChange={changeHandler}
            value={data.lastName}
            placeholder="Last Name"
            className="form-input textbox"
          />
          {errors.lastName && (
            <div className="validation">{errors.lastName}</div>
          )}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Gender *</h2>
          <select
            type="text"
            name="gender"
            tabIndex={3}
            onChange={changeHandler}
            value={data.gender.toLowerCase()}
            placeholder="Gender"
            className="form-input pl-0 text-dark textbox"
          >
            <option value="">--select--</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="unspecified">Unspecified</option>
          </select>
          {errors.gender && <div className="validation">{errors.gender}</div>}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Email *</h2>
          <input
            type="text"
            name="email"
            tabIndex={4}
            onChange={changeHandler}
            value={data.email}
            placeholder="Email"
            className="form-input textbox"
          />
          {errors.email && <div className="validation">{errors.email}</div>}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Date of Birth *</h2>
          <input
            type="date"
            name="dob"
            tabIndex={5}
            onChange={changeHandler}
            value={data.dob}
            placeholder="Date of Birth"
            className="form-input textbox"
          />
          {errors.dob && <div className="validation">{errors.dob}</div>}
        </div>
        
        <div class="col-sm-6" style={{ marginLeft: "-26px" }}>
          <br></br>
          <h2 class="citext topfrom">Phone Number *</h2>
          <input
            type="text"
            name="phone"
            tabIndex={6}
            onChange={changeHandler}
            value={data.phone}
            placeholder="Phone"
            className="form-input textbox"
          />
          {errors.phone && <div className="validation">{errors.phone}</div>}
          <br></br>
          <br></br>
          <h2 class="citext topfrom">Cordinator Type *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="coordinatorType"
            placeholder="Cordinator Type"
            tabIndex={7}
            onChange={changeHandler}
            value={data.coordinatorType}
          >
            <option value="">--select--</option>
            <option>District</option>
            <option>Area</option>
          </select>
          {errors.coordinatorType && (
            <div className="validation">{errors.coordinatorType}</div>
          )}
          <br></br> <br></br>
          <h2 class="citext topfrom">Select State *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="state"
            placeholder="State"
            tabIndex={8}
            onChange={changeHandler}
            value={data.state}
          >
            <option value="">--Select State--</option>
            {states.map((item) => (
              <option key={item.state_id} value={item.state}>
                {item.state}
              </option>
            ))}
          </select>
          <br />
          <br />
          <h2 class="citext topfrom">Select District *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="districtId"
            placeholder="District Name"
            tabIndex={9}
            onChange={changeHandler}
            value={data.districtId}
          >
            <option value="">--Select District--</option>
            {districts &&
              districts.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.district_name}
                </option>
              ))}
          </select>
          {errors.districtId && (
            <div className="validation">{errors.districtId}</div>
          )}
        </div>
        {data.coordinatorType === "Area" && (
          <>
            <h2 className="citext topfrom">Select Locality *</h2>
            <select
              className="form-input pl-0 text-dark textbox"
              name="pincode"
              placeholder="Pincode"
              tabIndex={10}
              onChange={changeHandler}
              value={data.pincode}
            >
              <option value="">--Select Locality--</option>
              {locality.map((item) => (
                <option key={item.id} value={item.pincode}>
                  {item.locality}
                </option>
              ))}
            </select>
          </>
        )}
        {/* <h2 class="citext topfrom">Select Pincode *</h2>
          <select
            className="form-input pl-0 text-dark textbox"
            name="pincode"
            placeholder="Pincode"
            tabIndex={10}
            onChange={changeHandler}
            value={data.pincode}
          >
             {locality.map((item) => (
              <option key={item.id} value={item.pincode}>
                {item.locality}
              </option>
            ))}
          </select> */}
        {errors.coordinatorType && (
          <div className="validation">{errors.coordinatorType}</div>
        )}
      </div>

      <br></br>
      <center>
        <button onClick={handleSubmit}>Submit</button>
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => setShowForm(false)}
        >
          Close
        </button>
      </center>
    </div>
  );

 const addNewMemberUi = () => (isMobile ? mobileView() : desktopView());

  return (
    <div class="contain">
      <ToastContainer />
      <div class="providerline">
        <br></br> <br></br> <br></br>
        <h1 class=" providerprofileheading100 profileheading1">Coordinator</h1>
        <hr class="providerline"></hr>
        <div className="col-sm-12">
        <h6 className="mb-2 pb-1"> Type: </h6>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="providerType"
            id="Rhf"
            value="Rhf"
            checked={selectedProviderType === "Rhf"}
            onChange={handleProviderTypeChange}
          />
          <label className="form-check-label" htmlFor="Rhf">
          Rhf
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="providerType"
            id="Therapist"
            value="Therapist"
            checked={selectedProviderType === "Therapist"}
            onChange={handleProviderTypeChange}
          />
          <label className="form-check-label" htmlFor="Therapist">
          Therapist
          </label>
        </div>
        </div>
        <div className="col-sm-10">
        <input
              type="text"
              placeholder="Search coordinators by name"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control mb-3"
            />
             <div style={{ overflowX: "scroll" }}>
          <table>
            
            <thead>
              <th>S.No</th>
              <th>First Name</th>
              <th>Gender</th>
              <th>Dob</th>
              <th>Email</th>
              <th>Phone</th>
              <th>District</th>
              <th>coordinatorType</th>
              <th>Action</th>
            </thead>




            <tbody>
              {currentCoordinators.length > 0 ? (
                currentCoordinators.map((item,index) => (
                  <tr key={item.id}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    <td>{item.first_name} &nbsp;</td>
                    <td>
                      {item.gender.charAt(0).toUpperCase() +
                        item.gender.slice(1)}
                    </td>
                    <td>{moment(item.dob).format("DD/MM/YYYY")}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.district_name}</td>
                    <td>{item.coordinator_type}</td>

                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{ all: "initial", fontWeight: "800", padding: "0px 10px" }}
                        >
                          . . .
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => deleteHandler(item.id)}>
                            Delete Member
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              sendInvitation(
                                item.id,
                                item.first_name,
                                item.last_name,
                                item.email
                              )
                            }
                            disabled={item.status === "Invited"}
                          >
                            Send Invite
                          </Dropdown.Item>

                          {item.coordinator_type === "District" ? (
                            <Dropdown.Item onClick={() => showAreacoordinators(item.id)} >
                              View Area Coordinators
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => showUnits(item.id)}>
                              Show Units
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) :(
                <tr>
                    <td colSpan="3">Not available.</td>
                  </tr>
              )}
            </tbody>
          </table>
          </div>
          <br></br>
          <Pagination>{renderPaginationItems()}</Pagination>

          <br></br>
          <button class="qualificationbtn" onClick={() => setShowForm(true)}>
            +
          </button>
          <br></br>
          <br></br>
          {showForm && addNewMemberUi()}
        </div>
      </div>
    </div>
  );
}
export default AddCoordinator
