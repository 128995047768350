import React from "react";
import Header from "../header/Header";
function Team() {
  return (
    <>
      <Header></Header>

      <div class="teambg">
        <center>
          <div class="team-heading">
            <br></br>
            <h1 class="text-white">TEAM</h1>
          </div>
        </center>

        <div className="container">
          <br></br>
          <h1 style={{ fontWeight: "700", fontSize: "30px" }}>BOARD</h1>
          <br></br>
          <h2
            style={{ fontWeight: "600", fontSize: "25px", marginTop: "30px" }}
          >
            Subin Pappachen- Founder And President
          </h2>
          <br></br>

          <div class="row">
            <div class="col-sm-4">
              <div>
                <img class="sir" src="../style/img/footer/sir.png" />
              </div>
            </div>
            <div class="col-sm-8">
              <p
                style={{
                  fontSize: "17px",
                  textAlign: "justify",
                  fontFamily: "Times New Roman",
                }}
              >
                Subin Pappachen is a health tech entrepreneur and investor,
                primarily based in Bengaluru, India.He is the founder of
                RESPIRO, a cloud-based lung care platform aimed at transforming
                respiratory healthcare. He is also the co-founder and director
                at Pedro Labs, Infosil Technolabs, and Silmed Scientific.
                <br></br>
                <br></br>Pedro Labs focuses on healthcare, pharmaceuticals, and
                medical devices, including brands like Respiro and Onco. Infosil
                Technolabs provides IT services and consulting, particularly in
                biotech, healthcare, and pharmaceutical industries. Silmed
                Scientific specializes in global regulatory affairs consulting,
                helping pharmaceutical companies navigate drug development and
                approval processes.
                <br></br>
                <br></br>
                Through these initiatives, Subin aims to enhance healthcare
                accessibility and affordability by leveraging advanced
                technologies and innovative solutions. His work is driven by a
                commitment toimproving healthcare systems globally through
                collaboration and expertise.
              </p>
            </div>
          </div>

          <h2
            style={{ fontWeight: "600", fontSize: "25px", marginTop: "30px" }}
          >
            Lini Subin- Founder And Managing Director
          </h2>
          <br></br>

          <div class="row">
            <div class="col-sm-8">
              <div>
                <p
                  style={{
                    fontSize: "17px",
                    textAlign: "justify",
                    fontFamily: "Times New Roman",
                  }}
                >
                  Lini Subin is a distinguished regulatory affairs professional
                  with significant expertise in the pharmaceutical and
                  healthcare industries. As the CEO and Senior Regulatory
                  Affairs Consultant at Silmed Scientific, she oversees global
                  regulatory affairs consulting, helping pharmaceutical
                  companies navigate the complex processes involved in drug
                  development and approval. Lini is known for her deep knowledge
                  of regulatory requirements across various international
                  markets, including Europe and the FDA.
                </p>

                <p class="teamsubh">Her professional journey includes:</p>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "17px",
                    textAlign: "justify",
                    fontFamily: "Times New Roman",
                  }}
                >
                  <b class="text-dark">Leadership and Consulting:</b>
                  <br></br> Lini provides strategic guidance on regulatory
                  processes and product development, ensuring compliance with
                  international standards.<br></br>
                  <b class="text-dark">Innovation and Healthcare:</b>
                  <br></br> She is committed to advancing healthcare through
                  innovative regulatory solutions and is actively involved in
                  health tech and ed tech initiatives.<br></br>
                  <b class="text-dark">Professional Engagement:</b> <br></br>
                  Lini frequently shares insights and developments in regulatory
                  affairs, contributing to the professional community through
                  webinars and posts on platforms like LinkedIn. In addition to
                  her professional accomplishments, Lini balances her career
                  with her personal life as a mother of four.
                </p>
                <br></br>
              </div>
            </div>

            <div class="col-sm-4">
              <img class="mam" src="../style/img/footer/mam.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Team;
