import React from "react";
import Oproduct from "./Oproduct";
import Orent from "./Orent";

function O2() {
  return (
    <>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <br></br>
                <br></br>
                <br></br>
                <img
                  class="d-block rhfimg w-100 vip"
                  src="./style/img/banner/o2c.png"
                  alt="First slide"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-2"></div>
      </div>
      <br></br> <br></br>
      <br></br> <br></br>
      <Oproduct></Oproduct>
      <br></br> <br></br>
      <Orent></Orent>
    </>
  );
}

export default O2;
