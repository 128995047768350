const ArtistData = [
  {
    id: 1,
    title: "Vocal Warm-up kit",
    price: 200,
    desc: "A comprehensive kit designed to help performers warm up their voices and practice essential breathing exercises.",
    img: "../style/img/resp/artpicone.png",
  },
  {
    id: 2,
    title: "Online Vocal Performance Technique Workshop",
    price: 300,
    desc: " An online workshop focused on teaching advanced vocal performance techniques.",
    img:"../style/img/resp/artistbg2.png",
  },

  {
    id: 3,
    title: "Personalized Vocal Coaching Session to Improve Breath Control and Projection",
    price: 900,
    desc: " One-on-one vocal coaching sessions tailored to individual needs.",
    img: "../style/img/resp/artistbg3.png",
  },

  {
    id: 4,
    title: "Ergonomic Performance Chair with Lumbar Support",
    price: 4000,
    desc: " An ergonomic chair designed to support performers during practice and performance.",
    img: "../style/img/resp/artistbg4.png",
  },

  {
    id: 5,
    title: "Portable Stage Mat for Comfortable Movement",
    price: 1000,
    desc:" A portable mat to provide comfort and support during performances.", 
    img: "../style/img/resp/artistbg5.png",  },
  {
    id: 6,
    title: "Customized Stage Setup Consultation to Optimize Breathing Space and Movement",
    price: 750,
    desc: "A consultation service to optimize stage setup for better breathing and movement.",
    img: "../style/img/resp/artistbg6.png",  },
  {
    id: 7,
    title: "Stage Air Puriﬁer with HEPA Filter and Quiet Operation",
    price: 6000,
    desc: "An air purifier designed to improve air quality on stage. ",
    img: "../style/img/resp/artistbg7.png",  },
  {
    id: 8,
    title: "Oxygen-Enriched Air Generator for Backstage Use",
    price: 4000,
    desc: " A generator that enriches the air with oxygen for use backstage.",
    img: "../style/img/resp/artistbg8.png",  },
  {
    id: 9,
    title: "Portable Air Quality Monitor for On-stage Performance",
    price: 1500,
    desc: "A portable device to monitor air quality on stage.",
    img: "../style/img/resp/artistbg9.png",  },
  {
    id: 10,
    title: "Meditation App Subscription with Stress-Relief  Techniques",
    price: 700,
    desc: " A subscription to a meditation app tailored for performers",
    img: "../style/img/resp/artistbg10.png",  },
  {
    id: 11,
    title: "Aroma therapy Diffuser with Relaxing Essential Oils for Backstage Relaxation",
    price: 1200,
    desc: "An aromatherapy diffuser to create a relaxing backstage environment.",
    img: "../style/img/resp/artistbg11.png",  },

  {
    id: 12,
    title: "Performance Anxiety Relief Workshop with Breathing Techniques",
    price: 1500,
    desc: " A workshop focused on techniques to manage performance anxiety.",
    img: "../style/img/resp/artistbg12.png",  },
  {
    id: 13,
    title: "Organic Snack Box Subscription (Monthly) with Performer-friendly Snacks",
    price: 1500,
    desc: " A monthly subscription box with healthy, organic snacks tailored for performers.",
    img: "../style/img/resp/artistbg13.png",  },
  {
    id: 14,
    title: "  Fitness Tracker Smartwatch with Performance oriented Features",
    price: 3000,
    desc: " A smartwatch designed to track fitness and performance metrics.",
    img: "../style/img/resp/artistbg14.png",  },
  {
    id: 15,
    title: "Personalized Nutrition Consultation Session Tailored to Performers' Dietary Needs",
    price: 800,
    desc: " One-on-one nutrition consultation tailored to the dietary needs of performers.",
    img: "../style/img/resp/artistbg15.png",  },
    
  {
    id: 16,
    title: " Personalized Breathing Assessment with a Focus on Musicians' Respiratory Needs",
    price: 1200,
    desc: " A detailed breathing assessment tailored for performance artists.",
    img: "../style/img/resp/artistbg16.png",  },
  {
    id: 17,
    title: "Stage Environment Evaluation for Air Quality and Respiratory Health Considerations",
    price: 2500,
    desc: " A detailed breathing assessment tailored for performance artists.",
    img: "../style/img/resp/artistbg17.png",  },
  {
    id: 18,
    title: " Personalized Nutrition Consultation Session Tailored to Performers' Dietary Needs",
    price: 500,
    desc: "One-on-one nutrition consultation tailored to the dietary needs of performers.",
    img: "../style/img/resp/artistbg18.png",  },
  {
    id: 19,
    title: "Special Lung Care Package For Performers",
    price: 999,
    desc:  "This comprehensive plan is designed to meet the unique respiratory health needs of performers, providing regular monitoring and advanced diagnostic tests.",
    img: "../style/img/resp/artistbg19.png",  },
  
];

export default ArtistData;
