import Header from "../header/Header"
export default function Newsletter() {
    return (
        <>
        <Header></Header>
            <div class="container-fluid teambg">
                <div class="conatiner">
                    <h3 class=" mainprivacyhead">
                        <b class="text-dark privhd">Newsletter Signup</b>
                    </h3>
                    <br></br>
                    <p class=" termsp1 privj">
                        Stay Informed and Connected with Respiro Healthcare.
                        Join our community and be the first to receive the latest updates, health tips, and exclusive offers from Respiro Healthcare.
                    </p>
                    <p class=" termsp1 privj">
                        Our newsletter delivers valuable information right to your inbox, including:<br></br>
                        Health and Wellness Tips: Expert advice on respiratory health, disease prevention, and wellness strategies.<br></br>
                        Service Updates: Be the first to know about new services, special offers, and advancements at Respiro.<br></br>
                        Exclusive Content: Access to health articles, educational resources, and insider insights.<br></br>
                        Event Announcements: Information about upcoming events, webinars, and health workshops.<br></br>
                    </p>
                    <div class="lettersignup">Signup today</div><br></br><br></br>
                    <div class="container">
                        <form>
                            <div class="form-group">
                                <label for="name" class="text-dark">Name:</label>
                                <input type="text" id="name" name="name" required />
                            </div>
                            <div class="form-group">
                                <label for="email" class="text-dark">Email:</label>
                                <input  type="email" id="email" name="email" required />
                            </div>
                           
                            <button type="submit">Register</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}