import Header from "../header/Header";

export default function Terms() {
  return (
    <>
    <Header></Header>
      <div class="container-fluid teambg">
      <div class="container">
        <b class="text-dark">
          <h3 class=" mainprivacyhead ">Terms and Conditions</h3>
        </b>
        <p>
          <strong>Effective Date: 18/07/2024</strong>
        </p>
        <p class="privj">
          Welcome to Respiro Healthcare. These Terms and Conditions govern your
          use of our website (https://respirohealthcare.com/), our services, and
          any other platforms or services provided by Respiro Healthcare. By
          accessing or using our website and services, you agree to be bound by
          these Terms. If you do not agree with these Terms, please do not use
          our website or services.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">1. Acceptance of Terms</b>
        </h6>
        <p class="termsp privj">
          By accessing and using our website and services, you accept and agree
          to be bound by these Terms, our Privacy Policy, and any additional
          terms and conditions that may apply to specific sections of the
          website or to services offered on or through the website.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">2. Changes to Terms</b>
        </h6>
        <p class="termsp privj">
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting on our website. Your
          continued use of the website and services after any such changes
          constitutes your acceptance of the new Terms. Please review these
          Terms periodically for updates.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">3. Use of Our Website and Services</b>
        </h6>
        <h6 class="subprivacyhead">3.1 Eligibility</h6>
        <p class="termsp privj">
          You must be at least 12 years old to use our website and services. By
          using our website and services, you represent and warrant that you are
          at least 12 years old and have the legal capacity to enter into these
          Terms.
        </p>

        <h6 class="subprivacyhead">3.2 User Accounts</h6>
        <p class="termsp privj">
          To access certain features of our website and services, you may be
          required to create an account. You agree to:
        </p>
        <p class="termsp privj">
          {" "}
          <ul>
            <li>
              Provide accurate, current, and complete information during the
              registration process.
            </li>
            <li>Maintain and promptly update your account information.</li>
            <li>Keep your password secure and confidential.</li>
            <li>
              Notify us immediately of any unauthorized use of your account or
              any other security breach.
            </li>
            <li>
              You are responsible for all activities that occur under your
              account. We are not liable for any loss or damage arising from
              your failure to comply with these obligations.
            </li>
          </ul>
        </p>

        <h6 class="subprivacyhead">3.3 Prohibited Conduct</h6>
        <p class="termsp privj">
          You agree not to engage in any of the following prohibited activities:
        </p>
        <p class="termsp privj">
          {" "}
          <ul>
            <li>
              Using our website or services for any illegal purpose or in
              violation of any local, state, national, or international law.
            </li>
            <li>
              Posting or transmitting any content that is unlawful, defamatory,
              obscene, pornographic, offensive, or otherwise objectionable.
            </li>
            <li>
              Attempting to interfere with, compromise the system integrity or
              security, or decipher any transmissions to or from the servers
              running the website and services.
            </li>
            <li>
              Collecting or harvesting any personally identifiable information,
              including account names, from the website.
            </li>
            <li>
              Impersonating another person or misrepresenting your affiliation
              with a person or entity.
            </li>
            <li>
              Using the website and services to distribute unsolicited
              promotional or commercial content, or any other unwanted
              solicitation.
            </li>
            <li>
              Using our website or services in any manner that could disable,
              overburden, damage, or impair the site or interfere with any other
              party's use and enjoyment of the website and services.
            </li>
          </ul>
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">4. Intellectual Property</b>
        </h6>
        <h6 class="subprivacyhead">4.1 Ownership</h6>
        <p class="termsp privj">
          All content on our website and services, including but not limited to
          text, graphics, logos, icons, images, audio clips, video clips,
          digital downloads, data compilations, and software, is the property of
          Respiro Healthcare or its content suppliers and is protected by
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>

        <h6 class="subprivacyhead">4.2 License and Access</h6>
        <p class="termsp privj">
          Respiro Healthcare grants you a limited, non-exclusive,
          non-transferable, non-sublicensable license to access and use the
          website and services for your personal, non-commercial use. This
          license does not include any resale or commercial use of the website
          or its contents; any collection and use of any product listings,
          descriptions, or prices; any derivative use of the website or its
          contents; any downloading or copying of account information for the
          benefit of another merchant; or any use of data mining, robots, or
          similar data gathering and extraction tools.
        </p>

        <h6 class="subprivacyhead">4.3 Trademarks</h6>
        <p class="termsp privj">
          The trademarks, logos, service marks, and trade names (collectively
          the "Trademarks") displayed on our website are registered and
          unregistered Trademarks of Respiro Healthcare and its affiliates.
          Nothing contained on the website should be construed as granting any
          license or right to use any Trademark without our written permission.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">5. Medical Disclaimer</b>
        </h6>
        <h6 class="subprivacyhead">5.1 No Medical Advice</h6>
        <p class="termsp privj">
          The content provided on our website, including text, graphics, images,
          and other material, is for informational purposes only and is not
          intended as a substitute for professional medical advice, diagnosis,
          or treatment. Always seek the advice of your physician or other
          qualified health provider with any questions you may have regarding a
          medical condition.
        </p>

        <h6 class="subprivacyhead">5.2 No Doctor-Patient Relationship</h6>
        <p class="termsp privj">
          Your use of our website and services does not establish a
          doctor-patient relationship. Communications through our website,
          including the use of email, messaging, or other forms of
          communication, do not create a doctor-patient relationship.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">6. Payment and Subscription</b>
        </h6>
        <h6 class="ubprivacyhead">6.1 Payment Terms</h6>
        <p class="termsp privj">
          All fees for services provided by Respiro Healthcare are due at the
          time of purchase unless otherwise agreed upon. You agree to provide
          accurate and complete payment information and authorize us to charge
          your payment method for the fees and any applicable taxes.
        </p>

        <h6 class="subprivacyhead">6.2 Subscriptions</h6>
        <p class="termsp privj">
          Certain services may be offered on a subscription basis. If you
          purchase a subscription, your subscription will automatically renew at
          the end of each term unless you cancel it. You may cancel your
          subscription at any time, and the cancellation will take effect at the
          end of the current subscription period.
        </p>

        <h6 class="ubprivacyhead">6.3 Refund Policy</h6>
        <p class="termsp privj">
          Our{" "}
          <a href="/Refund" class="text-dark">
            Refund policy
          </a>{" "}
          is outlined on our website. We reserve the right to refuse a refund
          request if we believe (in our sole discretion) that it is fraudulent,
          abusive, or otherwise violates our refund policy.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">7. Limitation of Liability</b>
        </h6>
        <h6 class="subprivacyhead">7.1 Disclaimer of Warranties</h6>
        <p class="termsp privj">
          Our website and services are provided on an "as is" and "as available"
          basis without any warranties of any kind, either express or implied,
          including but not limited to warranties of merchantability, fitness
          for a particular purpose, and non-infringement.
        </p>

        <h6 class="subprivacyhead">7.2 Limitation of Liability</h6>
        <p class="termsp privj">
          To the fullest extent permitted by law, Respiro Healthcare, its
          affiliates, and their respective directors, employees, and agents
          shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages, including but not limited to lost
          profits, data loss, or other damages arising from your use of our
          website and services, even if Respiro Healthcare has been advised of
          the possibility of such damages.
        </p>

        <h6 class="subprivacyhead">7.3 Indemnification</h6>
        <p class="termsp privj">
          You agree to indemnify, defend, and hold harmless Respiro Healthcare,
          its affiliates, and their respective directors, employees, and agents
          from and against any claims, liabilities, damages, losses, costs,
          expenses, or fees (including reasonable attorneys' fees) arising from
          your use of our website and services or your violation of these Terms.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">8. Termination</b>
        </h6>
        <h6 class="subprivacyhead">8.1 Termination by You</h6>
        <p class="termsp privj">
          You may terminate your account and stop using our website and services
          at any time. To terminate your account, please contact us at [Contact
          Information].
        </p>

        <h6 class="subprivacyhead">8.2 Termination by Respiro Healthcare</h6>
        <p class="termsp privj">
          We reserve the right to terminate or suspend your account and access
          to our website and services, without prior notice or liability, for
          any reason, including if you breach these Terms.
        </p>

        <h6 class="subprivacyhead">8.3 Effect of Termination</h6>
        <p class="termsp privj">
          Upon termination, your right to use the website and services will
          immediately cease. All provisions of these Terms which by their nature
          should survive termination shall survive, including ownership
          provisions, warranty disclaimers, indemnity, and limitations of
          liability.
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">9. Governing Law and Dispute Resolution</b>
        </h6>
        <h6 class="subprivacyhead">9.1 Governing Law</h6>
        <p class="termsp privj">
          These Terms shall be governed and construed in accordance with the
          laws of [Your Jurisdiction], without regard to its conflict of law
          provisions.
        </p>

        <h6 class="subprivacyhead">9.2 Dispute Resolution</h6>
        <p class="termsp privj">
          Any dispute arising out of or in connection with these Terms,
          including any questions regarding their existence, validity, or
          termination, shall be resolved through good faith negotiations. If the
          dispute cannot be resolved through negotiations, it shall be settled
          by binding arbitration in accordance with the rules of [Arbitration
          Organization]. The arbitration shall take place in [Arbitration
          Location], and the language of the arbitration shall be [Language].
        </p>

        <h6 class="privacyhead">
          <b class="text-dark">10. Miscellaneous</b>
        </h6>
        <h6 class="subprivacyhead">10.1 Entire Agreement</h6>
        <p class="termsp privj">
          These Terms constitute the entire agreement between you and Respiro
          Healthcare regarding your use of our website and services and
          supersede any prior agreements or understandings, whether written or
          oral, relating to such subject matter.
        </p>

        <h6 class="subprivacyhead">10.2 Severability</h6>
        <p class="termsp privj">
          If any provision of these Terms is found to be invalid or
          unenforceable, the remaining provisions shall continue in full force
          and effect.
        </p>

        <h6 class="subprivacyhead">10.3 Waiver</h6>
        <p class="termsp privj">
          Our failure to enforce any right or provision of these Terms shall not
          be deemed a waiver of such right or provision.
        </p>

        <h6 class="subprivacyhead">10.4 Assignment</h6>
        <p class="termsp privj">
          We may assign our rights and obligations under these Terms to any
          party at any time without notice to you. You may not assign your
          rights or obligations under these Terms without our prior written
          consent.
        </p>

        <h6 class="subprivacyhead">10.5 Contact Information</h6>
        <p class="termsp privj">
          If you have any questions or concerns about these Terms, please
          contact us:
        </p>
        <p class="termsp privj">
        Email: <a href="mailto:business@respirohealthcare.com"  classname="policy-contact">
              business@respirohealthcare.com
            </a><br></br><br></br>
          Address: <br></br>2nd floor, Golden Plaza, TB Junction, Angamaly 683572
          <br></br><br></br>
          By Phone: +91 8075892588<br></br><br></br>
          By Website Contact Form:{" "}
          <a href="https://respirohealthcare.com/contact" class="text-dark">
            https://respirohealthcare.com/contact
          </a>
          <br></br>
          
          Thank you for choosing Respiro Healthcare for your respiratory health
          needs.
        </p>
      </div>
      </div>
    </>
  );
}
