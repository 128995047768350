import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import validator from "validator";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegEdit } from "react-icons/fa";
import {
  setFunction,
  getFunction,
  updateFunction,
  uploadFileFunction,
  deleteFunction,
} from "../../api/apiFunctions";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function ProviderPersonalInfo() {
  const [loading, setLoading] = useState(true);
  const { providerId, providerEmail, isMobile } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    gender: "male",
    phone: "",
    email: "",
    yearsExperience: "",
    initConsultFee: "",
    repeatConsultFee: "",
    repeatInterval: "",
    providerType: "",
    employmentType: "",
    registrationNumber: "",
    aboutMe: "",
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileUploadError, setFileUploadError] = useState("");
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [imageId, setImageId] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        console.log("Fetching image...");
        const response = await getFunction(
          { providerId, documentType: "PROFILEPIC" },
          "/documents/get-documentsbytype"
        );
        const { id, url } = response.success.result[0];
        setImageId(id);
        setImageKey(url);
      } catch (error) {
        console.log(error);
      }
    };
    fetchImage();
  }, [providerId]);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (imageKey) {
        try {
          console.log("Fetching image URL...");
          const response = await getFunction(
            { filename: imageKey },
            "/provider/file/get"
          );
          setImageUrl(JSON.parse(response.success.body).url);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchImageUrl();
  }, [imageKey]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        console.log("Fetching profile...");
        const response = await getFunction(
          { id: providerId },
          "/provider/get-profile"
        );
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
          gender: responseData.gender,
          phone: responseData.phone,
          email: responseData.email,
          yearsExperience: responseData.years_experience,
          initConsultFee: responseData.init_consult_fee,
          repeatConsultFee: responseData.repeat_consult_fee,
          repeatInterval: responseData.repeat_interval,
          providerType: responseData.provider_type,
          employmentType: responseData.employment_type,
          registrationNumber: responseData.registration_number,
          aboutMe: responseData.about_me,
        });
        console.log("Profile fetched successfully");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setData({ ...data, email: providerEmail, id: providerId });
        console.log("Error fetching profile");
        setExistingProvider(false);
        setLoading(false);
      }
    };
    fetchProfile();
  }, [providerId, providerEmail]);

  const profileButtonHandler = (e) => {
    setProfileEditView(true);
    if (profileEditView) {
      if (existingProvider) {
        updateProvider(e);
      } else {
        register(e);
      }
    }
  };

  const changeHandler = (e) => {
    setErrors({});
    const { name, value } = e.target;
    let errorMessage = "";

    if (value.trim() === "") {
      errorMessage = "This field is required";
    }

    if (name === "firstName" && !validator.isAlpha(value)) {
      errorMessage = "Only alphabets allowed!";
    } else if (name === "lastName" && !validator.isAlpha(value)) {
      errorMessage = "Only alphabets allowed!";
    } else if (
      name === "phone" &&
      !validator.isMobilePhone(value, "en-IN", { strictMode: false })
    ) {
      errorMessage = "Invalid phone number!";
    } else if (name === "email" && !validator.isEmail(value)) {
      errorMessage = "Invalid email address!";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    setData({ ...data, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      setFile(e.target.files[0]);
    }
  };

  const deleteFile = async () => {
    if (imageId) {
      try {
        await deleteFunction({ id: imageId }, "/documents/delete-documents");
        console.log("Deleted document");
        setImageId(null);
        setImageKey(null);
        setImageUrl(null);
        setFileName(null);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const uploadFile = async () => {
    if (!file) {
      setFileUploadError("Please select a file to upload.");
      return;
    }

    try {
      if (imageId) {
        await deleteFunction({ id: imageId }, "/documents/delete-documents");
        console.log("Deleted document");
      }

      const formData = new FormData();
      formData.append("file", file);
      await uploadFileFunction(
        formData,
        `/provider/file/upload/?providerId=${providerId}`
      );
      toast.success("File uploaded successfully"); // Success toast


      const documentData = {
        providerId,
        documentName: fileName,
        documentType: "PROFILEPIC",
        url: `${providerId}/${fileName}`,
      };

      const response = await setFunction(
        documentData,
        "/documents/set-documents"
      );
      console.log("Added to document table");
      setImageKey(documentData.url);
      setImageId(response.success.id);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while uploading the file.");

    }
  };

  // Registration 

  const register = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await setFunction(data, "/provider/set-profile");
      console.log(response.data);
      toast.success("Profile registered successfully");
      setProfileEditView(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        toast.error(error.response.data.error);
      } else {
        console.log(error);
        toast.error("An error occurred while submitting the form. Please try again later.");
      }

    }
  };

  // update profile info 

  const updateProvider = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await updateFunction(data, "/provider/update-profile");
      console.log(response.data);
      toast.success("Profile updated successfully!");
      setProfileEditView(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        toast.error(error.response.data.error);
      } else {
        console.log(error);
        toast.error("An error occurred while submitting the form. Please try again later.");
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }
    if (!data.aboutMe.trim()) {
      errors.aboutMe = "About me is required";
      valid = false;
    }
    if (!data.gender) {
      errors.gender = "Gender is required";
      valid = false;
    }
    if (!data.email.trim() || !validator.isEmail(data.email)) {
      errors.email = "Valid email is required";
      valid = false;
    }
    if (!data.phone.trim() || !validator.isMobilePhone(data.phone, "en-IN")) {
      errors.phone = "Valid phone number is required";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };
  const desktopView = () => (
    <>
    <div class="">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <h2 class="citext topfrom">First Name *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                name="firstName"
                tabIndex={1}
                value={data.firstName}
                onChange={changeHandler}
                placeholder="First Name"
                className="form-input textbox"
              />
              {errors.firstName && (
                <div className="validation">{errors.firstName}</div>
              )}
              <br></br>
              <br></br>
              <h2 class="citext topfrom">Gender *</h2>
              <select
                disabled={profileEditView ? false : true}
                type="text"
                name="gender"
                tabIndex={3}
                onChange={changeHandler}
                value={data.gender.toLowerCase()}
                placeholder="Gender"
                className="form-input pl-0 text-dark textbox"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unspecified">Unspecified</option>
              </select>
              {errors.gender && (
                <div className="validation">{errors.gender}</div>
              )}
              <br></br>
              <br></br>
            </div>
            <div class="col-sm-6">
              <h2 class="citext topfrom">Last Name *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                name="lastName"
                tabIndex={2}
                onChange={changeHandler}
                value={data.lastName}
                placeholder="Last Name"
                className="form-input textbox"
              />
              {errors.lastName && (
                <div className="validation">{errors.lastName}</div>
              )}
              <br></br>
              <br></br>
              <h2 class="citext topfrom">Phone Number *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                name="phone"
                tabIndex={4}
                onChange={changeHandler}
                value={data.phone}
                placeholder="Phone"
                className="form-input textbox"
              />
              {errors.phone && (
                <div className="validation">{errors.phone}</div>
              )}
              <br></br>
            </div>
          </div>
          <div>
            <h2 class="citext topfrom">About me *</h2>
            <textarea
              disabled={profileEditView ? false : true}
              name="aboutMe"
              tabIndex={4}
              value={data.aboutMe}
              style={{ width: "95%", height: "100px" }}
              onChange={changeHandler}
              placeholder="About Me"
              className="form-input textbox"
            />
            {errors.aboutMe && (
              <div className="validation">{errors.aboutMe}</div>
            )}
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    </div>
    <br></br><br></br>
        <div class="row ">
              <div class="col-sm-5">
                <h1 class="profileheading1 providerprofileheading1">
                  Service Information
                </h1>
              </div>
              <div class="col-sm-1"></div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <h2
                  type="button"
                  class="visit1 providervisit10"
                  onClick={profileButtonHandler}
                >
                  {profileEditView ? "Submit " : "Edit "}
                  {/* <span className="fa-solid fa-pen"></span> */}
                  <span><FaRegEdit /></span>
                </h2>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <h2 class="citext topfrom">Years of Experience *</h2>
                  <input
                    disabled={profileEditView ? false : true}
                    type="text"
                    tabIndex={6}
                    onChange={changeHandler}
                    name="yearsExperience"
                    placeholder="Years of Experience"
                    value={data.yearsExperience}
                    className="form-input textbox"
                  />
                  {errors.yearsExperience && (
                    <div className="validation">{errors.yearsExperience}</div>
                  )}
                  <br></br>
                  <br></br>
                  <h2 class="citext topfrom">Repeat Consultation Fee *</h2>
                  <input
                    disabled={profileEditView ? false : true}
                    type="text"
                    tabIndex={8}
                    onChange={changeHandler}
                    name="repeatConsultFee"
                    placeholder="Repeat Consult Fee"
                    value={data.repeatConsultFee}
                    className="form-input textbox"
                  />
                  {errors.repeatConsultFee && (
                    <div className="validation">{errors.repeatConsultFee}</div>
                  )}
                  <br></br> <br></br>
                  <h2 class="citext topfrom">Provider Type *</h2>
                  <select
                    disabled={profileEditView ? false : true}
                    className="form-input pl-0 text-dark textbox"
                    name="providerType"
                    placeholder="Provider Type"
                    tabIndex={10}
                    onChange={changeHandler}
                    value={data.providerType}
                  >
                    <option selected>--Select--</option>
                    <option>Doctor</option>
                    <option>Therapist</option>
                  </select>
                  {errors.providerType && (
                    <div className="validation">{errors.providerType}</div>
                  )}
                  <br></br> <br></br>
                  {data.providerType === "Therapist" && (
                    <>
                  <h2 class="citext topfrom">Employment Type *</h2>
                  <select
                    disabled={profileEditView ? false : true}
                    className="form-input pl-0 text-dark textbox"
                    name="employmentType"
                    placeholder="Employment Type"
                    onChange={changeHandler}
                    value={data.employmentType}
                  >
                    <option selected>--Select--</option>
                    <option>Full Time</option>
                    <option>Part Time</option>
                  </select>
                  </>
                  )}
                  {errors.empType && (
                    <div className="validation">{errors.empType}</div>
                  )}
                </div>
                <div class="col-sm-6">
                  <h2 class="citext topfrom">Initial Cousultation Fee *</h2>
                  <input
                    disabled={profileEditView ? false : true}
                    type="text"
                    onChange={changeHandler}
                    name="initConsultFee"
                    tabIndex={7}
                    placeholder="Initial Consult Fee"
                    value={data.initConsultFee}
                    className="form-input textbox"
                  />
                  {errors.initConsultFee && (
                    <div className="validation">{errors.initConsultFee}</div>
                  )}
                  <br></br> <br></br>
                  <h2 class="citext topfrom">Repeat Interval (Days) *</h2>
                  <input
                    disabled={profileEditView ? false : true}
                    type="text"
                    onChange={changeHandler}
                    name="repeatInterval"
                    tabIndex={9}
                    placeholder="Repeat Interval"
                    value={data.repeatInterval}
                    className="form-input textbox"
                  />
                  {errors.repeatInterval && (
                    <div className="validation">{errors.repeatInterval}</div>
                  )}
                  <br></br> <br></br>
                  <h2 class="citext topfrom">Registration Number *</h2>
                  <input
                    disabled={profileEditView ? false : true}
                    type="text"
                    onChange={changeHandler}
                    name="registrationNumber"
                    tabIndex={11}
                    placeholder="Registration Number"
                    value={data.registrationNumber}
                    className="form-input textbox"
                  />
                  {errors.registrationNumber && (
                    <div className="validation">
                      {errors.registrationNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>
            </>
  );
  const mobileView = () => (
    <>
      <div class="">
        <div class="">
          <div class="container" style={{ marginLeft: "-6px" }}>
            <div class="row">
              <div class="col-sm-6">
                <h2 class="citext topfrom">First Name *</h2>
                <input
                  disabled={profileEditView ? false : true}
                  type="text"
                  name="firstName"
                  tabIndex={1}
                  value={data.firstName}
                  onChange={changeHandler}
                  placeholder="First Name"
                  className="form-input textbox"
                />
                {errors.firstName && (
                  <div className="validation">{errors.firstName}</div>
                )}
                <br></br>
                <br></br>
                <h2 class="citext topfrom">Last Name *</h2>
                <input
                  disabled={profileEditView ? false : true}
                  type="text"
                  name="lastName"
                  tabIndex={2}
                  onChange={changeHandler}
                  value={data.lastName}
                  placeholder="Last Name"
                  className="form-input textbox"
                />
                {errors.lastName && (
                  <div className="validation">{errors.lastName}</div>
                )}
                <br></br>
                <br></br>
                <h2 class="citext topfrom">Gender *</h2>
                <select
                  disabled={profileEditView ? false : true}
                  type="text"
                  name="gender"
                  tabIndex={3}
                  onChange={changeHandler}
                  value={data.gender.toLowerCase()}
                  placeholder="Gender"
                  className="form-input pl-0 text-dark textbox"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="unspecified">Unspecified</option>
                </select>
                {errors.gender && (
                  <div className="validation">{errors.gender}</div>
                )}
                <br></br>
                <br></br>
              </div>
              <div class="col-sm-6">
                <h2 class="citext topfrom">Phone Number *</h2>
                <input
                  disabled={profileEditView ? false : true}
                  type="text"
                  name="phone"
                  tabIndex={4}
                  onChange={changeHandler}
                  value={data.phone}
                  placeholder="Phone"
                  className="form-input textbox"
                />
                {errors.phone && (
                  <div className="validation">{errors.phone}</div>
                )}
                <br></br>
              </div>
            </div>
            <div>
              <h2 class="citext topfrom">About me *</h2>
              <textarea
                disabled={profileEditView ? false : true}
                name="aboutMe"
                tabIndex={5}
                value={data.aboutMe}
                style={{ width: "95%", height: "100px" }}
                onChange={changeHandler}
                placeholder="About Me"
                className="form-input textbox"
              />
              {errors.aboutMe && (
                <div className="validation">{errors.aboutMe}</div>
              )}
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div class="row ">
        <div class="col-sm-5">
          <h1 class="profileheading1 providerprofileheading1">
            Service Information
          </h1>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-2"></div>
        <div class="col-sm-3">
          <h2
            type="button"
            class="visit1 providervisit10"
            onClick={profileButtonHandler}
          >
            {profileEditView ? "Submit " : "Edit "}
            {/* <span className="fa-solid fa-pen"></span> */}
            <span>
              <FaRegEdit />
            </span>
          </h2>
        </div>
      </div>
      <div class="container" style={{ marginLeft: "-6px" }}>
        <div class="row">
          <div class="col-sm-6">
            <h2 class="citext topfrom">Years of Experience *</h2>
            <input
              disabled={profileEditView ? false : true}
              type="text"
              tabIndex={6}
              onChange={changeHandler}
              name="yearsExperience"
              placeholder="Years of Experience"
              value={data.yearsExperience}
              className="form-input textbox"
            />
            {errors.yearsExperience && (
              <div className="validation">{errors.yearsExperience}</div>
            )}
            <br></br>
            <br></br>
            <h2 class="citext topfrom">Initial Cousultation Fee *</h2>
            <input
              disabled={profileEditView ? false : true}
              type="text"
              onChange={changeHandler}
              name="initConsultFee"
              tabIndex={7}
              placeholder="Initial Consult Fee"
              value={data.initConsultFee}
              className="form-input textbox"
            />
            {errors.initConsultFee && (
              <div className="validation">{errors.initConsultFee}</div>
            )}
            <br></br> <br></br>
            <h2 class="citext topfrom">Repeat Consultation Fee *</h2>
            <input
              disabled={profileEditView ? false : true}
              type="text"
              tabIndex={8}
              onChange={changeHandler}
              name="repeatConsultFee"
              placeholder="Repeat Consult Fee"
              value={data.repeatConsultFee}
              className="form-input textbox"
            />
            {errors.repeatConsultFee && (
              <div className="validation">{errors.repeatConsultFee}</div>
            )}
            <br></br> <br></br>
          </div>
          <div class="col-sm-6">
            <h2 class="citext topfrom">Repeat Interval (Days) *</h2>
            <input
              disabled={profileEditView ? false : true}
              type="text"
              onChange={changeHandler}
              name="repeatInterval"
              tabIndex={9}
              placeholder="Repeat Interval"
              value={data.repeatInterval}
              className="form-input textbox"
            />
            {errors.repeatInterval && (
              <div className="validation">{errors.repeatInterval}</div>
            )}
            <br></br> <br></br>
            <h2 class="citext topfrom">Provider Type *</h2>
            <select
              disabled={profileEditView ? false : true}
              className="form-input pl-0 text-dark textbox"
              name="providerType"
              placeholder="Provider Type"
              tabIndex={10}
              onChange={changeHandler}
              value={data.providerType}
            >
              <option selected>--Select--</option>
              <option>Doctor</option>
              <option>Therapist</option>
            </select>
            {errors.providerType && (
              <div className="validation">{errors.providerType}</div>
            )}
            <br></br> <br></br>
            {data.providerType === "Therapist" && (
              <>
                <h2 class="citext topfrom">Employment Type *</h2>
                <select
                  disabled={profileEditView ? false : true}
                  className="form-input pl-0 text-dark textbox"
                  name="employmentType"
                  placeholder="Employment Type"
                  onChange={changeHandler}
                  value={data.employmentType}
                >
                  <option selected>--Select--</option>
                  <option>Full Time</option>
                  <option>Part Time</option>
                </select>
              </>
            )}
            {errors.empType && (
              <div className="validation">{errors.empType}</div>
            )}
            <h2 class="citext topfrom">Registration Number *</h2>
            <input
              disabled={profileEditView ? false : true}
              type="text"
              onChange={changeHandler}
              name="registrationNumber"
              tabIndex={11}
              placeholder="Registration Number"
              value={data.registrationNumber}
              className="form-input textbox"
            />
            {errors.registrationNumber && (
              <div className="validation">{errors.registrationNumber}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <ToastContainer />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div class="contain">
          <div class="providerline">
            <br></br> <br></br> <br></br>
            <h1 class="profileheading1 providerprofileheading1">
              Personal Information
            </h1>
            <hr class=""></hr>
            {/* <div>
          <label htmlFor="file">Upload File:</label>
          <input type="file" id="file" name="file" onChange={handleFileChange} />
          {fileUploadError && <p>{fileUploadError}</p>}
        
        <button type="button" onClick={uploadFile}>Upload File</button>
  
        <button type="submit" onClick={profileButtonHandler}>
          {profileEditView ? "Save" : "Edit Profile"}
        </button></div> */}
            {/* <div>
          <img src={imageUrl} alt="Profile"></img>
        </div> */}
            <div class="row ">
              <div class="col-sm-3">
                <div class="container">
                  <div class="">
                    <img src={imageUrl} alt="Profile" class="drprofile11"></img>

                    <div class="dropdown">
                      <button class="dropbtn">
                        <img
                          src="../style/img/resp/addplus.png"
                          alt="Profile"
                          class=" liveicon1 providerliveicon1"
                          name="file"
                        ></img>
                      </button>
                      <div class="dropdown-content">
                        <input
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleFileChange}
                        />
                        {fileUploadError && <p>{fileUploadError}</p>}
                        <input type="file" id="fileInput" class="fileInput" />
                        <a href="#" id="saveLink" onClick={uploadFile}>
                          Save
                        </a>
                        <a href="#" onClick={deleteFile}>
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="conatiner">
                  <h3 class="profilname provideprofilname">
                    {data.firstName} &nbsp; {data.lastName}
                  </h3>
                  <h3 class="profilemail providerprofileemail">
                    {data.email}{" "}
                  </h3>
                </div>
              </div>
              <div class="col-sm-3"></div>
              <div class="col-sm-3">
                <h2
                  type="button"
                  class=" providervisit1 visit1"
                  onClick={profileButtonHandler}
                >
                  {profileEditView ? "Submit " : "Edit "}
                  {/* <span className="fa-solid fa-pen"></span> */}
                  <span><FaRegEdit /></span>
                </h2>
              </div>
            </div>

            {isMobile ? mobileView() : desktopView()}
              <br></br> <br></br>
          </div>
        </div>
      )}
    </>
  );
}

export default withProviderAuth(ProviderPersonalInfo);