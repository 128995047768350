import React, { useEffect, useState, useContext } from "react";
import QualificationTable from "./QualificationTable";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function Qualification() {
  const { providerId } = useContext(AuthContext);
  const [qualification, setQualification] = useState(null); // Initialize with appropriate value
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const emptyRow = {
    id: "",
    providerId: "",
    qualificationId: "",
    qualificationName: "",
    instituteName: "",
    procurementYear: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await getFunction({}, "/get-provider-qualification-list");
        setQualification(response1.success.result);

        const response2 = await getFunction({ providerId }, "/provider/get-qualification");
        setData(response2.success.result);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [providerId]); // Ensure useEffect runs when providerId changes

  const addEmptyRow = () => {
    setData([...data, emptyRow]);
  };

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  return (
    <div className="contain">
      <div className="providerline">
        <br></br> <br></br> <br></br>
        <h1 className="providerprofileheading100 profileheading1">Qualifications</h1>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <hr className="providerline" />
            <div class="col-sm-5">
            <table class="table-responsive">
              <thead>
                <tr>
                  <th>Qualification</th>
                  <th>Institute</th>
                  <th>Year</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <QualificationTable
                    key={item.id}
                    data={{
                      id: item.id,
                      providerId: item.provider_id,
                      qualificationId: item.qualification_id,
                      qualificationName: item.qualification_name,
                      instituteName: item.institute_name,
                      procurementYear: item.procurement_year
                        ? item.procurement_year.toString()
                        : item.procurement_year,
                    }}
                    qualification={qualification}
                    handleDelete={handleDelete}
                  />
                ))}
              </tbody>
            </table>
            </div>
            <br></br>
            <button
              className="qualificationbtn"
              onClick={() => {
                addEmptyRow();
              }}
            >
              +
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default withProviderAuth(Qualification);
