import React, { useEffect, useRef, useState } from "react";

function Partnership() {
  const videoRef = useRef(null);
  const [firstInteraction, setFirstInteraction] = useState(true);

  // useEffect is used for side effects
  useEffect(() => {
    // Function to play the video
    const playVideo = () => {
      // Check if the videoRef is available
      if (videoRef.current) {
        // Check if it's the first user interaction
        if (firstInteraction) {
          setFirstInteraction(false);
        } else {
          // Check if the video is paused to prevent multiple play attempts
          if (videoRef.current.paused) {
            // Attempt to play the video, catch any autoplay errors
            videoRef.current.play().catch((error) => {
              console.error("Autoplay was prevented:", error);
            });
          }
        }
      }
    };

    // Add an event listener to play the video when a user interacts with the page
    const handleClick = () => {
      playVideo();
      // Remove the event listener to avoid multiple play attempts
      const autoButton = document.getElementById("auto-button");
    };

    // Add click event listener
    document.addEventListener("auto", handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("auto", handleClick);
    };
  }, [firstInteraction]);
  return (
    <>
      <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>{" "}
      <br></br> <br></br> <br></br> <br></br>
      <div class="container">
        <div class="car">
          <div class="card-bod">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <br></br>
                  <h3>
                    <font color="#204ea0">Empowering Entrepreneurs in</font>
                  </h3>
                  <h3>
                    <font color="#000">Lung Health</font>
                  </h3>

                  <hr></hr>

                  <p class="pptext">
                    Welcome to LungCare Connect – where entrepreneurship meets a
                    healthier tomorrow! We take pride in being your Trusted
                    Health Partner, committed to fostering a community-driven
                    approach to lung health. Our mission is clear – to make lung
                    care accessible, understandable, and affordable for
                    everyone. As we continue on this transformative journey, we
                    extend a warm invitation to passionate and driven
                    individuals like you to become an integral part of our
                    success story.
                    <br></br> <br></br> <br></br>
                    <center>
                      <button class="main_btn ms-2">Apply Here</button>
                    </center>
                    <br></br>
                  </p>
                  <br></br>
                </div>
                <div class="col-sm-6">
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div>
        <div className="video-banner">
          <video ref={videoRef} autoPlay muted>
            <source src="./style/img/banner/p1video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className="overlay"></div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div class="container">
        <div class="car1">
          <div class="card-bod">
            <div class="container">
              <center>
                <h3>
                  <font color="#204ea0">Why should you become a</font>
                </h3>
                <h3>
                  <font color="#000">Why should you partner with Respiro</font>
                </h3>

                <hr></hr>
              </center>
              <div class="row">
                <div class="col-sm-2">
                  <br></br>
                  <div class="container">
                    <img
                      src="./style/img/banner/pimg1.jpg"
                      class="p_img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="p_text">Unlock Entrepreneurial Opportunities</p>
                  <hr></hr>
                  <p class="p1_text">
                    Embark on a journey as a lung care entrepreneur with
                    Respiro, contributing to the well-being of your community.
                  </p>
                </div>
                <br></br>
                <div class="col-sm-2">
                  <br></br>
                  <div class="container">
                    <img
                      src="./style/img/banner/pimg2.jpg"
                      class="p_img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="p_text">
                    Comprehensive Marketing and Technological Support
                  </p>
                  <hr></hr>
                  <p class="p1_text">
                    We provide robust marketing strategies and cutting-edge
                    technological tools to propel your lung care business to new
                    heights.
                  </p>
                </div>
                <br></br>
              </div>
              {/* 2row */}
              <div class="row">
                <div class="col-sm-2">
                  <br></br>
                  <div class="container">
                    <img
                      src="./style/img/banner/pimg3.jpg"
                      class="p_img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="p_text">Venture into Profitable Partnership</p>
                  <hr></hr>
                  <p class="p1_text">
                    Joining Respiro means unlocking diverse income opportunities
                    in healthcare services and innovative treatments, ensuring a
                    stable and successful entrepreneurial path.
                  </p>
                </div>
                <br></br>
                <div class="col-sm-2">
                  <br></br>
                  <div class="container">
                    <img
                      src="./style/img/banner/pimg4.jpg"
                      class="p_img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="p_text">Professional Training and Insights</p>
                  <hr></hr>
                  <p class="p1_text">
                    Receive specialized training and valuable insights to
                    enhance your expertise in respiratory care, empowering you
                    to make a significant impact.
                  </p>
                </div>
                <br></br>
              </div>
              {/* 3row */}
              <div class="row">
                <div class="col-sm-2">
                  <br></br>
                  <div class="container">
                    <img
                      src="./style/img/banner/pimg5.jpg"
                      class="p_img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="p_text">Collaborate with Respiro</p>
                  <hr></hr>
                  <p class="p1_text">
                    Partner with Respiro, a distinguished digital platform in
                    respiratory health, to reach a broader audience and
                    establish your presence in the industry.
                  </p>
                </div>
                <br></br>
                <div class="col-sm-2">
                  <br></br>
                  <div class="container">
                    <img
                      src="./style/img/banner/pimg6.jpg"
                      class="p_img"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="p_text">
                    Contribute to Healthier and Happier Communities
                  </p>
                  <hr></hr>
                  <p class="p1_text">
                    Above all, seize the opportunity to make a meaningful
                    difference by promoting respiratory health and happiness in
                    your community
                  </p>
                </div>
                <br></br>
              </div>
              <br></br> <br></br> <br></br>
            </div>
          </div>
        </div>
        <br></br> <br></br> <br></br>
        <br></br>
        <div class="container">
          <div class="car2">
            <div class="card-bod">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <br></br>
                    <h3>
                      <font color="#204ea0">Health Partner</font>
                    </h3>
                    <h3>
                      <font color="#000">Program</font>
                    </h3>

                    <hr></hr>

                    <p class="pptext">
                      Begin your journey towards making a meaningful difference
                      to the community & earning healthy rewards for this.
                      <br></br> <br></br> <br></br>
                      <center>
                        <button class="main_btn ms-2">Apply Here</button>
                      </center>
                      <br></br>
                    </p>
                    <br></br>
                  </div>
                  <div class="col-sm-6">
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partnership;
