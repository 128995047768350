import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { FaArrowRightLong } from "react-icons/fa6";

function Indexhome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const symptomsList = [
    "Shortness of breath",
    "Chest tightness",
    "Chronic cough",
    "Wheezing",
    "Fatigue",
    "Frequent lung infections",
    "Bluish lips or fingernails",
  ];

  const severities = ["Mild", "Moderate", "Severe"];

  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [severityLevels, setSeverityLevels] = useState({});
  const [aiResponse, setAiResponse] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSymptomChange = (e) => {
    const symptom = e.target.value;
    if (!selectedSymptoms.includes(symptom)) {
      setSelectedSymptoms([...selectedSymptoms, symptom]);
    }
  };

  const handleSeverityChange = (symptom, severity) => {
    setSeverityLevels({ ...severityLevels, [symptom]: severity });
  };

  const getAiRecommendation = () => {
    if (selectedSymptoms.length === 0) return "Please select symptoms.";

    let recommendation = "Based on the symptoms and severities you provided: ";

    selectedSymptoms.forEach((symptom) => {
      const severity = severityLevels[symptom];
      if (severity) {
        switch (symptom) {
          case "Shortness of breath":
            if (severity === "Mild") {
              recommendation +=
                "Mild shortness of breath may be related to anxiety or mild exercise. Consider relaxation techniques. ";
            } else if (severity === "Moderate") {
              recommendation +=
                "Moderate shortness of breath could indicate asthma or bronchitis. Consult a healthcare provider. ";
            } else {
              recommendation +=
                "Severe shortness of breath may suggest serious conditions like heart disease. Seek immediate medical attention. ";
            }
            break;
          case "Chest tightness":
            if (severity === "Mild") {
              recommendation +=
                "Mild chest tightness may be due to stress or anxiety. Practice deep breathing. ";
            } else if (severity === "Moderate") {
              recommendation +=
                "Moderate chest tightness could indicate asthma or early heart issues. Schedule a healthcare visit. ";
            } else {
              recommendation +=
                "Severe chest tightness could indicate a heart attack. Seek emergency care immediately. ";
            }
            break;
          case "Chronic cough":
            if (severity === "Mild") {
              recommendation +=
                "A mild chronic cough might be due to allergies or environmental irritants. Try over-the-counter remedies. ";
            } else if (severity === "Moderate") {
              recommendation +=
                "A moderate chronic cough may suggest bronchitis or asthma. It’s advisable to consult a healthcare provider. ";
            } else {
              recommendation +=
                "A severe chronic cough could suggest serious conditions such as COPD or lung cancer. Contact your healthcare provider urgently. ";
            }
            break;
          case "Wheezing":
            if (severity === "Mild") {
              recommendation +=
                "Mild wheezing may occur with allergies or mild asthma. Avoid triggers and monitor your symptoms. ";
            } else if (severity === "Moderate") {
              recommendation +=
                "Moderate wheezing could indicate asthma or bronchitis. A medical evaluation is recommended. ";
            } else {
              recommendation +=
                "Severe wheezing can be a sign of an asthma attack. Seek immediate medical attention. ";
            }
            break;
          case "Fatigue":
            if (severity === "Mild") {
              recommendation +=
                "Mild fatigue often results from stress or lack of sleep. Ensure proper rest and self-care. ";
            } else if (severity === "Moderate") {
              recommendation +=
                "Moderate fatigue may indicate underlying issues like anemia or chronic respiratory conditions. A doctor’s visit is recommended. ";
            } else {
              recommendation +=
                "Severe fatigue could indicate serious conditions such as heart failure. Seek medical attention promptly. ";
            }
            break;
          case "Frequent lung infections":
            if (severity === "Mild") {
              recommendation +=
                "Mild frequent lung infections may suggest allergies or mild respiratory issues. Maintain a healthy lifestyle to prevent infections. ";
            } else if (severity === "Moderate") {
              recommendation +=
                "Moderate infections could suggest chronic bronchitis. Consult a healthcare provider. ";
            } else {
              recommendation +=
                "Severe frequent infections may indicate serious issues like lung disease. Seek urgent medical attention. ";
            }
            break;
          case "Bluish lips or fingernails":
            recommendation +=
              "This is a sign of serious oxygen deprivation. Seek emergency medical assistance immediately. ";
            break;
          default:
            recommendation +=
              "Your symptoms could indicate a variety of lung conditions. Please consult a specialist for further evaluation.";
        }
      }
    });

    return recommendation;
  };

  const handleAnalyze = () => {
    setAiResponse(getAiRecommendation());
  };

  const handleReset = () => {
    setSelectedSymptoms([]);
    setSeverityLevels({});
    setAiResponse("");
  };

  return (
    <>
      {/* First banner carosel start */}
      <div class="container-fluid">
        <div
          id="carouselExampleControls"
          class="carousel slide custom-carousel"
          data-ride="carousel"
          data-interval="5000"
        >
          <div class="homegap2"></div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="back_ground_img"
                src="./style/img/background/Group 88.png"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="back_ground_img"
                src="./style/img/background/Group 256.png"
                alt="Second slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="back_ground_img"
                src="./style/img/background/Group 251.png"
                alt="Third slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="back_ground_img"
                src="./style/img/background/Group 255.png"
                alt="Second slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="back_ground_img"
                src="./style/img/background/Group 285.png"
                alt="Second slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="back_ground_img"
                src="./style/img/background/Group 290.png"
                alt="Second slide"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      {/* carousel close */}
      <div class="hometop"></div>
      <div className="container-fluid navbg">
        <div className="container my-5">
          <div className="row g-4">
            {[
              "Respiratory Home Services",
              "Pediatric to Geriatric Lung Care",
            ].map((title, sectionIndex) => (
              <div className="col-md-4 respcard" key={sectionIndex}>
                <div className="card big-card p-4 h-100">
                  <div className="card-body">
                    <h4 className="card-title navhed text-center mb-4">
                      {title}
                    </h4>
                    <div className="row g-1">
                      {[
                        {
                          img: "Group 68.png",
                          text: "Emergency care at home",
                          link: "/Rhf",
                        },
                        {
                          img: "Group 41.png",
                          text: "Sleep study",
                          link: "/Rhf",
                        },
                        {
                          img: "Group 70.png",
                          text: "Pulmonary Function Test",
                          link: "/Rhf",
                        },
                        {
                          img: "Group 71.png",
                          text: "Child Pneumonia Care",
                          link: "/Rhf",
                        },
                        {
                          img: "Group 80.png",
                          text: "Child",
                          link: "/ChildLungcareplan",
                        },
                        {
                          img: "Group 81.png",
                          text: "Youngster",
                          link: "/YoungsterLungcareplan",
                        },
                        {
                          img: "mid.png",
                          text: "Family",
                          link: "/FamilyLungcareplan",
                        },
                        {
                          img: "eld.png",
                          text: "Elderly",
                          link: "/ElderlyLungcareplan",
                        },
                      ]
                        .slice(sectionIndex * 4, sectionIndex * 4 + 4)
                        .map((service, index) => (
                          <div
                            className="col-6"
                            key={`${sectionIndex}-${index}`}
                          >
                            <div
                              className="zoom card small-card h-100 position-relative"
                              style={{
                                height: "200px",
                                margin: "0",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                border: "none",
                              }}
                            >
                              <Link
                                to={service.link}
                                style={{
                                  textDecoration: "none",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={`../style/img/resp/${service.img}`}
                                  className="img-fluid"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  alt={service.text}
                                />
                                <div
                                  className="overlay-text"
                                  style={{
                                    position: "absolute",
                                    bottom: "0",
                                    width: "100%",
                                    height: "20px",
                                    maxHeight: "30px",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    className="card-title m-0 text-white"
                                    style={{
                                      fontSize: isMobile ? "9px" : "11px",
                                      lineHeight: isMobile ? "11px" : "20px",
                                    }}
                                  >
                                    {service.text}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                    <a
                      href={
                        title === "Respiratory Home Services"
                          ? "/Rhfservicepage"
                          : title === "Pediatric to Geriatric Lung Care"
                          ? "/Finddoctor"
                          : "/"
                      }
                      className="allservices"
                      style={{ fontSize: "13px" }}
                    >
                      All Services&nbsp;
                      <FaArrowRightLong style={{ color: "#3495FF" }} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-md-4 respcard">
              <div className="card p-1 h-100">
                <img
                  src="../style/img/resp/adpost.png"
                  alt="Ad Image"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row g-4">
            {[
              "O2 store (medical/devices)",
              "Career-Specific Respiratory Care",
            ].map((title, sectionIndex) => (
              <div className="col-md-4 respcard" key={sectionIndex + 3}>
                <div className="card big-card p-4 h-100">
                  <div className="card-body">
                    <h4 className="card-title navhed text-center mb-4">
                      {title}
                    </h4>
                    <div className="row g-1">
                      {[
                        {
                          img: "oxconc.png",
                          text: "Oxygen concentrator",
                          link: "/O2_sale",
                        },
                        {
                          img: "medbed.png",
                          text: "Medical Bed",
                          link: "/O2_sale",
                        },
                        {
                          img: "cardmon.png",
                          text: "Cardiac Monitor",
                          link: "/O2_sale",
                        },
                        {
                          img: "nebu.png",
                          text: "Nebulizer Mask",
                          link: "/O2_sale",
                        },
                        {
                          img: "Group 83.png",
                          text: "Teacher Lung Care Plan",
                          link: "/Teachercareplan",
                        },
                        {
                          img: "Group 269.png",
                          text: "Artist's Lung Care Plan",
                          link: "/Artistcareplan",
                        },
                        {
                          img: "conwork.png",
                          text: "Worker Lung Care Plan",
                          link: "/WorkerLunghealth",
                        },
                        {
                          img: "Group 85.png",
                          text: "Musician Lung Care Plan",
                          link: "/Musiciancareplan",
                        },
                      ]
                        .slice(sectionIndex * 4, sectionIndex * 4 + 4)
                        .map((service, index) => (
                          <div
                            className="col-6"
                            key={`${sectionIndex + 3}-${index}`}
                          >
                            <div
                              className="zoom card small-card h-100 position-relative"
                              style={{
                                height: "200px",
                                margin: "0",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",

                                border: "none",
                              }}
                            >
                              <Link
                                to={service.link}
                                style={{
                                  textDecoration: "none",
                                  height: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={`../style/img/resp/${service.img}`}
                                  className="img-fluid"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  alt={service.text}
                                />
                                <div
                                  className="overlay-text"
                                  style={{
                                    position: "absolute",
                                    bottom: "0",
                                    width: "100%",
                                    height: "20px",
                                    maxHeight: "30px",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    className="card-title m-0 text-white"
                                    style={{
                                      fontSize: isMobile ? "9px" : "11px",
                                      lineHeight: isMobile ? "11px" : "20px",
                                    }}
                                  >
                                    {service.text}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                    </div>
                    <a
                      href={
                        title === "O2 store (medical/devices)"
                          ? "/O2_sale"
                          : title === "Career-Specific Respiratory Care"
                          ? "/Finddoctor"
                          : "/"
                      }
                      className="allservices"
                      style={{ fontSize: "13px" }}
                    >
                      All Services&nbsp;
                      <FaArrowRightLong style={{ color: "#3495FF" }} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-md-4 respcard">
              <div className="card p-1 h-100">
                <img
                  src="../style/img/resp/adpost3.png"
                  alt="Ad Image"
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <div class="container py-4">
        <h3 class="text-dark text-center">
          Consult top doctors online for you
        </h3>
        <br></br>
        <div class="row justify-content-center mt-4">
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
            <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
              <div
                class="zoom card text-center"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <center>
                  <img
                    src="../style/img/resp/Ellipse6.png"
                    class="img-fluid "
                    style={{ width: "60%", transition: "transform 0.8s" }}
                    alt="Breathing Difficulty"
                  />
                  <p class="mt-2 text-muted" style={{ fontSize: "13px" }}>
                    Breathing or Respiratory Issues{" "}
                  </p>
                </center>
              </div>
            </Link>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
            <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
              <div
                class="zoom card text-center"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <center>
                  <img
                    src="../style/img/resp/Ellipse7.png"
                    class="img-fluid "
                    style={{ width: "60%", transition: "transform 0.8s" }}
                    alt="Chronic Cough"
                  />
                  <p class="mt-2 text-muted" style={{ fontSize: "13px" }}>
                    Chronic Cough or Chest Discomfort
                  </p>
                </center>
              </div>
            </Link>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
            <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
              <div
                class="zoom card text-center"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <center>
                  <img
                    src="../style/img/resp/Ellipse8.png"
                    class="img-fluid "
                    style={{ width: "60%", transition: "transform 0.8s" }}
                    alt="Shortness of Breath"
                  />
                  <p class="mt-2 text-muted" style={{ fontSize: "13px" }}>
                    Shortness of Breath or Wheezing
                  </p>
                </center>
              </div>
            </Link>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
            <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
              <div
                class="zoom card text-center"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <center>
                  <img
                    src="../style/img/resp/Ellipse9.png"
                    class="img-fluid "
                    style={{ width: "60%", transition: "transform 0.8s" }}
                    alt="Sleep Apnea"
                  />
                  <p class="mt-2 text-muted" style={{ fontSize: "13px" }}>
                    Sleep Apnea or sleep related Issues
                  </p>
                </center>
              </div>
            </Link>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
            <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
              <div
                class="zoom card text-center"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <center>
                  <img
                    src="../style/img/resp/Ellipse10.png"
                    class="img-fluid "
                    style={{ width: "60%", transition: "transform 0.8s" }}
                    alt="Pediatric Respiratory"
                  />
                  <p class="mt-2 text-muted" style={{ fontSize: "13px" }}>
                    Pediatric respiratory difficulties
                  </p>
                </center>
              </div>
            </Link>
          </div>
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
            <Link to="/Finddoctor" style={{ textDecoration: "none" }}>
              <div
                class="zoom card text-center"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "none",
                }}
              >
                <center>
                  <img
                    src="../style/img/resp/Ellipse 11.png"
                    class="img-fluid "
                    style={{ width: "60%", transition: "transform 0.8s" }}
                    alt="Anxiety"
                  />
                  <p class="mt-2 text-muted" style={{ fontSize: "13px" }}>
                    Anxiety related to Respiratory Health
                  </p>
                </center>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="card">
          <div class="container-fluid">
            <h4 class="servicesheadresp">Diagnosis Services</h4>
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-ride="carousel"
              data-interval="2700"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-sm-3 servicesresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 72.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">Pneumothorax</Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <Link to="/PneumothoraxDS">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 servicesresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle73.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">
                              Pulmonary Edema
                            </Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <Link to="/PulmonaryedemaDS">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 servicesresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle74.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">Bronchitis</Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <Link to="/BronchitisDS">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 lastserviceresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle75.png"
                            style={{
                              marginTop: "10px",
                              marginBottom: "-8px",
                            }}
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">Pneumonia</Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <Link to="/PneumoniaDS">
                              {" "}
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-sm-3 servicesresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 72.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">Pneumothorax</Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 servicesresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle73.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">
                              Pulmonary Edema
                            </Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 servicesresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle74.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">Bronchitis</Card.Title>
                          </center>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 lastserviceresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle75.png"
                          />
                        </center>
                        <Card.Body>
                          <center>
                            <Card.Title class="cardp">Pneumonia</Card.Title>
                          </center>
                          <br></br>

                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="container-fluid">
        <div class="card">
          <div class="container-fluid">
            <h4 class="pulmonaryheadresp">Pulmonary Treatment</h4>

            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-ride="carousel"
              data-interval="2700"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-sm-3 pulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 87.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Pulmonary Critical care Medicine
                          </Card.Title>
                          <br></br>
                          <center>
                            <Link to="/PulmonaryCriticalcarePT">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 pulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 88.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Interventional Pulmonology
                          </Card.Title>
                          <br></br>
                          <center>
                            <Link to="/IntreventionalPulmonology">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 pulmonaryresp">
                      <Card>
                        <center>
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 90.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Lung transplant Medication
                          </Card.Title>
                          <br></br>
                          <center>
                            <Link to="Lungtransplant">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 lastpulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 91.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Gene Therapy
                          </Card.Title>{" "}
                          <br></br>
                          <center>
                            <Link to="/GeneTherapy">
                              <button class="custom-btn1 btn-3 topbookingbutton">
                                <span class="btntexty">Book Now</span>
                              </button>
                            </Link>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-sm-3 pulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 87.png"
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Pulmonary Critical care Medicine
                          </Card.Title>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 pulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 88.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Interventional Pulmonology
                          </Card.Title>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 pulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 90.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Lung transplant Medication
                          </Card.Title>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                    <div class="col-sm-3 lastpulmonaryresp">
                      <Card>
                        <center>
                          {" "}
                          <Card.Img
                            variant="top"
                            class="diaimg"
                            src="../style/img/resp/Rectangle 91.png"
                            style={{ marginTop: "10px" }}
                          />
                        </center>
                        <Card.Body>
                          <Card.Title
                            class="cardp"
                            style={{ textAlign: "center" }}
                          >
                            Gene Therapy
                          </Card.Title>
                          <br></br>
                          <center>
                            <button class="custom-btn1 btn-3 topbookingbutton">
                              <span class="btntexty">Book Now</span>
                            </button>
                          </center>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div></div>
            </div>
         </div>
        </div>
      </div> */}

      {/* <div class="card extend">
        <div class="container pullcrd" style={{ marginBottom: "20px" }}>
          <br></br>
          <h3 class="heading1 text-dark ">
            Book an Appointment for Respiro Clinic Respiratory Consultation
          </h3>
          <p class="para text-dark consultsubheading">
            Find experienced respiro doors across all specialties
          </p>
          <br></br>
          <div class="row">
            <div class="col-sm-3 ">
              <img
                src="../style/img/resp/Frame8.png"
                class="bookimg"
                alt=""
              // style={{ marginLeft: "25px" }}
              />
              <br></br>
              <center>
                {" "}
                <h6 class="cardptext1 text-dark">Respiratory Specialist</h6>
              </center>
              <hr></hr>
              <p
                class="cnsltpara"
                style={{
                  fontSize: "14px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {" "}
                Need guidance on respiratory friendly diet? Consult our
                respiratory dietition.
              </p>
              <center>
                <br></br>
                <Link to="/Finddoctor">
                  <button class="custom-btn1 btn-3 bookbtn ">
                    <span>CONSULT NOW</span>
                  </button>
                </Link>
              </center>
            </div>

            <div class="col-sm-3">
              <img
                src="../style/img/resp/Frame5.png"
                class="bookimg"
                alt=""
              // style={{ marginLeft: "25px" }}
              />
              <br></br>
              <center>
                {" "}
                <h6 class="cardptext1 text-dark">
                  Pediatric Respiratory Care
                </h6>
              </center>
              <hr></hr>

              <p
                class="cnsltpara"
                style={{
                  fontSize: "14px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {" "}
                Struggling with respiratory issues? Schedule a consultation.
              </p>

              <center>
                <br></br>
                <Link to="/Finddoctor">
                  <button class="custom-btn1 btn-3 bookbtn  ">
                    <span>CONSULT NOW</span>
                  </button>
                </Link>
              </center>
            </div>

            <div class="col-sm-3">
              <img
                src="../style/img/resp/Frame6.png"
                class="bookimg"
                alt=""
              // style={{ marginLeft: "25px" }}
              />
              <br></br>
              <center>
                {" "}
                <h6 class="cardptext1 text-dark"> Respiratory Nutrition</h6>
              </center>
              <hr></hr>

              <p
                class="cnsltpara"
                style={{
                  fontSize: "14px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                {" "}
                Respiratory concern for your child? Book a pediatric
                consultation.
              </p>

              <center>
                <br></br>
                <Link to="/Finddoctor">
                  <button class="custom-btn1 btn-3 bookbtn ">
                    <span>CONSULT NOW</span>
                  </button>
                </Link>
              </center>
            </div>

            <div class="col-sm-3">
              <img
                src="../style/img/resp/Frame7.png"
                class="bookimg"
                alt=""
              // style={{ marginLeft: "25px" }}
              />
              <br></br>
              <center>
                {" "}
                <h6 class="cardptext1 text-dark">
                  Respiratory Physiotherapy
                </h6>
              </center>
              <hr></hr>
              <p
                class="cnsltpara"
                style={{
                  fontSize: "14px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Need guidance on respiratory friendly diet? Consult our
                respiratory dietition.
              </p>
              <center>
                <br></br>
                <Link to="/Finddoctor">
                  <button class="custom-btn1 btn-3 bookbtn ">
                    <span>CONSULT NOW</span>
                  </button>
                </Link>
              </center>
            </div>
          </div>
        </div>
              </div> */}
      <div class="container-fluid">
        <div class="card">
          <div class="container-fluid">
            <br></br> <br></br>
            <div class="row">
              <div class="col-md-3">
                <div class="doctorcenter">
                  <h4 class="head">
                    Explore Top <br />
                    Articles From <br />
                    Respiratory Health Experts
                    <br />
                  </h4>
                  <br></br>
                  <span class="spanhead">
                    Stay updated about respiratory health practices and your
                    wellness{" "}
                  </span>
                  <br />
                  <br />

                  {/* <button class="articlebtn">View All Articles</button> */}
                </div>
              </div>
              <div class="col-sm-3 article">
                <img
                  src="../style/img/resp/Frame11.png"
                  class="articleimg"
                  alt=""
                />
                {/* <img
                  src="../style/img/resp/Ellipse 2.png"
                  class="profileimg"
                  alt=""
                /> */}
                <div class="arti_content">
                  <br></br>
                  <span style={{ color: "blueviolet", fontWeight: "bold" }}>
                    Dr.Thomas Kurian
                  </span>
                  <div>
                    Mastering Techniques for Optimal <br /> Lung Health
                  </div>
                  <span style={{ color: "grey", fontSize: "15px" }}>
                    May 01, 2023{" "}
                  </span>
                  <div style={{ marginLeft: "-30px" }}>
                    <ul>
                      <li>Breathing techniques for lung health.</li>
                      <li>
                        Learn more effectively breathing
                        <br /> exercises to improve lung functions <br /> and
                        overall well being.
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
                <Link to="/ArticleOne">
                  <button
                    class="articlebtn"
                    style={{ backgroundColor: "#a97dd1" }}
                  >
                    Learn More{" "}
                  </button>
                </Link>
                <br />
                <span
                  style={{
                    textAlign: "justify",
                    position: "relative",
                    top: "15px",
                  }}
                ></span>
              </div>
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Frame12.png"
                  class="articleimg"
                  alt=""
                />
                {/* <img
                  src="../style/img/resp/Ellipse 5.png"
                  class="profileimg"
                  alt=""
                /> */}
                <div class="arti_content">
                  <br></br>
                  <span style={{ color: "blueviolet", fontWeight: "bold" }}>
                    Dr.David Johnson
                  </span>
                  <div>
                    Breathe Easy: Nutrition Tips for
                    <br /> Respiratory Wellness
                  </div>
                  <span style={{ color: "grey", fontSize: "15px" }}>
                    May 01, 2023{" "}
                  </span>
                  <div style={{ marginLeft: "-30px" }}>
                    <ul>
                      <li>Nutrition tips for respiratory wellness.</li>
                      <li>
                        Discover dietary strategies to support respiratory
                        health concern and boost immunity.
                      </li>
                      <br></br>
                      <br></br>
                    </ul>
                  </div>
                </div>

                <Link to="/ArticleOne">
                  <button
                    class="articlebtn"
                    style={{ backgroundColor: "#a97dd1" }}
                  >
                    Learn More{" "}
                  </button>
                </Link>
                <br />
                <span
                  style={{
                    textAlign: "justify",
                    position: "relative",
                    top: "15px",
                  }}
                ></span>
              </div>
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Frame13.png"
                  class="articleimg"
                  alt=""
                />
                {/* <img
                  src="../style/img/resp/Ellipse 4.png"
                  class="profileimg"
                  alt=""
                /> */}
                <div class="arti_content">
                  <br></br>
                  <span style={{ color: "blueviolet", fontWeight: "bold" }}>
                    Dr.Emily Davis
                  </span>
                  <div>
                    Lung Diseases: Overview & <br />
                    Prevention
                  </div>
                  <span style={{ color: "grey", fontSize: "15px" }}>
                    May 01, 2023{" "}
                  </span>
                  <div style={{ marginLeft: "-30px" }}>
                    <ul>
                      <li>
                        A commonly found disease is pulmonary fibrosis
                        -symptoms, risks, treatments.
                      </li>
                      <li>
                        Emphasize on prevention include smoking cessation and
                        vaccination.
                      </li>
                      336
                      <br />
                    </ul>
                    <Link to="/ArticleOne">
                      <button
                        class="articlebtn"
                        style={{
                          backgroundColor: "#a97dd1",
                          marginLeft: "29px",
                        }}
                      >
                        Learn More{" "}
                      </button>
                    </Link>
                    <br />
                    <span
                      style={{
                        textAlign: "justify",
                        position: "relative",
                        top: "15px",
                      }}
                    ></span>
                  </div>
                </div>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card p-4">
          <h4 className="text-center mb-4" style={{ fontSize: "28px" }}>
            Testimonials from our patients
          </h4>
          <div className="row g-4">
            <div className="col-sm-2">
              <div
                className="card p-3 text-center "
                style={{ backgroundColor: "#e3f2fd" }}
              >
                <p>"Simple yet powerful, a game-changer for caregivers"</p>
                <h6 className="mt-3">Lily James</h6>
                <p>Kozhikode</p>
              </div>
            </div>
            <div className="col-sm-3">
              <img
                src="../style/img/resp/Rectangle56.png"
                className="img-fluid"
                alt="testimonial"
              />
            </div>
            <div className="col-sm-2">
              <div
                className="card p-3  text-white"
                style={{ backgroundColor: "#e3f2fd" }}
              >
                <p>
                  “Whether you're newly diagnosed or a seasoned veteran in
                  managing lung conditions, this app has something for
                  everyone.”
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card p-3 " style={{ backgroundColor: "#e3f2fd" }}>
                <p>
                  "Respiro Healthcare provides expert respiratory care through
                  specialized doctors and dedicated practitioners, including
                  Rapid Heal Force (RHF) nurses who offer in-home respiratory,
                  cardiac, and diabetic services. We also provide medical
                  devices for rent or sale and have a network of professionals
                  committed to your respiratory health."
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4 mt-4">
            <div className="col-sm-2">
              <img
                src="../style/img/resp/Rectangle 62.png"
                className="img-fluid"
                alt="testimonial"
              />
            </div>
            <div className="col-sm-3">
              <div
                className="card p-3 text-center  text-white"
                style={{ backgroundColor: "#e3f2fd" }}
              >
                <p>
                  Respiro’s exceptional care changed my life. Their dedicated
                  team provided me with personalized treatment for my condition,
                  and I couldn’t be more grateful.
                </p>
                <p className="fw-bold">Irene Duke</p>
                <p>
                  <i>Thrissur</i>
                </p>
              </div>
            </div>
            <div className="col-sm-2">
              <img
                src="../style/img/resp/Rectangle 63.png"
                className="img-fluid mb-3"
                alt="testimonial"
              />
              <div className="card p-3 " style={{ backgroundColor: "#e3f2fd" }}>
                <p>
                  “Whether you're newly diagnosed or a seasoned veteran in
                  managing lung conditions, this app has something for
                  everyone.”
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <img
                src="../style/img/resp/Rectangle 65.png"
                className="img-fluid"
                alt="testimonial"
              />
            </div>
          </div>
        </div>
      </div>

      {/* indexhome */}
      <div class="container">
        <h1 style={{ fontSize: "28px" }}>Welcome to RespiroSense!</h1>
        <p>
          Assess your respiratory health with our AI-powered tool. Simply select
          your symptoms and their severity to receive personalized
          recommendations for better lung health.
        </p>
        <div className="symptomchecker-advanced-container">
          <h2>RespiroSense: Intelligent Symptom Evaluator</h2>
          <div className="symptomchecker-dropdown-container">
            <label class="text-dark">Select Your Symptoms:</label>
            <select
              className="symptomchecker-dropdown"
              value=""
              onChange={handleSymptomChange}
            >
              <option value="" disabled>
                -- Select Symptom --
              </option>
              {symptomsList.map((symptom) => (
                <option key={symptom} value={symptom}>
                  {symptom}
                </option>
              ))}
            </select>
          </div>
          {selectedSymptoms.length > 0 && (
            <div className="symptomchecker-symptoms-list">
              {selectedSymptoms.map((symptom) => (
                <div key={symptom} className="symptomchecker-symptom-item">
                  <span class="text-dark">{symptom}</span>
                  <select
                    className="symptomchecker-severity-dropdown"
                    onChange={(e) =>
                      handleSeverityChange(symptom, e.target.value)
                    }
                  >
                    <option value="" disabled>
                      -- Select Severity --
                    </option>
                    {severities.map((severity) => (
                      <option key={severity} value={severity}>
                        {severity}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          )}
          <div className="symptomchecker-actions">
            <button
              className="symptomchecker-analyze-btn"
              onClick={handleAnalyze}
              disabled={selectedSymptoms.length === 0}
            >
              Analyze Symptoms
            </button>
            <button className="symptomchecker-reset-btn" onClick={handleReset}>
              Reset
            </button>
          </div>
          {aiResponse && (
            <div className="symptomchecker-ai-response-container">
              <h3>RespiroSense(AI) Recommendation:</h3>
              <p>{aiResponse}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Indexhome;

