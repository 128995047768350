import { useRoutes } from "react-router-dom";
import Rhf from "../pages/rhf/Rhf";
import Rhfadmin from "../pages/rhf/Rhfadmin";
import RhfUnit from "../pages/rhf/RhfUnit";
import RhfMember from "../pages/rhf/RhfMember";
import RhfLocality from "../pages/rhf/RhfLocality";
import RhfLanding from "../pages/rhf/RhfLanding";
import RhfAddress from "../pages/rhf/RhfAddress";
import { RhfLogin, RhfSignup } from "../pages/auth/RhfLogin";
import RhfLogout from "../pages/auth/RhfLogout";
import RhfDashboard from "../pages/rhf/RhfDashboard";
import RhfProfile from "../pages/rhf/RhfProfile";
import RhfMemberProfile from "../pages/rhf/RhfMemberProfile";
import RhfSearchResult from "../pages/rhf/RhfSearchResult";
import RhfDetails from "../pages/rhf/RhfDetails";
import PendingUnitAppointments from "../pages/rhf/PendingUnitAppointments";
import RhfSlotBooking from "../pages/rhf/RhfSlotBooking";
import RhfSlotConfirmation from "../pages/rhf/RhfSlotConfirmation";
import RhfPayment from "../pages/rhf/RhfPayment";
import Rhfservicepage from "../pages/rhf/Rhfservicepage"




const RhfRoutes = () => {
  let routes = useRoutes([
    { path: "/rhf/landing", element: <RhfLanding /> },
    { path: "/Rhfadmin", element: <Rhfadmin /> },
    { path: "/Rhf", element: <Rhf /> },
    { path: "/RhfUnit", element: <RhfUnit /> },
    { path: "/RhfMember", element: <RhfMember /> },
    { path: "/rhf/member-profile", element: <RhfMemberProfile /> },
    { path: "/RhfLocality", element: <RhfLocality /> },
    { path: "/RhfAddress", element: <RhfAddress /> },
    { path: "/rhf/login", element: <RhfLogin /> },
    { path: "/rhf/signup", element: <RhfSignup /> },
    { path: "/rhf/logout", element: <RhfLogout /> },
    { path: "/rhf/profile", element: <RhfProfile /> },
    { path: "/rhf/dashboard", element: <RhfDashboard /> },
    { path: "/RhfSearchResult", element: <RhfSearchResult/> },
    { path : "/RhfDetails/:id" , element: <RhfDetails/>},
    { path : "/Rhfservicepage/" , element: <Rhfservicepage/>},


    { path : "/PendingUnitAppointments" , element: <PendingUnitAppointments/>},

    { path : "/RhfSlotBooking" , element: <RhfSlotBooking/>},
    { path : "/rhfslot-confirmation" , element: <RhfSlotConfirmation/>},
    { path : "/rhf/payment" , element: <RhfPayment/>},
   




   
    
    RhfSearchResult
  ]);
  return routes;
};

export default RhfRoutes;
