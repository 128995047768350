import React, { useEffect ,useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { getFunction } from "../../api/apiFunctions";

function PaymentStatus() {
 // const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { transactionId } = useParams();
  //const [responseUrl, setResponseUrl] = useState('');

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await getFunction({}, `/payment/payment-status/?transactionId=${transactionId}`);
         console.log('Payment Response:', response);

       if (response.success && response.success.result && response.success.result.responseUrl) {
         console.log('Response URL:', response.success.result.responseUrl);
           navigate(response.success.result.responseUrl);
       } else {
          console.log('No response URL found');
          navigate('/failure');
         }
      } catch (error) {
         console.log('Error fetching payment status:', error);
        navigate('/failure');
       }
     };

    fetchPaymentStatus();
  }, [transactionId, navigate]);

  
  









  // useEffect(() => {
  //   const fetchPaymentStatus = async () => {
  //     const params = new URLSearchParams(location.search);
  //     const transactionId = params.get('transactionId');

  //     try {
  //       const response = await axios.get(`/payment/status?transactionId=${transactionId}`);
  //       console.log('Payment Response:', response);

  //       if (response.success && response.success.result && response.success.result.responseUrl) {
  //         console.log('Response URL:', response.success.result.responseUrl);
  //         navigate(response.success.result.responseUrl);
  //       } else {
  //         console.log('No response URL found');
  //         navigate('/failure');
  //       }
  //     } catch (error) {
  //       console.log('Error fetching payment status:', error);
  //       navigate('/failure');
  //     }
  //   };

  //   fetchPaymentStatus();
  // }, [location, navigate]);

  // return <div>Loading...{transactionId}</div>;
  return (
    <div>
      <h1>Payment Status</h1>
      <p>Transaction ID: {transactionId}</p>
      {data ? (
        <div>
          <p>Response URL: <a href={data.responseUrl} target="_blank" rel="noopener noreferrer">{data.responseUrl}</a></p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {/* <div>{data ? `Response URL: ${data.responseUrl}` : 'Loading...'}</div> */}
    </div>
  );
}



export default PaymentStatus;
