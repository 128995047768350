import React from "react";

function Rehabilitation() {
  return (
    <>
      <br></br> <br></br>
      <section class="">
        <div class="container">
          <br></br>
          <br></br>
          <br></br>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/ch.jpg"
                 alt=""/>
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Child</font>
                    </a>
                  </h3>
                  <p class="ptext">
                    Children with breathing difficulties and lung problems can
                    benefit from pulmonary rehabilitation programs. The main
                    goal of these programs is to improve breathing, lung
                    function, and overall quality of life. Rehabilitation
                    requires a partnership-like relationship between patients,
                    healthcare providers, and parents or caregivers. The doctor
                    examines the child’s health and lung condition during the
                    patient assessment. In cases when a child doesn’t have a
                    diagnosed breathing-related condition, the doctor may order
                    necessary tests to diagnose the problem.
                  </p>
                  <br></br>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/me.jpg"
                 alt=""/>
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Youngsters</font>
                    </a>
                  </h3>
                  <p class="ptext">
                    Since every Adult is different, the exact course of
                    pulmonary rehabilitation may vary too. Some adults have
                    serious health conditions that require a more rigorous
                    approach, whereas others benefit the most from an easy
                    program. The doctor creates a pulmonary rehabilitation plan
                    according to each one’s specific needs. Pulmonary
                    rehabilitation also involves consistent monitoring of the
                    adult’s condition. Communication with parents or caregivers
                    plays an important role here. The goal is to analyze the
                    progress and adjust the rehabilitation plan if necessary.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/ma.jpg"
                 alt=""/>
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Middle Age</font>
                    </a>
                  </h3>
                  <p class="ptext">
                    Every pulmonary rehabilitation program is unique to the
                    specific needs of each patient. The most common elements of
                    pulmonary rehabilitation include patient assessment,
                    treatment planning, consistent communication, and regular
                    sessions. At the end of your program, your healthcare team
                    will give you tests to check your lung function again to see
                    if your breathing has improved. Some of these tests, such as
                    exercise tests, will be the same ones you had at the start
                    of your program
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="card card-team">
                <img
                  class="card-img rounded-0"
                  src="./style/img/banner/ed.jpg"
                 alt=""/>
                <div class="card-team__body text-center">
                  <h3>
                    <a href="#">
                      <font color="#204ea0">Elderly</font>
                    </a>
                  </h3>

                  <p class="ptext">
                    Rehabilitation of elderly people aims to restore function or
                    enhance residual functional capacity and improve the
                    capacity in older people ie particularly those with
                    disabling impairments and/or frailty. Rehabilitation of
                    older adults patients can assist in preserving functional
                    independence and improving the quality of life.
                    Rehabilitation of the older adult should: emphasize
                    functional activity to maintain functional mobility and
                    capability; improvement of lung health through exercise and
                    functional activity programs good nutrition and good general
                    care and social and emotional support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <div class="container">
        <div class="area-heading row">
          <div class="col-md-5 col-xl-4">
            <h3>
              <font color="#204ea9">
                What happens during pulmonary <br />
                rehabilitation?
              </font>
            </h3>
          </div>
          <div class="col-md-7 col-xl-8">
            <blockquote class="generic-blockquote">
              To help design your pulmonary rehabilitation plan, your healthcare
              team may do one of the following tests:<br></br>
              Exercise stress test to measure your oxygen level, blood pressure,
              and heart rate while you exercise
              <br />
              Breathing tests to check how well your lungs are working
              <br />
              Six-minute walk test to measure how far you can walk in six
              minutes
            </blockquote>
          </div>
        </div>

        <p class="ptext1">
          You may have pulmonary rehabilitation in the hospital or a clinic, or
          you may learn physical therapy or breathing exercises to do at home.
          You may also use activity monitors or smartphone-based lessons or
          monitoring.
        </p>
        <br />
        <div class="row">
          <div class="col-lg-12">
            <blockquote class="generic-blockquote">
              Your pulmonary rehabilitation plan may include the following
              training and education:
              <br />
              <br />
              Breathing techniques
              <br />
              Education In group or individual sessions, your providers can
              answer questions about your lung disease and offer guidance for
              how to manage it.
              <br />
              Psychological counseling
              <br />
              Exercise training
              <br />
              Nutritional counseling
            </blockquote>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default Rehabilitation;
