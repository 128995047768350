import React from "react";

function Homeresponsive() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="card cardheight">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <br />
                    <h4 class="text1">Book a Test</h4>
                    <p className="" style={{ fontSize: "13px" }}>
                      Search deals on hotels, homes, and much more...
                    </p>
                    <button className="main_btn1">Book Now</button>
                  </div>
                  {/* <div className="col-sm-4">
                          <img src="./style/img/banner/aswa.png" className="fcardimg" alt="Banner" />
                        </div> */}
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-sm-12">
            <br></br>
            <div className="card cardheight">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <br />
                    <h4 class="text1">Book a RHF</h4>
                    <p className="" style={{ fontSize: "13px" }}>
                      Search deals on hotels, homes, and much more...
                    </p>
                    <button className="main_btn1">Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="card cardheight">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <br />
                    <h4 class="text1">Book a Therapist</h4>
                    <p className="" style={{ fontSize: "13px" }}>
                      Search deals on hotels, homes, and much more...
                    </p>
                    <button className="main_btn1">Book Now</button>
                  </div>
                  {/* <div className="col-sm-4">
                        <img src="./style/img/banner/Therapist.png" className="fcardimg" alt="Banner" />
                      </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <br></br>
            <div className="card cardheight">
              <div className="container">
                <div className="row">
                  <div className="col-sm-8">
                    <br />
                    <h4 class="text1">Book a Pulmonologist</h4>
                    <p className="" style={{ fontSize: "13px" }}>
                      Search deals on hotels, homes, and much more...
                    </p>
                    <button className="main_btn1">Book Now</button>
                  </div>
                  {/* <div className="col-sm-4">
                        <img src="./style/img/banner/abh.jpg" className="fcardimg" alt="Banner" />
                      </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homeresponsive;
