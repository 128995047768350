import React from "react";
function Comingsoon() {
  return (
    <>
      <br></br>

      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              class="d-block w-100 vip"
              src="./style/img/banner/come.jpg"
              alt="First slide"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Comingsoon;
