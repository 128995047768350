import React from "react";

function Padmin() {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <nav class="nav_menu">
        <div class="nav_menu_cont">
          <div class="nav_menu_list">
            <h3 class="nav_menu_title">General</h3>
            <ul>
              <li class="nav_tema pest">Tema</li>
              <li class="subtema subPest">Subtema</li>
            </ul>
            <ul>
              <li class="nav_tema pest">Tema</li>
              <li class="subtema subPest">Subtema</li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="dashboard">
        <main class="top_menu">
          <div class="top_menu_title">Admin | Dash</div>

          <div class="top_menu_options">
            <div class="top_menu_opt">
              <span class="pest">Mail</span>
              <div class="subPest top_menu_pest">
                <ul>
                  <li class="top_menu_subPest">Opción</li>
                  <li class="top_menu_subPest">Opción</li>
                </ul>
              </div>
            </div>
            <div class="top_menu_opt">
              <img
                class="user_img pest"
                src="http://www.lovemarks.com/wp-content/uploads/profile-avatars/default-avatar-tech-guy.png"
                alt=""
              />

              <div class="subPest top_menu_pest">
                <ul>
                  <li class="top_menu_subPest">Opción</li>
                  <li class="top_menu_subPest">Salir</li>
                </ul>
              </div>
            </div>
          </div>
        </main>

        <div class="content">
          <div class="row">
            <div class="col">
              <div class="box">Curso #1</div>
            </div>
            <div class="col">
              <div class="box">Curso #2</div>
            </div>
            <div class="col">
              <div class="box">Curso #3</div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="box">
                {" "}
                <img
                  src="http://www.excel-easy.com/data-analysis/images/charts/column-chart.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col">
              <div class="box">
                {" "}
                <img
                  src="https://fluidsurveys.com/wp-content/uploads/2013/09/Charts-PC.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col">
              <div class="box">
                {" "}
                <img
                  src="https://fluidsurveys.com/wp-content/uploads/2013/09/Charts-PC.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-50">
              <div class="box">
                {" "}
                <img
                  src="https://krispo.github.io/angular-nvd3/img/stackedAreaChart.png"
                  alt=""
                />
              </div>
            </div>
            <div class="col-50">
              <div class="box">
                {" "}
                <img
                  src="https://www.zoho.com/reports/images/line-chart.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <footer class="footer"></footer>
      </div>
    </div>
  );
}

export default Padmin;
