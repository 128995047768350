import { useRoutes } from "react-router-dom";
import RentDetail from "../pages/o2/RentDetail";
import ProductDetail from "../pages/o2/ProductDetail";
import MusicDetail from "../pages/o2/MusicDetail";
import ArtistDetail from "../pages/o2/ArtistDetail";
import WorkerDetail from "../pages/o2/WorkerDetail";
import SaleDetail from "../pages/o2/SaleDetail";
import O2 from "../pages/o2/O2";
import O2_rent from "../pages/o2/O2_rent";
import O2_sale from "../pages/o2/O2_sale";
import Subcatagory from "../pages/o2/Subcatagory";
const O2Routes = () => {
  let routes = useRoutes([
    
    { path: "/o2", element: <O2 /> },
    { path: "/products/:id", element: <ProductDetail /> },
    { path: "/music/:id", element: <MusicDetail /> },
    { path: "/artist/:id", element: <ArtistDetail /> },
    { path: "/worker/:id", element: <WorkerDetail /> },

    { path: "/productsa/:id", element: <RentDetail /> },
    
    { path: "/sale/:id", element: <SaleDetail/> },
    { path: "/O2_rent", element: <O2_rent /> },
    { path: "/O2_sale", element: <O2_sale /> },
    { path: "/Subcatagory", element: <Subcatagory /> },

  ]);
  return routes;
};

export default O2Routes;
