const MusicianData = [
    {
      id: 1,
      title: "Breath Trainer Device for Wind Instrument Players (One Time Purchase)",
      price: 1800,
      desc: "Enhance your breathing control with a specialized trainer device designed for wind instrument players.",
      img:"../style/img/resp/musicianbg1.png"
    },
    {
      id: 2,
      title: "Online Breathing Technique Workshop for Singers (One-time Fee)",
      price: 400,
      desc: "Join our expert-led online workshop to master breathing techniques for singers.       ",
      img:"../style/img/resp/musicianbg2.png"
    },
    {
      id: 3,
      title: "Musician's Chair with Lumbar Support",
      price: 5500,
      desc: "Optimize your performance with a chair designed for musicians, featuring lumbar support for comfort during long sessions.",
      img:"../style/img/resp/musicianbg3.png"
    },
    {
      id: 4,
      title: "Adjustable Music Stand with Angle Control",
      price: 3200,
      desc: "Achieve optimal viewing angles with this adjustable music stand.",
      img:"../style/img/resp/musicianbg4.png"
    },
    {
      id: 5,
      title: "Guitar Strap with Breathable  Material for Comfort",
      price: 800,
      desc: "Enhance your playing comfort with a breathable guitar strap.",
      img:"../style/img/resp/musicianbg5.png"
    },
    {
      id: 6,
      title: "  HEPA Air Purifier with Noise Reduction Technology for Practice Rooms",
      price: 6000,
      desc: " Ensure clean and quiet practice environments with this advanced HEPA air purifier designed for musicians.",
      img:"../style/img/resp/musicianbg6.png"
    },
    {
      id: 7,
      title: "Air-Purifying Plants Specifically Selected for Musicians (Set of 3)",
      price:1200,
      desc: "Enhance your practice space with these air-purifying plants selected for their air-cleansing properties.",
      img:"../style/img/resp/musicianbg7.png"
    },
    {
      id: 8,
      title: "Hygrometer and Thermometer Combo for Room Climate Control ",
      price: 750,
      desc: "Monitor and control the climate of your practice room with this combo device. ",
      img:"../style/img/resp/musicianbg8.png"
    },
    {
      id: 9,
      title: "Musician's Meditation App/website Subscription with Breathing Exercise",
      price: "800/year",
      desc: "Subscribe to our meditation app designed specifically for musicians, offering tailored breathing exercises.",
      img:"../style/img/resp/musicianbg9.png"
    },
    {
      id: 10,
      title: "Aromatherapy Diffuser with Essential  Oils for Relaxation and Focus",
      price: 300,
      desc: "Create a calming practice environment with an aromatherapy diffuser and essential oils.",
      img:"../style/img/resp/musicianbg10.png"
    },
    {
      id: 11,
      title: "Yoga for Musicians Online Classes with Breathing Techniques",
      price: 900,
      desc: "Participate in online yoga classes specifically designed for musicians, focusing on breathing techniques.",
      img:"../style/img/resp/musicianbg11.png"
    },
    {
      id: 12,
      title: "Organic Snack Box Subscription  (Monthly) with Nutrient-Rich Snacks",
      price: "1500/month",
      desc: "Subscribe to our monthly snack box filled with nutrient-rich snacks, perfect for musicians to maintain energy and health.",
      img:"../style/img/resp/musicianbg12.png"
    },
    {
      id: 13,
      title: "Fitness Tracker Smartwatch with Musician-friendly Features and Lung Health Tracking",
      price: 3000,
      desc: "Stay on top of your health with this fitness tracker smartwatch, designed with features tailored for musicians, including lung health tracking. ",
      img:"../style/img/resp/musicianbg13.png"
    },
    {
      id: 14,
      title: "Personalized Nutrition Consultation Session Tailored to Musicians' Dietary Needs",
      price: 800,
      desc: "Get a personalized nutrition consultation designed to meet the specific dietary needs of musicians.",
      img:"../style/img/resp/musicianbg14.png"
    },
    {
      id: 15,
      title: "Personalized Breathing Assessment with a Focus on Musicians' Respiratory Needs",
      price: 1200,
      desc: "A thorough breathing assessment specifically designed for musicians, focusing on enhancing respiratory function and performance.",
      img:"../style/img/resp/musicianbg15.png"
    },
    {
      id: 16,
      title: "Performance Venue Air Quality Evaluation with Acoustic and Lung Health Considerations",
      price: 2500,
      desc: "An evaluation of the air quality in performance venues, considering both acoustic properties and lung health for optimal performance conditions.",
      img:"../style/img/resp/musicianbg16.png"
    },
    {
      id: 17,
      title: "Special Lung Care Package For Musicians",
      price: 999,
      desc: "Our Tailored Respiratory Care Bundle for Musicians is designed to cater to the distinctive respiratory and wellness requirements of musicians, especially those engaged in playing wind instruments and vocal performances.",
      img:"../style/img/resp/musicianbg17.png"
    },
   
    
  ];
  
  export default MusicianData;
  