import { useRoutes } from "react-router-dom";

import { AdminLogin, AdminSignup } from "../pages/auth/AdminLogin";
// import { AdminLogout} from "../pages/auth/AdminLogout";
import AdminDashboard from "../pages/admin/AdminDashboard";
import Dashboard from "../pages/admin/Dashboard";
import ListallProviders from "../pages/admin/ListallProviders";
import ListallRhf from "../pages/admin/ListallRhf";
import ViewProviderDetails from "../pages/admin/ViewProviderDetails";
import ViewRhfDetails from "../pages/admin/ViewRhfDetails";
import AdminLogout from "../pages/auth/AdminLogout";
import ViewAreaCoordinators from "../pages/admin/ViewAreaCoordinators";
import ViewUnits from "../pages/admin/ViewUnits";
const AdminRoutes = () => {
    let routes = useRoutes([
       
        { path: "/admin/login", element: <AdminLogin /> },
        { path: "/admin/dash", element: <Dashboard /> },
        { path: "/admin/signup", element: <AdminSignup /> },
        { path: "/admin/dashboard", element: <AdminDashboard /> },
        { path: "/admin/allproviders", element: <ListallProviders/>},
        { path: "/admin/allrhf" , element: <ListallRhf />},
        { path: "/admin/providerdetails/:id" , element: <ViewProviderDetails/>},
        { path: "/admin/rhfdetails/:id" , element: <ViewRhfDetails/>},
        { path: "/admin/logout", element: <AdminLogout /> },
        { path: "/admin/ViewAreaCoordinators/:id", element: <ViewAreaCoordinators/> },
        { path: "/admin/ViewUnits/:id", element: <ViewUnits/> },


        
      ]);
      return routes;
};

export default AdminRoutes;