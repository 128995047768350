import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import validator from "validator";
import { Link } from "react-router-dom";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus, getAuthTokens } from "../../helpers/authFunctions";
import { AuthContext } from "../../context/authContext";
import withCoordinatorAuth from "../../middleware/withCoordinatorAuth";
import CoordinatorHeader from "../header/CoordinatorHeader";


function Dashboard() {
  const {
    coordinatorId,
    setCoordinatorId,
    coordinatorEmail,
    setCoordinatorEmail,
    setIdToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });


  const [existingProvider, setExistingProvider] = useState(true);
 
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    if (accessToken && refreshToken && coordinatorId) {
      // getProfile(accessToken, refreshToken, coordinatorId);
    }
  }, [accessToken, refreshToken, coordinatorId]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/coordinator/get-token", "coordinator")
        .then((data) => {
          setCoordinatorId(data.sub);
          setCoordinatorEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("coordinatorEmail", data.email);
          localStorage.setItem("coordinatorId", data.sub);
          // getProfile(data.accessToken, data.refreshToken, data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);





  return (
    <>
   <CoordinatorHeader/>
    <div class="grid">
        <aside class="sidenav">
          <div class="container">
            <br></br>
            <br></br>
            <div class="container">
              <div className="logo">
                <Link to="/home">
                  <img src="../style/img/logonav.png" alt="" />
                </Link>
              </div>
            </div>
            <div class="sidenav__profile">
              <div class="sidenav__profile-title text-dark">
                {data.firstName}&nbsp;{data.lastName}
              </div>
            </div>
            
          </div>
        </aside>

        <main class="">
          <br></br>
          <div class="main-header">
            <div class="main-header__intro-wrapper">
              <div class="main-header__welcome">
                <div class="main-header__welcome-title text-light">
                  Welcome,{" "}
                  <strong>
                    {data.firstName}&nbsp;{data.lastName}
                  </strong>
                </div>
                <div class="main-header__welcome-subtitle text-light">
                  How are you today?
                </div>
              </div>
              <div class="quickview">
                <div class="quickview__item">
                  <div class="quickview__item-total">41</div>
                  <div class="quickview__item-description">
                    <i class="far fa-calendar-alt"></i>
                    <span class="text-light">Events</span>
                  </div>
                </div>
                <div class="quickview__item">
                  <div class="quickview__item-total">64</div>
                  <div class="quickview__item-description">
                    <i class="far fa-comment"></i>
                    <span class="text-light">Messages</span>
                  </div>
                </div>
                <div class="quickview__item">
                  <div class="quickview__item-total">27&deg;</div>
                  <div class="quickview__item-description">
                    <i class="fas fa-map-marker-alt"></i>
                    <span class="text-light">Austin</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="main__cards">
            {/* <UpcomingAppointment /> */}
            
           
          </div>
        </main>

        <footer class="footer">
          <p>
            <span class="footer__copyright">&copy;</span> 2018 MTH
          </p>
         
        </footer>
      </div>
    </>
    
  )
}

export default withCoordinatorAuth (Dashboard)