import React from "react";
import Header from "../header/Header";
import { IoHome } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
function Contact() {
  return (
    <>
    <Header></Header>
      <br></br>
      <section class="contact-section mypading">
        <div class="container">
          <div class="d-none d-sm-block mb-5 pb-4">
            <div id="map" class="mapheight"></div>

            <script src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926.816746097579!2d76.38638837379985!3d10.195532489920094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b08065f911cf53f%3A0xfa6240188af82f9f!2sGolden%20Plaza%20Building!5e0!3m2!1sen!2sin!4v1721884810564!5m2!1sen!2sin"></script>
          </div>
          <div class="contactgapresp"></div>
          <div class="row">
            <div class="col-12">
              <h2 class="contact-title">Get in Touch</h2>
            </div>
            <div class="col-lg-8">
              <form
                class="form-contact contact_form"
                action="contact_process.php"
                method="post"
                id="contactForm"
                novalidate="novalidate"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <textarea
                        class="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        class="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        class="form-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter email address"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        class="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <button type="submit" class="button button-contactForm">
                    Send Message
                  </button>
                </div>
              </form>
            </div>

            <div class="col-lg-4">
              <div class="media contact-info">
                <span class="contact-info__icon">
                <IoHome class="text-dark"/>


                </span>
                <div class="media-body">
                  <h3><b class="text-dark">Address</b></h3>
                  <p>
                    Pedro Labs Pvt Ltd, 2nd Floor, Gloden Plaza, TB Junction,
                    Angamaly, Cochin{" "}
                  </p>
                </div>
              </div>
              <div class="media contact-info">
                <span class="contact-info__icon">
                <IoCallSharp class="text-dark"/>

                </span>
                <div class="media-body">
                  <h3>
                    <a href="tel:454545654">00 (440) 9865 562</a>
                  </h3>
                  <p></p>
                </div>
              </div>
              <div class="media contact-info">
                <span class="contact-info__icon">
                <IoMail class="text-dark"/>

                </span>
                <div class="media-body">
                  <h3>
                    <a href="mailto:.com">labs.respiro@gmail.com</a>
                  </h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
