import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { getFunction } from "../../api/apiFunctions";
import { FaArrowRightLong } from "react-icons/fa6";

function Rhfresp() {
  const navigate = useNavigate();
  const [locationInput, setLocationInput] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [service, setService] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedlocValue, setSelectedlocValue] = useState("");

    useEffect(() => {
      const animateCounters = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const counters = document.querySelectorAll(".timer");
            counters.forEach((counter) => {
              const updateCount = () => {
                const target = +counter.getAttribute("data-target");
                const count = +counter.innerText;

                const speed = 200; // Speed of count up
                const increment = target / speed;

                if (count < target) {
                  counter.innerText = Math.ceil(count + increment);
                  setTimeout(updateCount, 30); // Adjust speed of counting
                } else {
                  counter.innerText = target; // Assign final number once reached
                }
              };

              updateCount();
            });
            // Stop observing once the animation has started
            observer.disconnect();
          }
        });
      };

      // Set up Intersection Observer to trigger animation when the counter section is in view
      const observer = new IntersectionObserver(animateCounters, {
        threshold: 0.5, // Trigger when 50% of the counter section is visible
      });

      const counterSection = document.querySelector(".counter-section");
      if (counterSection) {
        observer.observe(counterSection);
      }

      return () => {
        if (observer && observer.disconnect) {
          observer.disconnect();
        }
      };
    }, []);
   // Fetch service
   useEffect(() => {
    getFunction({}, "/rhf_service/get-service")
      .then((response) => {
        const responseData = response.success.result;
        setService(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const callSearchService = () => {
      if (locationInput) {
        getFunction({ place: locationInput }, "/locality/get-location-by-name")
          .then((response) => {
            const responseData = response.success.result;
            setLocationSuggestions(responseData);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLocationSuggestions([]);
      }
    };

    let debouncer = setTimeout(() => {
      callSearchService();
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [locationInput]);

  // Handle location selection
  const handleLocationSelect = (e) => {
    const selectedOption = locationSuggestions.find(
      (location) => location.id === e.target.value
    );

    if (selectedOption) {
      setSelectedLocation(selectedOption);
      setLocationInput(selectedOption.locality);
      setLocationSuggestions([]);
    }
  };


  // Log location input
  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocationInput(value);
    console.log("Location Input:", value); // Log the location input
  };

  const handleServiceChange = (event) => {
    const serviceValue = event.target.value;
    setSelectedValue(serviceValue);

    const locationValue = selectedLocation
      ? selectedLocation.locality
      : locationInput;
    setSelectedlocValue(locationValue);

    const data = { loc: locationValue, serv: serviceValue };
    navigate("/RhfSearchResult", { state: data });
    console.log("Selected Service:", serviceValue); 
    console.log("Selected Location:", locationValue); 
  };

  return (
    <>
      <Header></Header>
      <div class="container-fluid bg3">
        <br></br>
        <div class="rhfban">
          <br></br> <br></br>
          <center>
            <h4 class="text-white ">Welcome to Rapid Heal Force </h4>
          </center>
          <br></br>
          <center>
            <h5 class="text-white">Find RHF specialists near you</h5>
          </center>
          <br></br>
          <div class="container">
            <div class="row no-gutters">
              <div class="col-sm-6 location">
                {/* <div class="form-outline" data-mdb-input-init> */}
                <input
                  type="text"
                  id="form1"
                  className="searche"
                  placeholder="Select Location"
                  value={locationInput}
                  onChange={handleLocationInputChange}
                />
                {locationSuggestions.length > 0 && (
                  <div className="suggestions-dropdown">
                    {locationSuggestions.map((item) => (
                      <button
                        key={item.id}
                        value={item.id}
                        onClick={handleLocationSelect}
                      >
                        {item.locality}, {item.districtname}, {item.state}
                      </button>
                    ))}
                  </div>
                )}

                {/* </div> */}
              </div>
              <div className="col-sm-6">
                {/* <div className="form-outline" data-mdb-input-init> */}
                <select
                  className="form-control findcontorl"
                  value={selectedValue}
                  onChange={handleServiceChange}
                >
                  <option value="">Select Service</option>
                  {service.map((serv) => (
                    <option key={serv.id} value={serv.service_id}>
                      {serv.service_name}
                    </option>
                  ))}
                </select>
                {/* </div> */}
              </div>
            </div>
            <br></br>
            <br></br>
            <center>
              <p class="text-white">
                Popular Searches : RHF Pulmonologist | RHF Chest Physician | RHF
                Respiratory Therapist
              </p>
            </center>
            <br></br>

            <br></br>
          </div>
        </div>
      </div>
      <br></br>
      <div class="container">
        <div class="card rhfcard">
          <div class="container">
            <div class="container">
              <div class="row">
                <div class="col-sm-6">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="container rhfcontainer">
                    <h3 class=" text-light ">Who is RHF?</h3>
                    <p class="text-light  ">
                      RHF is a dedicated nurse providing essential respiratory
                      care at home, focusing on improving respiratory health and
                      making a positive impact on people's lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div class="rhfsebg">
        <div class="container">
          <br></br>
          <br></br>
          <center>
            <h2 class="rhfhed1">Services We Provide</h2>
            <br></br>
            <p>
              Experience compassionate care and expert guidance tailored to your
              individual needs, fostering holistic wellness and empowering you
              to thrive.
            </p>
          </center>
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 136.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Emergency Care @ Home</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFemergencyCare">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 137.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Routine Medical Check-up</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFroutineMedCheckup">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 138.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Sleep Study</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFsleepStudy">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <br></br>

            <div class="row">
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 139.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Pulmonary Function Test</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFpulmonaryFunctionTest">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 140.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">COPD Patient Care</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFCOPDpatientCare">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 141.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Chest Physiotherapy</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFchestPhysiotherapy">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <div class="row">
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 142.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Pneumonia Pediatric Care</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFpneumoniaPediatricCare">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 143.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Doctor Consultation @ Home</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFdoctorConsultation">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 144.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Spirometry Test</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFspirometryTest">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
                <br></br>
                <center>
                  <div class="">
                    <Link to="/Rhfservicepage">
                      <button style={{ borderRadius: "25px" }} class="">
                        {" "}
                        All Services&nbsp;{" "}
                        <FaArrowRightLong style={{ color: "text-dark" }} />
                      </button>
                    </Link>
                  </div>
                </center>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="card hor">
              <div class="container">
                <h2 clsss="">Social Impact</h2>
                <br></br>
                <p>
                  Nurses contribute to society's well-being by addressing
                  respiratory health disparities and positively impacting lives.
                  RHF supports research initiatives aimed at advancing
                  respiratory healthcare.
                </p>
                <br></br>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <img src="../style/img/resp/Rectangle155.png" class="socimg"></img>
          </div>
        </div>
      </div>
      <br></br> <br></br>
      <center>
        <h3>Technical Support</h3>
      </center>
      <div class="container">
        <br></br>
        <table class="table-fill1 tbl">
          <thead></thead>
          <tbody class="">
            <tr>
              <td class="text-left tbg">
                <h2 class="tblcontent text-dark">
                  Billing and Financial Management
                </h2>
              </td>

              <td class="text-left tbg1">
                <h2 class="text-dark tblcontent">Feedback and Rating System</h2>
              </td>
            </tr>

            <tr>
              <td class="text-left tbg">
                <h2 class="tblcontent text-dark">Online Booking Support</h2>
              </td>

              <td class="text-left tbg1">
                <h2 class=" text-dark tblcontent">Appointment Scheduling</h2>
              </td>
            </tr>
            <tr>
              <td class="text-left tbg">
                <h2 class="tblcontent text-dark">Patient Records Storage</h2>
              </td>

              <td class="text-leftresp tbg1">
                <h2 class=" text-dark tblcontent">Staff Tracking System</h2>
              </td>
            </tr>
            <tr>
              <td class="text-left tbg">
                <h2 class="tblcontent text-dark">Appointment Reminders</h2>
              </td>

              <td class="tb tbg1">
                <h2 class=" text-dark tblcontent">Patient Portal</h2>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
      <br></br>
      <div class="container">
        <h3 class="home_text">
          <font color="204ea0">Respiratory Wellness Corner</font>
        </h3>
        <p>Explore Essential Insights for Better Lung Health</p>
        <hr></hr>
        <div class="row ">
          <div class="col-sm-6">
            <Link to="/rhf/landing">
              <img
                className="home2bg"
                src="./style/img/banner/Group 301.jpg"
                alt="First slide"
              />
            </Link>
          </div>
        </div>
      </div>
      <section id="counter" className="counter-section content-section">
        <div className="container">
          <div className="row text-center mb-5">
            <div className="col-12">
              <h1 style={{ fontSize: "44px" }}>
                <b className="text-dark">Our Strength</b>
              </h1>
            </div>
          </div>
          <div className="row text-center justify-content-center" style={{width:"-20px"}}>
            <div className="col-6 col-md-3 mb-4">
              <div className="counter-wrap">
                <strong>
                  <span
                    className="timer"
                    data-target="100000"
                    style={{ fontSize: "45px" }}
                  >
                    0
                  </span>
                  +
                </strong>
                <span
                  className="count-description d-block mt-2"
                  style={{ fontSize: "25px" }}
                >
                  Rhf Nurses
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3 mb-4">
              <div className="counter-wrap">
                <strong>
                  <span
                    className="timer"
                    data-target="3000"
                    style={{ fontSize: "45px" }}
                  >
                    0
                  </span>
                  +
                </strong>
                <span
                  className="count-description d-block mt-2"
                  style={{ fontSize: "25px" }}
                >
                  Pediatric Pulmonologist
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3 mb-4">
              <div className="counter-wrap">
                <strong>
                  <span
                    className="timer"
                    data-target="7000"
                    style={{ fontSize: "45px" }}
                  >
                    0
                  </span>
                  +
                </strong>
                <span
                  className="count-description d-block mt-2"
                  style={{ fontSize: "25px" }}
                >
                  Pulmonologists
                </span>
              </div>
            </div>

            <div className="col-6 col-md-3 mb-4">
              <div className="counter-wrap">
                <strong>
                  <span
                    className="timer"
                    data-target="25000"
                    style={{ fontSize: "45px" }}
                  >
                    0
                  </span>
                  +
                </strong>
                <span
                  className="count-description d-block mt-2"
                  style={{ fontSize: "25px" }}
                >
                  Rhf Units
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Rhfresp;