import { useRoutes } from "react-router-dom";

import Finddoctor from "../pages/findDoctor/Finddoctor";
import SearchResult from "../pages/findDoctor/SearchResult";
import ProviderDetails from "../pages/findDoctor/ProviderDetails";
import SlotBooking from "../pages/findDoctor/SlotBooking";
import SlotConfirmation from "../pages/findDoctor/SlotConfirmation";
const FinddoctorRoutes = () => {
  let routes = useRoutes([{ path: "/Finddoctor", element: <Finddoctor /> },
  { path: "/SearchResult", element: <SearchResult /> },
  { path : "/ProviderDetails/:id" , element: <ProviderDetails />},
  { path : "/Slotbooking" , element: <SlotBooking />},
  { path : "/slot-confirmation" , element: <SlotConfirmation />},
]);

  return routes;
};

export default FinddoctorRoutes;