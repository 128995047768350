import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { checkAuthStatus } from "../helpers/authFunctions";
import { AuthContext } from "../context/authContext";
const withRhfAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const [isAuth, setIsAuth] = useState(undefined);
    const { accessToken, refreshToken } = useContext(AuthContext);
    const renewPath = "/auth/rhf/renew-token";
    const authType = "rhf";
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");

    useEffect(() => {
      if (WrappedComponent.name === "RhfDashboard" && code) {
        setIsAuth(true);
      } else {
        checkAuthStatus(accessToken, refreshToken, renewPath, authType)
          .then(() => {
            setIsAuth(true);
          })
          .catch((error) => {
            console.error("Authentication error:", error);
            setIsAuth(false);
          });
      }
    }, [accessToken, refreshToken, renewPath, authType]);
    if (isAuth) {
      return <WrappedComponent {...props} />;
    } else if (isAuth === false) { 
      return <Navigate to="/rhf/login" />;
    }
  };

  return WithAuth;
};

export default withRhfAuth;
