import React, { useEffect, useState, useContext } from "react";
import {
  setFunction,
  getFunction,
  updateFunction,
  deleteFunction,
} from "../../api/apiFunctions";
import Modal from "react-bootstrap/Modal";
import { AuthContext } from "../../context/authContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import withProviderAuth from "../../middleware/withProviderAuth";

function RhfLocality() {
  const [errors, setErrors] = useState({});
  const { rhfId, accessToken, refreshToken } = useContext(AuthContext);
  const [loc, setLoc] = useState([]);
  const [addLocation, setAddLocation] = useState(false);
  const [newLoc, setNewLoc] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
const [selectedSpec, setSelectedSpec] = useState(null);


  const [data, setData] = useState({
    rhfId: rhfId,
    localityId: "",
  });

  const [serviceArea, setServiceArea] = useState([]);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    getFunction({ rhfUnitId: rhfId }, "/rhf_locality/get-rhflocality")
      .then((response) => {
        const responseData = response.success.result;
        setServiceArea(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (newLoc) {
      getFunction({ rhfUnitId: rhfId }, "/rhf_locality/get-rhflocality")
        .then((response) => {
          const responseData = response.success.result;
          setServiceArea(responseData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [newLoc]);

  useEffect(() => {
    if (data.pincode && data.pincode.length < 6) {
      setLoc([]);
      setData({ ...data, place: "", district: "", state: "", localityId: "" });
    } else {
      getLocations(data.pincode);
    }
  }, [data.pincode]);

  useEffect(() => {
    const selectedLoc = loc.filter((item) => item.locality === data.place);
    console.log("selectedLoc", selectedLoc);
    selectedLoc.length > 0 &&
      setData({
        ...data,
        district: selectedLoc[0].districtname,
        state: selectedLoc[0].state,
        localityId: selectedLoc[0].id,
      });
  }, [data.place]);

  const getLocations = (pincode) => {
    getFunction({ pincode }, "/locality/get-location-by-pincode")
      .then((response) => {
        const responseData = response.success.result;
        setLoc(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeHandler = (e) => {
    console.log(e);
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const pinRegex = new RegExp(/^[1-9]\d{2}\s?\d{3}$/);
    if (fieldName === "pincode" && !pinRegex.test(fieldValue)) {
      setErrors({ pincode: "Invalid Pincode!" });
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!data.pincode.trim()) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };


  const handleShowConfirm = (id) => {
    setSelectedSpec({ id });  // Ensure you're setting the correct ID to be deleted
    setShowConfirm(true);     // Show the confirmation modal
  };

  const handleDelete = (id) => {
    deleteFunction({ id }, "/rhf_locality/delete-rhflocality")
      .then((response) => {
        toast.success("Service Location Deleted  successfully");
        console.log(response);
        const newServiceArea = serviceArea.filter((item) => item.id != id);
        setServiceArea(newServiceArea);
        handleClose();
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        console.log(error.response.data);
      });
  };

  const handleClose = () => {
    setShowConfirm(false); // Close the confirmation modal
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const locality = {
      rhfUnitId: data.rhfId,
      localityId: data.localityId,
      primaryLoc: checked ? "Y" : "N",
    };
    if (validateForm()) {
      setFunction(locality, "/rhf_locality/set-rhflocality")
        .then((response) => {
          toast.success("Service Location Added  successfully");
          setAddLocation(false);
          setNewLoc(true);
          console.log(response.success.id);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error);
          }
          console.log(error);
          toast.error("An error occurred while saving Service Location data.");
        });
    }
  };

  const addLocationUI = (
    <div class="container">
      <div class="row">
        <div class="">
          <h2 class="citext topfrom">Pincode *</h2>
          <input
            type="text"
            name="pincode"
            onChange={changeHandler}
            value={data.pincode}
            placeholder="Pincode"
            className="form-input"
          />
          {errors.pincode && <div className="validation">{errors.pincode}</div>}

          <h2 class="citext topfrom">Place *</h2>
          <select
            type="text"
            name="place"
            onChange={changeHandler}
            placeholder="Place"
            className="form-input textbox"
          >
            <option value={data.place}>{data.place}</option>
            {loc &&
              loc.map((item) => (
                <option value={item.locality} key={item.id}>
                  {item.locality}, {item.districtname}, {item.state}
                </option>
              ))}
          </select>
          <br></br>
          <br></br>
          {errors.place && <div className="validation">{errors.place}</div>}
          <span class="citext topfrom" style={{ color: "black" }}>
            Primary
          </span>
          <input
            type="checkbox"
            name="primaryLoc"
            onChange={() => setChecked(!checked)}
            checked={checked}
            placeholder="Primary"
            className=""
          />

          <br></br>
        </div>
      </div>
      <button
        class="qualificationbtn"
        onClick={handleSubmit}
        disabled={!data.localityId}
      >
        Submit
      </button>
      <button
        class="qualificationbtn"
        onClick={() => setAddLocation(false)}
        style={{ marginLeft: "20px" }}
      >
        Close
      </button>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete the locality?</Modal.Body>
      <Modal.Footer>
        <button onClick={() => handleDelete(selectedSpec.id)}>Delete</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );
  

  return (
    <div className="">
            {modalConfirmUI}
      <ToastContainer />
      <br></br> <br></br> <br></br>
      <h1 class="profileheading1">Service Locations</h1>
      <br></br>
      <br></br>
      <div className="col-sm-8">
        <div style={{ overflowX: "scroll" }}>
          <table>
            <thead>
              <tr>
                <th>Location</th>
                <th>State</th>
                <th>Pincode</th>
                <th>Primary</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {serviceArea &&
                serviceArea.map((item) => (
                  <tr>
                    <td>{item.locality}</td>
                    <td>{item.districtname}</td>
                    <td>{item.pincode}</td>
                    <td>{item.primary_loc}</td>
                    <td>
                      <FontAwesomeIcon
                        onClick={() =>handleShowConfirm(item.id)}
                        icon={faTrash}
                        style={{ fontSize: "15px" }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <br></br>
        <br></br>
        <button
          class="qualificationbtn"
          onClick={() => {
            setAddLocation(true);
            setNewLoc(false);
          }}
        >
          +
        </button>
        {addLocation ? addLocationUI : null}
        <hr></hr>
      </div>
    </div>
  );
}

export default RhfLocality;
