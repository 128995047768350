import React, { useContext, useState,useEffect } from "react";
import moment from "moment";
import Header from "../header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import withPatientAuth from "../../middleware/withPatientAuth";
import { setFunction, deleteFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";

function RhfSlotBooking() {
  const location = useLocation();
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState({});
  const { patientId } = useContext(AuthContext);
  const [appointmentId, setAppointmentId] = useState();

  // const time24Hour = moment(time, "h:mm A").format("HH:mm");
  const {
    selectedDate,
    unitId,
    serviceId,
    unitNumber,
    contactName,
    fees,
    service,
  } = location.state || {};
  useState(() => {
    localStorage.setItem("appointmentUnitId", unitId);
    localStorage.setItem("appointmentType", "rhf");
    localStorage.setItem("serviceId", serviceId);
    localStorage.setItem("bookingDate", selectedDate);
    localStorage.setItem("bookingTime", "9:00");
    localStorage.setItem("appointmentFee", fees);
    localStorage.setItem("unitName", unitNumber);
    localStorage.setItem("serviceName", service);
  }, []);
  const handleClick = async () => {
    const data = {
      amount: parseFloat(fees),
      patientId,
      transactionId: "T" + Date.now(),
      pageSelector: "rhf",
    };

    try {
      const paymentResponse = await setFunction(
        data,
        "/payment/initiate-payment"
      );
      console.log(paymentResponse);
      if (
        paymentResponse.success &&
        paymentResponse.success.result &&
        paymentResponse.success.result.success === true
      ) {
        window.location.href =
          paymentResponse.success.result.data.instrumentResponse.redirectInfo.url;
      } else {        
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div class="container fluid">
        <div class="row">
          <div class="col-sm-12">
            <center>
              <h3 style={{ color: "#199fd9" }}>Appointment Confirmation </h3>
            </center>
            <div>
              <h5>Details of Your Appointment:</h5>
              <p>Date:{selectedDate} </p>
              <p>unit:{unitNumber}</p>
              <p>contact name:{contactName}</p>
              <p>service:{service} </p>
              <p>Fees: ₹{fees} </p>
            </div>
            <button
              class="custom-btn1 btn-3"
              style={{ marginLeft: "353px" }}
              onClick={handleClick}
            >
              <span>Book Now</span>
            </button>

            {/*<p>Your Appointment is booked on : {date} at {slotTime}</p>*/}

            {/* <p>Provider ID: {providerId}</p> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default withPatientAuth(RhfSlotBooking);
