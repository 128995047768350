import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../header/Header";
import { FaRightLong } from "react-icons/fa6";
import { delItem } from "../../redux/actions";

function Cart() {
  const cartItems = useSelector((state) => state.cart) || []; // Fallback to an empty array if cartItems is undefined
  const dispatch = useDispatch();

  const handleRemove = (product) => {
    dispatch(delItem(product));
  };

  return (
    <>
      <Header />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="row cart-wrapper">
                {/* Cart Table */}
                <div className="col-sm-8 col-md-8">
                  <div className="cart-table-container">
                    <table className="table table-cart">
                      <thead>
                        <tr>
                          <th className="thumbnail-col"></th>
                          <th className="product-col">Product</th>
                          <th className="price-col">Price</th>
                          <th className="action-col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Loop through cart items */}
                        {cartItems.length > 0 ? (
                          cartItems.map((item) => (
                            <tr className="product-row" key={item.id}>
                              <td>
                                <figure className="product-image-container">
                                  <img
                                    src={item.img}
                                    alt={item.title}
                                    style={{ width: "80px" }}
                                  />
                                </figure>
                              </td>
                              <td className="product-col">
                                <h5 className="product-title">{item.title}</h5>
                              </td>
                              <td>₹{item.price}</td>
                              <td>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleRemove(item)}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4">Your cart is empty</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Cart Summary */}
                <div className="col-sm-4 col-md-4">
                  <div className="cart-summary">
                    <h3>CART TOTALS</h3>
                    <table className="table table-totals">
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>
                            ₹
                            {cartItems.reduce((acc, item) => acc + item.price, 0)}
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td>
                            <b>Total</b>
                          </td>
                          <td>
                            <b>₹{cartItems.reduce((acc, item) => acc + item.price, 0)}</b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="checkout-methods">
                      <a href="#!" className="btn btn-block btn-dark">
                        Proceed to Checkout &nbsp;
                        <FaRightLong style={{ color: "#fff", marginTop: "-3px" }} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cart;
