import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
function RHFspirometryTest() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(999);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="stimg1">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              Spirometry Test
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Details</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgrlglg">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Service Description</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          A spirometry test measures the amount of air you can
                          breathe in and out and how quickly you can do so. This
                          test is essential for diagnosing and monitoring lung
                          conditions such as asthma, COPD, and other respiratory
                          disorders.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgrlglg">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Flexible Schedule</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Choose the duration and frequency of tests to meet
                          your healthcare needs.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgrlglg">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Customizable Options</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Adjust the duration and price based on your specific
                          requirements.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                 <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">Advantages of Spirometry Test at Home</b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Convenience</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Conduct the test in the comfort of your home, avoiding
                          the need to visit a clinic or hospital.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Accurate Results</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Our trained healthcare professionals ensure accurate
                          and reliable results.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/appcicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Early Diagnosis</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Helps in early detection and management of lung
                          conditions.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon5.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Personalized Care</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Get detailed analysis and personalized treatment plans
                          based on your test results.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/appcicon5.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Follow-Up Support</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Continuous monitoring and follow-up support to manage
                          and improve your respiratory health.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/assicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Safety</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Reduces exposure to infections by avoiding crowded
                          hospitals and clinics.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default RHFspirometryTest;
