import { useRoutes } from "react-router-dom";
import PneumothoraxDS from "../pages/diagnosisServices/PneumothoraxDS";
import PulmonaryedemaDS from "../pages/diagnosisServices/PulmonaryedemaDS";
import BronchitisDS from "../pages/diagnosisServices/BronchitisDS";
import PneumoniaDS from "../pages/diagnosisServices/PneumoniaDS";
import PulmonaryCriticalcarePT from "../pages/diagnosisServices/PulmonaryCriticalcarePT";
import IntreventionalPulmonology from "../pages/diagnosisServices/IntreventionalPulmonology";
import LungTransplant from "../pages/diagnosisServices/LungTransplant";
import GeneTherapy from "../pages/diagnosisServices/GeneTherapy";
const DiagnosisServicesRoutes = () => {
    let routes = useRoutes([
        { path: "/PneumothoraxDS", element: <PneumothoraxDS/> },
        { path: "/PulmonaryedemaDS", element: <PulmonaryedemaDS/> },
        { path: "/BronchitisDS", element: <BronchitisDS/> },
        { path: "/PneumoniaDS", element: <PneumoniaDS/> },
        { path: "/PulmonaryCriticalcarePT", element: <PulmonaryCriticalcarePT/> },
        { path: "/IntreventionalPulmonology", element: <IntreventionalPulmonology />},
        { path: "/LungTransplant", element:<LungTransplant />},
        { path: "/GeneTherapy", element:<GeneTherapy />},





    ]);
    return routes;
  };
  
  export default DiagnosisServicesRoutes;
