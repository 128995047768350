import React from "react";

function TeacherRespiratory() {
  return (
    <>
      <>
        {" "}
        <br></br> <br></br>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <div
              id="carouselExampleSlidesOnly"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    class="d-block w-100 rhfimg vip"
                    src="./style/img/banner/Respi.png"
                    alt="First slide"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-2"></div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">
                      Teacher Respiratory Lung Care Health Package
                    </font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        As an educator, your dedication to shaping young minds
                        often involves long hours of teaching and classroom
                        management, which can impact your respiratory health.
                        Our specialized Teacher Respiratory Health Plan is
                        designed to support you in maintaining optimal lung
                        function and well-being, tailored to address the unique
                        challenges faced by teachers. Here's what our Teacher
                        Respiratory Health Plan include
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">
                      Breath Control and Vocal Health:
                    </font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        Provide resources and techniques for improving breath
                        control during teaching sessions and presentations.
                        Offer guidance on proper breathing techniques to enhance
                        vocal projection and endurance. Classroom Ergonomics
                        Optimization:<br></br>
                        Promote proper posture and teaching stance to reduce
                        strain on the respiratory system and enhance breathing
                        efficiency. Offer ergonomic seating options and
                        adjustable lecterns to support optimal teaching posture
                        and comfort.
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">Indoor Air Quality Enhancement</font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        Raise awareness about the importance of indoor air
                        quality for teachers' respiratory health. Recommend air
                        purifiers and ventilation strategies to reduce exposure
                        to indoor pollutants and allergens in classrooms and
                        staff room
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">
                      Stress Management and Relaxation Techniques
                    </font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        Provide resources and strategies for managing stress and
                        anxiety-related breathing difficulties in the classroom.
                        Offer relaxation techniques such as breathing exercises,
                        meditation, and mindfulness to promote calmness and
                        focus during teaching hours{" "}
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">Healthy Lifestyle Promotion</font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        Provide educational materials on maintaining a healthy
                        lifestyle to support overall respiratory health and
                        well-being. Offer tips on nutrition, hydration, and
                        physical activity tailored to the specific needs of
                        educators
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">Enhanced Features</font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        In addition to our standard offerings, our Teacher
                        Respiratory Health Package includes the following
                        enhanced features<br></br>
                        Personalized Breathing Assessment: Access to
                        personalized breathing assessments conducted by
                        respiratory health experts to identify individual needs
                        and tailor recommendations accordingly. Classroom
                        Environment Evaluation: Assessment of air quality and
                        ergonomic factors in classrooms to identify potential
                        respiratory health risks and provide recommendations for
                        improvement. Teacher Respiratory Health Package
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">
                      Breathing Control and Vocal Health
                    </font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        * Voice Care Kit for Teachers with Breathing Exercises -
                        ₹1200 (One-time Purchase){" "}
                        <button class="btnclss">Book Now</button> <br></br>*
                        Online Vocal Health Workshop for Educators - ₹500
                        (One-time Fee)<button class="btnclss">Book Now</button>{" "}
                        <br></br>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">
                      Classroom Ergonomics Optimization
                    </font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        * Ergonomic Teacher's Chair with Back Support - ₹3500{" "}
                        <button class="btnclss">Book Now</button>
                        <br></br>* Height-Adjustable Lectern for Comfortable
                        Speaking Posture - ₹3000{" "}
                        <button class="btnclss">Book Now</button>
                        <br></br> * Anti-Fatigue Mat for Standing Teachers -
                        ₹700 <button class="btnclss">Bokk Now</button>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">Indoor Air Quality Enhancement</font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        * Air Purifier with HEPA Filter for Classroom Use -
                        ₹7000 <button class="btnclss"> Book Now</button>{" "}
                        <br></br>* Indoor Plants Known for Air Purification and
                        Oxygen Production (Set of 3) - ₹700
                        <button class="btnclss">Book Now</button>
                        <br></br>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">
                      Stress Management and Relaxation Techniques
                    </font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        * Meditation App Subscription Tailored for Educators'
                        Stress Relief - ₹600/year{" "}
                        <button class="btnclss">Book Now</button>
                        <br></br>* Aromatherapy Diffuser with Relaxing Essential
                        Oils for Teacher's Lounge - ₹900{" "}
                        <button class="btnclss">Book Now</button>
                        <br></br> * Yoga for Teachers Online Course for Stress
                        Reduction and Improved Breathing - ₹1500{" "}
                        <button class="btnclss">Bokk Now</button>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">Healthy Lifestyle Promotion</font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        * Healthy Snack Box Subscription (Monthly) with
                        Teacher-approved Snacks - ₹1500/month{" "}
                        <button class="btnclss">Book Now</button> <br></br>*
                        Fitness Tracker Watch to Monitor Activity Levels and
                        Encourage Movement - ₹3500
                        <button class="btnclss">Book Now</button>
                        <br></br>* Nutrition Consultation Session Focused on
                        Educators' Dietary Needs - ₹700{" "}
                        <button class="btnclss">Book Now</button> <br></br>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="section-top-border">
                <div class="container">
                  <h3 class="mb-30 title_color">
                    <font color="#204ea0">Enhanced Features</font>
                  </h3>
                  <div class="row">
                    <div class="col-lg-12">
                      <blockquote class="generic-blockquote">
                        * Personalized Breathing Assessment with Emphasis on
                        Teachers' Respiratory Health - ₹1000{" "}
                        <button class="btnclss">Book Now</button>
                        <br></br>* Classroom Environment Evaluation for Air
                        Quality and Respiratory Health Considerations - ₹2500{" "}
                        <button class="btnclss">Book Now</button>
                        <br />
                        <br></br>
                        Join Us in Prioritizing Respiratory Health for Teachers!
                        Customize your Teacher Respiratory Health Package today
                        to support your lung health while nurturing young minds.
                        Contact us now to tailor your plan and begin your
                        journey to healthier lungs and a more vibrant teaching
                        experience.
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default TeacherRespiratory;
