import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import { getFunction } from "../../api/apiFunctions";
import { FaStar } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";

export default function LungTherapies() {
  const navigate = useNavigate();
  const [specializations, setSpecializations] = useState([]);
  const [userLocation, setUserLocation] = useState("Your Location");
  const [nearestPlaces, setNearestPlaces] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showDoctors, setShowDoctors] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedlocValue, setSelectedlocValue] = useState("");
  // Fetch specializations
  useEffect(() => {
    getFunction({ providerType: "therapist" }, "/provider/get-specialization")
      .then((response) => {
        const responseData = response.success.result;
        setSpecializations(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const callSearchService = () => {
      if (locationInput) {
        getFunction({ place: locationInput }, "/locality/get-location-by-name")
          .then((response) => {
            const responseData = response.success.result;
            setLocationSuggestions(responseData);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLocationSuggestions([]);
      }
    };

    let debouncer = setTimeout(() => {
      callSearchService();
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [locationInput]);
  // Log location input
  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocationInput(value);
    console.log("Location Input:", value); // Log the location input
  };
  // Handle location selection
  const handleLocationSelect = (e) => {
    const selectedOption = locationSuggestions.find(
      (location) => location.id === e.target.value
    );

    if (selectedOption) {
      setSelectedLocation(selectedOption);
      setLocationInput(`${selectedOption.locality}, ${selectedOption.pincode}`);

      setLocationSuggestions([]);
    }
  };
  // Handle change for specialization select

  const handleSpecializationChange = (event) => {
    const specializationValue = event.target.value;
    setSelectedValue(specializationValue);

       const locationValue = `${selectedLocation.locality}, ${selectedLocation.pincode}`;
       setSelectedlocValue(locationValue);

    const data = { loc: selectedLocation.pincode, spec: specializationValue };
    navigate("/Therapist_SearchResult", { state: data });
    console.log("Selected Specialization:", specializationValue); // Log the selected specialization
    console.log("Selected Location:", locationValue); // Log the selected location
    console.log("Pincode:", selectedLocation.pincode);
  };



  // const handleSpecializationChange = (event) => {
  //   const serviceValue = event.target.value;
  //   setSelectedValue(serviceValue);

  //   if (selectedLocation) {
  //     const locationValue = `${selectedLocation.locality}, ${selectedLocation.pincode}`;
  //     setSelectedlocValue(locationValue);

  //     const data = { loc: selectedLocation.pincode, serv: serviceValue };
  //     navigate("/Therapist_SearchResult", { state: data });
  //     console.log("Selected Service:", serviceValue);
  //     console.log("Selected Location:", locationValue);
  //     console.log("Pincode:", selectedLocation.pincode);
  //   } else {
  //     console.log("No location selected");
  //   }
  // };

  return (
    <>
      <Header></Header>
      <br></br>

      <div class="lunbnr">
        <br></br>
        <center>
          <h2 class="text-white "> Book A Lung Therapy Near You </h2>
        </center>
        <br></br>
        <center>
          <h5 class="text-white">
            Use our easy search tool to find a qualified respiratory therapist
            or <br></br>physio-therapist in your area.
          </h5>
        </center>
        <br></br>
        <div class="container">
          <div className="row no-gutters">
            <div className="col-sm-6 location">
              {/* <div className="form-outline" data-mdb-input-init> */}
              <input
                type="text"
                id="fname"
                name="fname"
                className="searche"
                placeholder="Select Location"
                value={locationInput}
                onChange={handleLocationInputChange}
              />
              {locationSuggestions.length > 0 && (
                <div className="suggestions-dropdown">
                  {locationSuggestions.map((item) => (
                    <button
                      key={item.id}
                      value={item.id}
                      onClick={handleLocationSelect}
                    >
                      {item.locality}, {item.districtname}, {item.state}
                    </button>
                  ))}
                </div>
              )}
              {/* </div> */}
            </div>
            <div className="col-sm-6">
              {/* <div className="form-outline" data-mdb-input-init> */}
              <select
                className="form-control findcontorl"
                value={selectedValue}
                onChange={handleSpecializationChange}
              >
                <option value="">Select Specialization</option>
                {specializations.map((spec) => (
                  <option key={spec.id} value={spec.specialization_id}>
                    {spec.specialization_name}
                  </option>
                ))}
              </select>
            </div>
            {/* </div> */}
          </div>
          {/* <div class="input-group">
            <div class="input-group-prepend"></div>
            <input
              type="text"
              class="form-control lungcontorl lunginput"
              placeholder="Search for therapists here"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <i class="fa-solid fa-magnifying-glass mg"></i>
          </div> */}

          <center>
            <p class="text-white">
              Popular Searches : Respiratory Therapy | Physiotherapy
            </p>
          </center>

          <center>
            <p class="text-white">
              At Respiro, your<b class="text-white"> health </b>and{" "}
              <b class="text-white">well being </b>are our top priorities.
            </p>
          </center>
          <br></br>
        </div>
      </div>
      <br></br>

      <div class="container">
        <br></br>
        <center>
          <h3 class="pop">OUR THERAPY SERVICES</h3>
        </center>
        <center>
          <p> Dedicated services for all health conditions and age groups.</p>
        </center>
        <br></br>
        <div class="row">
          <div class="col-sm-6">
            {/* //1st row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2 text-dark">
                  {" "}
                  <a href="/Asthma">Asthma Management</a>
                  <br></br>Comprehensive care to control and manage asthma
                  symptoms.
                  <a href="/Asthma">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
              {/* <button class="custom-btn1 btn-3 rhbbtn">
                          <span class="btntexty rhbtxt">Book Now</span>
                        </button> */}
            </div>
            {/* 1st end */}

            {/* //2nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2 text-dark">
                  {" "}
                  <a href="/Copd">COPD Treatment</a>
                  <br></br>Specialized treatment plans to manage and improve
                  COPD conditions.
                  <a href="/Copd">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            {/* 2st end */}
            {/* //3nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2 text-dark">
                  {" "}
                  <a href="/Sleepapnea">Sleep Apnea Therapy</a>
                  <br></br>Effective solutions to manage and treat sleep apnea.
                  <a href="/Sleepapnea">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            {/* 3nd end */}
            {/* //4nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Pulmonaryfntest">Pulmonary Function Tests</a>
                  <br></br>Advanced testing to evaluate lung function and
                  diagnose respiratory conditions.
                  <a href="/Pulmonaryfntest">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            {/* 4th end */}
            {/* //5nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Pathogenpanel">Respiratory Pathogen Panel</a>
                  <br></br>Accurate testing to detect respiratory infections and
                  pathogens.
                  <a href="/Pathogenpanel">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            <br></br>
            {/* 5th end */}
            <div>
              <Link to="/Rehabservices">
                <button
                  style={{ borderRadius: "25px", marginLeft: "350px" }}
                  class="rhbservbtn"
                >
                  {" "}
                  All Services&nbsp;{" "}
                  <FaArrowRightLong style={{ color: "text-dark" }} />
                </button>
              </Link>
            </div>
            <br></br>
          </div>
          <div class="col-sm-6">
            <img src="../style/img/resp/Group 108.png" class="otsimg"></img>
          </div>
        </div>
        <br></br>
        <hr class="ltline1"></hr> <br></br>
        <div class="row">
          <div class="col-sm-6">
            <img src="../style/img/resp/Group 109.png" class="otsimg"></img>
          </div>

          <div class="col-sm-6">
            <br></br> <br></br>
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Postsurgical">Post-Surgical Rehabilitation</a>
                  <br></br>Tailored rehabilitation plans to aid recovery after
                  surgery.
                  <a href="/Postsurgical">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Sportsinjury">Sports Injury Recovery</a>
                  <br></br>Specialized treatment to help recover from
                  sports-related injuries.
                  <a href="/Sportsinjury">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Chronicpain">Chronic Pain Management</a>
                  <br></br>Effective strategies to manage and reduce chronic
                  pain.
                  <a href="/Chronicpain">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            {/* //4nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Mobility">Mobility and Balance Training</a>
                  <br></br>Programs designed to improve mobility and balance.
                  <a href="/Mobility">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            {/* //5nd row// */}
            <div class="row">
              <div class="col-sm-2">
                <img src="../style/img/resp/checkmark.png" class="lticon"></img>
              </div>
              <div class="col-sm-10">
                <p class="dry2  text-dark">
                  {" "}
                  <a href="/Strength">Strength and Conditioning Programs</a>
                  <br></br>Customized plans to enhance strength and overall
                  physical conditioning.
                  <a href="/Strength">
                    <FaArrowRightLong style={{ color: "#3495FF" }} />
                  </a>
                </p>
              </div>
            </div>
            <br></br>
            <div>
              <Link to="/Rehabservices">
                <button
                  style={{ borderRadius: "25px", marginLeft: "350px" }}
                  class="rhbservbtn"
                >
                  {" "}
                  All Services&nbsp;{" "}
                  <FaArrowRightLong style={{ color: "text-dark" }} />
                </button>
              </Link>
            </div>
            <br></br>
          </div>
        </div>
      </div>
      <div class="bg2">
        <br></br>
        <div class="container-fluid">
          <div class="container-fluid bg3">
            <br></br>
            <br></br>
            <center>
              <h3 class="pop">Top Therapist Near You</h3>
            </center>
            <center>
              <p> Accredited therapists with extensive clinical experience.</p>
            </center>
            <br></br>
            <div class="row">
              <div class="col-sm-3">
                <div class="card lungcard">
                  <div class="container">
                    <img
                      src="../style/img/resp/Rectangle 171.png"
                      alt="Image 1"
                      class="lungtherapyimg"
                    ></img>

                    <div class="containr">
                      <br></br>
                      <center>
                        {" "}
                        <h3 class="dry">Dr. David Kurian</h3>
                        <p class="dry1">
                          BSRT, MSRT, DHSc<br></br>Respiratory Therapist
                        </p>
                      </center>
                      <center>
                        {" "}
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                      </center>{" "}
                      <hr></hr>
                      <center>
                        {" "}
                        <a href="/">
                          <h5 class="viewthp">View Profile</h5>
                        </a>
                        <button class="custom-btn1 btn-3">
                          <span class="btntexty">Book Now</span>
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card lungcard">
                  <div class="container">
                    <img
                      src="../style/img/resp/Rectangle 172.png"
                      alt="Image 1"
                      class="lungtherapyimg"
                    ></img>
                    <div class="containr">
                      <br></br>
                      <center>
                        {" "}
                        <h3 class="dry">Dr. Lal Mohan</h3>
                        <p class="dry1">
                          BPT, MPT<br></br>Physio Therapist
                        </p>
                      </center>
                      <center>
                        {" "}
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                      </center>{" "}
                      <hr></hr>
                      <center>
                        {" "}
                        <a href="/">
                          <h5 class="viewthp">View Profile</h5>
                        </a>
                        <button class="custom-btn1 btn-3">
                          <span class="btntexty">Book Now</span>
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card lungcard">
                  <div class="container">
                    <img
                      src="../style/img/resp/Rectangle 173.png"
                      alt="Image 1"
                      class="lungtherapyimg"
                    ></img>
                    <div class="containr">
                      <br></br>
                      <center>
                        {" "}
                        <h3 class="dry">Dr. Urmila Kiran</h3>
                        <p class="dry1">
                          BPT, DNB, MPT<br></br>Physio Therapist
                        </p>
                      </center>
                      <center>
                        {" "}
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                      </center>{" "}
                      <hr></hr>
                      <center>
                        {" "}
                        <a href="/">
                          <h5 class="viewthp">View Profile</h5>
                        </a>
                        <button class="custom-btn1 btn-3">
                          <span class="btntexty">Book Now</span>
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card lungcard">
                  <div class="container">
                    <img
                      src="../style/img/resp/Rectangle 174.png"
                      alt="Image 1"
                      class="lungtherapyimg"
                    ></img>
                    <div class="containr">
                      <br></br>
                      <center>
                        {" "}
                        <h3 class="dry">Dr. Shanthi Devi</h3>
                        <p class="dry1">
                          BSRT, MSRT<br></br>Respiratory Therapist
                        </p>
                      </center>
                      <center>
                        {" "}
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                        <FaStar class="starc" />
                      </center>{" "}
                      <hr></hr>
                      <center>
                        {" "}
                        <a href="/">
                          <h5 class="viewthp">View Profile</h5>
                        </a>
                        <button class="custom-btn1 btn-3">
                          <span class="btntexty">Book Now</span>
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg2">
              <br />
              <div class="container-fluid bg3">
                <br />
                <br />
                <center>
                  <h3 class="pop">How To Book A Consultation?</h3>
                </center>
                <br />
                <br />
                <br />
                <img src="../style/img/resp/sc1.png" class="sc1" />
              </div>
              <br />
            </div>
            <br />
            <div class="container-fluid">
              <img
                src="../style/img/resp/Group 99.png"
                class="hiwbg hiwbgresp"
              />
              <br />
              <br />
              <br />
              <div class="row">
                <div class="col-sm-6">
                  <center>
                    <img
                      src="../style/img/resp/Ellipse 13.png"
                      class="rowicon rowiconresp"
                    />
                  </center>
                </div>
                <div class="col-sm-4">
                  <h3
                    class="hed8 hed8resp13 hed8respall"
                    style={{ textAlign: "center" }}
                  >
                    Expert Care from Experienced
                    <br /> Professionals
                  </h3>
                  <p class="rouptext rouptextres">
                    Our respiratory therapists are certified and have extensive
                    experience in treating various respiratory conditions. You
                    can trust our team to provide you with the highest level of
                    care and expertise.
                  </p>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <br />
              <br />
              <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                  <h3 class="hed8 hed8respall" style={{ textAlign: "center" }}>
                    Comprehensive Respiratory Care
                  </h3>
                  <p class="rouptext rouptextres">
                    Respiro offers a wide range of respiratory therapies and
                    treatments, all under one roof. Whether you need management
                    for chronic conditions or acute respiratory issues, we have
                    the solutions you need.
                  </p>
                </div>
                <div class="col-sm-6">
                  <center>
                    <img
                      src="../style/img/resp/Ellipse 15.png"
                      class="rowicon rowiconresp"
                    />
                  </center>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <br />
              <br />
              <div class="row">
                <div class="col-sm-6">
                  <center>
                    <img
                      src="../style/img/resp/Ellipse 14.png"
                      class="rowicon rowiconresp"
                    />
                  </center>
                </div>
                <div class="col-sm-4">
                  <h3
                    className="hed8 hed8resp13 hed8respall"
                    style={{ textAlign: "center" }}
                  >
                    Personalized Treatment Plans
                  </h3>
                  <p class="rouptext rouptextres">
                    At Respiro, we understand that each patient is unique. Our
                    therapists develop customized care plans tailored
                    specifically to your individual needs, ensuring the most
                    effective treatment and management of your respiratory
                    condition.
                  </p>
                </div>
                <div class="col-sm-2"></div>
              </div>
              <br />
              <br />
              <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                  <h3 class="hed8 hed8respall" style={{ textAlign: "center" }}>
                    Convenient Consultation Options
                  </h3>
                  <p class="rouptext rouptextres">
                    Booking an appointment with Respiro is simple and flexible.
                    Use our online portal to schedule consultations at your
                    convenience, choosing from in-clinic visits or home-based
                    sessions to suit your lifestyle and preferences.
                  </p>
                </div>
                <div class="col-sm-6">
                  <center>
                    <img
                      src="../style/img/resp/Ellipse 16.png"
                      class="rowicon rowiconresp"
                    />
                  </center>
                </div>
                <div class="col-sm-2"></div>
              </div>
            </div>
            <hr />

            <h3 class="home_text">
              <font color="204ea0">Welcome to the Practitioner Community!</font>
            </h3>
            <p>Partner with Us to Enhance Respiratory and Physical Health</p>
            <div class="row ">
              <div class="col-sm-3"></div>
              <div class="col-sm-6">
                <Link to="/provider/landing">
                  <img
                    className="home3bg"
                    src="./style/img/banner/Group 300.jpg"
                    alt="First slide"
                  />
                </Link>
                <br></br>
              </div>
              <div class="col-sm-3"></div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
}