import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import withPatientAuth from "../../middleware/withPatientAuth";
import { setFunction, updateFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";

function RhfSlotConfirmation() {
  //   const location = useLocation();
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    setAppointmentData({
      patientId: patientId,
      unitId: localStorage.getItem("appointmentUnitId"),
      bookingDate: localStorage.getItem("bookingDate"),
      bookingTime: localStorage.getItem("bookingTime"),
      serviceId: localStorage.getItem("serviceId"),
      statusField: "Unassigned",
      paymentStatus: "Completed",
    });
    setData({
      unitName: localStorage.getItem("unitName"),
      bookingDate: localStorage.getItem("bookingDate"),
      serviceName: localStorage.getItem("serviceName"),
      appointmentFee: localStorage.getItem("appointmentFee"),
    });
  }, []);

  const { patientId } = useContext(AuthContext);

  useEffect(() => {
    if (appointmentData.unitId) {
      setFunction(appointmentData, "/rhf_booking/set-rhf-booking")
        .then((response) => {
          console.log("appointment added");
          setAppointmentData({});
          localStorage.removeItem("appointmentUnitId");          
          localStorage.removeItem("bookingTime");
          localStorage.removeItem("serviceId");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appointmentData]);
  return (
    <>
      <Header />
      <div class="container fluid">
        <div class="row">
          <div class="col-sm-12">
            <center>
              <h3 style={{ color: "#199fd9" }}>Appointment has been booked!</h3>
            </center>
            <div className="hospital-card" style={{ color: "black" }}>
              <div className="hospital-header">
                Details of Your Appointment:
              </div>
              <div className="hospital-fields">Date: {data.bookingDate}</div>
              {/* <div className="hospital-fields">Time: {data.startTime}</div> */}
              <div className="hospital-fields">
                unit number: {data.unitName}
              </div>
              <div className="hospital-fields">
                Service Name: {data.serviceName}
              </div>

              <div className="hospital-fields">Fees: {data.appointmentFee}</div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RhfSlotConfirmation;
