import React, { useContext } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { setFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import { Card, Button } from "react-bootstrap";
function TherapistPayment() {
    const navigate = useNavigate();
    const location = useLocation();
    const { providerType,providerId } = useContext(AuthContext);
    const { date, time, fees } = location.state || {};
    const time24Hour = moment(time, "h:mm A").format("HH:mm");

    const handleClick = async () => {
        const data = {
          amount: 500, 
          providerId,
          transactionId: "T" + Date.now(),
          //contactName:"anu",
          pageSelector: "default",
         // phone:"9876545678"
        };

        try {
          const paymentResponse = await setFunction(data, "/payment/initiate-payment");

          if (
            paymentResponse.success &&
            paymentResponse.success.result &&
            paymentResponse.success.result.success === true
          ) {
            window.location.href =
              paymentResponse.success.result.data.instrumentResponse.redirectInfo.url;
          }
        } catch (error) {
          console.log(error);
          navigate("/failure");
        }
      };

    return (
      <div className="rhf-payment-container">
      <Card className="rhf-payment-card shadow-sm">
        <Card.Body>
          <Card.Title className="text-center mb-4">
            <h3>Please Make the Initial Payment</h3>
          </Card.Title>
          <Card.Text className="text-center text-muted">
            <span className="payment-amount">₹&nbsp;500</span> is required to process your application and secure your place within the Respiro Practitioner program.
          </Card.Text>
          <Card.Text className="text-justify">
          This fee covers the administrative and processing costs associated with reviewing your application and verifying your credentials. Your payment ensures that we can provide you with the necessary resources and support to join our network of healthcare professionals.
          </Card.Text>
          <Card.Text className="text-justify">
          Please note that this fee is non-refundable once you have attended the training or if you decide to withdraw from the program before attending. The fee will only be refunded if your application is rejected by us.
          </Card.Text>
          <div className="text-center mt-4">
            {/* <Button
              variant="primary"
              className="payment-button"
              onClick={handleClick}
            >
              Make Payment
            </Button> */}
            <button
                    className="custom-btn1 btn-3"
                    style={{ marginLeft: "30px" }}
                    onClick={handleClick}
                >
                    <span>Make Payment</span>
                </button>
          </div>
        </Card.Body>
      </Card>
    </div>
    );
}

export default TherapistPayment;
