import React, { useEffect, useState, useContext } from "react";
import SpecializationTable from "./SpecializationTable";
import { getFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function Specialization() {
  const { providerId, providerType } = useContext(AuthContext);
  const [specialization, setSpecialization] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
const [selectedSpec, setSelectedSpec] = useState(null);

  const emptyRow = {
    id: "",
    providerId: "",
    specializationId: "",
    specializationName: "",
    providerType: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response1 = await getFunction(
          { providerType },
          "/provider/get-specialization"
        );
        setSpecialization(response1.success.result);

        const response2 = await getFunction(
          { providerId },
          "/provider/get-provider-specialization"
        );
        setData(response2.success.result);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [providerId]);

  const addEmptyRow = () => {
    setData((prevData) => [...prevData, emptyRow]);
  };

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);

  };

  return (
    <div className="container">
      <div className="providerline">
        <br />
        <br />
        <br />
        <h1 className="providerprofileheading100 profileheading1">
          Specialization
        </h1>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <hr className="providerline" />
            <div class="col-sm-5">
              <table class="table-responsive specialtbl">
                <thead>
                  <tr>
                    <th>Specialization</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <SpecializationTable
                      key={item.id}
                      data={{
                        id: item.id,
                        providerId: item.provider_id,
                        specializationId: item.specialization_id,
                        specializationName: item.specialization_name,
                      }}
                      specialization={specialization}
                      handleDelete={handleDelete}
                    />
                  ))}
                </tbody>
              </table>

              <br></br>
              <br></br>
              <button className="qualificationbtn" onClick={addEmptyRow}>
                +
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withProviderAuth(Specialization);