import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";

export default function Rehabservices() {
  const cardData = [
    { id: 1, image: "../style/img/Rehabserv/Techie's.jpg", title: "Techie's Mechanical pain relief package", price: 999, link: "/Techies" },
    { id: 2, image: "../style/img/Rehabserv/Middle aged.jpg", title: "Middle aged women's knee pain management package", price: 999, link: "/Kneepain" },
    { id: 3, image: "../style/img/Rehabserv/Diabetic Patients.jpg", title: "Diabetic Patients Shoulder Joint Care Package", price: 999, link: "/Diabetic" },
    { id: 4, image: "../style/img/resp/pregnant.jpg", title: "Pregnant ladies package", price: 999, link: "/Pregnant" },
    { id: 5, image: "../style/img/Rehabserv/Sciatica Diagnosis.jpg", title: "Sciatica Diagnosis and Treatment Package", price: 999, link: "/Sciatica" },
    { id: 6, image: "../style/img/Rehabserv/Needling.jpg", title: "Dry Needling Treatment Package", price: 999, link: "/Dryneedle" },
    { id: 7, image: "../style/img/Rehabserv/Cupping.jpg", title: "Cupping Therapy Package (Dry Condition)", price: 999, link: "/Cupping" },
    { id: 8, image: "../style/img/Rehabserv/Taping.jpg", title: "Taping therapy package", price: 999, link: "/Taping" },
    { id: 9, image: "../style/img/Rehabserv/Sports Recover.jpg", title: "Sports injury recovery package", price: 999, link: "/Sportsinjury" },
    { id: 10, image: "../style/img/Rehabserv/Chronic pain.jpg", title: "Chronic pain management package", price: 999, link: "/Chronicpain" },
    { id: 11, image: "../style/img/Rehabserv/Mobility.jpg", title: "Mobility and Balance training package", price: 999, link: "/Mobility" },
    { id: 12, image: "../style/img/Rehabserv/Strength and Conditioning.jpg", title: "Strength and Conditioning programs package", price: 999, link: "/Strength" },
    { id: 13, image: "../style/img/Rehabserv/Postsurgical.jpg", title: "Postsurgical Rehabilitation package", price: 999, link: "/Postsurgical" },
    { id: 14, image: "../style/img/Rehabserv/Chest physio.jpg", title: "Chest physio therapy package", price: 999, link: "/Chestphysio" },
    { id: 15, image: "../style/img/Rehabserv/Neuromuscular.jpg", title: "NMES Package", price: 999, link: "/Neuromuscular" },
    { id: 16, image: "../style/img/Rehabserv/Lymphedema.jpg", title: "Lymphedema management package", price: 999, link: "/Lymphedema" },
    { id: 17, image: "../style/img/Rehabserv/Vestibular.jpg", title: "Vestibular Rehabilitation package", price: 999, link: "/Vestibular" },
    { id: 18, image: "../style/img/Rehabserv/Manual therapy.jpg", title: "Manual therapy package", price: 999, link: "/Manualtherapy" },
    { id: 19, image: "../style/img/Rehabserv/Geriatric.jpg", title: "Geriatric physiotherapy package", price: 999, link: "/Geriatric" },
    { id: 20, image: "../style/img/Rehabserv/Strokerehab.jpg", title: "Stroke Rehabilitation", price: 899, link: "/Strokerehab" },
    { id: 21, image: "../style/img/Rehabserv/Muscle Strengthening.jpg", title: "Dynamic Muscle Strengthening", price: 899, link: "/Musclestrength" },
    { id: 22, image: "../style/img/Rehabserv/Gaittraining.jpg", title: "Gait Training Program", price: 899, link: "/Gaittraining" },
    { id: 23, image: "../style/img/Rehabserv/ADL.jpg", title: "Activities of Daily Life(ADL) training", price: 2999, link: "/ADL" },
    { id: 24, image: "../style/img/Rehabserv/balanceco.jpg", title: "Balance and Coordination", price: 1999, link: "/Balanceco" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(cardData.length / itemsPerPage);

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return cardData.slice(startIndex, startIndex + itemsPerPage);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Header />
      <div className="rhfsebg" style={{ padding: "2rem 0", backgroundColor: "#f8f9fa" }}>
        <div className="container" style={{ maxWidth: "1200px", padding: "0 1rem" }}>
          <center>
            <h2 className="rhfhed1">Services We Provide</h2>
            <br />
            <p>Experience compassionate care and expert guidance tailored to your individual needs, fostering holistic wellness and empowering you to thrive.</p>
          </center>

          <div className="container">
            <div className="row">
              {paginateData().map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>

            <div className="text-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="custom-btn1 btn-3"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`page-number ${currentPage === index + 1 ? 'active' : ''}`}
                  style={{ margin: "0 0.5rem", padding: "0.5rem 1rem", border: "none", background: currentPage === index + 1 ? "#007bff" : "#ddd", color: currentPage === index + 1 ? "#fff" : "#000", cursor: "pointer" }}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="custom-btn1 btn-3"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Card({ card }) {
  return (
    <div className="col-sm-4 mb-4">
      <div className="card rhfcardser">
        <img className="card-img-top ghfg" src={card.image} alt="Card cap" />
        <div className="card-body d-flex flex-column">
          <p className="card-text">{card.title}</p>
          <p className="text-dark"><b className="text-dark">₹{card.price}/-</b></p>
          <center>
            <Link to={card.link}>
              <button className="custom-btn1 btn-3"><span>Book Now</span></button>
            </Link>
          </center>
        </div>
      </div>
    </div>
  );
}
