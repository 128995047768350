import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

function CoordinatorHeader() {
  const { coordinatorEmail, coordinatorId } = useContext(AuthContext);
  const [menuActive, setMenuActive] = useState(false);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const menuBar = (
    <nav className="nav">
      <input
        type="checkbox"
        id="nav-check"
        checked={menuActive}
        onChange={toggleMenu}
      />
      <div className="nav-header"></div>
      <div className="nav-btn providerheadernavbtn">
        <label htmlFor="nav-check">
          <span className="bgm1"></span>
          <span className="bgm1"></span>
          <span className="bgm1"></span>
        </label>
      </div>
      <ul className="nav-list mynavlist providerlist">
        <li>
          <Link className="nav-link ddd" to="/coordinator/dashboard">
            <font color="#fff">Dashboard</font>
          </Link>
        </li>
        {/* <li>
          <Link className="nav-link ddd" to="/admin/profile">
            <font color="#fff">Profile</font>
          </Link>
        </li> */}
        <li>
          <a className="nav-link ddd" href="/coordinator/report">
            <font color="#fff">Report</font>
          </a>
        </li>
        <li>
          <a className="nav-link ddd" href="/coordinator/scheduling">
            <font color="#fff">Schedule</font>
          </a>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="logo providerlogo">
            <Link to="/provider/landing">
              <img src="/../style/img/logonav.png" alt="Logo" />
            </Link>
          </div>

          <div className="push-left">
            <ul
              id="primary-menu"
              className={`menu nav-menu ${menuActive ? "menu-active" : ""}`}
            >
              <li className="topnavtext submenu dropdown">
                {!coordinatorEmail ? (
                  <Link
                    className="custom-btn btn-1 loginbtn loginbutton dropdown-toggle"
                    to="/coordinator/login"
                  >
                    Login
                  </Link>
                ) : (
                  <>
                    <span className="topnavtext dropdown-toggle">
                      {coordinatorEmail}
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="nav-link" to="/coordinator/profile">
                          <i className="fa-solid fa-user"></i> Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coordinator/login"
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          <i className="fa-solid fa-user"></i> Logout
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {coordinatorEmail && menuBar}
    </>
  );
}

export default CoordinatorHeader;
