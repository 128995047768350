import React from 'react';
import Header from '../header/Header';

const SurgeryServices = () => {
  return (
    <>
    <Header></Header>
    <div className="surgery-services">
      <header className="header-surgery">
          <div className="header-surgery-content">
          <h1 className="header-surgery-title">Respiro Respiratory Surgery Services</h1><br></br>
          <h2 className="header-surgery-subtitle">Expert Respiratory Care from Leading Surgeons at Respiro Clinics</h2><br></br>
          <p className="header-surgery-prompt">Book Your Consultation Today!</p>
          
        </div>
      </header>

      <section className="surgery-list">
        <h3>Popular Respiratory Surgeries</h3>
        <div className="surgery-icons">
          <div className="surgery-icon-item">Lung Biopsy</div>
          <div className="surgery-icon-item">Bronchoscopy</div>
          <div className="surgery-icon-item">Thoracotomy</div>
          <div className="surgery-icon-item">Pleural Biopsy</div>
          <div className="surgery-icon-item">Tracheostomy</div>
          <div className="surgery-icon-item">Lung Transplant</div>
          <div className="surgery-icon-item">Pulmonary Resection</div>
          <div className="surgery-icon-item">Endobronchial Ultrasound (EBUS)</div>
          <div className="surgery-icon-item">Pleurodesis</div>
          <div className="surgery-icon-item">Robotic Lung Surgery</div>
          <div className="surgery-icon-item">Video-Assisted Thoracoscopic Surgery (VATS)</div>
          <div className="surgery-icon-item">Decortication Surgery</div>
          <div className="surgery-icon-item">Stenting and Balloon Dilation</div>
          <div className="surgery-icon-item">Chest Wall Reconstruction</div>
          <div className="surgery-icon-item">Oxygen Therapy Management</div>
        </div>
      </section>

      <section className="specialties-surgery">
        <h3>Our Specialties</h3>
        <div className="specialties-surgery-list">
          <div className="specialty-surgery-item">
            <img src="../style/img/resp/lung2.jpg" alt="Pulmonary Surgery" className="specialty-surgery-image" />
            <p>Pulmonary Surgery - 10 procedures</p>
          </div>
          <div className="specialty-surgery-item">
            <img src="../style/img/resp/Thoracic2.png" alt="Thoracic Surgery" className="specialty-surgery-image" />
            <p>Thoracic Surgery - 6 procedures</p>
          </div>
          <div className="specialty-surgery-item">
            <img src="../style/img/resp/Transplant2.jpeg" alt="Transplant Surgery" className="specialty-surgery-image" />
            <p>Transplant Surgery - 2 procedures</p>
          </div>
          <div className="specialty-surgery-item">
            <img src="../style/img/resp/Minimally2.jpg" alt="Minimally Invasive Surgery" className="specialty-surgery-image" />
            <p>Minimally Invasive Surgery - 5 procedures</p>
          </div>
          <div className="specialty-surgery-item">
            <img src="../style/img/resp/Diagnostic2.jpg" alt="Diagnostic Procedures" className="specialty-surgery-image" />
            <p>Diagnostic Procedures - 7 tests</p>
          </div>
        </div>
      </section>

      <section className="benefits-surgery">
        <h3>Respiro Surgery Benefits</h3><br></br><br></br>
        <div className="benefit-surgery-item">
          <img src="../style/img/resp/Access2.jpg" alt="Expert Surgeons" className="benefit-surgery-icon" />
          <p>Access to Leading Surgeons</p>
        </div>
        <div className="benefit-surgery-item">
          <img src="../style/img/resp/Advanced2.jpg" alt="Advanced Technology" className="benefit-surgery-icon" />
          <p>Advanced Surgical Technology</p>
        </div>
        <div className="benefit-surgery-item">
          <img src="../style/img/resp/Personalized2.jpg" alt="Personalized Care" className="benefit-surgery-icon" />
          <p>Personalized Patient Care</p>
        </div>
        <div className="benefit-surgery-item">
          <img src="../style/img/resp/Comprehensive2.jpg" alt="Comprehensive Support" className="benefit-surgery-icon" />
          <p>Comprehensive Post-Surgery Support</p>
        </div>
      </section>

      {/* <section className="how-it-works-surgery">
        <h3>How It Works</h3>
        <ol>
          <li>Consult with a Specialist</li>
          <li>Review Your Surgical Options</li>
          <li>Schedule Your Surgery</li>
          <li>Undergo the Procedure</li>
          <li>Receive Post-Operative Care</li>
        </ol>
      </section> */}

      <section className="about-surgery">
        <h3>About Our Surgical Services</h3>
        <p>At Respiro, we provide advanced respiratory surgical care, using state-of-the-art technology and techniques. Our team of experts is dedicated to ensuring the best outcomes for our patients through comprehensive preoperative and postoperative care.</p>
      </section><br></br>

      <section className="booking-surgery container">
        <h3 style={{textAlign:"center"}}>Book Your Surgery</h3>
        <form>
          <label htmlFor="patient-name">Full Name :</label>
          <input type="text" id="patient-name" name="patient-name" required />
          
          <label htmlFor="patient-email">Email Address :</label>
          <input type="email" id="patient-email" name="patient-email" required />
          
          <label htmlFor="surgery-type">Type of Surgery :</label>
          <select id="surgery-type" name="surgery-type" required>
            <option value="lung-biopsy">Lung Biopsy</option>
            <option value="bronchoscopy">Bronchoscopy</option>
            <option value="thoracotomy">Thoracotomy</option>
            <option value="pleural-biopsy">Pleural Biopsy</option>
            <option value="tracheostomy">Tracheostomy</option>
            <option value="lung-transplant">Lung Transplant</option>
            <option value="Pulmonary Resection">Pulmonary Resection</option>
            <option value="Endobronchial Ultrasound (EBUS)">Endobronchial Ultrasound (EBUS)</option>
            <option value="Pleurodesis">Pleurodesis</option>
            <option value="Robotic Lung Surgery">Robotic Lung Surgery</option>
            <option value="Video-Assisted Thoracoscopic Surgery (VATS)">Video-Assisted Thoracoscopic Surgery (VATS)</option>
            <option value="Decortication Surgery">Decortication Surgery</option>
            <option value="Stenting and Balloon Dilation">Stenting and Balloon Dilation</option>
            <option value="Chest Wall Reconstruction">Chest Wall Reconstruction</option>
            <option value="Oxygen Therapy Management">Oxygen Therapy Management</option>
          </select>
          <br></br>
          <br></br>
         <center>
          <button type="submit" >Book Now</button>
          </center>
        </form>
      </section><br></br>
    </div>
   
</>

  );
};

export default SurgeryServices;
