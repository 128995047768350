import { useRoutes } from "react-router-dom";
import RHFemergencyCare from "../pages/rhfProducts/RHFemergencyCare";
import RHFsleepStudy from "../pages/rhfProducts/RHFsleepStudy";
import RHFroutineMedCheckup from "../pages/rhfProducts/RHFroutineMedCheckup";
import RHFpulmonaryFunctionTest from "../pages/rhfProducts/RHFpulmonaryFunctionTest";
import RHFCOPDpatientCare from "../pages/rhfProducts/RHFCOPDpatientCare";
import RHFchestPhysiotherapy from "../pages/rhfProducts/RHFchestPhysiotherapy";
import RHFpneumoniaPediatricCare from "../pages/rhfProducts/RHFpneumoniaPediatricCare";
import RHFdoctorConsultation from "../pages/rhfProducts/RHFdoctorConsultation";
import RHFspirometryTest from "../pages/rhfProducts/RHFspirometryTest";
import Oxygenadministration from "../pages/rhfProducts/Oxygenadministration";


import Patientcareplan from "../pages/rhfProducts/Patientcareplan";
import Ostomycare from "../pages/rhfProducts/Ostomycare";

import RHFenema from "../pages/rhfProducts/RHFenema";
import Patientassessment from "../pages/rhfProducts/Patientassessment";
import Tracheostomycare from "../pages/rhfProducts/Tracheostomycare";
import Rhfvaccinations from "../pages/rhfProducts/Rhfvaccinations"
import Rhfinfusiontherapy from "../pages/rhfProducts/Rhfinfusiontherapy"

const RHFproductRoutes = () => {
    let routes = useRoutes([
        { path: "/RHFemergencyCare", element: <RHFemergencyCare /> },
        { path: "/RHFsleepStudy", element: <RHFsleepStudy/> },
        { path: "/RHFroutineMedCheckup", element: <RHFroutineMedCheckup/> }, 
        { path: "/RHFpulmonaryFunctionTest", element: <RHFpulmonaryFunctionTest/> }, 
        { path: "/RHFCOPDpatientCare", element: <RHFCOPDpatientCare/> },
        { path: "/RHFchestPhysiotherapy", element: <RHFchestPhysiotherapy/> },
        { path: "/RHFpneumoniaPediatricCare", element: <RHFpneumoniaPediatricCare/> },
        { path: "/RHFdoctorConsultation", element: <RHFdoctorConsultation/> },
        { path: "/RHFspirometryTest", element: <RHFspirometryTest/> },
        { path: "/Oxygenadministration", element: <Oxygenadministration/> },

        { path: "/Patientcareplan", element: <Patientcareplan/> },
        { path: "/Ostomycare", element: <Ostomycare/> },
        { path: "/Patientassessment", element: <Patientassessment/> },
        { path: "/Tracheostomycare", element: <Tracheostomycare/> },
        
        { path: "/RHFenema", element: <RHFenema />},
        { path : "/Rhfvaccinations" , element: <Rhfvaccinations/>},
        { path : "/Rhfinfusiontherapy" , element: <Rhfinfusiontherapy/>},
        
    ]);
    return routes;
  };
  
  export default RHFproductRoutes;