import React from "react";
import Header from "../header/Header";
import { useState } from "react";
export default function Pulmonaryfntest(){
    const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
    return (
      <p>
        <Header></Header>
        <div class="bg2">
          <div class="bg3">
            <div class="" style={{ textAlign: "center", position: "relative" }}>
              <center>
                <img
                  src="../style/img/resp/pneumoban.png"
                  class="pneumoban"
                  alt=""
                ></img>
              </center>
              <center>
                <br></br>
                <br></br>
                <h3>
                  <b class="just text-white pneu1">Pulmonary Function Tests</b>
                </h3>
              </center>
              <center>
                <p>
                  <b class="just text-white pneu2">
                    <br></br>
                    Comprehensive testing to assess lung function and health.
                  </b>
                </p>
              </center>
            </div>

            <div class="greenbg">
              <br></br>
              <center>
                <h5
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    fontFamily: "Poppins",
                  }}
                >
                  Package Contents
                </h5>
              </center>
              <br></br>
              <div class="container">
                <center>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="blank">
                        <div class="container">
                          <br></br>
                          <img
                            src="../style/img/resp/pneuicon1.png"
                            style={{ height: "60px" }}
                            alt=""
                          ></img>
                          <br></br>
                          <center>
                            <h5 class="iconh"> Initial Assessment</h5>
                          </center>
                          <br></br>
                          <center>
                            <p class="iconp">
                              Comprehensive evaluation to determine your
                              specific lung function needs.{" "}
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="blank">
                        <div class="container">
                          <br></br>
                          <img
                            src="../style/img/resp/pneuicon2.png"
                            style={{ height: "60px" }}
                            alt=""
                          ></img>
                          <br></br>
                          <center>
                            <h5 class="iconh"> Spirometry</h5>
                          </center>
                          <br></br>
                          <center>
                            <p class="iconp">
                              Detailed analysis of lung function through
                              spirometry.{" "}
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="blank">
                        <div class="container">
                          <br></br>
                          <img
                            src="../style/img/resp/pneuicon4.png"
                            style={{ height: "60px" }}
                            alt=""
                          ></img>
                          <br></br>
                          <center>
                            <h5 class="iconh"> Plethysmography</h5>
                          </center>
                          <br></br>
                          <center>
                            <p class="iconp">
                              Assessment of lung volumes and capacities.
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="blank">
                        <div class="container">
                          <br></br>
                          <img
                            src="../style/img/resp/pneuicon4.png"
                            style={{ height: "60px" }}
                            alt=""
                          ></img>
                          <br></br>
                          <center>
                            <h5 class="iconh"> Diffusion Capacity</h5>
                          </center>
                          <br></br>
                          <center>
                            <p class="iconp">
                              Measurement of gas exchange efficiency in the
                              lungs.
                            </p>{" "}
                          </center>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="blank">
                        <div class="container">
                          <br></br>
                          <img
                            src="../style/img/resp/pneuicon4.png"
                            style={{ height: "60px" }}
                            alt=""
                          ></img>
                          <br></br>
                          <center>
                            <h5 class="iconh"> Follow-up and Monitoring</h5>
                          </center>
                          <br></br>
                          <center>
                            <p class="iconp">
                              Regular follow-ups to monitor and adjust your
                              treatment plan.{" "}
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="blank">
                        <div class="container">
                          <br></br>
                          <img
                            src="../style/img/resp/pneuicon4.png"
                            style={{ height: "60px" }}
                            alt=""
                          ></img>
                          <br></br>
                          <center>
                            <h5 class="iconh">
                              {" "}
                              Precautions and Self-Care Advice
                            </h5>
                          </center>
                          <br></br>
                          <center>
                            <p class="iconp">
                              Tips on precautions and self-care practices to
                              enhance your recovery.{" "}
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </center>
                <br></br>
              </div>
              <br></br>
              <br></br>
            </div>
            <br></br>
            <br></br>

            <div>
              <center>
                <h3
                  style={{
                    fontWeight: "800",
                    fontFamily: "Poppins",
                    fontSize: "30px",
                  }}
                >
                  Plan Purchase
                </h3>
                <h6
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Choose Your Plan Duration
                </h6>
              </center>

              <div class="container">
                <center>
                  <div
                    class="greenbg priceboxgreen"
                    style={{
                      borderRadius: "20px",
                      width: "750px",
                      height: "300px",
                    }}
                  >
                    <br></br>
                    <form
                      class="booking-form"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div class="form-group11">
                        <label class="boxresd">Duration Selector:</label>
                        <div
                          class="diaginput"
                          style={{ position: "relative", marginLeft: "10px" }}
                        >
                          <span
                            class="subtraction text-dark"
                            onClick={() => handleDurationChange(-1)}
                            style={{
                              position: "absolute",
                              left: 20,
                              width: "20px",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            -
                          </span>
                          <input
                            class="booktestdur"
                            name="duration"
                            value={`${duration} month${
                              duration > 1 ? "s" : ""
                            }`}
                            readOnly
                            style={{
                              textAlign: "center",
                              width: "100%",
                              padding: "0 40px",
                            }}
                          />
                          <span
                            class="addition text-dark  booktestadd"
                            onClick={() => handleDurationChange(1)}
                            style={{
                              position: "absolute",
                              right: 20,
                              bottom: "0px",
                              width: "20px",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div class="form-group11">
                        <label class="boxresd">Price:</label>
                        <div class="diaginput" style={{ position: "relative" }}>
                          <input
                            class="booktestdur"
                            style={{ width: "300px" }}
                            type="number"
                            name="totalPrice"
                            value={totalPrice}
                            readOnly
                          />
                        </div>
                      </div>
                    </form>
                    <br></br>
                    <center>
                      <button
                        class="purchasebutton"
                        style={{
                          fontWeight: "600",
                          backgroundColor: "#fff",
                          color: "#466E6C",
                          borderRadius: "30px",
                        }}
                      >
                        Purchase
                      </button>
                    </center>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
          <br></br>
        </div>
      </p>
    );
}
