import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Pagination from 'react-bootstrap/Pagination';

import {
  setFunction,
  getFunction,
  updateFunction,
  deleteFunction,
} from "../../api/apiFunctions";

function AddRhfservice() {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    id: null,
    serviceName: "",
    cost: "",
    sessionCount: "",
    frequency: "",
  });
  const [services, setServices] = useState([]); 
  const [show, setShow] = useState(false); 
  const [isEditMode, setIsEditMode] = useState(false);
  const [filteredRhfservices, setFilteredRhfservices] = useState([]); // For filtered data
  const [itemsPerPage] = useState(7); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const handleShow = () => setShow(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState({});
  const handleClose = () => {
    setShow(false);
    setData({
      id: null,
      serviceName: "",
      cost: "",
      sessionCount: "",
      frequency: "",
    });
    setIsEditMode(false);
    setShowConfirm(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchServices();
  }, []);
  useEffect(() => {
    filterRhfservice();
  }, [searchTerm, services]);
  const filterRhfservice = () => {
    const filtered = services.filter(q =>
      q.service_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRhfservices(filtered);
    setCurrentPage(1); // Reset to first page on search
  };


  const fetchServices = () => {
    getFunction({}, "/rhf_service/get-service")
      .then((response) => {
        const responseData = response.success.result;
        setServices(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isEditMode) {
        updateService();
      } else {
        const newService = {
          serviceName: data.serviceName,
          cost: data.cost,
          sessionCount: data.sessionCount,
          frequency: data.frequency,
        };

        setFunction(newService, "/rhf_service/set-service")
          .then((response) => {
            setData({ ...data, id: response.success.id });
            toast.success('RHF Service added successfully');
            fetchServices(); 
            handleClose(); 
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              toast.error(error.response.data.error);
            } else {
              toast.error('An error occurred while adding RHF service');
            }
            console.log(error);
          });
      }
    }
  };



  const handleDelete = (serviceId) => {
    console.log("Deleting service with ID:", serviceId); // Add this log for debugging
    deleteFunction({ id: serviceId }, "/rhf_service/delete-service")
      .then((response) => {
        console.log("Delete response:", response); // Log the response for debugging
        toast.success("Service deleted successfully");
        fetchServices(); 
        handleClose();
      })
      .catch((error) => {
        console.error("Error deleting service:", error); // Adjusted for clarity
        if (error.response) {
          toast.error(error.response.data.error);
        } else {
          toast.error('This Services cannot be deleted because it is associated with one or more rhf.');
        }
      });
  };


  const handleEdit = (service) => {
    setData({
      id: service.id,
      serviceName: service.service_name || "",
      cost: service.cost || "",
      sessionCount: service.session_count || "",
      frequency: service.frequency || "",
    });
    setIsEditMode(true);
    setShow(true);
  };

  const updateService = () => {
    const updatedService = {
      id: data.id,
      serviceName: data.serviceName,
      cost: data.cost,
      sessionCount: data.sessionCount,
      frequency: data.frequency,
    };

    updateFunction(updatedService, "/rhf_service/update-service")
      .then((response) => {
        toast.success("RHF Service updated successfully");
        fetchServices();
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.error;
  
          // Check if the error is related to a foreign key constraint
          if (errorMessage.includes()) {
            toast.error(
              "This Services cannot be deleted because it is associated with one or more rhf."
            );
          } else {
            // Display the original error message for other errors
            toast.error(errorMessage);
          }
        } else {
          toast.error('An error occurred while deleting the Rhf Service');
        }
        console.log(error);
      });
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!String(data.serviceName).trim()) {
      newErrors.serviceName = "Service is required";
      valid = false;
      toast.error('Service is required');
    }
    if (!String(data.cost).trim()) {
      newErrors.cost = "Cost is required";
      valid = false;
      toast.error('Cost is required');
    }
    if (!String(data.sessionCount).trim()) {
      newErrors.sessionCount = "Session count is required";
      valid = false;
      toast.error('Session count is required');
    }
    if (!String(data.frequency).trim()) {
      newErrors.frequency = "Frequency is required";
      valid = false;
      toast.error('Frequency is required');
    }

    setErrors(newErrors);
    return valid;
  };

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setData({ ...data, [fieldName]: fieldValue });
  };
   // Pagination logic
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   const currentRhfservices = filteredRhfservices.slice(indexOfFirstItem, indexOfLastItem);
 
   const totalPages = Math.ceil(filteredRhfservices.length / itemsPerPage);
 
   const handlePageChange = (pageNumber) => {
     setCurrentPage(pageNumber);
   };
   const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

 
   const renderPaginationItems = () => {
     let items = [];
     for (let number = 1; number <= totalPages; number++) {
       items.push(
         <Pagination.Item
           key={number}
           active={number === currentPage}
           onClick={() => handlePageChange(number)}
         >
           {number}
         </Pagination.Item>
       );
     }
     return items;
   };
  

  const modalUI = (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? "Edit RHF Service" : "Add New RHF Service"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <h2 className="citext topfrom">Service Name *</h2>
          <input
            type="text"
            name="serviceName"
            value={data.serviceName}
            onChange={changeHandler}
            placeholder="Service"
            className="form-input textbox"
          />
          <h2 className="citext topfrom">Cost *</h2>
          <input
            type="text"
            name="cost"
            value={data.cost}
            onChange={changeHandler}
            placeholder="Cost"
            className="form-input textbox"
          /><br /><br />
          <h2 className="citext topfrom">Session Count *</h2>
          <input
            type="text"
            name="sessionCount"
            value={data.sessionCount}
            onChange={changeHandler}
            placeholder="Session"
            className="form-input textbox"
          />
          <h2 className="citext topfrom">Frequency *</h2>
          <input
            type="text"
            name="frequency"
            value={data.frequency}
            onChange={changeHandler}
            placeholder="Frequency"
            className="form-input textbox"
          /><br /><br />
          <center>
          <button className="sub_btn" type="submit">{isEditMode ? "Update" : "Submit"}</button> &nbsp;
          <button onClick={handleClose}>Close</button>
          </center>
        </form>
      </Modal.Body>
    </Modal>
  );


  const handleShowConfirm = (serviceId) => {
    setSelectedSpec({ id: serviceId });
    setShowConfirm(true);
  };

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleClose} animation={false}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this service?</Modal.Body>
      <Modal.Footer>
        <button onClick={() => handleDelete(selectedSpec.id)}>Delete</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {modalUI}
      {modalConfirmUI}
      <ToastContainer />

       <div className="d-flex col-sm-5" style={{ justifyContent: "space-between" }}>
      <h4 style={{ marginLeft: "20px" }}>Add RHF Services</h4>
      <button onClick={handleShow}>+</button>
    </div>
    <br></br> <br></br>

    <div className="col-sm-6">
      <input
        type="text"
        placeholder="Search Rhf services"
        value={searchTerm}
        onChange={handleSearchChange}
        className="form-control mb-3"
      />
     <div style={{ overflowX: "scroll" }}>
      <table>
        <thead>
          <tr>
            <th className="th-sm">S.No</th>
            <th className="th-sm">Service Name</th>
            <th className="th-sm">Cost</th>
            <th className="th-sm">Session Count</th>
            <th className="th-sm">Frequency</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRhfservices &&
            currentRhfservices.map((item, index) => (
              <tr key={item.id}>
                <td>{indexOfFirstItem + index + 1}</td>
                <td>{item.service_name}</td>
                <td>{item.cost}</td>
                <td>{item.session_count}</td>
                <td>{item.frequency}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle style={{ all: "initial", fontWeight: "800" }}>
                      . . .
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleShowConfirm(item.id)}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleEdit(item)}>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      </div>
      <br />
      <Pagination>{renderPaginationItems()}</Pagination>
    </div>

    </>
  );
}

export default AddRhfservice;
