import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
function RHFpneumoniaPediatricCare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="ppcimg1">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>RHF - Pneumonia Pediatric Care
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Details</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Service Description</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Specialized care for children with pneumonia focuses
                          on proper treatment and management to ensure recovery
                          and prevent complications.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Flexible Schedule</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Choose the duration and frequency of care sessions to
                          suit your child’s health needs and recovery timeline.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>

                    <p class="iconh">Customizable Options</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Adjust the duration and price based on your specific
                          requirements.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">
                Advantages of Pneumonia Pediatric Care at Home
              </b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/appcicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Expert Pediatric Care</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Specialized care by experienced pediatricians and
                          respiratory therapists for children with pneumonia.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Comfort of Home</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Treatment provided in a familiar and comfortable
                          environment, reducing stress for the child.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/appcicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Personalized Treatment Plans</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Tailored care plans based on the child’s specific
                          condition and recovery needs.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Continuous Monitoring</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Regular monitoring of the child’s progress to ensure
                          effective treatment and recovery.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/appcicon5.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Parental Guidance</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Education and support for parents to manage the
                          child’s condition and care at home.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/acpicon5.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Reduced Hospital Visits</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Minimize the need for hospital admissions and frequent
                          visits.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default RHFpneumoniaPediatricCare;
