import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { checkAuthStatus } from "../helpers/authFunctions";
import { AuthContext } from "../context/authContext";
const withPatientAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const [isAuth, setIsAuth] = useState(undefined);
    const { accessToken, refreshToken } = useContext(AuthContext);
    const renewPath = "/auth/renew-token";
    const authType = "patient";

    useEffect(() => {
      console.log("Inside auth check");
      checkAuthStatus(accessToken, refreshToken, renewPath, authType)
        .then(() => {
          setIsAuth(true);
        })
        .catch((error) => {
          console.error("Authentication error:", error);
          setIsAuth(false);
        });
    }, [accessToken, refreshToken, renewPath, authType]);
    if (isAuth) {
      return <WrappedComponent {...props} />;
    } else if (isAuth === false) {
      return <Navigate to="/login" />;
    }
  };

  return WithAuth;
};

export default withPatientAuth;
