import { useState, useEffect, useContext } from "react";
import { setFunction, deleteFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";

const SpecializationTable = (props) => {
  const { providerId } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [editView, setEditView] = useState(false);
  const [existingSpec, setExistingSpec] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState(null);

  const [data, setData] = useState({
    id: "",
    providerId: "",
    specializationId: "",
    specializationName: "",
    providerType: "doctor",
  });

  useEffect(() => {
    if (props.data.specializationId !== undefined) {
      setExistingSpec(true);
      setData({
        id: props.data.id,
        providerId: props.data.providerId,
        specializationId: props.data.specializationId,
        specializationName: props.data.specializationName,
        providerType: "doctor",
      });
    } else {
      setEditView(true);
    }
    setData((prevData) => ({ ...prevData, providerId }));
  }, [providerId, props.data]);

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let specializationName = "";
    if (
      fieldName === "specializationId" &&
      fieldValue !== "Select Specialization"
    ) {
      specializationName = props.specialization.filter(
        (item) => item.id === fieldValue
      )[0].specialization_name;
      setData({ ...data, [fieldName]: fieldValue, specializationName });
      return;
    }
    setData({ ...data, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (
      !data.specializationName.trim() ||
      data.specializationId === "Select Specialization"
    ) {
      newErrors.specializationName = "Specialization name is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const newData = { ...data };
      delete newData.id;
      setFunction(newData, "/provider/set-provider-specialization")
        .then((response) => {
          toast.success("Specialization data added successfully");
          setEditView(false);
          setData({ ...data, id: response.data.success.id });
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error);
          } else {
            console.log(error);
          }
        });
    }
  };

  const deleteSpecialization = () => {
    deleteFunction({ id: selectedSpec.id }, "/provider/delete-provider-specialization")
      .then((response) => {
        toast.success("Specialization data deleted successfully");
        console.log("Delete response:", response);
        props.handleDelete(selectedSpec.id);
        setShowConfirm(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        } else {
          console.log(error);
        }
      });
  };

  const handleShowConfirm = (id) => {
    setSelectedSpec({ id });
    setShowConfirm(true);
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const submitButtonHandler = (e) => {
    setEditView(true);
    if (editView) {
      handleSubmit(e);
    }
  };

  const modalConfirmUI = (
    <Modal show={showConfirm} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this specialization?</Modal.Body>
      <Modal.Footer>
        <button onClick={deleteSpecialization}>Delete</button>
        <button onClick={handleClose}>Close</button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {modalConfirmUI}
      <ToastContainer />
      <tr>
        <td>
          <select
            disabled={!editView}
            type="text"
            name="specializationId"
            onChange={changeHandler}
            value={data.specializationId}
            placeholder="Select Specialization"
          >
            <option className="boldtext">Select Specialization</option>
            {props.specialization &&
              props.specialization.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.specialization_name}
                </option>
              ))}
          </select>
          {errors.specializationName && (
            <div className="validation">{errors.specializationName}</div>
          )}
        </td>
        <td>
          <Dropdown>
            <Dropdown.Toggle style={{ all: "initial", fontWeight: "800" }}>
              <FontAwesomeIcon icon={faGear} style={{ fontSize: "15px" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={submitButtonHandler}>
                {editView ? (
                  <>
                    <span>Submit</span>
                  </>
                ) : (
                  <></>
                )}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleShowConfirm(data.id)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default withProviderAuth(SpecializationTable);
