import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link, NavLink } from "react-router-dom";
import WorkerData from "../../data/WorkerData";
const WorkerLunghealth = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const items = WorkerData.filter(item => item.id === 1 || item.id === 2 || item.id === 3 || item.id === 4 || item.id === 5 || item.id === 6 || item.id === 7
    || item.id === 8 || item.id === 9 || item.id === 10
  );
  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // Function to add the 'show' class to elements in the viewport
  function checkCards() {
    const cards = document.querySelectorAll('.careercardanim');
    cards.forEach(card => {
      if (isElementInViewport(card)) {
        card.classList.add('show');
      }
    });
  }

  // Run the checkCards function on scroll
  window.addEventListener('scroll', checkCards);

  // Run the checkCards function on page load (in case some cards are initially visible)
  window.addEventListener('load', checkCards);
  return (
    <>
      <Header></Header>

      <div class="bg4">
        <div class="workerbanner">
          <div class="container">
            <center>
              <h3 class="wrkhed cartexanim">
                Construction Sector Lung Wellness Initiative
              </h3>
            </center>
          </div>
        </div>
        <div class="container">
          <br></br>
          <p class="techptext">
            Construction Worker Lung Health Plan: Protect the respiratory health
            of construction workers exposed to various airborne hazards and
            pollutants on job sites with our specialized Construction Worker
            Lung Health Plan. Tailored to address the unique challenges faced by
            individuals in the construction industry, our plan offers essential
            equipment, services, and resources to promote lung health and
            safety.
          </p>
          <p class="techpbold">
            {" "}
            Here's what our Construction Worker Lung Health Plan includes:
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-6">
              <div class="card workercard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">
                      Respiratory Protective Equipment (RPE)
                    </h3>
                    <img src="../style/img/resp/wrk1.png" class="techimg"></img>
                    <p class="techptext1">
                      Provide high-quality respiratory protective equipment such
                      as N95 respirators, dust masks, and respirator cartridges
                      to shield workers from inhalation of hazardous particles,
                      dust, and fumes commonly encountered on construction
                      sites.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card workercard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Lung Function Screening</h3>
                    <img src="../style/img/resp/wrk2.png" class="techimg"></img>
                    <p class="techptext1">
                      Conduct regular lung function screenings using portable
                      spirometers and peak flow meters to assess the respiratory
                      health of workers and detect early signs of lung
                      conditions such as occupational asthma or chronic
                      obstructive pulmonary disease (COPD)
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card workercard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">
                      Respiratory Hazard Awareness Training
                    </h3>
                    <img src="../style/img/resp/wrk3.png" class="techimg"></img>
                    <p class="techptext1">
                      Offer comprehensive training sessions on respiratory
                      hazard awareness and mitigation strategies, including
                      proper respirator use, respiratory hygiene practices, and
                      hazard communication protocols, to empower workers with
                      the knowledge and skills to protect their lung health.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card workercard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">Health Surveillance Programs</h3>
                    <img src="../style/img/resp/wrk4.png" class="techimg"></img>
                    <p class="techptext1">
                      Implement regular health surveillance programs to monitor
                      respiratory health trends among construction workers,
                      track exposure levels to occupational lung hazards, and
                      facilitate early detection and intervention for
                      work-related respiratory conditions.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="card workercard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed"> Occupational Health Consultation</h3>
                    <img src="../style/img/resp/wrk5.png" class="techimg"></img>
                    <p class="techptext1">
                      Provide access to occupational health professionals,
                      including respiratory therapists and occupational
                      physicians, for individual consultations, medical
                      evaluations, and personalized respiratory health
                      recommendations tailored to the specific needs and
                      concerns of construction workers
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>

            <div class="col-sm-6">
              <br></br>
              <br></br>
              <div class="card workercard careercardanim">
                <center>
                  <div class="containertech ">
                    <br></br>
                    <h3 class="techshed">Educational Resources and Support</h3>
                    <img src="../style/img/resp/wrk6.png" class="techimg"></img>
                    <p class="techptext1">
                      Distribute educational materials, posters, and pamphlets
                      highlighting the importance of lung health protection in
                      the construction industry, as well as providing
                      information on common respiratory hazards, symptoms of
                      lung disease, and preventive measures.
                    </p>
                  </div>
                </center>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div class="bg4">
        <img src="../style/img/resp/wrkbbr.png" class="hiwbg"></img>
        <br></br>
        <br></br>
        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Rectangle 1951.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="resphead">Respiratory Protective Equipment (RPE) </h4>
                  <p class="resp_subhead">N95 Respirators (Pack of 10)</p>
                  <br></br>
                  <p class="resp_subhead">Dust Masks (Pack of 60)</p>
                  <br></br>
                  <p class="resp_subhead">Respirator Cartridges (Set of 2)</p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice fprice1 wrkprup1 wfont">₹ 500/-</h5>
                <h5 class="sprice sprice1 wrkprup2 wfont">₹ 250/-</h5>
                <h5 class="tprice tprice1 wrkprup3 wfont">₹ 5000/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container" key={item.id}>
                    {item.id === 1 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" fprice custom-btn1 btn-3 workbtn1">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 2 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 workbtn2">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}

                    {item.id === 3 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" tprice custom-btn1 btn-3 workbtn3">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>
        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Rectangle 2001.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="lunghead">Lung Function Screening</h4>
                  <p></p>
                  <p class="lungsubhead">Portable Spirometer (One Time Purchase)</p>
                  <br></br>
                  <p>
                    <br></br>
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice2 wrkprup4"><b class="text-dark wfont">₹ 1500/-</b></h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container" key={item.id}>
                    {item.id === 4 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 workbtn4">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Rectangle 2011.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="rhead">Respiratory Hazard Awareness Training</h4>
                  <p></p>
                  <p class="rsubhead">Training Sessions (per worker) (One Time Fee)</p>
                  <br></br>
                  <p>
                    <br></br>
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice2 wrkprup5 wfont">₹ 200/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container" key={item.id}>
                    {item.id === 5 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 workbtn5">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Rectangle 2022.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="rhead">Occupational Health Consultation</h4>
                  <p></p>
                  <p class="rhsubhead">
                    Respiratory Health Consultation with a Pulmonologist{" "}
                    <br></br>
                    (Per Worker)
                  </p>
                  <p>
                    <br></br>
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice2 wrkprup6 wfont">₹ 300/-</h5>
              </div>
              <div class="col-sm-1">
                {items.map((item) => (
                  <div className="button-container" key={item.id}>
                    {item.id === 6 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 workbtn6">
                          <span class="btntexty btnfsm">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Rectangle 2033.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="rhead">Health Surveillance Programs </h4>
                  <p class="srhsubhead1">
                    Health Surveillance Program Setup (per site)
                    <br></br>
                    (One-time Fee)
                  </p>
                  <p class="srhsubhead2">
                    Lung Health Monitoring (per worker)
                    <br></br>
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice3 wrkprup7 wfont">₹ 500/-</h5>
                <h5 class="sprice3 wrkprup8 wfont">₹ 800/month</h5>
              </div>
              <div class="col-sm-1">







                {items.map((item) => (
                  <div className="button-container" key={item.id}>
                    {item.id === 7 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" fprice custom-btn1 btn-3 workbtn7">
                          <span class="btntexty btntextbtn4">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                    {item.id === 8 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 workbtn8">
                          <span class="btntexty btntextbtn4">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
        <center>
          <hr class="techhr"></hr>
        </center>

        <div class="card techfcard">
          <div class="container">
            <div class="row">
              <div class="col-sm-3">
                <img
                  src="../style/img/resp/Rectangle 2055.png"
                  class="techcardimg"
                ></img>
              </div>
              <div class="col-sm-6">
                <center>
                  <h4 class="rhead">Educational Resources and Support</h4>
                  <p></p>
                  <p class="rhsubhead1">
                    Educational Materials (Pamphlets and Posters) <br></br>
                  </p>
                  <p>
                    <br></br>
                  </p>
                </center>
              </div>
              <div class="col-sm-2">
                <h5 class="fprice4 wrkprup9 wfont">₹ 100/-</h5>
              </div>
              <div class="col-sm-1">

                {items.map((item) => (
                  <div className="button-container" key={item.id}>
                    {item.id === 9 && (
                      <NavLink to={`/worker/${item.id}`}>
                        <button class=" bprice custom-btn1 btn-3 workbtn9">
                          <span class="btntexty  btntextbtn5">Book Now</span>
                        </button>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg2">
        <br></br>
        <div class="container-fluid">
          <div class="card techlcard">
            <br></br>
            <div class="container">
              <center>
                <h4 class="techshed1">
                  Special Lung Care Package For Construction Sector Wagers{" "}
                </h4>
              </center>{" "}
              <br></br>
              <div class="row">
                <div class="col-sm-4">
                  <img
                    src="../style/img/resp/Rectangle 2288.png"
                    class="lcardimg"
                  ></img>
                </div>
                <div class="col-sm-8  " >
                  <h5 class="techshed2">Monthly Diagnosis Plan</h5>
                  <br></br>
                  <div class="container">
                    <p class="techshed3">
                      {" "}
                      In addition to our standard offerings, our Construction
                      Worker Lung Health Care Packages include the following
                      enhanced features :{" "}
                    </p>
                    <p class="techlp">
                      Nebulization Check-Up
                      <br></br>
                      Peak Flow Test
                      <br></br>
                      Asthma Prevention Test<br></br>
                      Oxygen Air Flow Test<br></br>
                      Consultation with Pulmonologist
                    </p>
                    <center>
                      <p class="techlp1">
                        {" "}
                        Monthly Diagnosis Plan&nbsp; &nbsp; &nbsp;{" "}
                        <b class="text-dark fprice5"> ₹ 999/-</b>&nbsp; &nbsp; &nbsp;
                        &nbsp;
                        {items.map((item) => (
                          <div className="button-container" key={item.id}>
                            {item.id === 10 && (
                              <NavLink to={`/worker/${item.id}`}>
                                <button class=" custom-btn1 btn-3 bookbtn7">
                                  <span class="btntexty btntextbtn6">Book Now</span>
                                </button>
                              </NavLink>
                            )}
                          </div>
                        ))}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </div>
        </div>
        <br></br>
      </div>
    </>
  );
}

export default WorkerLunghealth;



