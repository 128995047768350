import React, { useEffect, useState } from 'react';
import Header from "../header/Header";
import { useLocation } from "react-router-dom";
import { getFunction } from "../../api/apiFunctions";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../common/Loader";

export default function SearchResult() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [showDoctors, setShowDoctors] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedlocValue, setSelectedlocValue] = useState('');
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch specializations
  useEffect(() => {
    getFunction({ providerType: "doctor" }, "/provider/get-specialization")
      .then((response) => {
        const responseData = response.success.result;
        setSpecializations(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const callSearchService = () => {
      if (locationInput) {
        getFunction({ place: locationInput }, "/locality/get-location-by-name")
          .then((response) => {
            const responseData = response.success.result;
            setLocationSuggestions(responseData);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLocationSuggestions([]);
      }
    };

    let debouncer = setTimeout(() => {
      callSearchService();
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [locationInput]);

  // Log location input
  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocationInput(value);
    console.log("Location Input:", value); // Log the location input
  };

  const handleLocationSelect = (e) => {
    const selectedOption = locationSuggestions.find(
      (location) => location.id === e.target.value
    );

    if (selectedOption) {
      setSelectedLocation(selectedOption);
      setLocationInput(selectedOption.locality);
      setLocationSuggestions([]);
    }
  };

  //search result
  const pincodeOrPlace = data.loc;
  const specialization = data.spec;
  console.log(pincodeOrPlace);
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    setLoading(true); // Start loading

    fetchProviders(pincodeOrPlace, specialization);
  }, []);

  // Fetch appointments
  const fetchProviders = (pincodeOrPlace, specialization) => {
    getFunction({ pincodeOrPlace, specialization, providerType: "doctor" }, "/appointment/get-appointment-loc-and-specialization")
      .then((response) => {
        const responseData = response.success.result;
        setDatas(responseData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // Set a delay for the loader
        setTimeout(() => {
          setLoading(false); // Stop loading after fetching data
        }, 1000);
      });
  };

  // Handle change for specialization select
  const handleSpecializationChange = (event) => {
    const specializationValue = event.target.value;
    setSelectedValue(specializationValue);
    const locationValue = selectedLocation ? selectedLocation.locality : locationInput;
    setSelectedlocValue(locationValue);
    const data = { loc: locationValue, spec: specializationValue };
    fetchProviders(locationValue, specializationValue); // Fetch new appointments based on selected specialization
    console.log("Selected Specialization:", specializationValue); // Log the selected specialization
    console.log("Selected Location:", locationValue); // Log the selected location
  };


  return (
    <>
      <Header />
      {loading ? (
        <Loader />

      ) : (
        <>
          <div className="container-fluid bg2">
            <br />
            <div class="spec_search">
              <br /> <br /><br /><br />
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-sm-6 location">
                    {/* <div className="form-outline" data-mdb-input-init> */}
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        className="searche"
                        placeholder="Select Location"
                        value={locationInput}
                        onChange={handleLocationInputChange}
                      />
                      {locationSuggestions.length > 0 && (
                        <div className="suggestions-dropdown">
                          {locationSuggestions.map((item) => (
                            <button
                              key={item.id}
                              value={item.id}
                              onClick={handleLocationSelect}
                            >
                              {item.locality}, {item.districtname}, {item.state}
                            </button>
                          ))}
                        </div>
                      )}
                    {/* </div> */}
                  </div>
                  <div className="col-sm-6">
                    {/* <div className="form-outline" data-mdb-input-init> */}
                      <select className="form-control findcontorl" value={selectedValue} onChange={handleSpecializationChange}>
                        <option value="">Select Specialization</option>
                        {specializations.map((spec) => (
                          <option key={spec.id} value={spec.specialization_id}>
                            {spec.specialization_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  {/* </div> */}
                </div>
                <br /><br /> <br />
              </div>
            </div>
            <br />
          </div>
          <br />
          <br />
          <div class="container">
            {datas.length === 0 ? (
              <div className="card__detail">
                <div className="card__source text-bold">Doctors not found for this place</div>
              </div>
            ) : (
              datas && datas.map((item) => (
                <>
                  <div class="row" key={item.provider_id}>
                    <div class="col-sm-3">
                      <div>
                        {item.profilePic ? (
                          <img src={item.profilePic} alt="Profile" class="drprofile11" ></img>
                        ) : (
                          <img
                            src="../style/img/resp/profilepicdummy.png"
                            alt="Profile"
                            className="drprofile providerdrprofile"
                          />
                        )}
                      </div><br /><br />
                      <Link to={`/ProviderDetails/${item.provider_id}`} style={{ marginLeft: "55px", color: "#199fd9" }}>View Profile</Link>
                    </div>
                    <div class="col-sm-9">

                      <h4 style={{ color: "#199fd9" }}>{item.fullName}</h4>
                      <p class="docdetails">{item.specialization_name}</p>
                      <p class="docdetails">{item.years_experience} Years of experience</p>
                      <p class="docdetails">{item.locality} , {item.subdistrictname} , {item.districtname}  {item.state}</p>
                      <p class="docdetails"> <span style={{ fontWeight: "bold" }}>₹ {item.init_consult_fee}</span> Consultation fee at clinic</p>
                      {/* <div class="rating">
                      &nbsp;&nbsp;<i class="fa fa-thumbs-up" aria-hidden="true">&nbsp;&nbsp;90% &nbsp;</i>
                    </div> */}
                      {/* <div>
                      <center>
                        <h6 style={{ color: "green" }}>Available Today</h6>
                      </center>
                      <button class="custom-btn1 btn-3" style={{ marginLeft: "353px" }} >
                        <span>Book clinic Visit</span>
                      </button>

                    </div> */}
                    </div>
                  </div>
                  <hr />
                </>
              ))
            )}
          </div>
        </>
      )}
    </>
  );
}
