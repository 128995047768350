import React from 'react';
import { Accordion } from 'react-bootstrap';
import Header from "../header/Header";

export default function Career() {
    return (
        <>
            <Header />
            <div className="container-fluid">
                <img 
                    src="../style/img/background/careerbanner.png"
                    className="careerbnr"
                    alt="banner"
                />
                <div className="top-left"><b className="text-dark">CAREER</b></div>
                <hr className="careerhr" />
                <div className="top-right">Build a Future</div>
            </div>

            <div className="container ">
                <h4 style={{ fontFamily: "sans-serif" }}>Join Us</h4>
                <p> 
                    Join our dedicated team at Respiro and help us provide top-tier respiratory care across India. 
                    We are currently hiring for various positions in Bangalore, Chennai, Kochi, and Hyderabad. 
                    We offer competitive salaries and a rewarding career at Respiro.
                </p>
                <Accordion  className='custom-accordion '>
                    <Accordion.Item eventKey="0" className='but'>
                        <Accordion.Header style={{fontSize:"22px"}}>State Director </Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br><br></br> */}
                        <h5>Responsibilities:</h5>
                        &nbsp; Oversee all state-level operations.<br></br>
                        &nbsp; Ensure alignment with central strategies and goals.<br></br>
                        &nbsp; Act as the main point of contact between state and central leadership.<br></br><br></br>
                        <h5>Qualifications:</h5>
                        &nbsp; Proven leadership experience in healthcare operations.<br></br>
                        &nbsp; Strong strategic and communication skills.<br></br>
                        &nbsp; Ability to manage multiple teams and initiatives.<br></br><br></br>
                        {/* <h5>Salary:</h5> &nbsp; Competitive, commensurate with experience and qualifications.               */}
                        </div>          </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header style={{fontSize:"22px"}}>State Medical Director</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5> &nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br><br></br> */}
                        <h5>Responsibilities:</h5>
                        &nbsp; Lead state-level clinical operations and medical strategy.<br></br>
                        &nbsp; Ensure the highest standards of patient care within the state.<br></br><br></br>
                        <h5>Qualifications:</h5>
                        &nbsp; Medical degree with specialization in pulmonology or related fields.<br></br>
                        &nbsp; Extensive clinical and administrative experience.<br></br>
                        &nbsp; Strong leadership and organizational skills.<br></br><br></br>
                        {/* <h5>Salary:</h5> &nbsp; Competitive, commensurate with experience and qualifications.        */}
                        </div>                 </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header style={{fontSize:"22px"}}>State Head of Pulmonology</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5> &nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br><br></br> */}
                        <h5>Responsibilities:</h5>
                        &nbsp; Lead the pulmonology department at the state level.<br></br>
                        &nbsp; Oversee patient consultations, diagnostics, and treatment plans.<br></br><br></br>
                        <h5>Qualifications:</h5>
                        &nbsp; Medical degree with specialization in pulmonology.<br></br>
                        &nbsp; Experience in patient care and team management.<br></br>
                        &nbsp; Excellent diagnostic and therapeutic skills.<br></br><br></br>
                        {/* <h5>Salary:</h5> &nbsp; Competitive, commensurate with experience and qualifications.      */}
                        </div>                   </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header style={{fontSize:"22px"}}>State Head of Nursing Services</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>
                        &nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br><br></br> */}
                        <h5>Responsibilities:</h5>
                        &nbsp; Manage state-level home respiratory care nursing teams.<br></br>
                        &nbsp; Ensure quality and consistency in nursing care delivery.<br></br><br></br>
                        <h5>Qualifications:</h5>
                        &nbsp; Nursing degree with relevant experience.<br></br>
                        &nbsp; Strong leadership and organizational skills.<br></br>
                        &nbsp; Commitment to patient care excellence.<br></br><br></br>
                        {/* <h5>Salary:</h5> &nbsp; Competitive, commensurate with experience and qualifications. */}
                                        </div>  </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header style={{fontSize:"22px"}}>State Head of Rehabilitation Services</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5> 
                        &nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                        &nbsp; Oversee state-level rehabilitation therapy programs.<br></br>
                        &nbsp; Develop personalized rehabilitation plans for state patients.<br></br>
                        <br></br><h5>Qualifications:</h5>
                        &nbsp; Degree in physical therapy or related field.<br></br>
                        &nbsp; Experience in rehabilitation services.<br></br>
                        &nbsp; Ability to design and implement effective therapy programs.<br></br>
                        {/* <br></br><h5>Salary:</h5>Competitive, commensurate with experience and qualifications. */}
                        </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header style={{fontSize:"22px"}}>State Head of Customer Support</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                       &nbsp; Lead customer service and patient support teams within the state.<br></br>
                       &nbsp; Ensure patient satisfaction and handle state-specific inquiries and feedback.<br></br>
                        <br></br><h5>Qualifications:</h5>
                       &nbsp; Experience in customer service management.<br></br>
                        &nbsp; Strong communication and problem-solving skills.<br></br>
                        &nbsp; Ability to manage and motivate a team.<br></br>
                        {/* <br></br><h5>Salary:</h5>&nbsp; Competitive, commensurate with experience and qualifications.   */}
                        </div></Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header style={{fontSize:"22px"}}>State Head of Marketing and Communications</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                       &nbsp; Implement state-specific marketing and communication strategies.<br></br>
                       &nbsp; Manage public relations and communications within the state.<br></br>
                        <br></br><h5>Qualifications:</h5>
                       &nbsp; Degree in marketing, communications, or related field.<br></br>
                        &nbsp; Experience in healthcare marketing preferred.<br></br>
                        &nbsp; Strong strategic and creative skills.<br></br>
                        {/* <br></br><h5>Salary:</h5>&nbsp; Competitive, commensurate with experience and qualifications.    */}
                        </div>            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header style={{fontSize:"22px"}}>State Head of Human Resources</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                       &nbsp; Manage state-level recruitment, employee relations, and HR policies.<br></br>
                       &nbsp; Ensure a positive and productive work environment within the state.<br></br>
                        <br></br><h5>Qualifications:</h5>
                       &nbsp; Degree in human resources or related field.<br></br>
                        &nbsp; Experience in HR management.<br></br>
                        &nbsp; Strong interpersonal and organizational skills.<br></br>
                        {/* <br></br><h5>Salary:</h5>&nbsp; Competitive, commensurate with experience and qualifications. */}
                             </div>               </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header style={{fontSize:"22px"}}>State Administrative Staff</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                       &nbsp; Handle state-specific administrative tasks and patient records.<br></br>
                       &nbsp; Support operational efficiency and coordination within the state.<br></br>
                        <br></br><h5>Qualifications:</h5>
                        &nbsp; Administrative experience in healthcare preferred.<br></br>
                        &nbsp; Strong organizational and multitasking skills.<br></br>
                        &nbsp; Proficiency in office software and record-keeping.<br></br>
                        {/* <br></br><h5>Salary:</h5>&nbsp; Competitive, commensurate with experience and qualifications. */}
                               </div>             </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header style={{fontSize:"22px"}}>State Customer Service Representatives</Accordion.Header>
                        <Accordion.Body >
                        <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                       &nbsp; Address patient inquiries and provide support at the state level.<br></br>
                       &nbsp; Ensure a positive patient experience and handle state-specific scheduling and follow-ups.<br></br>
                        <br></br><h5>Qualifications:</h5>
                       &nbsp; Experience in customer service.<br></br>
                        &nbsp; Excellent communication and interpersonal skills.<br></br>
                        &nbsp; Ability to handle patient concerns with empathy and efficiency.<br></br>
                        {/* <br></br><h5>Salary:</h5>&nbsp;  Competitive, commensurate with experience and qualifications. */}
                        </div>    </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header  style={{fontSize:"22px"}}>State Medical Assistants</Accordion.Header>
                        <Accordion.Body>
                            <div class="careerdetails">
                        {/* <h5>Location:</h5>&nbsp; Bangalore, Chennai, Kochi, Hyderabad<br></br> */}
                        <br></br><h5>Responsibilities:</h5>
                       &nbsp; Support state-level clinical staff with patient care and administrative duties.<br></br>
                       &nbsp; Assist in preparing patients for consultations and treatments.<br></br>
                        <br></br><h5>Qualifications:</h5>
                       &nbsp; Medical assistant certification or relevant experience.<br></br>
                        &nbsp; Strong organizational and patient care skills.<br></br>
                        &nbsp; Ability to work in a fast-paced clinical environment.<br></br>
                        {/* <br></br><h5>Salary:</h5>&nbsp; Competitive, commensurate with experience and qualifications. */}
                            </div>                </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}
