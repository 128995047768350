import { useRoutes } from "react-router-dom";
import ChildLungcareplan from "../pages/childLungcareplan/ChildLungcareplan";
import YoungsterLungcareplan from "../pages/childLungcareplan/YoungsterLungcareplan";
import FamilyLungcareplan from "../pages/childLungcareplan/FamilyLungcareplan";
import ElderlyLungcareplan from "../pages/childLungcareplan/ElderlyLungcareplan";
const LungcareplansRoutes = () => {
    let routes = useRoutes([
        { path: "/ChildLungcareplan", element: <ChildLungcareplan /> },
        { path: "/YoungsterLungcareplan", element: <YoungsterLungcareplan/> },
        { path: "/FamilyLungcareplan", element: <FamilyLungcareplan /> },
        { path: "/ElderlyLungcareplan", element: <ElderlyLungcareplan /> },

    ]);
    return routes;
  };
  
  export default LungcareplansRoutes;
  