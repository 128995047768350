import React, { useEffect, useState, useContext } from "react";
import { getFunction, setFunction, updateFunction } from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import { FaStar } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { CiEdit } from "react-icons/ci";

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function PastAppointments() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { patientId } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState({});
  const [show, setShow] = useState(false);
  const [review, setReview] = useState({});
  const date = moment().format("yyyy-MM-DD");
  const [initialComments, setInitialComments] = useState(""); // Track initial comments separately

  const handleClose = () => {
    setShow(false);
    setReview({});
  };

  const handleShow = (comments, rating, providerId, appointmentDate, reviewDate, patientId, reviewId) => {
    setReview({ comments, rating, providerId, appointmentDate, reviewDate, patientId, reviewId });
    setInitialComments(comments || ""); // Set initial comments for comparison

    setShow(true);
  };

  const handleStarClick = (item, star) => {
    if (item.comments) {
      toast.warning("You have already reviewed this appointment.");
    } else {
      handleShow(item.comments, star, item.provider_id, item.startDate, date, patientId, item.reviewId);
    }
  };

  useEffect(() => {
    getFunction(
      { patientId, timeFrame: "past" },
      "/appointment/get-appointment-by-patient-id"
    )
      .then((response) => {
        const responseData = response.success.result;
        setData(responseData);

        // Initialize ratings based on fetched data
        const initialRatings = {};
        responseData.forEach(item => {
          initialRatings[item.id] = item.rating || 0; // Use 0 if rating is null
        });
        setRatings(initialRatings);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [patientId]);

  const handleRatingChange = (appointmentId, rating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [appointmentId]: rating,
    }));
    console.log(`You clicked on ${appointmentId}: ${rating}`);
  };

  const addReview = () => {
    const newReview = {
      patientId: review.patientId,
      providerId: review.providerId,
      appointmentDate: review.appointmentDate,
      rating: review.rating,
      comments: review.comments,
      reviewDate: review.reviewDate
    };
    setFunction(newReview, "/provider/set-review")
      .then((response) => {
        toast.success("Review added successfully");
        console.log(response.success);
        handleClose(); // Close modal after successful add
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred while adding the review");
        }
        console.log(error);
      });
  };

  //edit review
  const updateReview = () => {
    const updatedReview = {
      id: review.reviewId,  // Use reviewId from the state
      patientId: review.patientId,
      providerId: review.providerId,
      appointmentDate: review.appointmentDate,
      rating: review.rating,
      comments: review.comments,
      reviewDate: review.reviewDate,
      modifiedDate: date 
    };

    updateFunction(updatedReview, "/provider/update-review")
      .then((response) => {
        toast.success("Review updated successfully");
        console.log(response.data);
        handleClose(); 
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error);
        } else {
          toast.error("An error occurred while updating the review");
        }
        console.log(error);
      });
  };

  const handleReviewSubmit = () => {
    if (initialComments && initialComments === review.comments) {
      toast.info("No changes detected in the review.");
    } else if (initialComments) {
      updateReview(); 
    } else {
      addReview(); 
    }
  };

  return (
    <div className="">
      <ToastContainer />
      <br />
      <br />
      <br />
      <h1 className="profileheading1">Previous Appointments</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <hr />
          <div className="contain pastappointmentcontain">
            <div className="table-responsive providerline1">
              <div className="col-sm-9">
                <table>
                  <thead>
                    <tr>
                      <th>Provider Name</th>
                      <th>Appointment Date</th>
                      <th>Start Time</th>
                      <th>Status</th>
                      <th>Payment Due</th>
                      <th>Payment Status</th>
                      <th>Rating</th>
                      {/* <th>Comments</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td>{item.fullName}</td>
                        <td>{item.startDate}</td>
                        <td>{item.start_time}</td>
                        <td>{item.status}</td>
                        <td>Rs.&nbsp;{item.payment_due}</td>
                        <td>{item.payment_status}</td>
                        <td className="star-rating">
                          <CiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleShow(
                                item.comments,
                                ratings[item.id],
                                item.provider_id,
                                item.startDate,
                                date,
                                patientId,
                                item.reviewId // Pass reviewId
                              )
                            }
                          />{" "}
                          &nbsp;&nbsp;&nbsp;
                          {[5, 4, 3, 2, 1].map((star) => (
                            <React.Fragment key={star}>
                              <input
                                id={`star-${star}-${item.id}`}
                                type="radio"
                                name={`rating-${item.id}`}
                                value={`star-${star}`}
                                checked={ratings[item.id] === star}
                                onChange={() =>
                                  handleRatingChange(item.id, star)
                                }
                                disabled={!!item.comments} // Disable if comments exist
                              />
                              <label
                                htmlFor={`star-${star}-${item.id}`}
                                title={`${star} stars`}
                              >
                                <FaStar
                                  onClick={() => handleStarClick(item, star)}
                                  style={{ height: "15px", width: "16px" }}
                                  color={
                                    star <= ratings[item.id] ? "orange" : "gray"
                                  }
                                />
                              </label>
                            </React.Fragment>
                          ))}
                        </td>

                        {/* <td>{item.comments || "No comments"}</td> */}
                        {/* <td>{item.id}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Modal show={show} onHide={handleClose} animation={false}>
                  <Modal.Header>
                    <Modal.Title>Please share your review.</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* <p>Previous Comments: {review.comments || "No comments"}</p> */}
                    <textarea
                      style={{ width: "420px" }}
                      id="textcomments"
                      name="textcomments"
                      value={review.comments || ""}
                      rows="4"
                      cols="70"
                      onChange={(e) =>
                        setReview({ ...review, comments: e.target.value })
                      }
                    />
                    {/* <p>Your Rating:</p>
                    {[5, 4, 3, 2, 1].map((star) => (
                      <React.Fragment key={star}>
                        <input
                          id={`modal-star-${star}`}
                          type="radio"
                          name="modal-rating"
                          value={`star-${star}`}
                          checked={review.rating === star}
                          onChange={() =>
                            setReview({ ...review, rating: star })
                          }
                        />
                        <label htmlFor={`modal-star-${star}`} title={`${star} stars`}>
                          <FaStar
                            style={{ height: "20px", width: "20px" }}
                            color={star <= review.rating ? "orange" : "gray"}
                          />
                        </label>
                      </React.Fragment>
                    ))} */}
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="editbtn" onClick={handleClose}>
                      Close
                    </button>
                    <button
                      className="editbtn"
                      onClick={handleReviewSubmit} // Call handleReviewSubmit
                    >
                      Submit
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PastAppointments;
