import React, { useEffect, useState, useContext } from "react";
import Header from "../header/Header";
function RHFroutineMedCheckup() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [duration, setDuration] = useState(1);
  const [pricePerMonth] = useState(500);

  const handleDurationChange = (amount) => {
    const newDuration = duration + amount;
    if (newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const totalPrice = duration * pricePerMonth;
  return (
    <>
      <Header></Header>
      <div class="rmimg1">
        <center>
          <h2>
            <b class="text-white">
              <br></br>
              <br></br>
              <br></br>
              <br></br>RHF - Routine Medical Check-up<br></br> @ Home
            </b>
          </h2>
        </center>
      </div>
      <div class="signature-background22">
        <div class="container">
          <br></br>
          <center>
            <h2>
              <b class="text-white">Service Details</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Service Description</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Comprehensive health check-ups conducted conveniently
                          at your home by our experienced respiratory nurses.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/ssicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Flexible Schedule</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Choose the duration and frequency that best fits your
                          health needs.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/emicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Customizable Options</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Adjust the duration and price to suit your
                          preferences.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br />

          <center>
            <h2>
              <b class="text-white">Customization Options</b>
            </h2>
          </center>
          <br></br>
          <center>
            <div class="viosq">
              <div class="container">
                <form
                  class="booking-form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div class="form-group11">
                    <label class="boxresd">Duration Selector:</label>
                    <div
                      class="diaginput"
                      style={{ position: "relative", marginLeft: "10px" }}
                    >
                      <span
                        class="subtraction text-dark"
                        onClick={() => handleDurationChange(-1)}
                        style={{
                          position: "absolute",
                          left: 20,
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        -
                      </span>
                      <input
                        class="booktestdur"
                        name="duration"
                        value={`${duration} month${duration > 1 ? "s" : ""}`}
                        readOnly
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "0 40px",
                        }}
                      />
                      <span
                        class="addition text-dark  booktestadd"
                        onClick={() => handleDurationChange(1)}
                        style={{
                          position: "absolute",
                          right: 20,
                          bottom: "0px",
                          width: "20px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                      >
                        +
                      </span>
                    </div>
                  </div>
                  <div class="form-group11">
                    <label class="boxresd">Price:</label>
                    <div class="diaginput" style={{ position: "relative" }}>
                      <input
                        class="booktestdur"
                        style={{ width: "300px" }}
                        type="number"
                        name="totalPrice"
                        value={totalPrice}
                        readOnly
                      />
                    </div>
                  </div>
                </form>
                <br></br>
                <center>
                  <button class="custom-btn1 btn-3">
                    <span class="btntexty bookingbtn">Book Now</span>
                  </button>
                </center>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>
          <center>
            {" "}
            <h2>
              <b class="text-white">
                Advantages of Routine Medical Check-up @ Home
              </b>
            </h2>
          </center>
          <br></br>

          {/* //advantages// */}
          <center>
            <div class="row">
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon1.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Convenience</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Schedule check-ups at your convenience, eliminating
                          travel time and waiting at clinics.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon2.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Comprehensive Assessment</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Thorough health evaluations including vital signs,
                          blood tests, and more, personalized to your health
                          profile.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon3.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Early Detection</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Prompt identification of health issues allows for
                          early intervention and prevention.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
          <br></br>

          <center>
            <div class="row">
              <div class="col-sm-6">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/armicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Health Monitoringt</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Regular check-ups enable ongoing monitoring of chronic
                          conditions, promoting better disease management.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="violetgr">
                  <div class="container">
                    <img
                      src="../style/img/resp/advicon4.png"
                      class="fdicon"
                    ></img>
                    <br></br>
                    <p class="iconh">Cost-Effective</p>
                    <center>
                      <p class="iconp">
                        <b class="text-dark">
                          Education and support for parents to manage the
                          child’s condition and care at home.
                        </b>
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </center>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default RHFroutineMedCheckup;
