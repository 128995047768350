import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getFunction } from "../../api/apiFunctions";
import { FaArrowRightLong } from "react-icons/fa6";
function Rhf1() {
  const navigate = useNavigate();
  const [locationInput, setLocationInput] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [service, setService] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedlocValue, setSelectedlocValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");



  useEffect(() => {
    const animateCounters = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const counters = document.querySelectorAll('.timer');
          counters.forEach(counter => {
            const updateCount = () => {
              const target = +counter.getAttribute('data-target');
              const count = +counter.innerText;

              const speed = 200; 
              const increment = target / speed;

              if (count < target) {
                counter.innerText = Math.ceil(count + increment);
                setTimeout(updateCount, 30);
              } else {
                counter.innerText = target;
              }
            };

            updateCount();
          });
          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(animateCounters, {
      threshold: 0.5
    });

    const counterSection = document.querySelector('.counter-section');
    if (counterSection) {
      observer.observe(counterSection);
    }

    return () => {
      if (observer && observer.disconnect) {
        observer.disconnect();
      }
    };
  }, []);


  useEffect(() => {
    getFunction({}, "/rhf_service/get-service")
      .then((response) => {
        const responseData = response.success.result;
        setService(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const callSearchService = () => {
      if (locationInput) {
        getFunction({ place: locationInput }, "/locality/get-location-by-name")
          .then((response) => {
            const responseData = response.success.result;
            
            setLocationSuggestions(responseData);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setLocationSuggestions([]);
      }
    };

    let debouncer = setTimeout(() => {
      callSearchService();
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [locationInput]);

  const handleLocationSelect = (e) => {
    const selectedOption = locationSuggestions.find(
      (location) => location.id === e.target.value
    );

    if (selectedOption) {
      setSelectedLocation(selectedOption);
      setLocationInput(`${selectedOption.locality}, ${selectedOption.pincode}`);

      setLocationSuggestions([]);
    }
  };

  const handleLocationInputChange = (e) => {
    const value = e.target.value;
    setLocationInput(value);
    console.log("Location Input:", value);
  };

  const handleServiceChange = (event) => {
    const serviceValue = event.target.value;
    setSelectedValue(serviceValue);

    if (selectedLocation) {
      const locationValue = `${selectedLocation.locality}, ${selectedLocation.pincode}`;
      setSelectedlocValue(locationValue);

      const data = { loc: selectedLocation.pincode, serv: serviceValue };
      navigate("/RhfSearchResult", { state: data });
      console.log("Selected Service:", serviceValue);
      console.log("Selected Location:", locationValue);
      console.log("Pincode:", selectedLocation.pincode);
    } else {
      console.log("No location selected");
    }
  };

  return (
    <>
      <Header></Header>
      <br></br>
      <div class="container-fluid bg3">
        <br></br>
        <div class="rhfban">
          <br></br> <br></br>
          <center>
            <h2 class="text-white ">Welcome to Rapid Heal Force </h2>
          </center>
          <br></br>
          <center>
            <h5 class="text-white">
              India's Leading Healthcare Platform for Respiratory Care!
            </h5>
            <h5 class="text-white">FIND RHF SPECIALISTS NEAR YOU</h5>
          </center>
          <br></br>
          <div class="container">
            <div class="row no-gutters">
              <div class="col-sm-6 location">
                <div class="form-outline" data-mdb-input-init>
                  <input
                    type="text"
                    id="form1"
                    className="searche"
                    placeholder="Select Location"
                    value={locationInput}
                    onChange={handleLocationInputChange}
                  />
                  {locationSuggestions.length > 0 && (
                    <div className="suggestions-dropdown">
                      {locationSuggestions.map((item) => (
                        <button
                          key={item.id}
                          value={item.id}
                          onClick={handleLocationSelect}
                        >
                          {item.locality}, {item.districtname}, {item.state}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-outline" data-mdb-input-init>
                  <select
                    className="form-control findcontorl"
                    value={selectedValue}
                    onChange={handleServiceChange}
                  >
                    <option value="">Select Service</option>
                    {service.map((serv) => (
                      <option key={serv.id} value={serv.service_id}>
                        {serv.service_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <center>
              <p class="text-white">
                Popular Searches : RHF Pulmonologist | RHF Chest Physician | RHF
                Respiratory Therapist
              </p>
            </center>
            <br></br>

            <br></br>
          </div>
        </div>
        <br></br>
      </div>
      <br></br>
      <br></br>
      <div class="container">
        <div class="card rhfcard ">
          <div class="container">
            <div class="container rhfalll">
              <div class="row">
                <div class="col-sm-6 rhfimages">
                  {/* First card */}
                  <br></br>
                  <br></br>

                  <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/Rectangle 130.png"
                        class="rhfcardimg"
                        alt=""
                      />
                    </div>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/Rectangle 129.png"
                        class="rhfcardimg"
                        alt=""
                      />
                    </div>
                    <div class="col-sm-3"></div>
                    <div class="col-sm-2"></div>
                  </div>

                  {/* 2 card */}

                  <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/Rectangle 133.png"
                        class="rhfcardimg"
                        alt=""
                      />
                    </div>
                    <div class="col-sm-3">
                      <img
                        src="../style/img/resp/Rectangle 132.png"
                        class="rhfcardimg"
                        alt=""
                      />
                    </div>
                    <div class="col-sm-3"></div>
                    <div class="col-sm-2"></div>
                  </div>

                  {/* 3 card */}

                  <div class="row">
                    <h2 class="vertical-line"></h2>
                  </div>
                </div>

                <div class="col-sm-6">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="container ">
                    <h3 class="rhfsunhed text-white slide-right">Who is RHF?</h3>
                    <p class="text-light rhfhddes slide-right">
                      RHF is a dedicated nurse providing essential respiratory
                      care at home, focusing on improving respiratory health and
                      making a positive impact on people's lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rhfsebg">
        <div class="container">
          <br></br>
          <br></br>
          <center>
            <h2 class="rhfhed1">Services We Provide</h2>

            <p>
              Experience compassionate care and expert guidance tailored to your
              individual needs, fostering holistic wellness and empowering you
              to thrive.
            </p>
          </center>

          <div class="container">
            <div class="row">
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 136.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Emergency Care @ Home</p>
                    <p class="text-dark">
                      <b class="text-dark"> ₹500 - ₹5,000</b>
                    </p>
                    <center>
                      <Link to="/RHFemergencyCare">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 137.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Routine Medical Check-up</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFroutineMedCheckup">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 138.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Sleep Study</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFsleepStudy">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <br></br>

            <div class="row">
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 139.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Pulmonary Function Test</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFpulmonaryFunctionTest">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 140.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">COPD Patient Care</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFCOPDpatientCare">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 141.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Chest Physiotherapy</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFchestPhysiotherapy">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <div class="row">
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 142.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Pneumonia Pediatric Care</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFpneumoniaPediatricCare">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 143.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Doctor Consultation @ Home</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFdoctorConsultation">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card rhfcardser">
                  <img
                    class="card-img-top ghfg"
                    src="../style/img/resp/Rectangle 144.png"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <p class="card-text">Spirometry Test</p>
                    <p class="text-dark">
                      <b class="text-dark">₹999/-</b>
                    </p>
                    <center>
                      <Link to="/RHFspirometryTest">
                        <button class="custom-btn1 btn-3">
                          <span>Learn More</span>
                        </button>
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="rhflearnmbutton">
            <Link to="/Rhfservicepage">
              <button
                style={{ borderRadius: "25px", marginLeft: "350px" }}
                class="rhbservbtn"
              >
                {" "}
                All Services&nbsp;{" "}
                <FaArrowRightLong style={{ color: "text-dark" }} />
              </button>
            </Link>
        </div>
      </div>
      <br></br>
      <div class="container">
        <center>
          {" "}
          <h2 class="rhfhed1">How it Works ?</h2>
        </center>{" "}
        <br></br>
        <div class="row">
          <div class="col-sm-4 mb-3">
            <div class="card rhfycard">
              <div class="container">
                <center>
                  <div class="card topcard">
                    <center>
                      {" "}
                      <img
                        src="../style/img/resp/search.png"
                        class="topdimg"
                      ></img>
                    </center>
                  </div>
                </center>
                <br></br>
                <center>
                  <h4 class="ycardh">Search for Services</h4>
                </center>
                <br></br>

                <p class="ycardp">
                  Visit the Respiro Health website and go to the "Respiratory
                  Health Force" page. Use our search tool to find RHF
                  services or packages available near you. Browse and select the
                  package that fits your needs.
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3">
            <div class="card rhfycard">
              <div class="container">
                <center>
                  <div class="card topcard">
                    <center>
                      {" "}
                      <img
                        src="../style/img/resp/calendar.png"
                        class="topdimg"
                      ></img>
                    </center>
                  </div>
                </center>
                <br></br>
                <center>
                  <h4 class="ycardh">Book Your Service</h4>
                </center>
                <br></br>
                <p class="ycardp">
                  Click "Book Now" to schedule an appointment. Choose between
                  immediate services or schedule for a later date. Receive
                  confirmation via email or SMS, including details of the
                  assigned RHF team.
                  <br></br>
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3">
            <div class="card rhfycard">
              <div class="container">
                <center>
                  <div class="card topcard">
                    <center>
                      {" "}
                      <img
                        src="../style/img/resp/health-shield 1.png"
                        class="topdimg"
                      ></img>
                    </center>
                  </div>
                </center>
                <br></br>
                <center>
                  <h4 class="ycardh">Prepare for Your Visit</h4>
                </center>
                <br></br>

                <p class="ycardp">
                  A certified RHF team, led by a senior head nurse, will be
                  assigned to your case. They prepare the necessary medical
                  supplies and equipment for your care.
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div class="row">
          <div class="col-sm-4 mb-3">
            <div class="card rhfycard">
              <div class="container">
                <center>
                  <div class="card topcard">
                    <center>
                      {" "}
                      <img
                        src="../style/img/resp/nursing 1.png"
                        class="topdimg"
                      ></img>
                    </center>
                  </div>
                </center>

                <center>
                  <h4 class="ycardh">
                    Initial Home Visit and Periodical Vists at Home
                  </h4>
                </center>
                <br></br>
                <p class="ycardp">
                  The RHF team arrives at your home as scheduled. They set up
                  equipment and provide an initial assessment and care.
                  Periodical visits ensure ongoing support and monitoring.
                </p>
                <br></br>
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3">
            <div class="card rhfycard">
              <div class="container">
                <center>
                  <div class="card topcard">
                    <center>
                      {" "}
                      <img
                        src="../style/img/resp/loop 1.png"
                        class="topdimg"
                      ></img>
                    </center>
                  </div>
                </center>
                <br></br>
                <center>
                  <h4 class="ycardh">Ongoing Care and Monitoring</h4>
                </center>
                <br></br>
                <p class="ycardp">
                  The senior nurse monitors progress and adjusts the care plan
                  as needed. Follow-ups and online access to care plans, test
                  results, and team communication ensure comprehensive care.
                </p>
                <br></br>
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-3">
            <div class="card rhfycard">
              <div class="container">
                <center>
                  <div class="card topcard">
                    <center>
                      {" "}
                      <img
                        src="../style/img/resp/dashboard 1.png"
                        class="topdimg"
                      ></img>
                    </center>
                  </div>
                </center>
                <br></br>
                <center>
                  <h4 class="ycardh">Follow-Up and Results</h4>
                </center>
                <br></br>
                <p class="ycardp">
                  View test results online and schedule a follow-up consultation
                  to discuss next steps. The RHF team offers continuous support,
                  monitoring, and adjustments to ensure optimal care outcomes.
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //2cards// */}
      <br></br> <br></br>
      <center>
        <h3>Technical Support</h3>
      </center>
      <div class="container">
        <table class="table-fill tbl">
          <thead></thead>
          <tbody class="">
            <tr>
              <td class="text-left tbg">
                <img
                  src="./style/img/resp/Ellipse 4-2.png"
                  class="circle"
                ></img>
                <h2 class="tabletext text-dark">Experienced Professionals</h2>
              </td>

              <td class="text-left tbg1 rhftb2">
                <img
                  src="./style/img/resp/Ellipse 41.png"
                  class="circle tbimg"
                ></img>
                <h2 class="tabletext1 text-dark">
                  Personalized Treatment Plans
                </h2>
              </td>
            </tr>

            <tr>
              <td class="text-left tbg">
                <img
                  src="./style/img/resp/Ellipse 4-1.png"
                  class="circle"
                ></img>
                <h2 class="tabletext text-dark">Accessible Healthcare</h2>
              </td>

              <td class="text-left tbg1">
                <img
                  src="./style/img/resp/Ellipse 51.png"
                  class="circle tbimg"
                ></img>
                <h2 class="tabletext1 text-dark">Easy Booking Process</h2>
              </td>
            </tr>
            <tr>
              <td class="text-left tbg">
                <img src="./style/img/resp/Ellipse 7.png" class="circle"></img>
                <h2 class="tabletext text-dark">
                  Advanced Pulmonary emergency care
                </h2>
              </td>

              <td class="text-left tbg1">
                <img
                  src="./style/img/resp/Ellipse 9.png"
                  class="circle tbimg"
                ></img>
                <h2 class="tabletext1 text-dark">24/7 Support</h2>
              </td>
            </tr>
            <tr>
              <td class="text-left tbg">
                <img src="./style/img/resp/Ellipse 6.png" class="circle"></img>
                <h2 class="tabletext text-dark">convenient locations</h2>
              </td>

              <td class="text-left tbg1">
                <img
                  src="./style/img/resp/Ellipse 8.png"
                  class="circle tbimg"
                ></img>
                <h2 class="tabletext1 text-dark">
                  consistent online monitoring
                </h2>
              </td>
            </tr>
          </tbody>
        </table>

        <img
          src="./style/img/resp/Rectangle 165.png"
          class="tablenursealone"
        ></img>
      </div>
      <br></br>
      <br></br>
      <div class=" bg3">
        <div class="rhfsebg">
          <div class="container">
            <br></br>
            <h3>
              From Our Home to Yours: RHF Nurses Providing Quality Respiratory
              Caret
            </h3>
            <br></br> <br></br>
            <div class="row no-gutter">
              <div class="col-sm-4">
                <div class="card rhfbcard">
                  <div class="container">
                    <br></br> <br></br>
                    <h4 class="rhfbcardh">
                      {" "}
                      At RHF, our dedicated team of respiratory nurses is
                      committed to making a difference in your family's
                      respiratory health journey. With personalized care plans
                      tailored to each individual, our nurses provide
                      comprehensive respiratory care right in the comfort of
                      your home.
                    </h4>
                    <br></br> <br></br>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <img
                  src="./style/img/resp/Rectangle 218.png"
                  class="rhfbcardim rhfimgn"
                ></img>
              </div>
              <div class="col-sm-4">
                <div class="card rhfbcard">
                  <div class="container">
                    <br></br> <br></br>
                    <h4 class="rhfbcardh">
                      {" "}
                      With RHF, enjoy peace of mind knowing that your family's
                      respiratory health is our top priority. Trust our nurses
                      to promote better health within your community and make a
                      significant impact on the well-being of your loved ones.
                    </h4>
                    <br></br> <br></br> <br></br>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutter rhfbtop ">
              <div class="col-sm-4">
                <img
                  src="./style/img/resp/Rectangle 220.png"
                  class="rhfbcardim rhfimgn"
                ></img>
              </div>
              <div class="col-sm-4">
                <div class="card rhfbcard">
                  <div class="container">
                    <br></br> <br></br>
                    <h4 class="rhfbcardh">
                      {" "}
                      From managing chronic conditions to ensuring the
                      well-being of your loved ones, our nurses go above and
                      beyond to address respiratory health disparities. Whether
                      it's caring for children or the elderly, rest assured that
                      your family members are in capable hands, even when you're
                      away.
                    </h4>
                    <br></br> <br></br>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <img
                  src="./style/img/resp/Rectangle 222.png"
                  class="rhfbcardiml rhfimgn"
                ></img>
              </div>
            </div>
            <br></br> <br></br> <br></br> <br></br>
            <div class="rhfbanner">
              <div class="container">
                <div class="row">
                  <div class="col-sm-6">
                    <img
                      src="./style/img/resp/india-image.png"
                      class="rhfbimg"
                    ></img>
                  </div>

                  <div class="col-sm-6">
                    <center>
                      <h3
                        style={{ marginTop: "-60px", fontSize: "40px" }}
                        class="rhfbannerh"
                      >
                        {" "}
                        Our Network
                      </h3>
                    </center>
                    <br></br>
                    <p style={{ marginBottom: "50px" }}>
                      At RHF, we are committed to revolutionizing respiratory
                      healthcare by providing comprehensive and affordable lung
                      care for all. With a network of over 25,000 esteemed
                      Therapists, including 7,000 Pulmonologists, 3,000
                      Pediatric pulmonologist, 1 Lakh Rapid Heal Force (RHF),
                      and a team of healthcare experts, we strive to serve
                      individuals with excellence and compassion across India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <div class="container">
              <h3 class="home_text">
                <font color="204ea0">Welcome to the Heart of Care!</font>
              </h3>
              <p>Join Us in Transforming Lives at the Rapid Heal Force</p>

              <div class="col-sm-6">
                <Link to="/rhf/landing">
                  <img
                    className="home2bg banrhf2"
                    src="./style/img/banner/Group 301.jpg"
                    alt="First slide"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="counter" className="counter-section content-section">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <h2 className="white">Our Strength</h2>
            </div>

             <div className="col-sm-3 counter-wrap">
              <strong>
                <span className="timer" data-target="100000">0</span>+
              </strong>
              <span className="count-description">Rhf Nurses</span>
            </div>

            <div className="col-sm-3 counter-wrap">
              <strong>
                <span className="timer" data-target="3000">0</span>+
              </strong>
              <span className="count-description">Pediatric pulmonologist</span>
            </div>

            <div className="col-sm-3 counter-wrap">
              <strong>
                <span className="timer" data-target="7000">0</span>+
              </strong>
              <span className="count-description">Pulmonologists</span>
            </div>

            <div className="col-sm-3 counter-wrap">
              <strong>
                <span className="timer" data-target="25000">0</span>+
              </strong>
              <span className="count-description">Rhf Units</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Rhf1;
