import { useRoutes } from "react-router-dom";
import Musiciancareplan from "../pages/advancedLungcheckupplan/Musiciancareplan";
import Teachercareplan from "../pages/advancedLungcheckupplan/TeacherCareplan";
import Artistcareplan from "../pages/advancedLungcheckupplan/Artistcareplan";
import WorkerLunghealth from "../pages/advancedLungcheckupplan/WorkerLunghealth";

const AdvancedLungcheckupplanRoutes = () => {
  let routes = useRoutes([
    { path: "/TeacherCareplan",  element: <Teachercareplan /> },
    { path: "/Musiciancareplan", element: <Musiciancareplan /> },
    { path: "/Artistcareplan",   element: <Artistcareplan /> },
    { path: "/WorkerLunghealth", element: <WorkerLunghealth /> },

  ]);
  return routes;
};

export default AdvancedLungcheckupplanRoutes;
