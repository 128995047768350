import { useRoutes } from "react-router-dom";
import Childpackage from "../pages/Packagesummary/Childpackage";
import Elderpackage from "../pages/Packagesummary/Elderpackage";
import Familypackage from "../pages/Packagesummary/Familypackage";
import Youngsterpackage from "../pages/Packagesummary/Youngsterpackage";
const PackagesummaryRoutes = () => {
    let routes = useRoutes([
      { path: "/Packagesummary/Childpackage", element: <Childpackage /> },
      { path: "/Packagesummary/Elderpackage", element: <Elderpackage/>},
      { path: "/Packagesummary/Familypackage", element: <Familypackage/>},
      { path: "/Packagesummary/Youngsterpackage", element: <Youngsterpackage/>},


  ]);
  return routes;
};

export default PackagesummaryRoutes;