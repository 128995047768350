import { useState, useContext, useEffect } from "react";
import RhfHeader from "../header/RhfHeader";
import { AuthContext } from "../../context/authContext";
// import withProviderAuth from "../../middleware/withProviderAuth";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import RhfMember from "./RhfMember";
import RhfAddress from "./RhfAddress";
import RhfLocality from "./RhfLocality";
import RhfUnit from "./RhfUnit";
import RhfServices from "./RhfServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareH,
  faLocationDot,
  faAddressBook,
  faPeopleGroup,
  faUserDoctor,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from "react-burger-menu"; // Importing burger menu
import RhfPayment from "./RhfPayment";

const RhfProfile = () => {
  const { rhfId, rhfEmail, rhfPrivilege, isMobile } = useContext(AuthContext);

  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);

  const [menuActive, setMenuActive] = useState(false);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const [showInfo, setShowInfo] = useState(false);
  const [showRhfLocality, setShowRhfLocality] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showRhfUnit, setShowRhfUnit] = useState(true);
  const [showServices, setShowServices] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMenuClick = (section) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowInfo(section === "info");
      setShowRhfLocality(section === "locality");
      setShowAddress(section === "address");
      setShowRhfUnit(section === "unit");
      setShowServices(section === "service");
      setShowPayment(section === "payment");
    }, 500); // Simulate loading time
  };

  const desktopMenu = () => (
    <div className="side-menu bg2 prowidth">
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("unit")}
        style={showRhfUnit ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faSquareH} style={{ fontSize: "25px" }} />
        &nbsp;RHF Unit
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("address")}
        style={showAddress ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faAddressBook} style={{ fontSize: "25px" }} />
        &nbsp;Unit Address
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("locality")}
        style={showRhfLocality ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: "25px" }} />
        &nbsp;Service Locations
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("info")}
        style={showInfo ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: "25px" }} />
        &nbsp;RHF Member
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("service")}
        style={showServices ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faUserDoctor} style={{ fontSize: "25px" }} />
        &nbsp;Rhf Services
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("payment")}
        style={showPayment ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: "25px" }} />
        &nbsp;Make Initial Payment
      </h3>
    </div>
  );

  const mobileMenu = () => (
    <Menu
      className="d-block d-md-none"
      pageWrapId={"page-wrap"}
      outerContainerId={"root"}
    >
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("unit")}
        style={showRhfUnit ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faSquareH} style={{ fontSize: "25px" }} />
        &nbsp;RHF Unit
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("address")}
        style={showAddress ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faAddressBook} style={{ fontSize: "25px" }} />
        &nbsp;Unit Address
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("locality")}
        style={showRhfLocality ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: "25px" }} />
        &nbsp;Service Locations
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("info")}
        style={showInfo ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: "25px" }} />
        &nbsp;RHF Member
      </h3>
      <h3
        className="side-menu-item "
        onClick={() => handleMenuClick("service")}
        style={showServices ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faUserDoctor} style={{ fontSize: "25px" }} />
        &nbsp;Rhf Services
      </h3>
    </Menu>
  );

  return (
    <div id="page-wrap">
      <RhfHeader />

      <div className="d-flex protop">
        {rhfPrivilege && (isMobile ? mobileMenu() : desktopMenu())}
        <div className="container profile-container">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="propporifile">
                <div className="container-fluid">
                  <br></br>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="">
                        <img
                          src="../style/img/resp/drprofile.jpg"
                          alt="Profile"
                          className="drprofile"
                        ></img>
                        <img
                          src="../style/img/resp/green.png"
                          alt="Profile"
                          className="liveicon"
                        ></img>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <p>
                        <div className="pgreen">&nbsp; live</div> Your Profile
                        is live on Respirolab.com
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                </div>
              </div>

              <br></br>
              <br></br>
              {rhfPrivilege && showInfo && <RhfMember />}
              {rhfPrivilege && showRhfLocality && <RhfLocality />}
              {rhfPrivilege && showAddress && <RhfAddress />}
              {rhfPrivilege && showRhfUnit && <RhfUnit />}
              {rhfPrivilege && showServices && <RhfServices />}
              {rhfPrivilege && showPayment && <RhfPayment />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingSpinner = () => (
  <>
    <br></br> <br></br> <br></br>
  </>
);

export default RhfProfile;
