import React, { useEffect, useState } from "react";
import AdminHeader from '../header/AdminHeader'
import { useParams } from 'react-router-dom';
import { getFunction } from "../../api/apiFunctions";

function ViewProviderDetails() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [docdata, setDocdata] = useState([]);
  const [groupedDocuments, setGroupedDocuments] = useState({});
  const [expandedDocumentTypes, setExpandedDocumentTypes] = useState({});

  const providerId = id; 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getFunction({ id }, "/admin/get-provider")
      .then((response) => {
        const responseData = response.success.result[0];
        setData(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const groupDocumentsByType = (documents) => {
    return documents.reduce((acc, doc) => {
      if (!acc[doc.documentType]) {
        acc[doc.documentType] = [];
      }
      acc[doc.documentType].push(doc);
      return acc;
    }, {});
  };

  useEffect(() => {
    getFunction({ providerId }, "/admin/get-allproviderdocuments")
      .then((response) => {
        const responseData = response.success.result;
        setDocdata(responseData);
        setGroupedDocuments(groupDocumentsByType(responseData));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [providerId]);

  const toggleDocumentVisibility = (documentType) => {
    setExpandedDocumentTypes(prevState => ({
      ...prevState,
      [documentType]: !prevState[documentType]
    }));
  };

  return (
    <>
      <AdminHeader /><br />
      <div className="pbgm1">
        <div className="container">
          <div className="card">
            <br />
            <div className="container">
              <center><h2 className="paihed">Provider Information</h2></center><br />
              <div className="row">
                <div className="col-sm-6">
                  <p className="plaintext">Name </p>
                  <h2 className="paihed2">{data.first_name}&nbsp;{data.last_name}</h2><br />
                  <p className="plaintext">Phone </p>
                  <h2 className="paihed2">{data.phone} </h2><br />
                  <p className="plaintext">Year of experience </p>
                  <h2 className="paihed2">{data.years_experience} </h2><br />
                  <p className="plaintext">Repeat consult fee </p>
                  <h2 className="paihed2">{data.repeat_consult_fee} </h2>
                </div>
                <div className="col-sm-6">
                  <p className="plaintext">Provider Type </p>
                  <h2 className="paihed2">{data.provider_type}</h2><br />
                  <p className="plaintext">Email </p>
                  <h2 className="paihed2">{data.email} </h2><br />
                  <p className="plaintext">Initial consult fee </p>
                  <h2 className="paihed2">{data.init_consult_fee} </h2><br />
                  <p className="plaintext">Registration Number </p>
                  <h2 className="paihed2">{data.registration_number} </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card">
            <br />
            <div className="container">
              <center><h2 className="paihed">Documents Uploaded by the Provider</h2></center><br />
              <div className="row">
                <div className="col-sm-12">
                  {Object.keys(groupedDocuments).length > 0 ? (
                    Object.keys(groupedDocuments).map((documentType, index) => (
                      <div key={index}>
                        <p>
                          Document Type : {documentType} &nbsp;&nbsp;&nbsp;
                          <a
                            href="#!"
                            style={{ color: "#127bed" }}
                            onClick={() => toggleDocumentVisibility(documentType)}
                          >
                            click here 
                          </a>
                        </p>
                        {expandedDocumentTypes[documentType] && (
                          groupedDocuments[documentType].map((item, docIndex) => (
                            <div key={docIndex}>
                              <a href={item.url} target="_blank" rel="noopener noreferrer" style={{color:"#2024e5",fontSize:"12px"}}>
                                <li style={{ color: "#287ae5" }}>&nbsp;{item.document_name}</li>
                              </a>
                            </div>
                          ))
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No documents uploaded.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
}

export default ViewProviderDetails;
