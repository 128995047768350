import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
function FamilyLungcareplan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header></Header>
      <div class="bg2">
        <div class="bg3">
          <br></br>
          <br></br>
          <img src="../style/img/resp/familybnr.png" class="childbnr"></img>
          <br></br>
          <div class="container">
            <div class="row">
              <div class="col-sm-7">
                <h2 class="pop familyhead">
                  <b class="text-dark">
                    Respiro - Family <br></br>Lung Health <br></br>Care Plan
                  </b>
                </h2>
                <p class="pop just drychild">
                  Welcome to Respiro, Your Family's Lung Health Care <br></br>
                  Partner.<br></br>
                  Ensuring 100% Lung Health for Families in India.
                </p>
                <div class="col-sm-6 left">
                  <button class=" fprice custom-btn1 btn-3">
                    <span class="btntexty">Book Now</span>
                  </button>
                </div>
                <a href="">
                  <div class="col-sm-6 right text-dark details">
                    For more details <FaLongArrowAltRight class="text-dark"/>
                  </div>
                </a>
              </div>
              <div class="col-sm-5">
                <img
                  src="../style/img/resp/familybnr2.png"
                  class="childbnr2"
                ></img>
              </div>
            </div>
          </div>
          
          <br></br>
        </div>
        <br></br>
        <div class="container">
          <center>
            <img src="../style/img/resp/familybnr3.png" class="yngimg3"></img>
          </center>
          <div class="container">
            <div class="row">
              <div class="col-sm-5">
                <img
                  src="../style/img/resp/familypic1.png"
                  class="yngpic1"
                ></img>
              </div>
              <div class="col-sm-7">
                <center>
                  <h3 class="famhead famh">
                    <b class="text-dark famh">Breathe</b> Together,<br></br>
                    Thrive <b class="text-dark famh">Together</b>
                  </h3>
                </center>
               
                <div class="container">
                  <center>
                    <p class="ylgpara">
                      At Respiro, we believe in the power of family and the
                      importance of collective well-being. Our Family Lung
                      Health Care Plan is designed to provide comprehensive lung
                      care for every member of your family, fostering a culture
                      of respiratory wellness within your family unit. Together,
                      let's breathe easy and thrive.
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="signature-background88">
        <br></br>
        <center>
          <h3>Why Choose Respiro for Your Family's Lung Health?</h3>
        </center>
        <br></br>
        {/* //1st row start// */}
        <div class="container">
          <center>
          <div class="row">
            <center>
              <div class="col-sm-6 famlongbox">
                <div class="blank1 blank390_1">
                  <br></br>

                  <center>
                    <h6>
                      <b class="text-dark iconh">
                        COMPREHENSIVE CARE FOR EVERY GENERATION
                      </b>
                    </h6>
                  </center>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-8">
                        <p class="just iconp fampr">
                          Our Family Lung Health Care Plan caters to the unique
                          respiratory needs of every member of your family, from
                          the youngest to the oldest.
                        </p>
                      </div>
                      <div class="col-sm-4">
                        <img
                          src="../style/img/resp/famicon1.png"
                          class="fdicon famdicon"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br><br></br>
              </div>
            </center>

            <div class="col-sm-6 famlongbox2">
              <div class="blank1 blank390_2">
                <br></br>
                <center>
                  <h6>
                    <b class="text-dark iconh">
                      HOLISTIC APPROACH TO LUNG HEALTH
                    </b>
                  </h6>
                </center>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-8">
                      <p class="just iconp fampr">
                        Beyond check-ups and consultations, Respiro offers
                        holistic solutions including lifestyle guidance, stress
                        management techniques, and community support to ensure
                        your family's lungs stay strong and healthy.
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <img
                        src="../style/img/resp/famicon2.png"
                        class="fdicon famdicon"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></center>
          <br></br>
        </div>
        {/* //1st row end// */}

        {/* //2nd row start// */}
        <div class="container">
          <center>
          <div class="row">
            <center>
	
              <div class="col-sm-6 famlongbox">
                <div class="blank1 blank390_1">
                  <br></br>

                  <center>
                    <h6>
                      <b class="text-dark iconh">
                        {" "}
                        PERSONALISED FAMILY DASHBOARD
                      </b>
                    </h6>
                  </center>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-8">
                        <p class="just iconp fampr">
                          Keep track of your family's lung health journey with
                          our intuitive online dashboard, where you can access
                          health records, schedule appointments, and receive
                          personalized recommendations for each family member.
                        </p>
                      </div>
                      <div class="col-sm-4">
                        <img
                          src="../style/img/resp/famicon3.png"
                          class="fdicon famdicon"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br><br></br>
              </div>
            </center>

            <div class="col-sm-6 famlongbox2">
              <div class="blank1 blank390_2">
                <br></br>
                <center>
                  <h6>
                    <b class="text-dark iconh">COMMUNITY ENGAGEMENT</b>
                  </h6>
                </center>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-8">
                      <p class="just iconp fampr">
                        Join our vibrant community of families committed to lung
                        health. Share experiences, tips, and encouragement with
                        other Respiro families, and together, inspire and
                        support each other on your wellness journey.
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <img
                        src="../style/img/resp/famicon4.png"
                        class="fdicon famdicon"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </center>
          <br></br>
        </div>
        {/* //2nd row end// */}

        {/* //3rd row start// */}
        <div class="container">
          <center>
          <div class="row">
            <center>
              <div class="col-sm-6 famlongbox">
                <div class="blank1 blank390_1">
                  <br></br>

                  <center>
                    <h6>
                      <b class="text-dark iconh">
                        EXCLUSIVE FAMILY EVENTS AND WORKSHOP
                      </b>
                    </h6>
                  </center>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-8">
                        <p class="just iconp fampr">
                          Enjoy access to exclusive events and workshops
                          designed to promote lung health awareness and
                          education within your family, including fun activities
                          for all ages.
                        </p>
                      </div>
                      <div class="col-sm-4">
                        <img
                          src="../style/img/resp/famicon5.png"
                          class="fdicon famdicon"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br><br></br>
              </div>
            </center>

            <div class="col-sm-6 famlongbox2">
              <div class="blank1 blank390_2">
                <br></br>
                <center>
                  <h6>
                    <b class="text-dark iconh">
                      CONVENIENT CHECH-UPS AND CONSULTATIONS
                    </b>
                  </h6>
                </center>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-8">
                      <p class="just iconp fampr">
                        Get 5 free lung health check-ups for each family member
                        upon enrollment. Enjoy a 50% discount on all subsequent
                        check-ups. Access our experienced pulmonologists anytime
                        for any lung-related concerns or emergencies.
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <img
                        src="../style/img/resp/famicon6.png"
                        class="fdicon famdicon"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          </center>
          <br></br>
        </div>
        {/* //3rd row end// */}

        {/* //4th row start// */}
        <div class="container">
          <center>
          <div class="row">
            <center>
              <div class="col-sm-6 famlongbox">
                <div class="blank1 blank390_1">
                  <br></br>

                  <center>
                    <h6>
                      <b class="text-dark iconh">SPECIAL HOUSEHOLD DISCOUNTS</b>
                    </h6>
                  </center>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-8">
                        <p class="just iconp fampr">
                          Sign up and get a 20% to 50% discount for your entire
                          household.
                        </p>
                      </div>
                      <div class="col-sm-4">
                        <img
                          src="../style/img/resp/famicon7.png"
                          class="fdicon famexcept"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br><br></br>
              </div>
            </center>

            <div class="col-sm-6 famlongbox2">
              <div class="blank1 blank390_2">
                <br></br>
                <center>
                  <h6>
                    <b class="text-dark iconh">FREE INITIAL CONSULTATIONS</b>
                  </h6>
                </center>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-8">
                      <p class="just iconp fampr">
                        Avail the first 5 lung health consultations for free
                        upon enrollment for each family member.
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <img
                        src="../style/img/resp/famicon8.png"
                        class="fdicon famexcept"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></center>
          <br></br>
        </div>
        {/* //4th row end// */}

        {/* //5th row start// */}
        <div class="container">
          <center>
          <div class="row">
            <center>
              <div class="col-sm-6 famlongbox">
                <div class="blank1 blank390_1">
                  <br></br>

                  <center>
                    <h6>
                      <b class="text-dark iconh">REFERRAL BENEFITS</b>
                    </h6>
                  </center>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-8">
                        <p class="just iconp fampr">
                          Refer another family and receive additional benefits
                          or discounts. Encourage your friends and their
                          families to join Respiro and enjoy extra benefits or
                          discounts for your family.
                        </p>
                      </div>
                      <div class="col-sm-4">
                        <img
                          src="../style/img/resp/famicon9.png"
                          class="fdicon famdicon"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br><br></br>
              </div>
            </center>

            <div class="col-sm-6 famlongbox2">
              <div class="blank1 blank390_2">
                <br></br>
                <center>
                  <h6>
                    <b class="text-dark iconh">ADDITIONAL SERVICES</b>
                  </h6>
                </center>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-8">
                      <p class="just iconp fampr">
                        Avail discounts on services such as respiratory therapy,
                        sleep studies, and pulmonary function tests for every
                        member of your family.
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <img
                        src="../style/img/resp/famicon10.png"
                        class="fdicon famdicon"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></center>
          <br></br>
        </div>
        {/* //5th row end// */}
      </div>

      <div class="bggradient2">
        <br></br>
        <center>
          <h5>
            <b class="text-white childf">How to Get Started ?</b>
          </h5>
        </center>
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col-sm-3 famhowto">
              <div class="blank2 fambx">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">Family Enrollment</b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/iconfam1.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                    Sign up your entire family for Respiro's Family Lung Health
                    Care Plan.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3 famhowto">
              <div class="blank2 fambx">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">
                        Personalized Family Assessment
                      </b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/iconfam2.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                    Complete a comprehensive family assessment to identify
                    individual needs and preferences.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3 famhowto">
              <div class="blank2 fambx">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">
                        Create Your Family Wellness Plan
                      </b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/iconfam3.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                    Work with our experts to create a personalized wellness plan
                    tailored to your family's specific goals and challenges.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            <div class="col-sm-3 famhowto">
              <div class="blank2 fambx">
                <div class="container">
                  <br></br>
                  <center>
                    <h5>
                      <b class="text-dark childf">
                        Join the Respiro Family Community
                      </b>
                    </h5>
                  </center>
                  <br></br>
                  <center>
                    <img
                      src="../style/img/resp/iconfam4.png"
                      class="fdicon"
                    ></img>
                  </center>
                  <p class="gradf just">
                    Connect with other Respiro families, participate in events
                    and workshops, and embark on your lung health journey
                    together.
                  </p>
                </div>
              </div>
            </div>
            <br></br>
          </div>
          <br></br>
        </div>
        <br></br>
        <br></br>
      </div>

      <div class="bg3">
        <br></br>
        <div class="container">
          <div class="row">
            <div class="col-sm-7">
              <h4>
                <b class="brown">Join the Respiro Family Today</b>
              </h4>
              <br></br>
              <p class="just yadjust brown">
                Experience the power of collective wellness with Respiro. Sign
                up now and embark on a journey to ensure optimal lung health for
                your entire family. Together, let's breathe, thrive, and create
                a healthier future for generations to come.
              </p>
            </div>

            <div class="col-sm-5">
              <img src="../style/img/resp/fampic2.png" class="yngpic2"></img>
            </div>
          </div>
        </div>

        <center>
          {" "}
          <Link to ="/Packagesummary/Familypackage"> <button class=" fprice custom-btn1 btn-3 btn-33">
            <span class="btntexty">Book Now</span>
          </button></Link>
        </center>
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default FamilyLungcareplan;
