import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { MdOutlineLogin } from "react-icons/md";

function DoctorHeader() {
  const { rhfEmail } = useContext(AuthContext);
  const [menuActive, setMenuActive] = useState(false);
  const { rhfId } = useContext(AuthContext);

  function toggleMenu() {
    setMenuActive(!menuActive);
  }

  const menuBar = (
    <nav className="nav">
      <input type="checkbox" id="nav-check" />
      <div className="nav-header"></div>
      <div className="nav-btn providerheadernavbtn">
        <label htmlFor="nav-check">
          <span className="bgm1"></span>
          <span className="bgm1"></span>
          <span className="bgm1"></span>
        </label>
      </div>
      <ul className="nav-list mynavlist providerlist">
        <li>
          <Link className="nav-link ddd" to="/rhf/dashboard">
            <font color="#fff">Dashboard</font>
          </Link>
        </li>
        <li>
          <Link className="nav-link ddd" to="/rhf/profile">
            <font color="#fff">Profile</font>
          </Link>
        </li>
        <li>
          <a className="nav-link ddd" href="/rhf/report">
            <font color="#fff">Report</font>
          </a>
        </li>
        <li>
          <a className="nav-link ddd" href="/rhf/scheduling">
            <font color="#fff">Schedule</font>
          </a>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="logo providerlogo">
            <Link to="/Doctor/Landing">
              <img src="/../style/img/logonav.png" alt="Logo" />
            </Link>
          </div>

          <div className="push-left">
            <ul
              id="primary-menu"
              className={`menu nav-menu ${menuActive ? "menu-active" : ""}`}
            >
              <li className="topnavtext submenu dropdown">
                {!rhfEmail ? (
                  <Link
                    className="custom-btn btn-1 loginbtn loginbutton dropdown-toggle"
                    to="/rhf/login"
                  >
                    Login
                    <MdOutlineLogin class="text-white" />
                  </Link>
                ) : (
                  <>
                    <span className="topnavtext dropdown-toggle login-text">
                      {rhfEmail}
                    </span>

                    {/* <Link
        className="custom-btn btn-1 loginbtn loginbutton dropdown-toggle icon-button"
        to="/rhf/login"
      >
        <span className="icon">
          <MdOutlineLogin className="text-white" />
        </span>
        <span className="text">Login</span>
      </Link>
    ) : (
      <>
        <span className="topnavtext dropdown-toggle ">
          {rhfEmail}
        </span> */}

                    <ul className="dropdown-menu">
                      <li>
                        <Link className="nav-link" to="/rhf/profile">
                          <i className="fa-solid fa-user"></i> Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/rhf/logout"
                          onClick={() => {
                            localStorage.clear();
                          }}
                        >
                          <i className="fa-solid fa-user"></i> Logout
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {rhfId ? menuBar : null}
    </>
  );
}

export default DoctorHeader;
