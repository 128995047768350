import React from "react";

function Lungcancer() {
  return (
    <>
      <br></br> <br></br>
      <div class="service-area area-padding-top">
        <div class="container">
          <div class=""></div>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="card-service1 text-center text-lg-left mb-4 mb-lg-0">
                <h3 class="card-service__title">
                  <center>
                    <font color="#204ea0">
                      Imaging Test<br></br>₹399
                    </font>
                  </center>
                </h3>
                <p class="card-service__subtitle">
                  Imaging tests are commo n tools used by health care providers
                  to screen for medical problems, diagnose diseases and
                  conditions, monitor existing conditions, and assess whether
                  treatments are effective
                </p>
                <br></br>
                <center>
                  <a href="#" class="main_btn1">
                    Book Now
                  </a>
                </center>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="card-service1 text-center text-lg-left mb-4 mb-lg-0">
                <h3 class="card-service__title">
                  <center>
                    <font color="#204ea0">
                      Tissue Sample<br></br>₹299
                    </font>
                  </center>
                </h3>
                <p class="card-service__subtitle">
                  Tissue provides information that helps clinicians diagnose
                  cancer and make appropriate treatment decisions. Tissue
                  sampling refers to various procedures to obtain bodily fluids
                  or tissue for analysis
                </p>
                <br></br>
                <center>
                  <a href="#" class="main_btn1">
                    Book Now
                  </a>
                </center>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="card-service1 text-center text-lg-left mb-4 mb-lg-0">
                <h3 class="card-service__title">
                  <center>
                    <font color="#204ea0">
                      Sputum Cytology<br></br>₹299
                    </font>
                  </center>
                </h3>
                <p class="card-service__subtitle">
                  Sputum cytology examines a sample of sputum (mucus) under a
                  microscope to determine whether abnormal cells are present
                </p>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <center>
                  <a href="#" class="main_btn1">
                    Book Now
                  </a>
                </center>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="card-service1 text-center text-lg-left mb-4 mb-lg-0">
                <h3 class="card-service__title">
                  <center>
                    <font color="#204ea0">
                      Blood Test<br></br>₹299
                    </font>
                  </center>
                </h3>
                <p class="card-service__subtitle">
                  A blood test is a laboratory analysis performed on a blood
                  sample that is usually extracted from a vein in the arm using
                  a hypodermic needle
                </p>
                <br></br>
                <br></br>
                <br></br>
                <center>
                  <a href="#" class="main_btn1">
                    Book Now
                  </a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br> <br></br> <br></br>
    </>
  );
}

export default Lungcancer;
