import React, { useEffect, useState } from "react";
import { getFunction, updateFunction } from "../../api/apiFunctions";
import withAdminAuth from "../../middleware/withAdminAuth";
import { useNavigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

function ListallProviders() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]); // For filtered data
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Number of items per page
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    filterProviders();
  }, [searchTerm, data]);
  const fetchProviders = () => {
    getFunction({}, "/admin/get-pendingprovider")
      .then((response) => {
        const responseData = response.success.result;
        setData(responseData);
        console.log(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filterProviders = () => {
    const filtered = data.filter((q) =>
      q.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProviders(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const approveProvider = (id) => {
    //alert(`Button clicked for provider ID: ${id}`);
    updateFunction({ id, validatedFlg: "Y" }, "/admin/approve-provider")
      .then((response) => {
        if (response.success) {
          //alert(`Provider with ID: ${id} has been approved.`);
          toast.success("Provider has been approved successfully");

          setData(data.filter((item) => item.id !== id));
        } else {
          toast.error("An error occurred while approving provider");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProviders = filteredProviders.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredProviders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };
  const viewDetails = (id) => {
    navigate(`/admin/providerdetails/${id}`);
  };

  return (
    <>
      <ToastContainer />

      <div className="datatable">
        <h4 style={{ color: "#3131e5" }}>Pending Providers</h4>
        <div className="col-sm-8">
          <input
            type="text"
            placeholder="Search providers"
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control mb-3"
          />
          <div style={{ overflowX: "scroll" }}>
            <table className="table">
              <thead>
                <tr>
                  <th className="th-sm">S.No</th>
                  <th className="th-sm">Name</th>
                  {/* <th className="th-sm">Phone</th>
              <th className="th-sm">Email</th> */}
                  <th className="th-sm">Yr. Exp</th>
                  <th className="th-sm">Fee</th>
                  <th className="th-sm">Type</th>
                  <th className="th-sm">Reg No</th>
                  {/* <th className="th-sm">Status</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentProviders.length === 0 ? (
                  <div className="card__detail">
                    <div className="card__source text-bold">
                      No pending providers
                    </div>
                  </div>
                ) : (
                  currentProviders &&
                  currentProviders.map((item, index) => (
                    <tr key={item.id}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>
                        {item.first_name}&nbsp;&nbsp;{item.last_name}
                      </td>
                      {/* <td>{item.phone}</td>
                <td>{item.email}</td> */}
                      <td>{item.years_experience}</td>
                      <td>{item.init_consult_fee}</td>
                      <td>{item.provider_type}</td>
                      <td>{item.registration_number}</td>
                      {/* <td>
                  <p style={{ color: "red" }}>Pending</p>
                </td> */}
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{ all: "initial", fontWeight: "800" }}
                          >
                            <FontAwesomeIcon
                              icon={faGear}
                              style={{ fontSize: "15px" }}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => approveProvider(item.id)}
                            >
                              Approve
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => viewDetails(item.id)}>
                              Details
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <br />
          <Pagination>{renderPaginationItems()}</Pagination>
        </div>
      </div>
    </>
  );
}
export default withAdminAuth(ListallProviders);
