import Header from "../header/Header";
export default function Plus() {
    return (
        <>
        <Header></Header>
        <div class="container-fluid teambg">
            <div class="container" >
                {/* <div class="container usehead" >
                    <span class="dot1"></span>
                    <span class="dot2"></span>
                    <span class="dot3"></span>
                    </div> */}
                <b class="text-dark">
                    <h3 class=" termshead ">Respiro Plus</h3>
                </b>
                <br></br>

                <br></br>
                <p class=" termsp1 privj">
                Experience the Pinnacle of Healthcare with Respiro Plus
              Elevate your health and well-being with Respiro Plus, our exclusive premium plan designed to offer a superior healthcare experience. 
              Tailored for those seeking comprehensive, personalized care and unparalleled benefits, Respiro Plus provides access to a range of 
              high-quality services, advanced medical solutions, and premium offers.

                </p>
                <br></br>

                <h5 style={{marginLeft:"-20px"}}>
                    <b class="text-dark"> What is Respiro Plus?</b>
                </h5>
                <p class=" termsp1 privj">
                Respiro Plus is a premium membership program that delivers an all-inclusive healthcare experience. This elite plan combines cutting-edge medical services, tailored wellness programs, and exclusive perks to ensure you receive the best care available. Designed for individuals who demand the highest level of service and proactive health management, Respiro Plus is your gateway to advanced, personalized care.
                Premium Features of Respiro Plus
                </p>
                <h5 style={{marginLeft:"-20px"}}>
                    <b class="text-dark"> Why choose Respiro Plus?</b>
                </h5>
                <p class=" termsp1 privj">
                Respiro Plus is more than just a healthcare plan—it's a premium experience designed for those who seek exceptional care, exclusive services, and a proactive approach to health. With Respiro Plus, you receive the highest standard of medical and wellness services, ensuring you live your healthiest and most fulfilling life.
                </p>
                <h5 style={{marginLeft:"-20px"}}>
                    <b class="text-dark"> Premium Features of Respiro Plus</b>
                </h5><br></br>
                <h6 class="privacyhead">
                    <b class="text-dark">Comprehensive Health Assessments</b>
                </h6>
                <p class=" termsp1 privj">
                ● Exclusive Health Evaluations: Benefit from thorough and detailed health assessments, including advanced diagnostic tests, comprehensive respiratory evaluations, and personalized health reports.<br></br>
                ● Ongoing Health Monitoring: Enjoy continuous monitoring of your health metrics with regular follow-ups to optimize your care plan and track progress.

                </p>


                <br></br>
                <h6 class="privacyhead">
                    <b class="text-dark"> Personalized Care Plans</b>
                </h6>
                <p class=" termsp1 privj">
                ● Tailored Health Solutions: Receive a customized care plan designed specifically for your health needs and goals, incorporating the latest in medical technology and treatments.<br></br>
                ● Dedicated Expert Team: Access a team of top-tier healthcare professionals, including renowned doctors, specialized therapists, and wellness coaches, who are dedicated to your well-being. 
                </p>

                <h6 class="privacyhead">
                    <b class="text-dark">  Priority Access and Advanced Services</b>
                </h6>
                <p class=" termsp1 privj">
                ● Priority Scheduling: Experience expedited access to consultations, treatments, and specialist services with priority scheduling.<br></br>
                ● Cutting-Edge Treatments: Take advantage of the latest advancements in medical technology and treatment options that are exclusively available to Respiro Plus members.
                </p>
                <h6 class="privacyhead">
                    <b class="text-dark">Enhanced Support and Convenience</b>
                </h6>
                <p class=" termsp1 privj">
                ● 24/7 Support Line: Enjoy round-the-clock support from our dedicated team for any health-related inquiries or needs.<br></br>
                ● Premium Home Care Services: Receive prioritized access to our RHF (Rapid Heal Force) home care services, including in-home consultations, advanced therapy, and personalized care.
                </p>
                <h6 class="privacyhead">
                    <b class="text-dark">Wellness and Prevention Programs</b>
                </h6>
                <p class=" termsp1 privj">
                ● Exclusive Health Workshops: Participate in elite workshops and seminars focused on respiratory health, wellness strategies, and preventive care.<br></br>
                ● Personalized Lifestyle Coaching: Benefit from one-on-one coaching sessions to enhance your lifestyle and achieve your health goals.
                </p>

                <h6 class="privacyhead">
                    <b class="text-dark">Special Offers and Discounts</b>
                </h6>
                <p class=" termsp1 privj">
                    
                    ● Exclusive Discounts: Access significant discounts on medical equipment rentals and purchases, as well as additional health services and products.<br></br>
                    ● Partner Perks: Enjoy special offers and benefits from our network of premium partner organizations, including wellness centers, fitness clubs, and health-related services.

                </p>
                <h5 style={{marginLeft:"-20px"}}>
                    <b class="text-dark"> How to join Respiro Plus?</b>
                </h5><br></br>
                <h6 class="privacyhead">
                    <b class="text-dark">Enrollment steps</b>
                </h6>
                <p class=" termsp1 privj">
                    
                    ● Contact Us: Reach out to our membership team to express your interest in Respiro Plus and schedule a consultation.<br></br>
                    ● Personalized Consultation: Engage in a detailed consultation to outline your health needs and goals, ensuring Respiro Plus is the right fit for you.<br></br>
                    ● Activate Membership: Complete the enrollment process and start enjoying the exclusive benefits and services offered by Respiro Plus.

                </p>
                <h6 class="privacyhead">
                    <b class="text-dark">Contact Us</b>
                </h6>
                <p class=" termsp1 privj">
                For more information or to enroll in Respiro Plus, please contact us:<br></br>
                Email:bussiness@respirohealthcare.com<br></br>
                Phone:+91 8075892588<br></br>
                Visit Our Office:2nd floor, Golden Plaza, TB Junction, Angamaly 683572
                   </p>

                </div>
            </div>
        </>
    );
}


// If you have any questions about this Cookie Policy, please contact us:<br></br>
//                     Email:bussiness@respirohealthcare.com<br></br>
//                     Phone:+91 8075892588<br></br>





