import React, { useEffect, useState, useContext } from "react";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoadingSpinner = () => (
  <div className="loading">
    <svg
      version="1.2"
      height="300"
      width="600"
      xmlns="http://www.w3.org/2000/svg"
      viewport="0 0 60 60"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="pulsar"
        stroke="rgba(6, 57, 212, 0.888)"
        fill="none"
        strokeWidth="1"
        strokeLinejoin="round"
        d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
      />
    </svg>
    <center>
      <h5>Loading...</h5>
    </center>
  </div>
);

function RhfAddress() {
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingAddress, setExistingAddress] = useState(true);
  const [editView, setEditView] = useState(false);
  const { rhfId, accessToken, refreshToken ,isMobile} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    rhfId: "",
    addressType: "rhf",
    addressLine1: "",
    addressLine2: "",
    place: "",
    district: "",
    state: "",
    pincode: "",
    localityId: "",
  });

  const [loc, setLoc] = useState([]);

  useEffect(() => {
    setLoading(true);
    getFunction(
      { id: data.rhfId, addressType: data.addressType },
      "/address/get-rhf-address"
    )
      .then((response) => {
        if (response.success && response.success.result.length > 0) {
          const responseData = response.success.result[0];
          setData({
            id: responseData.id,
            addressType: "rhf",
            rhfUnitId: responseData.rhf_unit_id,
            addressLine1: responseData.address_line_1,
            addressLine2: responseData.address_line_2,
            place: responseData.locality,
            district: responseData.districtname,
            state: responseData.state,
            pincode: responseData.pincode.toString(),
          });
          setExistingAddress(true);
        } else {
          setExistingAddress(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [data.rhfId, data.addressType]);

  useEffect(() => {
    if (data.pincode.length < 6) {
      setLoc([]);
      setData({ ...data, place: "", district: "", state: "" });
    } else {
      getLocations(data.pincode);
    }
  }, [data.pincode]);


  useEffect(() => {
    if (rhfId) {
      setData({ ...data, rhfId });
    }
  }, [rhfId]);


useEffect(() => {
  if (loc.length > 0 && data.place) {
    const selectedLoc = loc.find((item) => item.locality === data.place);
    if (selectedLoc) {
      setData({
        ...data,
        district: selectedLoc.districtname,
        state: selectedLoc.state,
        localityId: selectedLoc.id,
      });
    }
  }
}, [data.place, loc]);


  const getLocations = (pincode) => {
    getFunction({ pincode }, "/locality/get-location-by-pincode")
      .then((response) => {
        const responseData = response.success.result;
        setLoc(responseData);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          console.error("Error response:", error.response.data);
        }
      });
  };

  const changeHandler = (e) => {
    setErrors({});
    const { name, value } = e.target;
    const regex = /^[\w\s,.-]*$/;
    const pinRegex = /^[1-9]\d{2}\s?\d{3}$/;

    if (
      (name === "addressLine1" || name === "addressLine2") &&
      !regex.test(value)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Invalid ${name.replace(/([A-Z])/g, " $1").toLowerCase()}`,
      }));
    } else if (
      name === "pincode" &&
      value.length === 6 &&
      pinRegex.test(value)
    ) {
      getLocations(value);
    }
    setData({ ...data, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    if (!data.addressLine1.trim()) {
      newErrors.addressLine1 = "Address line 1 is required";
      valid = false;
    }
    if (!data.place.trim()) {
      newErrors.place = "Place is required";
      valid = false;
    }
    if (!data.state.trim()) {
      newErrors.state = "State is required";
      valid = false;
    }
    if (!data.pincode.trim()) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFunction(data, "/address/set-rhf-address")
        .then((response) => {
          toast.success("Address Added successfully"); 
          setEditView(false);
          setExistingAddress(true);
          setData({ ...data, id: response.success.id });
          
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.error); 
          }
          toast.error("An error occurred while submitting the form. Please try again later."); 
        });
    }
  };

  const updateRhfAddress = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    console.log("Payload data: ", data);
    updateFunction(data, "/address/update-rhf-address")
      .then((response) => {
        toast.success("Address updated successfully");
        setEditView(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.error); 
        }
        toast.error("An error occurred while submitting the form. Please try again later."); 
      });
  };

  const addressButtonHandler = (e) => {
    setEditView(!editView);
    if (editView) {
      if (existingAddress) {
        updateRhfAddress(e);
      } else {
        handleSubmit(e);
      }
    }
  };


  // const addressButtonHandler = (e) => {
  //   if (editView) {
  //     if (existingAddress) {
  //       updateRhfAddress(e);
  //     } else {
  //       handleSubmit(e);
  //     }
  //   }
  //   setEditView(!editView); 
  // };
  
const desktopView = () => (
  <div className="row">
    <div className="col-sm-5 serviceinfo">
      <h2 className="citext topfrom">Address line 1 *</h2>
      <input
        disabled={!editView}
        type="text"
        tabIndex={1}
        name="addressLine1"
        onChange={changeHandler}
        value={data.addressLine1}
        placeholder="Address Line 1"
        className="form-input textbox"
      />
      {errors.addressLine1 && (
        <div className="validation">{errors.addressLine1}</div>
      )}
      <h2 className="citext topfrom">Pincode *</h2>
      <input
        disabled={!editView}
        type="text"
        name="pincode"
        tabIndex={3}
        onChange={changeHandler}
        value={data.pincode}
        placeholder="Pincode"
        className="form-input textbox"
      />
      {errors.pincode && <div className="validation">{errors.pincode}</div>}
      <h2 className="citext topfrom">District *</h2>
      <input
        disabled
        type="text"
        tabIndex={5}
        name="district"
        value={data.district}
        placeholder="District"
        className="form-input textbox"
      />
      {errors.district && <div className="validation">{errors.district}</div>}
    </div>
    <div className="col-sm-5">
      <h2 className="citext topfrom">Address line 2 *</h2>
      <input
        disabled={!editView}
        type="text"
        name="addressLine2"
        tabIndex={2}
        onChange={changeHandler}
        value={data.addressLine2}
        placeholder="Address Line 2"
        className="form-input textbox"
      />
      {errors.addressLine2 && (
        <div className="validation">{errors.addressLine2}</div>
      )}
      <h2 className="citext topfrom">Place *</h2>
      <select
        disabled={!editView}
        name="place"
        tabIndex={4}
        onChange={changeHandler}
        value={data.place}
        className="form-input textbox"
      >
        <option value={data.place}>{data.place}</option>
        {loc.map((item) => (
          <option value={item.locality} key={item.id}>
            {item.locality}
          </option>
        ))}
      </select>
      {errors.place && <div className="validation">{errors.place}</div>}
      <h2 className="citext topfrom">State *</h2>
      <input
        disabled
        type="text"
        name="state"
        tabIndex={6}
        value={data.state}
        placeholder="State"
        className="form-input textbox"
      />
      {errors.state && <div className="validation">{errors.state}</div>}
    </div>
    <div className="col-sm-2">
      <h2
        type="button"
        className="visit1 providervisit3"
        onClick={addressButtonHandler}
      >
        {editView ? "Submit " : "Edit "}
        <span className="fa-solid fa-pen"></span>
      </h2>
    </div>
  </div>
);
const mobileView = () => (
  <div className="row rhdaddressmobile">
    <div className="col-sm-2">
      <h2
        type="button"
        className="visit1 providervisit3"
        onClick={addressButtonHandler}
      >
        {editView ? "Submit " : "Edit "}
        <span className="fa-solid fa-pen"></span>
      </h2>
    </div>

    <div className="col-sm-5 serviceinfo">
      <h2 className="citext topfrom">Address line 1 *</h2>
     
      <input
        disabled={!editView}
        type="text"
        tabIndex={1}
        name="addressLine1"
        onChange={changeHandler}
        value={data.addressLine1}
        placeholder="Address Line 1"
        className="form-input textbox"
      />
      {errors.addressLine1 && (
        <div className="validation">{errors.addressLine1}</div>
      )}
      <h2 className="citext topfrom">Address line 2 *</h2>
      <input
        disabled={!editView}
        type="text"
        name="addressLine2"
        tabIndex={2}
        onChange={changeHandler}
        value={data.addressLine2}
        placeholder="Address Line 2"
        className="form-input textbox"
      />
      {errors.addressLine2 && (
        <div className="validation">{errors.addressLine2}</div>
      )}
      <h2 className="citext topfrom">Pincode *</h2>
      <input
        disabled={!editView}
        type="text"
        name="pincode"
        tabIndex={3}
        onChange={changeHandler}
        value={data.pincode}
        placeholder="Pincode"
        className="form-input textbox"
      />
      {errors.pincode && <div className="validation">{errors.pincode}</div>}
    </div>
    <div className="col-sm-5">
      <h2 className="citext topfrom">Place *</h2>
      <select
        disabled={!editView}
        name="place"
        tabIndex={4}
        onChange={changeHandler}
        value={data.place}
        className="form-input textbox"
      >
        <option value={data.place}>{data.place}</option>
        {loc.map((item) => (
          <option value={item.locality} key={item.id}>
            {item.locality}
          </option>
        ))}
      </select>
      {errors.place && <div className="validation">{errors.place}</div>}
      <h2 className="citext topfrom">District *</h2>
      <input
        disabled
        type="text"
        tabIndex={5}
        name="district"
        value={data.district}
        placeholder="District"
        className="form-input textbox"
      />
      {errors.district && <div className="validation">{errors.district}</div>}

      <h2 className="citext topfrom">State *</h2>
      <input
        disabled
        type="text"
        name="state"
        tabIndex={6}
        value={data.state}
        placeholder="State"
        className="form-input textbox"
      />
      {errors.state && <div className="validation">{errors.state}</div>}
    </div>
  </div>
);
  return (
    <div className="contain">
       <ToastContainer /> 
      <br></br> <br></br> <br></br>
      <h1 className="profileheading1 mobilehead">Address Information</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <hr className="providerline"></hr>
         {isMobile ? mobileView() : desktopView() }
        </>
      )}
    </div>
  );
}

export default withRhfAuth(RhfAddress);
