import React, { useState, useEffect, useContext } from "react";
import ProviderHeader from "../header/ProviderHeader";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBedPulse,
  faCalendarDays,
  faHome,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import DashboardLanding from "./DashboardLanding";
import MyAppointments from "./MyAppointments";
import Patientdetails from "./Patientdetails";
import { slide as Menu } from "react-burger-menu";

function ProviderDashboard() {
  const {
    providerId,
    setProviderId,
    setProviderType,
    providerEmail,
    setProviderEmail,
    setIdToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  } = useContext(AuthContext);

  const [menuActive, setMenuActive] = useState(false);
  const [home, setHome] = useState(true);
  const [completedAppointments, setCompletedAppointments] = useState(false);
  const [myPendingAppointments, setMyPendingAppointments] = useState(false);
  const [patientDetails, setPatientDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control mobile menu open/close

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (section) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setHome(section === "home");
      setCompletedAppointments(section === "completedappointments");
      setMyPendingAppointments(section === "mypendingappointments");
      setPatientDetails(section === "patientdetails");
      setIsMenuOpen(false); // Close mobile menu after selection
    }, 500); // Simulate loading time
  };


  const renderMenu = () => (
    <>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("home")}
        style={home ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faHome} style={{ fontSize: "25px" }} />
        &nbsp;Home
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("mypendingappointments")}
        style={myPendingAppointments ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "25px" }} />
        &nbsp;My Appointments
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("patientdetails")}
        style={patientDetails ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faBedPulse} style={{ fontSize: "25px" }} />
        &nbsp;Patient Queue
      </h3>
    </>
  );

  const renderMenuMobile = () => (
    <Menu
      left
      pageWrapId={"page-wrap"}
      outerContainerId={"root"}
      isOpen={isMenuOpen} // Control menu open/close
      onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)} // Update menu state on open/close
    >
      {renderMenu()}
    </Menu>
  );


  const renderMenuDesktop = () => (
    <div className={`side-menu bg2 prowidth ${menuActive ? "active" : ""}`}>
      {renderMenu()}
    </div>
  );

  return (
    <div>
      <ProviderHeader />
      <div className="d-flex protop" id="outer-container">
        {isMobile ? renderMenuMobile() : renderMenuDesktop()}
        <div id="page-wrap" className="container" style={{ marginTop: "20px" }}>
          {home && <DashboardLanding />}
          {myPendingAppointments && <MyAppointments />}
          {patientDetails && <Patientdetails />}
        </div>
      </div>
    </div>
  );
}

export default withProviderAuth(ProviderDashboard);
