import React, { useState, useEffect, useContext } from "react";
import validator from "validator";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";

import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";

function ServiceInformation() {
  const { providerId, providerEmail } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    gender: "",
    phone: "",
    email: "",
    yearsExperience: "",
    initConsultFee: "",
    repeatConsultFee: "",
    repeatInterval: "",
    providerType: "",
    registrationNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingProvider, setExistingProvider] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);

  useEffect(() => {
    getFunction({ id: providerId }, "/provider/get-profile")
      .then((response) => {
        const responseData = response.success.provider;
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
          gender: responseData.gender,
          phone: responseData.phone,
          email: responseData.email,
          yearsExperience: responseData.years_experience,
          initConsultFee: responseData.init_consult_fee,
          repeatConsultFee: responseData.repeat_consult_fee,
          repeatInterval: responseData.repeat_interval,
          providerType: responseData.provider_type,
          registrationNumber: responseData.registration_number,
        });
      })
      .catch((error) => {
        setData({ ...data, email: providerEmail, id: providerId });
        console.log(error);
        setExistingProvider(false);
      });
  }, []);

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let errorMessage = "";

    if (fieldValue.trim() === "") {
      errorMessage = "This field is required";
    }

    if (fieldName === "firstName" && !validator.isAlpha(fieldValue)) {
      errorMessage = "Only alphabets allowed!";
    } else if (fieldName === "lastName" && !validator.isAlpha(fieldValue)) {
      errorMessage = "Only alphabets allowed!";
    } else if (
      fieldName === "phone" &&
      !validator.isMobilePhone(fieldValue, "en-IN", { strictMode: false })
    ) {
      errorMessage = "Invalid phone number!";
    } else if (fieldName === "email" && !validator.isEmail(fieldValue)) {
      errorMessage = "Invalid email address!";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));

    setData({ ...data, [fieldName]: fieldValue });
  };

  const register = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setFunction(data, "/provider/set-profile")
      .then((response) => {
        console.log(response.data);
        setProfileEditView(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.error);
        }
        console.log(error);
        setRegisterStatus(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };

  const updateProvider = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    updateFunction(data, "/provider/update-profile")
      .then((response) => {
        console.log(response.data);
        setProfileEditView(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.error);
        }
        console.log(error);
        setRegisterStatus(
          "An error occurred while submitting the form. Please try again later."
        );
      });
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
      valid = false;
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
      valid = false;
    }
    if (!data.gender) {
      errors.gender = "Gender is required";
      valid = false;
    }

    if (!validator.isMobilePhone(data.phone, "en-IN", { strictMode: false })) {
      errors.phone = "Phone number is required";
      valid = false;
    }
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is required";
      valid = false;
    }
    if (!data.yearsExperience) {
      errors.yearsExperience = "Years of experience is required";
      valid = false;
    }

    if (!data.initConsultFee) {
      errors.initConsultFee = "Initial consultation fee is required";
      valid = false;
    }
    if (!data.repeatConsultFee) {
      errors.repeatConsultFee = "Repeat consultation fee is required";
      valid = false;
    }
    if (!data.repeatInterval) {
      errors.repeatInterval = "Repeat interval is required";
      valid = false;
    }
    if (!data.providerType || data.providerType === "Provider Type") {
      errors.providerType = "Provider type is required";
      valid = false;
    }
    if (!data.registrationNumber) {
      errors.registrationNumber = "Registration number is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const profileButtonHandler = (e) => {
    setProfileEditView(true);
    if (profileEditView) {
      if (existingProvider) {
        updateProvider(e);
      } else {
        register(e);
      }
    }
  };

  return (
    <>
      <div class="providerline">
        <br></br>
        <br></br>
        <br></br>

        <h1 class="profileheading1 providerprofileheading1">Service Information</h1>
        <hr class="providerline"></hr>

        <div class="container">
          <div class="row">
            <div class="col-sm-5 serviceinfo">
              <h2 class="citext topfrom">Years of Experience *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                onChange={changeHandler}
                name="yearsExperience"
                placeholder="Years of Experience"
                value={data.yearsExperience}
                className="form-input textbox"
              />
              {errors.yearsExperience && (
                <div className="validation">{errors.yearsExperience}</div>
              )}
              <br></br>
              <br></br>
              <h2 class="citext topfrom">Repeat Consultation Fee *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                onChange={changeHandler}
                name="repeatConsultFee"
                placeholder="Repeat Consult Fee"
                value={data.repeatConsultFee}
                className="form-input textbox"
              />
              {errors.repeatConsultFee && (
                <div className="validation">{errors.repeatConsultFee}</div>
              )}
              <br></br> <br></br>
              <h2 class="citext topfrom">Provider Type *</h2>
              <select
                disabled={profileEditView ? false : true}
                className="form-input pl-0 text-dark textbox"
                name="providerType"
                placeholder="Provider Type"
                onChange={changeHandler}
                value={data.providerType}
              >
                <option selected>Provider Type</option>
                <option>Doctor</option>
                <option>Therapist</option>
              </select>
              {errors.providerType && (
                <div className="validation">{errors.providerType}</div>
              )}
              
            </div>
            <div class="col-sm-5">
              <h2 class="citext topfrom">Initial Cousultation Fee  *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                onChange={changeHandler}
                name="initConsultFee"
                placeholder="Initial Consult Fee"
                value={data.initConsultFee}
                className="form-input textbox"
              />
              {errors.initConsultFee && (
                <div className="validation">{errors.initConsultFee}</div>
              )}
              <br></br> <br></br>
              <h2 class="citext topfrom">Repeat Interval (Days)  *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                onChange={changeHandler}
                name="repeatInterval"
                placeholder="Repeat Interval"
                value={data.repeatInterval}
                className="form-input textbox"
              />
              {errors.repeatInterval && (
                <div className="validation">{errors.repeatInterval}</div>
              )}
              <br></br> <br></br>
              <h2 class="citext topfrom">Registration Number  *</h2>
              <input
                disabled={profileEditView ? false : true}
                type="text"
                onChange={changeHandler}
                name="registrationNumber"
                placeholder="Registration Number"
                value={data.registrationNumber}
                className="form-input textbox"
              />
              {errors.registrationNumber && (
                <div className="validation">{errors.registrationNumber}</div>
              )}
            </div>
            <div class="col-sm-2">
              <h2 type="button" class="visit1 providervisit2" onClick={profileButtonHandler}>
                {profileEditView ? "Submit " : "Edit "}
                <span className="fa-solid fa-pen"></span>
              </h2>
            </div>
          </div>
        </div>
=======
     
        <hr></hr>

       

        <br></br>
        <br></br>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default ServiceInformation;
