import { jwtDecode } from "jwt-decode";
import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

const getAuthTokens = (code, path, authType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseURL + path, {
        code,
      })
      .then((response) => {
        const { sub, email } = jwtDecode(response.data.success.id_token);
        console.log(sub, email);
        const idToken = response.data.success.id_token;
        const accessToken = response.data.success.access_token;
        const refreshToken = response.data.success.refresh_token;
        const data = { sub, email, idToken, accessToken, refreshToken };
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const renewAuthTokens = (refresh_token, path, authType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseURL + path, {
        refresh_token,
      })
      .then((response) => {
        const { sub, email } = jwtDecode(response.data.success.id_token);
        localStorage.setItem("id_token", response.data.success.id_token);
        localStorage.setItem(
          "access_token",
          response.data.success.access_token
        );
        localStorage.setItem(`${authType}Id`, sub);
        localStorage.setItem(`${authType}Email`, email);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const validateToken = (accessToken) => {
  return new Promise((resolve, reject) => {
    if (!accessToken) {
      return reject(false);
    }
    const decodedToken = jwtDecode(accessToken);
    if (decodedToken.exp * 1000 < Date.now()) {
      return reject(false);
    }
    return resolve(true);
  });
};

const checkAuthStatus = (accessToken, refreshToken, renewPath, authType) => {
  return new Promise((resolve, reject) => {
    validateToken(accessToken)
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        renewAuthTokens(refreshToken, renewPath, authType)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(false);
          });
      });
  });
};

const getTokenFromLocalStorage = () => {
  return new Promise((resolve, reject) => {
    try {
      const access_token = localStorage.getItem("access_token");
      const id_token = localStorage.getItem("id_token");
      const refresh_token = localStorage.getItem("refresh_token");
      const tokens = { access_token, id_token, refresh_token };
      resolve(tokens);
    } catch (error) {
      reject(error);
    }
  });
};

export {
  getAuthTokens,
  renewAuthTokens,
  checkAuthStatus,
  getTokenFromLocalStorage,
};
