import React, { useState, useRef, useEffect } from "react";
import Header from "../header/Header";
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'; // Import both down and up arrow icons

const infusionTherapies = [
  {
    id: 1,
    name: 'Hydration Therapy',
    priceRange: '₹500 (including visit charge)',
    imgSrc: '../style/img/resp/infusion1.jpg',
    description: "Hydration Therapy-IV Fluids Used: Normal saline (0.9% sodium chloride), Ringer's lactate solution"
  },
  {
    id:2,
    name: 'IV Antibiotic Therapy',
    priceRange: '₹800 (including monitoring and visit charge)',
    imgSrc: '../style/img/resp/infusion2.jpg',
    description: 'For administering antibiotics directly into the bloodstream.'
  },
  {
    id: 3,
    name: 'Pain Management (IV Analgesics)',
    priceRange: '₹500 (for severe pain relief)',
    imgSrc: '../style/img/resp/infusion3.jpg',
    description: 'To deliver pain relief directly into the bloodstream for patients with severe pain post-surgery, due to cancer, or other chronic pain conditions.'
  },
  {
    id: 4,
    name: 'Chemotherapy',
    priceRange: '₹3,000 (for cancer patients)',
    imgSrc: '../style/img/resp/infusion4.jpg',
    description: 'For cancer patients requiring chemotherapy drugs to be administered intravenously.'
  },
  {
    id: 5,
    name: 'Nutritional Therapy',
    priceRange: '₹1,500 (including visit charge)',
    imgSrc: '../style/img/resp/infusion5.jpg',
    description: 'Providing essential nutrients intravenously for patients unable to eat due to medical conditions.'
  },
  {
    id: 6,
    name: 'Iron Infusion',
    priceRange: '₹3,000 (for cancer patients)',
    imgSrc: '../style/img/resp/infusion6.jpg',
    description: 'For patients with anemia or low iron levels who require intravenous iron supplementation to replenish iron stores in the body.'
  },
  {
    id: 7,
    name: 'Anti-Nausea and Anti-Emetic Therapy',
    priceRange: '₹600 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion7.jpg',
    description: 'For patients undergoing chemotherapy, or experiencing severe nausea and vomiting due to other medical conditions.'
  },
  {
    id: 8,
    name: 'Immune Support Therapy',
    priceRange: '₹1,500 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion8.jpg',
    description: 'IV infusion of immune-boosting elements such as zinc, Vitamin C, and other minerals for patients recovering from illness, or to strengthen the immune system.'
  },
  {
    id: 9,
    name: 'IV Migraine Relief Therapy',
    priceRange: '₹1,000 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion9.jpg',
    description: 'Provides relief from acute migraine episodes using a combination of IV fluids, anti-nausea medication, and pain-relief medication to alleviate symptoms.'
  },
  {
    id: 10,
    name: 'IV Electrolyte Therapy',
    priceRange: '₹700 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion10.jpg',
    description: 'Corrects electrolyte imbalances (e.g., sodium, potassium) in patients suffering from conditions like dehydration, kidney issues, or after severe vomiting/diarrhea.'
  },
  {
    id: 11,
    name: 'IV Detox Therapy',
    priceRange: '₹1,800 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion11.jpg',
    description: 'A blend of IV fluids, vitamins, and antioxidants designed to detoxify the body and support liver function, especially after exposure to toxins or medication side effects.'
  },
  {
    id: 12,
    name: 'Post-COVID Recovery IV Therapy',
    priceRange: '₹2,500 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion12.jpg',
    description: 'Specially designed for patients recovering from COVID-19, this therapy includes immune-boosting nutrients, hydration, and vitamins to promote faster recovery.'
  },
  {
    id: 13,
    name: 'Nutritional Support Therapy (Total Parenteral Nutrition - TPN)',
    priceRange: '₹4,500 (includes visit charge)',
    imgSrc: '../style/img/resp/infusion13.jpg',
    description: 'For patients who cannot consume food orally, TPN delivers essential nutrients directly into the bloodstream, ensuring proper nutrition and energy supply.'
  },
  // Add more infusion therapy objects as needed...
];

const InfusionTherapyCard = ({ therapy, handleSelect, expandedTherapyId, handleToggle }) => {
  const showDetails = expandedTherapyId === therapy.id; // Determine if this therapy's details should be shown

  return (
    <div className="col-sm-4">
      <div className="card rhfcardser">
        <img className="card-img-top ghfg" src={therapy.imgSrc} alt={therapy.name} />
        <div className="card-body">
          <p className="card-text">{therapy.name}</p>
          <p className="text-dark">
            <b className="text-dark">{therapy.priceRange}</b>
          </p>

          {/* Detail Button styled as a button */}
          <button
            onClick={() => handleToggle(therapy.id)} // Call the toggle function with therapy ID
            style={{
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              color: '#3495FF',
              padding: '0',
              fontSize: '16px',
              textDecoration: 'underline',
              display: 'inline-block',
              margin: '5px 0',
            }}
            aria-expanded={showDetails}
          >
            Details {showDetails ? <FaArrowUp style={{ color: "#3495FF" }} /> : <FaArrowDown style={{ color: "#3495FF" }} />}
          </button>

          {/* Conditionally render the details if showDetails is true */}
          {showDetails && (
            <div className="therapy-details" style={{ marginTop: '15px' }}>
              <p>{therapy.description}</p>
            </div>
          )}

          {/* Select Button */}
          <center>
            <button className="custom-btn1 btn-3" onClick={() => handleSelect(therapy)}>
              <span>Select</span>
            </button>
          </center>
        </div>
      </div>
    </div>
  );
};

const InfusionTherapySelection = () => {
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [expandedTherapyId, setExpandedTherapyId] = useState(null); // Track the currently expanded therapy
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const bookingSectionRef = useRef(null);

  const handleSelect = (therapy) => {
    setSelectedTherapy(therapy);
    const initialPrice = parseInt(therapy.priceRange.split(' ')[0].replace('₹', '').replace(',', ''));
    setTotalPrice(initialPrice);

    if (bookingSectionRef.current) {
      bookingSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleToggle = (id) => {
    // Toggle the details view based on the selected therapy ID
    setExpandedTherapyId((prevId) => (prevId === id ? null : id));
  };

  const handleNextPage = () => {
    if ((currentPage * itemsPerPage) < infusionTherapies.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // This useEffect will run when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // This useEffect will run when the currentPage changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the current page changes
  }, [currentPage]);

  // Calculate the index of the first and last item on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = infusionTherapies.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <Header />
      <div className="rhfsebginfusbackg">
        <br />
        <br />
        <center>
          <br />
          <h2 className="rhfhed1">IV Infusion Therapy Packages</h2>
          <p>
            <br />
            Choose Respiro Healthcare's IV infusion therapy packages, managed by the RHF team. We offer comprehensive therapy
            services to ensure your health and safety. Schedule your session today!
          </p>
        </center>

        <div className="container">
          <div className="row">
            {currentItems.map((therapy) => (
              <InfusionTherapyCard
                key={therapy.id}
                therapy={therapy}
                handleSelect={handleSelect}
                expandedTherapyId={expandedTherapyId} // Pass the currently expanded ID
                handleToggle={handleToggle} // Pass the toggle function
              />
            ))}
          </div>

          {selectedTherapy && (
            <div ref={bookingSectionRef}>
              <center><h2><b className="selvacc text-dark">Selected Therapy</b></h2></center>
              <br />
              <center>
                <div className="viosqginfusion">
                  <div className="container">
                    <form>
                      <div className="form-group11">
                        <label className="form-label" htmlFor="therapyName">Therapy Name:</label>
                        <input type="text" className="form-control" id="therapyName" value={selectedTherapy.name} readOnly />
                      </div>
                      <div className="form-group11">
                        <label className="form-label" htmlFor="therapyPrice">Price:</label>
                        <input type="text" className="form-control" id="therapyPrice" value={totalPrice} readOnly />
                      </div>
                      <button type="submit" className="custom-btn1 btn-3" style={{ width: '50%' }}>
                        <span>Book Now</span>
                      </button>
                    </form>
                  </div>
                </div>
              </center>
            </div>
          )}

          {/* Pagination Controls */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1} style={{ marginRight: '10px' }}>
              Previous
            </button>
            <button onClick={handleNextPage} disabled={indexOfLastItem >= infusionTherapies.length}>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfusionTherapySelection;
