import React, { useEffect, useState, useContext } from "react";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegEdit } from "react-icons/fa";
const LoadingSpinner = () => (
  <>
    <br></br>
    <br></br>
    <br></br>
    <div className="loading">
      <svg
        version="1.2"
        height="300"
        width="600"
        xmlns="http://www.w3.org/2000/svg"
        viewport="0 0 60 60"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          id="pulsar" 
          stroke="rgba(6, 57, 212, 0.888)"
          fill="none"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210"
        />
      </svg>
      <center>
        <h5>Loading...</h5>
      </center>
    </div>
  </>
);

function ProviderAddress() {
  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingAddress, setExistingAddress] = useState(true);
  const [editView, setEditView] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const { providerId, accessToken, refreshToken,isMobile } = useContext(AuthContext);

  const [data, setData] = useState({
    providerId: providerId || "",
    addressType: "provider",
    addressLine1: "",
    addressLine2: "",
    place: "",
    district: "",
    state: "",
    pincode: "",
    localityId: "",
  });

  const [loc, setLoc] = useState([]);

  useEffect(() => {
    if (!providerId) return;
    setData((prevData) => ({ ...prevData, providerId }));

    getFunction(
      { id: providerId, addressType: "provider" },
      "/address/get-provider-address"
    )
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          addressType: "provider",
          providerId: responseData.provider_id,
          addressLine1: responseData.address_line_1,
          addressLine2: responseData.address_line_2,
          place: responseData.locality,
          district: responseData.districtname,
          state: responseData.state,
          pincode: responseData.pincode,
          localityId: responseData.locality_id,
        });
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setExistingAddress(false);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, [providerId]);

  useEffect(() => {
    if (data.pincode.length < 6) {
      setLoc([]);
      setData((prevData) => ({
        ...prevData,
        place: "",
        district: "",
        state: "",
      }));
    } else {
      getLocations(data.pincode);
    }
  }, [data.pincode]);

  useEffect(() => {
    const selectedLoc = loc.filter((item) => item.locality === data.place);
    if (selectedLoc.length > 0) {
      setData((prevData) => ({
        ...prevData,
        district: selectedLoc[0].districtname,
        state: selectedLoc[0].state,
        localityId: selectedLoc[0].id,
      }));
    }
  }, [data.place, loc]);

  const getLocations = (pincode) => {
    getFunction({ pincode }, "/locality/get-location-by-pincode")
      .then((response) => {
        const responseData = response.success.result;
        setLoc(responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeHandler = (e) => {
    setErrors({});
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const regex = /^[\w\s,.-]*$/gm;
    const pinRegex = /^[1-9]\d{2}\s?\d{3}$/;

    if (fieldName === "addressLine1" && !regex.test(fieldValue)) {
      setErrors({ addressLine1: "Invalid Address Line 1" });
    } else if (fieldName === "addressLine2" && !regex.test(fieldValue)) {
      setErrors({ addressLine2: "Invalid Address Line 2" });
    } else if (fieldName === "pincode" && pinRegex.test(fieldValue)) {
      getLocations(fieldValue);
    }

    setData((prevData) => ({ ...prevData, [fieldName]: fieldValue }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!data.addressLine1.trim()) {
      newErrors.addressLine1 = "Address line 1 is required";
      valid = false;
    }
    if (!data.place.trim()) {
      newErrors.place = "Place is required";
      valid = false;
    }
    if (!data.state.trim()) {
      newErrors.state = "State is required";
      valid = false;
    }
    if (!data.pincode.trim()) {
      newErrors.pincode = "Pincode is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFunction(data, "/address/set-provider-address")
        .then((response) => {
          toast.success("Address Added successfully"); 
          setEditView(false);
          setExistingAddress(true);
          setData((prevData) => ({ ...prevData, id: response.success.id }));
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.error); 
          }
          console.log(error);
          toast.error("An error occurred while submitting the form. Please try again later."); 
        });
    }
  };

  const updatePatientAddress = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const updateData = {
      id: data.id,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      localityId: data.localityId,
      type: data.type,
    };

    updateFunction(updateData, "/address/update-provider-address")
      .then((response) => {
        toast.success("Address Updated successfully"); 
        setEditView(false);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          toast.error(error.response.data.error); 
        }
        console.log(error);
        toast.error("An error occurred while submitting the form. Please try again later."); 
      });
  };

  const addressButtonHandler = (e) => {
    setEditView(true);
    if (editView) {
      if (existingAddress) {
        updatePatientAddress(e);
      } else {
        handleSubmit(e);
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  const desktopView = () => (
    <div className="row">
          <div className="col-sm-5 serviceinfo">
            <h2 className="citext topfrom">Address line 1 *</h2>
            <input
              disabled={!editView}
              type="text"
              name="addressLine1"
              tabIndex={1}
              onChange={changeHandler}
              value={data.addressLine1}
              placeholder="Address Line 1"
              className="form-input textbox"
            />
            {errors.addressLine1 && (
              <div className="validation">{errors.addressLine1}</div>
            )}
            <br></br>
            <br></br>
            <h2 className="citext topfrom">Pincode *</h2>
            <input
              disabled={!editView}
              type="text"
              name="pincode"
              tabIndex={3}
              onChange={changeHandler}
              value={data.pincode}
              placeholder="Pincode"
              className="form-input textbox"
            />
            {errors.pincode && (
              <div className="validation">{errors.pincode}</div>
            )}
            <br></br>
            <br></br>
            <h2 className="citext topfrom">District *</h2>
            <input
              disabled={true}
              type="text"
              name="district"
              tabIndex={5}
              value={data.district}
              placeholder="District"
              className="form-input textbox"
            />
            {errors.district && (
              <div className="validation">{errors.district}</div>
            )}
            <br></br>
            <br></br>
          </div>
          <div className="col-sm-5">
            <h2 className="citext topfrom">Address line 2 *</h2>
            <input
              disabled={!editView}
              type="text"
              name="addressLine2"
              tabIndex={2}
              onChange={changeHandler}
              value={data.addressLine2}
              placeholder="Address Line 2"
              className="form-input textbox"
            />
            {errors.addressLine2 && (
              <div className="validation">{errors.addressLine2}</div>
            )}
            <br></br>
            <br></br>
            <h2 className="citext topfrom">Place *</h2>
            <select
              disabled={!editView}
              name="place"
              tabIndex={4}
              onChange={changeHandler}
              value={data.place}
              placeholder="Place"
              className="form-input textbox"
            >
              <option value="">{data.place || "Select Place"}</option>
              {loc &&
                loc.map((item) => (
                  <option value={item.locality} key={item.id}>
                    {item.locality}
                  </option>
                ))}
            </select>
            {errors.place && <div className="validation">{errors.place}</div>}
            <br></br>
            <br></br>
            <h2 className="citext topfrom">State *</h2>
            <input
              disabled={true}
              type="text"
              name="state"
              tabIndex={6}
              value={data.state}
              placeholder="State"
              className="form-input textbox"
            />
            {errors.state && <div className="validation">{errors.state}</div>}
            <br></br>
            <br></br>
          </div>
          <div className="col-sm-2">
            <h2
              type="button"
              className="visit1 providervisit3"
              onClick={addressButtonHandler}
            >
              {editView ? "Submit " : "Edit "}
              {/* <span className="fa-solid fa-pen"></span> */}
              <span><FaRegEdit /></span>
            </h2>
          </div>
        </div>
  )
  const mobileView = () => (
    <div className="row">
      <div className="col-sm-2">
        <h2
          type="button"
          className="visit1 providervisit3"
          onClick={addressButtonHandler}
        >
          {editView ? "Submit " : "Edit "}
          {/* <span className="fa-solid fa-pen"></span> */}
          <span>
            <FaRegEdit />
          </span>
        </h2>
      </div>
    
      <div className="col-sm-5 serviceinfo">
        <br></br>
        <h2 className="citext topfrom">Address line 1 *</h2>
        <input
          disabled={!editView}
          type="text"
          name="addressLine1"
          tabIndex={1}
          onChange={changeHandler}
          value={data.addressLine1}
          placeholder="Address Line 1"
          className="form-input textbox"
        />
        {errors.addressLine1 && (
          <div className="validation">{errors.addressLine1}</div>
        )}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Address line 2 *</h2>
        <input
          disabled={!editView}
          type="text"
          name="addressLine2"
          tabIndex={2}
          onChange={changeHandler}
          value={data.addressLine2}
          placeholder="Address Line 2"
          className="form-input textbox"
        />
        {errors.addressLine2 && (
          <div className="validation">{errors.addressLine2}</div>
        )}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Pincode *</h2>
        <input
          disabled={!editView}
          type="text"
          name="pincode"
          tabIndex={3}
          onChange={changeHandler}
          value={data.pincode}
          placeholder="Pincode"
          className="form-input textbox"
        />
        {errors.pincode && <div className="validation">{errors.pincode}</div>}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">Place *</h2>
        <select
          disabled={!editView}
          name="place"
          tabIndex={4}
          onChange={changeHandler}
          value={data.place}
          placeholder="Place"
          className="form-input textbox"
        >
          <option value="">{data.place || "Select Place"}</option>
          {loc &&
            loc.map((item) => (
              <option value={item.locality} key={item.id}>
                {item.locality}
              </option>
            ))}
        </select>
        {errors.place && <div className="validation">{errors.place}</div>}
        <br></br>
        <br></br>
        <h2 className="citext topfrom">District *</h2>
        <input
          disabled={true}
          type="text"
          name="district"
          tabIndex={5}
          value={data.district}
          placeholder="District"
          className="form-input textbox"
        />
        {errors.district && <div className="validation">{errors.district}</div>}
        <br></br>
        <br></br>
      </div>
      <div className="col-sm-5">
        <h2 className="citext topfrom">State *</h2>
        <input
          disabled={true}
          type="text"
          name="state"
          tabIndex={6}
          value={data.state}
          placeholder="State"
          className="form-input textbox"
        />
        {errors.state && <div className="validation">{errors.state}</div>}
        <br></br>
        <br></br>
      </div>
    </div>
  );

  return (
    <div className="contain">
               <ToastContainer /> 
      <div className="providerline">
     
        
        <h1 className="profileheading1 providerprofileheading100">
          Address Information
        </h1>

        <hr className="providerline"></hr>
        {isMobile ? mobileView() : desktopView()}


        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default withProviderAuth(ProviderAddress);