import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import validator from "validator";
import { Link } from "react-router-dom";
import RhfHeader from "../header/RhfHeader";
import { slide as Menu } from "react-burger-menu";
import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus, getAuthTokens } from "../../helpers/authFunctions";
import { AuthContext } from "../../context/authContext";
import withRhfAuth from "../../middleware/withRhfAuth";
import PendingUnitAppointments from "./PendingUnitAppointments";
import CompletedUnitAppointments from "./CompletedUnitAppointments";
import MyRhfAppointments from "./MyRhfAppointments";
import {
  faBedPulse,
  faCalendarDays,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faHourglassHalf,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import DashboardReports from "./DashboardReports";
import CreateUnit from "./CreateUnit";

function RhfDashboard() {
  const {
    rhfId, setRhfId, rhfEmail, setRhfEmail, setIdToken, accessToken, setAccessToken, refreshToken, setRefreshToken, setRhfPrivilege, rhfPrivilege, isMobile
  } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    contactName: "",
    phone: "",
    email: "",
  });
  const [appointment, setAppointment] = useState({
    id: "",
    patientId: "",
    appointmentDate: "",
    startTime: "",
    status: "",
  });

  //const [appointment, setAppointment] = useState([]);

  const [errors, setErrors] = useState({});
  const [registerStatus, setRegisterStatus] = useState("");
  const [existingRhf, setExistingRhf] = useState(true);
  const [profileEditView, setProfileEditView] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState(false);
  const [completedAppointments, setCompletedAppointments] = useState(false);
  const [myAppointments, setMyAppointments] = useState(false);
  const [createUnit, setCreateUnit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tokenRenewPath = "/auth/rhf/renew-token";
  const authType = "rhf";
  const [home, setHome] = useState(true);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    if (accessToken && refreshToken && rhfId) {
      getProfile(accessToken, refreshToken, rhfId);
    }
  }, [accessToken, refreshToken, rhfId]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/rhf/get-token", "rhf")
        .then((data) => {
          setRhfId(data.sub);
          setRhfEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("rhfEmail", data.email);
          localStorage.setItem("rhfId", data.sub);
          getProfile(data.accessToken, data.refreshToken, data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);

  useEffect(() => {
    getFunction({ email: rhfEmail }, "/rhf_member/get-rhf-member-privilege")
      .then((response) => {
        const responseData = response.success.result[0].privilege;
        if (responseData === 1) {
          setRhfPrivilege(true);
          localStorage.setItem("rhfPrivilege", true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rhfEmail]);

  const getProfile = (accessToken, refreshToken, rhfId) => {
    getFunction({ email: rhfEmail }, "/rhf_unit/get-rhf-unit")
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          contactName: responseData.contact_name,
          email: responseData.email,
        });
      })
      .catch((error) => {
        setData({ ...data, email: rhfEmail, id: rhfId });
        console.log(error);
        setExistingRhf(false);
      });
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

 const handleMenuClick = (section) => {
   setIsLoading(true);

   setTimeout(() => {
     setIsLoading(false);
     setHome(section === "home");
     setPendingAppointments(section === "pendingappointments");
     setCompletedAppointments(section === "completedappointments");
     setMyAppointments(section === "myappointments");
     setCreateUnit(section === "createunit");

     // Close the menu when an item is clicked
     setMenuActive(false); // Close the menu for all views

     if (isMobile) {
       setMenuActive(false); // For mobile-specific case (redundant but ensures closure)
     }
   }, 500); // Simulate loading time
 };

  const renderMenu = () => (
    <>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("home")}
        style={home ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faHome} style={{ fontSize: "25px" }} />
        &nbsp;Home
      </h3>
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("myappointments")}
        style={myAppointments ? { border: "1px solid #2196f3" } : null}
      >
        <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: "25px" }} />
        &nbsp;My Tasks
      </h3>
      {rhfPrivilege ? (
        <div>
          <h3
            className="side-menu-item"
            onClick={() => handleMenuClick("pendingappointments")}
            style={pendingAppointments ? { border: "1px solid #2196f3" } : null}
          >
            <FontAwesomeIcon
              icon={faHourglassHalf}
              style={{ color: "red", fontSize: "25px" }}
            />
            &nbsp;Pending Tasks
          </h3>
          <h3
            className="side-menu-item"
            onClick={() => handleMenuClick("completedappointments")}
            style={
              completedAppointments ? { border: "1px solid #2196f3" } : null
            }
          >
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "green", fontSize: "25px" }}
            />
            &nbsp;Completed Tasks
          </h3>
        </div>
      ) : 
      <h3
        className="side-menu-item"
        onClick={() => handleMenuClick("createunit")}
        style={{ border: "1px solid #2196f3" }}
      >
        <FontAwesomeIcon icon={faBedPulse} style={{ fontSize: "25px" }} />
        &nbsp;Create New Unit
      </h3>
      }
    </>
  );

  const renderMenuMobile = () => (
    <Menu
      left
      isOpen={menuActive} // Controlled open/close state
      onStateChange={({ isOpen }) => setMenuActive(isOpen)} // Sync menu open state
      pageWrapId={"page-wrap"}
      outerContainerId={"root"}
      style={{ color: "white" }}
    >
      {renderMenu()}
    </Menu>
  );

  const renderMenuDesktop = () => (
    <div className={`side-menu bg2 prowidth ${menuActive ? "active" : ""}`}>
      {renderMenu()}
    </div>
  );

  return (
    <>
      <RhfHeader></RhfHeader>

      <div className="d-flex protop" id="outer-container">
        {/* <div className="positon">
          <button className="toggle-button" onClick={toggleMenu}>
            ☰
          </button>
        </div> */}
        {/* <div
          className={`side-menu bg2 prowidth ${menuActive ? "active" : ""}`}
        ></div> */}
        {isMobile ? renderMenuMobile() : renderMenuDesktop()}
        <div id="page-wrap" className="container" style={{ marginTop: "20px" }}>
          <>{home && <DashboardReports />}</>
          <>{pendingAppointments && <PendingUnitAppointments />}</>
          <>{completedAppointments && <CompletedUnitAppointments />}</>
          <>{myAppointments && <MyRhfAppointments />}</>
          <>{createUnit && <CreateUnit/>}</>
        </div>
      </div>
    </>
  );
}

export default withRhfAuth(RhfDashboard);