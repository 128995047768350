import React from "react";

function Postsurgerycare() {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <section class="appointment-area">
        <div class="container">
          <div class="appointment-inner">
            <div class="row">
              <div class="col-sm-12 col-lg-5 offset-lg-1">
                <h3>
                  <font color="204ea0">Post surgery care at home </font>
                </h3>
                <blockquote class="generic-blockquote">
                  <img
                    class="d-block w-100 r"
                    src="./style/img/banner/sury.jpg"
                    alt="First slide"
                  />
                </blockquote>
              </div>

              <div class="col-lg-6">
                <div class="appointment-form">
                  <h3>
                    <font color="#204ea0">Just Starts 999₹</font>
                  </h3>
                  <blockquote class="generic-blockquote">
                    1. <br></br>
                    2. <br></br>
                    3. <br></br>
                    4. <br></br>
                    5. <br></br>
                    6. <br></br>
                    7. <br></br>
                    8. <br></br>
                    9. <br></br>
                    10. <br></br>
                    11.
                  </blockquote>
                  <center>
                    <a href="/Pulmonaryedema" class="main_btn mb-3 mr-1 ">
                      Add cart{" "}
                    </a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonial">
        <div class="container"></div>
        <div class="item">
          <div class="testi_item"></div>
        </div>
        <div class="item"></div>
      </section>
    </>
  );
}

export default Postsurgerycare;
