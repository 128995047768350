import React, { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { checkAuthStatus } from "../helpers/authFunctions";
import { AuthContext } from "../context/authContext";
const withProviderAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    const [isAuth, setIsAuth] = useState(undefined);
    const { accessToken, refreshToken } = useContext(AuthContext);
    const renewPath = "/auth/provider/renew-token";
    const authType = "provider";
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");

    useEffect(() => {
      console.log("inside auth");
      if ((WrappedComponent.name === "DashboardLanding" || WrappedComponent.name === "ProviderDashboard") && code) {     
        console.log(WrappedComponent.name)   
        setIsAuth(true);
      } else {
        checkAuthStatus(accessToken, refreshToken, renewPath, authType)
          .then(() => {
            console.log("Check Auth status true");
            setIsAuth(true);
          })
          .catch((error) => {
            console.error("Authentication error:", error);
            setIsAuth(false);
          });
      }
    }, [accessToken, refreshToken, renewPath, authType]);
    
    if (isAuth) {
      console.log("inside true");
      return <WrappedComponent {...props} />;
    } else if (isAuth === false) {
      console.log("inside false");
      //  return <WrappedComponent {...props} />;

      return <Navigate to="/provider/login" />;
    }
  };

  return WithAuth;
};

export default withProviderAuth;
