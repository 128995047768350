import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  getFunction,
} from "../../api/apiFunctions";

function DisbursementReport() {
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProviderType, setSelectedProviderType] = useState('Doctor');
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM')); 
  const [selectedYear, setSelectedYear] = useState(moment().year().toString()); 

  const currentYear = moment().year();
  const months = moment.months();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchData();
  },[])

  useEffect(() => {    
    fetchData();
  }, [selectedProviderType, selectedMonth, selectedYear]);

  const fetchData = () => {
    getFunction({
      entityTypes: selectedProviderType,
      month: selectedMonth,
      year: selectedYear,
    }, "/admin/get-revenue-by-providertype")
      .then((response) => {
        if (response.success && response.success.result) {
          setData(response.success.result);
          setErrorMessage('');
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        setData([]);
        setErrorMessage('Record not found!.');
      });
  };

  const handleProviderTypeChange = (event) => {
    setSelectedProviderType(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const renderTableHeaders = () => {
    if (selectedProviderType === 'rhf') {
      return (
        <tr>
          <th>S.NO</th>
          <th>Unit Number</th>
          <th>Contact Name</th>
          <th>Booking Count</th>
          <th>Total Amount</th>
          <th></th>
        </tr>
      );
    }
    return (
      <tr>
        <th>S.NO</th>
        <th>Provider Name</th>
        <th>Type</th>
        <th>Appointment Count</th>
        <th>Payable Amount</th>
        <th></th>
      </tr>
    );
  };

  const renderTableRows = () => {
    if (data.length > 0) {
      return data.map((item, index) => {
        if (selectedProviderType === 'rhf') {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.unit_number}</td>
              <td>{item.contact_name}</td>
              <td>{item.booking_count}</td>
              <td>{item.payable_amount}</td>
            </tr>
          );
        }
        return (
          <tr key={item.provider_id}>
            <td>{index + 1}</td>
            <td>{item.provider_name}</td>
            <td>{item.provider_type}</td>
            <td>{item.appointment_count}</td>
            <td>{item.payable_amount}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="6" style={{ textAlign: 'center' }}>
            {errorMessage || 'No records found'}
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      <h2>Disbursement Report</h2>
      <br />
      <div className="col-sm-12">
        <h6 className="mb-2 pb-1"> Type: </h6>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="doctor"
            value="Doctor"
            checked={selectedProviderType === "Doctor"}
            onChange={handleProviderTypeChange}
          />
          <label className="form-check-label" htmlFor="doctor">
            Doctor
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="therapist"
            value="Therapist"
            checked={selectedProviderType === "Therapist"}
            onChange={handleProviderTypeChange}
          />
          <label className="form-check-label" htmlFor="therapist">
            Therapist
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="rhf"
            value="rhf"
            checked={selectedProviderType === "rhf"}
            onChange={handleProviderTypeChange}
          />
          <label className="form-check-label" htmlFor="rhf">
            Rhf
          </label>
        </div>
        <br />
        <h6>Choose Month and Year</h6>
        <select value={selectedMonth} onChange={handleMonthChange}>
          {months.map((month, index) => (
            <option key={index} value={moment().month(index).format('MM')}>
              {month}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        <select value={selectedYear} onChange={handleYearChange}>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>

        <br />
        <br />
      </div>
      <div className="col-sm-6" style={{ overflowY: 'auto', maxHeight: '350px' }}>
        <table>
          <thead>
            {renderTableHeaders()}
          </thead>
          <tbody>
            {renderTableRows()}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DisbursementReport;
