import { useRoutes } from "react-router-dom";
import LungTherapies from "../pages/lungTherapies/LungTherapies";
import Rehabservices from "../pages/lungTherapies/Rehabservices";
import Techies from "../pages/lungTherapies/Techies";
import Kneepain from "../pages/lungTherapies/Kneepain";
import Diabetic from "../pages/lungTherapies/Diabetic";
import Pregnant from "../pages/lungTherapies/Pregnant";
import Sciatica from "../pages/lungTherapies/Sciatica";
import Dryneedle from "../pages/lungTherapies/Dryneedle";
import Cupping from "../pages/lungTherapies/Cupping";
import Taping from "../pages/lungTherapies/Taping";
import Sportsinjury from "../pages/lungTherapies/Sportsinjury";
import Chronicpain from "../pages/lungTherapies/Chronicpain";
import Mobility from "../pages/lungTherapies/Mobility";
import Strength from "../pages/lungTherapies/Strength";
import Postsurgical from "../pages/lungTherapies/Postsurgical";
import Chestphysio from "../pages/lungTherapies/Chestphysio";
import Neuromuscular from "../pages/lungTherapies/Neuromuscular";
import Lymphedema from "../pages/lungTherapies/Lymphedema";
import Vestibular from "../pages/lungTherapies/Vestibular";
import Manualtherapy from "../pages/lungTherapies/Manualtherapy";
import Geriatric from "../pages/lungTherapies/Geriatric";
import Asthma from "../pages/lungTherapies/Asthma";
import Copd from "../pages/lungTherapies/Copd";
import Pathogenpanel from "../pages/lungTherapies/Pathogenpanel";
import Sleepapnea from "../pages/lungTherapies/Sleepapnea";
import Pulmonaryfntest from "../pages/lungTherapies/Pulmonaryfntest";
import Ventilator from "../pages/lungTherapies/Ventilator";
import TherapistSearchResult from "../pages/lungTherapies/TherapistSearchResult";
import Strokerehab from "../pages/lungTherapies/Strokerehab";
import Musclestrength from "../pages/lungTherapies/Musclestrength";
import Gaittraining from "../pages/lungTherapies/Gaittraining";
import ADL from "../pages/lungTherapies/ADL";
import Balanceco from "../pages/lungTherapies/Balanceco";


const LungtherapiesRoutes = () => {
  let routes = useRoutes([
    { path: "/LungTherapies", element: <LungTherapies /> },
    { path: "/Rehabservices", element: <Rehabservices /> },
    { path: "/Techies", element: <Techies /> },
    { path: "/Kneepain", element: <Kneepain /> },
    { path: "/Diabetic",element: <Diabetic />},
    { path: "/Pregnant",element: <Pregnant />},
    { path: "/Sciatica",element: <Sciatica />},
    { path: "/Dryneedle",element: <Dryneedle />},
    { path: "/Cupping",element: <Cupping />},
     {path: "/Sportsinjury",element: <Sportsinjury />},
    { path: "/Taping",element:<Taping />},
    { path: "/Chronicpain",element:<Chronicpain/>},
    { path: "/Mobility",element:<Mobility/>},
    { path: "/Strength",element:<Strength/>},
    { path: "/Postsurgical",element:<Postsurgical/>},
    { path: "/Chestphysio",element:<Chestphysio/>},
    { path: "/Neuromuscular",element:<Neuromuscular/>},
    { path: "/Lymphedema",element:<Lymphedema/>},
    { path: "/Vestibular",element:<Vestibular/>},
    { path: "/Manualtherapy",element:<Manualtherapy/>},
    { path: "/Geriatric",element:<Geriatric/>},
    { path: "/Pathogenpanel",element:<Pathogenpanel/>},
    { path: "/Asthma",element:<Asthma/>},
    { path: "/Copd",element:<Copd/>},
    { path: "/Sleepapnea",element:<Sleepapnea/>},
    { path: "/Pulmonaryfntest",element:<Pulmonaryfntest/>},
    { path: "/Therapist_SearchResult",element:<TherapistSearchResult/>},
    { path: "/Ventilator",element:<Ventilator/>},
    { path: "/Strokerehab",element:<Strokerehab/>},
    { path: "/Musclestrength",element:<Musclestrength/>},
    { path: "/Gaittraining",element:<Gaittraining/>},
    { path: "/ADL",element:<ADL/>},
    { path: "/Balanceco",element:<Balanceco/>},
    
  ]);
  return routes;
};

export default LungtherapiesRoutes;
