import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";

export default function RhfServicePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cardStyle = {
    height: "330px", // Fixed height for the cards
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    marginBottom: "20px",
  };

  const imgStyle = {
    height: "180px", // Fixed height for the images
    objectFit: "cover",
  };

  const cardTextStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    marginBottom: "10px", // Add margin for better spacing
    fontSize: "14px", // Adjust font size as needed
  };

  const services = [
    {
      id: 1,
      title: "Emergency Care @ Home",
      price: "₹500 - ₹5,000",
      link: "/RHFemergencyCare",
      imgSrc: "../style/img/resp/Rectangle 136.png",
    },
    {
      id: 2,
      title: "Routine Medical Check-up",
      price: "₹999/-",
      link: "/RHFroutineMedCheckup",
      imgSrc: "../style/img/resp/Rectangle 137.png",
    },
    {
      id: 3,
      title: "Sleep Study",
      price: "₹999/-",
      link: "/RHFsleepStudy",
      imgSrc: "../style/img/resp/Rectangle 138.png",
    },
    {
      id: 4,
      title: "Pulmonary Function Test",
      price: "₹999/-",
      link: "/RHFpulmonaryFunctionTest",
      imgSrc: "../style/img/resp/Rectangle 139.png",
    },
    {
      id: 5,
      title: "COPD Patient Care",
      price: "₹999/-",
      link: "/RHFCOPDpatientCare",
      imgSrc: "../style/img/resp/Rectangle 140.png",
    },
    {
      id: 6,
      title: "Chest Physiotherapy",
      price: "₹999/-",
      link: "/RHFchestPhysiotherapy",
      imgSrc: "../style/img/resp/Rectangle 141.png",
    },
    {
      id: 7,
      title: "Pneumonia Pediatric Care",
      price: "₹999/-",
      link: "/RHFpneumoniaPediatricCare",
      imgSrc: "../style/img/resp/Rectangle 142.png",
    },
    {
      id: 8,
      title: "Doctor Consultation @ Home",
      price: "₹999/-",
      link: "/RHFdoctorConsultation",
      imgSrc: "../style/img/resp/Rectangle 143.png",
    },
    {
      id: 9,
      title: "Spirometry Test",
      price: "₹999/-",
      link: "/RHFspirometryTest",
      imgSrc: "../style/img/resp/Rectangle 144.png",
    },
    {
      id: 10,
      title: "Oxygen Administration",
      price: "₹500",
      link: "/Oxygenadministration",
      imgSrc: "../style/img/resp/seniormanbreath.jpg",
    },
    {
      id: 11,
      title: "Patient Care Plan",
      price: "₹999/-",
      link: "/Patientcareplan",
      imgSrc: "../style/img/resp/rhfpatientcare1.jpg",
    },
    {
      id: 12,
      title: "Ostomycare",
      price: "₹1000/-",
      link: "/Ostomycare",
      imgSrc: "../style/img/resp/rhfpatientcare2.jpg",
    },
    {
      id: 13,
      title: "Enema Procedure",
      price: "₹999/-",
      link: "/RHFenema",
      imgSrc: "../style/img/resp/rhfpatientcare3.jpg",
    },
    {
      id: 14,
      title: "Patient Assessment",
      price: "₹999/-",
      link: "/Patientassessment",
      imgSrc: "../style/img/resp/rhfpatientcare4.jpg",
    },
    {
      id: 15,
      title: "Tracheostomy Care",
      price: "₹999/-",
      link: "/Tracheostomycare",
      imgSrc: "../style/img/resp/Rectangle 141.png",
    },
    {
      id: 16,
      title: "Vaccinations",
      price: "₹300 - 4000/-",
      link: "/Rhfvaccinations",
      imgSrc: "../style/img/resp/vaccine2.jpg",
    },
    {
      id: 17,
      title: "IV Infusion Therapy",
      price: "₹500 - 3000/-",
      link: "/Rhfinfusiontherapy",
      imgSrc: "../style/img/resp/infusion4.jpg",
    },
  ];

  return (
    <>
      <Header />
      <div className="rhfsebg">
        <div className="container">
          <header className="text-center">
            <h2 className="rhfhed1">Services We Provide</h2>
            <p>
              Experience compassionate care and expert guidance tailored to your
              individual needs, fostering holistic wellness and empowering you
              to thrive.
            </p>
          </header>
          <div class="container">
            <div className="row">
              {services.map((service) => (
                <div className="col-sm-4" key={service.id}>
                  <div className="card rhfcardser" style={cardStyle}>
                    <img
                      className="card-img-top"
                      src={service.imgSrc}
                      alt={`Image for ${service.title}`}
                      style={imgStyle}
                    />
                    <div className="card-body">
                      <p className="card-text" style={cardTextStyle}>
                        {service.title}
                      </p>
                      <p className="text-dark">
                        <strong>{service.price}</strong>
                      </p>
                      <div className="text-center">
                        <Link to={service.link}>
                          <button className="custom-btn1 btn-3">
                            <span>Learn More</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <br></br>
        </div>
      </div>
    </>
  );
}
