import { useState, useContext, useEffect } from "react";
import ProviderHeader from "../header/ProviderHeader";
import { AuthContext } from "../../context/authContext";
import withProviderAuth from "../../middleware/withProviderAuth";
import { useSearchParams } from "react-router-dom";

import {
  setFunction,
  getFunction,
  updateFunction,
} from "../../api/apiFunctions";
import { checkAuthStatus, getAuthTokens } from "../../helpers/authFunctions";
import AddCoordinator from "./AddCoordinator.js";
import Viewrhfunit from "./Viewrhfunit.js";

import CoordinatorHeader from "../header/CoordinatorHeader.js";
const CoordinatorDashboard = () => {
  const {
    coordinatorId,
    setCoordinatorId,
    coordinatorEmail,
    setCoordinatorEmail,
    setCoordinatorType,
    setIdToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  } = useContext(AuthContext);
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });
  const [menuActive, setMenuActive] = useState(false);
  const [showallCoordinators, setallCoordinators] = useState(true); 
  const [showallViewrhfunits, setAllViewrhfunits] = useState(false);
  const [showCoordinator, setShowcordinator] = useState('');

  const [existingCoordinator, setExistingCoordinator] = useState(true);

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    if (accessToken && refreshToken && coordinatorId) {
      getProfile(accessToken, refreshToken, coordinatorId);
    }
  }, [accessToken, refreshToken, coordinatorId]);

  useEffect(() => {
    if (code) {
      getAuthTokens(code, "/auth/coordinator/get-token", "coordinator")
        .then((data) => {
          console.log(data);
          setCoordinatorId(data.sub);
          setCoordinatorEmail(data.email);
          setIdToken(data.idToken);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          localStorage.setItem("id_token", data.idToken);
          localStorage.setItem("access_token", data.accessToken);
          localStorage.setItem("refresh_token", data.refreshToken);
          localStorage.setItem("coordinatorEmail", data.email);
          localStorage.setItem("coordinatorId", data.sub);
          localStorage.setItem("coordinatorType",data.coordinatorType)
          getProfile(data.accessToken, data.refreshToken, data.sub);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);
  
  const getProfile = (accessToken, refreshToken, coordinatorId) => {
    getFunction({ id: coordinatorId }, "/coordinator/get-profile")
      .then((response) => {
        const responseData = response.success.result[0];
        setData({
          id: responseData.id,
          firstName: responseData.first_name,
          lastName: responseData.last_name,
        });
        setCoordinatorType(responseData.coordinator_type);
        localStorage.setItem("coordinatorType", responseData.coordinator_Type);
   
      })
      .catch((error) => {
        setData({ ...data, email: coordinatorEmail, id: coordinatorId });
        console.log(error);
        setExistingCoordinator(false);
      });
  };

  const getcoordinator = () => {
    getFunction({email:coordinatorEmail}, "/coordinator/get-coordinator")
      .then((response) => {
        const responseData = response.success.result[0];
        setShowcordinator(responseData);
        localStorage.setItem("coordinatorType", responseData.coordinator_type);

        console.log(responseData);
      })
      .catch((error) => { });
  };
useEffect(()=> {
  getcoordinator();
});

  const [isLoading, setIsLoading] = useState(false);
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleMenuClick = (section) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setallCoordinators(section === 'showAddCoordinator');
      setAllViewrhfunits(section === 'showallViewrhfunits');
    }, 500); 
  };

  return (
    <>
      <CoordinatorHeader/>

      <div className="profile">
        <div className="container-fluid">
          <br />
          <div className="row">
            <div className="col-sm-4">
              <div>
                <img
                  src="../style/img/resp/drprofile.jpg"
                  alt="Profile"
                  className="drprofile providerdrprofile"
                />
                <img
                  src="../style/img/resp/green.png"
                  alt="Profile"
                  className="liveicon providerliveicon"
                />
              </div>
            </div>
            <div className="col-sm-4">
              <h3 className="profileln">
               {showCoordinator.coordinator_type } Coordinator
              </h3>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <br />
      <br />

      <div className="d-flex protop">
        <div className={`side-menu bg2 prowidth ${menuActive ? 'active' : ''}`}>

          {showCoordinator.coordinator_type === "District" ? (
            <h3 className="side-menu-item" onClick={() => handleMenuClick('showAddCoordinator')}>
              View Area Coordinator
            </h3>
          ) : (
            <h3 className="side-menu-item" onClick={() => handleMenuClick('showallViewrhfunits')}>
              View Rhf Units
            </h3>
          )}

        </div>

        <div className="container profile-container" style={{ marginTop: "20px" }}>
          <>
            {showallCoordinators && <AddCoordinator />}
            {showallViewrhfunits && <Viewrhfunit />}
          </>
        </div>
      </div>
    </>
  );
};



export default CoordinatorDashboard;
