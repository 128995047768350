import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <>
      <nav class="nav">
        <input type="checkbox" id="nav-check" />
        <div class="nav-header"></div>

        <div class="nav-btn">
          <label for="nav-check">
            <span class="bgm1"></span>
            <span class="bgm1"></span>
            <span class="bgm1"></span>
          </label>
        </div>

        <ul class="nav-list mynavlist">
          <li class="">
            <a class="nav-link ddd" href="/rhf/Rhf">
              <font color="#fff">Book a RHF</font>
            </a>

            <div class=""></div>
          </li>
          <li class=" submenu dropdown">
            <Link
              to="/bookathome"
              class="nav-link  ddd "
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font color="#fff">Book a Test</font>
            </Link>
            <ul class="dropdown-menu">
              <li class="">
                <a class="nav-link" href="/booking-home">
                  Book at Home{" "}
                </a>
              </li>
              <li class="">
                <a class="nav-link" href="/book-clinic">
                  Book a Clinic
                </a>
              </li>
            </ul>
          </li>
          <li class="">
            <a class="nav-link  ddd" href="/book-therapist">
              <font color="#fff">Book a therapist</font>
            </a>
            <div class=""></div>
          </li>

          <li class=" submenu dropdown">
            <Link
              to="/pulmonologist"
              class="nav-link  ddd dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font color="#fff">consult a Pulmonologist </font>
            </Link>
            <ul class="dropdown-menu">
              <li class="">
                <a class="nav-link" href="/consult-online">
                  Online
                </a>
              </li>
              <li class="">
                <a class="nav-link" href="/consult-offline">
                  Offline
                </a>
              </li>
              {/* <li class="nav-item"><a class="nav-link" href="/Respirotherapist">Book a Therapist</a></li> */}
              {/* <li class="nav-item"><a class="nav-link" href="/Pulmonologist">Pulmonologistt</a></li> */}
            </ul>
          </li>
          <li class="">
            <a class="nav-link  ddd" href="/o2">
              <font color="#fff">Medical equipment</font>
            </a>
            <div class=""></div>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
