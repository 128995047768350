import React from "react";

function Rhfadmin() {
  return (
    <div>
      <div class="rhfadmin"></div>
    </div>
  );
}

export default Rhfadmin;
