import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import DATA1 from "../../data/Data1";
import { FaArrowRightLong } from "react-icons/fa6";


const Oproduct = ({ searchInput }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [showAllProducts, setShowAllProducts] = useState(false);

  const productRefs = useRef([]); // Create refs for all product cards

  // Function to calculate discounted price
  const calculateDiscountedPrice = (price, discountPercentage) => {
    const discountedPrice = price - (price * discountPercentage) / 100;
    return discountedPrice.toFixed(2); // Keep 2 decimal points
  };

  // Function to generate random star ratings between 2.5 and 5
  const getRandomRating = () => {
    const randomValue = Math.random() * 2.5 + 2.5; // Generates a number between 2.5 and 5
    return Math.round(randomValue * 2) / 2; // Round to the nearest half
  };

  // Filter products based on search input and selected category
  const filteredProducts = DATA1.filter((item) => {
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchInput.toLowerCase());
    const matchesCategory = selectedCategory
      ? item.title.toLowerCase().includes(selectedCategory)
      : true;
    return matchesSearch && matchesCategory;
  }).sort((a, b) => {
    if (sortOrder === "lowToHigh") return a.price - b.price;
    if (sortOrder === "highToLow") return b.price - a.price;
    return 0;
  });

  // Display either all or a limited number of products
  const displayedProducts = showAllProducts ? filteredProducts : filteredProducts.slice(0, 9);

  const handleCategorySelect = (category) => {
    setSelectedCategory(prevCategory => (prevCategory === category ? "" : category));
    setShowAllProducts(false);
  };

  const toggleShowProducts = () => {
    setShowAllProducts(!showAllProducts);
  };

  // Use IntersectionObserver to apply animation when the product card is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target); // Stop observing after it's animated in
          }
        });
      },
      { threshold: 0.2 } // Adjust threshold as needed
    );

    productRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      productRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [displayedProducts]);

  return (
    <div style={{ display: "flex" }}>
      {/* Sidebar Filter */}
      <div style={{
        width: "20%",
        padding: "20px",
        backgroundColor: "#f4f4f4",
        borderRight: "1px solid #ddd"
      }}>
        <h4>Filter By</h4>

        {/* Product Category */}
        <div style={{ marginBottom: "20px" }}>
          <h5>Category</h5>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === ""}
              onChange={() => handleCategorySelect("")}
            /> All
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "bp monitor"}
              onChange={() => handleCategorySelect("bp monitor")}
            /> BP Monitor
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "pulse oximeter"}
              onChange={() => handleCategorySelect("pulse oximeter")}
            /> Pulse Oximeter
          </div>
          <div>
            <input
              type="checkbox"
              checked={selectedCategory === "nebulizer"}
              onChange={() => handleCategorySelect("nebulizer")}
            /> Nebulizer
          </div>
          {/* Add more categories as needed */}
        </div>

        {/* Sort by Price */}
        <div style={{ marginBottom: "20px" }}>
          <h5>Sort by Price</h5>
          <button
            className="bg-secondary"
            onClick={() => setSortOrder("lowToHigh")}
            style={{ display: "block", margin: "5px 0", width: "100%" }}>
            Low to High
          </button>
          <button
            className="bg-secondary"
            onClick={() => setSortOrder("highToLow")}
            style={{ display: "block", margin: "5px 0", width: "100%" }}>
            High to Low
          </button>
        </div>
      </div>

      {/* Main Product Area */}
      <div style={{ width: "80%", padding: "20px" }}>
        <h1>All Products</h1>
        <div className="row">
          {displayedProducts.map((item, index) => {
            const discountedPrice = calculateDiscountedPrice(item.price, item.discountPercentage);
            const randomRating = getRandomRating(); // Get a random rating for the product

            return (
              <div
                className="col-md-4 product-card" // Apply animation class
                key={item.id}
                ref={(el) => (productRefs.current[index] = el)}
                style={{ marginBottom: "20px" }}
              >
                <div className="card o2scard" style={{ position: "relative", padding: "25px", border: "1px solid #ddd" }}>
                  {/* Sale and Respiro Assured tags */}
                  {item.discountPercentage > 0 && (
                    <div className="product-tag-sale">Sale</div>
                  )}
                  {item.isRespiroAssured && (
                    <div className="product-tag-respiro-assured">Respiro Assured</div>
                  )}
                  
                  <img className="o2simg" src={item.img} alt="" style={{ width: "100%", height: "200px" }} />
                  <center>
                    <h4 className="o2stitle" style={{ margin: "40px 0" }}>{item.title}</h4>
                  </center>
                  
                  {/* Display star ratings */}
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    {Array.from({ length: 5 }, (_, index) => (
                      <span key={index} style={{ color: index < Math.floor(randomRating) ? "#FFD700" : "#ccc" }}>
                        {index < randomRating ? (index + 1 <= randomRating ? '★' : '☆') : '☆'}
                      </span>
                    ))}
                  </div>
                
                  {/* Display original and discounted prices */}
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div className="o2sprice" style={{ fontSize: "1.2em", fontWeight: "bold", color: "#333" }}>
                      {item.discountPercentage > 0 ? (
                        <>
                          <strike>₹{item.price}</strike>
                          <span style={{ color: "red", marginLeft: "8px" }}>- {item.discountPercentage}%</span>
                          <div>₹{discountedPrice} </div>
                        </>
                      ) : (
                        <div>₹{item.price} </div> // Show only the original price if there's no discount
                      )}
                    </div>

                    <NavLink to={`/Sale/${item.id}`}>
  <button className="btn btno2ss" style={{
    background: "linear-gradient(144deg, #1f70ff, #8db7fb)", // Updated gradient
    color: "#fff", // White text
    padding: "10px 20px", // Padding
    fontSize: "0.9em", // Font size
    fontWeight: "bold", // Bold text
    border: "none", // No border
    borderRadius: "30px", // Rounded corners
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow
    transition: "all 0.3s ease-in-out" // Smooth transition
  }}>
    <span style={{
      display: "flex",
      alignItems: "center"
    }}>
      View <FaArrowRightLong style={{ marginLeft: "8px", fontSize: "1.2em" }} />
    </span>
  </button>
</NavLink>

                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <button
          onClick={toggleShowProducts}
          style={{ marginTop: "20px", padding: "10px 20px", backgroundColor: "#007bff", color: "#fff", border: "none", borderRadius: "5px" }}>
          {showAllProducts ? "Show Less" : "Show All Products"}
        </button>
      </div>
    </div>
  );
};

export default Oproduct;
