import { useRoutes } from "react-router-dom";

import Payment from "../pages/paymentGateway/Payment";
import Success from "../pages/paymentGateway/Success";
import PaymentStatus from "../pages/paymentGateway/PaymentStatus";
import Failure from "../pages/paymentGateway/Failure";


const PaymentRoutes = () => {
  
  let routes = useRoutes([
    { path: "/Payment", element: <Payment/> },
    { path: "/Success", element: <Success/> },
    { path: "/Failure", element: <Failure/> },
    { path: "/PaymentStatus/:transactionId", element: <PaymentStatus/> },
   
  ]);
  return routes;
};

export default PaymentRoutes;
